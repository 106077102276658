/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import {motion as FramerMotion} from 'framer-motion';

const AnimationTransitionX = (props) => {
  const {x, duration, children} = props;
  return (
    <FramerMotion.div animate={{x: x}} transition={{ease: 'easeOut', duration: duration}}>
      {children}
    </FramerMotion.div>
  );
};

AnimationTransitionX.propTypes = {
  x: PropTypes.number.isRequired,
  duration: PropTypes.number,
  children: PropTypes.any,
};

AnimationTransitionX.defaultProps = {
  duration: 1,
  children: null,
};

export default AnimationTransitionX;
