import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useFilterModal} from '../../lib/hooks/logic/useFilter';
import Icon from '../../components/icon';
import * as ICONS from '../../components/icon/constants';
import {NavbarFilterWrapper, NavFilterLabel} from './styled-component';

const NavbarFilter = (props) => {
  const {isShow} = props;

  const {formatMessage} = useIntl();
  const {dispatchSetVisibleFilter} = useFilterModal();

  return (
    <NavbarFilterWrapper isShow={isShow || window.pageYOffset <= 50} onClick={() => dispatchSetVisibleFilter(true)}>
      <Icon name={ICONS.FILTER} color="white" />
      <NavFilterLabel>{formatMessage({defaultMessage: 'ตัวกรองงาน'})}</NavFilterLabel>
    </NavbarFilterWrapper>
  );
};

NavbarFilter.propTypes = {
  isShow: PropTypes.bool,
};

NavbarFilter.defaultProps = {
  isShow: true,
};

export default NavbarFilter;
