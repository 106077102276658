export const ROUTE_PROFILE = '/profile';
export const ROUTE_EDIT_PROFILE = '/edit_profile';
export const ROUTE_USER_CHANGE_PASSWORD = '/user_change_password';
export const ROUTE_EDIT_PROFILE_GENERAL = '/edit_profile/general';
export const ROUTE_EDIT_PROFILE_WORKS = '/edit_profile/work';
export const ROUTE_EDIT_PROFILE_EDUCATION = '/edit_profile/education';
export const ROUTE_EDIT_PROFILE_SKILL = '/edit_profile/skills';
export const ROUTE_EDIT_PROFILE_INTEREST = '/edit_profile/interest';
export const ROUTE_EDIT_PROFILE_OTHER = '/edit_profile/other';

export const ROUTE_MAIN = '/';
export const ROUTE_FAQ = '/faq';
export const ROUTE_HELP = '/help';
export const ROUTE_CONTACT = '/contact';
export const ROUTE_NOTIFY_MANAGE = '/manage_noti';

// protected routes
export const ROUTE_LOGIN = '/login';
export const ROUTE_REGISTER = '/register';
export const ROUTE_OTP = '/verify';
export const ROUTE_FORGOT_PASSWORD = '/forgot_password';
export const ROUTE_FACEBOOK_LOGIN = '/auth/facebook/callback';

// private routes
export const ROUTE_HOME = '/';
export const ROUTE_LIKE = '/like';
export const ROUTE_NOTIFY = '/notify';
export const ROUTE_MENU = '/menu';
export const ROUTE_SETTING = '/setting';

export const ROUTE_404 = '/404';

// search
export const ROUTE_SEARCH = '/search';
export const ROUTE_SEARCH_RESULTS = '/search_results/query';
export const ROUTE_SEARCH_BY_ID = '/job/id';
export const ROUTE_SEARCH_HASHTAG = '/search_hashtag/tag';
export const ROUTE_JOB_CATEGORY = '/job_category';
export const ROUTE_HIDDEN_JOB = '/hidden_job';
export const ROUTE_SEARCH_AUTHOR = '/search_author/author';

export const ROUTE_LANDING_GROUP = '/l/:id';
export const ROUTE_LANDING_GROUP_RAW = '/l/';

export const ROUTE_GROUP_LIST = '/all_group';
export const ROUTE_EDIT_MAIL_FORM = '/mail_form';
export const ROUTE_RESUME_TEMPLATE = '/resume_template';
export const ROUTE_NO_INTERNET = '/internet_offline';
export const ROUTE_APPLY_JOB = '/apply_job';

export const ROUTE_COMPANY_PROFILE = '/company_profile/:id';
export const ROUTE_COMPANY_PROFILE_RAW = '/company_profile/';

export const ROUTE_JOB_INDEX = '/job_index';
export const ROUTE_COMPANY_INDEX = '/company_index';
export const ROUTE_COMPANY_INDEX_TH = '/company_index/th';
export const ROUTE_COMPANY_INDEX_EN = '/company_index/en';
export const ROUTE_COMPANY_INDEX_NUMBER = '/company_index/number';

export const ROUTE_PRIVACY_POLICY = '/privacy_policy';
export const ROUTE_TERM_AND_AGREEMENT = '/terms_of_service';
export const ROUTE_COOKIE_POLICY = '/cookie_policy';
export const ROUTE_ACCEPT_POLICY = '/accept_policy';
