import * as TYPES from './type';

export const userAuthCurrent = (payload) => ({
  type: TYPES.AUTH_CURRENT_REQ,
  payload: payload,
});

export const userAuthLogout = (payload) => ({
  type: TYPES.AUTH_LOGOUT_REQ,
  payload: payload,
});

export const userRegisterManual = (formData) => ({
  type: TYPES.AUTH_REGISTER_MANUAL_REQ,
  payload: formData,
});

export const userRegisterFacebook = (formData) => ({
  type: TYPES.AUTH_REGISTER_FACEBOOK_REQ,
  payload: formData,
});

export const userLoginManual = (formData) => ({
  type: TYPES.AUTH_LOGIN_MANUAL_REQ,
  payload: formData,
});

export const userLoginFacebook = (formData) => ({
  type: TYPES.AUTH_FACEBOOK_LOGIN_REQ,
  payload: formData,
});

export const userLoginFromRegisterAction = (payload) => ({
  type: TYPES.AUTH_LOGIN_FROM_REGISTER_REQ,
  payload: payload,
});

export const userForgotPasswordRequestCode = (payload) => ({
  type: TYPES.AUTH_FORGOT_PASSWORD_REQUEST_CODE_REQ,
  payload: payload,
});

export const userForgotPasswordCheckCode = (payload) => ({
  type: TYPES.AUTH_FORGOT_PASSWORD_CHECK_CODE_REQ,
  payload: payload,
});

export const userForgotPasswordSetPassword = (payload) => ({
  type: TYPES.AUTH_FORGOT_PASSWORD_RESET_PASSWORD_REQ,
  payload: payload,
});

export const userForgotPasswordChangePhone = () => ({
  type: TYPES.AUTH_FORGOT_PASSWORD_CHANGE_PHONE_REQ,
});

export const userAuthorizeDenied = (payload) => ({
  type: TYPES.AUTH_AUTHORIZE_REQ,
  payload: payload,
});

export const userOtpCancelInvoke = (payload) => ({
  type: TYPES.AUTH_OTP_CANCEL_INVOKE_REQ,
  payload: payload,
});

export const userForgotPasswordClear = () => ({
  type: TYPES.AUTH_FORGOT_PASSWORD_CLEAR_REQ,
});

export const authUpdateCurrent = (payload) => ({
  type: TYPES.AUTH_UPDATE_CURRENT_REQ,
  payload: payload,
});

export const firebaseAnonymousLogin = () => ({
  type: TYPES.FIREBASE_ANONYMOUS_LOGIN_REQ,
});

export const firebaseAnonymousLogout = () => ({
  type: TYPES.FIREBASE_ANONYMOUS_LOGOUT_REQ,
});
