import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useGetReduxState} from '../../../lib/hooks-logic/useGetReduxState';
import {directCheckEmailAvailable} from '../../../lib/hooks-logic/useDirectApi';
import {useDispatchFileUpload} from '../../../lib/hooks/logic/useUpload';
import {translate} from '../../../res/language/translate';
import {
  ButtonSmallWrapper,
  HeaderWrapper,
  ButtonLargeWrapper,
  InputHidden,
  RowResume,
  LabelResume,
  OverrideStyles,
  SelectBirthDayWrapper,
  RowBirthDay,
} from './styled-component';
import {useRegister} from '../../../lib/hooks/logic/useRegister';
import Form from '../../../components/form';
import Button from '../../../components/button';
import OptionBuilder from '../../../views/option-builder/new-version';
import Input from '../../../components/input';
import Loading from '../../../components/loading';
import Link from '../../../components/link';
import Header from '../../../components/header';
import FormWrapper from '../../../views/form';
import SelectProvince from '../../../views/select-location-sibling/select-province';
import Responsive from '../../../components/responsive';
import * as validate from './validate';
import * as logic from './logic';
import * as snackbar from '../../../utils/components/toast-notification/provider';
import * as initForm from './init-form';
import * as coreRegister from '../../../lib/context/register';

const CURRENT_STEP = '(2/2)';
const BREAKPOINT_SMALL = 1024;
const FILE_IMAGES_SUPPORT = ['image/jpeg', 'image/png', 'image/jpg'];
const FILE_SUPPORT = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  ...FILE_IMAGES_SUPPORT,
];

const FormInfo = (props) => {
  const {onSubmit} = props;

  const nodeInputHiddenRef = useRef(null);

  const {formatMessage} = useIntl();
  const {reduxState: facebookState} = useGetReduxState('auth.facebook.user');
  const {reduxState: registerIdState} = useGetReduxState('register.registerId._id');
  const {reduxState: formStep1State} = useGetReduxState('register.step1.formRegister');
  const {formInfoState, dispatchUpdatePreviousStep, dispatchUpdateFormInfo} = useRegister();
  const {dispatchUploadFile, isLoadingUploadState} = useDispatchFileUpload();

  const [formData] = useState({
    date: coreRegister.generateDateData(),
    month: coreRegister.generateMonthData(),
    year: coreRegister.generateYearData(),
    sex: coreRegister.generateGenderData(),
  });
  const [formState, setFormState] = useState({...initForm.initFormInfo});
  const [errorTexts, setErrorTexts] = useState({...initForm.initFormInfo});
  const [stepLabelState, setStepLabelState] = useState(CURRENT_STEP);

  useEffect(() => {
    if (formInfoState) {
      setFormState({
        ...formInfoState,
      });
    }
  }, [formInfoState]);

  useEffect(() => {
    if (!facebookState && formStep1State && !formState.email && !formInfoState?.email) {
      const newState = {
        ...formState,
        email: formStep1State.email,
      };
      setFormState({
        ...newState,
      });
    }
  }, [formStep1State, facebookState, formState, formInfoState]);

  useEffect(() => {
    if (facebookState?.first_name && !formInfoState?.firstName) {
      setFormState({
        ...formState,
        firstName: facebookState.first_name,
        lastName: facebookState.last_name,
        email: facebookState.email,
      });
    }
    if (facebookState) {
      setStepLabelState('');
    }
  }, [facebookState, formInfoState]);

  const onChangeError = (key, value) => {
    const newError = {...errorTexts};
    newError[key] = value;
    setErrorTexts(newError);
  };

  const onChangeText = (key, value) => {
    onChangeError(key, '');
    const newForm = {...formState};
    newForm[key] = value;
    setFormState(newForm);
  };

  const onValidateExistEmail = async () => {
    const [response, error] = await directCheckEmailAvailable(formState.email);
    if (error) {
      setErrorTexts({...errorTexts, email: error});
      return;
    }
    if (response) {
      onSubmit(formState);
    }
  };

  const handlerOnSubmit = () => {
    const formValues = {
      ...formState,
    };
    const [isValidate, message] = validate.validateInfoForm(formValues);
    if (!isValidate) {
      setErrorTexts({...errorTexts, ...message});
      return;
    }
    setErrorTexts({...initForm.initFormInfo});
    onValidateExistEmail();
  };

  const onEventRequestUpload = () => {
    nodeInputHiddenRef.current.click();
  };

  const onChangeFile = async (event) => {
    if (!event || !registerIdState) {
      return;
    }
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const [uploadUrl, errorUpload] = await dispatchUploadFile(registerIdState, {file}, 'manual_resume');
    if (uploadUrl) {
      onChangeText('resume', uploadUrl);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'อัพโหลดเรซูเม่สำเร็จ'}));
    }
    if (errorUpload) {
      onChangeText('resume', '');
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรุณาลองใหม่อีกครั้ง'}));
    }
  };

  const onClickDeleteResume = () => {
    onChangeText('resume', '');
  };

  const onClickPrevious = () => {
    dispatchUpdateFormInfo(formState, true);
    dispatchUpdatePreviousStep();
  };

  return (
    <FormWrapper position="start">
      <Loading loading={isLoadingUploadState} />
      <HeaderWrapper>
        <Header variant="h2">{translate('register.step2')}</Header>
      </HeaderWrapper>
      <Form formId="form-register-info" style={OverrideStyles.form} onSubmit={handlerOnSubmit}>
        {facebookState && !facebookState?.email && (
          <Input
            fillBackground
            name="email"
            type="email"
            label={translate('login.fieldEmail')}
            placeholder={logic.getPlaceholderText('email')}
            value={formState.email}
            onChange={(value) => onChangeText('email', value)}
            warningText={errorTexts.email}
          />
        )}
        <Input
          fillBackground
          name="firstName"
          type="text"
          label={translate('register.textFieldFirstName')}
          placeholder={logic.getPlaceholderText('firstName')}
          value={formState.firstName}
          onChange={(value) => onChangeText('firstName', value)}
          warningText={errorTexts.firstName}
        />
        <Input
          fillBackground
          name="lastName"
          type="text"
          label={translate('register.textFieldLastName')}
          placeholder={logic.getPlaceholderText('lastName')}
          value={formState.lastName}
          onChange={(value) => onChangeText('lastName', value)}
          warningText={errorTexts.lastName}
        />
        <OptionBuilder
          name="sex"
          noArrow
          style={OverrideStyles.option}
          label={formatMessage({defaultMessage: 'เพศ'})}
          placeholder={formatMessage({defaultMessage: 'เลือกเพศ'})}
          data={formData.sex}
          value={formState.sex}
          onChange={(value) => onChangeText('sex', value)}
          warningText={errorTexts.sex}
        />
        <RowBirthDay>
          <SelectBirthDayWrapper noMargin>
            <OptionBuilder
              name="bd-date"
              noArrow
              style={OverrideStyles.option}
              label={formatMessage({defaultMessage: 'วันเกิด'})}
              placeholder={formatMessage({defaultMessage: 'วัน'})}
              data={formData.date}
              value={formState.birthDate}
              onChange={(value) => onChangeText('birthDate', value)}
              warningText={errorTexts.birthDate}
            />
          </SelectBirthDayWrapper>
          <SelectBirthDayWrapper>
            <OptionBuilder
              name="bd-month"
              noArrow
              style={OverrideStyles.option}
              label={formatMessage({defaultMessage: 'เดือนเกิด'})}
              placeholder={formatMessage({defaultMessage: 'เดือน'})}
              data={formData.month}
              value={formState.birthMonth}
              onChange={(value) => onChangeText('birthMonth', value)}
              warningText={errorTexts.birthMonth}
            />
          </SelectBirthDayWrapper>
          <SelectBirthDayWrapper>
            <OptionBuilder
              name="bd-year"
              noArrow
              style={OverrideStyles.option}
              label={formatMessage({defaultMessage: 'ปีเกิด'})}
              placeholder={formatMessage({defaultMessage: 'ปี'})}
              data={formData.year}
              value={formState.birthYear}
              onChange={(value) => onChangeText('birthYear', value)}
              warningText={errorTexts.birthYear}
            />
          </SelectBirthDayWrapper>
        </RowBirthDay>
        <SelectProvince
          checkBy="value"
          label={formatMessage({defaultMessage: 'จังหวัดที่อยู่ปัจจุบัน'})}
          placeholder={formatMessage({defaultMessage: 'เลือกจังหวัด'})}
          selectedProvince={formState.location || []}
          onChange={(value) => onChangeText('location', [value])}
          warningText={errorTexts.location}
        />
        <>
          <LabelResume> {formatMessage({defaultMessage: 'เรซูเม่ (ถ้ามี)'})}</LabelResume>
          <RowResume>
            <InputHidden
              name="file-upload"
              type="file"
              ref={nodeInputHiddenRef}
              accept={FILE_SUPPORT.join(',')}
              onChange={(event) => {
                onChangeFile(event);
                event.target.value = null;
              }}
            />
            <Button edgeInsets="none" small onClick={onEventRequestUpload}>
              {formatMessage({defaultMessage: 'อัพโหลด'})}
            </Button>
            {formState.resume && (
              <>
                <Button variant="light" edgeInsets="vertical" small onClick={onClickDeleteResume}>
                  {formatMessage({defaultMessage: 'ลบเรซูเม่'})}
                </Button>
                <Link newTab url={formState.resume}>
                  <Button variant="light" small edgeInsets="none">
                    {formatMessage({defaultMessage: 'ดูตัวอย่าง'})}
                  </Button>
                </Link>
              </>
            )}
          </RowResume>
        </>

        <ButtonLargeWrapper row>
          <Button type="button" edgeInsets="none" variant="light" style={OverrideStyles.button} onClick={onClickPrevious}>
            {translate('register.buttonPrevious')}
          </Button>
          <Button block type="submit" style={{...OverrideStyles.button, ...OverrideStyles.buttonLeftSpace}}>
            {`${translate('register.buttonNext')} ${CURRENT_STEP}`}
          </Button>
        </ButtonLargeWrapper>
      </Form>
      <Responsive maxWidth={BREAKPOINT_SMALL}>
        <ButtonSmallWrapper row>
          {!facebookState && (
            <Button type="button" edgeInsets="none" variant="light" onClick={onClickPrevious}>
              {translate('register.buttonPrevious')}
            </Button>
          )}
          <Button block type="submit" style={OverrideStyles.buttonLeftSpace} onClick={handlerOnSubmit}>
            {`${translate('register.buttonNext')} ${stepLabelState}`}
          </Button>
        </ButtonSmallWrapper>
      </Responsive>
    </FormWrapper>
  );
};

FormInfo.propTypes = {
  onSubmit: PropTypes.func,
};

FormInfo.defaultProps = {
  onSubmit: () => {},
};

export default FormInfo;
