import styled from 'styled-components';

export const Container = styled.div`
  z-index: 2040;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.modalBackdrop};
  ${(props) => props.zIndex && `z-index: ${props.zIndex};`};
`;

export const ChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1025px) {
    height: 90%;
    max-height: 700px;
  }
`;
