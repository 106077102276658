import * as ROUTES from '../../routes/routes-name';

export const matchUrlMenuSidebar = (pathname) => {
  switch (pathname) {
    case ROUTES.ROUTE_FAQ:
    case ROUTES.ROUTE_CONTACT:
    case ROUTES.ROUTE_MENU:
    case ROUTES.ROUTE_PROFILE:
    case ROUTES.ROUTE_SETTING:
      return true;
    default:
      return false;
  }
};

export const matchUrlEditSidebar = (pathname) => {
  switch (pathname) {
    case ROUTES.ROUTE_USER_CHANGE_PASSWORD:
    case ROUTES.ROUTE_EDIT_PROFILE_GENERAL:
    case ROUTES.ROUTE_EDIT_PROFILE_WORKS:
    case ROUTES.ROUTE_EDIT_PROFILE_EDUCATION:
    case ROUTES.ROUTE_EDIT_PROFILE_SKILL:
    case ROUTES.ROUTE_EDIT_PROFILE_INTEREST:
    case ROUTES.ROUTE_EDIT_PROFILE_OTHER:
      return true;
    default:
      return false;
  }
};
