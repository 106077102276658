import React, {useState, useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import {useUserState, useUserDispatch} from '../../../../lib/hooks-logic/useUser';
import {useDispatchFileUpload} from '../../../../lib/hooks/logic/useUpload';
import {FormWrapper, FormCenter, ListSkillWrapper, OverrideStyles} from '../styled-component';
import {
  ChipWrapper,
  ChipLabel,
  OptionWrapper,
  OptionSpacer,
  InputSpacer,
  LanguageCertItem,
  OverrideStyles as InnerStyles,
  InputHidden,
  LanguageItem,
} from './styled-component';
import Form from '../../../../components/form';
import Input from '../../../../components/input';
import ButtonIcon from '../../../../components/button-icon';
import Button from '../../../../components/button';
import Divider from '../../../../components/divider';
import Row from '../../../../components/row';
import Text from '../../../../components/text';
import Loading from '../../../../components/loading';
import Link from '../../../../components/link';
import Icon from '../../../../components/icon';
import OptionBuilder from '../../../../views/option-builder/new-version';
import * as ICONS from '../../../../components/icon/constants';
import * as typeDef from '../../../../utils/strong-types';
import * as initData from './init-data';
import * as logic from './logic';
import * as validate from './validate';
import * as snackbar from '../../../../utils/components/toast-notification/provider';
import * as coreUser from '../../../../lib/context/user';

const FILE_IMAGES_SUPPORT = ['image/jpeg', 'image/png', 'image/jpg'];
const FILE_SUPPORT = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  ...FILE_IMAGES_SUPPORT,
];

const FormSkill = () => {
  const {formatMessage} = useIntl();
  const {userState, isLoadingState} = useUserState();
  const {dispatchUpdateProfile} = useUserDispatch();
  const {dispatchUploadFileAllType, isLoadingUploadState} = useDispatchFileUpload();

  const nodeInputRef = useRef(null);
  const [listSkillState, setListSkillState] = useState();
  const [listAchievementState, setListAchievementState] = useState();
  const [newSkillState, setNewSkillState] = useState('');
  const [newAchievementState, setNewAchievementStat] = useState('');
  const [languageListState, setLanguageListState] = useState();
  const [examCertState, setExamCertState] = useState();
  const [examCertErrorState, setExamCertErrorState] = useState([]);
  const [targetFileIndexState, setTargetFileIndexState] = useState(0);

  const onSubmit = () => {
    const [isValidate, errorList] = validate.validateAllRow(examCertState);
    if (typeDef.isArrayLength(errorList) && !isValidate) {
      setExamCertErrorState(errorList);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรอกข้อมูลให้ครบผลการทดสอบให้ครบ หรือลบออก'}), 'warning');
      return;
    }
    const [isValidateLang, newLangList] = validate.validateRowLanguage(languageListState);
    if (!isValidateLang) {
      setLanguageListState(newLangList);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรอกข้อมูลภาษาให้ครบ'}), 'warning');
      return;
    }
    if (!userState) {
      return;
    }
    const language = logic.transformLanguage(languageListState);
    const userGeneral = coreUser.getGeneralNewAge(userState.general);
    const formSubmit = {
      ...userState,
      ...userGeneral,
      skill: typeDef.isArrayLength(listSkillState) ? [...listSkillState] : [],
      achievement: typeDef.isArrayLength(listAchievementState) ? [...listAchievementState] : [],
      language: [...language],
      language_certification: logic.transformExamCert(examCertState),
    };
    logic.deleteStorageFile(examCertState, userState);
    dispatchUpdateProfile(formSubmit);
  };

  const onChangeText = (key, value) => {
    switch (key) {
      case 'skill':
        setNewSkillState(value);
        break;
      case 'achievement':
        setNewAchievementStat(value);
        break;
      default:
        break;
    }
  };

  const onAddSkill = (value) => {
    if (!value) {
      return;
    }
    if (typeDef.isNotArrayLength(listSkillState)) {
      setListSkillState([value.trim()]);
    }
    const oldState = listSkillState || [];
    const copyList = [...oldState, value.trim()];
    setListSkillState(copyList);
    setNewSkillState('');
  };

  const onDeleteSkill = (index) => {
    const copyList = [...listSkillState];
    copyList.splice(index, 1);
    setListSkillState(copyList);
  };

  const onAddAchievement = (value) => {
    if (!value) {
      return;
    }
    if (typeDef.isNotArrayLength(listAchievementState)) {
      setListAchievementState([value.trim()]);
    }
    const oldState = listAchievementState || [];
    const copyList = [...oldState, value.trim()];
    setListAchievementState(copyList);
    setNewAchievementStat('');
  };

  const onDeleteAchievement = (index) => {
    const copyList = [...listAchievementState];
    copyList.splice(index, 1);
    setListAchievementState(copyList);
    if (examCertErrorState) {
      setExamCertErrorState([]);
    }
  };

  const onChangeLanguage = (value, index) => {
    const copyLangList = [...languageListState];
    copyLangList[index].name = value;
    if (copyLangList[index].customName !== 'อื่นๆ') {
      copyLangList[index].customName = '';
    }
    setLanguageListState(copyLangList);
  };

  const onChangeLanguageLevel = (value, index) => {
    const copyLangList = [...languageListState];
    copyLangList[index].level = value;
    setLanguageListState(copyLangList);
  };

  const onChangeLanguageCustomName = (value, index) => {
    const copyLangList = [...languageListState];
    copyLangList[index].customName = value;
    copyLangList[index].errorText = '';
    setLanguageListState(copyLangList);
  };

  const onAddRowLanguage = () => {
    const [isValidate, newList] = validate.validateRowLanguage(languageListState);
    if (!isValidate) {
      setLanguageListState([...newList]);
      return;
    }
    setLanguageListState([...newList, {...initData.rowLanguage}]);
  };

  const onDeleteRowLanguage = () => {
    const copyLangList = [...languageListState];
    if (copyLangList.length < 2) {
      return;
    }
    copyLangList.splice(copyLangList.length - 1);
    setLanguageListState(copyLangList);
  };

  const onAddExamCert = () => {
    if (typeDef.isNotArrayLength(examCertState)) {
      setExamCertState([{...initData.rowLanguageCertificate}]);
      return;
    }
    const [isValidate, errorList] = validate.validateAllRow(examCertState);
    if (typeDef.isArrayLength(errorList) && !isValidate) {
      setExamCertErrorState(errorList);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรอกข้อมูลให้ครบก่อนเพิ่มแถว'}), 'warning');
      return;
    }
    const copyList = [...examCertState, {...initData.rowLanguageCertificate}];
    setExamCertState(copyList);
  };

  const onDelExamCert = (index) => {
    const copyList = [...examCertState];
    copyList.splice(index, 1);
    setExamCertState(copyList);
  };

  const onChangeCertText = (value, index, type) => {
    const copyList = [...examCertState];
    switch (type) {
      case 'name':
        copyList[index].organization = value;
        break;
      case 'score':
        copyList[index].score = value;
        break;
      case 'url':
        copyList[index].url = value;
        break;
      default:
        break;
    }
    setExamCertState(copyList);
    if (examCertErrorState) {
      setExamCertErrorState([]);
    }
  };

  const onRequestUploadFile = (index) => {
    const [isValidate, errorList] = validate.validateAllRow(examCertState);
    if (typeDef.isArrayLength(errorList) && !isValidate) {
      setExamCertErrorState(errorList);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรอกข้อมูลให้ครบก่อนอัพโหลดไฟล์'}), 'warning');
      return;
    }

    nodeInputRef.current.click();
    setTargetFileIndexState(index);
  };

  const onChangeFile = async (event) => {
    if (!event) {
      return;
    }
    const file = event.target.files[0];
    const [response, error] = await dispatchUploadFileAllType(userState?._id, {file});
    if (error) {
      snackbar.setSnackbarMessage('อัพโหลดไฟล์ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
      return;
    }
    snackbar.setSnackbarMessage('อัพโหลดไฟล์สำเร็จ');
    onChangeCertText(response, targetFileIndexState, 'url');
  };

  useEffect(() => {
    const isCanDefaultSkill = userState?.general && typeDef.isArrayLength(userState.skill) && typeDef.isNullOrUndefined(listSkillState);
    const isCanDefaultAchievement =
      userState?.general && typeDef.isArrayLength(userState.achievement) && typeDef.isNullOrUndefined(listAchievementState);
    if (isCanDefaultSkill) {
      setListSkillState([...userState.skill]);
    }
    if (isCanDefaultAchievement) {
      setListAchievementState([...userState.achievement]);
    }
  }, [userState, listSkillState, listAchievementState]);

  useEffect(() => {
    const isCanDefaultLanguage = userState?.general && typeDef.isNullOrUndefined(languageListState);
    if (isCanDefaultLanguage) {
      const defaultLang = logic.mapLanguage(userState.language);
      setLanguageListState(defaultLang);
    }
  }, [userState, languageListState]);

  useEffect(() => {
    const isCanDefaultCert = userState?.general && typeDef.isNullOrUndefined(examCertState);
    if (isCanDefaultCert) {
      if (userState.language_certification) {
        setExamCertState(userState.language_certification);
      } else {
        setExamCertState([]);
      }
    }
  }, [userState, examCertState]);

  return (
    <FormCenter column>
      <Loading type="data" loading={isLoadingState && !userState} />
      <Loading type="action" loading={isLoadingState && !!userState} />
      <Loading type="action" loading={isLoadingUploadState} />

      {userState && (
        <>
          <FormWrapper marginBottom>
            <Text style={OverrideStyles.textTitle}>{formatMessage({defaultMessage: 'ทักษะด้านภาษา'})}</Text>
            <Divider bold edgeInsets />
            <Form formId="edit-lang" onSubmit={() => {}}>
              {typeDef.isNotArrayLength(languageListState) && <div />}
              {typeDef.isArrayLength(languageListState) &&
                languageListState.map((item, index) => (
                  <LanguageItem key={index.toString()}>
                    <Row content="space-between" wrap="nowrap">
                      <OptionWrapper>
                        <OptionBuilder
                          name="op-lang1"
                          label={`${formatMessage({defaultMessage: 'ภาษาที่ '})} ${index + 1}`}
                          data={initData.languageData || []}
                          value={item.name || null}
                          onChange={(value) => onChangeLanguage(value, index)}
                        />
                      </OptionWrapper>

                      {item.name.label !== 'อื่นๆ' && (
                        <>
                          <OptionSpacer />
                          <OptionWrapper>
                            <OptionBuilder
                              name="op-lang1-level"
                              label={formatMessage({defaultMessage: 'ระดับ'})}
                              data={initData.languageLevelData || []}
                              value={item.level || null}
                              onChange={(value) => onChangeLanguageLevel(value, index)}
                            />
                          </OptionWrapper>
                        </>
                      )}
                    </Row>
                    {item.name.label === 'อื่นๆ' && (
                      <Row alignStart content="space-between" wrap="nowrap">
                        <OptionWrapper>
                          <Input
                            fillBackground
                            type="text"
                            label={formatMessage({defaultMessage: 'ชื่อภาษา'})}
                            placeholder={formatMessage({defaultMessage: 'กรอกชื่อภาษา'})}
                            value={item.customName}
                            onChange={(value) => onChangeLanguageCustomName(value, index)}
                            warningText={item.errorText || null}
                          />
                        </OptionWrapper>
                        <OptionSpacer />
                        <OptionWrapper>
                          <OptionBuilder
                            name="op-lang1-level"
                            label={formatMessage({defaultMessage: 'ระดับ'})}
                            data={initData.languageLevelData || []}
                            value={item.level || null}
                            onChange={(value) => onChangeLanguageLevel(value, index)}
                          />
                        </OptionWrapper>
                      </Row>
                    )}
                  </LanguageItem>
                ))}
              <Row>
                <ButtonIcon small variant="success" edgeInsets="horizontal" iconName={ICONS.PLUS} onClick={onAddRowLanguage}>
                  {formatMessage({defaultMessage: 'เพิ่มภาษา'})}
                </ButtonIcon>
                {languageListState?.length > 1 && (
                  <ButtonIcon small variant="danger" edgeInsets="vertical" iconName={ICONS.MINUS} onClick={onDeleteRowLanguage}>
                    {formatMessage({defaultMessage: 'ลบภาษา'})}
                  </ButtonIcon>
                )}
              </Row>
            </Form>
          </FormWrapper>
          <FormWrapper marginBottom>
            <Text style={OverrideStyles.textTitle}>{formatMessage({defaultMessage: 'ผลการสอบระดับทางภาษา'})}</Text>
            <Divider bold edgeInsets />
            <InputHidden name="file-upload" type="file" ref={nodeInputRef} accept={FILE_SUPPORT.join(',')} onChange={onChangeFile} />
            {typeDef.isArrayLength(examCertState) &&
              examCertState.map((item, index) => (
                <LanguageCertItem key={index.toString()}>
                  <Row alignStart content="space-between" wrap="nowrap">
                    <OptionWrapper>
                      <Input
                        fillBackground
                        type="text"
                        label={formatMessage({defaultMessage: 'ชื่อผลการทดสอบ'})}
                        placeholder={formatMessage({defaultMessage: 'กรอกชื่อผลการทดสอบ'})}
                        value={item.organization}
                        onChange={(value) => onChangeCertText(value, index, 'name')}
                        warningText={examCertErrorState[index]?.organization || null}
                      />
                    </OptionWrapper>
                    <InputSpacer />
                    <OptionWrapper>
                      <Input
                        fillBackground
                        type="text"
                        label={formatMessage({defaultMessage: 'คะแนน'})}
                        placeholder={formatMessage({defaultMessage: 'กรอกคะแนน'})}
                        value={item.score}
                        onChange={(value) => onChangeCertText(value, index, 'score')}
                        warningText={examCertErrorState[index]?.score || null}
                      />
                    </OptionWrapper>
                  </Row>
                  <Row wrap="nowrap">
                    <ButtonIcon small edgeInsets="none" iconName={ICONS.UPLOAD} onClick={() => onRequestUploadFile(index)}>
                      {formatMessage({defaultMessage: 'อัพโหลดไฟล์'})}
                    </ButtonIcon>
                    {item.url && (
                      <Link newTab url={item.url}>
                        <ButtonIcon small edgeInsets="vertical" variant="light" iconName={ICONS.ATTACHMENT}>
                          {formatMessage({defaultMessage: 'ดูไฟล์'})}
                        </ButtonIcon>
                      </Link>
                    )}
                    <Button small edgeInsets={item.url ? 'none' : 'vertical'} variant="danger" onClick={() => onDelExamCert(index)}>
                      {formatMessage({defaultMessage: 'ลบแถว'})}
                    </Button>
                  </Row>
                  <Divider edgeInsets style={InnerStyles.divider} />
                </LanguageCertItem>
              ))}
            {typeDef.isNotArrayLength(examCertState) && (
              <Text style={InnerStyles.textBottom}>{`-${formatMessage({defaultMessage: 'ยังไม่มีผลการทดสอบ'})}`}</Text>
            )}
            <ButtonIcon small variant="success" edgeInsets="none" iconName={ICONS.PLUS} onClick={() => onAddExamCert()}>
              {formatMessage({defaultMessage: 'เพิ่มผลการทดสอบ'})}
            </ButtonIcon>
          </FormWrapper>
          <FormWrapper marginBottom>
            <Text style={OverrideStyles.textTitle}>{formatMessage({defaultMessage: 'ทักษะพิเศษ'})}</Text>
            <Divider bold edgeInsets />
            <ListSkillWrapper>
              {typeDef.isNotArrayLength(listSkillState) && <Text>{`-${formatMessage({defaultMessage: 'ยังไม่มีทักษะพิเศษ'})}`}</Text>}
              <Row>
                {typeDef.isArrayLength(listSkillState) &&
                  listSkillState.map((item, index) => (
                    <ChipWrapper key={index.toString()} onClick={() => onDeleteSkill(index)}>
                      <ChipLabel>{item}</ChipLabel>
                      <Icon name={ICONS.CLOSE} color="white" size={18} />
                    </ChipWrapper>
                  ))}
              </Row>
            </ListSkillWrapper>
            <Form formId="form-edit-skill" onSubmit={() => onAddSkill(newSkillState)}>
              <Input
                fillBackground
                label={formatMessage({defaultMessage: 'ทักษะพิเศษใหม่'})}
                placeholder={formatMessage({defaultMessage: 'กรอกทักษะพิเศษใหม่'})}
                value={newSkillState}
                onChange={(value) => onChangeText('skill', value)}
              />
              <ButtonIcon small variant="success" edgeInsets="none" iconName={ICONS.PLUS} onClick={() => onAddSkill(newSkillState)}>
                {formatMessage({defaultMessage: 'เพิ่มทักษะพิเศษ'})}
              </ButtonIcon>
            </Form>
          </FormWrapper>
          <FormWrapper marginBottom>
            <Text style={OverrideStyles.textTitle}>{formatMessage({defaultMessage: 'ความสำเร็จ'})}</Text>
            <Divider bold edgeInsets />
            <ListSkillWrapper>
              {typeDef.isNotArrayLength(listAchievementState) && <Text>{`-${formatMessage({defaultMessage: 'ยังไม่มีความสำเร็จ'})}`}</Text>}
              <Row>
                {typeDef.isArrayLength(listAchievementState) &&
                  listAchievementState.map((item, index) => (
                    <ChipWrapper key={index.toString()} onClick={() => onDeleteAchievement(index)}>
                      <ChipLabel>{item}</ChipLabel>
                      <Icon name={ICONS.CLOSE} color="white" size={18} />
                    </ChipWrapper>
                  ))}
              </Row>
            </ListSkillWrapper>
            <Form formId="form-edit-achievement" onSubmit={() => onAddAchievement(newAchievementState)}>
              <Input
                fillBackground
                label={formatMessage({defaultMessage: 'ความสำเร็จใหม่'})}
                placeholder={formatMessage({defaultMessage: 'กรอกความสำเร็จ'})}
                value={newAchievementState}
                onChange={(value) => onChangeText('achievement', value)}
              />
              <ButtonIcon small variant="success" edgeInsets="none" iconName={ICONS.PLUS} onClick={() => onAddAchievement(newAchievementState)}>
                {formatMessage({defaultMessage: 'เพิ่มความสำเร็จ'})}
              </ButtonIcon>
            </Form>
          </FormWrapper>
          <FormWrapper>
            <Button block type="button" style={OverrideStyles.buttonSubmit} onClick={onSubmit}>
              {formatMessage({defaultMessage: 'บันทึกข้อมูล'})}
            </Button>
          </FormWrapper>
        </>
      )}
    </FormCenter>
  );
};

FormSkill.propTypes = {};

export default FormSkill;
