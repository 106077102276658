import {translate} from '../../../res/language/translate';

export const getFieldValue = (value) => {
  if (!value) {
    return translate('profile.emptyInfo');
  }
  return value;
};

export const getSexValue = (value) => {
  switch (value) {
    case 'm':
      return 'ชาย';
    case 'f':
      return 'หญิง';
    default:
      return translate('profile.emptyInfo');
  }
};
