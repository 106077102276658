import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useIntl} from 'react-intl';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import JobPostBuilder from '../../views/job-post-builder';
import Screen from '../../views/screen';
import MetaTags from '../../components/meta-tag';

const combineData = (job, apply, like) =>
  job.map((a) => {
    const ans = {...a, jobLike: !!like[a._id]};
    const applyLog = apply[a._id]?.logs;
    if (applyLog) {
      ans.jobApply = {
        mailSentDate: _.find(applyLog, (b) => b.status === 'mail_sent')?.date,
        resumeReadDate: _.find(applyLog, (b) => b.status === 'resume_downloaded')?.date,
      };
      if (typeof ans.jobApply.mailSentDate === 'string') ans.jobApply.mailSentDate = new Date(ans.jobApply.mailSentDate);
      if (typeof ans.jobApply.resumeReadDate === 'string') ans.jobApply.resumeReadDate = new Date(ans.jobApply.resumeReadDate);
    }
    return ans;
  });

const ApplyJobView = (props) => {
  const {jobs, jobsApply, jobsLike, loading, likeLogic} = props;
  const data = combineData(jobs, jobsApply, jobsLike);
  useSetGrayBackground();

  const {formatMessage} = useIntl();
  const title = formatMessage({defaultMessage: 'JobFinFin - งาน หางาน สมัครงาน อัพเดทงานทุกวันจากบริษัทชั้นนำทั่วไทย'});

  return (
    <>
      <MetaTags title={title} description={title} />
      <Screen noEdgeInsets isChildScreen>
        <JobPostBuilder
          noTopEdgeInsets
          noBottomEdgeInsets
          emptyLabel={formatMessage({defaultMessage: 'ไม่มีข้อมูล เนื่องจากคุณยังไม่ได้สมัครงานบริษัทใดเลย'})}
          data={data}
          isEmptyData={!loading && !jobs.length}
          isLoadingFeed={loading}
          likeLogic={likeLogic}
        />
      </Screen>
    </>
  );
};

ApplyJobView.propTypes = {
  jobs: PropTypes.array.isRequired,
  jobsLike: PropTypes.object.isRequired,
  jobsApply: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  likeLogic: PropTypes.object.isRequired,
};

ApplyJobView.defaultProps = {
  loading: false,
};

export default ApplyJobView;
