import {createServiceURL} from '../../env/utils/env-services';
import {adapterPost} from '../../utils/adapter/post';
import {adapterGet} from '../../utils/adapter/get';

export const userRegisterValidateEmail = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/check_email_user`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const userRegisterRequestOTP = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/otp/request_otp`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const userRegisterVerifyOTP = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/otp/check_otp`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const userRegisterManual = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/user_register_manual`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const userRegisterFacebook = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/user_register_facebook`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const generateId = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/generate_id`;
  const response = await adapterGet({url: API_URL, privateMethod: false});
  return response;
};
