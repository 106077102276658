import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {translate} from '../../../../res/language/translate';
import {OverrideStyles} from './styled-component';
import {ButtonWrapper, ButtonText} from '../styled-component';
import ListTile from '../../../../components/list-tile';
import Link from '../../../../components/link';
import Header from '../../../../components/header';
import Row from '../../../../components/row';
import Icon from '../../../../components/icon';
import StarView from '../star';
import * as ICONS from '../../../../components/icon/constants';
import * as ROUTES from '../../../../routes/routes-name';
import * as mainLogic from '../main-logic';
import * as dateTime from '../../../../utils/date-time';
import * as coreUser from '../../../../lib/context/user';
import {calGeneralInfo} from '../resume-info/logic';

const GeneralInfo = (props) => {
  const {userState} = props;
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      <Row content="space-between">
        <Row>
          <Icon name={ICONS.USER} color={OverrideStyles.iconColor} />
          <Header variant="h4" style={OverrideStyles.header}>
            {translate('profile.infoPersonal.title')}
          </Header>
          <StarView total={20} score={calGeneralInfo(userState) || 0} />
        </Row>

        <Link wrapperChildren url={ROUTES.ROUTE_EDIT_PROFILE_GENERAL}>
          <ButtonWrapper onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            {isHover && <Icon name={ICONS.EDIT_SQUARE} size={16} color={OverrideStyles.iconColor} />}
            <ButtonText>{translate('profile.edit')}</ButtonText>
          </ButtonWrapper>
        </Link>
      </Row>
      <ListTile
        divider
        title={translate('profile.infoPersonal.firstName')}
        description={mainLogic.getFieldValue(userState?.general?.firstname)}
        titleStyle={OverrideStyles.title}
      />
      <ListTile
        divider
        title={translate('profile.infoPersonal.lastName')}
        description={mainLogic.getFieldValue(userState?.general?.lastname)}
        titleStyle={OverrideStyles.title}
      />
      <ListTile
        divider
        title={translate('profile.infoPersonal.sex.title')}
        description={mainLogic.getSexValue(userState?.general?.sex)}
        titleStyle={OverrideStyles.title}
      />
      <ListTile
        divider
        title={translate('profile.infoPersonal.birthDate')}
        description={mainLogic.getFieldValue(dateTime.birthDateFormat({date: userState?.general?.birthday}))}
        titleStyle={OverrideStyles.title}
      />
      <ListTile
        divider
        title={translate('profile.infoPersonal.address')}
        description={mainLogic.getFieldValue(userState?.location?.address)}
        titleStyle={OverrideStyles.title}
      />
      <ListTile
        divider
        title={translate('profile.infoPersonal.province')}
        description={mainLogic.getFieldValue(userState?.location?.province)}
        titleStyle={OverrideStyles.title}
      />
      <ListTile
        divider
        title={translate('profile.infoPersonal.district')}
        description={mainLogic.getFieldValue(coreUser.getDistrict(userState?.location?.district))}
        titleStyle={OverrideStyles.title}
      />
    </>
  );
};

GeneralInfo.propTypes = {
  userState: PropTypes.object,
};

GeneralInfo.defaultProps = {
  userState: {},
};

export default GeneralInfo;
