import styled from 'styled-components';

export const RowWrapper = styled.div`
  display: flex;
  flex-wrap: ${(props) => props.wrap};
  align-items: center;
  justify-content: ${(props) => (props.content ? `${props.content}` : 'space-between')};
  padding: ${(props) => (!props.edgeInsets ? '0' : `2rem`)};
  ${(props) => props.alignStart && `align-items: flex-start`};
`;
