import * as TYPES from './type';

export const updateJobIndex = (payload) => ({
  type: TYPES.SEO_UPDATE_JOB_INDEX_REQ,
  payload: payload,
});

export const updateCompanyIndex = (payload) => ({
  type: TYPES.SEO_UPDATE_COMPANY_INDEX_REQ,
  payload: payload,
});

export const updateCompanyIndexKey = (payload) => ({
  type: TYPES.SEO_UPDATE_COMPANY_INDEX_KEY_REQ,
  payload: payload,
});
