import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 280px;
  width: 320px;
  background-color: ${(props) => props.theme.blue200};
  padding: 2rem;
  margin: 0.5rem;
  border-radius: 8px;
`;

export const WalkThroughIcon = styled.img`
  width: 45px;
  height: 45px;
  fill: ${(props) => props.theme.primary};
`;

export const WalkThroughHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100px;
`;

export const OverrideStyles = {
  icon: {
    fill: lightTheme.primary,
  },
};
