export const jobLevel = [
  {
    label: 'เจ้าหน้าที่',
    value: 'เจ้าหน้าที่',
  },
  {
    label: 'หัวหน้างาน',
    value: 'หัวหน้างาน',
  },
  {
    label: 'ผู้จัดการ/ผู้อาวุโส',
    value: 'ผู้จัดการ/ผู้อาวุโส',
  },
  {
    label: 'ผู้บริหาร',
    value: 'ผู้บริหาร',
  },
];

export const companySizeData = [
  {label: 'เล็ก (ไม่เกิน 50 คน)', value: 'เล็ก'},
  {label: 'กลาง (50 - 200 คน)', value: 'กลาง'},
  {label: 'ใหญ่ (200 คนขึ้นไป)', value: 'ใหญ่'},
];

export const rowWork = {
  company_name: '',
  position: '',
  start: '',
  end: '',
  salary: '',
  company_size: {...companySizeData[0]},
  is_now: false,
  job_description: '',
  address: '',
  province: '',
  district: '',
  job_level: null,
};
