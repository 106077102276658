import * as TYPES from './type';

export const searchLocationNearest = (payload) => ({
  type: TYPES.SEARCH_LOCATION_NEAREST_REQ,
  payload: payload,
});

export const searchCacheLocationNearest = (payload) => ({
  type: TYPES.SEARCH_CACHE_LOCATION_NEAREST_REQ,
  payload: payload,
});

export const searchPopularJobCat = (payload) => ({
  type: TYPES.SEARCH_POPULAR_JOB_CAT_REQ,
  payload: payload,
});

export const searchPopularJobUpdateLocation = (payload) => ({
  type: TYPES.SEARCH_POPULAR_UPDATE_LOCATION_REQ,
  payload: payload,
});

export const searchAllJobCat = () => ({
  type: TYPES.SEARCH_ALL_JOB_CAT_REQ,
});

export const searchJobTimeline = (payload) => ({
  type: TYPES.SEARCH_JOB_TIMELINE_REQ,
  payload: payload,
});

export const searchJobTimelineRefresh = (payload) => ({
  type: TYPES.SEARCH_JOB_TIMELINE_REFRESH_REQ,
  payload: payload,
});

export const searchJobLiked = (payload) => ({
  type: TYPES.SEARCH_JOB_LIKED_REQ,
  payload: payload,
});

export const searchJobLikedRefresh = (payload) => ({
  type: TYPES.SEARCH_JOB_LIKED_REFRESH_REQ,
  payload: payload,
});

export const searchJobLikedClear = () => ({
  type: TYPES.SEARCH_JOB_LIKED_CLEAR_REQ,
});

export const searchJobLikedUnlike = (payload) => ({
  type: TYPES.SEARCH_JOB_LIKED_UNLIKE_REQ,
  payload: payload,
});

export const searchUserLike = (payload) => ({
  type: TYPES.SEARCH_JOB_USER_LIKE_REQ,
  payload: payload,
});

export const searchUserUnlike = (payload) => ({
  type: TYPES.SEARCH_JOB_USER_UNLIKE_REQ,
  payload: payload,
});

export const searchHistory = () => ({
  type: TYPES.SEARCH_JOB_HISTORY_REQ,
});

export const searchHistoryPin = (payload) => ({
  type: TYPES.SEARCH_JOB_PIN_REQ,
  payload: payload,
});

export const searchHistoryDelete = (payload) => ({
  type: TYPES.SEARCH_HISTORY_DELETE_REQ,
  payload: payload,
});

export const searchQuery = (payload) => ({
  type: TYPES.SEARCH_JOB_QUERY_REQ,
  payload: payload,
});

export const searchQueryNearby = (payload) => ({
  type: TYPES.SEARCH_JOB_QUERY_NEARBY_REQ,
  payload: payload,
});

export const searchHashtag = (payload) => ({
  type: TYPES.SEARCH_JOB_HASHTAG_REQ,
  payload: payload,
});

export const searchJobId = (payload) => ({
  type: TYPES.SEARCH_JOB_ID_REQ,
  payload: payload,
});

export const searchSaveForm = (payload) => ({
  type: TYPES.SEARCH_SAVE_FORM_REQ,
  payload: payload,
});

export const searchSeenJob = (payload) => ({
  type: TYPES.SEARCH_SEEN_JOB_REQ,
  payload: payload,
});

export const searchInterestJob = (payload) => ({
  type: TYPES.SEARCH_INTEREST_STREAM_REQ,
  payload: payload,
});

export const searchInterestDebounce = (payload) => ({
  type: TYPES.SEARCH_INTEREST_DEBOUNCE_REQ,
  payload: payload,
});

export const searchGetHiddenJobs = (payload) => ({
  type: TYPES.SEARCH_JOB_HIDDEN_LIST_REQ,
  payload: payload,
});

export const searchHiddenJob = (payload) => ({
  type: TYPES.SEARCH_JOB_HIDDEN_REQ,
  payload: payload,
});

export const searchUnHiddenJob = (payload) => ({
  type: TYPES.SEARCH_JOB_UNHIDDEN_REQ,
  payload: payload,
});

export const searchJobAuthor = (payload) => ({
  type: TYPES.SEARCH_JOB_AUTHOR_REQ,
  payload: payload,
});

export const searchJobGroupGetParams = (payload) => ({
  type: TYPES.SEARCH_JOB_GROUP_PARAMS_REQ,
  payload: payload,
});

export const searchJobGroup = (payload) => ({
  type: TYPES.SEARCH_JOB_GROUP_REQ,
  payload: payload,
});

export const searchJobGroupRefresh = (payload) => ({
  type: TYPES.SEARCH_JOB_GROUP_REFRESH_REQ,
  payload: payload,
});

export const searchJobGroupUpdateFilter = (payload) => ({
  type: TYPES.SEARCH_JOB_GROUP_UPDATE_FILTER_REQ,
  payload: payload,
});

export const searchJobGroupList = (payload) => ({
  type: TYPES.SEARCH_JOB_GROUP_LIST_REQ,
  payload: payload,
});

export const searchJobQueryStoreParams = (payload) => ({
  type: TYPES.SEARCH_JOB_QUERY_STORE_PARAMS_REQ,
  payload: payload,
});
