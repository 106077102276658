import React from 'react';
import PropTypes from 'prop-types';
import {OverrideStyles, NotifyHeaderLeft, PrimaryText, SecondaryText} from '../styled-component';
import Row from '../../../components/row';
import Column from '../../../components/column';
import Icon from '../../../components/icon';
import * as logic from '../logic';

const NotifyHeader = (props) => {
  const {type, title, notifyDate} = props;
  return (
    <Row alignStart wrap="nowrap" content="space-between" style={OverrideStyles.rowHeader}>
      <NotifyHeaderLeft>
        <Icon name={logic.getIconName(type)} color={logic.getIconColor(type)} size={38} />
        <Column>
          <PrimaryText>{title}</PrimaryText>
          <SecondaryText>{notifyDate}</SecondaryText>
        </Column>
      </NotifyHeaderLeft>
    </Row>
  );
};

NotifyHeader.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  notifyDate: PropTypes.string,
};

NotifyHeader.defaultProps = {
  notifyDate: '',
};
export default NotifyHeader;
