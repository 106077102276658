import React, {useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import ProtectedRoute from './protected-route';
import MasterContactScreen from '../screens/master-contacts';
import FAQScreen from '../screens/faq';
import LoginScreen from '../screens/login';
import RegisterScreen from '../screens/register';
import ResetPasswordScreen from '../screens/reset-password';
import OTPScreen from '../screens/otp';
import SearchIdScreen from '../screens/search-id';
import IndexScreen from '../screens';
import Screen404 from '../screens/404';
import LandingGroupScreen from '../screens/landing-group';
import LikedScreen from '../screens/liked';
import NotifyScreen from '../screens/notify';
import MenuScreen from '../screens/menu';
import ProfileScreen from '../screens/profile';
import SettingScreen from '../screens/settings';
import EditProfileScreen from '../screens/edit-profile';
import SearchResultScreen from '../screens/search-result';
import NoInternetScreen from '../screens/no-internet';
import SearchAuthorScreen from '../screens/search-author';
import SearchHashtagScreen from '../screens/search-hashtag';
import SearchScreen from '../screens/search';
import ApplyJobScreen from '../screens/apply-job';
import JobCategoryScreen from '../screens/job-category';
import LandingListScreen from '../screens/landing-list';
import CompanyProfileScreen from '../screens/company-profile';
import FacebookLoginScreen from '../screens/facebook-login';
import {page} from '../utils/mixpanel';
import JobIndexScreen from '../screens/job-index';
import CompanyIndexScreen from '../screens/company-index';
import PrivacyAndPolicyScreen from '../screens/term/privacy-policy';
import CookiesPolicyScreen from '../screens/term/cookies';
import ManageNotify from '../screens/notify-manage';
import AcceptPolicy from '../screens/accept-policy';
import * as ROUTES from './routes-name';

const Routes = () => {
  const history = useHistory();
  useEffect(() => {
    return history.listen(({pathname}) => {
      page(pathname);
    });
  }, [history]);

  return (
    <Switch>
      <ProtectedRoute exact main path={ROUTES.ROUTE_MAIN} component={IndexScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_COMPANY_PROFILE} component={CompanyProfileScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_SEARCH_BY_ID} component={SearchIdScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_LANDING_GROUP} component={LandingGroupScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_SEARCH_RESULTS} component={SearchResultScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_SEARCH_AUTHOR} component={SearchAuthorScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_SEARCH_HASHTAG} component={SearchHashtagScreen} />
      <Route exact path={ROUTES.ROUTE_NO_INTERNET} component={NoInternetScreen} />
      <Route exact path={ROUTES.ROUTE_FAQ} component={FAQScreen} />
      <Route exact path={ROUTES.ROUTE_CONTACT} component={MasterContactScreen} />
      <Route exact path={ROUTES.ROUTE_LOGIN} component={LoginScreen} />
      <Route exact path={ROUTES.ROUTE_REGISTER} component={RegisterScreen} />
      <Route exact path={ROUTES.ROUTE_FORGOT_PASSWORD} component={ResetPasswordScreen} />
      <Route exact path={ROUTES.ROUTE_SEARCH} component={SearchScreen} />
      <Route exact protectedVerify path={ROUTES.ROUTE_FACEBOOK_LOGIN} component={FacebookLoginScreen} />
      <Route exact path={ROUTES.ROUTE_JOB_INDEX} component={JobIndexScreen} />
      <Route exact path={ROUTES.ROUTE_COMPANY_INDEX} component={CompanyIndexScreen} />
      <Route exact path={ROUTES.ROUTE_COMPANY_INDEX_TH} component={CompanyIndexScreen} />
      <Route exact path={ROUTES.ROUTE_COMPANY_INDEX_EN} component={CompanyIndexScreen} />
      <Route exact path={ROUTES.ROUTE_COMPANY_INDEX_NUMBER} component={CompanyIndexScreen} />
      <Route exact path={ROUTES.ROUTE_TERM_AND_AGREEMENT} component={PrivacyAndPolicyScreen} />
      <Route exact path={ROUTES.ROUTE_COOKIE_POLICY} component={CookiesPolicyScreen} />
      <Route exact path={ROUTES.ROUTE_NOTIFY_MANAGE} component={ManageNotify} />
      <Route exact path={ROUTES.ROUTE_GROUP_LIST} component={LandingListScreen} />
      <ProtectedRoute exact protectedVerify path={ROUTES.ROUTE_OTP} component={OTPScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_LIKE} component={LikedScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_NOTIFY} component={NotifyScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_MENU} component={MenuScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_PROFILE} component={ProfileScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_SETTING} component={SettingScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_USER_CHANGE_PASSWORD} component={EditProfileScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_EDIT_PROFILE_GENERAL} component={EditProfileScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_EDIT_PROFILE_EDUCATION} component={EditProfileScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_EDIT_PROFILE_SKILL} component={EditProfileScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_EDIT_PROFILE_WORKS} component={EditProfileScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_EDIT_PROFILE_INTEREST} component={EditProfileScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_EDIT_PROFILE_OTHER} component={EditProfileScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_APPLY_JOB} component={ApplyJobScreen} />
      <ProtectedRoute verifyPolicy exact path={ROUTES.ROUTE_JOB_CATEGORY} component={JobCategoryScreen} />
      <ProtectedRoute exact acceptPolicy path={ROUTES.ROUTE_ACCEPT_POLICY} component={AcceptPolicy} />
      <Route component={Screen404} />
    </Switch>
  );
};

export default Routes;
