import styled from 'styled-components';
import checkboxTheme from '../../styles/checkbox';
import layoutTheme from '../../styles/layout';

export const CheckboxContainer = styled.div`
  display: inline-flex;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  height: ${checkboxTheme.size.inputHeight}px;
  align-items: center;
  margin-right: ${layoutTheme.margin.content}px;
  overflow: hidden;
`;

export const BoxBackdrop = styled.div`
  height: ${checkboxTheme.size.inputHeight}px;
  width: ${checkboxTheme.size.inputWidth}px;
  border-radius: ${checkboxTheme.size.inputWidth / 2}px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: ${checkboxTheme.size.inputHeight}px;
  &:hover {
    background-color: ${(props) => props.theme.tinGrayAlpha};
  }
`;

export const Box = styled.span`
  position: 'absolute';
  left: '9px';
`;

export const CheckboxInput = styled.input`
  display: none;
`;

export const CheckboxLabel = styled.span`
  margin-bottom: -3px;
  margin-right: 8px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/** fullText */
export const CheckboxFullTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding-right: ${layoutTheme.margin.content}px;
  padding-bottom: 0.75rem;
`;

export const BoxBackdropFullText = styled.div`
  display: flex;
  min-width: 30px;
`;

export const CheckboxFullLabel = styled.span`
  margin-bottom: -3px;
  margin-right: 8px;
  display: block;
  white-space: pre-line;
`;
