import lodash from 'lodash';

export const checkUserAcceptedPolicy = (userPolicyState) => {
  if (!Array.isArray(userPolicyState)) {
    return false;
  }
  const orderedVersion = lodash.orderBy(userPolicyState, 'version', 'desc');
  const isAccepted = lodash.get(orderedVersion, '[0].is_agreed', false);
  return isAccepted;
};
