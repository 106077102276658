import {useEffect, useState} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import get from 'lodash/get';
import {JOB_PAGE_SIZE} from '../../context/pagination';
import * as searchActions from '../../../redux/search/action';
import * as typeDef from '../../../utils/strong-types';

export const useSearchTimelineState = () => {
  const [isEmptyDataState, setIsEmptyDataState] = useState(false);
  const [isCanFetchMoreState, setIsCanFetchMoreState] = useState(true);

  const {
    timeline: {stackFeed: stackFeedState, newFeed: newFeedState},
    pageIndex: pageIndexState,
    loading: isLoadingState,
    loadingRefresh: isLoadingRefreshState,
    error: errorState,
  } = useSelector((state) => state.search.jobTimeline, shallowEqual);

  useEffect(() => {
    if (!isLoadingState && newFeedState) {
      if (newFeedState.resultCount === 0) {
        setIsEmptyDataState(true);
      } else {
        setIsEmptyDataState(false);
      }
    }
  }, [isLoadingState, newFeedState]);

  useEffect(() => {
    if (isCanFetchMoreState && newFeedState?.resultCount && Array.isArray(stackFeedState)) {
      if (stackFeedState.length >= newFeedState?.resultCount) {
        setIsCanFetchMoreState(false);
      }
    }
  }, [newFeedState, stackFeedState, isCanFetchMoreState]);

  return {
    isEmptyDataState,
    stackFeedState,
    newFeedState,
    pageIndexState,
    isLoadingState,
    isLoadingRefreshState,
    errorState,
    isCanFetchMoreState,
  };
};

export const useSearchTimelineSingleState = (name) => {
  const singleState = useSelector((state) => {
    const res = get(state, `search.jobTimeline.${name}`);
    return res;
  }, shallowEqual);
  return {singleState};
};

export const useSearchTimelineEffect = () => {
  const dispatch = useDispatch();

  const {
    timeline: {newFeed: newFeedState},
    loading: isLoadingState,
    loadingRefresh: isLoadingRefreshState,
  } = useSelector((state) => state.search.jobTimeline, shallowEqual);

  useEffect(() => {
    const beginInvoke = isLoadingState || isLoadingRefreshState;
    if (!typeDef.isArray(newFeedState?.results) && !beginInvoke) {
      const reqBody = {
        params: {},
        pageIndex: 0,
        pageSize: JOB_PAGE_SIZE,
      };
      dispatch(searchActions.searchJobTimeline({data: reqBody}));
    }
  }, [newFeedState, isLoadingState, isLoadingRefreshState, dispatch]);
};

export const useSearchTimelineDispatch = () => {
  const dispatch = useDispatch();

  const dispatchFetchSearchTimeline = (pageIndex) => {
    if (!pageIndex) {
      return;
    }
    const reqBody = {
      params: {},
      pageIndex,
      pageSize: JOB_PAGE_SIZE,
    };
    dispatch(searchActions.searchJobTimeline({data: reqBody}));
  };

  const dispatchFetchRefreshTimeline = () => {
    const reqBody = {
      params: {},
      pageIndex: 0,
      pageSize: JOB_PAGE_SIZE,
    };
    dispatch(searchActions.searchJobTimelineRefresh({data: reqBody}));
  };

  return {
    dispatchFetchSearchTimeline,
    dispatchFetchRefreshTimeline,
  };
};
