import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useLogin, useLoginEffect} from '../../lib/hooks/logic/useLogin';
import {Container} from './views/styled-component';
import NavBar from './views/nav-bar';
import SidePresent from './views/side-present';
import LoginForm from './views/form';
import Column from '../../components/column';
import MetaTag from '../../components/meta-tag';
import Loading from '../../components/loading';
import * as platform from '../../utils/platform';

const KEY_RD = '?rd_path=true';

const LoginScreen = () => {
  useLoginEffect();
  const history = useHistory();
  const {formatMessage} = useIntl();

  const {isLoadingState, dispatchLogin} = useLogin();
  const callbackPath = useSelector((state) => state.context.callbackPath);

  const onEventSubmit = (formValues) => {
    const reqBody = {
      email: formValues.email,
      password: formValues.password,
      platform: platform.getPlatform(),
    };
    dispatchLogin({data: reqBody});
  };

  useEffect(() => {
    if (callbackPath?.pathname && history) {
      history.replace({pathname: history.location.pathname, search: KEY_RD});
    }
  }, [callbackPath, history]);

  return (
    <Container>
      <MetaTag
        title={formatMessage({defaultMessage: 'เข้าสู่ระบบ - JobFinFin'})}
        description={formatMessage({defaultMessage: 'งาน หางาน สมัครงาน - JobFinFin'})}
      />
      <Loading loading={isLoadingState} />
      <SidePresent />
      <Column>
        <NavBar />
        <LoginForm onSubmit={onEventSubmit} />
      </Column>
    </Container>
  );
};

LoginScreen.propTypes = {};

LoginScreen.defaultProps = {};

export default LoginScreen;
