import styled from 'styled-components';
import buttonTheme from '../../styles/button';
import colors from '../../styles/color';
import layout from '../../styles/layout';

const StyledButtonBase = styled.div`
  cursor: pointer;
  display: flex;
  background-color: ${colors.button.primary};
  height: ${buttonTheme.size.defaultHeight}px;
  padding: 0px ${buttonTheme.size.defaultHorizontalPadding}px;
  border-radius: ${buttonTheme.size.borderRadius}px;
  color: ${colors.button.textWhite};
  border: none;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: 'Prompt Light';
  &:hover {
    background-color: ${colors.button.primaryHover};
  }
`;

export const StyledButtonModify = styled(StyledButtonBase)`
  width: fit-content;
  font-size: ${(props) => (props.small ? '13px;' : '16px;')};
  ${(props) => props.edgeInsets === layout.edgeInsets.all && `margin: 8px;`};
  ${(props) => props.edgeInsets === layout.edgeInsets.vertical && `margin: 0px 8px;`};
  ${(props) => props.edgeInsets === layout.edgeInsets.horizontal && `margin: 8px 0px;`};
  ${(props) => (props.block ? `display: flex; width: 100%; margin: 0px;` : `width: fit-content;`)};
  ${(props) =>
    props.small &&
    `height:${buttonTheme.size.smallHeight}px; 
    border-radius: ${buttonTheme.size.smallBorderRadius}px;
    padding: 0px ${buttonTheme.size.smallHorizontalPadding}px;`}
  ${(props) =>
    !props.nested && !props.small
      ? `min-width: ${buttonTheme.size.fixMinWidth}px;`
      : !props.nested && props.small && `min-width: ${buttonTheme.size.fixSmallMinWidth}px;`};
  ${(props) => props.disabled && `cursor: default;`};
`;

export const StyledButtonPrimary = styled(StyledButtonModify)`
  background-color: ${colors.button.primary};
  &:hover {
    background-color: ${(props) => (props.outline || props.disabled ? `${colors.button.primary}` : `${colors.button.primaryHover}`)};
    ${(props) => props.outline && `color: ${colors.button.textWhite}`}
  }
  ${(props) =>
    props.outline &&
    `background-color: ${colors.button.transparent};
   border: 1.5px solid ${colors.button.primary};
   color: ${colors.button.primary}`};
`;

export const StyledButtonSuccess = styled(StyledButtonModify)`
  background-color: ${colors.button.success};
  &:hover {
    background-color: ${(props) => (props.outline ? `${colors.button.success}` : `${colors.button.successHover}`)};
    ${(props) => props.outline && `color: ${colors.button.textWhite}`}
  }
  ${(props) =>
    props.outline &&
    `background-color: ${colors.button.transparent};
   border: 1.5px solid ${colors.button.success};
   color: ${colors.button.success}`};
`;

export const StyledButtonDanger = styled(StyledButtonModify)`
  background-color: ${colors.button.danger};
  &:hover {
    background-color: ${(props) => (props.outline ? `${colors.button.danger}` : `${colors.button.dangerHover}`)};
    ${(props) => props.outline && `color: ${colors.button.textWhite}`}
  }
  ${(props) =>
    props.outline &&
    `background-color: ${colors.button.transparent};
   border: 1.5px solid ${colors.button.danger};
   color: ${colors.button.danger}`};
`;

export const StyledButtonTransparent = styled(StyledButtonModify)`
  background-color: ${colors.button.transparent};
  color: ${colors.button.textBlack};
  &:hover {
    background-color: transparent;
  }
`;

export const StyledButtonLight = styled(StyledButtonModify)`
  background-color: ${colors.button.light};
  color: ${colors.button.textBlack};
  &:hover {
    background-color: ${colors.button.lightHover};
  }
  ${(props) =>
    props.outline &&
    `background-color: ${colors.button.transparent};
   border: 1.5px solid ${colors.button.light};
   `};
`;

export const StyledButtonLighter = styled(StyledButtonLight)`
  color: #606770;
`;

export const ButtonLabel = styled.span`
  padding-left: 0.5rem;
`;

export const OverrideStyles = {
  iconColorWhite: colors.global.white,
  iconColorBlack: colors.global.textBlack,
  iconColorLighter: '#606770',
};
