const TabHeader = {
  height: 50,
};

const NavigationHeader = {
  height: 56,
  search: 35,
};

const NavigationBottom = {
  height: 56,
};

const NavigationHeadShadow = {
  boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.25)',
  boxShadow2: 'rgba(137, 143, 156, 0.5) 0.2px 0.2px 2px',
  boxShadowContent: 'box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px',
  boxShadowBottomView: '0 2px 4px -1px rgba(0, 0, 0, 0.25)',
};

const NavigationTheme = {
  TabHeader,
  NavigationHeader,
  NavigationBottom,
  NavigationHeadShadow,
};

export default NavigationTheme;
