import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {translate} from '../../../res/language/translate';
import {TextForgot, ForgotWrapper, OverrideStyles} from './styled-component';
import AppLogo from '../../../views/logo/logo';
import Form from '../../../components/form';
import Button from '../../../components/button';
import ButtonIcon from '../../../components/button-icon';
import Input from '../../../components/input';
import Text from '../../../components/text';
import Link from '../../../components/link';
import FormWrapper from '../../../views/form';
import * as ICONS from '../../../components/icon/constants';
import * as validate from './validate';
import * as ROUTES from '../../../routes/routes-name';
import * as navigation from '../../../utils/navigation/navigation-services';

const LoginForm = (props) => {
  const {onSubmit} = props;

  const {formatMessage} = useIntl();

  const [emailState, setEmailState] = useState('');
  const [passwordState, setPasswordState] = useState('');
  const [errorTexts, setErrorTexts] = useState({email: '', password: ''});

  const handlerOnSubmit = (formValues) => {
    const {emailResult, passwordResult} = validate.validateLoginForm({...formValues});
    const isValidate = !emailResult && !passwordResult;
    if (!isValidate) {
      const errorMsg = {};
      if (emailResult) {
        errorMsg.email = emailResult;
      }
      if (passwordResult) {
        errorMsg.password = passwordResult;
      }
      setErrorTexts(errorMsg);
      return;
    }
    onSubmit(formValues);
  };

  const onChangeError = (key, value) => {
    const newError = {...errorTexts};
    newError[key] = value;
    setErrorTexts(newError);
  };

  const onChangeText = (key, value) => {
    onChangeError(key, '');
    if (key === 'email') {
      setEmailState(value);
      return;
    }
    setPasswordState(value);
  };

  const onEventLoginWithFacebook = () => {
    navigation.navigateTo({pathname: ROUTES.ROUTE_FACEBOOK_LOGIN});
  };

  return (
    <FormWrapper>
      <AppLogo ratio={1.8} />
      <ButtonIcon block iconName={ICONS.FACEBOOK_LOGIN} style={OverrideStyles.btnFacebook} onClick={onEventLoginWithFacebook}>
        {formatMessage({defaultMessage: 'เข้าสู่ระบบด้วย Facebook'})}
      </ButtonIcon>
      <Text style={OverrideStyles.textOption}>{translate('login.textLoginOption')}</Text>
      <Form formId="form-login" style={OverrideStyles.formLogin} onSubmit={handlerOnSubmit}>
        <Input
          fillBackground
          name="email"
          type="email"
          iconName={ICONS.EMAIL_FILL}
          placeholder={translate('login.fieldEmail')}
          value={emailState}
          onChange={(value) => onChangeText('email', value)}
          warningText={errorTexts.email}
        />
        <Input
          fillBackground
          name="password"
          type="password"
          iconName={ICONS.PASSWORD_FILL}
          placeholder={translate('login.fieldPassword')}
          value={passwordState}
          onChange={(value) => onChangeText('password', value)}
          warningText={errorTexts.password}
        />
        <ForgotWrapper>
          <Link url={ROUTES.ROUTE_FORGOT_PASSWORD}>
            <TextForgot>{translate('login.buttonForgotPassword')}</TextForgot>
          </Link>
        </ForgotWrapper>

        <Button block type="submit">
          {translate('login.buttonLoginWithManual')}
        </Button>
      </Form>
    </FormWrapper>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {};

export default LoginForm;
