import * as TYPES from './type';

const initNotifyList = {
  error: null,
  loading: false,
  notify: null,
  userNotification: null,
  countNew: null,
};

const initNotifySettings = {
  error: null,
  loading: false,
  loadingNotifyState: false,
  setting: {
    frequency: 1,
    is_read_resume_notify: false,
    is_recommended_job_notify: false,
    is_email_notify: false,
  },
  modal: {
    visible: false,
    isEdit: false,
  },
  interestedJobForm: {
    search: null,
    district: null,
    province: null,
    jobcat: null,
    subjobcat: null,
    author_name: null,
  },
};

const initManageNotifyState = {
  get: {
    loading: false,
    data: null,
    error: null,
  },
  update: {
    loading: false,
    result: null,
    error: null,
  },
};

export const notifyListReducer = (state = initNotifyList, {type, payload}) => {
  switch (type) {
    case TYPES.NOTIFY_LIST_REQ:
      return {...state, loading: true, error: null};
    case TYPES.NOTIFY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        notify: payload?.notifications,
        userNotification: payload?.userNoti,
      };
    case TYPES.NOTIFY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case TYPES.NOTIFY_COUNT_NEW_REQ:
      return {...state, loading: true, error: null};
    case TYPES.NOTIFY_COUNT_NEW_SUCCESS:
      return {...state, loading: false, countNew: payload};
    case TYPES.NOTIFY_COUNT_NEW_FAIL:
      return {...state, loading: false, countNew: payload};
    default:
      return state;
  }
};

export const notifySettingsReducer = (state = initNotifySettings, {type, payload}) => {
  switch (type) {
    case TYPES.NOTIFY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        setting: {
          ...state.setting,
          frequency: payload?.userNoti?.frequency,
          is_read_resume_notify: payload?.userNoti?.is_read_resume_notify,
          is_recommended_job_notify: payload?.userNoti?.is_recommended_job_notify,
          is_email_notify: payload?.userNoti?.is_email_notify,
        },
        interestedJobForm: {...state.interestedJobForm, ...payload?.userNoti?.search_params},
      };

    case TYPES.NOTIFY_SETTING_REQ:
      return {...state, loading: true, error: null};

    case TYPES.NOTIFY_SETTING_SUCCESS:
      return {...state, loading: false, setting: {...state.setting, ...payload}};

    case TYPES.NOTIFY_SETTING_FAIL:
      return {...state, loading: false, error: payload};

    case TYPES.NOTIFY_UPDATE_SETTING_REQ:
      return {...state, loading: true};

    case TYPES.NOTIFY_UPDATE_SETTING_SUCCESS:
      return {...state, loading: false, setting: payload};

    case TYPES.NOTIFY_UPDATE_SETTING_FAIL:
      return {...state, loading: false, error: payload};

    case TYPES.NOTIFY_UPDATE_NOTIFICATION_SETTING_REQ:
      return {...state, loadingNotifyState: true};

    case TYPES.NOTIFY_UPDATE_NOTIFICATION_SETTING_SUCCESS:
      return {...state, loadingNotifyState: false, loading: false, setting: payload};

    case TYPES.NOTIFY_UPDATE_NOTIFICATION_SETTING_FAIL:
      return {...state, loadingNotifyState: false, loading: false, error: payload};

    case TYPES.NOTIFY_SETTING_MODAL_SUCCESS:
      return {...state, modal: payload?.data};

    case TYPES.NOTIFY_SAVE_FORM_SUCCESS:
      return {...state, interestedJobForm: {...state.interestedJobForm, ...payload?.data}};

    case TYPES.NOTIFY_SAVE_FORM_CLEAR:
      return {...state, interestedJobForm: {...state.interestedJobForm, ...initNotifySettings?.interestedJobForm}};

    case TYPES.NOTIFY_UPDATE_INTERESTED_JOB_SUCCESS:
      return {...state, loading: false, modal: {visible: false, isEdit: false}};

    case TYPES.NOTIFY_UPDATE_INTERESTED_JOB_FAIL:
      return {...state, loading: false, error: payload};

    default:
      return state;
  }
};

export const manageNotifyReducer = (state = initManageNotifyState, {type, payload}) => {
  switch (type) {
    case TYPES.NOTIFY_GET_DATA_BY_SIGNED_URL_REQ:
      return {...state, get: {...state.get, loading: true, error: null}};
    case TYPES.NOTIFY_GET_DATA_BY_SIGNED_URL_SUCCESS:
      return {...state, get: {...state.get, loading: false, data: payload}};
    case TYPES.NOTIFY_GET_DATA_BY_SIGNED_URL_FAIL:
      return {...state, get: {...state.get, loading: false, error: payload}};
    case TYPES.NOTIFY_GET_DATA_BY_SIGNED_URL_CLEAR:
      return {...state};
    case TYPES.NOTIFY_UPDATE_DATA_BY_SIGNED_URL_REQ:
      return {...state, upload: {...state.upload, loading: false, error: null}};
    case TYPES.NOTIFY_UPDATE_DATA_BY_SIGNED_URL_SUCCESS:
      return {...state, upload: {...state.upload, loading: false, result: payload}};
    case TYPES.NOTIFY_UPDATE_DATA_BY_SIGNED_URL_FAIL:
      return {...state, upload: {...state.upload, loading: false, error: null}};
    default:
      return {...state};
  }
};
