import * as typeDef from '../../../utils/strong-types';
import * as TYPES from '../../../lib/context/images/img-content-type';
import imageSize from '../../../styles/image';

const calVerticalChildWidth = ({contextWidth}) => {
  return (contextWidth * 32.9647) / 100;
};

export const isVertical = ({type}) => {
  switch (type) {
    case TYPES.CONTENT_VERTICAL_1X1:
    case TYPES.CONTENT_VERTICAL_1X2:
    case TYPES.CONTENT_VERTICAL_1X3:
    case TYPES.CONTENT_VERTICAL_1XN:
      return true;
    default:
      return false;
  }
};

const createImage2length = ({images}) => {
  if (images[0].height >= images[0].width) {
    return TYPES.CONTENT_VERTICAL_1X1;
  }
  return TYPES.CONTENT_HORIZONTAL_1X1;
};

const createImage3length = ({images}) => {
  if (images[0].height >= images[0].width) {
    return TYPES.CONTENT_VERTICAL_1X2;
  }
  return TYPES.CONTENT_HORIZONTAL_1X2;
};

const createImage4length = ({images}) => {
  if (images[0].height >= images[0].width) {
    return TYPES.CONTENT_VERTICAL_1X3;
  }
  return TYPES.CONTENT_HORIZONTAL_1X3;
};

const createImage5length = ({images}) => {
  if (images[0].height >= images[0].width) {
    return TYPES.CONTENT_VERTICAL_1XN;
  }
  if (images[0].width === images[1].width) {
    return TYPES.CONTENT_HORIZONTAL_2X3;
  }
  return TYPES.CONTENT_HORIZONTAL_1XN;
};

const createImageMoreLength = ({images}) => {
  if (images[0].height >= images[0].width) {
    return TYPES.CONTENT_VERTICAL_1XN;
  }
  if (images[0].width === images[1].width) {
    return TYPES.CONTENT_HORIZONTAL_2XN;
  }
  return TYPES.CONTENT_HORIZONTAL_1XN;
};

export const calImage1X2Horizontal = ({contextWidth}) => {
  if (contextWidth >= imageSize.maxWidth) {
    const width = (imageSize.maxWidth - imageSize.space) / 2;
    const height = width;
    return {width, height};
  }
  const width = (contextWidth - imageSize.space) / 2;
  const height = width;
  return {width, height};
};

export const calImage1X3NHorizontal = ({contextWidth}) => {
  if (contextWidth >= imageSize.maxWidth) {
    const width = (imageSize.maxWidth - imageSize.space * 2) / 3;
    const height = imageSize.childHorizontal3;
    return {width, height};
  }
  const width = (contextWidth - imageSize.space * 2) / 3;
  const height = width;
  return {width, height};
};

export const calImage2X3NHorizontal = ({contextWidth, row, imgWidth, imgHeight}) => {
  if (contextWidth >= imageSize.maxWidth) {
    let width = 0;
    if (row === 1) {
      width = (imageSize.maxWidth - imageSize.space) / 2;
    } else {
      width = (imageSize.maxWidth - imageSize.space * 2) / 3;
    }
    const percent = (width * 100) / imgWidth;
    const _width = (imgWidth * percent) / 100;
    const _height = (imgHeight * percent) / 100;
    return {width: _width, height: _height};
  }
  let width = 0;
  if (row === 1) {
    width = (contextWidth - imageSize.space) / 2;
  } else {
    width = (contextWidth - imageSize.space * 2) / 3;
  }
  const height = width;
  return {width, height};
};

export const calImage1X1Vertical = ({contextWidth, imgWidth, imgHeight}) => {
  if (contextWidth >= imageSize.maxWidth) {
    const percent = (((imageSize.maxWidth - imageSize.space) / 2) * 100) / imgWidth;
    const _width = (imgWidth * percent) / 100;
    const _height = (imgHeight * percent) / 100;
    return {width: _width, height: _height};
  }
  const percent = (((contextWidth - imageSize.space) / 2) * 100) / imgWidth;
  const _width = (imgWidth * percent) / 100;
  const _height = (imgHeight * percent) / 100;
  return {width: _width, height: _height};
};

export const calImage1X2Vertical = ({contextWidth, imgWidth, imgHeight, col, parentHeight}) => {
  if (contextWidth >= imageSize.maxWidth) {
    if (col === 1) {
      const percent = (((imageSize.maxWidth - imageSize.space) / 2) * 100) / imgWidth;
      const _width = (imgWidth * percent) / 100;
      const _height = (imgHeight * percent) / 100;
      return {width: _width, height: _height};
    }
    const width = (imageSize.maxWidth - imageSize.space) / 2;
    const height = parentHeight / 2;
    return {width, height};
  }
  if (col === 1) {
    const percent = (((contextWidth - imageSize.space) / 2) * 100) / imgWidth;
    const _width = (imgWidth * percent) / 100;
    const _height = (imgHeight * percent) / 100;
    return {width: _width, height: _height};
  }
  const width = (contextWidth - imageSize.space) / 2;
  const height = parentHeight / 2;
  return {width, height};
};

export const calImage1X3NVertical = ({contextWidth, imgWidth, imgHeight, col, parentHeight}) => {
  if (contextWidth >= imageSize.maxWidth) {
    if (col === 1) {
      const percent = ((imageSize.maxWidth - imageSize.childHorizontal3 - imageSize.space) * 100) / imgWidth;
      const _width = (imgWidth * percent) / 100;
      let _height = (imgHeight * percent) / 100;
      if (_height > imageSize.parentVertical3) {
        _height = imageSize.parentVertical3;
      }
      return {width: _width, height: _height};
    }
    const width = imageSize.childHorizontal3;
    const height = parentHeight / 3;
    return {width, height};
  }
  if (col === 1) {
    const percent = ((contextWidth - calVerticalChildWidth({contextWidth}) - imageSize.space) * 100) / imgWidth;
    const _width = (imgWidth * percent) / 100;
    let _height = (imgHeight * percent) / 100;
    if (_height > imageSize.parentVertical3) {
      _height = imageSize.parentVertical3;
    }
    return {width: _width, height: _height};
  }
  const width = calVerticalChildWidth({contextWidth}) - imageSize.space;
  const height = parentHeight / 3;
  return {width, height};
};

export const isPoorImage = ({images}) => {
  if (typeDef.isNotArrayLength(images)) {
    return true;
  }
  if (images.length > 1) {
    return false;
  }
  if (images[0].width <= 250 && images[0].height <= 250) {
    return true;
  }
  if (images[0].height <= 250 && images[0].width >= 350) {
    return false;
  }
  return false;
};

export const createImageLayout = ({images}) => {
  if (typeDef.isNotArrayLength(images)) {
    return null;
  }
  switch (images.length) {
    case 1:
      return TYPES.CONTENT_X1;
    case 2:
      return createImage2length({images});
    case 3:
      return createImage3length({images});
    case 4:
      return createImage4length({images});
    case 5:
      return createImage5length({images});
    default:
      return createImageMoreLength({images});
  }
};
