import {useEffect, useState} from 'react';

export const useNetworkStatus = () => {
  const [isOnlineState, setIsOnlineState] = useState(navigator.onLine);

  useEffect(() => {
    function changeStatus() {
      setIsOnlineState(navigator.onLine);
    }
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, []);
  return {
    isOnlineState,
  };
};
