import React from 'react';
import {translate} from '../../../../res/language/translate';
import MetaTag from '../../../../components/meta-tag';
import Screen from '../../../../views/screen';
import Header from '../../../../components/header';
import Divider from '../../../../components/divider';
import EditEducationForm from './form';

const EditEducationView = () => {
  return (
    <>
      <MetaTag title={translate('meta.editEducation')} description={translate('meta.editEducation')} />
      <Screen leftSidebar isChildScreen type="single-fluid">
        <Header variant="h3" fitLine>
          {translate('screenTitle.editEducation')}
        </Header>
        <Divider bold edgeInsets />
        <EditEducationForm />
      </Screen>
    </>
  );
};
//
EditEducationView.propTypes = {};

export default EditEducationView;
