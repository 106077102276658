import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useClickOutsideEffect} from '../../lib/hooks/useClickOutSideEffect';
import {
  Label,
  InputWrapper,
  MultipleContainer,
  VerticalLine,
  SelectBox,
  RightWrapper,
  OptionAbsolute,
  MultipleBox,
  OptionAbsoluteItem,
  ChipItem,
  ChipItemText,
  PlaceholderText,
  PlaceholderWrapper,
  SelectedItemWrapper,
  SelectedItemText,
  InputHint,
  OverrideStyles,
} from './styled-component';
import Icon from '../../components/icon';
import Row from '../../components/row';
import * as typeDef from '../../utils/strong-types';
import * as arrayState from '../../lib/context/array-state';
import * as ICONS from '../../components/icon/constants';

const OptionBuilder = (props) => {
  const {data, name, label, value, onChange, multiple, onChangeDel, placeholder, setDefaultValue, rightLabelComponent, style, warningText, noArrow} =
    props;
  const nodeViewAbsoluteRef = useRef(null);
  const [isVisibleState, setIsVisibleState] = useState(false);
  const [renderDataState, setRenderDataState] = useState();
  const {formatMessage} = useIntl();

  useEffect(() => {
    if (multiple && Array.isArray(data) && Array.isArray(value)) {
      const renderData = arrayState.setNewDifferenceValue(data, value);
      setRenderDataState(renderData);
    }
  }, [multiple, data, value]);

  useEffect(() => {
    if (typeDef.isNullOrUndefined(value) && Array.isArray(data) && setDefaultValue) {
      onChange(data[0]);
    }
  }, [value, data, setDefaultValue]);

  const onEventSetVisibleAbsoluteView = (visible) => {
    if (typeof visible === 'boolean') {
      setIsVisibleState(visible);
    }
  };

  const onEventSelectedItem = (item) => {
    setIsVisibleState(false);
    if (typeDef.isString(value)) {
      onChange(item.value);
    }
    onChange(item);
  };

  const onEventChangeDel = (event, item) => {
    event.stopPropagation();
    onChangeDel(item);
  };

  const onEventClickDropDown = (event) => {
    event.preventDefault();
    if (isVisibleState) {
      setIsVisibleState(false);
      return;
    }
    setIsVisibleState(true);
  };

  useClickOutsideEffect(nodeViewAbsoluteRef, () => onEventSetVisibleAbsoluteView(false));

  if (!Array.isArray(data)) {
    return null;
  }

  if (multiple) {
    return (
      <MultipleContainer ref={nodeViewAbsoluteRef}>
        {label && <Label edgeInsetsTop>{label}</Label>}
        <MultipleBox isFocus={isVisibleState} isEmpty={typeDef.isNotArrayLength(value)}>
          <SelectBox onClick={onEventClickDropDown}>
            <InputWrapper name={name} value={JSON.stringify(value)} onChange={() => {}} />
            {typeDef.isNotArrayLength(value) && !!placeholder && (
              <PlaceholderWrapper>
                <PlaceholderText>{placeholder}</PlaceholderText>
              </PlaceholderWrapper>
            )}
            {typeDef.isArrayLength(value) &&
              value.map((item, index) => (
                <ChipItem key={index.toString()} onClick={(e) => onEventChangeDel(e, item)}>
                  <ChipItemText>{item.label}</ChipItemText>
                  <Icon name={ICONS.CLOSE} size={18} color={OverrideStyles.iconCloseColor} />
                </ChipItem>
              ))}
          </SelectBox>
          <RightWrapper onClick={onEventClickDropDown}>
            <VerticalLine />
            <Icon name={ICONS.ARROW_DOWN} />
          </RightWrapper>
        </MultipleBox>
        {isVisibleState && (
          <OptionAbsolute space={typeDef.isNotArrayLength(renderDataState)}>
            {typeDef.isNotArrayLength(renderDataState) && <ChipItemText>{formatMessage({defaultMessage: 'ไม่มีตัวเลือกเพิ่มเติม'})}</ChipItemText>}
            {typeDef.isArrayLength(renderDataState) &&
              renderDataState.map((item, index) => (
                <OptionAbsoluteItem key={index.toString()} onClick={() => onEventSelectedItem(item)} noBottom={index === renderDataState.length - 1}>
                  {item.label}
                </OptionAbsoluteItem>
              ))}
          </OptionAbsolute>
        )}
      </MultipleContainer>
    );
  }

  return (
    <MultipleContainer ref={nodeViewAbsoluteRef} style={style}>
      <Row content="space-between">
        {label && <Label edgeInsetsTop>{label}</Label>}
        {rightLabelComponent && <>{rightLabelComponent}</>}
      </Row>
      <MultipleBox isFocus={isVisibleState} isEmpty={!isVisibleState} warning={!!warningText}>
        <SelectBox onClick={onEventClickDropDown}>
          <InputWrapper name={name} value={JSON.stringify(value)} onChange={() => {}} />
          {(typeDef.isNullOrUndefined(value) || !value) && !!placeholder && (
            <PlaceholderWrapper>
              <PlaceholderText>{placeholder}</PlaceholderText>
            </PlaceholderWrapper>
          )}
          {value && (
            <SelectedItemWrapper>
              <SelectedItemText>{typeDef.isString(value) ? value : value.label}</SelectedItemText>
            </SelectedItemWrapper>
          )}
        </SelectBox>
        {!noArrow && (
          <RightWrapper onClick={onEventClickDropDown}>
            <VerticalLine />
            <Icon name={ICONS.ARROW_DOWN} />
          </RightWrapper>
        )}
      </MultipleBox>
      {isVisibleState && (
        <OptionAbsolute noArrow={noArrow}>
          {typeDef.isArrayLength(data) &&
            data.map((item, index) => (
              <OptionAbsoluteItem key={index.toString()} onClick={() => onEventSelectedItem(item)}>
                {item.label}
              </OptionAbsoluteItem>
            ))}
        </OptionAbsolute>
      )}
      {!!warningText && <InputHint warning>{warningText}</InputHint>}
    </MultipleContainer>
  );
};

OptionBuilder.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onChangeDel: PropTypes.func,
  multiple: PropTypes.bool,
  setDefaultValue: PropTypes.bool,
  rightLabelComponent: PropTypes.any,
  warningText: PropTypes.string,
  noArrow: PropTypes.bool,
  style: PropTypes.any,
};

OptionBuilder.defaultProps = {
  label: null,
  data: null,
  value: null,
  multiple: false,
  placeholder: null,
  setDefaultValue: false,
  rightLabelComponent: null,
  warningText: '',
  noArrow: false,
  style: null,
  onChangeDel: () => {},
};

export default OptionBuilder;
