import {get, isEmpty} from 'lodash';

export const useQuery = (search) => {
  return new URLSearchParams(search).get('alphabet');
};
export const useTitle = (uri) => {
  const decodeUri = decodeURIComponent(uri);
  if (!decodeUri) {
    return 'JobFinFin';
  }
  const splitUrl = decodeUri.split('#');
  const splitGetTag = (splitUrl[1] || decodeUri).split('@');
  let description = `มีตำแหน่งงานมากมายให้ท่านเลือก ค้นหางานผ่าน - Jobfinfin`;
  const province = get(splitGetTag, '[1]') ? `ในจังหวัด ${get(splitGetTag, '[1]')}` : '';
  if (!isEmpty(uri)) {
    description = `ค้นหางาน ${get(splitGetTag, '[0]')} ${province} - JobFinFin`;
  }
  return description;
};
