import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import {lightTheme} from '../../styles/themes';

const SwitchToggle = (props) => {
  const {isToggled, disabled, onChangeToggled} = props;
  return (
    <Switch
      disabled={disabled || false}
      onColor={lightTheme.primary}
      onHandleColor={lightTheme.white}
      height={28}
      width={56}
      checkedIcon={false}
      uncheckedIcon={false}
      onChange={() => onChangeToggled(!isToggled)}
      checked={isToggled || false}
    />
  );
};

SwitchToggle.propTypes = {
  isToggled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChangeToggled: PropTypes.func.isRequired,
};

SwitchToggle.defaultProps = {
  disabled: false,
};

export default SwitchToggle;
