import {put} from 'redux-saga/effects';
import * as TYPES from './type';

export function* alertSetAlertAction({payload}) {
  if (!payload?.error) {
    yield put({type: TYPES.ALERT_SET_ALERT_SUCCESS, payload: payload?.data});
  } else {
    yield put({type: TYPES.ALERT_SET_ALERT_FAIL, payload: payload.error});
  }
}

export function* alertRemoveAlertAction({payload}) {
  if (!payload?.error) {
    yield put({type: TYPES.ALERT_REMOVE_ALERT_SUCCESS, payload});
  } else {
    yield put({type: TYPES.ALERT_REMOVE_ALERT_FAIL, payload});
  }
}
