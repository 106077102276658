import React from 'react';
import {useIntl} from 'react-intl';
import MetaTag from '../../components/meta-tag';
import AcceptPolicyView from './views';

const AcceptPolicyScreen = () => {
  const {formatMessage} = useIntl();

  return (
    <>
      <MetaTag
        title={formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์ - JobFinFin'})}
        description={formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์ - JobFinFin'})}
      />
      <AcceptPolicyView />
    </>
  );
};

AcceptPolicyScreen.propTypes = {};

export default AcceptPolicyScreen;
