import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper, Container, PostWrapper} from './styled-component';
import AboutCompany from './about';

const About = (props) => {
  const {isMid} = props;

  if (isMid) {
    return (
      <PostWrapper>
        <AboutCompany />
      </PostWrapper>
    );
  }
  return (
    <Wrapper>
      <Container>
        <AboutCompany />
      </Container>
    </Wrapper>
  );
};

About.propTypes = {
  isMid: PropTypes.bool,
};

About.defaultProps = {
  isMid: false,
};

export default About;
