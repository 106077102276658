import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  padding: 0 0 1rem 0;
`;

export const InputWrapper = styled.div`
  display: flex;
  height: 44px;
  padding: 0 1rem 0 1rem;
  background-color: white;
  align-items: center;
  border-radius: 6px;
  margin: 0.25rem 0 0.1rem 0;
  cursor: pointer;
  ${(props) => props.grayBackground && `background-color: ${props.theme.grayBackground}`};
  ${(props) => props.sandboxColor === 'gray' && `border: none; background-color: ${props.theme.grayBackground}`};
  ${(props) => props.disabled && `cursor:  not-allowed`};
  ${(props) => props.outline && `border: 1px solid ${props.theme.tinGray}`};
  ${(props) => props.normalStyle && `height: 36px`};
`;

export const InputLabel = styled.label`
  font-weight: bold;
  display: inline-block;
  ${(props) => props.edgeInsets && `padding-left: 0.5rem`};
`;

export const LimitLabel = styled.p`
  display: inline-block;
`;

export const InputField = styled.input`
  display: none;
`;

export const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: relative;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
`;

export const SelectBox = styled.div`
  margin: 1rem;
`;

/** Options item */
export const OptionGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
  align-items: center;
  ${(props) => props.child && `padding-left: 2rem`};
`;

export const OptionChildren = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  ${(props) => props.open && `max-height: ${props.height + 32}px`};
`;

export const OptionChildAnimationWrapper = styled.div``;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  @media (max-width: 1024px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }
  @media (min-width: 1025px) {
    width: 600px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  height: 56px;
  background-color: ${(props) => props.theme.grayBackground};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  justify-content: stretch;
  align-items: center;
  padding: 0 1rem;
`;

/**
 * SandBox
 */

export const Sandbox = styled.div`
  margin-top: 0.5rem;
  background-color: ${(props) => props.theme.white};
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  ${(props) => props.sandboxColor === 'gray' && `border: 1px solid ${props.theme.grayBackground}`}
  ${(props) => props.outline && `border: 1px solid ${props.theme.tinGray}`};
`;

export const OverrideStyles = {
  icon: {
    color: lightTheme.grayDisable,
    blueFade: lightTheme.blueFade,
  },
  placeholder: {
    gray: lightTheme.gray,
    color: lightTheme.placeholder,
  },
  button: {
    marginLeft: 0,
  },
  text: {
    paddingLeft: '0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textActive: {
    fontSize: 24,
    paddingLeft: '0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'underline',
    color: lightTheme.primary,
  },
  textActiveNormal: {
    fontSize: 18.72,
    paddingLeft: '0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'underline',
    color: lightTheme.primary,
  },
  inputWrapper: {
    locationPadding: {
      paddingLeft: '0.5rem',
    },
  },
};
