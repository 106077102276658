import * as typeDef from '../../utils/strong-types';
import * as dateTime from '../../utils/date-time';

export const ROLE = {
  USER: 'user',
  COMPANY: 'company',
};

export const getAge = (birthday) => {
  const age = dateTime.diffYear(birthday);
  return age;
};

export const getGeneralNewAge = (general) => {
  const userGeneral = general || {};
  return {general: {...userGeneral, birthday: userGeneral.birthday}};
};

export const getFullName = (firstName, lastName) => {
  let name = '';
  if (firstName) {
    name += firstName;
  }
  if (lastName) {
    name += `  ${lastName}`;
  }
  return name;
};

export const getDistrict = (district) => {
  if (!typeDef.isString(district)) {
    return '';
  }
  const splitText = district.split('|');
  if (!typeDef.isArrayLength(splitText)) {
    return district;
  }
  return splitText[1];
};
