import * as TYPES from './type';
import * as logic from './logic';

const jobIndexInitState = {
  jobIndex: {
    loading: false,
    data: {
      job: null,
      location: null,
    },
  },
};

const companyIndexInitState = {
  loading: false,
  alphabet: 'ก',
  type: 'th',
  data: {},
  counter: {},
};

export const jobIndexReducer = (state = jobIndexInitState, {type, payload}) => {
  switch (type) {
    case TYPES.SEO_UPDATE_JOB_INDEX_REQ:
      return {...state, loading: true};
    case TYPES.SEO_UPDATE_JOB_INDEX_SUCCESS:
      return {
        ...state,
        jobIndex: {
          loading: false,
          data: {
            job: payload?.job,
            location: payload?.location,
          },
        },
      };
    case TYPES.SEO_UPDATE_JOB_INDEX_CLEAR:
      return {...jobIndexInitState};
    default:
      return state;
  }
};

export const companyIndexReducer = (state = companyIndexInitState, {type, payload}) => {
  switch (type) {
    case TYPES.SEO_UPDATE_COMPANY_INDEX_REQ:
      return {...state, loading: true, error: null};
    case TYPES.SEO_UPDATE_COMPANY_INDEX_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.alphabet]: logic.createJobIndex(payload.data),
        },
        counter: {
          ...state.counter,
          [payload.alphabet]: logic.counterResults(payload.data),
        },
        loading: false,
      };
    case TYPES.SEO_UPDATE_COMPANY_INDEX_FAIL:
      return {...state, error: payload, loading: false};
    case TYPES.SEO_UPDATE_COMPANY_INDEX_CLEAR:
      return {...companyIndexInitState};
    case TYPES.SEO_UPDATE_COMPANY_INDEX_KEY_REQ:
      return {...state};
    case TYPES.SEO_UPDATE_COMPANY_INDEX_KEY_SUCCESS:
      return {...state, alphabet: payload.alphabet, type: payload.type};
    default:
      return state;
  }
};
