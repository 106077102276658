import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {Container, Wrapper, ContentWrapper} from './styled-component';
import Header from '../../../../components/header';
import cookieContent from '../../../../res/content/cookie-policy';
import RenderText from './render-text';
import RenderLink from './render-link';

const CookiesPolicyView = (props) => {
  const {isLoggedIn} = props;
  const {formatMessage} = useIntl();

  if (!cookieContent) {
    return null;
  }

  return (
    <Container isLoggedIn={isLoggedIn}>
      <Wrapper>
        <Header variant="h2" center>
          {formatMessage({defaultMessage: 'นโยบายคุกกี้'})}
        </Header>
        {Array.isArray(cookieContent) &&
          cookieContent.map((item, index) => (
            <ContentWrapper key={index.toString()}>
              {item.type === 'string' && <RenderText>{item.value}</RenderText>}
              {item.type === 'link' && <RenderLink>{item.value}</RenderLink>}
            </ContentWrapper>
          ))}
      </Wrapper>
    </Container>
  );
};

CookiesPolicyView.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default CookiesPolicyView;
