import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useUpdateTermDispatch} from '../hooks/useUpdateTerm';
import Checkbox from '../../../components/checkbox';
import Button from '../../../components/button';
import Loading from '../../../components/loading';
import {
  Container,
  ContentWrapper,
  ContentBody,
  ContentHeader,
  TextTitle,
  ContentBottom,
  RowTerm,
  TextFocus,
  TextUnFocus,
  ButtonGroup,
} from './styled-component';
import PrivacyPolicyView from '../../term/privacy-policy/views';
import {getPlatform} from '../../../utils/platform';
import {getAuthToken} from '../../../utils/storage/storage';
import * as ALERT from '../../../utils/components/alert/provider';
import * as authActions from '../../../redux/auth/action';
import * as alertActions from '../../../redux/alert/action';
import * as logic from './logic';

const AcceptPolicyView = () => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const {dispatchUpdateTerm} = useUpdateTermDispatch();
  const nodeElemHeaderRef = useRef(null);
  const nodeELemBottomRef = useRef(null);
  const nodeBodyRef = useRef(null);

  const authState = useSelector((state) => state.auth.auth);
  const isLoadingState = useSelector((state) => state.user.information.loadingTerm);
  const [acceptPrivacyState, setAcceptPrivacyState] = useState(false);
  const [currentView, setCurrentView] = useState('privacy');

  const onChangeAcceptPrivacy = () => {
    setAcceptPrivacyState(!acceptPrivacyState);
  };

  const onChangeView = (view) => {
    setCurrentView(view);
    if (nodeBodyRef.current) {
      nodeBodyRef.current.scrollTo(0, 0);
    }
  };

  const onEventConfirmLogout = () => {
    const reqBody = {
      platform: getPlatform(),
      token: getAuthToken(),
    };
    dispatch(authActions.userAuthLogout(reqBody));
  };

  const onEventLogout = () => {
    const alertData = {
      message: formatMessage({defaultMessage: 'คุณต้องการใช้งานแบบไม่เข้าสู่ระบบใช่หรือไม่'}),
      negativeTitle: formatMessage({defaultMessage: 'ใช่'}),
      positiveTitle: formatMessage({defaultMessage: 'ไม่ใช่'}),
      action: {
        negativeAction: () => onEventConfirmLogout(),
        positiveAction: () => ALERT.removeAlert(),
      },
    };
    dispatch(alertActions.alertSetAlert({data: alertData}));
  };

  const onEventAccept = () => {
    if (!acceptPrivacyState) {
      ALERT.setAlert(formatMessage({defaultMessage: 'กรุณาเลือกยอมรับ ก่อนกดปุ่มเข้าสู่เว็บไซต์'}));
      return;
    }
    const version = logic.getPolicyVersion(authState?.user?.terms_and_conditions);
    const updateData = {terms_and_conditions: [{version}]};
    dispatchUpdateTerm(updateData);
  };

  return (
    <>
      <Loading loading={isLoadingState} />
      <Container>
        <ContentWrapper>
          <ContentHeader ref={nodeElemHeaderRef}>
            <TextTitle>{formatMessage({defaultMessage: 'JobFinFin ไม่สามามารถแสดงข้อมูลงานให้คุณได้'})}</TextTitle>
            <TextTitle>{formatMessage({defaultMessage: 'เนื่องจากคุณยังไม่ได้ยอมรับข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์'})}</TextTitle>
          </ContentHeader>
          <ContentBody ref={nodeBodyRef}>
            <>{currentView === 'privacy' && <PrivacyPolicyView isView isLoggedIn={false} />}</>
          </ContentBody>
          <ContentBottom ref={nodeELemBottomRef}>
            <RowTerm>
              <Checkbox label={formatMessage({defaultMessage: 'ยอมรับ'})} checked={acceptPrivacyState} onChange={onChangeAcceptPrivacy} />
              <TextFocus left active={currentView === 'terms'} onClick={() => onChangeView('privacy')}>
                {formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์'})}
              </TextFocus>
              <TextUnFocus>{formatMessage({defaultMessage: 'ของ JobFinFin'})}</TextUnFocus>
            </RowTerm>
            <ButtonGroup>
              <Button onClick={onEventAccept}>{formatMessage({defaultMessage: 'เข้าสู่เว็บไซต์'})}</Button>
              <Button variant="light" onClick={onEventLogout}>
                {formatMessage({defaultMessage: 'ใช้งานแบบไม่เข้าสู่ระบบ'})}
              </Button>
            </ButtonGroup>
          </ContentBottom>
        </ContentWrapper>
      </Container>
    </>
  );
};

AcceptPolicyView.propTypes = {};

export default AcceptPolicyView;
