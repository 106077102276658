import CardTheme from '../../styles/card';
import * as ROUTES from '../../routes/routes-name';

const MAX_WIDTH = CardTheme.Size.width;
const SIDE_WIDTH = 160;

export const calRow = ({contextWidth}) => {
  if (!contextWidth) {
    return 1;
  }
  if (contextWidth > MAX_WIDTH * 4 + SIDE_WIDTH) {
    return 4;
  }
  if (contextWidth > MAX_WIDTH * 3 + SIDE_WIDTH) {
    return 3;
  }
  if (contextWidth > MAX_WIDTH * 2 + SIDE_WIDTH) {
    return 2;
  }
  return 1;
};

export const getIsEdgeInsets = ({totalRow, index}) => {
  if (totalRow === 1) {
    return false;
  }
  if (totalRow === 2) {
    return index !== 1 && index !== 3;
  }
  if (totalRow === 3) {
    return index !== 2 && index !== 5;
  }
  return index !== 3 && index !== 7;
};

export const getGroupLink = (urlName) => {
  if (!urlName) {
    return ROUTES.ROUTE_LANDING_GROUP_RAW;
  }
  return `${ROUTES.ROUTE_LANDING_GROUP_RAW}${urlName}`;
};
