import styled from 'styled-components';
import * as LAYOUT from '../layout';

export const Wrapper = styled.div``;

export const Container = styled.div`
  width: 340px;
  border-radius: 8px;
  padding: 1rem;
  margin-right: 1rem;
  background-color: ${(props) => props.theme.white};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
  display: block;
  transition: width 0.2s ease-out;
  ${(props) => props.fixed && `position: fixed;`};
  top: 60px;
  @media (max-width: ${LAYOUT.FULL_FILTER_BREAKPOINT}px) {
    display: none;
  }
`;

export const ContainerHidden = styled.div`
  height: 10px;
  width: 340px;
  margin-right: 1rem;
  position: relative;
  visibility: hidden;
  @media (max-width: ${LAYOUT.FULL_FILTER_BREAKPOINT}px) {
    display: none;
  }
`;

export const NavbarFilterWrapper = styled.div`
  z-index: 90;
  position: fixed;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${LAYOUT.FILTER_NAV_HEIGHT}px;
  background-color: red;
  top: 56px;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.primary};
  transform: ${(props) => (props.isShow ? `translateY(0)` : `translateY(-60px)`)};
  transition: transform 0.5s ease-in-out;
  @media (min-width: ${LAYOUT.FULL_FILTER_BREAKPOINT}px) {
    transform: translateY(-60px);
  }
`;

export const NavFilterLabel = styled.span`
  color: ${(props) => props.theme.white};
  font-weight: bold;
`;

export const IconWrapper = styled.div`
  padding-top: 0.15rem;
  padding-right: 0.5rem;
`;

export const TextWrapper = styled.div`
  word-wrap: break-word;
  padding-left: 1.6rem;
  margin: 0.25rem 0;
`;

export const GroupTextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0.5rem;
  ${(props) => props.nowrap && 'flex-wrap: nowrap'};
`;

export const LinkWrapper = styled.div`
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  color: ${(props) => props.theme.primary};
  @media (max-width: 680px) {
    width: calc(100vw - 150px);
  }
  @media (min-width: 1065px) {
    width: 210px;
  }
  @media (min-width: 681px) and (max-width: 1064px) {
    width: 535px;
  }
`;

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 680px;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${(props) => props.theme.white};
  @media (max-width: 680px) {
    width: calc(100vw);
    border-radius: 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 0.5rem`};
  }
  @media (min-width: 681px) {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 1rem`};
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 0.75rem;
`;

export const ContactWrapper = styled.div`
  display: flex;
  margin-left: 1.6rem;
  ${(props) => props.wrapLine && 'flex-direction: column'};
`;

export const TextContactHead = styled.span`
  padding-right: 0.5rem;
`;

export const TextMail = styled.span`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.primary};
  }
  @media (max-width: 680px) {
    width: calc(100vw - 150px);
  }
  @media (min-width: 1065px) {
    width: 220px;
  }
  @media (min-width: 681px) and (max-width: 1064px) {
    width: 535px;
  }
`;

export const TextTel = styled.span`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.primary};
  }
  @media (max-width: 680px) {
    width: calc(100vw - 150px);
  }
  @media (min-width: 1065px) {
    width: 220px;
  }
  @media (min-width: 681px) and (max-width: 1064px) {
    width: 535px;
  }
`;

export const TextLine = styled.span`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 680px) {
    width: calc(100vw - 120px);
  }
  @media (min-width: 1065px) {
    width: 290px;
  }
  @media (min-width: 681px) and (max-width: 1064px) {
    width: 620px;
  }
`;

export const OverrideStyles = {
  header: {
    paddingRight: '0.5rem',
  },
};
