import React, {useState} from 'react';
import {Container, RowWrapper, ItemPresentWrapper, DescriptionWrapper} from './styled-component';
import Image from '../../../components/image';
import Text from '../../../components/text';
import Header from '../../../components/header';
import BoxDecoration from '../../../components/box-decoration';
import * as utils from './utils';
import {lightTheme} from '../../../styles/themes';

const BoxPresent = () => {
  const [presentDataState] = useState(() => utils.createPresetData());

  return (
    <Container>
      {presentDataState.map((item, index) => {
        const swapped = index % 2 === 1;
        return (
          <RowWrapper key={index.toString()} whiteBackground={swapped} reverse={swapped}>
            <ItemPresentWrapper floatStick="flex-end">
              {!swapped ? (
                <BoxDecoration noEdgeInsets>
                  <Image autoScale fluid src={item.image} maxWidth={350} />
                </BoxDecoration>
              ) : (
                <DescriptionWrapper>
                  <Header variant="h2" color={lightTheme.primary}>
                    {item.title}
                  </Header>
                  <Text>{item.description}</Text>
                </DescriptionWrapper>
              )}
            </ItemPresentWrapper>
            <ItemPresentWrapper floatStick="flex-start">
              {!swapped ? (
                <DescriptionWrapper>
                  <Header variant="h2" color={lightTheme.primary}>
                    {item.title}
                  </Header>
                  <Text>{item.description}</Text>
                </DescriptionWrapper>
              ) : (
                <BoxDecoration noEdgeInsets>
                  <Image autoScale fluid src={item.image} maxWidth={350} />
                </BoxDecoration>
              )}
            </ItemPresentWrapper>
          </RowWrapper>
        );
      })}
    </Container>
  );
};

BoxPresent.propTypes = {};

export default BoxPresent;
