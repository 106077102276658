import {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useScrollReset} from '../useScrollingEffect';
import * as typeDef from '../../../utils/strong-types';
import * as searchActions from '../../../redux/search/action';
import * as filterUtils from '../../context/filter';
import * as contextActions from '../../../redux/context/action';

const SAL_MAX = 150000;
const MAX = 5000000;

export const useLandingFilterOptions = () => {
  const dispatch = useDispatch();
  const {resetScrollPosition} = useScrollReset();

  const checkIsSelectChip = (selectedValue, value) => {
    if (!Array.isArray(selectedValue) || !value) {
      return false;
    }
    const isSelected = selectedValue.find((element) => element === value);
    return !!isSelected;
  };

  const dispatchTransformFilter = (formValues) => {
    if (!formValues) {
      return;
    }
    const {filterEdu, filterExp, filterSalary, isFilterSalary, filterKey} = formValues;
    let reqBody = {};
    if (typeDef.isArrayLength(filterEdu)) {
      reqBody.education_requirement = [...filterEdu];
    } else {
      delete reqBody.education_requirement;
    }
    if (typeDef.isArrayLength(filterExp)) {
      reqBody.experience_requirement = filterExp.map((x) => parseInt(x, 16));
    } else {
      delete reqBody.experience_requirement;
    }
    reqBody.salary_min = filterSalary[0] === SAL_MAX ? SAL_MAX : filterSalary[0];
    reqBody.salary_max = filterSalary[1] === SAL_MAX ? MAX : filterSalary[1];

    if (!isFilterSalary) {
      delete reqBody.salary_min;
      delete reqBody.salary_max;
    }
    if (filterKey?.keyCompany) {
      reqBody.author_name = filterKey.keyCompany;
    }
    reqBody.search = filterKey?.keyJob || '';
    if (typeDef.isEmptyObject(reqBody)) {
      reqBody = null;
    }
    dispatch(searchActions.searchJobGroupUpdateFilter({data: reqBody}));
    resetScrollPosition();
  };

  const dispatchClearFilter = () => {
    dispatch(searchActions.searchJobGroupUpdateFilter({data: {}}));
  };

  return {
    checkIsSelectChip,
    dispatchTransformFilter,
    dispatchClearFilter,
  };
};

export const useLandingFilterOptionEffect = ({setFilterKey, setFilterEdu, setFilterExp, setFilterSalary, setIsFilterSalary}) => {
  const {filter: filterState} = useSelector((state) => state.search.jobGroup, shallowEqual);

  useEffect(() => {
    if (filterState) {
      if (filterState.education_requirement) {
        setFilterEdu(() => {
          return filterUtils.transformValueToLabel({
            type: 'edu',
            key: filterState.education_requirement,
          });
        });
      }
      if (filterState.experience_requirement) {
        setFilterExp(() => {
          return filterUtils.transformValueToLabel({
            type: 'exp',
            key: filterState.experience_requirement,
          });
        });
      }
      if (filterState.salary_min && filterState.salary_max) {
        const salMax = filterState.salary_max < MAX ? filterState.salary_max : SAL_MAX;
        setIsFilterSalary(true);
        setFilterSalary([filterState.salary_min, salMax]);
      }
      if (filterState.search || filterState.author_name) {
        const keys = {};
        if (filterState.search) {
          keys.keyJob = filterState.search;
        }
        if (filterState.author_name) {
          keys.keyCompany = filterState.author_name;
        }
        setFilterKey(keys);
      }
    }
  }, [filterState]);
};

export const useFilterModal = () => {
  const dispatch = useDispatch();
  const isVisibleFilterState = useSelector((state) => state.context.filter.visible, shallowEqual);
  const dispatchSetVisibleFilter = (isVisible) => {
    if (isVisible) {
      dispatch(contextActions.contextSetVisibleFilter({visible: true}));
    } else {
      dispatch(contextActions.contextSetVisibleFilter({visible: false}));
    }
  };
  return {isVisibleFilterState, dispatchSetVisibleFilter};
};
