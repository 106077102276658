import {setSnackbarMessage} from '../../utils/components/toast-notification/provider';

export const useWebNavigator = () => {
  const isCanShareNative = !!navigator.share;

  const unhandledError = () => {};

  const copyTextToClipboard = async (text, message) => {
    if (!text) {
      return;
    }
    try {
      await navigator.clipboard.writeText(text);
      setSnackbarMessage(message || 'คัดลอกลิงก์เรียบร้อย');
    } catch (error) {
      unhandledError(error);
      setSnackbarMessage('อุปกรณ์ไม่รองรับ', 'warning');
    }
  };

  const shareToNativeApplication = async (data) => {
    if (!data) {
      return;
    }
    try {
      await navigator.share(data);
      setSnackbarMessage('แชร์งานสำเร็จ');
    } catch (error) {
      unhandledError(error);
      // setSnackbarMessage('อุปกรณ์ไม่รองรับ', 'warning');
    }
  };
  return {
    isCanShareNative,
    copyTextToClipboard,
    shareToNativeApplication,
  };
};
