import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import { isEmpty } from 'lodash';
import ENV from '../../env.json';


const MetaTags = (props) => {
  const {title, description, quote, image, hashtag, contentUrl, scriptLdJson} = props;

  return (
    <Helmet>
      <title>{title}</title>
      <meta charset="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={contentUrl || ENV.PUBLIC.API_URL} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta property="fb:app_id" content={ENV.PUBLIC.FACEBOOK.CLIENT_ID} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:width" content="1200" />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={contentUrl} />
      <meta property="og:site_name" content="CampersTribe" />
      <meta property="og:description" content={description} />
      {!isEmpty(scriptLdJson) && <script type="application/ld+json">{JSON.stringify(scriptLdJson)}</script>}
    </Helmet>
  );
};

MetaTags.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  quote: PropTypes.any,
  image: PropTypes.string,
  hashtag: PropTypes.any,
  contentUrl: PropTypes.string,
  scriptLdJson: PropTypes.any,
};

MetaTags.defaultProps = {
  title: 'JobFinFin',
  description: null,
  quote: null,
  image: null,
  hashtag: null,
  contentUrl: null,
  scriptLdJson: null,
};

export default MetaTags;
