import {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {clearStorage} from '../../../utils/storage/storage';
import {getErrorMessage} from '../../../redux/utils/error-store';
import * as ROUTES from '../../../routes/routes-name';
import * as alertAction from '../../../redux/alert/action';

export const useAuthorizeEventEffect = () => {
  const dispatch = useDispatch();

  const authError = useSelector((state) => state.auth.auth.error, shallowEqual);
  const authErrorStatus = useSelector((state) => state.auth.auth.errorStatus, shallowEqual);

  const onAuthorizationDeniedAction = () => {
    clearStorage();
    dispatch(alertAction.alertRemoveAlert());
    if (authErrorStatus === 401 || authErrorStatus === 403 || authErrorStatus === 999) {
      window.location.replace(ROUTES.ROUTE_MAIN);
    }
  };

  const onTrackingEventAuthorization = () => {
    const alertPayload = {
      message: getErrorMessage(authError),
      action: {
        positiveAction: () => onAuthorizationDeniedAction(),
      },
    };
    dispatch(alertAction.alertSetAlert({data: alertPayload}));
  };

  useEffect(() => {
    if (authError) {
      onTrackingEventAuthorization();
    }
  }, [authError]);
};
