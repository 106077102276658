import {useIntl} from 'react-intl';
import * as ROUTES from '../../../routes/routes-name';
import {lightTheme} from '../../../styles/themes';
import * as ICONS from '../../../components/icon/constants';

export const getIconColor = (pathname, target) => {
  if (target === ROUTES.ROUTE_HOME && pathname === ROUTES.ROUTE_HOME) {
    return lightTheme.primary;
  }

  if (target === ROUTES.ROUTE_LIKE && pathname === ROUTES.ROUTE_LIKE) {
    return lightTheme.primary;
  }

  if (target === ROUTES.ROUTE_NOTIFY && pathname === ROUTES.ROUTE_NOTIFY) {
    return lightTheme.primary;
  }

  if (target === ROUTES.ROUTE_APPLY_JOB && pathname === ROUTES.ROUTE_APPLY_JOB) {
    return lightTheme.primary;
  }

  if (target === ROUTES.ROUTE_MENU) {
    switch (pathname) {
      case ROUTES.ROUTE_MENU:
      case ROUTES.ROUTE_PROFILE:
      case ROUTES.ROUTE_SETTING:
      case ROUTES.ROUTE_FAQ:
      case ROUTES.ROUTE_CONTACT:
        return lightTheme.primary;
      default:
        return lightTheme.grayMenu;
    }
  }
  return lightTheme.grayMenu;
};

export const getIconName = (target, isActive) => {
  if (target === ROUTES.ROUTE_HOME) {
    return ICONS.HOME;
  }
  if (target === ROUTES.ROUTE_LIKE) {
    if (isActive) {
      return ICONS.LIKE;
    }
    return ICONS.LIKE_OUTLINE;
  }
  if (target === ROUTES.ROUTE_NOTIFY) {
    if (isActive) {
      return ICONS.BELL_FILL;
    }
    return ICONS.BELL;
  }
  if (target === ROUTES.ROUTE_MENU) {
    return ICONS.MENU;
  }
  if (target === ROUTES.ROUTE_APPLY_JOB) {
    return ICONS.SEND_EMAIL;
  }
  return lightTheme.grayMenu;
};

export const isActiveSubMenu = (pathname) => {
  switch (pathname) {
    case ROUTES.ROUTE_MENU:
    case ROUTES.ROUTE_PROFILE:
    case ROUTES.ROUTE_SETTING:
    case ROUTES.ROUTE_FAQ:
    case ROUTES.ROUTE_CONTACT:
      return true;
    default:
      return false;
  }
};

export const getForceIsChildRoute = (pathname) => {
  switch (pathname) {
    case ROUTES.ROUTE_SEARCH_RESULTS:
    case ROUTES.ROUTE_SEARCH_AUTHOR:
    case ROUTES.ROUTE_SEARCH_HASHTAG:
    case ROUTES.ROUTE_SEARCH:
    case ROUTES.ROUTE_SEARCH_BY_ID:
      return true;
    default:
      return false;
  }
};

export const getCountNewNotify = (countNewNotify) => {
  const {formatMessage} = useIntl();
  if (countNewNotify > 99) {
    return formatMessage({defaultMessage: '99+'});
  }
  if (countNewNotify < 1) {
    return null;
  }
  return countNewNotify;
};
