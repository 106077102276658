/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {getPlatform} from '../../../utils/platform';
import {getAuthToken} from '../../../utils/storage/storage';
import {translate} from '../../../res/language/translate';
import ListTile from '../../../components/list-tile';
import * as logic from './logic';
import * as alertActions from '../../../redux/alert/action';
import * as authActions from '../../../redux/auth/action';
import * as navigation from '../../../utils/navigation/navigation-services';

const MenuList = (props) => {
  const {divider} = props;
  const dispatch = useDispatch();
  const [menus] = useState(logic.createMenus());

  const onEventConfirmLogout = () => {
    const reqBody = {
      platform: getPlatform(),
      token: getAuthToken(),
    };
    dispatch(authActions.userAuthLogout(reqBody));
  };

  const onEventRequestLogout = () => {
    const alertData = {
      message: translate('menus.alertLogout'),
      negativeTitle: translate('global.yes'),
      positiveTitle: translate('global.no'),
      action: {
        negativeAction: () => onEventConfirmLogout(),
        positiveAction: () => dispatch(alertActions.alertRemoveAlert()),
      },
    };
    dispatch(alertActions.alertSetAlert({data: alertData}));
  };

  const onEventAction = (id, route) => {
    if (id === 'logout') {
      onEventRequestLogout();
      return;
    }
    navigation.navigateTo({pathname: route});
  };

  return (
    <>
      {menus.map((item, index) => {
        return (
          <ListTile
            key={item.id}
            icon={item.iconName}
            title={item.title}
            divider={divider && index < menus.length - 1}
            onClick={() => onEventAction(item.id, item.route)}
          />
        );
      })}
    </>
  );
};

MenuList.propTypes = {
  divider: PropTypes.bool,
};

MenuList.defaultProps = {
  divider: false,
};

export default MenuList;
