import React from 'react';
import {useLocation} from 'react-router-dom';
import {useScrollResetEffect} from '../../lib/hooks/useScrollingEffect';
import ChangePasswordView from './views/edit-password';
import EditGeneralView from './views/edit-general';
import EditSkillView from './views/edit-skill';
import EditEducationView from './views/edit-education';
import InterestView from './views/edit-interest';
import OtherView from './views/edit-other';
import EditWorkView from './views/edit-work';

import * as ROUTES from '../../routes/routes-name';

const EditProfileScreen = () => {
  useScrollResetEffect(null);
  const {pathname} = useLocation();

  if (!pathname) {
    return null;
  }

  if (pathname === ROUTES.ROUTE_USER_CHANGE_PASSWORD) {
    return <ChangePasswordView />;
  }

  if (pathname === ROUTES.ROUTE_EDIT_PROFILE_GENERAL) {
    return <EditGeneralView />;
  }

  if (pathname === ROUTES.ROUTE_EDIT_PROFILE_SKILL) {
    return <EditSkillView />;
  }

  if (pathname === ROUTES.ROUTE_EDIT_PROFILE_EDUCATION) {
    return <EditEducationView />;
  }

  if (pathname === ROUTES.ROUTE_EDIT_PROFILE_WORKS) {
    return <EditWorkView />;
  }

  if (pathname === ROUTES.ROUTE_EDIT_PROFILE_INTEREST) {
    return <InterestView />;
  }

  if (pathname === ROUTES.ROUTE_EDIT_PROFILE_OTHER) {
    return <OtherView />;
  }

  return <div />;
};

EditProfileScreen.propTypes = {};

export default EditProfileScreen;
