import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const SearchFilterWrapper = styled.div`
  margin: 2rem 1.5rem -20px 1.5rem; ;
`;

export const OverrideThisStyles = {
  textNoResult: {
    textAlign: 'center',
  },
  recommend: {
    backgroundColor: lightTheme.grayBackground,
  },
  textRecommend: {
    marginRight: '1rem',
    fontSize: 24,
  },
  textRecommendNormal: {
    marginRight: '1rem',
    fontSize: 18.72,
  },
  iconRecommendLocation: {
    color: lightTheme.danger,
  },
};
