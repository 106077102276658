import {useDispatch} from 'react-redux';
import * as ACTIONS from '../../redux/context/action';

export const useCallbackPathDispatch = () => {
  const dispatch = useDispatch();

  const dispatchAddCallbackPath = (callbackData = {}) => {
    dispatch(ACTIONS.contextAddCallbackPath(callbackData));
  };

  const dispatchClearCallbackPath = () => {
    dispatch(ACTIONS.contextAddCallbackPath({clear: true}));
  };

  return {dispatchAddCallbackPath, dispatchClearCallbackPath};
};
