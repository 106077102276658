import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useRegister} from '../../../lib/hooks/logic/useRegister';
import {translate} from '../../../res/language/translate';
import {ButtonSmallWrapper, HeaderWrapper, ButtonLargeWrapper, FacebookRegisterWrapper, OverrideStyles} from './styled-component';
import Form from '../../../components/form';
import Button from '../../../components/button';
import ButtonIcon from '../../../components/button-icon';
import Input from '../../../components/input';
import Text from '../../../components/text';
import Header from '../../../components/header';
import FormWrapper from '../../../views/form';
import Responsive from '../../../components/responsive';
import * as ICONS from '../../../components/icon/constants';
import * as validate from './validate';
import * as logic from './logic';
import * as ROUTES from '../../../routes/routes-name';
import * as navigation from '../../../utils/navigation/navigation-services';

const CURRENT_STEP = '(1/2)';
const BREAKPOINT_SMALL = 1024;

const FormGeneral = (props) => {
  const {onSubmit} = props;
  const {formGeneralState} = useRegister();
  const {formatMessage} = useIntl();

  const [emailState, setEmailState] = useState('');
  const [passwordState, setPasswordState] = useState('');
  const [confPasswordState, setConfPasswordState] = useState('');
  const [errorTexts, setErrorTexts] = useState({email: '', password: '', confPassword: ''});

  useEffect(() => {
    if (formGeneralState) {
      setEmailState(formGeneralState.email);
      setPasswordState(formGeneralState.password);
      setConfPasswordState(formGeneralState.password);
    }
  }, [formGeneralState]);

  const onChangeError = (key, value) => {
    const newError = {...errorTexts};
    newError[key] = value;
    setErrorTexts(newError);
  };

  const onChangeText = (key, value) => {
    onChangeError(key, '');
    if (key === 'email') {
      setEmailState(value);
      return;
    }
    if (key === 'password') {
      setPasswordState(value);
      return;
    }
    setConfPasswordState(value);
  };

  const handlerOnSubmit = () => {
    const formValues = {
      email: emailState,
      password: passwordState,
      confPassword: confPasswordState,
    };
    const {emailResult, passwordResult, confPasswordResult} = validate.validateGeneralForm(formValues);
    const isValidate = !emailResult && !passwordResult && !confPasswordResult;
    if (!isValidate) {
      const errorMsg = {};
      if (emailResult) {
        errorMsg.email = emailResult;
      }
      if (passwordResult) {
        errorMsg.password = passwordResult;
      }
      if (confPasswordResult) {
        errorMsg.confPassword = confPasswordResult;
      }
      setErrorTexts(errorMsg);
      return;
    }
    onSubmit(formValues);
  };

  const onEventLoginWithFacebook = () => {
    navigation.navigateTo({pathname: ROUTES.ROUTE_FACEBOOK_LOGIN});
  };

  return (
    <FormWrapper position="start">
      <HeaderWrapper>
        <Header variant="h2">{translate('register.step1')}</Header>
      </HeaderWrapper>
      <Form formId="form-register-general" style={OverrideStyles.form} onSubmit={handlerOnSubmit}>
        <FacebookRegisterWrapper>
          <ButtonIcon block iconName={ICONS.FACEBOOK_LOGIN} style={OverrideStyles.btnFacebook} onClick={onEventLoginWithFacebook}>
            {formatMessage({defaultMessage: 'เข้าสู่ระบบด้วย Facebook'})}
          </ButtonIcon>
          <Text style={OverrideStyles.textRegisFacebook}>{formatMessage({defaultMessage: '-หรือสร้างบัญชี-'})}</Text>
        </FacebookRegisterWrapper>
        <Input
          fillBackground
          name="email"
          type="email"
          label={translate('login.fieldEmail')}
          placeholder={logic.getPlaceholderText('email')}
          value={emailState}
          onChange={(value) => onChangeText('email', value)}
          warningText={errorTexts.email}
        />
        <Input
          fillBackground
          name="password"
          type="password"
          label={translate('login.fieldPassword')}
          placeholder={logic.getPlaceholderText('password')}
          value={passwordState}
          onChange={(value) => onChangeText('password', value)}
          warningText={errorTexts.password}
          hint={translate('register.errorPassword.regex')}
        />
        <Input
          fillBackground
          name="confPassword"
          type="password"
          label={translate('login.fieldPassword')}
          placeholder={logic.getPlaceholderText('conf-password')}
          value={confPasswordState}
          onChange={(value) => onChangeText('confPassword', value)}
          warningText={errorTexts.confPassword}
        />
        <ButtonLargeWrapper>
          <Button block type="submit" style={OverrideStyles.button}>
            {`${translate('register.buttonNext')} ${CURRENT_STEP}`}
          </Button>
        </ButtonLargeWrapper>
      </Form>
      <Responsive maxWidth={BREAKPOINT_SMALL}>
        <ButtonSmallWrapper>
          <Button block type="submit" onClick={handlerOnSubmit}>
            {`${translate('register.buttonNext')} ${CURRENT_STEP}`}
          </Button>
        </ButtonSmallWrapper>
      </Responsive>
    </FormWrapper>
  );
};

FormGeneral.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FormGeneral;
