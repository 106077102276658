import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';

export const isNullOrUndefined = (value) => {
  return value === null || typeof value === 'undefined';
};

export const isString = (value) => {
  return typeof value === 'string' || value instanceof String;
};

export const isNumber = (value) => {
  return typeof value === 'number' && isFinite(value);
};

export const isNumberDecimal = (value) => {
  return typeof value === 'number';
};

export const isNotNumber = (value) => {
  if (isNullOrUndefined(value)) {
    return true;
  }
  return typeof value !== 'number' || !isFinite(value);
};

export const isArray = (value) => {
  return value && typeof value === 'object' && value.constructor === Array;
};

export const isArrayLength = (value) => {
  try {
    return value && typeof value === 'object' && value.constructor === Array && value.length > 0;
  } catch (error) {
    return false;
  }
};

export const isFunction = (value) => {
  return typeof value === 'function';
};

export const isObject = (value) => {
  return value && typeof value === 'object' && value.constructor === Object;
};

export const isNull = (value) => {
  return value === null;
};

export const isUndefined = (value) => {
  return typeof value === 'undefined';
};

export const isBoolean = (value) => {
  return typeof value === 'boolean';
};

export const isRegExp = (value) => {
  return value && typeof value === 'object' && value.constructor === RegExp;
};

export const isError = (value) => {
  return value instanceof Error && typeof value.message !== 'undefined';
};

export const isDate = (value) => {
  return value instanceof Date;
};

export const isSymbol = (value) => {
  return typeof value === 'symbol';
};

export const isObjHasKey = (value) => {
  return typeof value !== 'undefined';
};

export const isEmptyValue = (value) => {
  return isEmpty(value);
};

export const isNotArrayLength = (value) => {
  try {
    if (isNullOrUndefined(value)) {
      return true;
    }
    if (isObject(value)) {
      return true;
    }
    if (isBoolean(value)) {
      return true;
    }
    if (isString(value)) {
      return true;
    }
    return value && typeof value === 'object' && value.constructor === Array && value.length === 0;
  } catch (error) {
    return true;
  }
};

export const isNotObject = (value) => {
  if (isNullOrUndefined(value)) {
    return true;
  }
  if (isBoolean(value)) {
    return true;
  }
  if (isString(value)) {
    return true;
  }
  if (isArray(value)) {
    return true;
  }
  if (isNumber(value)) {
    return true;
  }
  if (value && typeof value !== 'object' && value.constructor !== Object) {
    return true;
  }
  return false;
};

export const isEmptyObject = (value) => {
  if (isNotObject(value)) {
    return true;
  }
  return JSON.stringify(value) === JSON.stringify({});
};
