import styled from 'styled-components';

export const JobText = styled.pre`
  white-space: pre-wrap;
  font-size: 14px;
  ${(props) => props.block && `display: block;`};
  font-family: 'Prompt Light';
`;

export const JobTextHyperLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const JobTextHyperLink = styled.span`
  font-size: 14px;
  padding: 0 0.5rem;
  text-decoration: underline;
  color: ${(props) => props.theme.primary};
`;

export const Container = styled.div``;
