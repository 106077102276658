import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as typeDef from '../../../utils/strong-types';
import * as searchActions from '../../../redux/search/action';

// const DEFAULT_LOCATION = 'กรุงเทพมหานคร';

export const useRecommendJob = () => {
  const allJobCatState = useSelector((state) => state.search.jobCat.allJobCat.jobs);
  const popularJobCatState = useSelector((state) => state.search.jobCat.popularJobCat.jobs);
  const isLoadingState = useSelector((state) => state.search.jobCat.popularJobCat.loading);

  return {
    allJobCat: allJobCatState,
    popularJobCat: popularJobCatState,
    loading: isLoadingState,
  };
};

export const useRecommendJobEffect = (province) => {
  const dispatch = useDispatch();
  const [currentProvince, setCurrentProvince] = useState();
  const allJobCatState = useSelector((state) => state.search.jobCat.allJobCat.jobs);
  const popularJobCatState = useSelector((state) => state.search.jobCat.popularJobCat.jobs);

  useEffect(() => {
    if (typeDef.isNotArrayLength(allJobCatState)) {
      dispatch(searchActions.searchAllJobCat());
    }
  }, [dispatch, allJobCatState]);

  useEffect(() => {
    if (province && province !== currentProvince) {
      dispatch(searchActions.searchPopularJobCat({data: province}));
      setCurrentProvince(province);
    }
  }, [dispatch, province, popularJobCatState, currentProvince]);
};
