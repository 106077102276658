import React from 'react';
import PropTypes from 'prop-types';
import {PostWrapper, OverrideStyles} from './components/styled-component';
import Divider from '../../components/divider';
import PostHeader from './components/header';
import PostBody from './components/body';
import PostBodyFancyText from './components/body-fancy-text';
import PostCounter from './components/counter';
import PostInteractions from './components/interact';
import PostImages from './components/image';
import JobApplyStatus from './components/job-apply-status';

const NOOP = () => {};

const JobPostItem = (props) => {
  const {
    isMeta,
    id,
    authorId,
    avatarSrc,
    authorName,
    postDate,
    postImages,
    location,
    jobTitle,
    salary,
    postText,
    hashtags,
    likeCounter,
    seenCounter,
    interestedCounter,
    isLiked,
    onClickLike,
    onClickApplyJob,
    lineIds,
    lineText,
    emails,
    emailText,
    tels,
    telText,
    isCompany,
    jobApply,
    authorUrl,
    authorSrc,
    isCompanyVerified,
    fancyText,
    normalText,
    srcRemoved,
    isActivated,
    jobData,
    companyName,
    isSalaryHidden,
    onEventTracking,
    locations,
    postSections,
  } = props;

  if (!id) {
    return null;
  }

  return (
    <PostWrapper>
      <PostHeader
        jobId={id}
        isMeta={isMeta}
        isCompanyVerified={isCompanyVerified}
        isCompany={isCompany}
        avatarSrc={avatarSrc}
        authorName={authorName}
        postDate={postDate}
        location={location}
        srcRemoved={srcRemoved}
        isActivated={isActivated}
        src={authorSrc}
        authorSrc={authorSrc}
        authorId={authorId}
      />
      {!fancyText && (
        <PostBody
          jobId={id}
          isMeta={isMeta}
          jobTitle={jobTitle}
          isSalaryHidden={isSalaryHidden}
          salary={salary}
          bodyText={postText}
          hashtags={hashtags}
          source={authorSrc}
          postUrl={authorUrl}
          location={locations}
          postSections={postSections}
        />
      )}
      {fancyText && <PostBodyFancyText fancyText={fancyText} normalText={normalText} />}
      {!jobApply && <PostImages postImages={authorSrc === 'owned_facebook' ? postImages : []} />}
      {jobApply && <JobApplyStatus mailSentDate={jobApply.mailSentDate} resumeReadDate={jobApply.resumeReadDate} />}
      {!isMeta && (
        <>
          <PostCounter likeCounter={likeCounter} seenCounter={seenCounter} interestedCounter={interestedCounter} />
          <Divider style={OverrideStyles.divider} />
          <PostInteractions
            id={id}
            isLiked={isLiked}
            onClickApplyJob={!jobApply ? () => onClickApplyJob(id, emails, jobTitle, isActivated, srcRemoved, authorUrl, companyName, jobData) : NOOP}
            onClickLike={() => onClickLike(id)}
            lineIds={lineIds}
            lineText={lineText}
            emails={emails}
            emailText={emailText}
            tels={tels}
            telText={telText}
            jobTitle={jobTitle}
            jobSalary={salary}
            disableJobApply={!!jobApply}
            authorUrl={authorUrl}
            authorSrc={authorSrc}
            jobData={jobData}
            onEventTracking={onEventTracking}
          />
        </>
      )}
    </PostWrapper>
  );
};

JobPostItem.propTypes = {
  id: PropTypes.string.isRequired,
  isMeta: PropTypes.bool,
  authorId: PropTypes.string,
  avatarSrc: PropTypes.string,
  authorName: PropTypes.string,
  postDate: PropTypes.string,
  postImages: PropTypes.array,
  location: PropTypes.string,
  jobTitle: PropTypes.string,
  postText: PropTypes.string,
  hashtags: PropTypes.array,
  salary: PropTypes.string,
  likeCounter: PropTypes.number,
  seenCounter: PropTypes.number,
  interestedCounter: PropTypes.number,
  onClickLike: PropTypes.func,
  onClickApplyJob: PropTypes.func,
  isLiked: PropTypes.bool,
  lineIds: PropTypes.array,
  lineText: PropTypes.string,
  tels: PropTypes.array,
  telText: PropTypes.string,
  emails: PropTypes.array,
  emailText: PropTypes.string,
  isCompany: PropTypes.bool,
  jobApply: PropTypes.object,
  authorUrl: PropTypes.string,
  authorSrc: PropTypes.string,
  isCompanyVerified: PropTypes.bool,
  fancyText: PropTypes.string,
  normalText: PropTypes.string,
  isActivated: PropTypes.bool,
  srcRemoved: PropTypes.bool,
  jobData: PropTypes.object.isRequired,
  companyName: PropTypes.array,
  isSalaryHidden: PropTypes.bool,
  onEventTracking: PropTypes.func.isRequired,
  locations: PropTypes.array,
  postSections: PropTypes.array,
};

JobPostItem.defaultProps = {
  isMeta: false,
  authorId: null,
  avatarSrc: null,
  authorName: null,
  postDate: null,
  location: null,
  jobTitle: null,
  postText: null,
  hashtags: null,
  salary: null,
  likeCounter: 0,
  seenCounter: 0,
  interestedCounter: 0,
  postImages: [],
  isLiked: false,
  lineIds: [],
  lineText: '',
  tels: [],
  telText: '',
  emails: [],
  emailText: '',
  jobApply: null,
  onClickLike: () => {},
  onClickApplyJob: () => {},
  isCompany: false,
  authorUrl: null,
  authorSrc: null,
  isCompanyVerified: false,
  fancyText: '',
  normalText: '',
  isActivated: true,
  srcRemoved: false,
  companyName: [],
  isSalaryHidden: false,
  locations: [],
  postSections: null,
};

export default JobPostItem;
