import React from 'react';
import {Redirect} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useAuthState} from '../../lib/hooks-logic/useAuth';
import MetaTag from '../../components/meta-tag';
import SplashScreen from '../splash';
import ManageNotify from './views';
import {useManageNotifyEffect} from './hooks/useManageNotify';
import * as ROUTES from '../../routes/routes-name';

const ManageNotifyScreen = () => {
  useManageNotifyEffect();

  const {formatMessage} = useIntl();
  const {isCheckedAuthState, isCurrentLoggedInState} = useAuthState();

  if (!isCheckedAuthState) {
    return <SplashScreen />;
  }

  if (isCurrentLoggedInState) {
    return <Redirect to={ROUTES.ROUTE_NOTIFY} />;
  }

  return (
    <>
      <MetaTag
        title={formatMessage({defaultMessage: 'ตั้งค่าการแจ้งเตือน - JobFinFin'})}
        description={formatMessage({defaultMessage: 'ตั้งค่าการแจ้งเตือน - JobFinFin'})}
      />
      <ManageNotify />
    </>
  );
};

ManageNotifyScreen.propTypes = {};

export default ManageNotifyScreen;
