import styled from 'styled-components';
import * as LAYOUT from '../layout';

export const Wrapper = styled.div``;

export const Container = styled.div`
  overflow-y: auto;
  width: 340px;
  border-radius: 8px;
  padding: 1rem;
  margin-right: 1rem;
  background-color: ${(props) => props.theme.white};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
  display: block;
  transition: width 0.2s ease-out;
  top: 60px;
  ${(props) => props.fixed && `max-height: calc(100vh - 80px)`};
  ${(props) => props.fixed && `position: fixed;`};
  @media (max-width: ${LAYOUT.FULL_FILTER_BREAKPOINT}px) {
    display: none;
    visibility: hidden;
    width: 0px;
    position: absolute;
  }
`;

export const ContainerHidden = styled.div`
  height: 10px;
  width: 340px;
  margin-right: 1rem;
  position: relative;
  visibility: hidden;
  @media (max-width: ${LAYOUT.FULL_FILTER_BREAKPOINT}px) {
    display: none;
  }
`;

export const NavbarFilterWrapper = styled.div`
  z-index: 90;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${LAYOUT.FILTER_NAV_HEIGHT}px;
  background-color: red;
  top: 56px;
  left: 0;
  right: 0;
  padding: 0 0.5rem;
  background-color: ${(props) => props.theme.gray};
  transform: ${(props) => (props.isShow ? `translateY(0)` : `translateY(-60px)`)};
  transition: transform 0.5s ease-in-out;
  @media (min-width: ${LAYOUT.FULL_FILTER_BREAKPOINT}px) {
    transform: translateY(-60px);
  }
`;

export const NavFilterLabel = styled.span`
  color: ${(props) => props.theme.white};
  font-weight: bold;
  padding: 0 0.5rem;
`;

export const GroupMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.white};
  min-width: 80px;
  height: 34px;
  @media (max-width: 459px) {
    ${(props) => props.search && `min-width: 30px`};
  }
`;

export const OverrideStyles = {
  iconFilter: 'white',
};
