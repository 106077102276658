import styled from 'styled-components';

export const TextDownLoad = styled.span`
  color: ${(props) => props.theme.danger};
`;

export const TextUnderline = styled.span`
  text-decoration: underline;
`;

export const TextUploadTitle = styled.p`
  ${(props) => props.sm && `font-size: 14px;`};
  ${(props) => props.bold && `font-weight: bold;`};
`;

export const InputHidden = styled.input`
  display: none;
`;

export const ButtonUpload = styled.div`
  width: 82px;
  padding: 0.15rem 0;
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  background-color: ${(props) => props.theme.primary};
`;

export const TextBtn = styled.span`
  font-size: 12px;
  padding: 0 0.5rem;
  ${(props) => props.white && 'color: white;'};
`;

export const FileListWrapper = styled.div`
  margin: 0.5rem 0;
`;

export const TextFileName = styled.span`
  font-size: 12px;
  margin-right: 0.5rem;
`;

export const FileDisplay = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonSee = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.lightGray};
  width: 62px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  padding: 0.2rem 0;
`;

export const ButtonDel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.danger};
  width: 62px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  padding: 0.25rem 0;
`;
