import React from 'react';
import PropTypes from 'prop-types';
import {FormContainer} from './styled-component';
import {isArrayLength} from '../../utils/strong-types';

const Form = (props) => {
  const {formId, children, style, onSubmit, customEvent} = props;

  const handlerSubmit = (e) => {
    e.preventDefault();
    if (customEvent) {
      onSubmit(e);
      return;
    }
    const formInput = Object.values(e.target);
    if (isArrayLength(formInput)) {
      const formValue = {};
      formInput.forEach((element) => {
        if (element.localName === 'input') {
          formValue[element.name] = element.checked ? true : element.value;
        }
      });
      onSubmit(formValue);
    }
  };

  return (
    <FormContainer id={formId} style={style} onSubmit={handlerSubmit}>
      {children}
    </FormContainer>
  );
};

Form.propTypes = {
  formId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  style: PropTypes.any,
  customEvent: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

Form.defaultProps = {
  style: null,
  customEvent: false,
};

export default Form;
