import {useEffect} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import * as registerAction from '../../../redux/register/actions';

export const useValidateEmail = (config = {}) => {
  const {register} = config;
  const dispatch = useDispatch();
  const currentStepState = useSelector((state) => state.register.visibleStep.currentStep, shallowEqual);
  const isValidateEmail = useSelector((state) => state.register.step1.emailIsValidate, shallowEqual);
  const isLoadingValidateEmail = useSelector((state) => state.register.step1.loading, shallowEqual);

  const dispatchValidateEmail = (email) => {
    dispatch(registerAction.userRegisterStep1ValidateEmail({data: {email}}));
  };

  useEffect(() => {
    if (isValidateEmail && register) {
      dispatch(registerAction.userRegisterStep1ValidateEmail({clear: true}));
      dispatch(
        registerAction.userRegisterUpdateVisibleStep({
          data: currentStepState + 1,
        }),
      );
    }
  }, [isValidateEmail, currentStepState, register, dispatch]);

  return {dispatchValidateEmail, isLoadingValidateEmail};
};
