import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

export const useScrollSearchEffect = () => {
  const history = useHistory();
  useEffect(() => {
    if (history.action === 'PUSH') {
      window.scrollTo({top: 0});
    }
  }, [history]);
};
