import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useClickOutsideEffect} from '../../lib/hooks/useClickOutSideEffect';
import {Container, SheetContent} from './styled-component';

const BottomSheets = (props) => {
  const {isOpen, onRequestClose, children, noEdgeInsets} = props;
  const nodSheetRef = useRef();

  const onEventRequestClose = () => {
    onRequestClose();
  };

  const onClickDimiss = useClickOutsideEffect(nodSheetRef, onEventRequestClose);

  return (
    <Container onClick={onClickDimiss} isOpen={isOpen}>
      <SheetContent ref={nodSheetRef} isOpen={isOpen} noEdgeInsets={noEdgeInsets}>
        {children}
      </SheetContent>
    </Container>
  );
};

BottomSheets.propTypes = {
  isOpen: PropTypes.bool,
  noEdgeInsets: PropTypes.bool,
  children: PropTypes.any,
  onRequestClose: PropTypes.func.isRequired,
};

BottomSheets.defaultProps = {
  isOpen: false,
  children: null,
  noEdgeInsets: false,
};

export default BottomSheets;
