import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {translate} from '../../../../res/language/translate';
import {OverrideStyles} from './styled-component';
import Sidebar from '../../../../views/app-bar/side-bar';
import Divider from '../../../../components/divider';
import Header from '../../../../components/header';
import ListTile from '../../../../components/list-tile';
import Link from '../../../../components/link';
import Icon from '../../../../components/icon';
import Row from '../../../../components/row';
import GestureDetector from '../../../../components/gesture';
import * as logic from './logic';
import * as ICONS from '../../../../components/icon/constants';
import * as ROUTES from '../../../../routes/routes-name';

const EditInfoSidebar = () => {
  const history = useHistory();
  const {pathname} = useLocation();
  const [menus] = useState(logic.createMenus());

  const onEventGoBack = () => {
    history.replace(ROUTES.ROUTE_PROFILE);
  };

  return (
    <Sidebar>
      <Row>
        <GestureDetector circle hovered withEdgeInsets={false} onClick={onEventGoBack}>
          <Icon name={ICONS.ARROW_LEFT} />
        </GestureDetector>
        <Header variant="h3" style={OverrideStyles.header}>
          {translate('sidebar.editProfileTitle')}
        </Header>
      </Row>
      <Divider style={OverrideStyles.divider} />
      {Array.isArray(menus) &&
        menus.map((item) => {
          return (
            <Link key={item.id} url={item.route} wrapperChildren>
              <ListTile isActive={pathname === item.route} icon={item.iconName} title={item.title} onClick={() => {}} />
            </Link>
          );
        })}
    </Sidebar>
  );
};

EditInfoSidebar.propTypes = {};

export default EditInfoSidebar;
