import get from 'lodash/get';
import {numberWithComma} from '../../../utils/number';

export const cleanUpHeader = (title) => {
  if (!title) {
    return title;
  }
  const pattern = /เปิดรับสมัคร|รับสมัคร/;
  return title.replace(pattern, '');
};

export const cleanUpLocation = (prettyBody, location, source) => {
  if (!Array.isArray(prettyBody) || !get(location, '[0]') || source === 'company') {
    return prettyBody;
  }
  const headers = prettyBody.filter((a) => /<h>|<\/h>/g.test(a));
  const hasLocation = headers.findIndex((a) => {
    if (a.includes('สถานที่ทำงาน')) return true;
    if (a.includes('สถานที่ปฏิบัติงาน')) return true;
    if (a.includes('Location')) return true;
    if (a.includes('location')) return true;
    return false;
  });
  if (hasLocation > -1) {
    return prettyBody;
  }
  const cleanedLocation = [...prettyBody];
  cleanedLocation.push('<h>สถานที่ปฏิบัติงาน</h>');
  const districts = location.filter((a) => a.includes('|'));
  if (!get(districts, '[0]')) {
    cleanedLocation.push(`• ${location[0]}`);
  } else {
    const province = districts[0].split('|')[0];
    const district = districts.map((a) => a.split('|')[1]);
    const displayLocation = `• ${district.join(' ')} ${province}`;
    cleanedLocation.push(displayLocation);
  }
  return cleanedLocation;
};

export const cleanUpSalary = (prettyBody, source) => {
  if (source !== 'findgovjob') return prettyBody;

  prettyBody.splice(0, 0, '<h>หน่วยงาน/สังกัด</h>');

  const salaryHeaderIndex = prettyBody.indexOf('<h>ค่าตอบแทน</h>');
  const salaryLine = prettyBody[salaryHeaderIndex + 1];

  if (salaryLine) {
    let number;
    salaryLine.replace(/(\d*)/, (m) => {
      number = m;
    });

    if (number) {
      number = numberWithComma({input: number}).concat(' บาท');

      prettyBody[salaryHeaderIndex + 1] = number;
    }
  }

  return prettyBody;
};

export const cleanupLessBody = (body) => {
  if (!Array.isArray(body)) {
    return body;
  }
  const lessBody = body.slice(0, 3);
  let maximumLess = false;
  const cleanedLessBody = [];
  lessBody.forEach((a) => {
    if (a.includes('<h>') && !maximumLess) {
      cleanedLessBody.push(a);
    } else if (a.length <= 80 && !maximumLess) {
      cleanedLessBody.push(a);
    } else if (a.length > 80 && !maximumLess) {
      const maxLen = a.length > 200 ? 200 : a.length;
      cleanedLessBody.push(a.substring(0, maxLen));
      maximumLess = true;
    }
  });
  const lastRow = get(cleanedLessBody, `${[cleanedLessBody.length - 1]}`, '');
  const isLastHeader = lastRow.includes('<h>');
  if (!isLastHeader) {
    cleanedLessBody[cleanedLessBody.length - 1] = `${cleanedLessBody[cleanedLessBody.length - 1]}...`;
  }
  return cleanedLessBody;
};
