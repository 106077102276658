import React from 'react';
import PropTypes from 'prop-types';
import {FormStart, FormContainer, OverrideStyles} from './styled-component';
import Center from '../../components/center';

const FormWrapper = (props) => {
  const {variant, position, children} = props;

  if (variant === 'pure') {
    return <FormContainer pure>{children}</FormContainer>;
  }

  if (position === 'start') {
    return (
      <FormStart>
        <FormContainer start="true">{children}</FormContainer>
      </FormStart>
    );
  }

  return (
    <Center style={OverrideStyles.centerWrapper}>
      <FormContainer>{children}</FormContainer>
    </Center>
  );
};

FormWrapper.propTypes = {
  position: PropTypes.oneOf(['start', 'center']),
  variant: PropTypes.oneOf(['pure', 'default']),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

FormWrapper.defaultProps = {
  position: 'center',
  variant: 'default',
  children: null,
};

export default FormWrapper;
