import React from 'react';
import {translate} from '../../../res/language/translate';
import {Container} from './styled-component';
import SnapshotError from '../../../views/error-boundary/snapshot';
import Button from '../../../components/button';
import Header from '../../../components/header';
import * as navigationServices from '../../../utils/navigation/navigation-services';

const NoInternet = () => {
  const onEventRedirect = () => {
    navigationServices.goBack();
  };

  return (
    <Container>
      <SnapshotError size={250} />
      <Header variant="h2">{`${translate('global.noInternet')}`}</Header>
      <Button onClick={onEventRedirect}>{translate('global.tryAgain')}</Button>
    </Container>
  );
};

NoInternet.propTypes = {};

export default NoInternet;
