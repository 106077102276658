import * as typeDef from '../../../../utils/strong-types';
import {regexNoneSymbol} from '../../../../utils/regex';

export const validateAllRow = (listState) => {
  if (typeDef.isNotArrayLength(listState)) {
    return [true, null];
  }
  let isValidate = true;
  const errorList = listState.map((elem) => {
    if (elem.organization && elem.score) {
      return {
        organization: '',
        score: '',
      };
    }
    isValidate = false;
    if (!elem.organization && !elem.score) {
      return {
        organization: 'กรุณากรอกชื่อ',
        score: 'กรุณากรอกคะแนน',
      };
    }
    if (!elem.organization) {
      return {
        organization: 'กรุณากรอกชื่อ',
      };
    }
    return {
      score: 'กรุณากรอกคะแนน',
    };
  });
  return [isValidate, errorList];
};

const validateCustomLanguageName = (text) => {
  return regexNoneSymbol({input: text});
};

export const validateRowLanguage = (languageList = []) => {
  const copyList = [...languageList];
  const result = copyList.map((elem) => {
    if (elem.name.label !== 'อื่นๆ') {
      return elem;
    }
    const item = {...elem};
    if (validateCustomLanguageName(elem.customName)) {
      item.errorText = '';
    } else {
      item.errorText = 'ชื่อภาษาไม่ถูกต้อง';
    }
    return item;
  });
  const isAllValidate = result.filter((elem) => elem.errorText).length === 0;
  return [isAllValidate, result];
};
