import styled from 'styled-components';

export const Title = styled.span`
  font-weight: bold;
`;

export const LocationText = styled.span`
  margin-left: 1rem;
  color: ${(props) => props.theme.textGray};
`;

export const OverrideStyles = {
  rowLocation: {
    paddingBottom: '1rem',
  },
};
