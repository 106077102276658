import styled from 'styled-components';

export const AvatarImg = styled.img`
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.tinGray};
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.2);
  }
  object-fit: contain;
`;

export const AvatarDummy = styled.div`
  max-width: 48px;
  max-height: 48px;
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.grayBackground};
  border: 1px solid ${(props) => props.theme.tinGray};
`;
