import Jobs from '../../res/json/job-cat.json';
import * as generate from '../../utils/generate';
import * as deepState from '../../utils/deep-state';

export const LIMIT_JOB_CAT = 10;

export const createJobCatData = () => {
  const jobCats = Object.keys(Jobs);
  const shapeJobs = [];
  jobCats.forEach((item) => {
    const parentId = generate.generateId('jc');
    const jobItem = {
      id: parentId,
      label: item,
      value: item,
      sub: [],
    };
    if (Jobs[item]?.subcat) {
      const subCats = Object.keys(Jobs[item]?.subcat);
      subCats.forEach((sub) => {
        const subItem = {
          id: generate.generateId('sjc'),
          parentId,
          label: sub,
          value: `${item}|${sub}`,
        };
        jobItem.sub.push(subItem);
      });
    }
    shapeJobs.push(jobItem);
  });
  return shapeJobs;
};

export const mapSelectedByTypes = ({selectedOptions, newItems}) => {
  const {parentItem, childItem} = newItems;
  const newOptions = deepState.cloneDeepState({data: selectedOptions});
  if (parentItem && !childItem) {
    const hasValue = !!newOptions.find((x) => x.id === parentItem.id);
    if (hasValue) {
      const filterOutValue = newOptions.filter((x) => x.id !== parentItem.id);
      return filterOutValue;
    }
    const filterOutChild = newOptions.filter((x) => x.parentId !== parentItem.id);
    const mapSelected = [...filterOutChild, {...parentItem}];
    return mapSelected;
  }
  const hasValue = !!newOptions.find((x) => x.id === childItem.id);
  if (hasValue) {
    const filterOutValue = newOptions.filter((x) => x.id !== childItem.id);
    return filterOutValue;
  }
  const filterOutParent = newOptions.filter((x) => x.id !== childItem.parentId);
  const mapSelected = [...filterOutParent, {...childItem}];
  return mapSelected;
};

export const filterOutById = ({id, selectedOptions}) => {
  if (!selectedOptions) {
    return selectedOptions;
  }
  const filterOut = selectedOptions.filter((x) => x.id !== id);
  return filterOut;
};

export const shapeTextParentCategory = ({text}) => {
  if (!text) {
    return text;
  }
  return `${text}-ทั้งหมด`;
};
