import get from 'lodash/get';

const errorStore = {
  /** new error */
  'access is wrong type': 'หน้านี้สำหรับแอดมินเท่านั้น',
  'access login with manual only': 'เปลี่ยนรหัสผ่านได้เฉพาะ วิธีการเข้าระบบปกติ',
  'access with wrong type': 'หน้านี้สำหรับแอดมินเท่านั้น',
  'admin company not found': 'ไม่พบแอดมินบริษัท',
  "can't read file": 'อ่านไฟล์ไม่สำเร็จ',
  'cannot cal base score': 'เกิดข้อผิดพลาด cal base score',
  'cannot count job interest': 'เกิดข้อผิดพลาด cannot count job interest',
  'cannot create company interest': 'การสนใจไม่สำเร็จ',
  'cannot create job like': 'ไม่สามารถไลก์ได้',
  'cannot create user': 'ไม่สามารถสมัครสมาชิกได้',
  'cannot create user company': 'ไม่สามารถสร้างผู้ใช้งานได้',
  'cannot delete company interest': 'ลบการสนใจไม่สำเร็จ',
  'cannot delete hidden job': 'ไม่สามารถลบงานที่ถูกซ่อนได้',
  'cannot delete search history': 'ไม่สามารถลบประวัติการค้นหาได้',
  'cannot delete user company': 'ไม่สามารถลบผู้ใช้งานได้',
  'cannot generate otp': 'ไม่สามารถสร้างรหัส OTP ได้',
  'cannot generate token': 'ไม่สามารถสร้างโทเคนได้',
  'cannot logout': 'ลงชื่อออกไม่สำเร็จ',
  'cannot set new password': 'ตั้งรหัสผ่านใหม่ไม่สำเร็จ',
  'cannot update company': 'ไม่สามารถแก้ไขข้อมูลได้',
  'cannot update company interest': 'ไม่สามารถแก้ไขข้อมูลได้',
  'cannot update email and password': 'ตั้งอีเมลและรหัสผ่านไม่สำเร็จ',
  'cannot update facebook': 'อัพเดตข้อมูล facebook ไม่สำเร็จ',
  'cannot update job': 'ไม่สามารถแก้ไขข้อมูลได้',
  'cannot update job like': 'ไม่สามารถแก้ไขข้อมูลได้',
  'cannot update notification': 'ไม่สามารถแก้ไขข้อมูลได้',
  'cannot update otp': 'ไม่สามารถอัพเดทรหัส OTP ได้',
  'cannot update password': 'ตั้งรหัสผ่านใหม่ไม่สำเร็จ',
  'cannot update profile': 'ไม่สามารถแก้ไขข้อมูลได้',
  'cannot update user': 'ไม่สามารถแก้ไขข้อมูลได้',
  'cannot upload file into storage': 'อัพโหลดผิดพลาด',
  'category_type not found': 'ไม่พบประเภท',
  'company id already exists': 'มีบริษัทซ้ำในระบบแล้ว',
  'company interest not found': 'ไม่มีข้อมูลที่เลือก',
  'company name is already exists': 'มีชื่อบริษัทซ้ำในระบบแล้ว',
  'company not found': 'ไม่มีบริษัทที่เลือก',
  'company_id not found': 'ไม่มีบริษัทที่เลือก',
  'company_name must be an array': 'ชื่อบริษัทผิดพลาด',
  'district must be an array': 'อำเภอผิดพลาด',
  'draft email not found': 'ไม่พบอีเมลที่ค้นหา',
  'education_requirement must be an array': 'ข้อมูลการศึกษาไม่ถูกต้อง',
  'email already exists': 'มีอีเมลนี้ซ้ำในระบบแล้ว',
  'email must be an array': 'อีเมลไม่ถูกต้อง',
  'email not found': 'ไม่พบอีเมล',
  'file must be csv': 'ไฟล์ต้องมีนามสกุล csv',
  'gender_requirement must be an array': 'เพศไม่ถูกต้อง',
  'hashtags must be an array': 'แท็กไม่ถูกต้อง',
  'hot company not found': 'ไม่พบบริษัท hot company',
  'input not array type': 'การส่งค่าผิดพลาด',
  'invalid author_id': 'รหัสคนประกาศงานไม่ถูกต้อง',
  'invalid author_name': 'ชื่อคนประกาศงานไม่ถูกต้อง',
  'invalid birthday': 'วันเกิดไม่ถูกต้อง',
  'invalid body': 'เนื้อความไม่ถูกต้อง',
  'invalid company': 'บริษัทไม่ถูกต้อง',
  'invalid company email': 'อีเมลบริษัทไม่ถูกต้อง',
  'invalid company id': 'รหัสบริษัทไม่ถูกต้อง',
  'invalid company interest id': 'รหัสการสนใจไม่ถูกต้อง',
  'invalid company name': 'ชื่อบริษัทไม่ถูกต้อง',
  'invalid company tel_no': 'เบอร์โทรศัพท์ไม่ถูกต้อง',
  'invalid contactable_tel': 'เบอร์โทรศัพท์ไม่ถูกต้อง',
  'invalid description': 'คำอธิบายไม่ถูกต้อง',
  'invalid displayName': 'ชื่อไม่ถูกต้อง',
  'invalid district': 'อำเภอไม่ถูกต้อง',
  'invalid district or province': 'จังหวัด/อำเภอไม่ถูกต้อง',
  'invalid education_branch': 'สาขาวิชาไม่ถูกต้อง',
  'invalid education_level': 'ระดับการศึกษาไม่ถูกต้อง',
  'invalid education_requirement': 'วุฒิการศึกษาไม่ถูกต้อง',
  'invalid education_school': 'สถาบันการศึกษาไม่ถูกต้อง',
  'invalid email': 'อีเมลไม่ถูกต้อง',
  'invalid end date': 'วันที่สิ้นสุดไม่ถูกต้อง',
  'invalid end month': 'เดือนไม่ถูกต้อง',
  'invalid end year': 'ปีไม่ถูกต้อง',
  'invalid expected_salary': 'เงินเดือนไม่ถูกต้อง',
  'invalid experience_requirement': 'ประสบการณ์ไม่ถูกต้อง',
  'invalid facebook': 'facebook ไม่ถูกต้อง',
  'invalid first name': 'ชื่อไม่ถูกต้อง',
  'invalid firstname': 'ชื่อไม่ถูกต้อง',
  'invalid gender': 'เพศไม่ถูกต้อง',
  'invalid gender_requirement': 'เพศไม่ถูกต้อง',
  'invalid hidden job post id': 'รหัสประกาศงานไม่ถูกต้อง',
  'invalid hot company id': 'ไม่ถูกต้อง',
  'invalid id': 'การส่งข้อมูลผิดพลาด',
  'invalid job id': 'รหัสประกาศงานไม่ถูกต้อง',
  'invalid job post id': 'รหัสประกาศงานไม่ถูกต้อง',
  'invalid job_experience': 'ประสบการณ์ไม่ถูกต้อง',
  'invalid job_id': 'รหัสประกาศงานไม่ถูกต้อง',
  'invalid job_interest': 'ประเภทงานไม่ถูกต้อง',
  'invalid job_level': 'ระดับตำแหน่งไม่ถูกต้อง',
  'invalid job_type_interest': 'ประเภทงานไม่ถูกต้อง',
  'invalid jobcat': 'ประเภทงานไม่ถูกต้อง',
  'invalid jobcat_interest': 'ประเภทงานไม่ถูกต้อง',
  'invalid language certification score': 'เกียรติบัตรภาษาไม่ถูกต้อง',
  'invalid languages': 'ภาษาไม่ถูกต้อง',
  'invalid last name': 'นามสกุลไม่ถูกต้อง',
  'invalid last_update': 'การอัพเดตไม่ถูกต้อง',
  'invalid lastname': 'นามสกุลไม่ถูกต้อง',
  'invalid lat long': 'พิกัดไม่ถูกต้อง',
  'invalid name': 'ชื่อไม่ถูกต้อง',
  'invalid new password': 'กรุณากรอกรหัสผ่านเดิม',
  'invalid older password': 'กรุณากรอกรหัสผ่านเดิม',
  'invalid otp': 'รหัส OTP ไม่ถูกต้อง',
  'invalid otp number': 'รหัส OTP ไม่ถูกต้อง',
  'invalid page index': 'การส่งค่าผิดพลาด invalid page index',
  'invalid page size': 'การส่งค่าผิดพลาด invalid page size',
  'invalid password': 'รหัสผ่านไม่ถูกต้อง',
  'invalid photoURL': 'รูปไม่ถูกต้อง',
  'invalid platform': 'การส่งค่าผิดพลาด invalid platform',
  'invalid profile url': 'รูปไม่ถูกต้อง',
  'invalid province': 'จังหวัดไม่ถูกต้อง',
  'invalid query': 'คำค้นไม่ถูกต้อง',
  'invalid role': 'สิทธิการใช้งานไม่ถูกต้อง',
  'invalid role company': 'สิทธิการใช้งานไม่ถูกต้อง',
  'invalid salary_max': 'เงินเดือนไม่ถูกต้อง',
  'invalid salary_min': 'เงินเดือนไม่ถูกต้อง',
  'invalid search': 'คำค้นไม่ถูกต้อง',
  'invalid search id': 'รหัสคำค้นไม่ถูกต้อง',
  'invalid start date': 'วันที่ไม่ถูกต้อง',
  'invalid start month': 'เดือนไม่ถูกต้อง',
  'invalid start year': 'ปีไม่ถูกต้อง',
  'invalid subjobcat_interest': 'ประเภทงานไม่ถูกต้อง',
  'invalid tag': 'แท็กไม่ถูกต้อง',
  'invalid tel': 'เบอร์โทรศัพท์ไม่ถูกต้อง',
  'invalid tel_no': 'เบอร์โทรศัพท์ไม่ถูกต้อง',
  'invalid template': 'รูปแบบไม่ถูกต้อง',
  'invalid title': 'ตำแหน่งงานไม่ถูกต้อง',
  'invalid token': 'ข้อมูล token ไม่ถูกต้อง',
  'invalid training course year': 'ข้อมูลปีไม่ถูกต้อง',
  'invalid uid': 'รหัสไม่ถูกต้อง',
  'invalid user id': 'รหัสผู้ใช้งานไม่ถูกต้อง',
  'invalid user_id': 'รหัสผู้ใช้งานไม่ถูกต้อง',
  'invalid version': 'เวอร์ชั่นไม่ถูกต้อง',
  'invalid welfare': 'สวัสดิการไม่ถูกต้อง',
  'is_auto_update must be a boolean': 'การอัพเดตไม่ถูกต้อง',
  'is_pin must be a boolean': 'การปักหมุดไม่ถูกต้อง',
  'job company not found': 'ไม่พบบริษัทที่ค้นหา',
  'job hidden': 'ประกาศงานถูกซ่อน',
  'job id not array type': 'การส่งค่าผิดพลาด job id not array type',
  'job not found': 'ไม่พบประกาศงาน',
  'job unlike not found': 'ไม่สามารถ unlike ได้',
  'job_id must be an array': 'การส่งค่าผิดพลาด job_id must be an array',
  'job_id not found': 'ไม่พบประกาศงาน',
  'job_title must be an array': 'ตำแหน่งงานไม่ถูกต้อง',
  'jobcat must be an array': 'ประเภทงานไม่ถูกต้อง',
  'landing not found': 'ไม่พบหน้าแลนดิ้ง',
  'lat long required': 'พิกัดไม่ถูกต้อง',
  'line id must be an array': 'ไลน์ไม่ถูกต้อง',
  'line_id must be an array': 'ไลน์ไม่ถูกต้อง',
  'missing author_id': 'การส่งค่าผิดพลาด author_id',
  'missing district or province': 'ต้องกรอกจังหวัด',
  'missing parameter': 'การส่งค่าผิดพลาด missing parameter',
  'missing tag': 'ไม่ได้ใส่แท็ก',
  'no files were uploaded': 'ไม่ได้อัพโหลดไฟล์',
  'no images were uploaded': 'ไม่ได้อัพโหลดรูป',
  'no modification': 'ข้อมูลไม่ต่างจากเดิม',
  'old password is not match': 'กรอกรหัสผ่านเดิมไม่ถูกต้อง',
  'only image can be uploaded': 'ประเภทไฟล์ไม่ถูกต้อง',
  'otp not found or expired': 'รหัส OTP หมดอายุ',
  'otp number expired': 'รหัส OTP หมดอายุ',
  'otp number not 6 digits': 'รหัส OTP ไม่ถูกต้อง',
  'otp number not match': 'รหัส OTP ไม่ถูกต้อง',
  'outdated version': 'กรุณาอัพเดท หรือรีเฟรช',
  'page index must be positive number': 'การส่งค่าผิดพลาด page index must be positive number',
  'page size must be positive number': 'การส่งค่าผิดพลาด page size must be positive number',
  'password is not match': 'รหัสผ่านไม่ถูกต้อง',
  'password not found': 'ไม่พบรหัสผ่าน',
  'permission denied': 'สิทธิการใช้งานไม่ถูกต้อง',
  'post image must not be empty': 'ต้องใส่รูปด้วย',
  'post text must not be empty': 'ข้อความสมัครงานไม่ถูกต้อง',
  'post_image must be an array': 'รูปไม่ถูกต้อง',
  'province must be an array': 'จังหวัดไม่ถูกต้อง',
  'role not company and access denied': 'การล็อกอินผิดประเภท',
  'role not user and access denied': 'การล็อกอินผิดประเภท',
  'role wrong type': 'สิทธิการใช้งานไม่ถูกต้อง',
  'salary max must be greater than salary min': 'เงินเดือนไม่ถูกต้อง',
  'salary max must be postive': 'เงินเดือนติดลบไม่ได้',
  'salary min must be postive': 'เงินเดือนติดลบไม่ได้',
  'session expired': 'เซสชันหมดอายุ',
  'setup notification not found': 'ไม้พบการแจ้งเตือน',
  'some information empty': 'ข้อมูลกรอกไม่ครบ',
  'some information empty. Return error msg': 'ข้อมูลกรอกไม่ครบ',
  ' emptyList': 'error 500',
  'start date must occur before end date': 'วันเริ่มต้นต้องมาก่อนวันสิ้นสุด',
  'subjobcat must be an array': 'ประเภทงานไม่ถูกต้อง',
  'tel not found': 'ไม่พบเบอร์โทรศัพท์',
  'tel number must be an array': 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
  'tel number wrong input type': 'เบอร์มือถือต้องเป็นตัวเลขเท่านั้น',
  'tel or email is already exists': 'เบอร์โทรนี้ถูกใช้งานไปแล้ว',
  'test failed': 'เทสไม่ถูกต้อง',
  'the target user company not found': 'ไม่พบผู้ใช้งาน',
  'token is not valid': 'กรุณาลงชื่อเข้าใช้งานใหม่',
  'token not found': 'ไม่พบ token',
  'url not found': 'ไม่พบ url',
  'user company id not found': 'ไม่พบผู้ใช้งาน',
  'user company is not an admin': 'หน้านี้สำหรับแอดมินเท่านั้น',
  'user company not found': 'ไม่พบผู้ใช้งาน',
  'user is already exists': 'มีผู้ใช้งานซ้ำในระบบแล้ว',
  'user not found': 'ไม่พบข้อมูลผู้ใช้งาน',
  'user with the email not found': 'ไม่พบข้อมูลผู้ใช้งาน',
  'validate fail': 'ผิดรูปแบบ',
  'waiting for older otp expire': 'กรุณารอรหัส OTP เก่าหมดอายุก่อนขอรหัส OTP ใหม่อีกครั้ง',
  'welfare must be an array': 'สวัสดิการ',
  'wrong category type': 'ประเภทไม่ถูกต้อง',
  'wrong email format': 'อีเมลไม่ถูกต้อง',
  'wrong picture url format': 'รูปไม่ถูกต้อง',
  'wrong tel number format': 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
  'wrong theme color format': 'ค่าสีไม่ถูกต้อง',

  /** old error */
  'No token, authorization denied': 'เซสชันหมดอายุ',
  'logout success': 'ออกจากระบบสำเร็จ',
  'invalid top': 'เบอร์มือถือไม่ถูกต้อง',
  'cannot create otp': 'ไม่สามารถสร้างรหัส OTP ได้',
  'cannot send sms': 'ไม่สามารถส่ง SMS ได้',
  'email already exist': 'อีเมลนี้ถูกใช้งานแล้ว',
  'invalid surname': 'ชื่อไม่ถูก้อง',
  'invalid sex': 'เพศไม่ถูกต้อง',
  'educations not array type': 'ประวัติการศึกษาไม่ถูกต้อง',
  'works not array type': 'ประวัติการทำงานไม่ถูกต้อง',
  'mongoose validation error': 'เกิดข้อผิดพลาด (500)',
  'invalid destination email': 'อีเมลปลายทางไม่ถูกต้อง',
};

export const getErrorMessage = (key, isDev = false) => {
  const message = get(errorStore, key);
  if (message) {
    return message;
  }
  if (isDev) {
    return key;
  }
  return 'กรุณาลองใหม่อีกครั้ง';
};
