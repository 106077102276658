import React from 'react';
import {useUserState} from '../../../lib/hooks/logic/useUser';
import Divider from '../../../components/divider';
import Loading from '../../../components/loading';
import ProfileAvatar from './avatar';
import LoginInfo from './login-info';
import GeneralInfo from './general-info';
import ExperienceInfo from './experience-info';
import EducationInfo from './education-info';
import SkillAndAchievementInfo from './skill-info';
import InterestInfo from './interest-info';
import OtherInfo from './other-info';
import ResumeInfo from './resume-info';

const ProfileView = () => {
  const {isLoadingState, userState} = useUserState();

  return (
    <>
      <ProfileAvatar />
      <Divider bold />
      {isLoadingState && userState && <Loading loading={isLoadingState} type="action" />}
      {isLoadingState && !userState && <Loading loading={isLoadingState} type="data" />}
      {userState && (
        <>
          <ResumeInfo />
          <Divider edgeInsets bold />
          <LoginInfo />
          <Divider edgeInsets bold />
          <GeneralInfo userState={userState} />
          <Divider edgeInsets bold />
          <InterestInfo userState={userState} />
          <Divider edgeInsets bold />
          <ExperienceInfo userState={userState} />
          <Divider edgeInsets bold />
          <EducationInfo userState={userState} />
          <Divider edgeInsets bold />
          <SkillAndAchievementInfo userState={userState} />
          <Divider edgeInsets bold />
          <OtherInfo userState={userState} />
        </>
      )}
    </>
  );
};

ProfileView.propTypes = {};

export default ProfileView;
