import * as TYPES from './types';

export const reportCreateNewReport = (payload) => ({
  type: TYPES.REPORT_JOB_CREATE_NEW_REPORT_REQ,
  payload: payload,
});

export const generateReportId = () => ({
  type: TYPES.GENERATE_OBJECT_ID_REQ,
});
