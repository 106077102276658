import React from 'react';
import PropTypes from 'prop-types';
import {FacebookShareButton, FacebookMessengerShareButton} from 'react-share';
import {translate} from '../../../../../res/language/translate';
import {useShare} from '../../../../../lib/hooks/logic/useShare';
import {useWebNavigator} from '../../../../../lib/hooks/useWebNavigator';
import {ButtonShareWrapper, ButtonShareText, OverrideStyles} from '../styled-component';
import Icon from '../../../../../components/icon';
import * as ICONS from '../../../../../components/icon/constants';

const FacebookShareDialog = (props) => {
  const {id, title, description, noBorder, onEventCallback, jobData} = props;
  const {getGetShareById, dispatchInterest} = useShare();

  const onClickShare = () => {
    onEventCallback();
    dispatchInterest(id, jobData, 'jobShareFacebook');
  };

  return (
    <FacebookShareButton url={getGetShareById(id)} title={title} quote={description} style={OverrideStyles.buttonShare.fluid} onClick={onClickShare}>
      <ButtonShareWrapper noBorder={noBorder}>
        <Icon name={ICONS.FACEBOOK_SHARE} size={20} color={OverrideStyles.iconInteract.unActive} />
        <ButtonShareText>Facebook</ButtonShareText>
      </ButtonShareWrapper>
    </FacebookShareButton>
  );
};

FacebookShareDialog.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  noBorder: PropTypes.bool,
  onEventCallback: PropTypes.func,
  jobData: PropTypes.object.isRequired,
};

FacebookShareDialog.defaultProps = {
  id: null,
  title: null,
  description: null,
  noBorder: true,
  onEventCallback: () => {},
};

const MessengerShareDialog = (props) => {
  const {id, title, description, noBorder, onEventCallback, jobData} = props;
  const {getGetShareById, dispatchInterest} = useShare();

  const onClickShare = () => {
    onEventCallback();
    dispatchInterest(id, jobData, 'jobShareMessenger');
  };

  return (
    <FacebookMessengerShareButton
      url={getGetShareById(id)}
      title={title}
      quote={description}
      style={OverrideStyles.buttonShare.fluid}
      onClick={onClickShare}>
      <ButtonShareWrapper noBorder={noBorder}>
        <Icon name={ICONS.MESSENGER_SHARE} size={20} color={OverrideStyles.iconInteract.unActive} />
        <ButtonShareText>Messenger</ButtonShareText>
      </ButtonShareWrapper>
    </FacebookMessengerShareButton>
  );
};

MessengerShareDialog.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  noBorder: PropTypes.bool,
  onEventCallback: PropTypes.func,
  jobData: PropTypes.object.isRequired,
};

MessengerShareDialog.defaultProps = {
  id: null,
  title: null,
  description: null,
  noBorder: true,
  onEventCallback: () => {},
};

const CopyShare = (props) => {
  const {id, noBorder, onEventCallback, jobData} = props;
  const {getGetShareById, dispatchInterest} = useShare();
  const {copyTextToClipboard} = useWebNavigator();

  const onClickShare = () => {
    onEventCallback();
    dispatchInterest(id, jobData, 'jobShareUrl');
    const shareUrl = getGetShareById(id);
    copyTextToClipboard(shareUrl);
  };

  return (
    <ButtonShareWrapper onClick={onClickShare} noBorder={noBorder}>
      <Icon name={ICONS.COPY} size={20} color={OverrideStyles.iconInteract.unActive} />
      <ButtonShareText>คัดลอกลิงก์</ButtonShareText>
    </ButtonShareWrapper>
  );
};

CopyShare.propTypes = {
  id: PropTypes.string,
  noBorder: PropTypes.bool,
  onEventCallback: PropTypes.func,
  jobData: PropTypes.object.isRequired,
};

CopyShare.defaultProps = {
  id: null,
  noBorder: true,
  onEventCallback: () => {},
};

const OtherShare = (props) => {
  const {id, title, description, noBorder, onEventCallback, jobData} = props;
  const {getGetShareById, dispatchInterest} = useShare();
  const {shareToNativeApplication} = useWebNavigator();

  const onClickShare = () => {
    onEventCallback();
    dispatchInterest(id, jobData, 'jobShareOther');
    const shareUrl = getGetShareById(id);
    let shareText = '';
    if (title) {
      shareText += `เปิดรับสมัครงานตำแหน่ง ${title}\n`;
    }
    if (description) {
      shareText += `${translate('search.salary')} ${description}`;
    }
    const shareData = {
      title: '',
      text: shareText,
      url: shareUrl,
    };
    shareToNativeApplication(shareData);
  };

  return (
    <ButtonShareWrapper onClick={onClickShare} noBorder={noBorder}>
      <Icon name={ICONS.SHARE_OTHER} size={20} color={OverrideStyles.iconInteract.unActive} />
      <ButtonShareText>แชร์อื่นๆ</ButtonShareText>
    </ButtonShareWrapper>
  );
};

OtherShare.propTypes = {
  id: PropTypes.string,
  noBorder: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onEventCallback: PropTypes.func,
  jobData: PropTypes.object.isRequired,
};

OtherShare.defaultProps = {
  id: null,
  noBorder: true,
  title: '',
  description: '',
  onEventCallback: () => {},
};

export {FacebookShareDialog, MessengerShareDialog, CopyShare, OtherShare};
