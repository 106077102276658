import * as TYPES from './type';

const initialState = {
  loading: false,
  user: null,
  error: null,
  errorStatus: null,
  isInit: false,
  loadingTerm: false,
};

const initManualUploadResume = {
  loading: false,
  data: null,
  error: null,
};

export const userInformationReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case TYPES.USER_SHORT_PROFILE_REQ:
    case TYPES.USER_DETAIL_PROFILE_REQ:
    case TYPES.EDIT_USER_PROFILE_REQ:
    case TYPES.USER_CHANGE_PASSWORD_REQ:
      return {
        ...state,
        loading: true,
      };
    case TYPES.USER_SHORT_PROFILE:
    case TYPES.USER_DETAIL_PROFILE:
    case TYPES.USER_CHANGE_PASSWORD:
      return {
        ...state,
        loading: false,
        user: payload,
        isInit: true,
      };
    case TYPES.CLEAT_USER:
      return {
        ...state,
        user: null,
      };
    case TYPES.EDIT_USER_PROFILE:
      return {
        ...state,
        loading: false,
        user: payload,
        isInit: true,
      };
    case TYPES.USER_ERROR:
      return {
        ...state,
        loading: false,
        error: payload?.message,
        errorStatus: payload?.status,
        isInit: true,
      };
    case TYPES.USER_UPDATE_RESUME:
      return {
        ...state,
        user: {
          ...state.user,
          resume: payload.resume,
          is_resume_auto_gen: false,
          isInit: true,
        },
      };
    case TYPES.USER_UPDATE_AUTO_RESUME:
      return {
        ...state,
        user: {
          ...state.user,
          auto_resume: payload.auto_resume,
          is_resume_auto_gen: true,
          isInit: true,
        },
      };
    case TYPES.USER_SET_INIT:
      return {
        ...state,
        isInit: true,
      };
    case TYPES.USER_ACCEPT_TERM_REQ:
      return {...state, loadingTerm: true};
    case TYPES.USER_ACCEPT_TERM_SUCCESS:
      return {...state, loadingTerm: false, user: {...state.user, terms_and_conditions: payload}};
    case TYPES.USER_ACCEPT_TERM_FAIL:
      return {...state, loadingTerm: false};
    default:
      return state;
  }
};

export const userManualUploadResumeReducer = (state = initManualUploadResume, {type, payload}) => {
  switch (type) {
    case TYPES.USER_UPLOAD_RESUME_MANUAL_REQ:
      return {...state, loading: true, error: null};
    case TYPES.USER_UPLOAD_RESUME_MANUAL_SUCCESS:
      return {...state, loading: false, data: payload};
    case TYPES.USER_UPLOAD_RESUME_MANUAL_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.USER_UPLOAD_RESUME_MANUAL_CLEAR:
      return {...initManualUploadResume};
    default:
      return state;
  }
};
