import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (max-width: 1024px) {
    justify-content: flex-start;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

export const ContentBody = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${(props) => props.theme.white};
  border: 2px solid ${(props) => props.theme.grayBackground};
  border-radius: 8px;
  padding: 1rem;
  overflow-y: auto;
  @media (min-width: 1025px) {
    margin: 0 2rem;
    max-width: 1025px;
    height: 300px;
    max-height: 1200px;
  }
  @media (max-width: 1024px) {
    margin: 0 1rem;
  }
`;

export const ContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

export const TextTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  ${(props) => props.focus && `color: ${props.theme.primary}`}
`;

export const RowTerm = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;
`;

export const TextFocus = styled.span`
  padding-top: 0.2rem;
  color: ${(props) => props.theme.primary};
  cursor: pointer;
  ${(props) => props.active && `text-decoration: underline;`}
  ${(props) => props.left && `margin-left: -1rem;`};
`;

export const TextUnFocus = styled.span`
  padding: 0 0.5rem;
  padding-top: 0.2rem;
`;

export const ButtonGroup = styled.div``;
