import React from 'react';
import {translate} from '../../res/language/translate';
import {useAuth} from '../../lib/hooks/logic/useAuth';
import FaqList from './views/faq-list';
import MetaTag from '../../components/meta-tag';
import Screen from '../../views/screen';

const FAQScreen = () => {
  const {loggedInState, isCheckedAuth} = useAuth();

  if (!isCheckedAuth) {
    return null;
  }

  if (loggedInState) {
    return (
      <>
        <MetaTag title={translate('meta.faq')} description={translate('meta.faq')} />
        <Screen leftSidebar isChildScreen type="single-fluid">
          <FaqList isLoggedIn />
        </Screen>
      </>
    );
  }

  return (
    <>
      <MetaTag title={translate('meta.faq')} description={translate('meta.faq')} />
      <FaqList />
    </>
  );
};

FAQScreen.propTypes = {};

export default FAQScreen;
