import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const CheckboxSalaryWrapper = styled.div`
  margin: -1rem -0.25rem 1rem -0.25rem;
`;

export const Title = styled.span`
  padding-left: 0.75rem;
  font-weight: bold;
  ${(props) => props.danger && `color: ${props.theme.danger}`};
  ${(props) => props.noLeftEdgeInsets && `padding-left: 0`};
`;

export const ModalFilterWrapper = styled.div`
  padding: 1rem;
  background-color: ${(props) => props.theme.white};
`;

export const SearchLabelWrapper = styled.div`
  padding-top: 0.5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const OverrideStyles = {
  iconColor: lightTheme.blueFade,
  divider: {
    margin: '1rem 0',
  },
  inputCompany: {
    marginTop: '-0.5rem',
  },
  rowChip: {
    marginTop: '1rem',
  },
  chipItem: {
    minWidth: 80,
    height: 30,
  },
};
