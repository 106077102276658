/* eslint-disable no-unused-vars */
import React from 'react';
import {translate} from '../../res/language/translate';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import {useSearchLikedEffect, useSearchLikedState} from '../../lib/hooks/logic/useSearchLiked';
import JobPostBuilder from '../../views/job-post-builder';
import Screen from '../../views/screen';
import MetaTags from '../../components/meta-tag';
import Loading from '../../components/loading';

const LikedScreen = () => {
  useSetGrayBackground();
  useSearchLikedEffect();

  const {isEmptyDataState, stackFeedState, isLoadingState, isLoadingRefreshState} = useSearchLikedState();

  return (
    <>
      <MetaTags title={translate('meta.like')} description={translate('meta.like')} />
      <Screen noEdgeInsets isChildScreen>
        <Loading loading={isLoadingRefreshState} type="refresh" />
        <JobPostBuilder
          noTopEdgeInsets
          noBottomEdgeInsets
          data={stackFeedState}
          isEmptyData={isEmptyDataState}
          isLoadingFeed={isLoadingState}
          isLoadingRefresh={isLoadingRefreshState}
          emptyLabel={translate('emptyData.liked')}
        />
      </Screen>
    </>
  );
};

LikedScreen.propTypes = {};

export default LikedScreen;
