import styled from 'styled-components';

export const PostHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const MoreWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const MoreListWrapper = styled.div`
  position: absolute;
  border-radius: 4px;
  padding: 0.5rem 0;
  width: 220px;
  right: 0;
  background-color: ${(props) => props.theme.white};
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1.41px 1.2px;
`;

export const MoreItem = styled.div`
  ${(props) => props.disabled && `cursor: inherit`};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${(props) => props.theme.divider};
  ${(props) => props.noBorder && `border: none`};
  &:hover {
    ${(props) => !props.disabled && `background-color: ${props.theme.chipBackground};`};
  }
`;

export const ItemText = styled.span`
  font-size: 15px;
  padding-left: 0.5rem;
`;
