/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {createJobCatIndex, createTransportIndex, createProvinceIndex} from './data-transform';
import {Container, RowIndex, ColumnIndex, Divider, TextIndex, BodyIndex, OverrideStyles} from './styled-component';
import Header from '../../../../components/header';
import Link from '../../../../components/link';
import * as ROUTES from '../../../../routes/routes-name';
import ENV from '../../../../env.json';

const BoxSEOView = () => {
  const {formatMessage} = useIntl();

  const [topJobCatState, setTopJobCatState] = useState();
  const [topTransportState, setTopTransportState] = useState();
  const [topProvinceState, setTopProvinceState] = useState();

  useEffect(() => {
    if (!topJobCatState) {
      const topList = createJobCatIndex(true);
      setTopJobCatState(topList);
    }
    if (!topTransportState) {
      const topList = createTransportIndex();
      setTopTransportState(topList);
    }
    if (!topProvinceState) {
      const topList = createProvinceIndex();
      setTopProvinceState(topList);
    }
  }, []);

  return (
    <Container>
      <RowIndex>
        <ColumnIndex flex={2}>
          <Header variant="h3">{formatMessage({defaultMessage: 'งานแยกตามประเภทงาน'})}</Header>
          <BodyIndex>
            {Array.isArray(topJobCatState) &&
              topJobCatState.map((item, index) => (
                <Link key={index.toString()} url={item.url} search={item.params}>
                  <TextIndex>{item.value}</TextIndex>
                </Link>
              ))}
            <Link url={ROUTES.ROUTE_JOB_INDEX}>
              <TextIndex>{formatMessage({defaultMessage: 'หมวดหมู่งานทั้งหมด'})}</TextIndex>
            </Link>
            <Link url={ROUTES.ROUTE_COMPANY_INDEX}>
              <TextIndex>{formatMessage({defaultMessage: 'หมวดหมู่บริษัททั้งหมด'})}</TextIndex>
            </Link>
          </BodyIndex>
        </ColumnIndex>
        <ColumnIndex flex={2}>
          <Header variant="h3">{formatMessage({defaultMessage: 'งานแยกตามสถานที่ปฏิบัติงาน'})}</Header>
          <BodyIndex>
            {Array.isArray(topTransportState) &&
              topTransportState.map((item, index) => (
                <Link key={index.toString()} url={item.url} search={item.params}>
                  <TextIndex>{item.value}</TextIndex>
                </Link>
              ))}
            {Array.isArray(topProvinceState) &&
              topProvinceState.map((item, index) => (
                <Link key={index.toString()} url={item.url} search={item.params}>
                  <TextIndex>{item.value}</TextIndex>
                </Link>
              ))}
          </BodyIndex>
        </ColumnIndex>
        <ColumnIndex flex={1}>
          <Header variant="h3">{formatMessage({defaultMessage: 'สำหรับผู้สมัครงาน'})}</Header>
          <Link url={ROUTES.ROUTE_SEARCH}>
            <TextIndex> {formatMessage({defaultMessage: 'ค้นหางาน'})}</TextIndex>
          </Link>
          <Header variant="h3" style={OverrideStyles.headerCompany}>
            {formatMessage({defaultMessage: 'สำหรับบริษัท'})}
          </Header>
          <BodyIndex>
            <Link newTab url={ENV.PUBLIC.COMPANY_URL}>
              <TextIndex> {formatMessage({defaultMessage: 'ลงประกาศรับสมัครงาน'})}</TextIndex>
            </Link>
            {/* <TextIndex> {formatMessage({defaultMessage: 'ค้นหาประวัติ'})}</TextIndex> */}
          </BodyIndex>
        </ColumnIndex>
      </RowIndex>
      <Divider />
    </Container>
  );
};

BoxSEOView.propTypes = {};

export default BoxSEOView;
