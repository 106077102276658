import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const Responsive = (props) => {
  const {minWidth, maxWidth, children} = props;
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    function onSizeChange() {
      const {innerWidth} = window;
      if (minWidth) {
        const isSetVisible = innerWidth >= minWidth;
        if (isSetVisible && !isShow) {
          setIsShow(true);
          return;
        }
        if (!isSetVisible && isShow) {
          setIsShow(false);
        }
      }
      if (maxWidth) {
        const isSetVisible = innerWidth <= maxWidth;
        if (isSetVisible && !isShow) {
          setIsShow(true);
          return;
        }
        if (!isSetVisible && isShow) {
          setIsShow(false);
        }
      }
    }
    window.addEventListener('resize', onSizeChange);
    return () => window.removeEventListener('resize', onSizeChange);
  }, [isShow, minWidth, maxWidth]);

  useEffect(() => {
    const {innerWidth} = window;
    if (minWidth) {
      if (innerWidth >= minWidth) {
        setIsShow(true);
        return;
      }
      setIsShow(false);
    }
    if (maxWidth) {
      if (innerWidth <= maxWidth) {
        setIsShow(true);
        return;
      }
      setIsShow(false);
    }
  }, [minWidth, maxWidth]);

  if (!isShow) {
    return null;
  }

  return <>{children}</>;
};

Responsive.propTypes = {
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  children: PropTypes.any,
};

Responsive.defaultProps = {
  minWidth: null,
  maxWidth: null,
  children: null,
};

export default Responsive;
