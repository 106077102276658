import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import Icon from '../../../../components/icon';
import Column from '../../../../components/column';
import {JobApplyContainer, Container, H5, Text} from './styled-component';

const DATE_FORMAT = {month: 'short', day: 'numeric'};

const MyIcon = (props) => {
  const {isCheck} = props;
  if (isCheck) return <Icon name="check-circle" color="green" size={40} style={{display: 'inline'}} />;
  return <Icon name="close-circle" color="#8D949E" size={40} style={{display: 'inline'}} />;
};
MyIcon.propTypes = {isCheck: PropTypes.bool.isRequired};

const JobApplyStatus = (props) => {
  const {mailSentDate, resumeReadDate} = props;
  const {formatDate, formatMessage} = useIntl();

  return (
    <JobApplyContainer>
      <Container>
        <Column>
          <Container>
            <Column style={{flex: 1, alignItems: 'flex-end'}}>
              <MyIcon isCheck />
            </Column>
            <Column style={{flex: 3}}>
              <H5 isCheck>{formatMessage({defaultMessage: 'อีเมลถูกส่งเรียบร้อย'})}</H5>
              <Text>
                {formatMessage({defaultMessage: 'อีเมล์ถูกส่งเมื่อ'})} {formatDate(mailSentDate, DATE_FORMAT)}
              </Text>
            </Column>
          </Container>
        </Column>
        <Column>
          <Container>
            <Column style={{flex: 1, alignItems: 'flex-end'}}>
              <MyIcon isCheck={!!resumeReadDate} />
            </Column>
            <Column style={{flex: 3}}>
              {resumeReadDate && <H5 isCheck>{formatMessage({defaultMessage: 'บริษัทโหลดเรซูเม่ไปอ่านแล้ว'})}</H5>}
              {resumeReadDate && (
                <Text isCheck>
                  {formatMessage({defaultMessage: 'เรซูเม่ถูกอ่านเมื่อ'})} {formatDate(resumeReadDate, DATE_FORMAT)}
                </Text>
              )}
              {!resumeReadDate && <H5>{formatMessage({defaultMessage: 'บริษัทยังไม่โหลดเรซูเม่ไปอ่าน'})}</H5>}
            </Column>
          </Container>
        </Column>
      </Container>
    </JobApplyContainer>
  );
};

JobApplyStatus.propTypes = {
  mailSentDate: PropTypes.instanceOf(Date).isRequired,
  resumeReadDate: PropTypes.instanceOf(Date),
};

JobApplyStatus.defaultProps = {
  resumeReadDate: null,
};

export default JobApplyStatus;
