import styled from 'styled-components';
import {lightTheme} from '../../../../styles/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HistoryItemWrapper = styled.div`
  padding-top: 0.5rem;
`;

export const Title = styled.span`
  font-weight: bold;
`;

export const EmptyText = styled.span`
  color: ${(props) => props.theme.textGray};
`;

export const TextDelAll = styled.span`
  color: ${(props) => props.theme.danger};
`;

export const HistoryText = styled.span`
  padding: 0.5rem 0;
  color: ${(props) => props.theme.gray};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const OverrideStyles = {
  iconFill: lightTheme.primary,
  iconGray: lightTheme.gray,
  gestureEdgeInsets: {
    marginLeft: '0.5rem',
  },
  rowItem: {
    padding: '0',
  },
  buttonSee: {
    marginTop: '1rem',
    maxWidth: 400,
  },
};
