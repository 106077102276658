import * as ICONS from '../../components/icon/constants';
import * as ROUTES from '../../routes/routes-name';
import {TYPE, TEXT} from './constant';
import {lightTheme} from '../../styles/themes';

export const getIconName = (type) => {
  if (type === TYPE.COMPLETE_PROFILE) {
    return ICONS.NOTIFICATION_IMPORTANT;
  }
  if (type === TYPE.RECOMMENDED_JOB) {
    return ICONS.NOTIFICATION_ACTIVE;
  }
  if (type === TYPE.READ_RESUME) {
    return ICONS.NOTIFICATION;
  }
  return ICONS.NOTIFICATION;
};

export const getIconColor = (type) => {
  if (type === TYPE.COMPLETE_PROFILE) {
    return lightTheme.danger;
  }
  if (type === TYPE.RECOMMENDED_JOB) {
    return lightTheme.yellow;
  }
  if (type === TYPE.READ_RESUME) {
    return lightTheme.primary;
  }
  return lightTheme.primary;
};

export const getLinkLabel = (type) => {
  if (type === TYPE.COMPLETE_PROFILE) {
    return TEXT.COMPLETE_PROFILE;
  }
  if (type === TYPE.RECOMMENDED_JOB) {
    return TEXT.RECOMMENDED_JOB;
  }
  if (type === TYPE.READ_RESUME) {
    return TEXT.READ_RESUME;
  }
  return '';
};

export const getLink = (type) => {
  if (type === TYPE.COMPLETE_PROFILE) {
    return ROUTES.ROUTE_EDIT_PROFILE_GENERAL;
  }
  if (type === TYPE.RECOMMENDED_JOB) {
    return ROUTES.ROUTE_SEARCH_RESULTS;
  }
  if (type === TYPE.READ_RESUME) {
    return ROUTES.ROUTE_APPLY_JOB;
  }
  return ROUTES.ROUTE_NOTIFY;
};

export const getSearchParams = (type, searchParams) => {
  if (type === TYPE.RECOMMENDED_JOB) {
    return searchParams;
  }
  return '';
};
