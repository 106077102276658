import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {translate} from '../../../res/language/translate';
import {FormWrapper, LinkWrapper, CenterWrapper, OverrideStyles} from './styled-component';
import Form from '../../../components/form';
import Input from '../../../components/input';
import Button from '../../../components/button';
import Link from '../../../components/link';
import * as authAction from '../../../redux/auth/action';
import * as validates from './validate';
import * as strFunc from '../../../utils/string-func';
import * as ROUTES from '../../../routes/routes-name';

const RequestForm = () => {
  const dispatch = useDispatch();
  const [phoneState, setPhoneState] = useState('');
  const [errorText, setErrorText] = useState('');

  const onSubmit = (formValues) => {
    const {phone} = formValues;
    const validateResult = validates.validatePhoneNumber({phone});
    const isValidate = !validateResult;
    if (!isValidate) {
      setErrorText(validateResult);
      return;
    }
    const reqBody = {
      tel: strFunc.trimSafely(phone),
    };
    dispatch(authAction.userForgotPasswordRequestCode({data: reqBody}));
  };

  const onChangeText = (value) => {
    if (errorText) {
      setErrorText('');
    }
    setPhoneState(value);
  };

  return (
    <FormWrapper>
      <Form formId="form-reset-pass-request" onSubmit={onSubmit}>
        <Input
          borderOutline
          type="phone"
          name="phone"
          placeholder={translate('forgotPassword.textFieldPhone')}
          value={phoneState}
          onChange={(value) => onChangeText(value)}
          warningText={errorText}
        />
        <Button block type="submit" edgeInsets="none" style={OverrideStyles.button}>
          {translate('forgotPassword.buttonSendCode')}
        </Button>
      </Form>
      <CenterWrapper>
        <Link url={ROUTES.ROUTE_LOGIN}>
          <LinkWrapper>{translate('forgotPassword.buttonBack')}</LinkWrapper>
        </Link>
      </CenterWrapper>
    </FormWrapper>
  );
};

RequestForm.propTypes = {};

export default RequestForm;
