import {regexNumber} from '../../../../utils/regex';
import * as typeDef from '../../../../utils/strong-types';

const LIMIT_SALARY = 50000000;

const checkPositionText = (text) => {
  if (!text) {
    return 'กรุณากรอกตำแหน่งงาน';
  }
  const pattern = /^[a-zA-Z 0-9-_ ก-๙ () & .]+$/;
  if (!pattern.test(text)) {
    return 'กรุณากรอกตำแหน่งงานให้ถูกต้อง';
  }
  return '';
};

export const validateSalary = (salaryMin, salaryMax) => {
  const min = parseInt(salaryMin, 10);
  const max = parseInt(salaryMax, 10);

  if (salaryMin && salaryMax) {
    const isValidateMin = regexNumber({input: salaryMin});
    const isValidateMax = regexNumber({input: salaryMax});

    if (isValidateMin && isValidateMax) {
      if (max < min) {
        return [null, {salaryMax: 'เงินเดือนสูงสุดต้องมากกว่าขั้นต่ำ'}];
      }
      if (max > LIMIT_SALARY) {
        return [null, {salaryMax: 'เงินเดือนไม่ถูกต้อง'}];
      }
      if (min > LIMIT_SALARY) {
        return [null, {salaryMin: 'เงินเดือนไม่ถูกต้อง'}];
      }
      return [{salaryMin, salaryMax}, null];
    }
    const error = {};
    if (!isValidateMax) {
      error.salaryMax = 'เงินเดือนไม่ถูกต้อง';
    }
    if (!isValidateMin) {
      error.salaryMin = 'เงินเดือนไม่ถูกต้อง';
    }
    return [null, error];
  }
  if (!salaryMin && !salaryMin) {
    return [{}, null];
  }
  if (salaryMin) {
    const isValidateMin = regexNumber({input: salaryMin});
    if (!isValidateMin || min > LIMIT_SALARY) {
      return [null, {salaryMin: 'เงินเดือนไม่ถูกต้อง'}];
    }

    return [{salaryMin}, null];
  }
  const isValidateMax = regexNumber({input: salaryMax});
  if (!isValidateMax || max > LIMIT_SALARY) {
    return [null, {salaryMax: 'เงินเดือนไม่ถูกต้อง'}];
  }
  return [{salaryMax}, null];
};

export const validateJobPosition = (positions) => {
  if (typeDef.isNotArrayLength(positions)) {
    return [true, null];
  }
  const errorList = [...positions];
  positions.forEach((elem, index) => {
    const errorMgs = checkPositionText(elem.value);
    if (errorMgs) {
      errorList[index].error = errorMgs;
    } else {
      errorList[index].error = '';
    }
  });
  const isError = errorList.filter((elem) => elem.error !== '').length;
  return [!isError, errorList];
};
