import {storage} from './index';

export const uploadSingleImage = async (_id, name, image) => {
  const ans = await new Promise((resolve, reject) => {
    const uploadTask = storage.ref(`${_id}/${name}`).put(image.file);

    uploadTask.on(
      'state_changed',
      (snapshot) => Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        reject(error);
      },
      () => {
        storage
          .ref(`${_id}`)
          .child(name)
          .getDownloadURL()
          .then((url) => resolve(url));
      },
    );
  });

  return ans;
};

export const uploadResume = async (_id, name, file) => {
  const ans = await new Promise((resolve, reject) => {
    const uploadTask = storage.ref(`${_id}/${name}`).put(file.file);

    uploadTask.on(
      'state_changed',
      (snapshot) => Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        reject(error);
      },
      () => {
        storage
          .ref(`${_id}`)
          .child(name)
          .getDownloadURL()
          .then((url) => resolve(url));
      },
    );
  });

  return ans;
};

export const uploadFile = async (_id, name, file) => {
  const ans = await new Promise((resolve, reject) => {
    const randomId = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
    const nameRef = name ? `${name}-${randomId}` : `file-${randomId}`;
    const uploadTask = storage.ref(`${_id}/${nameRef}`).put(file.file);

    uploadTask.on(
      'state_changed',
      (snapshot) => Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        reject(error);
      },
      () => {
        storage
          .ref(`${_id}`)
          .child(nameRef)
          .getDownloadURL()
          .then((url) => resolve(url));
      },
    );
  });

  return ans;
};

export const uploadJobReport = async (_id, name, file) => {
  const ans = await new Promise((resolve, reject) => {
    const uploadTask = storage.ref(`jobReport/${_id}/${name}`).put(file.file);

    uploadTask.on(
      'state_changed',
      (snapshot) => Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        reject(error);
      },
      () => {
        storage
          .ref(`jobReport/${_id}`)
          .child(name)
          .getDownloadURL()
          .then((url) => resolve(url));
      },
    );
  });

  return ans;
};

export const deleteFile = async (fileName) => {
  try {
    const desertRef = storage.refFromURL(fileName);
    const res = await desertRef.delete();
    return [res, null];
  } catch (error) {
    return [null, error];
  }
};
