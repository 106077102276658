import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';

const FILE_IMAGES_SUPPORT = ['image/jpeg', 'image/png', 'image/jpg'];
const FILE_SUPPORT = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  ...FILE_IMAGES_SUPPORT,
];

export const webImagePickerManager = async ({event, multiple = false}) => {
  if (!event) {
    throw new Error('cannot select file maybe browser is not support');
  }
  event.stopPropagation();
  event.preventDefault();
  if (!event?.target?.files?.length) {
    throw new Error('empty selected images');
  }
  const keySupportImages = keyBy(FILE_IMAGES_SUPPORT);
  const {files} = event.target;
  if (!multiple) {
    if (!keySupportImages[files[0]?.type]) {
      throw new Error('image type not support');
    }
    return {
      file: files[0],
      uri: URL.createObjectURL(files[0]),
      name: files[0]?.name,
      type: files[0]?.type,
      size: files[0]?.size,
    };
  }
  const arrayFiles = [...files];
  const resultImages = [];
  forEach(arrayFiles, (item) => {
    if (!keySupportImages[item.type]) {
      return;
    }
    resultImages.push({
      uri: URL.createObjectURL(item),
      name: item.name,
      type: item.type,
      size: item.size,
    });
  });
  return resultImages;
};

export const webFilePickerManager = async ({event, multiple = false}) => {
  if (!event) {
    throw new Error('cannot select file maybe browser is not support');
  }
  event.stopPropagation();
  event.preventDefault();
  if (!event?.target?.files?.length) {
    throw new Error('empty selected files');
  }
  const keySupportFiles = keyBy(FILE_SUPPORT);
  const {files} = event.target;
  if (!multiple) {
    if (!keySupportFiles[files[0]?.type]) {
      throw new Error('file type was not support');
    }
    return {
      file: files[0],
      uri: URL.createObjectURL(files[0]),
      name: files[0]?.name,
      type: files[0]?.type,
      size: files[0]?.size,
    };
  }
  const arrayFiles = [...files];
  const resultFiles = [];
  forEach(arrayFiles, (item) => {
    if (!keySupportFiles[item.type]) {
      return;
    }
    resultFiles.push({
      uri: URL.createObjectURL(item),
      name: item.name,
      type: item.type,
      size: item.size,
    });
  });
  return resultFiles;
};
