import {useDispatch} from 'react-redux';
import * as actions from '../../../redux/user/action';

export const useUpdateTermDispatch = () => {
  const dispatch = useDispatch();

  const dispatchUpdateTerm = (term) => {
    if (!term) {
      return;
    }
    dispatch(actions.userAcceptTerm({data: term}));
  };

  return {dispatchUpdateTerm};
};
