import * as typeDef from '../../utils/strong-types';
import * as generate from '../../utils/generate';
import {translate} from '../../res/language/translate';
import SVGTrain from '../../res/assets/images/svg/electric-train.svg';
import SVGBus from '../../res/assets/images/svg/bus.svg';
import SVGMall from '../../res/assets/images/svg/mall.svg';
import SVGFactory from '../../res/assets/images/svg/factory.svg';

export const createProvincesData = (rawProvinces, addAll) => {
  if (typeDef.isNotArrayLength(rawProvinces)) {
    return [];
  }
  const provinces = [];
  if (addAll) {
    provinces.push({
      id: generate.generateId('PV'),
      label: 'ทุกจังหวัด',
      value: 'ทุกจังหวัด',
    });
  }
  rawProvinces.forEach((element) => {
    const item = {
      id: generate.generateId('PV'),
      label: element,
      value: element,
    };
    provinces.push(item);
  });
  return provinces;
};

export const createDistrictsData = (rawDistricts) => {
  if (typeDef.isNotArrayLength(rawDistricts)) {
    return [];
  }
  const districts = [];
  rawDistricts.forEach((element) => {
    const startIndex = element.indexOf('|');
    const item = {
      id: generate.generateId('DT'),
      label: element.substr(startIndex + 1, element.length),
      value: element,
    };
    districts.push(item);
  });
  return districts;
};

export const getSelectedLabel = (isLoading, isEmpty, selectedProvince, placeholder) => {
  if (isLoading || isEmpty) {
    return translate('landing.preLoading');
  }
  if (placeholder && typeDef.isNotArrayLength(selectedProvince)) {
    return placeholder;
  }
  if (!Array.isArray(selectedProvince)) {
    return translate('editProfile.general.province');
  }
  const labelMap = selectedProvince.map((element) => element.label);
  return labelMap.join(', ');
};

export const getSelectedDistrictLabel = (isLoading, isEmpty, selected) => {
  if (isLoading || isEmpty) {
    return translate('landing.preLoading');
  }
  if (!Array.isArray(selected) || !selected.length) {
    return translate('editProfile.general.district');
  }
  const labelMap = selected.map((element) => element.label);
  return labelMap.join(', ');
};

export const checkIsSelectedById = ({id, selectedOptions}) => {
  if (!id || typeDef.isNotArrayLength(selectedOptions)) {
    return false;
  }
  const isSelected = selectedOptions.find((x) => x.id === id);
  return !!isSelected;
};

export const checkIsSelectedByValue = ({value, selectedOptions}) => {
  if (!value || typeDef.isNotArrayLength(selectedOptions)) {
    return false;
  }
  const isSelected = selectedOptions.find((x) => x.value === value);
  return !!isSelected;
};

export const getSvgByTag = (tag) => {
  switch (tag) {
    case 'BTS':
      return SVGTrain;
    case 'MRT':
      return SVGBus;
    case 'เซ็นทรัล':
      return SVGMall;
    case 'นิคมอุตสาหกรรม':
      return SVGFactory;
    default:
      return null;
  }
};

export const checkIsActiveTag = (list, targetTag) => {
  if (!targetTag || typeDef.isNotArrayLength(list)) {
    return false;
  }
  const isSelected = list.find((elem) => elem === targetTag);
  return !!isSelected;
};
