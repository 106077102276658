import {useState, createContext, useContext, useCallback} from 'react';
import {IntlProvider, useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import thLang from '../res/i18n/th.json';

const LangContext = createContext('th');

const loadLocaleData = (locale) => {
  switch (locale) {
    case 'en':
      return import('../res/i18n/en.json');
    case 'th':
      return Promise.resolve(thLang);
    default:
      throw new Error('unknown language: ', locale);
  }
};

const useI18n = () => {
  const ans = useContext(LangContext);
  if (!ans) throw new Error('I18nProvider not installed');
  return ans;
};

let intlCache = {formatMessage: () => ''};
const getIntl = () => intlCache;
const ReferenceLeaker = (props) => {
  intlCache = useIntl();
  return props.children;
};

const I18nProvider = (props) => {
  const {children} = props;
  const [lang, setLang] = useState({lang: 'th', data: thLang});

  const cb = useCallback((newLang) => {
    loadLocaleData(newLang).then((data) => setLang({lang: newLang, data}));
  }, []);

  return (
    <LangContext.Provider value={[lang.lang, cb]}>
      <IntlProvider locale={lang.lang} defaultLocale="th" messages={lang.data}>
        <ReferenceLeaker>{children}</ReferenceLeaker>
      </IntlProvider>
    </LangContext.Provider>
  );
};

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {I18nProvider, useI18n, getIntl};
