import * as TYPES from './type';

export const alertSetAlert = (payload) => ({
  type: TYPES.ALERT_SET_ALERT_REQ,
  payload: payload,
});

export const alertRemoveAlert = (payload) => ({
  type: TYPES.ALERT_REMOVE_ALERT_REQ,
  payload: payload,
});
