export const LIKE_NEW_LIKE_REQ = '@LIKE/NEW_LIKE_REQ';
export const LIKE_NEW_LIKE_SUCCESS = '@LIKE/NEW_LIKE_SUCCESS';
export const LIKE_NEW_LIKE_FAIL = '@LIKE/NEW_LIKE_FAIL';

export const LIKE_UNLIKE_REQ = '@LIKE/UNLIKE_REQ';
export const LIKE_UNLIKE_SUCCESS = '@LIKE/UNLIKE_SUCCESS';
export const LIKE_UNLIKE_FAIL = '@LIKE/UNLIKE_FAIL';

export const LIKE_UPDATE_STREAM_REQ = '@LIKE/UPDATE_STREAM_REQ';
export const LIKE_UPDATE_STREAM_SUCCESS = '@LIKE/UPDATE_STREAM_SUCCESS';
export const LIKE_UPDATE_STREAM_FAIL = '@LIKE/UPDATE_STREAM_FAIL';
