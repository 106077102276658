import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {NavWrapper, NavCenter, NavTitleWrapper, NavTitle, NavSubTitle, OverrideStyles} from './styled-component';
import Searcher from '../searcher';
import Icon from '../../components/icon';
import GestureDetector from '../../components/gesture';
import AppLogo from '../logo/logo';
import * as navigationServices from '../../utils/navigation/navigation-services';
import * as logic from './logic';
import * as ROUTES from '../../routes/routes-name';
import * as ICONS from '../../components/icon/constants';

const ScreenNavbar = (props) => {
  const {title, subTitle} = props;
  const {pathname} = useLocation();
  const [screenTitle, setScreenTitle] = useState(null);
  const isParentRoute = logic.matchParentRoute(pathname);

  useEffect(() => {
    if (pathname && title) {
      setScreenTitle(title);
    }
    if (pathname && !title) {
      const defaultTitle = logic.getDefaultScreenTitle(pathname);
      setScreenTitle(defaultTitle);
    }
  }, [pathname, title]);

  const onEventGoBack = () => {
    if (logic.matchUrlRouteEdit(pathname)) {
      navigationServices.navigateReplaceTo({pathname: ROUTES.ROUTE_PROFILE});
      return;
    }
    navigationServices.goBack();
  };

  const onEventGoTimeline = () => {
    navigationServices.navigateReplaceTo({pathname: ROUTES.ROUTE_HOME});
  };

  return (
    <NavWrapper>
      {!isParentRoute && (
        <GestureDetector withEdgeInsets={false} style={OverrideStyles.leftArrow} onClick={onEventGoBack}>
          <Icon name={ICONS.ARROW_LEFT} size={36} />
        </GestureDetector>
      )}
      {isParentRoute && (
        <GestureDetector withEdgeInsets={false} style={OverrideStyles.leftArrow} onClick={onEventGoTimeline}>
          <AppLogo />
        </GestureDetector>
      )}
      <NavCenter>
        <NavTitleWrapper>
          <NavTitle hasSubTitle={!!subTitle}>{screenTitle}</NavTitle>
          {subTitle && <NavSubTitle>{subTitle}</NavSubTitle>}
        </NavTitleWrapper>
      </NavCenter>
      <Searcher />
    </NavWrapper>
  );
};
ScreenNavbar.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

ScreenNavbar.defaultProps = {
  title: null,
  subTitle: null,
};

export default ScreenNavbar;
