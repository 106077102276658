import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {OverrideStyles} from './styled-component';

const SkeletonLoading = memo((props) => {
  const {type, count, width, height, egeInsets} = props;
  const innerStyle = egeInsets ? OverrideStyles.edgeInsets : null;
  return (
    <SkeletonTheme>
      <Skeleton style={innerStyle} circle={type === 'circle'} count={count} width={width} height={height} />
    </SkeletonTheme>
  );
});

SkeletonLoading.propTypes = {
  type: PropTypes.oneOf(['post', 'chip', 'circle', 'text']),
  count: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  egeInsets: PropTypes.bool,
};

SkeletonLoading.defaultProps = {
  type: 'text',
  count: 1,
  width: 0,
  height: 0,
  egeInsets: true,
};

export default SkeletonLoading;
