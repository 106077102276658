import {takeEvery, takeLatest} from 'redux-saga/effects';
import * as TYPES from './type';
import * as userSaga from './saga';

export function* watchUserShortProfile() {
  yield takeLatest(TYPES.USER_SHORT_PROFILE_REQ, userSaga.userShortProfile);
}

export function* watchUserDetailProfile() {
  yield takeLatest(TYPES.USER_DETAIL_PROFILE_REQ, userSaga.userDetailProfile);
}

export function* watchClearUser() {
  yield takeEvery(TYPES.CLEAR_USER_REQ, userSaga.clearStateUserProfile);
}

export function* watchEditUserProfile() {
  yield takeEvery(TYPES.EDIT_USER_PROFILE_REQ, userSaga.editUserProfile);
}

export function* watchUserChangePassword() {
  yield takeEvery(TYPES.USER_CHANGE_PASSWORD_REQ, userSaga.userChangePassword);
}

export function* watchUserManualUploadResume() {
  yield takeEvery(TYPES.USER_UPLOAD_RESUME_MANUAL_REQ, userSaga.userManualUploadProfile);
}

export function* watchUserAutoGenerateResume() {
  yield takeEvery(TYPES.USER_GENERATE_AUTO_RESUME_REQ, userSaga.userAutoGenerateResume);
}

export function* watchUserAcceptTerm() {
  yield takeEvery(TYPES.USER_ACCEPT_TERM_REQ, userSaga.userAcceptTermAction);
}
