import styled from 'styled-components';
import {lightTheme} from '../../../styles/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.grayBackground};
  @media (min-width: 1025px) {
    min-height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.white};
  @media (min-width: 1025px) {
    margin: 3rem 0;
    width: 680px;
    height: auto;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 1.41px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 450px;
  padding: 1rem 0;
`;

export const ThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
  border-radius: 75px;
  background-color: ${(props) => props.theme.gray239};
  margin: 2.5rem 0;
`;

export const ThumbnailImg = styled.img`
  height: 80px;
  width: 80px;
`;

export const LinkWrapper = styled.p`
  color: ${(props) => props.theme.primary};
  text-decoration: underline;
`;

export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

export const RowTerm = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
`;

export const TextFocus = styled.span`
  padding-top: 0.2rem;
  color: ${(props) => props.theme.primary};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  ${(props) => props.left && `margin-left: -1rem;`};
`;

export const TextUnFocus = styled.span`
  padding: 0 0.5rem;
  padding-top: 0.2rem;
`;

export const OverrideStyles = {
  textHeader: {
    color: lightTheme.primary,
  },
  textBottom: {
    margin: '1rem 0',
  },
  container: {
    padding: '4rem 2rem',
  },
  button: {
    marginTop: '1rem',
    marginBottom: '0.5rem',
  },
  rowButton: {
    marginTop: '1rem',
  },
};
