import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => props.paddingRem && `padding: ${props.paddingRem}rem`};
  ${(props) => props.paddingPixel && `padding: ${props.paddingPixel}px`};
  ${(props) => props.marginRem && `margin: ${props.marginRem}rem`};
  ${(props) => props.marginPixel && `margin: ${props.marginPixel}px`};
  ${(props) => props.noEdgeInsets && `padding: 0; margin: 0;`};
`;
