import styled from 'styled-components';

export const FormStart = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 2rem 0;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 500px;
  @media (max-width: 1024px) {
    padding: 0 2rem;
    ${(props) => !props.pure && `margin-top: 56px;`};
    ${(props) => props.start && `  justify-content: flex-start`};
  }
`;

export const OverrideStyles = {
  centerWrapper: {
    minHeight: 450,
  },
};
