import {useSelector, useDispatch} from 'react-redux';
import * as searchActions from '../../../redux/search/action';
// import * as typeDef from '../../../utils/strong-types';

export const useFormSearchState = () => {
  const formSearchState = useSelector((state) => state.search.jobQuery.form);
  return {
    formSearchState,
  };
};

export const useFormSearchDispatch = () => {
  const dispatch = useDispatch();
  const dispatchUpdateForm = (form) => {
    if (!form) {
      return;
    }
    dispatch(searchActions.searchSaveForm({data: {...form}}));
  };
  const dispatchClearForm = () => {
    dispatch(searchActions.searchSaveForm({clear: true}));
  };

  return {
    dispatchUpdateForm,
    dispatchClearForm,
  };
};
