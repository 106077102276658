export const NOTIFY_LIST_REQ = '@NOTIFY/LIST_REQ';
export const NOTIFY_LIST_SUCCESS = '@NOTIFY/LIST_SUCCESS';
export const NOTIFY_LIST_FAIL = '@NOTIFY/LIST_FAIL';

export const NOTIFY_REMOVE_REQ = '@NOTIFY/REMOVE_REQ';
export const NOTIFY_REMOVE_SUCCESS = '@NOTIFY/REMOVE_SUCCESS';
export const NOTIFY_REMOVE_FAIL = '@NOTIFY/REMOVE_FAIL';

export const NOTIFY_UPDATE_READ_REQ = '@NOTIFY/UPDATE_READ_REQ';
export const NOTIFY_UPDATE_READ_SUCCESS = '@NOTIFY/UPDATE_READ_SUCCESS';
export const NOTIFY_UPDATE_READ_FAIL = '@NOTIFY/UPDATE_READ_FAIL';

export const NOTIFY_SETTING_REQ = '@NOTIFY/SETTING_REQ';
export const NOTIFY_SETTING_SUCCESS = '@NOTIFY/SETTING_SUCCESS';
export const NOTIFY_SETTING_FAIL = '@NOTIFY/SETTING_FAIL';

export const NOTIFY_UPDATE_SETTING_REQ = '@NOTIFY/UPDATE_SETTING_REQ';
export const NOTIFY_UPDATE_SETTING_SUCCESS = '@NOTIFY/UPDATE_SETTING_SUCCESS';
export const NOTIFY_UPDATE_SETTING_FAIL = '@NOTIFY/UPDATE_SETTING_FAIL';

export const NOTIFY_COUNT_NEW_REQ = '@NOTIFY/COUNT_NEW_REQ';
export const NOTIFY_COUNT_NEW_SUCCESS = '@NOTIFY/COUNT_NEW_SUCCESS';
export const NOTIFY_COUNT_NEW_FAIL = '@NOTIFY/COUNT_NEW_FAIL';

export const NOTIFY_UPDATE_NOTIFICATION_SETTING_REQ = '@NOTIFY/NOTIFY_UPDATE_NOTIFICATION_SETTING_REQ';
export const NOTIFY_UPDATE_NOTIFICATION_SETTING_SUCCESS = '@NOTIFY/NOTIFY_UPDATE_NOTIFICATION_SETTING_SUCCESS';
export const NOTIFY_UPDATE_NOTIFICATION_SETTING_FAIL = '@NOTIFY/NOTIFY_UPDATE_NOTIFICATION_SETTING_FAIL';

export const NOTIFY_SETTING_MODAL_REQ = '@NOTIFY/NOTIFY_SETTING_MODAL_REQ';
export const NOTIFY_SETTING_MODAL_SUCCESS = '@NOTIFY/NOTIFY_SETTING_MODAL_SUCCESS';

export const NOTIFY_SAVE_FORM_REQ = '@NOTIFY/NOTIFY_SAVE_FORM_REQ';
export const NOTIFY_SAVE_FORM_SUCCESS = '@NOTIFY/NOTIFY_SAVE_FORM_SUCCESS';
export const NOTIFY_SAVE_FORM_CLEAR = '@NOTIFY/NOTIFY_SAVE_FORM_CLEAR';

export const NOTIFY_UPDATE_INTERESTED_JOB_REQ = '@NOTIFY/NOTIFY_UPDATE_INTERESTED_JOB_REQ';
export const NOTIFY_UPDATE_INTERESTED_JOB_SUCCESS = '@NOTIFY/NOTIFY_UPDATE_INTERESTED_JOB_SUCCESS';
export const NOTIFY_UPDATE_INTERESTED_JOB_FAIL = '@NOTIFY/NOTIFY_UPDATE_INTERESTED_JOB_FAIL';

export const NOTIFY_GET_DATA_BY_SIGNED_URL_REQ = '@NOTIFY/GET_DATA_BY_SIGNED_URL_REQ';
export const NOTIFY_GET_DATA_BY_SIGNED_URL_SUCCESS = '@NOTIFY/GET_DATA_BY_SIGNED_URL_SUCCESS';
export const NOTIFY_GET_DATA_BY_SIGNED_URL_FAIL = '@NOTIFY/GET_DATA_BY_SIGNED_URL_FAIL';
export const NOTIFY_GET_DATA_BY_SIGNED_URL_CLEAR = '@NOTIFY/GET_DATA_BY_SIGNED_URL_CLEAR';

export const NOTIFY_UPDATE_DATA_BY_SIGNED_URL_REQ = '@NOTIFY/UPDATE_DATA_BY_SIGNED_URL_REQ';
export const NOTIFY_UPDATE_DATA_BY_SIGNED_URL_SUCCESS = '@NOTIFY/UPDATE_DATA_BY_SIGNED_URL_SUCCESS';
export const NOTIFY_UPDATE_DATA_BY_SIGNED_URL_FAIL = '@NOTIFY/UPDATE_DATA_BY_SIGNED_URL_FAIL';
