import store from '../../../redux/store';
import * as snackbarActions from '../../../redux/snackbar/action';

// appearance type oneOf(['success', 'error', 'warning', 'info'])

export const setSnackbarMessage = (message, type) => {
  if (!message) {
    return;
  }
  store.dispatch(snackbarActions.snackbarSetMessage({data: {message, type}}));
};
