import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocationContextState} from '../../lib/hooks/logic/useLocation';
import {translate} from '../../res/language/translate';
import {isArrayLength} from '../../utils/strong-types';
import {
  InputField,
  InputWrapper,
  InputLabel,
  BottomWrapper,
  SelectBoxWrapper,
  SelectBox,
  OverrideStyles,
  ModalWrapper,
} from '../../components/select/styled-component';
import OptionItem from '../../components/select/sub-comp';
import {Container, InputHint} from './styled-component';
import Row from '../../components/row';
import Icon from '../../components/icon';
import Text from '../../components/text';
import Modal from '../../components/modal';
import Button from '../../components/button';
import * as ICONS from '../../components/icon/constants';
import * as logic from './logic';

const SelectDistrict = (props) => {
  const {outline, iconLabel, selectedDistrict, label, warningText, disabled, onChange, manualData} = props;

  const {contextDistrictState, isLoadingDistrictState} = useLocationContextState();

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [districtState, setDistrictState] = useState([]);
  const isEmptyProvince = !Array.isArray(districtState) && !districtState.length;

  useEffect(() => {
    if (Array.isArray(contextDistrictState) || Array.isArray(manualData)) {
      const districtData = manualData || contextDistrictState;
      const transformDistrict = logic.createDistrictsData(districtData);
      setDistrictState(transformDistrict);
    }
  }, [contextDistrictState, manualData]);

  const onChangeProvince = (item) => {
    if (!onChange) {
      return;
    }
    onChange(item);
  };

  const onConfirmChange = () => {
    setIsVisibleModal(false);
  };

  return (
    <>
      <Modal visible={isVisibleModal} onRequestClose={() => setIsVisibleModal(false)}>
        <ModalWrapper>
          <SelectBoxWrapper id="slb">
            <SelectBox>
              {isArrayLength(districtState) &&
                districtState.map((item) => (
                  <OptionItem
                    key={item.id}
                    forceChild
                    noneRightArrow
                    isChecked={logic.checkIsSelectedByValue({value: item.value, selectedOptions: selectedDistrict})}
                    text={item.label}
                    parentId={item.id}
                    openChildList={{}}
                    selectedOptions={selectedDistrict}
                    onChange={() => onChangeProvince(item)}
                  />
                ))}
            </SelectBox>
          </SelectBoxWrapper>
          <BottomWrapper>
            <Button block onClick={() => onConfirmChange()}>
              {translate('global.confirm')}
            </Button>
          </BottomWrapper>
        </ModalWrapper>
      </Modal>
      <Container>
        <Row>
          {!iconLabel && <InputLabel>{label}</InputLabel>}
          {iconLabel && (
            <>
              <Icon name={iconLabel} color={OverrideStyles.icon.blueFade} />
              <InputLabel edgeInsets>{label}</InputLabel>
            </>
          )}
        </Row>
        <InputWrapper
          outline={outline}
          disabled={disabled}
          grayBackground={!outline}
          onClick={() => {
            if (!isEmptyProvince && !disabled) {
              setIsVisibleModal(true);
            }
          }}
          style={OverrideStyles.inputWrapper.locationPadding}>
          <Icon name={ICONS.LOCATION} color={OverrideStyles.icon.color} />
          <InputField id="district" name="location-district" value={JSON.stringify(selectedDistrict)} onChange={() => {}} />
          <Text color={OverrideStyles.placeholder.color} style={OverrideStyles.text}>
            {logic.getSelectedDistrictLabel(isLoadingDistrictState, isEmptyProvince, selectedDistrict)}
          </Text>
        </InputWrapper>
        {warningText && <InputHint warning>{warningText}</InputHint>}
      </Container>
    </>
  );
};

SelectDistrict.propTypes = {
  label: PropTypes.string,
  warningText: PropTypes.string,
  selectedDistrict: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  iconLabel: PropTypes.string,
  outline: PropTypes.bool,
  manualData: PropTypes.array,
};

SelectDistrict.defaultProps = {
  label: null,
  warningText: null,
  outline: false,
  iconLabel: null,
  disabled: false,
  selectedDistrict: null,
  manualData: null,
};

export default SelectDistrict;
