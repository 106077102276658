import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useSearchCountState} from '../../../lib/hooks-logic/useSearchResultCount';
import {useWindowResizeEffect} from '../../../lib/hooks/useWindowResizeEffect';
import {useSearchLikedDispatch, useSearchLikedSingleState} from '../../../lib/hooks/logic/useSearchLiked';
import {useSearchTimelineDispatch, useSearchTimelineSingleState} from '../../../lib/hooks/logic/useSearchTimeline';
import {
  Container,
  NavUlWrapper,
  NavLiItem,
  NavContainer,
  CenterAbsolute,
  NavCenter,
  SpanIcon,
  OverrideStyles,
  NavTitle,
  NavSubTitle,
  NavTitleWrapper,
  LeftWrapper,
  GoBackWrapper,
  TextCount,
  Badge,
} from './styled-component';
import AppLogo from '../../logo/logo';
import Link from '../../../components/link';
import Searcher from '../../searcher';
import Icon from '../../../components/icon';
import LAYOUT from '../../../styles/layout';
import * as ICONS from '../../../components/icon/constants';
import * as ROUTES from '../../../routes/routes-name';
import * as logic from './logic';
import * as typeDef from '../../../utils/strong-types';
import {goBack} from '../../../utils/navigation/navigation-services';

const LoggedInAppBar = () => {
  const {pathname} = useLocation();
  const {windowWidth} = useWindowResizeEffect();
  const {resultCount} = useSearchCountState();
  const {singleState: isLoadingRefreshLikeState} = useSearchLikedSingleState('loadingRefresh');
  const {singleState: isLoadingLikeState} = useSearchLikedSingleState('loading');
  const {singleState: isLoadingRefreshTimelineState} = useSearchTimelineSingleState('loadingRefresh');
  const {singleState: isLoadingTimelineState} = useSearchTimelineSingleState('loading');
  const {dispatchFetchRefreshTimeline} = useSearchTimelineDispatch();
  const {dispatchFetchRefreshLiked} = useSearchLikedDispatch();
  const [forceRefresh, setForceRefresh] = useState(false);

  const isActiveHome = pathname === ROUTES.ROUTE_HOME;
  const isActiveLiked = pathname === ROUTES.ROUTE_LIKE;
  const isActiveNotify = pathname === ROUTES.ROUTE_NOTIFY;
  const isActiveApplyJob = pathname === ROUTES.ROUTE_APPLY_JOB;
  const isActiveMenu = logic.isActiveSubMenu(pathname);
  const isAcceptPolicyRoute = pathname === ROUTES.ROUTE_ACCEPT_POLICY;

  const isLargeContent = windowWidth > LAYOUT.dimension.ipadProWidth;
  const likeCount = useSelector((state) => state.likes.likes?.likes);
  const applyJobCount = useSelector((state) => state.listApplyJob?.jobs);
  const notificationCountNew = useSelector((state) => state.notify.notifyList?.countNew);

  useEffect(() => {
    if (window.pageYOffset === 0 && forceRefresh) {
      if (isActiveHome) {
        dispatchFetchRefreshTimeline();
      }
      if (isActiveLiked) {
        dispatchFetchRefreshLiked();
      }
      setForceRefresh(false);
    }

    const onScrolling = () => {
      if (!forceRefresh || window.pageYOffset > 0) {
        return;
      }
      if (isActiveHome) {
        dispatchFetchRefreshTimeline();
      }
      if (isActiveLiked) {
        dispatchFetchRefreshLiked();
      }
      setForceRefresh(false);
    };
    window.addEventListener('scroll', onScrolling);
    return () => window.removeEventListener('scroll', onScrolling);
  }, [isActiveHome, isActiveLiked, forceRefresh]);

  const onClickNavMenu = (path) => {
    if (path !== ROUTES.ROUTE_HOME && path !== ROUTES.ROUTE_LIKE) {
      return;
    }
    if (path === ROUTES.ROUTE_HOME && pathname === ROUTES.ROUTE_HOME) {
      if (isLoadingRefreshTimelineState || isLoadingTimelineState) {
        return;
      }
      window.scrollTo({top: 0, behavior: 'smooth'});
      setForceRefresh(true);
    }
    if (path === ROUTES.ROUTE_LIKE && pathname === ROUTES.ROUTE_LIKE) {
      if (isLoadingRefreshLikeState || isLoadingLikeState) {
        return;
      }
      window.scrollTo({top: 0, behavior: 'smooth'});
      setForceRefresh(true);
    }
  };

  const onClickGoBack = () => {
    goBack();
  };

  if (isAcceptPolicyRoute) {
    return null;
  }

  if (logic.getForceIsChildRoute(pathname)) {
    const isSearch = pathname === ROUTES.ROUTE_SEARCH;

    return (
      <>
        <Container forceChild>
          <CenterAbsolute>
            <NavTitleWrapper>
              {!isSearch && (
                <>
                  <NavTitle hasSubTitle>ผลลัพธ์การค้นหา</NavTitle>
                  <NavSubTitle>{`${resultCount} รายการ`}</NavSubTitle>
                </>
              )}
              {isSearch && (
                <>
                  <NavTitle hasSubTitle>ค้นหางาน</NavTitle>
                </>
              )}
            </NavTitleWrapper>
          </CenterAbsolute>
          <NavContainer>
            <LeftWrapper>
              <GoBackWrapper onClick={onClickGoBack}>
                <Icon name={ICONS.ARROW_LEFT} size={36} />
              </GoBackWrapper>
              <Link url={ROUTES.ROUTE_HOME}>
                <AppLogo />
              </Link>
            </LeftWrapper>

            {isLargeContent && <Searcher />}
          </NavContainer>
        </Container>
        <Container forceBottom>
          <NavContainer>
            {isLargeContent && (
              <Link url={ROUTES.ROUTE_HOME}>
                <AppLogo />
              </Link>
            )}
            <NavCenter>
              <NavUlWrapper>
                <NavLiItem active={isActiveHome}>
                  <Link style={OverrideStyles.link} url={ROUTES.ROUTE_HOME} onClick={() => onClickNavMenu(ROUTES.ROUTE_HOME)}>
                    <SpanIcon>
                      <Icon name={ICONS.HOME} color={logic.getIconColor(pathname, ROUTES.ROUTE_HOME)} />
                    </SpanIcon>
                  </Link>
                </NavLiItem>
                <NavLiItem active={isActiveLiked}>
                  <Link style={OverrideStyles.link} url={ROUTES.ROUTE_LIKE} onClick={() => onClickNavMenu(ROUTES.ROUTE_LIKE)}>
                    <SpanIcon>
                      <Icon name={logic.getIconName(ROUTES.ROUTE_LIKE, isActiveLiked)} color={logic.getIconColor(pathname, ROUTES.ROUTE_LIKE)} />
                    </SpanIcon>
                  </Link>
                </NavLiItem>
                <NavLiItem active={isActiveApplyJob}>
                  <Link style={OverrideStyles.link} url={ROUTES.ROUTE_APPLY_JOB}>
                    <SpanIcon>
                      <Icon
                        name={logic.getIconName(ROUTES.ROUTE_APPLY_JOB, isActiveApplyJob)}
                        color={logic.getIconColor(pathname, ROUTES.ROUTE_APPLY_JOB)}
                      />
                    </SpanIcon>
                  </Link>
                </NavLiItem>
                <NavLiItem active={isActiveNotify}>
                  <Link style={OverrideStyles.link} url={ROUTES.ROUTE_NOTIFY}>
                    <SpanIcon>
                      <Icon name={logic.getIconName(ROUTES.ROUTE_NOTIFY, isActiveNotify)} color={logic.getIconColor(pathname, ROUTES.ROUTE_NOTIFY)} />
                    </SpanIcon>
                  </Link>
                </NavLiItem>
                <NavLiItem active={isActiveMenu}>
                  <Link style={OverrideStyles.link} url={ROUTES.ROUTE_MENU}>
                    <SpanIcon>
                      <Icon name={logic.getIconName(ROUTES.ROUTE_MENU, isActiveMenu)} color={logic.getIconColor(pathname, ROUTES.ROUTE_MENU)} />
                    </SpanIcon>
                  </Link>
                </NavLiItem>
              </NavUlWrapper>
            </NavCenter>
            {isLargeContent && <Searcher />}
          </NavContainer>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        <NavContainer>
          {isLargeContent && (
            <Link url={ROUTES.ROUTE_HOME}>
              <AppLogo />
            </Link>
          )}
          <NavCenter>
            <NavUlWrapper>
              <NavLiItem active={isActiveHome}>
                <Link style={OverrideStyles.link} url={ROUTES.ROUTE_HOME} onClick={() => onClickNavMenu(ROUTES.ROUTE_HOME)}>
                  <SpanIcon>
                    <Icon name={ICONS.HOME} color={logic.getIconColor(pathname, ROUTES.ROUTE_HOME)} />
                  </SpanIcon>
                </Link>
              </NavLiItem>
              <NavLiItem active={isActiveLiked}>
                <Link style={OverrideStyles.link} url={ROUTES.ROUTE_LIKE} onClick={() => onClickNavMenu(ROUTES.ROUTE_LIKE)}>
                  <SpanIcon>
                    <Icon name={logic.getIconName(ROUTES.ROUTE_LIKE, isActiveLiked)} color={logic.getIconColor(pathname, ROUTES.ROUTE_LIKE)} />
                    {isActiveLiked && typeDef.isArrayLength(likeCount) && <TextCount>{likeCount.length}</TextCount>}
                  </SpanIcon>
                </Link>
              </NavLiItem>
              <NavLiItem active={isActiveApplyJob}>
                <Link style={OverrideStyles.link} url={ROUTES.ROUTE_APPLY_JOB}>
                  <SpanIcon>
                    <Icon
                      name={logic.getIconName(ROUTES.ROUTE_APPLY_JOB, isActiveApplyJob)}
                      color={logic.getIconColor(pathname, ROUTES.ROUTE_APPLY_JOB)}
                    />
                    {isActiveApplyJob && typeDef.isArrayLength(applyJobCount) && <TextCount>{applyJobCount.length}</TextCount>}
                  </SpanIcon>
                </Link>
              </NavLiItem>
              <NavLiItem active={isActiveNotify}>
                <Link style={OverrideStyles.link} url={ROUTES.ROUTE_NOTIFY}>
                  <SpanIcon>
                    <Icon name={logic.getIconName(ROUTES.ROUTE_NOTIFY, isActiveNotify)} color={logic.getIconColor(pathname, ROUTES.ROUTE_NOTIFY)} />
                    {pathname !== ROUTES.ROUTE_NOTIFY &&
                      !typeDef.isNullOrUndefined(notificationCountNew) &&
                      typeDef.isNumber(notificationCountNew) &&
                      notificationCountNew > 0 && <Badge>{logic.getCountNewNotify(notificationCountNew)}</Badge>}
                  </SpanIcon>
                </Link>
              </NavLiItem>
              <NavLiItem active={isActiveMenu}>
                <Link style={OverrideStyles.link} url={ROUTES.ROUTE_MENU}>
                  <SpanIcon>
                    <Icon name={logic.getIconName(ROUTES.ROUTE_MENU, isActiveMenu)} color={logic.getIconColor(pathname, ROUTES.ROUTE_MENU)} />
                  </SpanIcon>
                </Link>
              </NavLiItem>
            </NavUlWrapper>
          </NavCenter>
          {isLargeContent && <Searcher />}
        </NavContainer>
      </Container>
    </>
  );
};

LoggedInAppBar.propTypes = {};

export default LoggedInAppBar;
