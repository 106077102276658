import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Container, ContentWrapper} from './views/styled-component';
import Loading from '../../components/loading';
import ResetPassHeader from './views/head';
import RequestForm from './views/form-request';
import ResetForm from './views/form-reset';
import * as authAction from '../../redux/auth/action';

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();
  const visibleViewState = useSelector((state) => state.auth.forgotPassword?.visibleStep);
  const isLoadingState = useSelector((state) => state.auth.forgotPassword?.loading);

  useEffect(() => {
    return () => dispatch(authAction.userForgotPasswordClear());
  }, [dispatch]);

  return (
    <Container>
      <Loading loading={isLoadingState} />
      <ContentWrapper>
        <ResetPassHeader />
        {visibleViewState === 1 && <RequestForm />}
        {visibleViewState === 2 && <ResetForm />}
      </ContentWrapper>
    </Container>
  );
};

ResetPasswordScreen.propTypes = {};

export default ResetPasswordScreen;
