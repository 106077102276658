const size = {
  defaultHeight: 40,
  borderRadius: 6,
  fixMinWidth: 124,
  fixSmallMinWidth: 84,
  circleButtonHeight: 40,
  circleButtonWidth: 40,
  defaultHorizontalPadding: 12,
  smallHeight: 26,
  smallBorderRadius: 4,
  smallHorizontalPadding: 12,
};

const buttonTheme = {
  size,
};

export default buttonTheme;
