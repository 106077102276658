import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import {createServiceFirebaseStore} from '../../env/utils/env-firebase';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = createServiceFirebaseStore();

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const auth = firebase.auth();

export {storage, auth, firebase};
