import styled from 'styled-components';
import {LoaderAlt} from '@styled-icons/boxicons-regular/LoaderAlt';
import {Home} from '@styled-icons/boxicons-solid/Home';
import {CheckboxChecked} from '@styled-icons/fluentui-system-filled/CheckboxChecked';
import {CheckboxUnchecked} from '@styled-icons/fluentui-system-regular/CheckboxUnchecked';
import {KeyboardArrowDown} from '@styled-icons/material-outlined/KeyboardArrowDown';
import {KeyboardArrowUp} from '@styled-icons/material-outlined/KeyboardArrowUp';
import {KeyboardArrowLeft} from '@styled-icons/material-outlined/KeyboardArrowLeft';
import {KeyboardArrowRight} from '@styled-icons/material-outlined/KeyboardArrowRight';
import {Menu} from '@styled-icons/ionicons-sharp/Menu';
import {Close} from '@styled-icons/ionicons-outline/Close';
import {Search} from '@styled-icons/boxicons-regular/Search';
import {LocationOn} from '@styled-icons/material-sharp/LocationOn';
import {Email} from '@styled-icons/evaicons-solid/Email';
import {LockAlt} from '@styled-icons/boxicons-solid/LockAlt';
import {EyeWithLine} from '@styled-icons/entypo/EyeWithLine';
import {Eye} from '@styled-icons/entypo/Eye';
import {Calendar} from '@styled-icons/boxicons-regular/Calendar';
import {DocumentText} from '@styled-icons/heroicons-solid/DocumentText';
import {ShieldFillCheck} from '@styled-icons/bootstrap/ShieldFillCheck';
import {Building} from '@styled-icons/boxicons-solid/Building';
import {StarFill} from '@styled-icons/bootstrap/StarFill';
import {Directions} from '@styled-icons/fluentui-system-filled/Directions';
import {Like} from '@styled-icons/boxicons-solid/Like';
import {SendPlane} from '@styled-icons/remix-fill/SendPlane';
import {ShareForward} from '@styled-icons/remix-fill/ShareForward';
import {Contact} from '@styled-icons/boxicons-solid/Contact';
import {FacebookWithCircle} from '@styled-icons/entypo-social/FacebookWithCircle';
import {Messenger} from '@styled-icons/boxicons-logos/Messenger';
import {Copy} from '@styled-icons/ionicons-solid/Copy';
import {PhoneCall} from '@styled-icons/boxicons-regular/PhoneCall';
import {Share} from '@styled-icons/entypo/Share';
import {DollarCircle} from '@styled-icons/boxicons-solid/DollarCircle';
import {Time} from '@styled-icons/boxicons-solid/Time';
import {GraduationCap} from '@styled-icons/fa-solid/GraduationCap';
import {Like as LikeOutline} from '@styled-icons/boxicons-regular/Like';
import {Bell} from '@styled-icons/boxicons-solid/Bell';
import {Bell as BellOutline} from '@styled-icons/boxicons-regular/Bell';
import {Settings} from '@styled-icons/evaicons-solid/Settings';
import {HelpCircle} from '@styled-icons/boxicons-solid/HelpCircle';
import {LogOut} from '@styled-icons/ionicons-sharp/LogOut';
import {CloseSquareOutline} from '@styled-icons/evaicons-outline/CloseSquareOutline';
import {Camera} from '@styled-icons/bootstrap/Camera';
import {MessageSquareEdit} from '@styled-icons/boxicons-regular/MessageSquareEdit';
import {UserCircle} from '@styled-icons/boxicons-solid/UserCircle';
import {BusinessTime} from '@styled-icons/fa-solid/BusinessTime';
import {Medal} from '@styled-icons/boxicons-solid/Medal';
import {CloudUpload} from '@styled-icons/boxicons-solid/CloudUpload';
import {Minus} from '@styled-icons/boxicons-regular/Minus';
import {Plus} from '@styled-icons/boxicons-regular/Plus';
import {Bookmark} from '@styled-icons/feather/Bookmark';
import {BookmarkFill} from '@styled-icons/bootstrap/BookmarkFill';
import {InfoCircleFill} from '@styled-icons/bootstrap/InfoCircleFill';
import {World} from '@styled-icons/boxicons-regular/World';
import {TravelBus} from '@styled-icons/zondicons/TravelBus';
import {CheckmarkCircle2} from '@styled-icons/evaicons-solid/CheckmarkCircle2';
import {CloseCircle} from '@styled-icons/evaicons-solid/CloseCircle';
import {Title} from '@styled-icons/material/Title';
import {DocumentAttach} from '@styled-icons/ionicons-sharp/DocumentAttach';
import {CloseOutline} from '@styled-icons/evaicons-outline/CloseOutline';
import {Options2} from '@styled-icons/evaicons-solid/Options2';
import {FacebookSquare} from '@styled-icons/boxicons-logos/FacebookSquare';
import {BookmarkStarFill} from '@styled-icons/bootstrap/BookmarkStarFill';
import {Certificate} from '@styled-icons/fluentui-system-filled/Certificate';
import {LayerGroup} from '@styled-icons/fa-solid/LayerGroup';
import {NotificationImportant} from '@styled-icons/material/NotificationImportant';
import {Notifications} from '@styled-icons/material/Notifications';
import {NotificationsActive} from '@styled-icons/material/NotificationsActive';
import {MoreHorizontalOutline} from '@styled-icons/evaicons-outline/MoreHorizontalOutline';
import {TrashFill} from '@styled-icons/bootstrap/TrashFill';

export const IconTrash = styled(TrashFill)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconMoreHorizontal = styled(MoreHorizontalOutline)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconGroup = styled(LayerGroup)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconCertificate = styled(Certificate)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconBookMarkStar = styled(BookmarkStarFill)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconFacebookSquare = styled(FacebookSquare)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconCloseOutline = styled(CloseOutline)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconFilter = styled(Options2)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconTravelBus = styled(TravelBus)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconAttachment = styled(DocumentAttach)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;
export const IconWorld = styled(World)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconTitle = styled(Title)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconInfoFill = styled(InfoCircleFill)``;

export const IconCheckCircle = styled(CheckmarkCircle2)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconCloseCircle = styled(CloseCircle)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconBookMark = styled(Bookmark)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconBookMarkFill = styled(BookmarkFill)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconPlus = styled(Plus)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconMinus = styled(Minus)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconUpload = styled(CloudUpload)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconMedal = styled(Medal)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconBusiness = styled(BusinessTime)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconUserCircle = styled(UserCircle)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconEditSquare = styled(MessageSquareEdit)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconCamera = styled(Camera)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconCloseSquare = styled(CloseSquareOutline)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconLogout = styled(LogOut)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconHelp = styled(HelpCircle)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconSettings = styled(Settings)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconBellOutline = styled(Bell)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconBell = styled(BellOutline)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconLikeOutline = styled(LikeOutline)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconGraduation = styled(GraduationCap)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconTime = styled(Time)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconDollar = styled(DollarCircle)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconShareOther = styled(Share)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconPhoneCall = styled(PhoneCall)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconCopy = styled(Copy)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconLoad = styled(LoaderAlt)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconHome = styled(Home)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconCheckbox = styled(CheckboxChecked)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconUnCheckbox = styled(CheckboxUnchecked)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconArrowLeft = styled(KeyboardArrowLeft)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconArrowRight = styled(KeyboardArrowRight)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconArrowUp = styled(KeyboardArrowUp)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconArrowDown = styled(KeyboardArrowDown)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconMenu = styled(Menu)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconClose = styled(Close)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconSearch = styled(Search)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconLocation = styled(LocationOn)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconEmailFill = styled(Email)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconPassFill = styled(LockAlt)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconPassSee = styled(EyeWithLine)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconPassUnSee = styled(Eye)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconCalendar = styled(Calendar)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconDocument = styled(DocumentText)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconShield = styled(ShieldFillCheck)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconBuilding = styled(Building)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconStar = styled(StarFill)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconDirection = styled(Directions)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconLike = styled(Like)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconSendEmail = styled(SendPlane)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconShare = styled(ShareForward)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconContact = styled(Contact)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconFacebook = styled(FacebookWithCircle)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconMessenger = styled(Messenger)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconNotificationImportant = styled(NotificationImportant)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconNotifications = styled(Notifications)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconNotificationsActive = styled(NotificationsActive)`
  color: ${(props) => (props.color ? props.color : props.theme.text)};
  font-size: ${(props) => `${props.size}px`};
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
`;
