import React from 'react';
import {translate} from '../../res/language/translate';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import FormSearch from './views';
import Screen from '../../views/screen';
import MetaTags from '../../components/meta-tag';

const SearchScreen = () => {
  useSetGrayBackground();

  return (
    <>
      <MetaTags title={translate('meta.search')} description={translate('meta.search')} />
      <Screen noEdgeInsets isChildScreen type="one-col-layout">
        <FormSearch />
      </Screen>
    </>
  );
};

SearchScreen.propTypes = {};

export default SearchScreen;
