import keyBy from 'lodash/keyBy';
import * as typeDef from '../../../../utils/strong-types';
import * as generate from '../../../../utils/generate';

export const mapStateToProvince = (provinceState) => {
  if (!provinceState) {
    return [];
  }
  const response = {
    id: generate.generateId('PV'),
    label: provinceState,
    value: provinceState,
  };
  return [response];
};

export const mapStateToDistrict = (districtState) => {
  if (!districtState) {
    return [];
  }
  const label = typeDef.isString(districtState) ? districtState.substring(districtState.lastIndexOf('|') + 1, districtState.length) : null;
  const response = {
    id: generate.generateId('PV'),
    label: label,
    value: districtState,
  };
  if (typeDef.isNullOrUndefined(response?.value)) {
    return null;
  }
  return [response];
};

export const mapDefaultJobCat = (listData, jobCat, subJobCat) => {
  if (typeDef.isNotArrayLength(jobCat) && typeDef.isNotArrayLength(subJobCat)) {
    return [];
  }
  const allSelect = [];
  const keyJobCat = keyBy(listData, 'value');
  if (typeDef.isArrayLength(jobCat)) {
    jobCat.forEach((elem) => {
      if (keyJobCat[elem]) {
        allSelect.push(keyJobCat[elem]);
      }
    });
  }
  if (typeDef.isArrayLength(subJobCat)) {
    subJobCat.forEach((elem) => {
      const splitParent = elem.split('|');
      const parentItems = keyJobCat[splitParent[0]];
      if (typeDef.isArrayLength(parentItems?.sub)) {
        const subItem = parentItems.sub.find((item) => item.value === elem);
        if (subItem) {
          allSelect.push(subItem);
        }
      }
    });
  }
  return allSelect;
};

export const updateJobCatAndSubJobCat = (allJobcat, interestedJobState) => {
  if (typeDef.isNotArrayLength(allJobcat)) {
    return {...interestedJobState, subjobcat: null, jobcat: null};
  }
  const subjobcat = allJobcat.filter((item) => !typeDef.isNullOrUndefined(item.parentId));
  const jobcat = allJobcat.filter((item) => typeDef.isNullOrUndefined(item.parentId));
  const copyForm = {...interestedJobState};
  if (typeDef.isArrayLength(subjobcat) || typeDef.isArrayLength(jobcat)) {
    return {...copyForm, subjobcat: subjobcat.map((item) => item.value), jobcat: jobcat.map((item) => item.value)};
  }
  return interestedJobState;
};
