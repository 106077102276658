const padding = {
  content: 16,
  contentPost: 10,
  default: 10,
  textFieldVertical: 6,
  chip: 10,
  webDefault: 20,
};

const margin = {
  navbarSearch: 10,
  postSection: 10,
  leftDefault: 10,
  rightDefault: 10,
  topDefault: 10,
  bottomDefault: 10,
  fieldSpace: 20,
  chip: 10,
  webDefault: 20,
  content: 16,
};

const content = {
  minFluid: 1200,
  twoLayoutMinFluid: 1440,
};

const grid = {
  side: 450,
  center: 680,
  large: 1440,
  loginSideLeft: 514,
};

const dimension = {
  iPhone8P: 414,
  mobileMaxWidth: 767,
  tabletMinWidth: 768,
  tabletMaxWidth: 921,
  desktopMinWidth: 922,
  ipadProWidth: 1024,
};

const edgeInsets = {
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  vertical: 'vertical',
  horizontal: 'horizontal',
  all: 'all',
  none: 'none',
};

const layout = {
  padding,
  margin,
  content,
  grid,
  dimension,
  edgeInsets,
};

export default layout;
