import React from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import {translate} from '../../../res/language/translate';
import {useCompanyProfileState} from '../../../lib/hooks/logic/useCompanyProfile';
import JobPostItem from '../../../views/job-post-item';
import {getAnnouncementText} from './about/logic';

const Announcement = (props) => {
  const {isCompanyVerified} = props;
  const {metaGroupState} = useCompanyProfileState();
  const isEmptyAnnouncement =
    !lodashGet(metaGroupState, 'welfare[0]') && !lodashGet(metaGroupState, 'description') && !lodashGet(metaGroupState, 'post_image[0]');

  if (!metaGroupState || isEmptyAnnouncement) {
    return null;
  }

  return (
    <JobPostItem
      isCompany
      isMeta
      isCompanyVerified={isCompanyVerified}
      id={metaGroupState?._id || 'meta-id'}
      avatarSrc={metaGroupState?.profile_url?.url}
      authorName={metaGroupState?.name}
      postDate={translate('post.announcement')}
      fancyText={metaGroupState?.description}
      normalText={getAnnouncementText(metaGroupState?.welfare)}
      postImages={metaGroupState?.post_image}
      jobData={{}}
      onEventTracking={() => {}}
      authorSrc=""
    />
  );
};

Announcement.propTypes = {
  isCompanyVerified: PropTypes.bool.isRequired,
};

export default Announcement;
