import React from 'react';
import PropTypes from 'prop-types';
import {TableWrapper, Table, TableRow, TableHead, TableData, TableBody} from './styled-component';

const RenderTable = (props) => {
  const {children} = props;

  if (!children?.head) {
    return null;
  }

  return (
    <TableWrapper>
      <Table>
        <TableBody>
          <TableRow>
            <TableHead>{children?.head?.col1}</TableHead>
            <TableHead>{children?.head?.col2}</TableHead>
          </TableRow>
          {Array.isArray(children?.row) &&
            children.row.map((item, index) => (
              <TableRow key={index.toString()}>
                <TableData>{item.col1}</TableData>
                <TableData>{item.col2}</TableData>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

RenderTable.propTypes = {
  children: PropTypes.object.isRequired,
};

export default RenderTable;
