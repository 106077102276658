import {regexNumber} from '../../../utils/regex';
import {translate} from '../../../res/language/translate';

export const validatePhoneNumber = ({phone}) => {
  if (!phone) {
    return translate('otp.errorPhone.undefined');
  }
  if (!regexNumber({input: phone})) {
    return translate('otp.errorPhone.regex');
  }
  return '';
};

export const validateOTPCode = ({value}) => {
  if (!value) {
    return translate('otp.errorOTPCode.undefined');
  }
  if (!regexNumber({input: value}) || value?.length !== 6) {
    return translate('otp.errorOTPCode.regex');
  }

  return '';
};
