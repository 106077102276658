import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useOTP} from '../../../lib/hooks/logic/useOTP';
import {translate} from '../../../res/language/translate';
import {FormWrapper, OverrideStyles, RowTerm, TextFocus, TextUnFocus} from './styled-component';
import Form from '../../../components/form';
import Button from '../../../components/button';
import ButtonUnderline from '../../../components/button-underline';
import OtpInput from '../../../components/otp-input';
import Checkbox from '../../../components/checkbox';
import Link from '../../../components/link';
import Row from '../../../components/row';
import * as validate from './validate';
import * as ROUTES from '../../../routes/routes-name';
import * as ALERT from '../../../utils/components/alert/provider';
import * as NAVIGATION from '../../../utils/navigation/navigation-services';
import ENV from '../../../env.json';

const ResetForm = () => {
  const {formatMessage} = useIntl();
  const {phoneState, dispatchRequestOTP, dispatchChangPhone, dispatchConfirmOTP, dispatchLoginOther} = useOTP();
  const [otpState, setOtpState] = useState('');
  const [errorTexts, setErrorTexts] = useState('');
  const [acceptPrivacyState, setAcceptPrivacyState] = useState(false);

  const onChangeText = (value) => {
    setErrorTexts('');
    setOtpState(value);
  };

  const onChangeAcceptPrivacy = () => {
    setAcceptPrivacyState(!acceptPrivacyState);
  };

  const onSubmitNotAcceptTerm = () => {
    const alertData = {
      message: formatMessage({
        defaultMessage:
          'ระบบไม่สามารถสมัครสมาชิกให้คุณได้ เนื่องจากคุณไม่ได้กดยอมรับข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์ กรุณากดยอมรับเงื่อนไขเพื่อดำเนินการต่อ หรือเลือกใช้งานแบบไม่เข้าสู่ระบบ',
      }),
      negativeTitle: formatMessage({defaultMessage: 'ใช้งานแบบไม่เข้าสู่ระบบ'}),
      positiveTitle: formatMessage({defaultMessage: 'ไปยอมรับ'}),
      action: {
        negativeAction: () => {
          ALERT.removeAlert();
          NAVIGATION.navigateTo({pathname: ROUTES.ROUTE_MAIN});
        },
        positiveAction: () => ALERT.removeAlert(),
      },
    };
    ALERT.setAlertManualAction(alertData);
  };

  const onSubmit = () => {
    const inValidate = validate.validateOTPCode({value: otpState});
    if (inValidate) {
      setErrorTexts(inValidate);
      return;
    }
    if (!acceptPrivacyState) {
      onSubmitNotAcceptTerm();
      return;
    }
    dispatchConfirmOTP(otpState, acceptPrivacyState);
  };

  const onClickResendOtpCode = () => {
    dispatchRequestOTP(phoneState);
  };

  const onClickChangePhone = () => {
    dispatchChangPhone();
  };

  const onClickLoginOther = () => {
    dispatchLoginOther();
  };

  return (
    <FormWrapper>
      <Form formId="form-reset-pass-reset" onSubmit={onSubmit}>
        <OtpInput name="otp" value={otpState} onChange={(value) => onChangeText(value)} errorText={errorTexts} />
        <RowTerm>
          <Checkbox label={formatMessage({defaultMessage: 'ยอมรับ'})} checked={acceptPrivacyState} onChange={onChangeAcceptPrivacy} />
          <Link newTab url={`${ENV.PUBLIC.API_URL}${ROUTES.ROUTE_TERM_AND_AGREEMENT}`}>
            <TextFocus left>{formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์'})}</TextFocus>
          </Link>
          <TextUnFocus>{formatMessage({defaultMessage: 'ของ JobFinFin'})}</TextUnFocus>
        </RowTerm>
        <Button block type="submit" edgeInsets="none" style={OverrideStyles.button}>
          {translate('otp.buttonConfirmCode')}
        </Button>
      </Form>
      <Button block variant="light" type="button" edgeInsets="none" onClick={onClickResendOtpCode}>
        {translate('forgotPassword.buttonResendCode')}
      </Button>
      <Row content="space-between" style={OverrideStyles.rowButton}>
        <ButtonUnderline onClick={onClickChangePhone}>{translate('forgotPassword.buttonChangePhone')}</ButtonUnderline>
        <ButtonUnderline onClick={onClickLoginOther}>{translate('otp.labelLoginOther')}</ButtonUnderline>
      </Row>
    </FormWrapper>
  );
};

ResetForm.propTypes = {};

export default ResetForm;
