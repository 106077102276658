import React from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {useScrollYDirectionEffect} from '../../lib/hooks/useScrollingEffect';
import {useSearchCountState} from '../../lib/hooks-logic/useSearchResultCount';
import {useSearchHashtagEffect, useSearchHashtagState, useSearchHashtagDispatch} from '../../lib/hooks/logic/useSearch';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import JobPostBuilder from '../../views/job-post-builder';
import Screen from '../../views/screen';
import MetaTags from '../../components/meta-tag';
import FilterBuilder from '../../views/filter-builder';
import FilterSmallBuilder from '../../views/app-bar-filter';
import NavbarFilter from '../../views/app-bar-filter/nav-bar-filter';
import {parseJsonLd} from '../../lib/context/parse-jsonld';
import {useDescription} from './hooks/useDescription';
import {useTitle} from './hooks/useTitle';

const SearchHashtagScreen = () => {
  useSetGrayBackground();
  useSearchHashtagEffect();

  const {scrollDirection} = useScrollYDirectionEffect();
  const {formatMessage} = useIntl();
  const {resultCount} = useSearchCountState();
  const {stackFeedState, isLoadingState, isEmptyDataState, isCanFetchMoreState, pageIndexState, newFeedState} = useSearchHashtagState();
  const {dispatchFetchSearchHashtag} = useSearchHashtagDispatch();
  const description = useDescription(stackFeedState, newFeedState?.countDisplay);
  const location = useLocation();
  const title = useTitle(location.search);

  const onEventEndReached = () => {
    if (!isLoadingState && !isEmptyDataState && isCanFetchMoreState) {
      dispatchFetchSearchHashtag(pageIndexState);
    }
  };

  return (
    <>
      <MetaTags title={title} description={description} scriptLdJson={parseJsonLd(stackFeedState, 'search.tag')} />
      <Screen
        noEdgeInsets
        isChildScreen
        title={formatMessage({defaultMessage: 'ผลลัพธ์การค้นหา'})}
        subTitle={`${resultCount} รายการ`}
        type="two-col-layout"
        leftComponent={<FilterBuilder />}
        onEndReached={onEventEndReached}>
        <>
          <FilterSmallBuilder />
          <NavbarFilter isShow={scrollDirection.top} />
          <JobPostBuilder noTopEdgeInsets noBottomEdgeInsets data={stackFeedState} isEmptyData={isEmptyDataState} isLoadingFeed={isLoadingState} />
        </>
      </Screen>
    </>
  );
};

SearchHashtagScreen.propTypes = {};

export default SearchHashtagScreen;
