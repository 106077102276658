import chunk from 'lodash/chunk';

export const createJobIndex = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }
  const shapeData = chunk([...data], 3);
  return shapeData;
};

export const counterResults = (data) => {
  if (!Array.isArray(data)) {
    return 0;
  }
  return data.length;
};
