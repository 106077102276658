import {utils} from '@hassanmojab/react-modern-calendar-datepicker';

export const customLocal = {
  months: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
  // week days by order
  weekDays: [
    {
      name: 'อาทิตย์',
      short: 'อา',
      isWeekend: true,
    },
    {
      name: 'จันทร์',
      short: 'จ',
    },
    {
      name: 'อังคาร',
      short: 'อ',
    },
    {
      name: 'พุธ',
      short: 'พ',
    },
    {
      name: 'พฤหัสบดี',
      short: 'พฤ',
    },
    {
      name: 'ศุกร์',
      short: 'ศ',
    },
    {
      name: 'เสาร์',
      short: 'ส',
      isWeekend: true,
    },
  ],
  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(getTodayObject) {
    return getTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'Next Month',
  previousMonth: 'Previous Month',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Select...',

  // for input range value
  from: 'from',
  to: 'to',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
};

export const dateRange = {
  minimum: {
    year: 1900,
    month: 1,
    day: 1,
  },
  maximum: {
    ...utils().getToday(),
  },
};
