import {put, call} from 'redux-saga/effects';
import {authorizeProvider} from '../utils/authorize';
import {alertManualProvider, alertRemoveProvider} from '../utils/fast-dispatch';
import {navigateTo} from '../../utils/navigation/navigation-services';
import * as api from './api';
import * as TYPES from './type';
import * as SEARCH_TYPES from '../search/type';
import * as CONTEXT_TYPES from '../context/type';
import * as routes from '../../routes/routes-name';
import * as mixpanel from '../../utils/mixpanel';

export function* likeNewLikeAction({payload}) {
  if (payload?.isLoggedOut) {
    const rdPath = {
      pathname: routes.ROUTE_SEARCH_BY_ID,
      search: {id: payload?.data?.job_id},
    };
    yield put({
      type: CONTEXT_TYPES.CONTEXT_ADD_CALLBACK_PATH_REQ,
      payload: rdPath,
    });
    const alertData = {
      message: 'กรุณาเข้าสู่ระบบก่อนกดไลค์',
      positiveTitle: 'สมัครสมาชิก',
      negativeTitle: 'ปิด',
      action: {
        negativeAction: () => alertRemoveProvider(),
        positiveAction: () => {
          navigateTo({pathname: routes.ROUTE_REGISTER, search: {rd_path: true}});
          alertRemoveProvider();
        },
      },
    };
    alertManualProvider(alertData);
    return;
  }
  try {
    const response = yield call(api.likeNewLike, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.LIKE_NEW_LIKE_SUCCESS,
        payload: {
          response: response.data,
          data: {
            _id: response?.data?.like?._id,
            job_id: payload?.data?.job_id,
          },
        },
      });
      if (payload?.path === 'query') {
        yield put({
          type: SEARCH_TYPES.SEARCH_JOB_QUERY_LIKE_SUCCESS,
          payload: response.data,
        });
      }
      if (payload?.path === 'id') {
        yield put({
          type: SEARCH_TYPES.SEARCH_JOB_ID_LIKE_SUCCESS,
          payload: response.data,
        });
      }

      yield put({
        type: SEARCH_TYPES.SEARCH_JOB_USER_LIKE_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: SEARCH_TYPES.SEARCH_JOB_HASHTAG_LIKE_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: SEARCH_TYPES.SEARCH_JOB_AUTHOR_LIKE_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: SEARCH_TYPES.SEARCH_JOB_GROUP_LIKE_SUCCESS,
        payload: response.data,
      });
      mixpanel.jobLike(response.data.job);
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.LIKE_NEW_LIKE_FAIL,
        payload: {
          error: responseError,
          data: payload?.refIds,
        },
      });
    }
  }
}

export function* likeUnlikeAction({payload}) {
  if (payload?.isLoggedOut) {
    const rdPath = {
      pathname: routes.ROUTE_SEARCH_BY_ID,
      search: {id: payload?.data?.job_id},
    };
    yield put({
      type: CONTEXT_TYPES.CONTEXT_ADD_CALLBACK_PATH_REQ,
      payload: rdPath,
    });
    const alertData = {
      message: 'กรุณาเข้าสู่ระบบก่อนกดไลค์',
      positiveTitle: 'สมัครสมาชิก',
      negativeTitle: 'ปิด',
      action: {
        negativeAction: () => alertRemoveProvider(),
        positiveAction: () => navigateTo({pathname: routes.ROUTE_REGISTER, search: {rd_path: true}}),
      },
    };
    alertManualProvider(alertData);
    return;
  }
  try {
    const response = yield call(api.likeUnlike, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.LIKE_UNLIKE_SUCCESS,
        payload: payload?.data,
      });
      if (payload?.path === 'query') {
        yield put({
          type: SEARCH_TYPES.SEARCH_JOB_QUERY_UNLIKE_SUCCESS,
          payload: response.data,
        });
      }
      if (payload?.path === 'id') {
        yield put({
          type: SEARCH_TYPES.SEARCH_JOB_ID_UNLIKE_SUCCESS,
          payload: response.data,
        });
      }
      yield put({
        type: SEARCH_TYPES.SEARCH_JOB_LIKED_UNLIKE_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: SEARCH_TYPES.SEARCH_JOB_USER_UNLIKE_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: SEARCH_TYPES.SEARCH_JOB_HASHTAG_UNLIKE_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: SEARCH_TYPES.SEARCH_JOB_AUTHOR_UNLIKE_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: SEARCH_TYPES.SEARCH_JOB_GROUP_UNLIKE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.LIKE_UNLIKE_FAIL,
        payload: {
          error: responseError,
          data: payload?.data,
        },
      });
    }
  }
}

export function* likeUpdateStreamAction({payload}) {
  try {
    yield put({
      type: TYPES.LIKE_UPDATE_STREAM_SUCCESS,
      payload: payload.data,
    });
  } catch (error) {
    yield put({
      type: TYPES.LIKE_UPDATE_STREAM_FAIL,
      payload: {
        error: error,
        data: payload?.data,
      },
    });
  }
}
