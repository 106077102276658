import React, {useState, useRef} from 'react';
import {useIntl} from 'react-intl';
import {useUserState, useUserResumeState, useUserDispatch} from '../../../../lib/hooks-logic/useUser';
import {useDispatchFileUpload} from '../../../../lib/hooks/logic/useUpload';
import {InputHidden, OverrideStyles, ManualResumeTile, RightItem, LeftItem} from './styled-component';
import {calculateStar} from './logic';
import Link from '../../../../components/link';
import Loading from '../../../../components/loading';
import ListTile from '../../../../components/list-tile';
import TextBold from '../../../../components/text-bold';
import Text from '../../../../components/text';
import Divider from '../../../../components/divider';
import SwitchToggle from '../../../../components/switch';
import Button from '../../../../components/button';
import Row from '../../../../components/row';
import Icon from '../../../../components/icon';
import CantApplyModal from '../../../../views/job-post-builder/utils/modal';
import {checkCanAutoResume} from '../../../../views/apply-job-modal/logic';
import * as ICONS from '../../../../components/icon/constants';
import * as snackbar from '../../../../utils/components/toast-notification/provider';
import * as navigation from '../../../../utils/navigation/navigation-services';
import * as coreUser from '../../../../lib/context/user';

const FILE_IMAGES_SUPPORT = ['image/jpeg', 'image/png', 'image/jpg'];
const FILE_SUPPORT = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  ...FILE_IMAGES_SUPPORT,
];

const ResumeInfo = () => {
  const {formatMessage} = useIntl();
  const nodeInputHiddenRef = useRef(null);

  const {dispatchUploadFile, isLoadingUploadState} = useDispatchFileUpload();
  const {dispatchUploadManualResume, dispatchUpdateProfile} = useUserDispatch();
  const {isLoadingState, dataState: resumeState} = useUserResumeState();
  const {userState} = useUserState();
  const [autoResumeData, setAutoResumeData] = useState();

  const onEventRequestUpload = () => {
    nodeInputHiddenRef.current.click();
  };

  const onClickDeleteResume = () => {
    if (!userState) {
      return;
    }
    const userGeneral = coreUser.getGeneralNewAge(userState.general);
    const formSubmit = {
      ...useState,
      ...userGeneral,
      is_resume_auto_gen: true,
      resume: null,
    };
    dispatchUpdateProfile(formSubmit);
    dispatchUploadManualResume({}, true);
  };

  const onChangeFile = async (event) => {
    if (!event || !userState?._id) {
      return;
    }
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const [uploadUrl, errorUpload] = await dispatchUploadFile(userState?._id, {file}, 'manual_resume');
    if (uploadUrl) {
      dispatchUploadManualResume({url: uploadUrl});
    }
    if (errorUpload) {
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรุณาลองใหม่อีกครั้ง'}));
    }
  };

  const onChangeAllowSearch = () => {
    if (isLoadingState || !userState) {
      return;
    }
    const userGeneral = coreUser.getGeneralNewAge(userState.general);
    const form = {...userState, ...userGeneral, is_profile_activated: !userState.is_profile_activated};
    dispatchUpdateProfile(form, true);
  };

  const onRequestPreviewAutoResume = () => {
    const [checkList, isCanAuto] = checkCanAutoResume(userState);
    if (!isCanAuto) {
      setAutoResumeData({checkList, isCanAuto});
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรุณากรอกข้อมูลเพิ่ม เพื่อสร้าง resume'}), 'warning');
      return;
    }
    setAutoResumeData(null);
    navigation.windowOpen({pathname: userState?.auto_resume});
  };

  const onCloseModal = () => {
    setAutoResumeData(null);
  };

  if (!userState) {
    return null;
  }

  return (
    <>
      {autoResumeData && <CantApplyModal onRequestClose={onCloseModal} general={{}} resumeData={autoResumeData} />}
      <Loading type="action" loading={isLoadingUploadState || isLoadingState} />
      <ListTile
        topEdgeInsets
        divider
        title={formatMessage({defaultMessage: 'เปิดการค้นหาเรซูเม่'})}
        rightComponent={<SwitchToggle isToggled={userState?.is_profile_activated || false} onChangeToggled={() => onChangeAllowSearch()} />}
        titleStyle={OverrideStyles.title}
      />
      <ManualResumeTile>
        <LeftItem>
          <Row>
            <TextBold>{formatMessage({defaultMessage: 'เรซูเม่โดย JobFinFin'})}</TextBold>
            <TextBold style={OverrideStyles.textStar}>{`${formatMessage({defaultMessage: '( คะแนน'})} ${calculateStar(userState)}/100`}</TextBold>
            <Icon name={ICONS.STAR} color={OverrideStyles.iconStarColor} size={14} />
            <TextBold style={OverrideStyles.textStar}>{formatMessage({defaultMessage: ')'})}</TextBold>
          </Row>
          <Text style={OverrideStyles.textDescription}>{formatMessage({defaultMessage: 'อัพเดทข้อมูลของคุณเพื่อรับดาวเพิ่ม'})}</Text>
        </LeftItem>
        <RightItem>
          <Button small edgeInsets="none" onClick={onRequestPreviewAutoResume} variant="light">
            {formatMessage({defaultMessage: 'ดูตัวอย่าง'})}
          </Button>
        </RightItem>
      </ManualResumeTile>
      <Divider style={OverrideStyles.divider} />
      <ManualResumeTile>
        <LeftItem>
          <TextBold>{formatMessage({defaultMessage: 'เรซูเม่ของฉัน'})}</TextBold>
          <Text style={OverrideStyles.textDescription}>{formatMessage({defaultMessage: 'สำหรับเลือกตอนสมัครงาน'})}</Text>
        </LeftItem>
        <RightItem>
          <InputHidden
            name="file-upload"
            type="file"
            ref={nodeInputHiddenRef}
            accept={FILE_SUPPORT.join(',')}
            onChange={(event) => {
              onChangeFile(event);
              event.target.value = null;
            }}
          />
          <Button edgeInsets="none" small onClick={onEventRequestUpload}>
            {formatMessage({defaultMessage: 'อัพโหลด'})}
          </Button>
          {(resumeState?.resume || userState?.resume) && (
            <>
              <Button variant="light" edgeInsets="vertical" small onClick={onClickDeleteResume}>
                {formatMessage({defaultMessage: 'ลบเรซูเม่'})}
              </Button>
              <Link newTab url={resumeState?.resume || userState?.resume}>
                <Button variant="light" small edgeInsets="none">
                  {formatMessage({defaultMessage: 'ดูตัวอย่าง'})}
                </Button>
              </Link>
            </>
          )}
        </RightItem>
      </ManualResumeTile>
    </>
  );
};

ResumeInfo.propTypes = {};

export default ResumeInfo;
