export const BLACKLIST_FIRST_LINE = {
  หน้าที่และความรับผิดชอบ: 'หน้าที่และความรับผิดชอบ',
  รายละเอียดงาน: 'รายละเอียดงาน',
  รายละเอียดตำแหน่งงาน: 'รายละเอียดตำแหน่งงาน',
  'Job Descriptions': 'Job Descriptions',
  คุณสมบัติ: 'คุณสมบัติ',
};

export const BLACKLIST_BODY_LINE = {
  // หน้าที่และความรับผิดชอบ: 'หน้าที่และความรับผิดชอบ',
};
