import React from 'react';
import PropTypes from 'prop-types';
import {Container} from './styled-component';

const Spinner = (props) => {
  const {color, size, stroke} = props;
  return <Container color={color} size={size} stroke={stroke} />;
};

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  stroke: PropTypes.number,
};

Spinner.defaultProps = {
  color: null,
  size: 24,
  stroke: 4,
};

export default Spinner;
