import store from '../store';
import {getErrorMessage} from './error-store';
import * as authActions from '../auth/action';

export const authorizeProvider = (error) => {
  if (!error) {
    return true;
  }
  const responseStatus = error?.response?.status;

  const responseError = error?.response?.data?.error;
  if (responseStatus === 401 || responseStatus === 403) {
    store.dispatch(
      authActions.userAuthorizeDenied({
        message: getErrorMessage(responseError),
        status: responseStatus,
      }),
    );
    return false;
  }
  return true;
};
