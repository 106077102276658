import * as TYPES from './type';

const initialState = {
  loading: false,
  error: null,
  draftTitle: '',
  draftBody: '',
  resumeLoading: false,
  resumeError: null,
  resumeLink: '',
  resumeMode: null, // null 'auto' 'upload'
  notifyEmail: '',
  resumeDownloadLink: null,
  jobApplyId: '',
  saveDataLoading: false,
};

const initApplyJobSendMail = {
  loading: false,
  result: null,
  error: null,
};

export const listApplyJob2Reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case TYPES.GET_APPLY_JOB_DRAFT_REQ:
      return {...state, loading: true};
    case TYPES.GET_APPLY_JOB_DRAFT_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.GET_APPLY_JOB_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        draftTitle: payload.draft?.title || '',
        draftBody: payload.draft?.body || '',
      };

    case TYPES.GET_APPLY_JOB_RESUME_REQ:
    case TYPES.GET_APPLY_JOB_RESUME_LOADING:
      return {...state, resumeLoading: true};
    case TYPES.GET_APPLY_JOB_RESUME_FAIL:
      return {...state, resumeLoading: false, resumeError: payload};
    case TYPES.GET_APPLY_JOB_RESUME_SUCCESS:
      return {...state, resumeLoading: false, resumeLink: payload.resume};

    case TYPES.GET_APPLY_JOB_SAVE_DATA_REQ:
      return {...state, saveDataLoading: true};
    case TYPES.GET_APPLY_JOB_SAVE_DATA_FAIL:
      return {...state, saveDataLoading: true};
    case TYPES.GET_APPLY_JOB_SAVE_DATA_SUCCESS:
      return {
        ...state,
        jobApplyId: payload.jobApplyId,
        resumeDownloadLink: payload.resumeUrl,
        notifyEmail: payload.notifyEmail,
        resumeMode: payload.useAutoResume ? 'auto' : 'upload',
        saveDataLoading: false,
      };

    case TYPES.GET_APPLY_JOB_CLEAR:
      return {...initialState, resumeMode: state.resumeMode, draftTitle: state.draftTitle, draftBody: state.draftBody};

    default:
      return state;
  }
};

export const applyJobSendMailReducer = (state = initApplyJobSendMail, {type, payload}) => {
  switch (type) {
    case TYPES.APPLY_JOB_SEND_MAIL_REQ:
      return {...initApplyJobSendMail, loading: true};
    case TYPES.APPLY_JOB_SEND_MAIL_SUCCESS:
      return {...state, loading: false, result: payload};
    case TYPES.APPLY_JOB_SEND_MAIL_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.APPLY_JOB_SEND_MAIL_CLEAR:
      return {...initApplyJobSendMail};
    default:
      return state;
  }
};
