import {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useLocationContextEffect} from '../../../lib/hooks/logic/useLocation';
import {getAppleEnv, setCacheLocation, getCacheLocation} from '../../../utils/storage/storage';
import {checkAppleEnv} from '../../../utils/storage/helper';
import {envServicesRef} from '../../../env/utils/env-services';
import * as searchAction from '../../../redux/search/action';
import * as contextAction from '../../../redux/context/action';
import * as userActions from '../../../redux/user/action';
import * as typeDef from '../../../utils/strong-types';

export const useInitEventEffect = () => {
  useLocationContextEffect();

  const dispatch = useDispatch();
  const cacheLocation = getCacheLocation();
  const locationState = useSelector((state) => state.context.location, shallowEqual);
  const locationNearestState = useSelector((state) => state.search.locationNearest?.locationNearest, shallowEqual);
  const isLoggedInState = useSelector((state) => state.auth.auth.user, shallowEqual);
  const userState = useSelector((state) => state.user.information.user, shallowEqual);

  const onEventSetEnvironment = () => {
    const CURRENT_ENV = checkAppleEnv(getAppleEnv());
    if (CURRENT_ENV) {
      envServicesRef.current = 'APPLE_STORE';
    }
  };

  const onEventGetLocation = async () => {
    dispatch(contextAction.contextGetLocation({data: null}));
  };

  const onEventDetectMobile = () => {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  useEffect(() => {
    onEventSetEnvironment();
  }, []);

  useEffect(() => {
    if (typeof cacheLocation === 'string' && cacheLocation !== 'null' && cacheLocation !== 'undefined' && cacheLocation !== '') {
      const nearest = cacheLocation.split(',');
      dispatch(searchAction.searchCacheLocationNearest(nearest));
    } else {
      onEventGetLocation();
    }
  }, [cacheLocation, dispatch]);

  useEffect(() => {
    if (locationState?.lat && locationState?.long && !locationNearestState) {
      const reqBody = {
        lat: locationState?.lat,
        long: locationState?.long,
      };
      dispatch(searchAction.searchLocationNearest({data: reqBody}));
    }
    if (locationNearestState) {
      setCacheLocation(locationNearestState);
    }
  }, [locationState, locationNearestState, dispatch]);

  useEffect(() => {
    const isMobile = onEventDetectMobile();
    dispatch(
      contextAction.contextUpdateDimensions({
        isMobileBrowser: isMobile,
        isDesktopBrowser: !isMobile,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedInState && typeDef.isNullOrUndefined(userState)) {
      dispatch(userActions.userDetailProfile());
    }
  }, [isLoggedInState, userState, dispatch]);
};
