const newLine = '\n';
const newTwoLine = '\n\n';

const privacy1 = [
  {
    type: 'string',
    value:
      `${newLine}<head>นโยบายคุกกี้</head>` +
      `เว็บไซต์นี้ให้บริการโดยบริษัท จ็อบฟินฟิน จำกัด และ (ในที่นี้เรียกว่า “จ็อบฟินฟิน”หรือ“เรา”) เว็บไซต์นี้ใช้คุกกี้และเครื่องมืออื่นเพื่อช่วยแยกแยะรูปแบบการใช้งานเว็บไซต์ของท่านจากผู้ใช้งานอื่นๆซึ่งจะช่วยให้ท่านได้รับประสบการณ์ที่ดีจากการใช้งานเว็บไซต์ และช่วยให้เราสามารถพัฒนาคุณภาพของเว็บไซต์ให้ดียิ่งขึ้น กรณีที่ท่านใช้งานเว็บไซต์นี้ต่อไป ถือว่าท่านได้ยินยอมให้เราติดตั้งคุกกี้ไว้ในเครื่องคอมพิวเตอร์ของท่าน`,
  },
  {
    type: 'string',
    value:
      `${newLine}<head>คุกกี้คืออะไร?</head>` +
      `คุกกี้ คือ ไฟล์ข้อความขนาดเล็กที่ถูกดาวน์โหลดไปยังอุปกรณ์คอมพิวเตอร์หรืออุปกรณ์มือถือของท่าน ซึ่งทำหน้าที่บันทึกข้อมูลและการตั้งค่าต่าง ๆ เช่น บันทึกข้อมูลการตั้งค่าภาษาในอุปกรณ์บนอุปกรณ์ของท่าน บันทึกสถานะการเข้าใช้งานในปัจจุบันของท่าน เพื่อช่วยให้ท่านสามารถเข้าใช้งานเว็บไซต์และ/หรือแอปพลิเคชัน (รวมเรียกว่า “บริการ”) ได้อย่างต่อเนื่อง รวมถึงมีการรวบรวมข้อมูลเกี่ยวกับประวัติการเข้าใช้งานบริการที่ท่านชื่นชอบในรูปแบบไฟล์ โดยคุกกี้ไม่ได้ทำให้เกิดอันตราย` +
      `ต่ออุปกรณ์ของท่าน และเนื้อหาในคุกกี้จะถูกเรียกออกมาดูหรืออ่านได้โดยบริการที่สร้างคุกกี้ดังกล่าวเท่านั้นคุกกี้ คือ ไฟล์ข้อความขนาดเล็กที่ถูกดาวน์โหลดไปยังอุปกรณ์คอมพิวเตอร์หรืออุปกรณ์มือถือของท่าน ซึ่งทำหน้าที่บันทึกข้อมูลและการตั้งค่าต่าง ๆ เช่น บันทึกข้อมูลการตั้งค่าภาษาในอุปกรณ์บนอุปกรณ์ของท่าน บันทึกสถานะการเข้าใช้งานในปัจจุบันของท่าน เพื่อช่วยให้ท่านสามารถเข้าใช้งานเว็บไซต์และ/หรือแอปพลิเคชัน (รวมเรียกว่า “บริการ”) ได้อย่างต่อเนื่อง รวมถึงมี` +
      `การรวบรวมข้อมูลเกี่ยวกับประวัติการเข้าใช้งานบริการที่ท่านชื่นชอบในรูปแบบไฟล์ โดยคุกกี้ไม่ได้ทำให้เกิดอันตรายต่ออุปกรณ์ของท่าน และเนื้อหาในคุกกี้จะถูกเรียกออกมาดูหรืออ่านได้โดยบริการที่สร้างคุกกี้ดังกล่าวเท่านั้น` +
      `${newTwoLine}การทำงานของคุกกี้ ช่วยให้เรารวบรวมและจัดเก็บข้อมูลการเยี่ยมชมเว็บไซต์ของท่านดังต่อไปนี้โดยอัตโนมัติ${newLine}` +
      `1. อินเตอร์เน็ตโดเมนและ IP Address จากจุดที่ท่านเข้าสู่เว็บไซต์${newLine}` +
      `2. ประเภทของเบราว์เซอร์ซอฟต์แวร์ ตลอดจนโครงสร้างและระบบการปฏิบัติงานที่ใช้ในการเข้าสู่เว็บไซต์${newLine}` +
      `3. วันที่และเวลาที่ท่านเข้าสู่เว็บไซต์${newLine}` +
      `4. ที่อยู่ของเว็บไซต์อื่นที่เชื่อมโยงท่านเข้าสู่เว็บไซต์ของเรา${newLine}` +
      `5. หน้าเว็บที่ท่านเข้า เยี่ยมชม และนำท่านออกจากเว็บไซต์ของเรา รวมถึงเนื้อหาบนหน้าเว็บที่ท่านเยี่ยมชมและระยะเวลาที่ท่านใช้ในการเยี่ยมชม${newLine}` +
      `${newLine}คำแถลงเกี่ยวกับนโยบายส่วนบุคคลของเรา จะกำหนดรายละเอียดทั้งหมดเกี่ยวกับข้อมูลอื่นๆ ที่เราจัดเก็บและวิธีการที่เรานำข้อมูลส่วนตัวของท่านไปใช้`,
  },
];

const privacy2 = [
  {
    type: 'string',
    value:
      `${newLine}<head>การใช้งานคุกกี้</head>` +
      `ประเภทของคุกกี้ที่เราใช้และในการใช้คุกกี้ดังกล่าว สามารถแบ่งประเภทได้ดังนี้` +
      `${newTwoLine}<head>"คุกกี้ประเภทที่มีความจำเป็นอย่างยิ่ง (Strictly Necessary Cookies)"</head>` +
      `สำคัญต่อการทำงานของเว็บไซต์ ซึ่งรวมถึงคุกกี้ที่ทำให้ท่านสามารถเข้าถึงข้อมูลและใช้งานในเว็บไซต์ของเราได้อย่างปลอดภัย` +
      `${newTwoLine}<head>"คุกกี้เพื่อการวิเคราะห์/วัดผลการทำงานของเว็บไซต์(Analytical/Performance Cookies)"</head>` +
      `คุกกี้ประเภทนี้จะช่วยให้เราสามารถจดจำและนับจำนวนผู้เข้าเยี่ยมชมเว็บไซต์ตลอดจนช่วยให้เราทราบถึงพฤติกรรมในการเยี่ยมชมเว็บไซต์ เพื่อปรับปรุงการทำงานของเว็บไซต์ให้มีคุณภาพดีขึ้นและมีความเหมาะสมมากขึ้น อีกทั้งเพื่อรวบรวมข้อมูลทางสถิติเกี่ยวกับวิธีการเข้าและพฤติกรรมการเยี่ยมชมเว็บไซต์ ซึ่งจะช่วยปรับปรุงการทำงานของเว็บไซต์โดยให้ผู้ใช้งานสามารถค้นหาสิ่งที่ต้องการได้อย่างง่ายดาย และช่วยให้เราเข้าใจถึงความสนใจของผู้ใช้ และวัดความมีประสิทธิผลของโฆษณาของเรา` +
      `${newTwoLine}<head>"คุกกี้เพื่อการทำงานของเว็บไซต์ (Functionality Cookies)"</head>` +
      `คุกกี้ประเภทนี้ใช้ในการจดจำตัวท่านเมื่อท่านกลับมาใช้งานเว็บไซต์อีกครั้ง ซึ่งจะช่วยให้เราสามารถปรับแต่งเนื้อหาสำหรับท่าน ปรับให้เว็บไซต์ของเราตอบสนองความต้องการใช้งานของท่าน รวมถึงจดจำการตั้งค่าของท่าน อาทิ ภาษา หรือภูมิภาค หรือขนาดของตัวอักษรที่ท่านเลือกใช้ในการใช้งานในเว็บไซต์` +
      `${newTwoLine}<head>"คุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย (Targeting Cookies"</head>` +
      `คุกกี้ประเภทนี้จะบันทึกการเข้าชมเว็บไซต์ของท่าน หน้าเว็บที่ท่านได้เยี่ยมชม และลิงค์ที่ท่านเยี่ยมชม เราจะใช้ข้อมูลนี้เพื่อปรับให้เว็บไซต์และเนื้อหาใด ๆ ที่ปรากฏอยู่บนหน้าเว็บตรงกับความสนใจของคุณมากขึ้น นอกจากนี้ เรายังอาจแชร์ข้อมูลนี้กับบุคคลที่สามเพื่อวัตถุประสงค์ดังกล่าว และ` +
      `${newTwoLine}<head>"คุกกี้เพื่อการโฆษณา (Advertising Cookies)"</head>` +
      `คุกกี้ประเภทนี้จะจดจำการตั้งค่าของท่านในการเข้าใช้งานหน้าเว็บไซต์ และนำไปใช้เป็นข้อมูลประกอบการปรับเปลี่ยนหน้าเว็บไซต์เพื่อนำเสนอโฆษณาที่เหมาะสมกับท่านมากที่สุดเท่าที่จะเป็นไปได้ ตัวอย่างเช่น การเลือกแสดงโฆษณาสินค้าที่ท่านสนใจ การป้องกันหรือการจำกัดจำนวนครั้งที่ท่านจะเห็นหน้าเว็บไซต์ของโฆษณาซ้ำๆ เพื่อช่วยวัดความมีประสิทธิผลของโฆษณา` +
      `${newTwoLine}โปรดทราบว่าคุกกี้บางประเภทในเว็บไซต์นี้จัดการโดยบุคคลที่สาม เช่น เครือข่ายการโฆษณา ลักษณะการทำงานต่าง ๆ อาทิ วิดีโอ แผนที่ และโซเชียลมีเดีย และผู้ให้บริการเว็บไซต์ภายนอกอื่น ๆ เช่น บริการวิเคราะห์การเข้าเยี่ยมชมเว็บไซต์ เป็นต้น คุกกี้เหล่านี้มักจะเป็นคุกกี้เพื่อการวิเคราะห์/วัดผลการทำงาน หรือคุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย ท่านควรต้องศึกษานโยบายการใช้คุกกี้และนโยบายส่วนบุคคลในเว็บไซต์ของบุคคลที่สาม เพื่อให้เข้าใจถึงวิธีการที่บุคคลที่สามอาจนำข้อมูลของท่านไปใช้`,
  },
];

const privacy3 = [
  {
    type: 'string',
    value:
      `${newLine}<head>การตั้งค่าคุกกี้</head>` +
      `ท่านสามารถบล็อกการทำงานของคุกกี้ได้โดยการกำหนดค่าในเบราวเซอร์ของท่าน ซึ่งท่านอาจปฏิเสธการติดตั้งค่าคุกกี้ทั้งหมดหรือบางประเภทก็ได้ แต่พึงตระหนักว่าหากท่านตั้งค่าเบราวเซอร์ของท่านด้วยการบล็อกคุกกี้ทั้งหมด (รวมถึงคุกกี้ที่จำเป็นต่อการใช้งาน) ท่านอาจจะไม่สามารเข้าสู่เว็บไซต์ทั้งหมดหรือบางส่วนของเราได้` +
      `${newTwoLine}เมื่อใดก็ตามที่ท่านต้องการยกเลิกความยินยอมการใช้งานคุกกี้ ท่านจะต้องตั้งค่าเบราวเซอร์ของท่านเพื่อให้ลบคุกกี้ออกจากแต่ละเบราวเซอร์ที่ท่านใช้งาน` +
      `หากท่านต้องการข้อมูลเพิ่มเติมเกี่ยวกับวิธีการดังกล่าว โปรดเลือกหัวข้อ “ความช่วยเหลือ” ในอินเตอร์เน็ตเบราวเซอร์ของท่านเพื่อศึกษาในรายละเอียดมากขึ้น`,
  },
];

const privacy4 = [
  {
    type: 'link',
    value:
      `${newLine}เว็บไซต์นี้ใช้คุกกี้เพื่อวัตถุประสงค์ในการปรับปรุงประสบการณ์ของผู้ใช้ให้ดียิ่งขึ้น ท่านสามารถศึกษารายละเอียดเพิ่มเติมเกี่ยวกับประเภทของคุกกี้ที่เราจัดเก็บ เหตุผลในการใช้คุกกี้ และวิธีการตั้งค่าคุกกี้ได้ใน ` +
      `<link>นโยบายคุกกี้</link>` +
      `<link>คำแถลงเกี่ยวกับนโยบายส่วนบุคคล</link>`,
  },
];

const content = [...privacy1, ...privacy2, ...privacy3, ...privacy4];

export default content;
