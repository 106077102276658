import React from 'react';
import {translate} from '../../../res/language/translate';
import {OverrideStyles} from './styled-component';
import Sidebar from '../../../views/app-bar/side-bar';
import Divider from '../../../components/divider';
import Header from '../../../components/header';
import MenuList from './menu-list';
import AvatarProfile from '../../../views/avatar-profile';

const MenuSidebar = () => {
  return (
    <Sidebar>
      <Header variant="h3">{translate('setting.screenTitle')}</Header>
      <Divider style={OverrideStyles.divider} />
      <AvatarProfile size="default" />
      <Divider />
      <MenuList />
    </Sidebar>
  );
};

MenuSidebar.propTypes = {};

export default MenuSidebar;
