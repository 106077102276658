import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as seoActions from '../../../redux/seo-index/action';

export const useCompanyIndexEffect = () => {
  const dispatch = useDispatch();
  const {data, alphabet, type} = useSelector((state) => state.seoIndex.companyIndex);

  useEffect(() => {
    if (alphabet) {
      const currentData = data || {};
      if (!Array.isArray(currentData[alphabet])) {
        dispatch(seoActions.updateCompanyIndex({data: {alphabet, type}}));
      }
    }
  }, [alphabet, type, data, dispatch]);
};

export const useCompanyIndexDispatch = () => {
  const dispatch = useDispatch();
  const dispatchUpdateKey = (alphabet, type) => {
    if (!type) {
      return;
    }
    dispatch(seoActions.updateCompanyIndexKey({data: {alphabet, type}}));
  };

  const dispatchFetchCompanyIndex = (alphabet, type) => {
    dispatch(seoActions.updateCompanyIndex({data: {alphabet, type}}));
  };
  return {dispatchUpdateKey, dispatchFetchCompanyIndex};
};

export const useCompanyIndexState = () => {
  const {
    data: companiesState,
    alphabet: alphabetState,
    type: typeState,
    loading: isLoadingState,
    counter: counterState,
  } = useSelector((state) => state.seoIndex.companyIndex);
  return {
    companiesState,
    alphabetState,
    typeState,
    isLoadingState,
    counterState,
  };
};
