import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import {SVGContainer} from './styled-component';

const SVGImage = (props) => {
  const {width, height, size, src, autoScale, maxHeight, inline, inlineStyle, color} = props;
  if (inline) {
    return <SVG src={src} cacheRequests width={width} height={height} style={inlineStyle} color={color} />;
  }
  return <SVGContainer autoScale={autoScale} maxHeight={maxHeight} height={height} width={width} src={src} size={size} />;
};

SVGImage.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  size: PropTypes.number,
  autoScale: PropTypes.bool,
  inline: PropTypes.bool,
  inlineStyle: PropTypes.object,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

SVGImage.defaultProps = {
  width: 250,
  height: 250,
  size: 0,
  autoScale: true,
  maxHeight: 250,
  inline: false,
  inlineStyle: null,
  color: null,
};

export default SVGImage;
