import {put, delay} from 'redux-saga/effects';
import * as TYPES from './type';
import * as effects from '../utils/effect';

export function* snackbarSetMessageAction({payload}) {
  yield put({type: TYPES.SNACKBAR_SET_MESSAGE_SUCCESS, payload: payload?.data});
}

export function* snackbarRemoveMessageAction({payload}) {
  yield delay(effects.DEBOUNCE_REMOVE_SNACKBAR);
  yield put({type: TYPES.SNACKBAR_REMOVE_MESSAGE_SUCCESS, payload});
}

export function* snackbarClearMessageAction() {
  yield put({type: TYPES.SNACKBAR_CLEAR_MESSAGE_SUCCESS});
}
