import uniqBy from 'lodash/uniqBy';
import provinceData from '../../res/json/district_data.json';

const MAIN_PROVINCE = 'กรุงเทพมหานคร';

export const generateProvince = () => {
  const data = [...provinceData.District];
  const province = data.map((elem) => elem.province_name.replace('จ. ', ''));
  const sortedProvince = uniqBy(province)
    .sort((a, b) => a.localeCompare(b))
    .filter((elem) => elem !== MAIN_PROVINCE);
  sortedProvince.splice(0, 0, MAIN_PROVINCE);
  return [...sortedProvince];
};
