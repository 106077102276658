import {useEffect, useState} from 'react';
import isEqual from 'lodash/isEqual';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useSearchParamsEffect} from '../useSearchParams';
import {JOB_PAGE_SIZE} from '../../context/pagination';
import * as searchActions from '../../../redux/search/action';
import * as typeDef from '../../../utils/strong-types';
import * as coreQuery from '../../context/filter';

export const useSearchId = () => {
  const dispatch = useDispatch();
  const {searchParamsState} = useSearchParamsEffect();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const {job: jobIdState, loading: isLoadingState, error: errorState} = useSelector((state) => state.search.jobId);
  const isEmptyResult = !isLoadingState && !!errorState;

  const dispatchSearchId = (id) => {
    if (!id) {
      return;
    }
    dispatch(
      searchActions.searchJobId({
        data: id,
        token: isLoggedInState.user,
      }),
    );
  };

  useEffect(() => {
    if (typeDef.isObject(searchParamsState)) {
      const {id} = searchParamsState;
      if (id) {
        dispatchSearchId(id);
      }
    }
  }, [searchParamsState]);

  return {
    isLoadingState,
    jobIdState,
    errorState,
    isEmptyResult,
    dispatchSearchId,
  };
};

export const useSearchQueryState = () => {
  const [isEmptyDataState, setIsEmptyDataState] = useState(false);
  const [isCanFetchMoreState, setIsCanFetchMoreState] = useState(true);

  const {
    loading: isLoadingState,
    pageIndex: pageIndexState,
    cacheToken: cacheTokenState,
    query: {newFeed: newFeedState, stackFeed: stackFeedState},
  } = useSelector((state) => state.search.jobQuery);

  useEffect(() => {
    if (!isLoadingState && newFeedState) {
      if (newFeedState.resultCount === 0) {
        setIsEmptyDataState(true);
      } else {
        setIsEmptyDataState(false);
      }
    }
  }, [isLoadingState, newFeedState]);

  useEffect(() => {
    if (isCanFetchMoreState && newFeedState?.resultCount && Array.isArray(stackFeedState)) {
      if (stackFeedState.length >= newFeedState?.resultCount || typeDef.isNotArrayLength(newFeedState?.results)) {
        setIsCanFetchMoreState(false);
      }
    }
  }, [newFeedState, stackFeedState, isCanFetchMoreState]);

  return {
    isEmptyDataState,
    isLoadingState,
    pageIndexState,
    newFeedState,
    stackFeedState,
    isCanFetchMoreState,
    cacheTokenState,
  };
};

export const useSearchQueryDispatch = () => {
  const dispatch = useDispatch();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const {searchParamsState} = useSearchParamsEffect();

  const dispatchFetchSearchQuery = (pageIndex, cacheTokenState) => {
    if (!pageIndex) {
      return;
    }
    const reqBody = {
      params: coreQuery.transformUrlParamsToQuery(searchParamsState),
      saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
      pageIndex,
      pageSize: JOB_PAGE_SIZE,
    };
    if (cacheTokenState) {
      reqBody.token = cacheTokenState;
    }
    dispatch(
      searchActions.searchQuery({
        data: reqBody,
        token: isLoggedInState.user,
        saveToken: false,
      }),
    );
  };

  const dispatchFetchSearchQueryNearby = (pageIndex, cacheToken, nonFuzzyToken) => {
    const reqBody = {
      params: coreQuery.transformUrlParamsToQuery(searchParamsState),
      saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
      pageIndex,
      pageSize: JOB_PAGE_SIZE,
      token: cacheToken,
    };

    if (nonFuzzyToken) {
      reqBody.nonFuzzyToken = nonFuzzyToken;
    }
    dispatch(
      searchActions.searchQueryNearby({
        data: reqBody,
        token: isLoggedInState.user,
      }),
    );
  };

  return {
    dispatchFetchSearchQuery,
    dispatchFetchSearchQueryNearby,
  };
};

export const useSearchQueryEffect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const {searchParamsState} = useSearchParamsEffect();

  useEffect(() => {
    if (typeDef.isObject(searchParamsState) && navigator.onLine) {
      window.scrollTo(0, 0);
      dispatch(searchActions.searchQuery({clear: true}));
      const reqBody = {
        params: coreQuery.transformUrlParamsToQuery(searchParamsState),
        saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
        pageIndex: 0,
        pageSize: JOB_PAGE_SIZE,
      };
      dispatch(
        searchActions.searchQuery({
          data: reqBody,
          token: isLoggedInState.user,
          saveToken: true,
        }),
      );
    }
  }, [searchParamsState, isLoggedInState, dispatch]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP' || history.action === 'REPLACE') {
        dispatch(
          searchActions.searchQuery({
            clear: true,
          }),
        );
      }
    };
  }, [history, dispatch]);
};

export const useSearchAuthorState = () => {
  const [isEmptyDataState, setIsEmptyDataState] = useState(false);
  const [isCanFetchMoreState, setIsCanFetchMoreState] = useState(true);

  const {
    loading: isLoadingState,
    pageIndex: pageIndexState,
    author: {newFeed: newFeedState, stackFeed: stackFeedState},
  } = useSelector((state) => state.search.jobAuthor);

  useEffect(() => {
    if (!isLoadingState && newFeedState) {
      if (newFeedState.resultCount === 0) {
        setIsEmptyDataState(true);
      } else {
        setIsEmptyDataState(false);
      }
    }
  }, [isLoadingState, newFeedState]);

  useEffect(() => {
    if (isCanFetchMoreState && newFeedState?.resultCount && Array.isArray(stackFeedState)) {
      if (stackFeedState.length >= newFeedState?.resultCount || typeDef.isNotArrayLength(newFeedState?.results)) {
        setIsCanFetchMoreState(false);
      }
    }
  }, [newFeedState, stackFeedState, isCanFetchMoreState]);

  return {
    isEmptyDataState,
    isLoadingState,
    pageIndexState,
    newFeedState,
    stackFeedState,
    isCanFetchMoreState,
  };
};

export const useSearchAuthorDispatch = () => {
  const dispatch = useDispatch();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const {searchParamsState} = useSearchParamsEffect();

  const dispatchFetchSearchAuthor = (pageIndex) => {
    if (!pageIndex) {
      return;
    }
    const reqBody = {
      params: coreQuery.transformUrlParamsToQuery(searchParamsState),
      saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
      pageIndex,
      pageSize: JOB_PAGE_SIZE,
    };
    dispatch(
      searchActions.searchJobAuthor({
        data: reqBody,
        token: isLoggedInState.user,
      }),
    );
  };

  return {
    dispatchFetchSearchAuthor,
  };
};

export const useSearchAuthorEffect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const {searchParamsState} = useSearchParamsEffect();

  useEffect(() => {
    if (typeDef.isObject(searchParamsState) && navigator.onLine) {
      dispatch(searchActions.searchJobAuthor({clear: true}));
      const reqBody = {
        params: coreQuery.transformUrlParamsToQuery(searchParamsState),
        saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
        pageIndex: 0,
        pageSize: JOB_PAGE_SIZE,
      };
      dispatch(
        searchActions.searchJobAuthor({
          data: reqBody,
          token: isLoggedInState.user,
        }),
      );
    }
  }, [searchParamsState, isLoggedInState, dispatch]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP' || history.action === 'REPLACE') {
        dispatch(
          searchActions.searchJobAuthor({
            clear: true,
          }),
        );
      }
    };
  }, [history, dispatch]);
};

export const useSearchHashtagState = () => {
  const [isEmptyDataState, setIsEmptyDataState] = useState(false);
  const [isCanFetchMoreState, setIsCanFetchMoreState] = useState(true);

  const {
    loading: isLoadingState,
    pageIndex: pageIndexState,
    hashtag: {newFeed: newFeedState, stackFeed: stackFeedState},
  } = useSelector((state) => state.search.jobHashtag);

  useEffect(() => {
    if (!isLoadingState && newFeedState) {
      if (newFeedState.resultCount === 0) {
        setIsEmptyDataState(true);
      } else {
        setIsEmptyDataState(false);
      }
    }
  }, [isLoadingState, newFeedState]);

  useEffect(() => {
    if (isCanFetchMoreState && newFeedState?.resultCount && Array.isArray(stackFeedState)) {
      if (stackFeedState.length >= newFeedState?.resultCount || typeDef.isNotArrayLength(newFeedState?.results)) {
        setIsCanFetchMoreState(false);
      }
    }
  }, [newFeedState, stackFeedState, isCanFetchMoreState]);

  return {
    isEmptyDataState,
    isLoadingState,
    pageIndexState,
    newFeedState,
    stackFeedState,
    isCanFetchMoreState,
  };
};

export const useSearchHashtagDispatch = () => {
  const dispatch = useDispatch();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const {searchParamsState} = useSearchParamsEffect();

  const dispatchFetchSearchHashtag = (pageIndex) => {
    if (!pageIndex) {
      return;
    }
    const reqBody = {
      params: coreQuery.transformUrlParamsToQuery(searchParamsState),
      saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
      pageIndex,
      pageSize: JOB_PAGE_SIZE,
    };
    dispatch(
      searchActions.searchHashtag({
        data: reqBody,
        token: isLoggedInState.user,
      }),
    );
  };

  return {
    dispatchFetchSearchHashtag,
  };
};

export const useSearchHashtagEffect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentParamsState, setCurrentParamsState] = useState('');
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const {
    loading: isLoadingState,
    hashtag: {newFeed: newFeedState},
    error: errorState,
  } = useSelector((state) => state.search.jobHashtag);
  const {searchParamsState} = useSearchParamsEffect();

  useEffect(() => {
    const isEqualParams = isEqual(searchParamsState, currentParamsState);
    const isMatchCondition =
      !errorState && !isLoadingState && typeDef.isObject(searchParamsState) && !Array.isArray(newFeedState?.results) && navigator.onLine;
    if (isMatchCondition) {
      const reqBody = {
        params: coreQuery.transformUrlParamsToQuery(searchParamsState),
        saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
        pageIndex: 0,
        pageSize: JOB_PAGE_SIZE,
      };
      dispatch(
        searchActions.searchHashtag({
          data: reqBody,
          token: isLoggedInState.user,
        }),
      );
      setCurrentParamsState(searchParamsState);
      return;
    }
    if (!isEqualParams && currentParamsState) {
      dispatch(searchActions.searchHashtag({clear: true}));
      const reqBody = {
        params: coreQuery.transformUrlParamsToQuery(searchParamsState),
        saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
        pageIndex: 0,
        pageSize: JOB_PAGE_SIZE,
      };
      dispatch(
        searchActions.searchHashtag({
          data: reqBody,
          token: isLoggedInState.user,
        }),
      );
    }
    setCurrentParamsState(searchParamsState);
  }, [searchParamsState, isLoggedInState, isLoadingState, newFeedState, errorState, currentParamsState, dispatch]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP' || history.action === 'REPLACE') {
        dispatch(
          searchActions.searchHashtag({
            clear: true,
          }),
        );
      }
    };
  }, [history, dispatch]);
};
