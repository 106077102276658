import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
`;

export const SeparatorWrapper = styled.span`
  padding: 0 0.25rem;
`;

export const TextWarning = styled.p`
  margin-top: 0.5rem;
  font-size: 10px;
  color: ${(props) => props.theme.danger};
`;

export const OverrideStyles = {
  boxInput: {
    fontFamily: 'Prompt Light',
    fontSize: 24,
    fontWeight: 'Bold',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: lightTheme.gray,
  },
};
