import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useRecommendJob, useRecommendJobEffect} from '../../lib/hooks/logic/useRecommendJob';
import {Container, TextCounter, OverrideStyles} from './styled-component';
import {translate} from '../../res/language/translate';
import Chip from '../../components/chip';
import Row from '../../components/row';
import Header from '../../components/header';
import SkeletonLoading from '../../components/skeleton';
import * as typeDef from '../../utils/strong-types';
import * as numberFormat from '../../utils/number';
import * as ROUTES from '../../routes/routes-name';
import * as navigation from '../../utils/navigation/navigation-services';
import * as mixpanel from '../../utils/mixpanel';

const RecommendJob = (props) => {
  const {province, isLoggedIn} = props;
  useRecommendJobEffect(province);

  const nodePopularRef = useRef();
  const {popularJobCat, allJobCat, loading: isLoading} = useRecommendJob();
  const [isExpanded, setIsExpanded] = useState(false);

  const onEventNavigation = (pathname, search) => {
    navigation.navigateTo({pathname, search});
  };

  const onClickChip = (item) => {
    if (isLoggedIn) {
      if (item.id === 999) {
        onEventNavigation(ROUTES.ROUTE_JOB_CATEGORY);
        return;
      }
      if (item.tag) {
        mixpanel.homeHighlightRecommend(isLoggedIn, item.tag, window.location.pathname);
        onEventNavigation(ROUTES.ROUTE_SEARCH_HASHTAG, {tag: [item.tag]});
        return;
      }
      mixpanel.homeHighlightRecommend(isLoggedIn, item._id[0], window.location.pathname);
      onEventNavigation(ROUTES.ROUTE_SEARCH_RESULTS, {jobcat: [item._id[0]]});
      return;
    }
    if (item.id === 999) {
      const isScrollToView = isExpanded;
      setIsExpanded(!isExpanded);
      if (isScrollToView && nodePopularRef.current) {
        nodePopularRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
      return;
    }
    if (item.tag) {
      mixpanel.homeHighlightRecommend(isLoggedIn, item.tag, window.location.pathname);
      onEventNavigation(ROUTES.ROUTE_SEARCH_HASHTAG, {tag: [item.tag]});
      return;
    }
    mixpanel.homeHighlightRecommend(isLoggedIn, item._id[0], window.location.pathname);
    onEventNavigation(ROUTES.ROUTE_SEARCH_RESULTS, {jobcat: [item._id[0]]});
  };

  return (
    <Container ref={nodePopularRef}>
      {isLoading && !typeDef.isArrayLength(popularJobCat) && <SkeletonLoading count={4} height={35} width={100} />}
      {isExpanded && (
        <Header variant="h3" style={OverrideStyles.header}>
          {translate('home.searchPopular')}
        </Header>
      )}
      {typeDef.isArrayLength(popularJobCat) && (
        <Row>
          {popularJobCat.map((item, index) => {
            if (isExpanded && item.id === 999) {
              return null;
            }
            return (
              <Chip
                key={index.toString()}
                wrapText
                active={item.id === 999}
                text={
                  <>
                    {item.name}
                    {item.id !== 999 && <TextCounter>{`(${numberFormat.numberWithComma({input: item.count})})`}</TextCounter>}
                  </>
                }
                onClick={() => onClickChip(item)}
              />
            );
          })}
        </Row>
      )}
      {isExpanded && typeDef.isArrayLength(allJobCat) && (
        <>
          <Header variant="h3" style={OverrideStyles.header2}>
            {translate('search.allJobCat')}
          </Header>
          <Row>
            {allJobCat.map((item, index) => (
              <Chip
                key={index.toString()}
                wrapText
                text={
                  <>
                    {item._id[0]}
                    {<TextCounter>{`(${numberFormat.numberWithComma({input: item.count})})`}</TextCounter>}
                  </>
                }
                onClick={() => onClickChip(item)}
              />
            ))}
            <Chip wrapText active text={translate('timeline.seeLess')} onClick={() => onClickChip({id: 999})} />
          </Row>
        </>
      )}
    </Container>
  );
};

RecommendJob.propTypes = {
  province: PropTypes.string,
  isLoggedIn: PropTypes.bool,
};

RecommendJob.defaultProps = {
  province: '',
  isLoggedIn: false,
};

export default RecommendJob;
