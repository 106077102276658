export const convertDate = (dateObj) => {
  if (!dateObj) {
    return '';
  }
  const {year, month, day} = dateObj;
  if (!year || !month) {
    return '';
  }

  const m = month <= 9 ? `0${month}` : month;
  if (!day) {
    return `${year}-${m}-01`;
  }
  const d = day <= 9 ? `0${day}` : day;
  return `${year}-${m}-${d}`;
};
