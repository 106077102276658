import _ from 'lodash';
import {diffChars} from 'diff';
import uap from 'ua-parser-js';

const MAGIC_CHAR = 'µ';

const getPrettyUniversityName = (university) => {
  if (typeof university !== 'string') {
    return '';
  }
  if (university.includes('มหาวิทยาลัย') || university.includes('สถาบัน') || university.includes('วิทยาลัย')) {
    return university;
  }
  return `มหาวิทยาลัย${university}`;
};

const getPrettyCompanyName = (company) => {
  if (typeof company !== 'string') {
    return '';
  }
  if (company.includes('บริษัท')) {
    return company;
  }
  return `บริษัท ${company}`;
};

const getSchoolLine = (edu) => {
  if (edu.level === 'ม.3' || edu.level === 'ม.6') {
    return _.get(edu, 'school');
  }
  if (edu.level === 'ปวช.' || edu.level === 'ปวส.') {
    const branch = edu.branch || '';
    return `${_.get(edu, 'school')} สาขาวิชา ${branch}`;
  }
  const faculty = edu.faculty || '';
  const universityName = getPrettyUniversityName(edu.school);
  return `${universityName} คณะ${faculty}`;
};

const bodyEmptyExperience = (educations, pronoun) => {
  if (!Array.isArray(educations) || (Array.isArray(educations) && !educations.length)) {
    return ' ';
  }
  return `${pronoun}เองจบการศึกษาจาก${getSchoolLine(educations[0])}`;
};

const bodyExperience = (user, pronoun) => {
  if (!user) {
    return ' ';
  }
  if (user?.job_experience) {
    return `ซึ่งตัว${pronoun}มีประสบการณ์ในด้านดังกล่าวเป็นเวลา ${user?.job_experience} ปี`;
  }

  return bodyEmptyExperience(user?.educations, pronoun);
};

export const defaultBody = (user, companyName) => {
  const {general, email, facebook, tel} = user;
  const {firstname, lastname} = general;
  const pronoun1 = general?.sex === 'f' ? 'ดิฉัน ' : 'ผม ';
  const pronoun2 = general?.sex === 'f' ? 'ดิฉัน' : 'ผม';
  const tel2 = user?.contactable_tel?.length ? user?.contactable_tel.join(', ') : tel;
  const email2 = email || facebook?.email;
  const ending = general?.sex === 'f' ? 'ค่ะ' : 'ครับ';
  const expLine = bodyExperience(user, pronoun2);
  const companyLine = companyName ? `ของ${getPrettyCompanyName(companyName)}` : '';

  let nameTitle;
  if (general?.sex !== 'f') nameTitle = 'นาย ';
  else if (general?.marital_status === 'สมรส') nameTitle = 'นาง ';
  else nameTitle = 'นางสาว ';
  return `เรียน ฝ่ายบุคคล

${pronoun1}${nameTitle}${firstname} ${lastname} มีความประสงค์จะสมัครงานในตำแหน่ง µ ${companyLine} ${pronoun2}รู้สึกสนใจในตำแหน่งงานและองค์กรเป็นอย่างยิ่ง ${expLine} และเชื่อว่ามีคุณสมบัติและประสบการณ์ที่เหมาะสมกับตำแหน่งงานนี้${ending}

${pronoun2}ได้แนบเอกสารประวัติการทำงานเพื่อพิจารณาตามลิ้งค์ด้านล่าง${ending}
หากต้องการข้อมูลใดเพิ่มเติมสามารถติดต่อกลับตามช่องทางด้านล่างนี้ ขอบพระคุณสำหรับโอกาสในการพิจารณา${ending}

ขอแสดงความนับถือ
${firstname} ${lastname}
อีเมล: ${email2}
โทรศัพท์: ${tel2.replace(/(\d?\d\d)(\d\d\d)(\d\d\d\d)/g, '$1-$2-$3')}`;
};
export const defaultTitle = 'Re: สมัครงานตำแหน่ง µ';

const clean = (str) => str.trim().replace(/[ \t]+/g, ' ');

export const renderTitle = (draftTitle, jobTitle) => {
  const template = clean(draftTitle || defaultTitle);
  const prefix = /[a-z]/i.test(jobTitle[0]) ? ' ' : '';
  const suffix = /[a-z]/i.test(jobTitle[jobTitle.length - 1]) ? ' ' : '';
  return {
    template,
    text: clean(template.replace(MAGIC_CHAR, prefix + jobTitle + suffix)),
  };
};

export const renderBody = (draftBody, user, jobTitle, companyName) => {
  const template = clean(draftBody || defaultBody(user, companyName));
  const prefix = /[a-z]/i.test(jobTitle[0]) ? ' ' : '';
  const suffix = /[a-z]/i.test(jobTitle[jobTitle.length - 1]) ? ' ' : '';
  return {
    template,
    text: clean(template.replace(MAGIC_CHAR, prefix + jobTitle + suffix)),
  };
};

export const diffTemplate = (template, beforeText, afterText) => {
  let variableStart = 0;
  let variableEnd = 0;

  const chunks = diffChars(beforeText, template);
  let offset = 0;
  for (let i = 0, ii = chunks.length; i < ii; i++) {
    const chunk = chunks[i];
    if (chunk.removed) {
      variableStart = offset;
      variableEnd = offset + chunk.count;
    } else if (chunk.added) break;
    else offset += chunk.count;
  }

  const chunks2 = diffChars(beforeText, afterText);
  let offset2 = 0;
  let offset3 = 0;
  for (let i = 0, ii = chunks2.length; i < ii; i++) {
    const {count, removed, added} = chunks2[i];

    if (offset2 < variableStart) {
      if (removed) {
        variableStart -= count;
        variableEnd -= count;
      } else if (added) {
        variableStart += count;
        variableEnd += count;
      }
    }

    if (variableStart <= offset2 + offset3 && offset2 + offset3 < variableEnd) {
      if (removed) offset3 -= count;
      else if (added) offset3 += count;
    }

    offset2 += count;
  }

  return afterText.slice(0, variableStart) + MAGIC_CHAR + afterText.slice(variableEnd + offset3);
};

export const getDefaultMailVendor = (user) => {
  if (/windows/i.test(uap().os.name)) return 'gmail';
  if (navigator.share) return 'mailto';

  const email = user?.email || user?.facebook?.email;
  if (/@gmail\.com/.test(email)) return 'gmail';
  // removed since there are bugs if (/@outlook|@hotmail/.test(email)) return 'outlook';
  return 'mailto';
};

export const genUrl = (vendor, subject, to, body, notifyEmail, resumeUrl) => {
  const subject2 = encodeURIComponent(subject);
  const to2 = encodeURIComponent(`${to},${notifyEmail}`);
  const body2 = encodeURIComponent(`${body}\n\nลิงค์ดาวน์โหลด resume\n${resumeUrl}`);

  if (vendor === 'gmail') return `https://mail.google.com/mail/u/0/?hl=en&tf=cm&fs=1&to=${to2}&su=${subject2}&body=${body2}`;
  return `mailto:${to2}?subject=${subject2}&body=${body2}`;
};

const checkGeneral = (user) => {
  const checkList = {
    firstname: user?.general?.firstname ? 1 : 0,
    lastname: user?.general?.lastname ? 1 : 0,
    sex: user?.general?.sex ? 1 : 0,
    birthday: user?.general?.birthday ? 1 : 0,
    contactable_tel: user?.contactable_tel ? 1 : 0,
  };
  let counter = 0;
  if (checkList.firstname) {
    counter++;
  }
  if (checkList.lastname) {
    counter++;
  }
  if (checkList.sex) {
    counter++;
  }
  if (checkList.birthday) {
    counter++;
  }
  if (checkList.contactable_tel) {
    counter++;
  }
  return [checkList, counter];
};

const checkInterest = (user) => {
  let counter = 0;
  const checkList = {
    category: 0,
    position: 0,
    level: 0,
    type: 0,
    canStart: 0,
    salaryMin: 0,
    salaryMax: 0,
  };
  if (user?.job_interest?.jobcat?.length || user?.job_interest?.subjobcat?.length) {
    checkList.category = 1;
    counter++;
  }
  if (user?.interesting_positions?.length) {
    checkList.position = 1;
    counter++;
  }
  if (user?.job_level?.length) {
    checkList.level = 1;
    counter++;
  }
  if (user?.job_type_interest?.length) {
    checkList.type = 1;
    counter++;
  }
  if (user?.available_start_date) {
    checkList.canStart = 1;
    counter++;
  }
  if (user?.salary_min) {
    checkList.salaryMin = 1;
    counter++;
  }
  if (user?.salary_max) {
    checkList.salaryMax = 1;
    counter++;
  }
  return [checkList, counter];
};

const checkEducation = (user) => {
  return user?.educations?.length ? 1 : 0;
};

export const checkCanAutoResume = (user) => {
  const [listGeneral, countGeneral] = checkGeneral(user);
  if (!user) {
    return [{general: {...listGeneral}}, false];
  }
  const [listInterest, countInterest] = checkInterest(user);
  const checkList = {
    general: {...listGeneral},
    education: checkEducation(user),
    interest: {...listInterest},
  };
  const isCanAuto = checkList.education === 1 && countGeneral === 5 && countInterest === 7;
  return [checkList, isCanAuto];
};
