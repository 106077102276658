export const USER_SHORT_PROFILE_REQ = '@USER/SHORT_PROFILE_REQ';
export const USER_SHORT_PROFILE = '@USER/SHORT_PROFILE';

export const USER_DETAIL_PROFILE_REQ = '@USER/DETAIL_PROFILE_REQ';
export const USER_DETAIL_PROFILE = '@USER/DETAIL_PROFILE';

export const CLEAR_USER_REQ = '@USER/CLEAR_USER_REQ';
export const CLEAT_USER = '@USER/CLEAR_USER';

export const EDIT_USER_PROFILE_REQ = '@USER/EDIT_USER_PROFILE_REQ';
export const EDIT_USER_PROFILE = '@USER/EDIT_USER_PROFILE';

export const USER_CHANGE_PASSWORD_REQ = '@USER/USER_CHANGE_PASSWORD_REQ';
export const USER_CHANGE_PASSWORD = '@USER/CHANGE_PASSWORD';

export const USER_UPLOAD_RESUME_MANUAL_REQ = '@USER/UPLOAD_RESUME_MANUAL_REQ';
export const USER_UPLOAD_RESUME_MANUAL_SUCCESS = '@USER/UPLOAD_RESUME_MANUAL_SUCCESS';
export const USER_UPLOAD_RESUME_MANUAL_FAIL = '@USER/UPLOAD_RESUME_MANUAL_FAIL';
export const USER_UPLOAD_RESUME_MANUAL_CLEAR = '@USER/UPLOAD_RESUME_MANUAL_CLEAR';

export const USER_ERROR = '@USER/USER_ERROR';
export const USER_UPDATE_RESUME = '@USER/USER_UPDATE_RESUME';
export const USER_UPDATE_AUTO_RESUME = '@USER/USER_UPDATE_AUTO_RESUME';

export const USER_GENERATE_AUTO_RESUME_REQ = '@USER/GENERATE_AUTO_RESUME_REQ';
export const USER_GENERATE_AUTO_RESUME_SUCCESS = '@USER/GENERATE_AUTO_RESUME_SUCCESS';
export const USER_GENERATE_AUTO_RESUME_FAIL = '@USER/GENERATE_AUTO_RESUME_FAIL';

export const USER_SET_INIT = '@USER/USER_SET_INIT';

export const USER_ACCEPT_TERM_REQ = '@USER/ACCEPT_TERM_REQ';
export const USER_ACCEPT_TERM_SUCCESS = '@USER/ACCEPT_TERM_SUCCESS';
export const USER_ACCEPT_TERM_FAIL = '@USER/ACCEPT_TERM_FAIL';
