/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import {motion as FramerMotion} from 'framer-motion';

const AnimationTransitionY = (props) => {
  const {y, duration, children} = props;
  return (
    <FramerMotion.div animate={{y: y}} transition={{ease: 'easeOut', duration: duration}}>
      {children}
    </FramerMotion.div>
  );
};

AnimationTransitionY.propTypes = {
  y: PropTypes.number.isRequired,
  duration: PropTypes.number,
  children: PropTypes.any,
};

AnimationTransitionY.defaultProps = {
  duration: 1,
  children: null,
};

export default AnimationTransitionY;
