import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useUserState, useUserDispatch} from '../../../../lib/hooks-logic/useUser';
import {useContextStackDistrictState, useContextStackDistrictsDispatch} from '../../../../lib/hooks-logic/useStackDistrict';
import {FormWrapper, FormCenter, OverrideStyles} from '../styled-component';
import {SectionItem, OverrideStyles as InnerStyles, Wrapper} from './styled-component';
import Form from '../../../../components/form';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Divider from '../../../../components/divider';
import Loading from '../../../../components/loading';
import Header from '../../../../components/header';
import OptionBuilder from '../../../../views/option-builder/new-version';
import ButtonIcon from '../../../../components/button-icon';
import DatePicker from '../../../../components/date-picker';
import InputArea from '../../../../components/input-area';
import Checkbox from '../../../../components/checkbox';
import SelectProvince from '../../../../views/select-location-sibling/select-province';
import SelectDistrict from '../../../../views/select-location-sibling/select-district';
import * as ICONS from '../../../../components/icon/constants';
import * as initData from './init-data';
import * as typeDef from '../../../../utils/strong-types';
import * as logic from './logic';
import * as validate from './validate';
import * as snackbar from '../../../../utils/components/toast-notification/provider';
import * as coreUser from '../../../../lib/context/user';

const EditEducationForm = () => {
  const {formatMessage} = useIntl();
  const {userState, isLoadingState} = useUserState();
  const {dataStackDistrictState} = useContextStackDistrictState();
  const {dispatchUpdateProfile} = useUserDispatch();
  const {dispatchGetStackDistrictByProvince, dispatchGetAllDistrictByProvinceList} = useContextStackDistrictsDispatch();

  const [formState, setFormState] = useState();
  const [errorTextState, setErrorTextState] = useState([]);

  const onSubmit = () => {
    const [isValidate, errorList] = validate.validateAllRow(formState);
    if (typeDef.isArrayLength(errorList) && !isValidate) {
      setErrorTextState(errorList);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรอกข้อมูลให้ครบให้ครบ หรือลบแถวออก'}), 'warning');
      return;
    }
    if (!userState) {
      return;
    }
    const userGeneral = coreUser.getGeneralNewAge(userState.general);
    const formSubmit = {
      ...useState,
      ...userGeneral,
      works: logic.transformWorks(formState),
    };
    dispatchUpdateProfile(formSubmit);
  };

  const resetError = () => {
    if (typeDef.isArrayLength(errorTextState)) {
      setErrorTextState([]);
    }
  };

  const onChangeText = (key, value, index) => {
    const copyForm = [...formState];
    copyForm[index][key] = value;
    setFormState(copyForm);
    resetError();
  };

  const onChangeJobLevel = (value, index) => {
    const copyForm = [...formState];
    copyForm[index].job_level = value;
    setFormState(copyForm);
  };

  const onChangeCompanySize = (value, index) => {
    const copyForm = [...formState];
    copyForm[index].company_size = value;
    setFormState(copyForm);
  };

  const onAddRow = () => {
    if (typeDef.isNotArrayLength(formState)) {
      const copyForm = [{...initData.rowWork}];
      setFormState(copyForm);
      return;
    }
    const [isValidate, errorList] = validate.validateAllRow(formState);
    if (typeDef.isArrayLength(errorList) && !isValidate) {
      setErrorTextState(errorList);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรอกข้อมูลให้ครบให้ครบ หรือลบแถวออก'}), 'warning');
      return;
    }
    const copyForm = [...formState, {...initData.rowWork}];
    setFormState(copyForm);
  };

  const onDelRow = (index) => {
    const copyList = [...formState];
    copyList.splice(index, 1);
    setFormState(copyList);
    resetError();
  };

  const onChangeProvince = (province, index) => {
    if (!province) {
      return;
    }
    if (province.value !== 'ทั้งหมด') {
      dispatchGetStackDistrictByProvince(province.value, dataStackDistrictState);
    }
    const copyForm = [...formState];
    copyForm[index].province = [province];
    copyForm[index].district = [];
    setFormState(copyForm);
  };

  const onChangeDistrict = (district, index) => {
    if (!district) {
      return;
    }
    const copyForm = [...formState];
    copyForm[index].district = [district];
    setFormState(copyForm);
  };

  useEffect(() => {
    const isCanDefault = userState && typeDef.isNullOrUndefined(formState) && dataStackDistrictState;
    if (isCanDefault) {
      const defaultState = logic.mapWork(userState.works);
      const provinceList = [];
      defaultState.forEach((elem) => {
        if (Array.isArray(elem.province)) {
          provinceList.push(elem.province[0]);
        }
      });
      if (typeDef.isArrayLength(provinceList)) {
        dispatchGetAllDistrictByProvinceList(provinceList, dataStackDistrictState);
      }
      setFormState(defaultState);
    }
  }, [userState, formState, dataStackDistrictState]);

  return (
    <FormCenter>
      <FormWrapper>
        <Loading type="data" loading={isLoadingState && !userState} />
        <Loading type="action" loading={isLoadingState && !!userState} />
        {userState && typeDef.isNotArrayLength(formState) && (
          <Header center variant="h3" style={InnerStyles.emptyHeader}>
            {formatMessage({defaultMessage: '-ยังไม่มีข้อมูล-'})}
          </Header>
        )}
        {userState && (
          <Form formId="edit-edu" onSubmit={() => {}}>
            {typeDef.isArrayLength(formState) &&
              formState.map((item, index) => (
                <SectionItem key={index.toString()}>
                  <Input
                    name="company_name"
                    type="text"
                    fillBackground
                    label={formatMessage({defaultMessage: 'ชื่อบริษัท'})}
                    placeholder={formatMessage({defaultMessage: 'กรอกชื่อบริษัท'})}
                    value={item.company_name || ''}
                    onChange={(value) => onChangeText('company_name', value, index)}
                    warningText={errorTextState[index]?.company_name}
                  />
                  <OptionBuilder
                    name="op-company-size"
                    label={formatMessage({defaultMessage: 'ขนาดบริษัท'})}
                    placeholder={formatMessage({defaultMessage: 'เลือกขนาดบริษัท'})}
                    data={[...initData.companySizeData] || []}
                    value={item.company_size}
                    onChange={(value) => onChangeCompanySize(value, index)}
                    onChangeDel={(value) => onChangeCompanySize(value, index)}
                  />
                  <Input
                    name="position"
                    type="text"
                    fillBackground
                    label={formatMessage({defaultMessage: 'ตำแหน่ง'})}
                    placeholder={formatMessage({defaultMessage: 'กรอกตำแหน่ง'})}
                    value={item.position || ''}
                    onChange={(value) => onChangeText('position', value, index)}
                    warningText={errorTextState[index]?.position}
                  />

                  <OptionBuilder
                    name="op-job-lv"
                    label={formatMessage({defaultMessage: 'ระดับตำแหน่ง'})}
                    placeholder={formatMessage({defaultMessage: 'เลือกระดับตำแหน่ง'})}
                    data={initData.jobLevel || []}
                    value={item.job_level}
                    onChange={(value) => onChangeJobLevel(value, index)}
                    onChangeDel={(value) => onChangeJobLevel(value, index)}
                  />
                  <Input
                    name="salary"
                    type="number"
                    fillBackground
                    label={formatMessage({defaultMessage: 'เงินเดือน'})}
                    placeholder={formatMessage({defaultMessage: 'กรอกเงินเดือน'})}
                    value={item.salary || ''}
                    onChange={(value) => onChangeText('salary', value, index)}
                    warningText={errorTextState[index]?.salary}
                  />
                  <InputArea
                    height={200}
                    label={formatMessage({defaultMessage: 'รายละเอียด'})}
                    placeholder={formatMessage({defaultMessage: 'กรอกรายละเอียด'})}
                    value={item.job_description}
                    onChange={(value) => onChangeText('job_description', value, index)}
                    warningText={errorTextState[index]?.job_description}
                  />
                  <Input
                    name="address"
                    type="text"
                    fillBackground
                    label={formatMessage({defaultMessage: 'ที่อยู่'})}
                    placeholder={formatMessage({defaultMessage: 'กรอกที่อยู่'})}
                    value={item.address || ''}
                    onChange={(value) => onChangeText('address', value, index)}
                    warningText={errorTextState[index]?.address}
                  />
                  <SelectProvince
                    checkBy="value"
                    label={formatMessage({defaultMessage: 'จังหวัด'})}
                    placeholder={formatMessage({defaultMessage: 'เลือกจังหวัด'})}
                    selectedProvince={item.province || []}
                    onChange={(value) => onChangeProvince(value, index)}
                  />
                  <SelectDistrict
                    disabled={!item.province}
                    manualData={item.province ? dataStackDistrictState[item.province[0].value] : []}
                    label={formatMessage({defaultMessage: 'เขต/อำเภอ'})}
                    selectedDistrict={item.district || []}
                    onChange={(value) => onChangeDistrict(value, index)}
                  />
                  <DatePicker
                    label={formatMessage({defaultMessage: 'วันที่เริ่มทำงาน'})}
                    initDate={item.start}
                    onChange={(date) => onChangeText('start', date, index)}
                    warningText={errorTextState[index]?.start}
                  />

                  {!item.is_now && (
                    <DatePicker
                      label={formatMessage({defaultMessage: 'วันที่สิ้นสุดทำงาน'})}
                      initDate={item.end || ''}
                      onChange={(date) => onChangeText('end', date, index)}
                      warningText={errorTextState[index]?.end}
                    />
                  )}
                  <Wrapper>
                    <Checkbox
                      checked={item.is_now}
                      onChange={() => onChangeText('is_now', !item.is_now, index)}
                      label={formatMessage({defaultMessage: 'ปัจจุบันยังทำอยู่'})}
                    />
                  </Wrapper>

                  <Button small edgeInsets="none" variant="danger" onClick={() => onDelRow(index)}>
                    {formatMessage({defaultMessage: 'ลบแถว'})}
                  </Button>
                  <Divider bold edgeInsets />
                </SectionItem>
              ))}
            <ButtonIcon small variant="success" edgeInsets="none" iconName={ICONS.PLUS} onClick={onAddRow}>
              {formatMessage({defaultMessage: 'เพิ่มประวัติการทำงาน'})}
            </ButtonIcon>
            <Button block type="button" style={OverrideStyles.buttonSubmit} onClick={onSubmit}>
              {formatMessage({defaultMessage: 'บันทึกข้อมูล'})}
            </Button>
          </Form>
        )}
      </FormWrapper>
    </FormCenter>
  );
};

EditEducationForm.propTypes = {};

export default EditEducationForm;
