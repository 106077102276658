import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import throttle from 'lodash/throttle';

export const useScrollingEffect = ({callbackFunc = () => {}, scrollThrottle = 400, dependencies = []}) => {
  useEffect(() => {
    const debounceScrolling = throttle(callbackFunc, scrollThrottle);
    window.addEventListener('scroll', debounceScrolling);
    return () => window.removeEventListener('scroll', debounceScrolling);
  }, [callbackFunc, ...dependencies]);
};

export const useScrollResetEffect = (behavior = 'smooth') => {
  useEffect(() => {
    if (behavior) {
      window.scrollTo({top: 0, behavior: behavior});
    } else {
      window.scrollTo({top: 0});
    }
  }, [behavior]);
};

export const useScrollResetAfterPopEffect = (initialReset) => {
  const historyRoute = useHistory();
  useEffect(() => {
    if (initialReset && historyRoute.action === 'PUSH') {
      window.scrollTo({top: 0});
    }
    return () => {
      if (historyRoute.action === 'POP' || historyRoute.action === 'REPLACE') {
        window.scrollTo({top: 0});
      }
    };
  }, [historyRoute, initialReset]);
};

export const useScrollReset = () => {
  const resetScrollPosition = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };
  return {
    resetScrollPosition,
  };
};

export const useCurrentScrollYEffect = (breakpointYOffset) => {
  const [isBreakpointYState, setIsBreakpointYState] = useState(false);

  const initSetup = () => {
    const {pageYOffset} = window;
    if (pageYOffset >= breakpointYOffset && !isBreakpointYState) {
      setIsBreakpointYState(true);
    }
    if (pageYOffset < breakpointYOffset && isBreakpointYState) {
      setIsBreakpointYState(false);
    }
  };

  useEffect(() => {
    initSetup();
    const onScrolling = () => {
      const {pageYOffset} = window;
      if (pageYOffset >= breakpointYOffset && !isBreakpointYState) {
        setIsBreakpointYState(true);
      }
      if (pageYOffset < breakpointYOffset && isBreakpointYState) {
        setIsBreakpointYState(false);
      }
    };
    window.addEventListener('scroll', onScrolling);
    return () => window.removeEventListener('scroll', onScrolling);
  }, [breakpointYOffset, isBreakpointYState]);

  return {isBreakpointYState};
};

export const useScrollYDirectionEffect = () => {
  const [scrollDirection, setScrollDirection] = useState({scrollPos: 0, top: true, bottom: false});

  useEffect(() => {
    const handleScroll = () => {
      setScrollDirection({
        scrollPos: document.body.getBoundingClientRect().top,
        top: document.body.getBoundingClientRect().top > scrollDirection.scrollPos,
        bottom: document.body.getBoundingClientRect().top <= scrollDirection.scrollPos,
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollDirection]);

  return {
    scrollDirection,
  };
};

export const isEndReached = (nodeRef) => {
  if (!nodeRef) {
    return false;
  }
  const {clientHeight, scrollHeight} = nodeRef.current;
  const isBottomReached = clientHeight >= Math.round(scrollHeight - window.pageYOffset);
  return isBottomReached;
};

export const isScreenEndReached = (nodeRef) => {
  if (!nodeRef) {
    return false;
  }
  const {scrollHeight} = nodeRef.current;
  const isBottomReached = window.innerHeight + 1 >= Math.round(scrollHeight - window.pageYOffset);
  return isBottomReached;
};
