import { useEffect } from "react";
import axios from "axios";

const setPrerender = () => {
    window.prerenderReady = true;
};


export const init = () => {
    if (navigator.userAgent.indexOf('prerender') !== -1) {
        const delay = 2000;
        let timeout = setTimeout(setPrerender, delay);
        axios.interceptors.response.use((response) => {
            clearTimeout(timeout);
            timeout = setTimeout(setPrerender, delay);
            return response
        }, (error) => {
            clearTimeout(timeout);
            timeout = setTimeout(setPrerender, delay);
            return Promise.reject(error);
        });
    }
};

export const usePrerender = () => {
    useEffect(() => {
        init();
    }, []);
};