import React from 'react';
import PropTypes from 'prop-types';
import FormSearch from './formSearch';

import Modal from '../../../components/modal';

const ModalInterestedJob = (props) => {
  const {visible} = props;
  return (
    <Modal visible={visible} id="edit-interested-job">
      <FormSearch />
    </Modal>
  );
};

ModalInterestedJob.propTypes = {
  visible: PropTypes.bool,
};
ModalInterestedJob.defaultProps = {
  visible: false,
};
export default ModalInterestedJob;
