import * as typeDef from '../../utils/strong-types';
import {translate} from '../../res/language/translate';
import * as number from '../../utils/number';

const createDistrict = (district) => {
  if (typeDef.isArrayLength(district)) {
    return `${district[0].replace('|', '-')} `;
  }
  return `${district.replace('|', '-')} `;
};

const createProvince = (province) => {
  if (typeDef.isString(province)) {
    return province.split('|')[0];
  }
  return province;
};

const createHistoryJobCat = (jobCat) => {
  return `${jobCat[0]} `;
};

const createHistorySubJobCat = (subJobCat) => {
  return `${subJobCat[0].replace('|', '-')} `;
};

const createHistoryExp = (exp) => {
  if (typeDef.isArrayLength(exp)) {
    let expLabel = `ประสบการณ์ `;
    exp.forEach((elem, index) => {
      if (index === 0) {
        expLabel += `(`;
      }
      if (elem < 1) {
        if (elem === 0) {
          expLabel += `จบใหม่`;
        }
        if (elem === -1) {
          expLabel += `ฝึกงาน`;
        }
        if (elem === -2) {
          expLabel += `Part-Time`;
        }
      } else {
        expLabel += `${elem} ปี`;
      }
      if (index < exp.length - 1) {
        expLabel += `, `;
      } else {
        expLabel += `) `;
      }
    });
    return expLabel;
  }
  if (exp < 1) {
    if (exp === 0) {
      return `จบใหม่ `;
    }
    if (exp === -1) {
      return `ฝึกงาน `;
    }
    if (exp === -2) {
      return `Part-Time `;
    }
  }
  return `ประสบการณ์ ${exp} ปี `;
};

const createHistorySalary = (salMin, salMax) => {
  if (!salMin || salMax) {
    const salary = salMin || salMax;
    return `${translate('search.salary')} ${number.numberWithComma({input: salary})} `;
  }
  return `${translate('search.salary')} ${number.numberWithComma({input: salMin})} - ${number.numberWithComma({input: salMax})} `;
};

export const checkIsEmptySearchHistory = (history) => {
  if (!history) {
    return true;
  }
  if (typeDef.isNotArrayLength(history?.pin) && typeDef.isNotArrayLength(history?.history)) {
    return true;
  }
  return false;
};

export const transformHistoryLabel = (params) => {
  if (typeDef.isNullOrUndefined(params)) {
    return '';
  }

  let label = '';
  if (typeDef.isArrayLength(params.jobcat)) {
    label += createHistoryJobCat(params.jobcat);
  }
  if (typeDef.isArrayLength(params.subjobcat)) {
    label += createHistorySubJobCat(params.subjobcat);
  }
  if (params.search) {
    label += `${params.search} `;
  }

  if (params.author_name) {
    label += `บริษัท ${params.author_name} `;
  }

  if (params.experience_requirement) {
    label += createHistoryExp(params.experience_requirement);
  }

  if (params.salary_min || params.salary_max) {
    label += createHistorySalary(params.salary_min, params.salary_max);
  }

  if (params.education_requirement) {
    label = label.concat(params.education_requirement, ' ');
  }

  if (params.tag) {
    if (typeDef.isArrayLength(params.tag)) {
      params.tag.forEach((element) => {
        label = label.concat(element, ' ');
      });
    } else {
      label = label.concat(params.tag, ' ');
    }
  }

  if (params.province) {
    label += createProvince(params.province);
  }

  if (params.district) {
    label += createDistrict(params.district);
  }

  return label;
};
