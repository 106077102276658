import store from '../../../redux/store';
import * as alertActions from '../../../redux/alert/action';

export const setAlert = (message) => {
  if (!message) {
    return;
  }
  store.dispatch(alertActions.alertSetAlert({data: {message}}));
};

export const setAlertManualAction = (data = {}) => {
  if (!data) {
    return;
  }
  store.dispatch(alertActions.alertSetAlert({data: {...data}}));
};

export const removeAlert = () => {
  store.dispatch(alertActions.alertRemoveAlert());
};
