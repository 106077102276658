import React from 'react';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../lib/hooks/logic/useAuth';
import MenuSidebar from '../../screens/menu/views/side-bar';
import EditSidebar from '../../screens/edit-profile/views/side-bar';
import * as logic from './logic';

const ComposeSidebar = () => {
  const {pathname} = useLocation();
  const {isCheckedAuth, loggedInState} = useAuth();

  if (!isCheckedAuth || !loggedInState) {
    return null;
  }

  if (logic.matchUrlMenuSidebar(pathname)) {
    return <MenuSidebar />;
  }

  if (logic.matchUrlEditSidebar(pathname)) {
    return <EditSidebar />;
  }
  return null;
};

ComposeSidebar.propTypes = {};

export default ComposeSidebar;
