import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {translate} from '../../../../../res/language/translate';
import {useJobPostContact} from '../../../../../lib/hooks/logic/useJobPost';
import {ShareWrapper, ButtonInteractWrapper, ButtonInteractText, ModalHover, OverrideStyles} from '../styled-component';
import Icon from '../../../../../components/icon';
import Link from '../../../../../components/link';
import ContactItem from './contact-sub';
import Responsive from '../../../../../components/responsive';
import BottomSheets from '../../../../bottom-sheets';
import * as ICONS from '../../../../../components/icon/constants';
import {interactTypes} from '../../../../../lib/hooks-logic/useTrackEvent';
// import ENV from '../../../../../env.json';

const BREAK_POINT = 1024;

const PostContact = (props) => {
  const {id, lineIds, lineText, tels, telText, emails, emailText, authorUrl, jobData, onEventTracking} = props; // authorSrc

  const {dispatchInterestedSeeContact} = useJobPostContact();
  const nodeButtonRef = useRef(null);
  const [isHover, setIsHover] = useState(false);
  const [isShowSheet, setIsShowSheet] = useState(false);
  const isNotShowLinkToOrigin = true; // (authorSrc === 'company' || authorSrc === 'admin') && authorUrl && authorUrl.includes(ENV.PUBLIC.API_URL);

  const onEventDemiseHover = () => {
    setIsHover(false);
    dispatchInterestedSeeContact(id);
  };

  const onEventActiveHover = () => {
    setIsHover(true);
    onEventTracking(interactTypes.contact);
  };

  const onEventHideSheet = () => {
    setIsShowSheet(false);
  };

  const onEventShowSheet = () => {
    setIsShowSheet(true);
    dispatchInterestedSeeContact(id);
    onEventTracking(interactTypes.contact);
  };

  return (
    <>
      <Responsive maxWidth={BREAK_POINT}>
        <BottomSheets noEdgeInsets isOpen={isShowSheet} onRequestClose={() => setIsShowSheet(false)}>
          <ContactItem jobData={jobData} type="tel" contactList={tels} contactText={telText} onEventCallback={onEventHideSheet} />
          <ContactItem jobData={jobData} type="line" contactList={lineIds} contactText={lineText} onEventCallback={onEventHideSheet} />
          <ContactItem jobData={jobData} type="email" contactList={emails} contactText={emailText} onEventCallback={onEventHideSheet} />
          {!isNotShowLinkToOrigin && (
            <Link newTab url={authorUrl}>
              <ContactItem jobData={jobData} type="link" onEventCallback={onEventHideSheet} />
            </Link>
          )}
          <ContactItem type="close" onEventCallback={onEventHideSheet} />
        </BottomSheets>
      </Responsive>
      <ShareWrapper ref={nodeButtonRef} onMouseEnter={onEventActiveHover} onMouseLeave={onEventDemiseHover}>
        {isHover && (
          <ModalHover bottom={nodeButtonRef.current?.clientHeight}>
            <ContactItem jobData={jobData} noBorder type="tel" contactList={tels} contactText={telText} onEventCallback={onEventDemiseHover} />
            <ContactItem jobData={jobData} noBorder type="line" contactList={lineIds} contactText={lineText} onEventCallback={onEventDemiseHover} />
            <ContactItem jobData={jobData} noBorder type="email" contactList={emails} contactText={emailText} onEventCallback={onEventDemiseHover} />
            {!isNotShowLinkToOrigin && (
              <Link newTab url={authorUrl}>
                <ContactItem jobData={jobData} type="link" noBorder onEventCallback={onEventHideSheet} />
              </Link>
            )}
          </ModalHover>
        )}
        <Responsive minWidth={BREAK_POINT + 1}>
          <ButtonInteractWrapper>
            <Icon name={ICONS.CONTACT} size={20} color={OverrideStyles.iconInteract.unActive} />
            <ButtonInteractText>{translate('timeline.contact')}</ButtonInteractText>
          </ButtonInteractWrapper>
        </Responsive>
        <Responsive maxWidth={BREAK_POINT}>
          <ButtonInteractWrapper onClick={onEventShowSheet}>
            <Icon name={ICONS.CONTACT} size={20} color={OverrideStyles.iconInteract.unActive} />
            <ButtonInteractText>{translate('timeline.contact')}</ButtonInteractText>
          </ButtonInteractWrapper>
        </Responsive>
      </ShareWrapper>
    </>
  );
};

PostContact.propTypes = {
  id: PropTypes.string.isRequired,
  lineIds: PropTypes.array,
  lineText: PropTypes.string,
  tels: PropTypes.array,
  telText: PropTypes.string,
  emails: PropTypes.array,
  emailText: PropTypes.string,
  authorUrl: PropTypes.string,
  // authorSrc: PropTypes.string,
  jobData: PropTypes.object.isRequired,
  onEventTracking: PropTypes.func.isRequired,
};

PostContact.defaultProps = {
  lineIds: [],
  lineText: '',
  tels: [],
  telText: '',
  emails: [],
  emailText: '',
  authorUrl: null,
  // authorSrc: '',
};

export default PostContact;
