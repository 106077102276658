import styled from 'styled-components';
import NavigationTheme from '../../styles/navigation';
import LAYOUT from '../../styles/layout';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* height: calc(100vh); */
  /* @media (max-width: 1024px) {
    ${(props) => !props.noTopEdgeInsets && `padding-bottom: 56px;`};
  } */
  @media (min-width: 1025px) {
    ${(props) => !props.noTopEdgeInsets && `padding-top: 56px;`};
  }
`;

export const SingleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  width: 100%;
  @media (max-width: 1024px) {
    ${(props) => props.isChildScreen && `padding-top: 56px; padding-bottom: 56px;`};
  }
`;

export const SingleContent = styled.div`
  display: block;
  width: 100%;
  max-width: 680px;
  padding: 1rem 0;
  @media (max-width: 680px) {
    ${(props) => !props.noHorizontalEdgeInsets && `padding: 1rem;`};
    ${(props) => props.noEdgeInsets && `padding: 0;`};
  }
`;

export const SingleContentFluid = styled.div`
  display: block;
  width: 100%;
  max-width: 960px;
  height: 100%;
  padding: 1.5rem 1rem;
  margin-left: 0.5rem;
  background-color: ${(props) => props.theme.white};
  @media (max-width: 680px) {
    ${(props) => !props.noHorizontalEdgeInsets && `padding: 1.5rem 1rem;`};
  }
  @media (min-width: 1200px) {
    ${(props) => !props.noHorizontalEdgeInsets && `padding: 1.5rem 3rem;`};
  }
  @media (max-width: 1199px) {
    margin-left: 0;
  }
`;

export const DeltaSidebar = styled.div`
  min-width: 360px;
  height: 100%;
  display: none;
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
`;

export const NavWrapper = styled.nav`
  position: fixed;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  padding: 0 1rem;
  box-shadow: ${NavigationTheme.NavigationHeadShadow.boxShadow2};
  background-color: ${(props) => props.theme.white};
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const NavCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 250px) {
    display: none;
  }
`;

export const NavTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  overflow: hidden;
`;

export const NavTitle = styled.span`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${(props) => props.hasSubtitle && `font-size: 14px`};
`;

export const NavSubTitle = styled.span`
  margin-top: -0.2rem;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const DeltaBottomBar = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  background-color: red;
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  @media (max-width: ${LAYOUT.content.twoLayoutMinFluid - 1}px) {
    align-items: center;
  }
  @media (min-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    padding: 1rem;
    max-width: calc((100% - 680px) / 2);
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 1rem;
  background-color: ${(props) => props.theme.white};
  @media (max-width: 1550px) {
    max-width: 330px;
  }
  @media (min-width: 1551px) and (max-width: 1600px) {
    max-width: 380px;
  }
  @media (min-width: 1601px) {
    max-width: 420px;
  }
  @media (min-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    max-height: calc(100vh - 130px);
    position: fixed;
  }
  @media (max-width: ${LAYOUT.content.twoLayoutMinFluid - 1}px) {
    max-width: 680px;
    align-items: center;
  }
  @media (min-width: 681px) {
    padding: 1rem;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
  }
  @media (max-width: 680px) {
    margin-bottom: -8px;
  }
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  align-items: center;

  @media (min-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    ${(props) => !props.singleCol && !props.timeline && `align-items: flex-start`};
  }
  @media (max-width: 1024px) {
    ${(props) => !props.noTopEdgeInsets && `padding-bottom: 56px;`};
    ${(props) => props.noTopEdgeInsets && `padding-bottom: 0; margin-bottom: -1rem;`};
  }
`;

export const CenterContent = styled.div`
  @media (max-width: 679px) {
    width: 100vw;
  }
  @media (min-width: 680px) {
    width: 680px;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0 1rem;
  @media (max-width: ${LAYOUT.content.twoLayoutMinFluid - 1}px) {
    align-items: center;
  }
  @media (min-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    padding: 1rem;
    max-width: calc((100% - 680px) / 2);
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  max-width: 350px;
  padding: 1rem;
  /* background-color: ${(props) => props.theme.white}; */
  background-color: ${(props) => props.theme.transparent};
  @media (min-width: 681px) {
    /* border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px; */
  }
`;

export const RightCenterContent = styled.div`
  margin-bottom: 1rem;
  @media (max-width: 679px) {
    margin-bottom: 0;
  }
  @media (min-width: 680px) {
    margin-top: -1rem;
  }
`;

export const LeftLargeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  @media (max-width: ${LAYOUT.content.twoLayoutMinFluid - 1}px) {
    display: none;
  }
  @media (max-width: ${LAYOUT.content.twoLayoutMinFluid - 1}px) {
    align-items: center;
  }
  @media (min-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    padding: 1rem;
    max-width: calc((100% - 680px) / 2);
  }
`;

/**
 * TWO COLUMN LAYOUT
 */
export const WrapperTwoCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TwoLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  @media (max-width: 680px) {
    margin-top: 2rem;
    ${(props) => !props.noTopEdgeInsets && `padding: 56px 0 0 0;`};
  }
  @media (min-width: 681px) and (max-width: 1024px) {
    margin-top: 3rem;
    ${(props) => !props.noTopEdgeInsets && `padding: 56px 0 56px 0;`};
  }
  @media (min-width: 1025px) and (max-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    ${(props) => !props.noTopEdgeInsets && `margin-top: 6.75rem;`};
    ${(props) => props.noTopEdgeInsets && `margin-top: 3rem`};
  }
  @media (min-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    ${(props) => !props.noTopEdgeInsets && `margin-top: 3.75rem;`};
  }
  ${(props) => props.noFilterBar && `margin-top: 0px`};
`;

export const WrapperOneCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OneLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  @media (max-width: 680px) {
    ${(props) => !props.noTopEdgeInsets && `padding: 56px 0 0 0;`};
    margin-top: -1rem;
  }
  @media (min-width: 681px) and (max-width: 1024px) {
    ${(props) => !props.noTopEdgeInsets && `padding: 56px 0 56px 0;`};
  }
  @media (min-width: 1025px) and (max-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    ${(props) => !props.noTopEdgeInsets && `margin-top: 3.75rem;`};
  }
  @media (min-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    ${(props) => !props.noTopEdgeInsets && `margin-top: 3.75rem;`};
  }
`;

/** TIMELINE */

export const WrapperTimeline = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1025px) {
    ${(props) => !props.noTopEdgeInsets && `padding-top: 56px;`};
  }
`;

export const TimelineLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  @media (max-width: 680px) {
    padding: 56px 0 0 0;
  }
  @media (min-width: 681px) and (max-width: 1024px) {
    padding: 72px 0 0 0;
  }
  @media (min-width: 1025px) and (max-width: ${LAYOUT.content.twoLayoutMinFluid}px) {
    padding: 1rem 0 0 0;
  }
`;

export const OverrideStyles = {
  row: {
    height: '100%',
  },
  leftArrow: {
    marginLeft: '-0.5rem',
  },
};
