import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  color: ${(props) => props.theme.primary};
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-family: 'Prompt light';
  font-size: 16px;
  cursor: pointer;
`;
