import {createServiceURL} from '../../env/utils/env-services';
import {adapterPost} from '../../utils/adapter/post';

export const likeNewLike = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/like/like_job`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const likeUnlike = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/like/unlike_job`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};
