import {createServiceURL} from '../../env/utils/env-services';
import {adapterPost} from '../../utils/adapter/post';
import {adapterGet} from '../../utils/adapter/get';

export const notifyGetSettingNotification = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/setup/information_notification`;
  const reqBody = {platform: 'website'};
  const response = await adapterGet({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const notifyUpdateNotification = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/setup/setup_notification`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const notifyGetCountNewNotification = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/notification/count_new_notis`;
  const reqBody = {platform: 'website'};
  const response = await adapterGet({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const notifyGetListNotification = async ({pageSize, pageIndex}) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/notification/all_notis?page_size=${pageSize}&page_index=${pageIndex}`;
  const response = await adapterGet({url: API_URL, privateMethod: true});
  return response;
};

export const notifyUpdateNotificationSetting = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/notification/toggle_noti`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const notifyUpdateInterestedJob = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/notification/save_recommend_params`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const notifyGetDataBySignedUrl = async ({token, userId}) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/notification/manage_user_notification/${token}/${userId}`;
  const response = await adapterGet({url: API_URL, privateMethod: true});
  return response;
};

export const notifyUpdateDataBySignedJob = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/notification/save_managed_user_notification`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};
