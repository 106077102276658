import * as TYPES from './type';

export const alertReducer = (state = {}, {type, payload = {}}) => {
  switch (type) {
    case TYPES.ALERT_SET_ALERT_REQ:
      return state;
    case TYPES.ALERT_SET_ALERT_SUCCESS:
      return {...payload};
    case TYPES.ALERT_SET_ALERT_FAIL:
    case TYPES.ALERT_REMOVE_ALERT_REQ:
    case TYPES.ALERT_REMOVE_ALERT_SUCCESS:
      return {};
    case TYPES.ALERT_REMOVE_ALERT_FAIL:
    default:
      return state;
  }
};
