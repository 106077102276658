import React, {useState, useEffect} from 'react';
import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import {SliderWrapper, CardWrapper, CardImage, CardTitle, SlickPageWrapper, SlickRowWrapper, SliderCounter} from './styled-component';
import Slider from '../../components/slider';
import * as logic from './logic';
import * as typeDef from '../../utils/strong-types';
import * as navigationServices from '../../utils/navigation/navigation-services';
import * as routes from '../../routes/routes-name';
import * as mixpanel from '../../utils/mixpanel';
import CardTheme from '../../styles/card';

const CARD_GAP = 16;
const ITEM_PER_COLUMN = 2;

const SlickCarousel = (props) => {
  const {data} = props;
  const [renderRowState, setRenderRowState] = useState(1);
  const [renderDataState, setRenderDataState] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [wrapStyle, setWrapStyle] = useState();
  const [sliderDotsState, setSliderDotsState] = useState(true);
  const [clientMouseEvent, setClientMouseEvent] = useState({
    clientXonMouseDown: null,
    clientYonMouseDown: null,
  });

  useEffect(() => {
    const initRow = logic.calRow({contextWidth: window.innerWidth});
    if (renderRowState !== initRow) {
      setRenderRowState(initRow);
    }
    function onSizeChange() {
      const newRow = logic.calRow({contextWidth: window.innerWidth});
      if (renderRowState !== newRow) {
        setRenderRowState(newRow);
      }
    }
    window.addEventListener('resize', onSizeChange);
    return () => window.removeEventListener('resize', onSizeChange);
  }, [renderRowState]);

  useEffect(() => {
    if (typeDef.isArrayLength(data)) {
      const chunkData = chunk(data, renderRowState * ITEM_PER_COLUMN);
      setRenderDataState(chunkData);
    }
  }, [renderRowState, data]);

  useEffect(() => {
    const initWidth = CardTheme.Size.width * renderRowState;
    const spaceWidth = CARD_GAP * (renderRowState - 1);
    const wrapWidth = initWidth + spaceWidth;
    setWrapStyle({width: wrapWidth});
  }, [renderRowState]);

  useEffect(() => {
    if (renderRowState <= 2) {
      setSliderDotsState(false);
    } else {
      setSliderDotsState(true);
    }
  }, [renderRowState]);

  const onClickCard = (e, item) => {
    e.stopPropagation();
    if (clientMouseEvent.clientXonMouseDown !== e.clientX || clientMouseEvent.clientYonMouseDown !== e.clientY) {
      e.preventDefault();
      return;
    }
    if (!item) {
      return;
    }
    mixpanel.homeHighlightLanding(false, `${routes.ROUTE_LANDING_GROUP_RAW}${item.url_name}`, window.location.pathname);
    navigationServices.navigateTo({
      pathname: `${routes.ROUTE_LANDING_GROUP_RAW}${item.url_name}`,
    });
  };

  const onEventSwipe = (e) => {
    setClientMouseEvent({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY,
    });
    e.preventDefault();
  };

  if (typeDef.isNotArrayLength(renderDataState) || !wrapStyle) {
    return null;
  }

  return (
    <SliderWrapper width={wrapStyle.width}>
      <Slider afterChange={(snapIndex) => setCurrentIndex(snapIndex)} settings={{dots: sliderDotsState}}>
        {renderDataState.map((items, index) => {
          return (
            <SlickPageWrapper key={index.toString()} width={wrapStyle.width}>
              <SlickRowWrapper width={wrapStyle.width} edgeInsetsRight={index < renderDataState.length - 1}>
                {items.map((item, childIndex) => {
                  return (
                    <CardWrapper
                      key={item._id}
                      edgeInsetsRight={logic.getIsEdgeInsets({totalRow: renderRowState, index: childIndex})}
                      onClick={(e) => onClickCard(e, item)}
                      onMouseDown={(e) => onEventSwipe(e)}>
                      <CardImage src={item.cover_photo_sm?.url || item.cover_photo?.url} />
                      <CardTitle>{item.th_name}</CardTitle>
                    </CardWrapper>
                  );
                })}
              </SlickRowWrapper>
            </SlickPageWrapper>
          );
        })}
      </Slider>
      {!sliderDotsState && (
        <SliderCounter>
          {currentIndex + 1}/{renderDataState.length}
        </SliderCounter>
      )}
    </SliderWrapper>
  );
};

SlickCarousel.propTypes = {
  data: PropTypes.array,
};

SlickCarousel.defaultProps = {
  data: null,
};

export default SlickCarousel;
