import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import SectionHeader from '../header';
import {calSkill} from '../resume-info/logic';
import * as ICONS from '../../../../components/icon/constants';
import * as ROUTES from '../../../../routes/routes-name';

const SkillAndAchievementInfo = (props) => {
  const {userState} = props;
  const {formatMessage} = useIntl();

  if (!userState) {
    return null;
  }

  return (
    <>
      <SectionHeader
        route={ROUTES.ROUTE_EDIT_PROFILE_SKILL}
        iconName={ICONS.ACHIEVEMENT}
        title={formatMessage({defaultMessage: 'ทักษะและความสำเร็จ'})}
        score={calSkill(userState)}
        total={15}
      />
    </>
  );
};

SkillAndAchievementInfo.propTypes = {
  userState: PropTypes.object,
};

SkillAndAchievementInfo.defaultProps = {
  userState: null,
};

export default SkillAndAchievementInfo;
