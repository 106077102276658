import {combineReducers} from 'redux';
import {authReducer, forgotPasswordReducer, facebookLoginReducer} from './auth/reducer';
import {
  registerIdReducer,
  registerStep1Reducer,
  registerStep2Reducer,
  registerVisibleStepReducer,
  registerOTPReducer,
  registerInterestReducer,
} from './register/reducer';
import {alertReducer} from './alert/reducer';
import {snackbarReducer} from './snackbar/reducer';
import {
  locationReducer,
  areaReducer,
  offsetReducer,
  filterReducer,
  dimensionsReducer,
  mainScrollConfigReducer,
  eventListenerConfigReducer,
  contactShowReducer,
  imageViewerReducer,
  stackDistrictsReducer,
  bottomLoginReducer,
  callbackPathReducer,
  reportPostReducer,
  rewritePostReducer,
} from './context/reducer';
import {
  locationNearestReducer,
  jobCatReducer,
  jobTimelineReducer,
  jobLikedReducer,
  jobSearchHistoryReducer,
  jobQueryReducer,
  jobHashtagReducer,
  jobIdReducer,
  jobSeenReducer,
  jobHiddenJobReducer,
  jobAuthorReducer,
  jobGroupReducer,
  jobGroupListReducer,
} from './search/reducer';
import {userInformationReducer, userManualUploadResumeReducer} from './user/reducer';
import {notifyListReducer, notifySettingsReducer, manageNotifyReducer} from './notify/reducer';
import {likeReducer} from './like/reducer';
import {checkInfoReducer, draftEmailReducer, modalFormReducer, resumeReducer} from './apply-job/reducer';
import {hotCompanyReducer} from './hot-company/reducer';
import {listApplyJobReducer} from './list-apply-job/reducer';
import {listApplyJob2Reducer, applyJobSendMailReducer} from './apply-job2/reducer';
import {companyDetailReducer} from './company-profile/reducer';
import {jobIndexReducer, companyIndexReducer} from './seo-index/reducer';
import {trackJobInteractReducer} from './track-event/reducer';
import {reportJobReducer} from './report-job/reducer';

export default combineReducers({
  snackbar: snackbarReducer,
  alert: alertReducer,
  auth: combineReducers({
    auth: authReducer,
    forgotPassword: forgotPasswordReducer,
    facebook: facebookLoginReducer,
  }),
  register: combineReducers({
    registerId: registerIdReducer,
    step1: registerStep1Reducer,
    step2: registerStep2Reducer,
    interest: registerInterestReducer,
    visibleStep: registerVisibleStepReducer,
    otp: registerOTPReducer,
  }),
  context: combineReducers({
    location: locationReducer,
    area: areaReducer,
    offset: offsetReducer,
    filter: filterReducer,
    dimensions: dimensionsReducer,
    mainScrollConfig: mainScrollConfigReducer,
    eventListener: eventListenerConfigReducer,
    contacts: contactShowReducer,
    imageViewer: imageViewerReducer,
    stackDistrict: stackDistrictsReducer,
    bottomLogin: bottomLoginReducer,
    callbackPath: callbackPathReducer,
    reportPost: reportPostReducer,
    rewrite: rewritePostReducer,
  }),
  search: combineReducers({
    locationNearest: locationNearestReducer,
    history: jobSearchHistoryReducer,
    jobCat: jobCatReducer,
    jobTimeline: jobTimelineReducer,
    jobLiked: jobLikedReducer,
    jobQuery: jobQueryReducer,
    jobHashtag: jobHashtagReducer,
    jobId: jobIdReducer,
    jobInterestAndSeen: jobSeenReducer,
    jobHidden: jobHiddenJobReducer,
    jobAuthor: jobAuthorReducer,
    jobGroup: jobGroupReducer,
    jobGroupList: jobGroupListReducer,
  }),
  likes: combineReducers({
    likes: likeReducer,
  }),
  notify: combineReducers({
    notifyList: notifyListReducer,
    settings: notifySettingsReducer,
    manage: manageNotifyReducer,
  }),
  user: combineReducers({
    information: userInformationReducer,
    resume: userManualUploadResumeReducer,
  }),
  applyJob: combineReducers({
    checkInfo: checkInfoReducer,
    draftEmail: draftEmailReducer,
    modalForm: modalFormReducer,
    resume: resumeReducer,
  }),
  hotCompany: hotCompanyReducer,
  listApplyJob: listApplyJobReducer,
  applyJob2: listApplyJob2Reducer,
  applyJobNew: combineReducers({
    sendMail: applyJobSendMailReducer,
  }),
  company: combineReducers({detail: companyDetailReducer}),
  seoIndex: combineReducers({
    jobIndex: jobIndexReducer,
    companyIndex: companyIndexReducer,
  }),
  trackEvent: combineReducers({
    jobInteract: trackJobInteractReducer,
  }),
  reportJob: combineReducers({
    delete: reportJobReducer,
  }),
});
