const thGlobal = require('./global.json');
const thAuth = require('./auth.json');
const thRegister = require('./register.json');
const thOtp = require('./otp.json');
const thUser = require('./user.json');
const thSearch = require('./search.json');

const composeResource = {
  ...thGlobal,
  ...thAuth,
  ...thRegister,
  ...thOtp,
  ...thUser,
  ...thSearch,
};

export default composeResource;
