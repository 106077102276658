export const initFormInfo = {
  firstName: '',
  lastName: '',
  birthDate: '',
  birthMonth: '',
  birthYear: '',
  email: '',
  resume: '',
  sex: '',
  location: '',
};
