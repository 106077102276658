import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {JobText, Container, JobTextHyperLink, JobTextHyperLinkWrapper} from './styled-component';
import Link from '../../../../components/link';
import {renderWithBullet} from '../../utils/shuffle';
import {getShortLink} from '../../utils/short-link';
import * as bodyRewrite from '../../utils/body-rewrite';
import * as bodyParser from '../../utils/body-parser';

const BodyRewrite = (props) => {
  const {children, source, head, count} = props;
  const [renderTextState, setRenderTextState] = useState('');

  useEffect(() => {
    if (children) {
      if (typeof children === 'string' && source !== 'company' && source !== 'owned_facebook') {
        let renderText = children;
        if (!children.includes(bodyParser.MAGIC_CHAR)) {
          renderText = bodyRewrite.splitBody(children, head, count, source);
        } else {
          renderText = children.replace(bodyParser.MAGIC_CHAR, '').split('\n');
        }
        setRenderTextState(renderText);
        return;
      }
      setRenderTextState(children);
    }
  }, [children, source]);

  if (!Array.isArray(renderTextState) || source === 'company' || source === 'owned_facebook') {
    return <JobText>{children}</JobText>;
  }

  return (
    <Container>
      {renderTextState.map((item, index) => {
        const isHyperLink = bodyRewrite.isHyperLinkTag(item);
        if (isHyperLink) {
          const renderLink = bodyRewrite.splitHyperLinkTag(renderWithBullet(item));
          return (
            <JobTextHyperLinkWrapper key={index.toString()}>
              {Array.isArray(renderLink) &&
                renderLink.map((text, textIndex) => {
                  const isTextLink = bodyRewrite.isHyperLinkTag(text);
                  if (isTextLink) {
                    return (
                      <Link key={textIndex.toString()} newTab url={text}>
                        <JobTextHyperLink>{getShortLink(text)}</JobTextHyperLink>
                      </Link>
                    );
                  }
                  return <JobText key={textIndex.toString()}>{text}</JobText>;
                })}
            </JobTextHyperLinkWrapper>
          );
        }
        return (
          <JobText key={index.toString()} block>
            {renderWithBullet(item, source)}
          </JobText>
        );
      })}
    </Container>
  );
};

BodyRewrite.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  source: PropTypes.string.isRequired,
  head: PropTypes.string,
  count: PropTypes.number.isRequired,
};

BodyRewrite.defaultProps = {
  head: '',
};

export default BodyRewrite;
