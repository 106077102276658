import axios from 'axios';
import qs from 'querystring';
import * as header from './header';

export const adapterGet = async ({url, body, privateMethod, fixedPath}) => {
  const headerProvider = header.headers(privateMethod ? header.PRIVATE_GET : header.GET);
  if (body) {
    let stringifyBody = null;
    if (typeof body === 'string') {
      stringifyBody = body;
    } else {
      stringifyBody = qs.stringify(body);
    }
    if (fixedPath) {
      const response = await axios.get(`${url}${stringifyBody}`, headerProvider);
      return response;
    }
    const response = await axios.get(`${url}?${stringifyBody}`, headerProvider);
    return response;
  }
  const response = await axios.get(`${url}`, headerProvider);
  return response;
};

export const adapterGetWithParams = async ({url, body, privateMethod}) => {
  const headerProvider = header.headers(privateMethod ? header.PRIVATE_GET : header.GET);
  const response = await axios.get(url, {params: body, headers: headerProvider});
  return response;
};
