import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const SelectWrapper = styled.select`
  height: 44px;
  padding: 0 0.5rem;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.tinGray};
  margin: 0.25rem 0;
  font-size: 16px;
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.tinGray};
  }
`;

export const OptionsWrapper = styled.option`
  font-size: 16px;
  font-family: 'Prompt Light';
`;

export const MultipleContainer = styled.div`
  position: relative;
  margin-bottom: 0.75rem;
`;

export const MultipleBox = styled.div`
  min-height: 44px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  border-radius: 6px;
  padding: 0 0.25rem;
  border: 1px solid ${(props) => props.theme.tinGray};
  ${(props) => props.isFocus && `border: 1px solid ${props.theme.primary}`}
  ${(props) => props.isEmpty && `background-color: ${props.theme.grayBackground}; border: none;`};
  ${(props) => props.warning && `border: 1px solid ${props.theme.danger}; background-color: ${props.theme.white}`};
`;

export const InputWrapper = styled.input`
  display: inline-block;
  width: 1px;
  height: 1px;
  border: none;
  opacity: 0;
`;
export const SelectBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const RightWrapper = styled.div`
  display: flex;
  width: 45px;
  align-items: center;
`;

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
`;

export const VerticalLine = styled.span`
  margin: 0.25rem 0.35rem;
  width: 1px;
  align-self: stretch;
  background: ${(props) => props.theme.tinGray};
`;

export const OptionAbsolute = styled.div`
  z-index: 99;
  position: absolute;
  right: ${(props) => (!props.noArrow ? '40px' : '0')};
  left: 0;
  overflow: auto;
  max-height: 300px;
  background-color: white;
  border-radius: 6px;
  padding: 0.25rem 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 1rem;
  ${(props) => props.space && `padding: 1rem`};
`;

export const OptionAbsoluteItem = styled.div`
  padding: 0.5rem 1rem;
  font-family: 'Prompt Light';
  &:hover {
    cursor: default;
    background-color: ${(props) => props.theme.lightHover100};
  }
`;

export const ChipItem = styled.div`
  display: flex;
  min-height: 34px;
  align-items: center;
  padding: 0 0.5rem;
  margin: 0.25rem 0.5rem 0.25rem 0;
  border-radius: 4px;
  background-color: ${(props) => props.theme.lightHover100};
`;

export const ChipItemText = styled.span`
  margin-right: 0.5rem;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    cursor: default;
  }
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
`;

export const PlaceholderText = styled.span`
  color: ${(props) => props.theme.gray};
  &:hover {
    cursor: default;
  }
`;

export const SelectedItemWrapper = styled.div`
  display: flex;
  padding: 0 0.75rem;
  align-items: center;
`;

export const SelectedItemText = styled.span`
  margin-right: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const InputHint = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.gray70};
  ${(props) => props.warning && `color: ${props.theme.danger}`}
`;

export const OverrideStyles = {
  iconCloseColor: lightTheme.danger,
  option: {
    marginBottom: '1rem',
  },
};
