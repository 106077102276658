import React from 'react';
import {translate} from '../../../res/language/translate';
import {useFormSearchState, useFormSearchDispatch} from '../../../lib/hooks/logic/useForm';
import Input from '../../../components/input';
import * as ICONS from '../../../components/icon/constants';

const KeyForm = () => {
  const {formSearchState} = useFormSearchState();
  const {dispatchUpdateForm} = useFormSearchDispatch();

  const onChangeText = (key, value) => {
    if (!key) {
      return;
    }
    const copyForm = {...formSearchState};
    copyForm[key] = value;
    dispatchUpdateForm(copyForm);
  };

  return (
    <>
      <Input
        borderOutline
        iconName={ICONS.SEARCH}
        placeholder={translate('search.placeholderJob')}
        value={formSearchState.keyJob || ''}
        onChange={(val) => onChangeText('keyJob', val)}
      />
      <Input
        borderOutline
        iconName={ICONS.SEARCH}
        value={formSearchState.keyCompany || ''}
        placeholder={translate('search.placeholderCompany')}
        onChange={(val) => onChangeText('keyCompany', val)}
      />
    </>
  );
};

KeyForm.propTypes = {};

export default KeyForm;
