import React from 'react';
import PropTypes from 'prop-types';
import {Container} from './styled-component';

const Center = (props) => {
  const {children, style} = props;
  return <Container style={style}>{children}</Container>;
};

Center.propTypes = {
  children: PropTypes.any,
  style: PropTypes.any,
};

Center.defaultProps = {
  children: null,
  style: null,
};

export default Center;
