import React from 'react';
import PropTypes from 'prop-types';
import IconGenerator from './generator';
import IconTheme from '../../styles/icon';
import {IconWrapper} from './styled-component';

const Icon = (props) => {
  const {style} = props;
  return (
    <IconWrapper style={style}>
      <IconGenerator {...props} />
    </IconWrapper>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.any,
};

Icon.defaultProps = {
  size: IconTheme.size.webDefault,
  color: '',
  style: null,
};

export default Icon;
