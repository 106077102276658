import React, {useState} from 'react';
import {Container, RowWrapper, ItemWrapper, SearchWrapper, OverrideStyle, MainSvgWrapper} from './styled-component';
import SVGImage from '../../../components/svg-img';
import SvgMainSearch from '../../../res/assets/images/svg/Main.svg';
import Input from '../../../components/input';
import Header from '../../../components/header';
import Select from '../../../components/select';
import SelectLocation from '../../../views/select-location';
import Form from '../../../components/form';
import Button from '../../../components/button';
import {translate} from '../../../res/language/translate';
import * as coreJobCat from '../../../lib/context/job-cat';
import * as coreLocation from '../../../lib/context/location';
import * as coreSearch from '../../../lib/context/search';
import * as ICON from '../../../components/icon/constants';
import * as ROUTES from '../../../routes/routes-name';
import * as navigation from '../../../utils/navigation/navigation-services';
import * as mixpanel from '../../../utils/mixpanel';

const BoxSearch = () => {
  const [keySearchState, setKeySearchState] = useState('');
  const [keyCompanyState, setKeyCompanyState] = useState('');
  const [jobCatsData] = useState(coreJobCat.createJobCatData());
  const [selectedJobCats, setSelectedJobCats] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);

  const onChangeSelectJobCategory = (parentItem, childItem) => {
    const mapSelected = coreJobCat.mapSelectedByTypes({
      selectedOptions: selectedJobCats,
      newItems: {
        parentItem,
        childItem,
      },
    });
    if (mapSelected.length > coreJobCat.LIMIT_JOB_CAT) {
      return;
    }
    setSelectedJobCats(mapSelected);
  };

  const onChangeSelectLocation = (parentItem, childItem) => {
    const newLocations = coreLocation.mapSelectedLocation({
      selectedOptions: selectedLocation,
      newItems: {
        parentItem,
        childItem,
      },
    });
    setSelectedLocation(newLocations);
  };

  const shapeTextJobCatFunc = (text, item) => {
    if (item.parentId) {
      return text;
    }
    const shapeText = coreJobCat.shapeTextParentCategory({text});
    return shapeText;
  };

  const onClickedChip = (id) => {
    const newItems = coreJobCat.filterOutById({id, selectedOptions: selectedJobCats});
    setSelectedJobCats([...newItems]);
  };

  const onSubmit = (formValue) => {
    mixpanel.searchClickHome(formValue);
    const params = coreSearch.transformSearchMiniForm(formValue);
    navigation.navigateTo({pathname: ROUTES.ROUTE_SEARCH_RESULTS, search: params});
  };

  return (
    <Container>
      <RowWrapper reverse>
        <ItemWrapper floatStick="flex-end">
          <SearchWrapper>
            <Header variant="h1">{translate('landing.searchTitle')}</Header>
            <Header variant="h5" style={OverrideStyle.searchDescription}>
              {translate('landing.searchDescription')}
            </Header>
            <Form formId="main-search" onSubmit={onSubmit}>
              <Input
                name="keySearch"
                iconName={ICON.SEARCH}
                placeholder={translate('search.placeholderJob')}
                value={keySearchState}
                onChange={(val) => setKeySearchState(val)}
                tooltipText={translate('search.toolTipsPosition')}
                options={{focusWhite: true}}
              />
              <Input
                name="keyCompany"
                iconName={ICON.SEARCH}
                placeholder={translate('search.placeholderCompany')}
                value={keyCompanyState}
                onChange={(val) => setKeyCompanyState(val)}
                options={{focusWhite: true}}
              />
              <SelectLocation
                label={translate('search.location')}
                placeholder={translate('landing.search.select')}
                selectedOptions={selectedLocation}
                onChange={onChangeSelectLocation}
              />
              <Select
                name="jobCat"
                label={translate('landing.search.jobCategory')}
                placeholder={translate('landing.search.select')}
                limitSelect={10}
                options={jobCatsData}
                selectedOptions={selectedJobCats}
                shapeTextFunc={shapeTextJobCatFunc}
                onClickChip={onClickedChip}
                onChange={onChangeSelectJobCategory}
                onClear={() => setSelectedJobCats([])}
              />
              <Button block type="submit" style={OverrideStyle.searchButton}>
                {translate('landing.search.buttonSearch')}
              </Button>
            </Form>
          </SearchWrapper>
        </ItemWrapper>
        <ItemWrapper floatStick="flex-start" onlyWidth>
          <MainSvgWrapper>
            <SVGImage autoScale src={SvgMainSearch} maxHeight={600} />
          </MainSvgWrapper>
        </ItemWrapper>
      </RowWrapper>
    </Container>
  );
};

BoxSearch.propTypes = {};

export default BoxSearch;
