import * as TYPES from './type';

export const snackbarSetMessage = (payload) => ({
  type: TYPES.SNACKBAR_SET_MESSAGE_REQ,
  payload: payload,
});

export const snackbarRemoveMessage = (payload) => ({
  type: TYPES.SNACKBAR_REMOVE_MESSAGE_REQ,
  payload: payload,
});

export const snackbarClearMessage = () => ({
  type: TYPES.SNACKBAR_CLEAR_MESSAGE_REQ,
});
