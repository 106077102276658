import React from 'react';
import {CoverImg, CoverDummy} from './styled-component';
import {useLandingGroup} from '../../../lib/hooks/logic/useLandingGroup';

// const testSource =
//   'https://images.unsplash.com/photo-1530216007945-9a593c139ae4?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTczfHxzZHxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60';

const ImageCover = () => {
  const {metaGroupState, isLoadingState} = useLandingGroup();
  if (isLoadingState || !metaGroupState?.cover_photo?.url) {
    return <CoverDummy />;
  }
  return <CoverImg src={metaGroupState?.cover_photo?.url} />;
};

ImageCover.propTypes = {};

export default ImageCover;
