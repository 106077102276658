/* eslint-disable react/no-unused-prop-types */
import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useClickOutsideEffect} from '../../../../lib/hooks/useClickOutSideEffect';
import {AutoCompleteWrapper, OptionAbsolute, OptionAbsoluteItem} from './styled-component';
import Input from '../../../../components/input';
import JSONUniversity from '../../../../res/json/university.json';
import * as typeDef from '../../../../utils/strong-types';

const SchoolAutoComplete = (props) => {
  const {onChange} = props;

  const nodeViewAbsoluteRef = useRef(null);
  const [universityData] = useState([...JSONUniversity]);
  const [autoListState, setAutoListState] = useState([]);
  const [isVisibleState, setIsVisibleState] = useState(false);

  const onEventChangeText = (text) => {
    onChange(text);
    if (text) {
      const results = universityData.filter((elem) => elem.includes(text));

      setAutoListState(results);
      if (!isVisibleState) {
        setIsVisibleState(true);
      }
    }
    if (!text) {
      setAutoListState([]);
      setIsVisibleState(false);
    }
  };

  const onEventSelectedItem = (item) => {
    onChange(item);
    setIsVisibleState(false);
  };

  useClickOutsideEffect(nodeViewAbsoluteRef, () => setIsVisibleState(false));

  return (
    <AutoCompleteWrapper ref={nodeViewAbsoluteRef}>
      <Input {...props} onChange={(key) => onEventChangeText(key)} />
      {isVisibleState && typeDef.isArrayLength(autoListState) && (
        <OptionAbsolute space={typeDef.isNotArrayLength(autoListState)}>
          {autoListState.map((item, index) => (
            <OptionAbsoluteItem key={index.toString()} onClick={() => onEventSelectedItem(item)} noBottom={index === autoListState.length - 1}>
              {item}
            </OptionAbsoluteItem>
          ))}
        </OptionAbsolute>
      )}
    </AutoCompleteWrapper>
  );
};

SchoolAutoComplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  iconName: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  warningText: PropTypes.string,
  tooltipText: PropTypes.string,
  borderOutline: PropTypes.bool,
  fillBackground: PropTypes.bool,
  onChange: PropTypes.func,
  containerStyle: PropTypes.any,
  onKeydown: PropTypes.func,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  inputStyle: PropTypes.any,
};

SchoolAutoComplete.defaultProps = {
  id: '',
  type: 'text',
  name: '',
  iconName: '',
  value: '',
  placeholder: '',
  hint: '',
  label: '',
  warningText: '',
  onChange: null,
  borderOutline: false,
  fillBackground: false,
  tooltipText: '',
  containerStyle: null,
  onKeydown: () => {},
  disabled: false,
  inputProps: {},
  inputStyle: null,
};
export default SchoolAutoComplete;
