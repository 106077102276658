import * as typeDef from '../../../../utils/strong-types';

const SCORE_6 = 6;
const SCORE_5 = 5;
const SCORE_4 = 4;
const SCORE_3 = 3;
const SCORE_2 = 2;
const SCORE_1 = 1;

export const calGeneralInfo = (user) => {
  const FREE_SCORE = 3;
  let ans = FREE_SCORE;
  if (user.general?.firstname && user.general?.lastname) {
    ans += SCORE_3;
  }
  if (user.general?.sex) {
    ans += SCORE_3;
  }
  if (user.general?.birthday) {
    ans += SCORE_3;
  }
  if (user.contactable_tel) {
    ans += SCORE_3;
  }
  if (user.general?.marital_status) {
    ans += SCORE_1;
  }
  if (user.disability) {
    ans += SCORE_1;
  }
  if (user.location?.province) {
    ans += SCORE_1;
  }
  if (user.location?.district) {
    ans += SCORE_1;
  }
  if (user.location?.address) {
    ans += SCORE_1;
  }
  return ans;
};

export const calInterestInfo = (user) => {
  let ans = 0;
  if (typeDef.isArrayLength(user.job_interest?.jobcat) || typeDef.isArrayLength(user.job_interest?.subjobcat)) {
    ans += SCORE_5;
  }
  if (typeDef.isArrayLength(user.interesting_positions)) {
    ans += SCORE_5;
  }
  if (typeDef.isArrayLength(user.job_level)) {
    ans += SCORE_1;
  }
  if (typeDef.isArrayLength(user.job_type_interest)) {
    ans += SCORE_1;
  }
  if (typeDef.isNumber(user.salary_min) && user.salary_min > 0) {
    ans += SCORE_2;
  }
  if (typeDef.isNumber(user.salary_max) && user.salary_max > 0) {
    ans += SCORE_2;
  }
  if (user.available_start_date) {
    ans += SCORE_2;
  }
  if (typeDef.isNumber(user.job_experience)) {
    ans += SCORE_1;
  }
  if (user.desire_work_province) {
    ans += SCORE_1;
  }
  return ans;
};

export const calWorkInfo = (user) => {
  let ans = 0;
  if (typeDef.isNotArrayLength(user.works)) {
    return ans;
  }
  const itemWork = user.works[0];
  if (itemWork.company_name) {
    ans += SCORE_5;
  }
  if (itemWork.company_size) {
    ans += SCORE_1;
  }
  if (itemWork.position) {
    ans += SCORE_5;
  }
  if (typeDef.isNumber(itemWork.salary) && itemWork.salary > 0) {
    ans += SCORE_2;
  }
  if (itemWork.job_description) {
    ans += SCORE_4;
  }
  if (itemWork.address && itemWork.province && itemWork.district) {
    ans += SCORE_1;
  }
  if (itemWork.start && (itemWork.end || itemWork.is_now)) {
    ans += SCORE_2;
  }
  return ans;
};

export const calEducationInfo = (user) => {
  let ans = 0;
  if (typeDef.isNotArrayLength(user.educations)) {
    return ans;
  }
  const item = user.educations[0];
  if (item.level) {
    ans += SCORE_6;
  }
  if (item.school) {
    ans += SCORE_3;
  }
  if (item.year) {
    ans += SCORE_2;
  }
  if (item.grade) {
    ans += SCORE_3;
  }
  if (item.level === 'ม.3' || item.level === 'ม.6') {
    ans += SCORE_6;
  }
  if (item.level === 'ปวช.' || item.level === 'ปวส.') {
    ans += SCORE_3;
    if (item.branch) {
      ans += SCORE_3;
    }
  }
  if (item.level === 'ป.ตรี' || item.level === 'ป.โท' || item.level === 'ป.เอก') {
    if (item.branch) {
      ans += SCORE_3;
    }
    if (item.faculty) {
      ans += SCORE_3;
    }
  }
  return ans;
};

export const calSkill = (user) => {
  let ans = 0;
  if (typeDef.isArrayLength(user.language)) {
    ans += SCORE_4;
  }
  if (typeDef.isArrayLength(user.language_certification)) {
    ans += SCORE_3;
  }
  if (typeDef.isArrayLength(user.skill)) {
    ans += SCORE_4;
  }
  if (typeDef.isArrayLength(user.achievement)) {
    ans += SCORE_4;
  }
  return ans;
};

export const calTrainingCourse = (user) => {
  let ans = 0;
  if (typeDef.isArrayLength(user.training_courses)) {
    ans += SCORE_5;
  }
  return ans;
};

export const calculateStar = (user) => {
  if (!user || !user.general) return 0;

  let ans = 0;
  ans += calGeneralInfo(user);
  ans += calInterestInfo(user);
  ans += calWorkInfo(user);
  ans += calEducationInfo(user);
  ans += calSkill(user);
  ans += calTrainingCourse(user);

  return Math.min(ans, 100);
};
