import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem 0;
`;

export const TextCredit = styled.span`
  font-size: 14px;
`;

export const JobTextHyperLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const JobTextHyperLink = styled.span`
  font-size: 14px;
  padding: 0 0.5rem;
  text-decoration: underline;
  color: ${(props) => props.theme.primary};
`;
