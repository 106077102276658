export const languageData = [
  {label: 'ไทย', value: 'ไทย'},
  {label: 'อังกฤษ', value: 'อังกฤษ'},
  {label: 'กัมพูชา', value: 'กัมพูชา'},
  {label: 'จีนกลาง', value: 'จีนกลาง'},
  {label: 'ฝรั่งเศส', value: 'ฝรั่งเศส'},
  {label: 'พม่า', value: 'พม่า'},
  {label: 'ฟิลิปิโน', value: 'ฟิลิปิโน'},
  {label: 'มาเลย์', value: 'มาเลย์'},
  {label: 'ยาวี', value: 'ยาวี'},
  {label: 'ลาว', value: 'ลาว'},
  {label: 'สเปน', value: 'สเปน'},
  {label: 'อินโดนีเซีย', value: 'อินโดนีเซีย'},
  {label: 'เกาหลี', value: 'เกาหลี'},
  {label: 'เยอรมัน', value: 'เยอรมัน'},
  {label: 'เวียดนาม', value: 'เวียดนาม'},
  {label: 'อื่นๆ', value: 'อื่นๆ'},
];

export const languageLevelData = [
  {label: 'พอใช้', value: 'พอใช้'},
  {label: 'ดี', value: 'ดี'},
  {label: 'ดีมาก', value: 'ดีมาก'},
];

export const rowLanguageCertificate = {
  url: '',
  organization: '',
  score: '',
};

export const rowLanguage = {
  name: {label: 'อังกฤษ', value: 'อังกฤษ'},
  level: {label: 'ดี', value: 'ดี'},
  customName: '',
  errorText: '',
};
