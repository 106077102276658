import React from 'react';
import {translate} from '../../res/language/translate';
import {useAuth} from '../../lib/hooks/logic/useAuth';
import MasterContactView from './views/contact';
import MetaTag from '../../components/meta-tag';
import Screen from '../../views/screen';

const MasterContactScreen = () => {
  const {loggedInState, isCheckedAuth} = useAuth();

  if (!isCheckedAuth) {
    return null;
  }
  //
  if (loggedInState) {
    return (
      <>
        <MetaTag title={translate('meta.contactUs')} description={translate('meta.contactUs')} />
        <Screen leftSidebar isChildScreen type="single-fluid">
          <MasterContactView isLoggedIn />
        </Screen>
      </>
    );
  }

  return (
    <>
      <MetaTag title={translate('meta.contactUs')} description={translate('meta.contactUs')} />
      <MasterContactView />
    </>
  );
};

MasterContactScreen.propTypes = {};

export default MasterContactScreen;
