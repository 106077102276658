import keyBy from 'lodash/keyBy';
import * as TYPES from './type';
import {updateStreamNewLike, updateStreamUnlike} from './logic';

const initLikeState = {
  loading: false,
  error: null,
  likes: [],
  keyedLikes: {},
};

export const likeReducer = (state = initLikeState, {type, payload}) => {
  switch (type) {
    case TYPES.LIKE_NEW_LIKE_REQ:
      return {...state, loading: true};
    case TYPES.LIKE_NEW_LIKE_SUCCESS: {
      const streamNewLiked1 = updateStreamNewLike({
        stackLike: state.likes,
        newLike: payload?.data,
      });
      return {
        ...state,
        likes: streamNewLiked1,
        keyedLikes: keyBy(streamNewLiked1, (x) => x.job_id),
      };
    }
    case TYPES.LIKE_NEW_LIKE_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.LIKE_UNLIKE_REQ:
      return {...state, loading: true};
    case TYPES.LIKE_UNLIKE_SUCCESS: {
      const streamUnLiked = updateStreamUnlike({
        stackLike: state.likes,
        unlike: payload,
      });
      return {
        ...state,
        loading: false,
        likes: streamUnLiked,
        keyedLikes: keyBy(streamUnLiked, (x) => x.job_id),
      };
    }
    case TYPES.LIKE_UNLIKE_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.LIKE_UPDATE_STREAM_REQ:
      return {...state};
    case TYPES.LIKE_UPDATE_STREAM_SUCCESS: {
      const streamNewLiked = updateStreamNewLike({
        stackLike: state.likes,
        newLike: payload,
      });
      return {
        ...state,
        likes: streamNewLiked,
        keyedLikes: keyBy(streamNewLiked, (x) => x.job_id),
      };
    }
    case TYPES.LIKE_UPDATE_STREAM_FAIL:
      return {...state};
    default:
      return state;
  }
};
