import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxContainer,
  CheckboxInput,
  BoxBackdropFullText,
  CheckboxFullTextContainer,
  CheckboxLabel,
  BoxBackdrop,
  Box,
  CheckboxFullLabel,
} from './styled-component';
import Icon from '../icon';
import checkboxTheme from '../../styles/checkbox';
import colorThemes from '../../styles/color';
import * as iconName from '../icon/constants';

const Checkbox = (props) => {
  const {checked, label, onChange, color, disabled, fullText} = props;

  if (fullText) {
    return (
      <CheckboxFullTextContainer
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onChange();
          }
        }}>
        <CheckboxInput type="checkbox" disabled={disabled} checked={checked} onChange={() => {}} />
        <BoxBackdropFullText>
          <Box>
            <Icon
              name={checked ? iconName.CHECKED_CHECKBOX : iconName.UN_CHECKBOX}
              size={checkboxTheme.size.default}
              color={checked ? color : colorThemes.global.gray}
            />
          </Box>
        </BoxBackdropFullText>
        <CheckboxFullLabel>{label}</CheckboxFullLabel>
      </CheckboxFullTextContainer>
    );
  }

  return (
    <CheckboxContainer
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onChange();
        }
      }}>
      <CheckboxInput type="checkbox" disabled={disabled} checked={checked} onChange={() => {}} />
      <BoxBackdrop>
        <Box>
          <Icon
            name={checked ? iconName.CHECKED_CHECKBOX : iconName.UN_CHECKBOX}
            size={checkboxTheme.size.default}
            color={checked ? color : colorThemes.global.gray}
          />
        </Box>
      </BoxBackdrop>

      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  fullText: PropTypes.bool,
};

Checkbox.defaultProps = {
  color: colorThemes.global.primary,
  disabled: false,
  fullText: false,
};

export default Checkbox;
