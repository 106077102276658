import qs from 'querystring';
import JobCatData from '../../../../res/json/job-cat.json';
import * as ROUTES from '../../../../routes/routes-name';

const topJobCat = [
  'สายงาน ธุรการ ประสานงานทั่วไป จัดซื้อ คีย์ข้อมูล',
  'สายงาน Sales: การขาย',
  'สาย Programmer/Developer/Technology: เทคโนโลยี',
  'สายงาน Factory: โรงงาน การผลิต QC ควบคุมคุณภาพ',
  'สายงาน Finance Banking: การเงิน-ธนาคาร',
  'สายงาน Engineer: วิศวกร',
  'สายงาน Marketing: การตลาด',
  'สายงาน Science: วิทยาศาสตร์-วิจัยพัฒนา-Lab-เทคโนโลยีการอาหาร',
  'สายงานบริการลูกค้า รับเรื่องทางโทรศัพท์ Call Center แอดมิน',
  'สายงาน HR: งานบุคคล บริหารบุคคล พัฒนาบุคคล พัฒนาองค์กร',
];

const topTransport = ['BTS', 'MRT'];

const topProvince = ['กรุงเทพมหานคร', 'นนทบุรี', 'สมุทรปราการ', 'เชียงใหม่', 'ชลบุรี', 'ปทุมธานี', 'สมุทรสาคร', 'ระยอง', 'ภูเก็ต', 'พระนครศรีอยุธยา'];

export const createJobCatIndex = (isTopIndex = false) => {
  if (isTopIndex) {
    const topJobs = topJobCat.map((elem) => {
      return {
        value: `งาน ${elem}`,
        url: ROUTES.ROUTE_SEARCH_RESULTS,
        params: qs.stringify({jobcat: [elem]}),
      };
    });
    return topJobs;
  }
  const shapeJobs = Object.entries(JobCatData);
  const newJobCats = shapeJobs.map((elem) => ({
    value: elem[0],
  }));
  return newJobCats;
};

export const createTransportIndex = () => {
  const transportData = topTransport.map((elem) => {
    return {
      value: `งาน ใกล้รถไฟฟ้า ${elem}`,
      url: ROUTES.ROUTE_SEARCH_HASHTAG,
      params: qs.stringify({tag: `#${elem}`}),
    };
  });
  return transportData;
};

export const createProvinceIndex = () => {
  const provinceData = topProvince.map((elem) => {
    return {
      value: `งาน ${elem}`,
      url: ROUTES.ROUTE_SEARCH_RESULTS,
      params: qs.stringify({province: [elem]}),
    };
  });
  return provinceData;
};
