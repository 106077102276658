import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as snackbarActions from '../../../redux/snackbar/action';

const ToastNotification = () => {
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const snackbarMessageState = useSelector((state) => state.snackbar);

  const onEventReceiveStoreMessage = () => {
    const {type, message} = snackbarMessageState;
    if (!message) {
      return;
    }
    // appearance oneOf(['success', 'error', 'warning', 'info'])
    const config = {
      appearance: type || 'success',
      autoDismiss: true,
    };
    addToast(message, config);
    dispatch(snackbarActions.snackbarClearMessage());
  };

  useEffect(() => {
    if (snackbarMessageState.message) {
      onEventReceiveStoreMessage();
    }
  }, [snackbarMessageState.message]);

  return null;
};

export default ToastNotification;
