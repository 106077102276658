import {useDispatch} from 'react-redux';
import * as searchActions from '../../redux/search/action';

export const useLandingPageFilterEffect = () => {};

export const useLandingPageDispatch = () => {
  const dispatch = useDispatch();
  const dispatchUpdateFormFilter = (params, filter) => {
    if (!params || !filter) {
      return;
    }
    dispatch(searchActions.searchJobGroupUpdateFilter({data: {params, filter}}));
  };

  const dispatchUpdateHotListFilter = (params) => {
    if (!params) {
      return;
    }
    dispatch(searchActions.searchJobGroupUpdateFilter({data: {params}}));
  };

  const dispatchClearFormFilter = () => {
    dispatch(searchActions.searchJobGroupUpdateFilter({clear: true}));
  };

  return {dispatchUpdateFormFilter, dispatchClearFormFilter, dispatchUpdateHotListFilter};
};
