import React from 'react';
import {useSelector} from 'react-redux';
import {getAuthToken} from '../utils/storage/storage';
import HomeScreen from './home';
import MainScreen from './main';
import SplashScreen from './splash';

const IndexScreen = (props) => {
  const isLoggedInState = getAuthToken();
  const isLoading = useSelector((state) => state.auth.auth.loading);

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!isLoggedInState) {
    return <MainScreen {...props} />;
  }
  return <HomeScreen {...props} />;
};

IndexScreen.propTypes = {};

export default IndexScreen;
