import moment from 'moment';
import 'moment/locale/th';

const GLOBAL_DATE_FORMAT = 'YYYY-MM-DD';
const BD = 543;

export const dateToDay = () => {
  return moment().format(GLOBAL_DATE_FORMAT);
};

export const dateNow = () => {
  return moment().format();
};

export const dateFrom = (date) => {
  try {
    return moment(date).format();
  } catch (error) {
    return moment().format();
  }
};

export const yearNow = () => {
  const year = moment().year();
  return year;
};

export const yearBdh = () => {
  const year = moment().year();
  return year + BD;
};

export const convertFormat = ({date}) => {
  try {
    return moment(date).format(GLOBAL_DATE_FORMAT);
  } catch (error) {
    return date;
  }
};

export const convertPickerToDate = ({year, month, day}) => {
  if (!year || !month) {
    return '';
  }
  const m = month?.length === 1 ? `0${month}` : month;
  if (!day) {
    return `${year - BD}-${m}-01`;
  }
  const d = day?.length === 1 ? `0${day}` : day;
  return `${year - BD}-${m}-${d}`;
};

export const isAfterToDay = ({date}) => {
  try {
    const today = dateToDay();
    const compareDate = moment(date).format(GLOBAL_DATE_FORMAT);
    return moment(today).isAfter(compareDate);
  } catch (error) {
    return true;
  }
};

export const isAfterDate = ({dateStart, dateEnd}) => {
  try {
    const convertDateStart = moment(dateStart).format(GLOBAL_DATE_FORMAT);
    const convertDateEnd = moment(dateEnd).format(GLOBAL_DATE_FORMAT);
    return moment(convertDateEnd).isAfter(convertDateStart);
  } catch (error) {
    return true;
  }
};

export const convertToThaiDate = ({date}) => {
  try {
    return moment(date).format('L');
  } catch (error) {
    return '';
  }
};

export const convertToDisplayTH = ({date}) => {
  try {
    moment.locale('th');
    return moment(date).format('LL');
  } catch (error) {
    return '';
  }
};

export const birthDateFormat = ({date}) => {
  try {
    moment.locale('th');
    const birthDateTh = moment(date).add(BD, 'years').format();
    return moment(birthDateTh).format('L');
  } catch (error) {
    return '';
  }
};

export const postDateFormat = ({date}) => {
  try {
    moment.locale('th');
    const responseRange = moment(dateToDay()).diff(moment(date).format(GLOBAL_DATE_FORMAT), 'month', true);
    if (responseRange <= 2) {
      return moment(date).fromNow();
    }
    return moment(date).format('LL');
  } catch (error) {
    return '';
  }
};

export const diffMonth = ({startDate, endDate}) => {
  try {
    const response = moment(endDate).diff(moment(startDate), 'month', true);
    return response;
  } catch (error) {
    return 0;
  }
};

export const diffYear = (date) => {
  try {
    const response = moment(dateToDay()).diff(moment(date), 'year');
    return response;
  } catch (error) {
    return null;
  }
};
