import {createServiceURL} from '../../env/utils/env-services';
import {adapterGet, adapterGetWithParams} from '../../utils/adapter/get';
import {adapterPost} from '../../utils/adapter/post';
import {adapterDelete} from '../../utils/adapter/delete';

export const userAuthCurrent = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/current`;
  const response = await adapterGet({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const userAuthLogout = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/logout`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const userLoginManual = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/user_login_manual`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const userLoginFacebook = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/user_login_facebook`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const userForgotPasswordRequestCode = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/otp/request_otp`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const userForgotPasswordCheckCode = async (reqBody = {}) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/forgot_password`;
  const response = await adapterGetWithParams({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const userForgotPasswordSetPassword = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/set_new_password`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const userLoginFirebase = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/login_firebase`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const userCancelOTP = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/otp/cancel_otp`;
  const response = await adapterDelete({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};
