import styled from 'styled-components';
import NavigationTheme from '../../../styles/navigation';

export const ScreenWrapper = styled.div`
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: 0;
  @media (max-width: 1064px) {
    margin-top: 50px;
    ${(props) => props.isNotSpace && `margin-top: 0px`};
  }
`;

export const NavParent = styled.header`
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  background-color: ${(props) => props.theme.white};
  box-shadow: ${NavigationTheme.NavigationHeadShadow.boxShadow};
  ${(props) => props.noUnderLine && `box-shadow: ${NavigationTheme.NavigationHeadShadow.boxShadow}`};
`;

export const NavHidden = styled.div`
  height: ${NavigationTheme.NavigationHeader.height}px;
  width: 100%;
  background-color: transparent;
`;

export const NavContainer = styled.nav`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  height: ${NavigationTheme.NavigationHeader.height}px;
  @media (max-width: 840px) {
    padding: 0 1rem;
  }
`;

export const CoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  box-shadow: rgb(137 143 156 / 50%) 0.2px 0.2px 2px;
  ${(props) => props.background && !props.defaultBackground && `background: linear-gradient(${props.background.toString()})`};
  ${(props) => props.background && props.defaultBackground && `background: linear-gradient(${props.background.toString()})`};
`;

export const CoverImg = styled.img`
  border: 1px solid ${(props) => props.theme.tinGray};
  height: 348px;
  width: 940px;
  border-radius: 0 0 8px 8px;
  @media (max-width: 939px) {
    height: calc(100vw / 2.7);
    width: 100%;
    border-radius: 0;
  }
`;

export const CoverDummy = styled.div`
  height: 348px;
  width: 940px;
  background-color: ${(props) => props.theme.tinGray};
  border-radius: 0 0 8px 8px;
  @media (max-width: 939px) {
    height: calc(100vw / 2.7);
    width: 100%;
    border-radius: 0;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  padding: 1rem 0;
`;

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FixedBottom = styled.div`
  height: 40px;
  width: 100%;
`;

export const OverrideStyles = {
  coverTitle: {
    padding: '1rem',
  },
  leftArrow: {
    marginLeft: '-10px',
    width: '77px',
  },
};
