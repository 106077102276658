import {takeEvery} from 'redux-saga/effects';
import * as TYPES from './type';
import * as applyJobSaga from './saga';

export function* watchApplyJobCheckInfo() {
  yield takeEvery(TYPES.APPLY_JOB_CHECK_INFO_REQ, applyJobSaga.applyJobCheckInfoAction);
}

export function* watchApplyJobGetDraftEmail() {
  yield takeEvery(TYPES.APPLY_JOB_GET_DRAFT_REQ, applyJobSaga.applyJobGetDraftEmailAction);
}

export function* watchApplyJobUpdateDraftEmail() {
  yield takeEvery(TYPES.APPLY_JOB_UPDATE_DRAFT_REQ, applyJobSaga.applyJobUpdateDraftEmailAction);
}

export function* watchApplyJobRequestModal() {
  yield takeEvery(TYPES.APPLY_JOB_REQUEST_MODAL_REQ, applyJobSaga.applyJobRequestModalAction);
}

export function* watchApplyJobGetResumeTemplate() {
  yield takeEvery(TYPES.APPLY_JOB_RESUME_GET_TEMPLATE_REQ, applyJobSaga.applyJobGetResumeTemplateAction);
}

export function* watchApplyJobGenerateResume() {
  yield takeEvery(TYPES.APPLY_JOB_RESUME_GENERATE_TEMPLATE_REQ, applyJobSaga.applyJobGenerateResumeAction);
}
