import {useDispatch, useSelector} from 'react-redux';
import * as contextActions from '../../redux/context/action';
import * as typeDef from '../../utils/strong-types';

export const useContextStackDistrictsDispatch = () => {
  const dispatch = useDispatch();
  const dispatchGetStackDistrictByProvince = (province, currentStack = {}) => {
    if (currentStack[province]) {
      return;
    }
    dispatch(contextActions.contextGetStackDistricts({data: {province}}));
  };

  const dispatchGetAllDistrictByProvinceList = (provinceList, currentStack = {}) => {
    if (typeDef.isNotArrayLength(provinceList)) {
      return;
    }
    provinceList.forEach((elem) => {
      if (currentStack[elem.value]) {
        return;
      }
      dispatch(contextActions.contextGetStackDistricts({data: {province: elem.value}}));
    });
  };

  return {
    dispatchGetStackDistrictByProvince,
    dispatchGetAllDistrictByProvinceList,
  };
};

export const useContextStackDistrictState = () => {
  const {
    loading: isLoadingStackDistrictState,
    data: dataStackDistrictState,
    error: errorStackDistrictState,
  } = useSelector((state) => state.context.stackDistrict);
  return {
    isLoadingStackDistrictState,
    dataStackDistrictState,
    errorStackDistrictState,
  };
};
