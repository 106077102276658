/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {stringify as HeaderStringify} from 'querystring';
import Axios from 'axios';
import {getPlatform} from '../../utils/platform';
import {translate} from '../../res/language/translate';
import {envFacebook} from '../../env/utils/env-services';
import LottieAnimation from '../../components/lottie';
import {Container, Label} from './styled-component';
import * as logic from './logic';
import * as navigationServices from '../../utils/navigation/navigation-services';
import * as routeName from '../../routes/routes-name';
import * as alertAction from '../../redux/alert/action';
import * as authAction from '../../redux/auth/action';
import * as storage from '../../utils/storage/storage';

const KEY_RD = 'rd_path';

const FacebookLoginScreen = () => {
  const {CLIENT_ID, REDIRECT_URL, CLIENT_SECRET, FACEBOOK_GRAPH_URL} = envFacebook();
  const dispatch = useDispatch();
  const history = useHistory();
  const [titleState, setTitleState] = useState(translate('facebookLogin.requestToLogin'));
  const facebookListenerAuth = useSelector((state) => state.auth.facebook);
  const callbackPath = useSelector((state) => state.context.callbackPath);

  // error login
  const onEventLoginErrorOrDenied = (error) => {
    const contextData = {
      message: logic.handlerError(error),
      action: {
        positiveAction: () =>
          navigationServices.navigateReplaceTo({
            pathName: routeName.ROUTE_LOGIN,
          }),
      },
    };
    dispatch(alertAction.alertSetAlert({data: contextData}));
  };

  // step 1 request login
  const onEventRequestAuth = () => {
    storage.setItem(storage.KEY_RD, callbackPath, true);
    const reqBody = {
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URL,
      state: 'st=state123abc',
    };
    window.location.assign(`https://www.facebook.com/v9.0/dialog/oauth?${HeaderStringify(reqBody)}`);
  };

  // step 2 receive login token
  const onEventReceivedFacebookToken = async (token) => {
    try {
      const reqBodyAccessToken = {
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URL,
        client_secret: CLIENT_SECRET,
        code: token,
      };
      const response = await Axios.get(`${FACEBOOK_GRAPH_URL}/v9.0/oauth/access_token?${HeaderStringify(reqBodyAccessToken)}`);
      await onEventVerifyToken(response.data);
    } catch (error) {
      onEventLoginErrorOrDenied(error);
    }
  };

  // step 3 verify token
  const onEventVerifyToken = async (resToken) => {
    try {
      const reqCredentials = {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: 'client_credentials',
      };
      const responseCredentials = await Axios.get(`${FACEBOOK_GRAPH_URL}/oauth/access_token?${HeaderStringify(reqCredentials)}`);

      const reqValidateAppToken = {
        input_token: resToken.access_token,
        access_token: responseCredentials.data.access_token,
      };
      const responseValidateAppToken = await Axios.get(`${FACEBOOK_GRAPH_URL}/debug_token?${HeaderStringify(reqValidateAppToken)}`);
      await onEventCheckPermission(resToken, responseValidateAppToken.data.data);
    } catch (error) {
      onEventLoginErrorOrDenied(error);
    }
  };

  // step 4 check permission
  const onEventCheckPermission = async (resToken, resData) => {
    try {
      const {app_id, user_id} = resData;
      if (!app_id || !user_id) {
        throw new Error('undefined app_id or user_id');
      }
      const reqPermission = {
        access_token: resToken.access_token,
      };
      // permission status enum{ granted, declined, expired }
      const responsePermission = await Axios.get(`${FACEBOOK_GRAPH_URL}/${user_id}/permissions?${HeaderStringify(reqPermission)}`);
      const validatePermission = logic.checkGrantedPermission(responsePermission.data.data);
      if (validatePermission.isValid) {
        await onEventGetUserInformation(resToken);
      } else {
        await onEventRequestPermission(resToken, validatePermission.data);
      }
    } catch (error) {
      onEventLoginErrorOrDenied(error);
    }
  };

  // step 5.1 if permission denied
  const onEventRequestPermission = async (resToken, scopes) => {
    try {
      const reqRequestPermission = {
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URL,
        auth_type: 'request',
        scope: scopes?.toString(),
      };
      const responseRequestPermission = await Axios.get(`https://www.facebook.com/v9.0/dialog/oauth?${HeaderStringify(reqRequestPermission)}`);
      // TODO: Handle if permission denied
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(responseRequestPermission);
      }
    } catch (error) {
      onEventLoginErrorOrDenied(error);
    }
  };

  // step 5.2 if permission granted
  const onEventGetUserInformation = async (resToken) => {
    try {
      setTitleState(translate('facebookLogin.syncProfile'));
      const fields = ['id,name,first_name,last_name,email, picture.width(720).height(720)'];
      // 'id,name,first_name,last_name, picture.width(720).height(720)',
      const reqFields = {
        fields: fields.toString(),
        access_token: resToken.access_token,
      };
      const responseUserInfo = await Axios.get(`${FACEBOOK_GRAPH_URL}/v9.0/me?${HeaderStringify(reqFields)}`);
      setTitleState(translate('facebookLogin.loginSuccess'));
      await onEventLoginSuccess(responseUserInfo.data);
    } catch (error) {
      onEventLoginErrorOrDenied(error);
    }
  };

  const onEventLoginSuccess = async (userInfo = {}) => {
    const reqBody = {
      ...userInfo,
      platform: getPlatform(),
    };
    const rdPath = storage.getItem(storage.KEY_RD);
    let callbackPathData = {};
    if (rdPath) {
      callbackPathData = JSON.parse(rdPath);
    }
    dispatch(authAction.userLoginFacebook({data: reqBody, callbackPath: callbackPathData}));
  };

  if (facebookListenerAuth?.user) {
    setTimeout(() => {
      if (facebookListenerAuth?.error) {
        history.replace({
          pathname: routeName.ROUTE_REGISTER,
        });
      } else {
        // navigationServices.navigateReplaceTo({pathName: routeName.ROUTE_HOME});
      }
    }, 1500);
  }

  useEffect(() => {
    const headerURLListener = window.location.search;
    const facebookToken = new URLSearchParams(headerURLListener).get('code');
    const facebookError = new URLSearchParams(headerURLListener).get('error');
    if (facebookError) {
      onEventLoginErrorOrDenied(facebookError);
    }
    if (!facebookToken && !facebookError) {
      onEventRequestAuth();
    } else {
      onEventReceivedFacebookToken(facebookToken);
    }
  }, []);

  return (
    <Container>
      <LottieAnimation refAnimationName="fa-login" />
      <Label>{titleState}</Label>
    </Container>
  );
};

FacebookLoginScreen.propTypes = {};

FacebookLoginScreen.defaultProps = {};

export default FacebookLoginScreen;
