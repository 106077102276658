import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import SkeletonLoading from 'react-loading-skeleton';
import {SkeletonJobPost, PostBodyWrapper, OverrideStyles} from './styled-component';
import Row from '../../components/row';
import Responsive from '../../components/responsive';

const Skeleton = forwardRef((props, ref) => {
  const {type} = props;
  if (type === 'jobPost') {
    return (
      <SkeletonJobPost ref={ref}>
        <Row content="space-between">
          <Row>
            <SkeletonLoading circle height={48} width={48} />
            <SkeletonLoading height={20} width={100} style={OverrideStyles.marginLeft} />
          </Row>
          <SkeletonLoading height={20} width={100} />
        </Row>
        <PostBodyWrapper>
          <SkeletonLoading count={5} />
        </PostBodyWrapper>
        <Row content="space-between">
          <Responsive maxWidth={400}>
            <SkeletonLoading height={20} width={90} />
            <SkeletonLoading height={20} width={90} />
            <SkeletonLoading height={20} width={90} />
          </Responsive>
          <Responsive minWidth={401}>
            <SkeletonLoading height={20} width={120} />
            <SkeletonLoading height={20} width={120} />
            <SkeletonLoading height={20} width={120} />
          </Responsive>
        </Row>
      </SkeletonJobPost>
    );
  }
  return <div>Avatar</div>;
});

Skeleton.propTypes = {
  type: PropTypes.oneOf(['jobPost', 'avatar']),
  // num: PropTypes.number,
};

Skeleton.defaultProps = {
  type: 'jobPost',
  // num: 1
};

export default Skeleton;
