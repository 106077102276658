import React, {useState} from 'react';
import {translate} from '../../../res/language/translate';
import {useOTP} from '../../../lib/hooks/logic/useOTP';
import {FormWrapper, CenterWrapper, OverrideStyles} from './styled-component';
import Form from '../../../components/form';
import Input from '../../../components/input';
import Button from '../../../components/button';
import ButtonUnderline from '../../../components/button-underline';
import * as validates from './validate';

const FormPhone = () => {
  const {dispatchRequestOTP, dispatchLoginOther} = useOTP();
  const [phoneState, setPhoneState] = useState('');
  const [errorText, setErrorText] = useState('');

  const onSubmit = (formValues) => {
    const {phone} = formValues;
    const validateResult = validates.validatePhoneNumber({phone});
    const isValidate = !validateResult;
    if (!isValidate) {
      setErrorText(validateResult);
      return;
    }
    dispatchRequestOTP(phone);
  };

  const onChangeText = (value) => {
    if (errorText) {
      setErrorText('');
    }
    setPhoneState(value);
  };

  const onClickLoginOther = () => {
    dispatchLoginOther();
  };

  return (
    <FormWrapper>
      <Form formId="form-register-verify-phone" onSubmit={onSubmit}>
        <Input
          borderOutline
          type="phone"
          name="phone"
          placeholder={translate('forgotPassword.textFieldPhone')}
          value={phoneState}
          onChange={(value) => onChangeText(value)}
          warningText={errorText}
        />
        <Button block type="submit" edgeInsets="none" style={OverrideStyles.button}>
          {translate('otp.buttonSendSMS')}
        </Button>
      </Form>
      <CenterWrapper>
        <ButtonUnderline onClick={onClickLoginOther}>{translate('otp.labelLoginOther')}</ButtonUnderline>
      </CenterWrapper>
    </FormWrapper>
  );
};

FormPhone.propTypes = {};

export default FormPhone;
