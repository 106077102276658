import * as TYPES from './type';

export const useGenerateId = () => ({
  type: TYPES.REGISTER_GENERATE_ID_REQ,
});

export const userRegisterUpdateFormGeneral = (payload) => ({
  type: TYPES.REGISTER_STEP1_UPDATE_FORM_REQ,
  payload,
});

export const userRegisterStep1ValidateEmail = (payload) => ({
  type: TYPES.REGISTER_STEP1_VALIDATE_EMAIL_REQ,
  payload,
});

export const userRegisterUpdateFormInfo = (payload) => ({
  type: TYPES.REGISTER_STEP2_UPDATE_FORM_REQ,
  payload,
});

export const userRegisterNextStep = (payload) => ({
  type: TYPES.REGISTER_NEXT_STEP_REQ,
  payload,
});

export const userRegisterUpdateVisibleStep = (payload) => ({
  type: TYPES.REGISTER_VISIBLE_STEP_REQ,
  payload,
});

export const userRegisterOTP = (payload) => ({
  type: TYPES.REGISTER_OTP_REQ,
  payload,
});

export const userRegisterOTPRequest = (payload) => ({
  type: TYPES.REGISTER_OTP_REQUEST_REQ,
  payload,
});

export const userRegisterOTPResend = (payload) => ({
  type: TYPES.REGISTER_OTP_RESEND_REQ,
  payload,
});

export const userRegisterOTPChangePhoneNumber = (payload) => ({
  type: TYPES.REGISTER_OTP_CHANGE_PHONE_REQ,
  payload,
});

export const userRegisterOTPConfirm = (payload) => ({
  type: TYPES.REGISTER_OTP_CONFIRM_REQ,
  payload,
});

export const userRegisterManual = (payload) => ({
  type: TYPES.REGISTER_MANUAL_REQ,
  payload: payload,
});

export const userRegisterFacebook = (payload) => ({
  type: TYPES.REGISTER_FACEBOOK_REQ,
  payload: payload,
});

export const userRegisterClearForm = () => ({
  type: TYPES.REGISTER_CLEAR_FORM_REQ,
});

export const userRegisterUpdateFormInterest = (payload) => ({
  type: TYPES.REGISTER_INTEREST_REQ,
  payload: payload,
});
