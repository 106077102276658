import {createServiceURL} from '../../env/utils/env-services';
import {adapterPost} from '../../utils/adapter/post';
import {adapterGet} from '../../utils/adapter/get';

export const userShortProfile = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/user/profile`;
  const response = await adapterGet({url: API_URL, privateMethod: true});
  return response;
};

export const userGenerateAutoResume = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/resume/template_upload`;
  const response = await adapterPost({url: API_URL, privateMethod: true});
  return response;
};

export const userDetailProfile = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/user/profile_detail`;
  const response = await adapterGet({url: API_URL, privateMethod: true});
  return response;
};

export const userChangePassword = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/user/change_password`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const editUserProfile = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/user/edit_profile`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const syncUserFacebook = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/user/sync_facebook`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const syncUserManual = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/user/sync_manual`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const userUploadResumeManual = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/resume/manual_upload`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const userAcceptTerm = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/update_tac`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};
