import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {InputField, InputWrapper, InputLabel, Container, InputHint, OverrideStyles} from './styled-component';
import Icon from '../icon';
import GestureDetector from '../gesture';
import Tooltip from '../tooltip';
import * as generate from '../../utils/generate';
import * as ICON from '../icon/constants';

const Input = React.forwardRef((props, ref) => {
  const {
    id,
    containerStyle,
    iconName,
    name,
    value,
    onChange,
    placeholder,
    type,
    label,
    hint,
    warningText,
    tooltipText,
    borderOutline,
    fillBackground,
    onKeydown,
    disabled,
    inputProps,
    inputStyle,
    options,
  } = props;
  const {focusWhite} = options;

  const [idFor] = useState(id || generate.generateIdString('input'));
  const [isFocus, setIsFocus] = useState(false);
  const [securePassword, setSecurePassword] = useState(null);
  const [iconNameState, setIconNameState] = useState(ICON.CLOSE);
  const iconColor = fillBackground ? OverrideStyles.icon.colorGray : OverrideStyles.icon.color;
  const isSecureText = type === 'password';

  useEffect(() => {
    if (isSecureText) {
      if (!securePassword) {
        setIconNameState(ICON.PASSWORD_SEE);
        return;
      }
      if (securePassword === 'password') {
        setIconNameState(ICON.PASSWORD_SEE);
      } else {
        setIconNameState(ICON.PASSWORD_UN_SEE);
      }
    }
  }, [type, securePassword]);

  const handlerRightAction = () => {
    if (isSecureText) {
      if (securePassword === 'password' || !securePassword) {
        setSecurePassword('text');
        return;
      }
      setSecurePassword('password');
    } else {
      onChange('');
    }
  };

  if (onChange) {
    return (
      <Container style={containerStyle}>
        {label && <InputLabel>{label}</InputLabel>}
        <InputWrapper
          data-tip
          data-for={idFor}
          borderOutline={borderOutline}
          fillBackground={fillBackground}
          isFocus={isFocus}
          focusWhite={focusWhite}
          warningText={warningText}>
          {iconName && <Icon name={iconName} color={iconColor} />}
          <InputField
            id={idFor}
            ref={ref}
            name={name}
            isFocus={isFocus}
            autoCapitalize="false"
            autoComplete="false"
            autoCorrect="false"
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            type={securePassword || type}
            placeholder={placeholder}
            icon={!!iconName}
            withLabel={!!label}
            onKeyDown={onKeydown}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
            {...inputProps}
            style={inputStyle}
          />
          {value && (
            <GestureDetector withEdgeInsets={false} onClick={handlerRightAction}>
              <Icon name={iconNameState} color={iconColor} size={isSecureText ? 20 : 22} />
            </GestureDetector>
          )}
        </InputWrapper>
        {(hint || warningText) && <InputHint warning={!!warningText}>{warningText || hint}</InputHint>}
        {tooltipText && <Tooltip idFor={idFor} dataTip={tooltipText} />}
      </Container>
    );
  }
  return (
    <Container style={containerStyle}>
      {label && <InputLabel>{label}</InputLabel>}
      <InputWrapper
        data-tip
        data-for={idFor}
        borderOutline={borderOutline}
        fillBackground={fillBackground}
        isFocus={isFocus}
        warningText={warningText}>
        {iconName && <Icon name={iconName} color={iconColor} />}
        <InputField
          id={idFor}
          ref={ref}
          name={name}
          autoCapitalize="false"
          autoComplete="false"
          autoCorrect="false"
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          type={type}
          placeholder={placeholder}
          icon={!!iconName}
          onKeyDown={onKeydown}
          withLabel={!!label}
          disabled={disabled}
          value={value}
          style={inputStyle}
        />
      </InputWrapper>
      {(hint || warningText) && <InputHint warning={!!warningText}>{warningText || hint}</InputHint>}
      {tooltipText && <Tooltip idFor={idFor} dataTip={tooltipText} />}
    </Container>
  );
});

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  iconName: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  warningText: PropTypes.string,
  tooltipText: PropTypes.string,
  borderOutline: PropTypes.bool,
  fillBackground: PropTypes.bool,
  onChange: PropTypes.func,
  containerStyle: PropTypes.any,
  onKeydown: PropTypes.func,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  inputStyle: PropTypes.any,
  options: PropTypes.object,
};

Input.defaultProps = {
  id: '',
  type: 'text',
  name: '',
  iconName: '',
  value: '',
  placeholder: '',
  hint: '',
  label: '',
  warningText: '',
  onChange: null,
  borderOutline: false,
  fillBackground: false,
  tooltipText: '',
  containerStyle: null,
  onKeydown: () => {},
  disabled: false,
  inputProps: {},
  inputStyle: null,
  options: {},
};

export default Input;
