import moment from 'moment';
import * as typeDef from '../../../../utils/strong-types';
import * as generate from '../../../../utils/generate';
import * as initData from './init-data';
import * as dateTime from '../../../../utils/date-time';

export const transToAge = (birthday) => {
  if (!birthday) {
    return '';
  }
  const age = dateTime.diffYear(birthday);
  if (!age) {
    return '';
  }
  return {id: age, label: `${age} ปี`, value: age};
};

export const getFirstOrDefault = (list) => {
  if (typeDef.isNotArrayLength(list)) {
    return null;
  }
  return list[0].value;
};

export const getValueOrDefault = (objItem) => {
  return objItem?.value || null;
};

export const mapArrayValue = (list) => {
  if (typeDef.isNotArrayLength(list)) {
    return [];
  }
  const response = list.map((elem) => elem.value);
  return response;
};

export const mapStateToGender = (list, state) => {
  if (typeDef.isNotArrayLength(list) || !state) {
    return '';
  }
  const findValue = list.find((elem) => elem.value === state);
  if (!findValue) {
    return '';
  }
  return findValue;
};

export const mapStateToGeneral = (generalState, locationState, contactableTel, contactableEmail) => {
  if (!generalState) {
    return initData.initForm;
  }
  const response = {...initData.initForm};
  response.firstName = generalState?.firstname || null;
  response.lastName = generalState?.lastname || null;
  response.address = locationState?.address || null;
  response.contactableTel = typeDef.isArrayLength(contactableTel) ? contactableTel[0] : '';
  response.contactableEmail = typeDef.isArrayLength(contactableEmail) ? contactableEmail[0] : '';
  return response;
};

export const mapStateToProvince = (provinceState) => {
  if (!provinceState) {
    return [];
  }
  const response = {
    id: generate.generateId('PV'),
    label: provinceState,
    value: provinceState,
  };
  return [response];
};

export const mapStateToDistrict = (districtState) => {
  if (!districtState) {
    return [];
  }
  const label = districtState.split('|');
  const response = {
    id: generate.generateId('DT'),
    label: label[1] || label[0],
    value: districtState,
  };
  return [response];
};

export const mapDefaultSingleValue = (listData, state) => {
  if (!state) {
    return listData[0];
  }
  const response = listData.find((elem) => elem.value === state);
  if (!response) {
    return listData[0];
  }
  return response;
};

export const mapDefaultMultiValue = (listData, state) => {
  if (typeDef.isNotArrayLength(state)) {
    return [];
  }
  const response = state.map((elem) => listData.find((item) => item.value === elem));
  return response.filter((elem) => elem);
};

export const mapDefaultBirthDate = (birthday, data) => {
  if (!data) {
    return {birthDate: '', birthMonth: '', birthYear: ''};
  }
  const {date, month, year} = data;
  const d = moment(birthday).date();
  const m = moment(birthday).month();
  const y = moment(birthday).year();

  const birthDate = date.find((elem) => elem.value === d);
  const birthMonth = month.find((elem) => elem.value === m + 1);
  const birthYear = year.find((elem) => elem.value === y);
  return {
    birthDate,
    birthMonth,
    birthYear,
  };
};

export const createAgeData = () => {
  const maxAge = 120;
  const data = [];
  for (let i = 12; i <= maxAge; i++) {
    data.push({id: i, label: `${i} ปี`, value: i});
  }
  return data;
};
