import {takeEvery} from 'redux-saga/effects';
import * as TYPES from './type';
import * as saga from './saga';

export function* watchFetchApplyJob() {
  yield takeEvery(TYPES.LIST_APPLY_JOB_REQ, saga.listApplyJob);
}

export function* watchFetchApplyJobLike() {
  yield takeEvery(TYPES.LIST_APPLY_JOB_LIKE_REQ, saga.listApplyJobLike);
}

export function* watchFetchApplyJobUnlike() {
  yield takeEvery(TYPES.LIST_APPLY_JOB_UNLIKE_REQ, saga.listApplyJobUnlike);
}