import * as typeDef from '../../../../utils/strong-types';

export const transformJobByType = (data) => {
  if (typeDef.isNotArrayLength(data)) {
    return null;
  }
  const jobCat = data.filter((elem) => !elem.value.includes('|'));
  const subJob = data.filter((elem) => elem.value.includes('|'));

  const response = {
    jobcat: jobCat.map((elem) => elem.value),
    subjobcat: subJob.map((elem) => elem.value),
  };
  return response;
};

export const transformArrayData = (data) => {
  if (typeDef.isNotArrayLength(data)) {
    return [];
  }
  const response = data.map((elem) => elem.value);
  return response;
};

export const transformArrayDataAndTrim = (data) => {
  if (typeDef.isNotArrayLength(data)) {
    return [];
  }
  const response = data.map((elem) => elem.value.trim());
  return response;
};

export const getFirstOrDefault = (list) => {
  if (typeDef.isNotArrayLength(list)) {
    return null;
  }
  return list[0].value;
};
