import {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useSearchParamsEffect} from '../hooks/useSearchParams';
import * as typeDef from '../../utils/strong-types';
import * as filterUtils from '../context/filter';
import * as contextActions from '../../redux/context/action';

const SAL_MAX = 150000;
const MAX = 5000000;

export const useFilterOptions = () => {
  const {searchParamsState} = useSearchParamsEffect();
  const externalParams = useSelector((state) => state.search.jobGroup.params);

  const checkIsSelectChip = (selectedValue, value) => {
    if (!Array.isArray(selectedValue) || typeDef.isNullOrUndefined(value)) {
      return false;
    }
    const isSelected = selectedValue.find((element) => element === value);
    return !!isSelected;
  };

  const transformFilterToParams = (formValues) => {
    if (!formValues) {
      return {};
    }
    const {filterEdu, filterExp, filterSalary, isFilterSalary, filterKey} = formValues;
    let reqBody = {};
    const params = filterUtils.transformUrlParamsToQuery(searchParamsState) || {};
    if (typeDef.isArrayLength(filterEdu)) {
      reqBody.education_requirement = [...filterEdu];
    } else {
      delete params.education_requirement;
      delete reqBody.education_requirement;
    }
    if (typeDef.isArrayLength(filterExp)) {
      reqBody.experience_requirement = filterExp.map((x) => parseInt(x, 16));
    } else {
      delete params.experience_requirement;
      delete reqBody.experience_requirement;
    }
    reqBody.salary_min = filterSalary[0] === SAL_MAX ? SAL_MAX : filterSalary[0];
    reqBody.salary_max = filterSalary[1] === SAL_MAX ? MAX : filterSalary[1];

    if (!isFilterSalary) {
      delete reqBody.salary_min;
      delete reqBody.salary_max;
      delete params.salary_min;
      delete params.salary_max;
    }
    if (filterKey?.keyCompany) {
      reqBody.author_name = filterKey.keyCompany;
    }
    if (!filterKey?.keyCompany) {
      delete params.author_name;
    }
    reqBody.search = filterKey?.keyJob || '';
    reqBody = {...params, ...reqBody};
    if (externalParams?.author_id_arr) {
      reqBody.author_id_arr = externalParams?.author_id_arr;
    }
    if (typeDef.isEmptyObject(reqBody)) {
      reqBody = null;
    }
    return reqBody;
  };

  return {
    checkIsSelectChip,
    transformFilterToParams,
  };
};

export const useFilterOptionEffect = ({setFilterKey, setFilterEdu, setFilterExp, setFilterSalary, setIsFilterSalary}) => {
  const {searchParamsState} = useSearchParamsEffect();

  useEffect(() => {
    if (searchParamsState) {
      if (searchParamsState.education_requirement) {
        setFilterEdu(() => {
          return filterUtils.transformValueToLabel({
            type: 'edu',
            key: searchParamsState.education_requirement,
          });
        });
      }
      if (searchParamsState.experience_requirement) {
        setFilterExp(() => {
          return filterUtils.transformValueToLabel({
            type: 'exp',
            key: searchParamsState.experience_requirement,
          });
        });
      }
      if (searchParamsState.salary_min && searchParamsState.salary_max) {
        const salMax = searchParamsState.salary_max < MAX ? searchParamsState.salary_max : SAL_MAX;
        setIsFilterSalary(true);
        setFilterSalary([searchParamsState.salary_min, salMax]);
      }
      if (searchParamsState.search || searchParamsState.author_name) {
        const keys = {};
        if (searchParamsState.search) {
          keys.keyJob = searchParamsState.search;
        }
        if (searchParamsState.author_name) {
          keys.keyCompany = searchParamsState.author_name;
        }
        setFilterKey(keys);
      }
    }
  }, [searchParamsState]);
};

export const useFilterModal = () => {
  const dispatch = useDispatch();
  const isVisibleFilterState = useSelector((state) => state.context.filter.visible, shallowEqual);
  const dispatchSetVisibleFilter = (isVisible) => {
    if (isVisible) {
      dispatch(contextActions.contextSetVisibleFilter({visible: true}));
    } else {
      dispatch(contextActions.contextSetVisibleFilter({visible: false}));
    }
  };
  return {isVisibleFilterState, dispatchSetVisibleFilter};
};
