import {put, call} from 'redux-saga/effects';
import * as api from './api';
import * as TYPES from './type';
import * as USER_TYPES from '../user/type';
import * as snackbar from '../../utils/components/toast-notification/provider';
import {getIntl} from '../../utils/i18n';
import {getErrorMessage} from '../utils/error-store';
import * as sagaAlert from '../utils/saga-alert';

export function* getDraft({payload}) {
  try {
    const response = yield call(api.getDraft, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.GET_APPLY_JOB_DRAFT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.GET_APPLY_JOB_DRAFT_FAIL,
      payload: responseError,
    });
  }
}

export function* uploadResume({payload}) {
  try {
    const response = yield call(api.updateResumeLink, payload);
    yield put({
      type: USER_TYPES.USER_UPDATE_RESUME,
      payload: {
        resume: response.data.resume,
      },
    });

    if (response.status === 200) {
      yield put({
        type: TYPES.GET_APPLY_JOB_RESUME_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: TYPES.GET_APPLY_JOB_SAVE_DATA_REQ,
        payload: {jobId: payload.jobId, useAutoResume: false},
      });
    }

    snackbar.setSnackbarMessage(getIntl().formatMessage({defaultMessage: 'อัพโหลดสำเร็จ'}));
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.GET_APPLY_JOB_RESUME_FAIL,
      payload: responseError,
    });
    snackbar.setSnackbarMessage(getIntl().formatMessage({defaultMessage: 'อัพโหลดผิดพลาด'}), 'warning');
  }
}

export function* saveDraft({payload}) {
  try {
    yield call(api.saveDraft, {title: payload.subj.template, body: payload.body.template});
  } catch (error) {
    // ignored
  }
}

export function* saveData({payload}) {
  try {
    const response = yield call(api.saveData, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.GET_APPLY_JOB_SAVE_DATA_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.GET_APPLY_JOB_SAVE_DATA_FAIL,
      payload: responseError,
    });
  }
}

export function* sendMailAction({payload}) {
  try {
    const response = yield call(api.sendMail, payload?.data);
    yield put({
      type: TYPES.APPLY_JOB_SEND_MAIL_SUCCESS,
      payload: response.data,
    });
    sagaAlert.sagaAlertSuccess('ส่งอีเมลสมัครงานเรียบร้อย');
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const errMessage = getErrorMessage(responseError);
    yield put({
      type: TYPES.APPLY_JOB_SEND_MAIL_FAIL,
      payload: responseError,
    });
    sagaAlert.sagaAlertError(errMessage);
  }
}
