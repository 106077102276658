/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {NavParent, NavHidden, NavContainer, NavCenter, OverrideStyles} from './styled-component';
import AppLogo from '../../../views/logo/logo';
import GestureDetector from '../../../components/gesture';
import Icon from '../../../components/icon';
import Searcher from '../../../views/searcher';
import * as navigationServices from '../../../utils/navigation/navigation-services';
import * as ICONS from '../../../components/icon/constants';

const NavBar = (props) => {
  const {onEventRefresh} = props;

  const onEventGoBack = () => {
    navigationServices.goBack();
  };

  return (
    <>
      <NavParent>
        <NavContainer>
          <GestureDetector withEdgeInsets={false} onClick={onEventGoBack} style={OverrideStyles.leftArrow}>
            <Icon name={ICONS.ARROW_LEFT} size={35} />
          </GestureDetector>
          <NavCenter>
            <AppLogo onClick={onEventRefresh} />
          </NavCenter>
          <Searcher />
        </NavContainer>
      </NavParent>
      <NavHidden />
    </>
  );
};

NavBar.propTypes = {
  onEventRefresh: PropTypes.func.isRequired,
};

export default NavBar;
