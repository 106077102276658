import React from 'react';
import {translate} from '../../../res/language/translate';
import {useFormSearchState, useFormSearchDispatch} from '../../../lib/hooks/logic/useForm';
import {Container, RowButton, OverrideStyles} from './styled-component';
import Form from '../../../components/form';
import Button from '../../../components/button';
import KeyForm from './key-form';
import CategoryForm from './category-form';
import LocationForm from './location-form';
import * as coreSearch from '../../../lib/context/search';
import * as ROUTES from '../../../routes/routes-name';
import * as navigation from '../../../utils/navigation/navigation-services';
import * as mixpanel from '../../../utils/mixpanel';

const FormSearch = () => {
  const {formSearchState} = useFormSearchState();
  const {dispatchClearForm} = useFormSearchDispatch();

  const onSubmitSearch = () => {
    mixpanel.searchClickForm(formSearchState);
    const searchParams = coreSearch.transformSearchFullForm(formSearchState);
    navigation.navigateReplaceTo({pathname: ROUTES.ROUTE_SEARCH_RESULTS, search: searchParams});
  };

  const onClearForm = () => {
    dispatchClearForm();
  };

  return (
    <Container>
      <Form formId="form-full-search" onSubmit={onSubmitSearch}>
        <KeyForm />
        <CategoryForm />
        <LocationForm />
        <RowButton>
          <Button variant="light" edgeInsets="none" onClick={onClearForm}>
            {translate('search.clear')}
          </Button>
          <Button block edgeInsets="none" type="submit" style={OverrideStyles.buttonSpaceLeft}>
            {translate('search.search')}
          </Button>
        </RowButton>
      </Form>
    </Container>
  );
};

FormSearch.propTypes = {};

export default FormSearch;
