import {useSelector, shallowEqual} from 'react-redux';

export const useAuth = () => {
  const loggedInState = useSelector((state) => state.auth.auth.user, shallowEqual);
  const isCheckedAuth = useSelector((state) => state.auth.auth.isCheckedAuth, shallowEqual);

  return {
    loggedInState,
    isCheckedAuth,
  };
};
