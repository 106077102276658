import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0.75rem 0.25rem;
  &:hover {
    background-color: ${(props) => props.theme.lightHover100};
  }
`;

export const AvatarImg = styled.img`
  object-fit: cover;
  border-radius: 50%;
  ${(props) => props.size === 'small' && `width: 26px; height: 26px;`};
  ${(props) => props.size === 'default' && `width: 40px; height: 40px;`};
  ${(props) => props.size === 'large' && `width: 56px; height: 56px;`};
  ${(props) => props.size === 'fluid' && `width: 80px; height: 80px;`};
`;

export const AvatarBackdrop = styled.div`
  border-radius: 50%;
  background-color: ${(props) => props.theme.grayBackground};
  border: 1px solid ${(props) => props.theme.primary};
  ${(props) => props.size === 'small' && `min-width: 26px; min-height: 26px;`};
  ${(props) => props.size === 'default' && `min-width: 40px; min-height: 40px;`};
  ${(props) => props.size === 'large' && `min-width: 56px; min-height: 56px;`};
  ${(props) => props.size === 'fluid' && `min-width: 80px; min-height: 80px;`};
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.75rem;
`;

export const Title = styled.span`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SubTitle = styled.span`
  font-size: 12px;
`;

export const OverrideStyles = {
  body: {
    paddingLeft: '1rem',
  },
};
