import * as TYPES from './type';
import * as utils from './logic';
import * as stateUtils from '../../utils/deep-state';

const initLocationNearestState = {
  loading: false,
  locationNearest: null,
  error: null,
};

const initJobCatState = {
  popularJobCat: {
    loading: false,
    jobs: null,
    error: null,
    location: null,
    locationSpecified: null,
  },
  allJobCat: {
    loading: false,
    jobs: null,
    error: null,
  },
};

const initJobTimelineState = {
  loading: false,
  error: null,
  cacheToken: null,
  timeline: {
    newFeed: null,
    stackFeed: null,
  },
  loadingRefresh: false,
  pageIndex: 0,
};

const initJobLikedState = {
  loading: false,
  error: null,
  liked: {
    newFeed: null,
    stackFeed: [],
  },
  loadingRefresh: false,
  pageIndex: 0,
};

const initJobSearchHistoryState = {
  loading: false,
  error: null,
  history: null,
  loadingPin: false,
};

const initJobQueryState = {
  loading: false,
  pageIndex: 0,
  error: null,
  cacheToken: null,
  query: {
    newFeed: null,
    stackFeed: null,
  },
  queryNearby: {
    loading: false,
    visibleNearbyButton: false,
    sinkQueryNearby: false,
    newFeed: null,
    stackFeed: null,
    error: null,
    pageIndex: 0,
    cacheNearByToken: null,
  },
  form: {
    keyCompany: null,
    keyJob: null,
    jobcat: null,
    subjobcat: null,
    province: null,
    district: null,
    tag: null,
  },
  storeParams: null,
};

const initJobHashtagState = {
  loading: false,
  hashtag: {
    newFeed: null,
    stackFeed: null,
  },
  pageIndex: 0,
  error: null,
};

const iniJobIdState = {
  loading: false,
  job: null,
  error: null,
};

const initJobSeenAndInterestState = {
  loading: false,
  error: null,
  seen: null,
  interest: {
    stackInterest: null,
    stream: null,
  },
};

const initHiddenJobState = {
  loading: false,
  error: null,
  hiddenStream: null,
  hiddenList: {
    loading: false,
    error: null,
    newFeed: null,
    stackFeed: null,
    pageIndex: 0,
  },
};

const initJobAuthorState = {
  loading: false,
  error: null,
  author: {
    newFeed: null,
    stackFeed: [],
  },
  loadingRefresh: false,
  pageIndex: 0,
};

const initJobGroupState = {
  loading: false,
  error: null,
  meta: null,
  hotList: null,
  group: {
    newFeed: null,
    stackFeed: [],
  },
  loadingRefresh: false,
  pageIndex: 0,
  params: null,
  filter: null,
};

const initJobGroupListState = {
  loading: false,
  error: null,
  data: null,
};

export const locationNearestReducer = (state = initLocationNearestState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_LOCATION_NEAREST_REQ:
      return {...state, loading: true};
    case TYPES.SEARCH_LOCATION_NEAREST_SUCCESS:
      return {...state, loading: false, locationNearest: payload};
    case TYPES.SEARCH_LOCATION_NEAREST_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.SEARCH_CACHE_LOCATION_NEAREST_REQ:
      return {...state};
    case TYPES.SEARCH_CACHE_LOCATION_NEAREST_SUCCESS:
      return {...state, locationNearest: payload};
    default:
      return state;
  }
};

export const jobCatReducer = (state = initJobCatState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_POPULAR_JOB_CAT_REQ:
      return {...state, popularJobCat: {...state.popularJobCat, loading: true}};
    case TYPES.SEARCH_POPULAR_JOB_CAT_SUCCESS:
      return {
        ...state,
        popularJobCat: {
          ...state.popularJobCat,
          loading: false,
          jobs: utils.mapPopularJobCat({rawData: payload.data}),
          location: payload.province,
        },
      };
    case TYPES.SEARCH_POPULAR_JOB_CAT_FAIL:
      return {
        ...state,
        popularJobCat: {...state.popularJobCat, loading: false, error: payload},
      };
    case TYPES.SEARCH_ALL_JOB_CAT_REQ:
      return {...state, allJobCat: {...state.allJobCat, loading: true}};
    case TYPES.SEARCH_ALL_JOB_CAT_SUCCESS:
      return {
        ...state,
        allJobCat: {...state.allJobCat, loading: false, jobs: payload},
      };
    case TYPES.SEARCH_ALL_JOB_CAT_FAIL:
      return {
        ...state,
        allJobCat: {...state.allJobCat, loading: false, error: payload},
      };
    case TYPES.SEARCH_POPULAR_JOB_CAT_CLEAR:
      return {
        ...state,
        popularJobCat: {
          ...initJobCatState.popularJobCat,
        },
      };
    case TYPES.SEARCH_POPULAR_UPDATE_LOCATION_REQ:
      return {...state};
    case TYPES.SEARCH_POPULAR_UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        popularJobCat: {
          ...state.popularJobCat,
          location: payload,
        },
      };
    case TYPES.SEARCH_POPULAR_UPDATE_LOCATION_SPECIFIED:
      return {
        ...state,
        popularJobCat: {
          ...state.popularJobCat,
          locationSpecified: payload,
        },
      };
    default:
      return state;
  }
};

export const jobTimelineReducer = (state = initJobTimelineState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_JOB_TIMELINE_CACHE_TOKEN_REQ:
      return {...state};
    case TYPES.SEARCH_JOB_TIMELINE_CACHE_TOKEN_SUCCESS:
      return {
        ...state,
        cacheToken: payload,
      };
    case TYPES.SEARCH_JOB_TIMELINE_REQ:
      return {
        ...state,
        loading: true,
        pageIndex: state.pageIndex + 1,
        error: null,
      };
    case TYPES.SEARCH_JOB_TIMELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        timeline: {
          newFeed: payload,
          stackFeed: stateUtils.mergeArrayState({
            prevState: state.timeline.stackFeed,
            newState: payload?.results,
          }),
        },
      };
    case TYPES.SEARCH_JOB_TIMELINE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        pageIndex: state.pageIndex - 1,
        cacheToken: null,
      };
    case TYPES.SEARCH_JOB_TIMELINE_REFRESH_REQ:
      return {
        ...state,
        loadingRefresh: true,
        cacheToken: null,
        timeline: {
          newFeed: null,
          stackFeed: [],
        },
        pageIndex: 1,
        error: null,
      };
    case TYPES.SEARCH_JOB_TIMELINE_REFRESH_SUCCESS:
      return {
        ...state,
        loadingRefresh: false,
        error: null,
        timeline: {
          newFeed: payload,
          stackFeed: stateUtils.mergeArrayState({newState: payload?.results}),
        },
      };
    case TYPES.SEARCH_JOB_TIMELINE_REFRESH_FAIL:
      return {
        ...state,
        loadingRefresh: false,
        error: payload,
        pageIndex: 0,
        cacheToken: null,
      };
    case TYPES.SEARCH_JOB_USER_LIKE_REQ:
      return {
        ...state,
        timeline: {
          ...state.timeline,
        },
      };
    case TYPES.SEARCH_JOB_USER_LIKE_SUCCESS:
      return {
        loading: false,
        error: null,
        timeline: {
          ...state.timeline,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.timeline.stackFeed,
            newLike: payload?.job,
          }),
        },
      };
    case TYPES.SEARCH_JOB_USER_UNLIKE_REQ:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.timeline.stackFeed,
            unlike: payload?.refIds,
          }),
        },
      };
    case TYPES.SEARCH_JOB_USER_UNLIKE_SUCCESS:
      return {
        loading: false,
        error: null,
        timeline: {
          ...state.timeline,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.timeline.stackFeed,
            unlike: payload?.job,
          }),
        },
      };
    default:
      return state;
  }
};

export const jobLikedReducer = (state = initJobLikedState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_JOB_LIKED_REQ:
      return {
        ...state,
        loading: true,
        pageIndex: state.pageIndex + 1,
        error: null,
      };
    case TYPES.SEARCH_JOB_LIKED_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        liked: {
          newFeed: payload,
          stackFeed: stateUtils.mergeArrayState({
            prevState: state.liked.stackFeed,
            newState: payload?.results,
          }),
        },
      };
    case TYPES.SEARCH_JOB_LIKED_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        pageIndex: state.pageIndex - 1,
      };
    case TYPES.SEARCH_JOB_LIKED_REFRESH_REQ:
      return {
        ...state,
        loadingRefresh: true,
        liked: {
          newFeed: null,
          stackFeed: [],
        },
        pageIndex: 1,
        error: null,
      };
    case TYPES.SEARCH_JOB_LIKED_REFRESH_SUCCESS:
      return {
        ...state,
        loadingRefresh: false,
        error: null,
        liked: {
          newFeed: payload,
          stackFeed: stateUtils.mergeArrayState({newState: payload?.results}),
        },
      };
    case TYPES.SEARCH_JOB_LIKED_REFRESH_FAIL:
      return {
        ...state,
        loadingRefresh: false,
        error: payload,
        pageIndex: 0,
      };
    case TYPES.SEARCH_JOB_LIKED_UNLIKE_REQ:
      return {...state};
    case TYPES.SEARCH_JOB_LIKED_UNLIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        liked: {
          ...state.liked,
          stackFeed: utils.updateJobLikeStackFeed({
            stackFeed: state.liked.stackFeed,
            unlike: payload?.job,
          }),
        },
      };
    case TYPES.SEARCH_JOB_LIKED_UNLIKE_FAIL:
      return {...state, error: payload};
    default:
      return state;
  }
};

export const jobSearchHistoryReducer = (state = initJobSearchHistoryState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_JOB_HISTORY_REQ:
      return {...state, loading: true, error: null};
    case TYPES.SEARCH_JOB_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: payload,
      };
    case TYPES.SEARCH_JOB_HISTORY_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.SEARCH_JOB_PIN_REQ:
      return {...state, loadingPin: true, error: null};
    case TYPES.SEARCH_JOB_PIN_SUCCESS:
      return {
        ...state,
        loadingPin: false,
        history: utils.updateSearchHistory({
          history: state.history,
          newItem: payload,
        }),
      };
    case TYPES.SEARCH_JOB_PIN_FAIL:
      return {...state, loadingPin: false, error: payload};
    case TYPES.SEARCH_HISTORY_DELETE_REQ:
      return {...state, loadingPin: true, error: null};
    case TYPES.SEARCH_HISTORY_DELETE_SUCCESS:
      return {
        ...state,
        loadingPin: false,
        history: utils.deleteSearchHistory({
          history: state.history,
          deleteItems: payload,
        }),
      };
    case TYPES.SEARCH_HISTORY_DELETE_FAIL:
      return {...state, loadingPin: false, error: payload};
    default:
      return state;
  }
};

export const jobQueryReducer = (state = initJobQueryState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_JOB_QUERY_CACHE_TOKEN_REQ:
      return {...state};
    case TYPES.SEARCH_JOB_QUERY_CACHE_TOKEN_SUCCESS:
      return {...state, cacheToken: payload};
    case TYPES.SEARCH_JOB_QUERY_REQ:
      return {
        ...state,
        loading: true,
        pageIndex: state.pageIndex + 1,
        error: null,
      };
    case TYPES.SEARCH_JOB_QUERY_SUCCESS: {
      const _stackFeed = stateUtils.mergeArrayState({
        prevState: state.query.stackFeed,
        newState: payload?.results,
      });
      return {
        ...state,
        loading: false,
        error: null,
        query: {
          newFeed: payload,
          stackFeed: _stackFeed,
        },
        queryNearby: {
          ...state.queryNearby,
          visibleNearbyButton: utils.isFindNearbyJob({
            stackFeed: _stackFeed,
            resultCount: payload?.resultCount,
          }),
        },
      };
    }
    case TYPES.SEARCH_JOB_QUERY_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        cacheToken: null,
        pageIndex: state.pageIndex - 1,
      };
    case TYPES.SEARCH_JOB_QUERY_NEARBY_REQ:
      return {
        ...state,
        queryNearby: {
          ...state.queryNearby,
          visibleNearbyButton: false,
          sinkQueryNearby: true,
          loading: true,
          pageIndex: state.queryNearby.pageIndex + 1,
          error: null,
        },
      };
    case TYPES.SEARCH_JOB_QUERY_NEARBY_SUCCESS:
      return {
        ...state,
        queryNearby: {
          ...state.queryNearby,
          loading: false,
          newFeed: payload,
          stackFeed: stateUtils.mergeArrayState({
            prevState: state.queryNearby.stackFeed,
            newState: payload?.results,
          }),
          cacheNearByToken: state.queryNearby.cacheNearByToken || payload?.token,
        },
      };
    case TYPES.SEARCH_JOB_QUERY_NEARBY_FAIL:
      return {
        ...state,
        queryNearby: {
          ...state.queryNearby,
          loading: false,
          pageIndex: state.queryNearby.pageIndex - 1,
          error: payload,
        },
      };
    case TYPES.SEARCH_JOB_QUERY_CLEAR:
      return {
        ...state,
        loading: false,
        query: {
          ...initJobQueryState.query,
        },
        queryNearby: {
          ...initJobQueryState.queryNearby,
        },
        pageIndex: 0,
        cacheToken: null,
      };
    case TYPES.SEARCH_JOB_QUERY_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        query: {
          ...state.query,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.query.stackFeed,
            newLike: payload?.job,
          }),
        },
      };
    case TYPES.SEARCH_JOB_QUERY_UNLIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        query: {
          ...state.query,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.query.stackFeed,
            unlike: payload?.job,
          }),
        },
      };
    case TYPES.SEARCH_SAVE_FORM_REQ:
      return {...state};
    case TYPES.SEARCH_SAVE_FORM_SUCCESS:
      return {
        ...state,
        form: payload,
      };
    case TYPES.SEARCH_SAVE_FORM_CLEAR:
      return {
        ...state,
        form: {
          ...initJobQueryState.form,
        },
      };
    case TYPES.SEARCH_JOB_QUERY_STORE_PARAMS_REQ:
      return state;
    case TYPES.SEARCH_JOB_QUERY_STORE_PARAMS_SUCCESS:
      return {...state, storeParams: payload};
    case TYPES.SEARCH_JOB_QUERY_STORE_PARAMS_CLEAR:
      return {...state, storeParams: null};
    default:
      return state;
  }
};

export const jobHashtagReducer = (state = initJobHashtagState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_JOB_HASHTAG_REQ:
      return {
        ...state,
        loading: true,
        pageIndex: state.pageIndex + 1,
        error: null,
      };
    case TYPES.SEARCH_JOB_HASHTAG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        hashtag: {
          newFeed: payload,
          stackFeed: stateUtils.mergeArrayState({
            prevState: state.hashtag.stackFeed,
            newState: payload?.results,
          }),
        },
      };
    case TYPES.SEARCH_JOB_HASHTAG_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        pageIndex: state.pageIndex - 1,
      };
    case TYPES.SEARCH_JOB_HASHTAG_CLEAR:
      return {...initJobHashtagState};
    case TYPES.SEARCH_JOB_HASHTAG_LIKE_SUCCESS:
      return {
        loading: false,
        error: null,
        hashtag: {
          ...state.hashtag,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.hashtag.stackFeed,
            newLike: payload?.job,
          }),
        },
      };
    case TYPES.SEARCH_JOB_HASHTAG_UNLIKE_SUCCESS:
      return {
        loading: false,
        error: null,
        hashtag: {
          ...state.hashtag,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.hashtag.stackFeed,
            unlike: payload?.job,
          }),
        },
      };
    default:
      return state;
  }
};

export const jobIdReducer = (state = iniJobIdState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_JOB_ID_REQ:
      return {...state, loading: true};
    case TYPES.SEARCH_JOB_ID_SUCCESS:
      return {...state, loading: false, job: payload};
    case TYPES.SEARCH_JOB_ID_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.SEARCH_JOB_ID_CLEAR:
      return {...iniJobIdState};
    case TYPES.SEARCH_JOB_ID_LIKE_SUCCESS:
      return {
        ...state,
        job: utils.updateJobIdStream({job: state.job, newState: payload}),
      };
    case TYPES.SEARCH_JOB_ID_UNLIKE_SUCCESS:
      return {
        ...state,
        job: utils.updateJobIdStream({job: state.job, newState: payload}),
      };
    default:
      return state;
  }
};

export const jobSeenReducer = (state = initJobSeenAndInterestState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_SEEN_JOB_REQ:
      return {...state, loading: true};
    case TYPES.SEARCH_SEEN_JOB_SUCCESS:
      return {...state, loading: false, seen: payload};
    case TYPES.SEARCH_SEEN_JOB_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.SEARCH_INTEREST_DEBOUNCE_REQ:
      return {...state};
    case TYPES.SEARCH_INTEREST_DEBOUNCE_SUCCESS:
      return {
        ...state,
        interest: {
          ...state.interest,
          stackInterest: stateUtils.mergeObjectToArrayState({
            prevState: state.interest.stackInterest,
            newState: payload,
          }),
        },
      };
    case TYPES.SEARCH_INTEREST_DEBOUNCE_FAIL:
      return {...state, error: payload};
    case TYPES.SEARCH_INTEREST_STREAM_REQ:
      return {
        ...state,
        loading: true,
        interest: {...state.interest.stackInterest, stream: null},
      };
    case TYPES.SEARCH_INTEREST_STREAM_SUCCESS:
      return {
        ...state,
        loading: false,
        interest: {
          stackInterest: null,
          stream: payload,
        },
      };
    case TYPES.SEARCH_INTEREST_STREAM_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.SEARCH_INTEREST_STREAM_CANCEL:
      return {...state, loading: false};
    default:
      return state;
  }
};

export const jobHiddenJobReducer = (state = initHiddenJobState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_JOB_HIDDEN_LIST_REQ:
      return {
        ...state,
        hiddenList: {
          ...state.hiddenList,
          loading: true,
          pageIndex: state.hiddenList.pageIndex + 1,
          error: null,
        },
      };
    case TYPES.SEARCH_JOB_HIDDEN_LIST_SUCCESS:
      return {
        ...state,
        hiddenStream: utils.createHiddenJobStream({
          prevState: state.hiddenStream,
          newState: payload?.jobs,
        }),
        hiddenList: {
          ...state.hiddenList,
          loading: false,
          newFeed: payload,
          stackFeed: stateUtils.mergeArrayState({
            prevState: state.hiddenList.stackFeed,
            newState: payload?.jobs,
          }),
        },
      };
    case TYPES.SEARCH_JOB_HIDDEN_LIST_FAIL:
      return {
        ...state,
        hiddenList: {
          ...state.hiddenList,
          loading: false,
          error: payload,
          pageIndex: state.hiddenList.pageIndex - 1,
        },
      };

    case TYPES.SEARCH_JOB_HIDDEN_REQ:
      return {
        ...state,
        loading: true,
        hiddenStream: utils.updateHiddenJob({
          prevState: state.hiddenStream,
          newState: payload?.newState,
        }),
      };
    case TYPES.SEARCH_JOB_HIDDEN_SUCCESS:
      return {...state, loading: false};
    case TYPES.SEARCH_JOB_HIDDEN_FAIL:
      return {
        ...state,
        loading: false,
        error: payload?.error,
        hiddenStream: utils.updateHiddenJob({
          prevState: state.hiddenStream,
          newState: payload?.newState,
          action: 'undo',
        }),
      };

    case TYPES.SEARCH_JOB_UNHIDDEN_REQ:
      return {
        ...state,
        loading: false,
        hiddenStream: utils.updateHiddenJob({
          prevState: state.hiddenStream,
          newState: payload?.newState,
          action: 'undo',
        }),
      };
    case TYPES.SEARCH_JOB_UNHIDDEN_SUCCESS:
      return {...state};
    case TYPES.SEARCH_JOB_UNHIDDEN_FAIL:
      return {
        ...state,
        loading: false,
        error: payload?.error,
        hiddenStream: utils.updateHiddenJob({
          prevState: state.hiddenStream,
          newState: payload?.newState,
        }),
      };

    default:
      return state;
  }
};

export const jobAuthorReducer = (state = initJobAuthorState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_JOB_AUTHOR_REQ:
      return {
        ...state,
        loading: true,
        pageIndex: state.pageIndex + 1,
        error: null,
      };
    case TYPES.SEARCH_JOB_AUTHOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        author: {
          newFeed: payload,
          stackFeed: stateUtils.mergeArrayState({
            prevState: state.author.stackFeed,
            newState: payload?.results,
          }),
        },
      };
    case TYPES.SEARCH_JOB_AUTHOR_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        pageIndex: state.pageIndex - 1,
      };
    case TYPES.SEARCH_JOB_AUTHOR_LIKE_SUCCESS:
      return {
        loading: false,
        error: null,
        author: {
          ...state.author,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.author.stackFeed,
            newLike: payload?.job,
          }),
        },
      };
    case TYPES.SEARCH_JOB_AUTHOR_UNLIKE_SUCCESS:
      return {
        loading: false,
        error: null,
        author: {
          ...state.author,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.author.stackFeed,
            unlike: payload?.job,
          }),
        },
      };
    case TYPES.SEARCH_JOB_AUTHOR_CLEAR:
      return {...initJobAuthorState};
    default:
      return state;
  }
};

export const jobGroupReducer = (state = initJobGroupState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_JOB_GROUP_PARAMS_REQ:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SEARCH_JOB_GROUP_PARAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        params: payload,
      };
    case TYPES.SEARCH_JOB_GROUP_PARAMS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case TYPES.SEARCH_JOB_GROUP_REQ:
      return {
        ...state,
        loading: true,
        pageIndex: state.pageIndex + 1,
        error: null,
      };
    case TYPES.SEARCH_JOB_GROUP_SUCCESS: {
      const catchData = {
        meta: state.pageIndex === 1 ? payload.meta : state.meta,
        hotList: state.pageIndex === 1 ? payload.hotCompanies : state.hotList,
      };
      return {
        ...state,
        loading: false,
        error: null,
        group: {
          newFeed: payload,
          stackFeed: stateUtils.mergeArrayState({
            prevState: state.group.stackFeed,
            newState: payload?.results,
          }),
        },
        ...catchData,
      };
    }
    case TYPES.SEARCH_JOB_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        pageIndex: state.pageIndex - 1,
      };
    case TYPES.SEARCH_JOB_GROUP_REFRESH_REQ:
      return {
        ...state,
        loadingRefresh: true,
        group: {
          newFeed: null,
          stackFeed: [],
        },
        meta: null,
        pageIndex: 1,
        error: null,
      };
    case TYPES.SEARCH_JOB_GROUP_REFRESH_SUCCESS:
      return {
        ...state,
        loadingRefresh: false,
        error: null,
        group: {
          newFeed: payload,
          stackFeed: stateUtils.mergeArrayState({newState: payload?.results}),
        },
        meta: payload.meta,
      };
    case TYPES.SEARCH_JOB_GROUP_REFRESH_FAIL:
      return {
        ...state,
        loadingRefresh: false,
        error: payload,
        pageIndex: 0,
      };
    case TYPES.SEARCH_JOB_GROUP_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        group: {
          ...state.group,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.group.stackFeed,
            newLike: payload?.job,
          }),
        },
      };
    case TYPES.SEARCH_JOB_GROUP_UNLIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        group: {
          ...state.group,
          stackFeed: utils.updateLikeTimelineStackFeed({
            stackFeed: state.group.stackFeed,
            unlike: payload?.job,
          }),
        },
      };
    case TYPES.SEARCH_JOB_GROUP_UPDATE_FILTER_REQ:
      return {...state};
    case TYPES.SEARCH_JOB_GROUP_UPDATE_FILTER_SUCCESS:
      if (!payload) {
        return {
          ...state,
          pageIndex: 0,
          params: payload?.params,
          filter: payload?.filter,
          group: {
            ...initJobGroupState.group,
          },
        };
      }
      return {
        ...state,
        params: payload?.params,
        filter: payload?.filter,
      };
    case TYPES.SEARCH_JOB_GROUP_UPDATE_FILTER_CLEAR:
      return {
        ...state,
        params: null,
        filter: null,
      };
    case TYPES.SEARCH_JOB_GROUP_CLEAR:
      return {...initJobGroupState};
    case TYPES.SEARCH_JOB_GROUP_DATA_CLEAR:
      return {...initJobGroupState, filter: state.filter, params: state.params};
    default:
      return state;
  }
};

export const jobGroupListReducer = (state = initJobGroupListState, {type, payload}) => {
  switch (type) {
    case TYPES.SEARCH_JOB_GROUP_LIST_REQ:
      return {...state, loading: true};
    case TYPES.SEARCH_JOB_GROUP_LIST_SUCCESS:
      return {...state, loading: false, data: payload};
    case TYPES.SEARCH_JOB_GROUP_LIST_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.SEARCH_JOB_GROUP_LIST_CLEAR:
      return {...initJobGroupListState};
    default:
      return state;
  }
};
