import {regexNumber} from '../../../utils/regex';
import {translate} from '../../../res/language/translate';

export const validatePhoneNumber = ({phone}) => {
  if (!phone) {
    return translate('otp.errorPhone.undefined');
  }
  if (!regexNumber({input: phone})) {
    return translate('otp.errorPhone.regex');
  }
  return '';
};

export const validateOTPCode = ({otp}) => {
  if (!otp) {
    return translate('otp.errorOTPCode.undefined');
  }
  if (!regexNumber({input: otp}) || otp.length !== 6) {
    return translate('otp.errorOTPCode.regex');
  }
  return '';
};

const validatePassword = ({password}) => {
  if (!password) {
    return translate('register.errorPassword.undefined');
  }
  if (password.length < 6) {
    return translate('register.errorPassword.regex');
  }
  return '';
};

const validateConfirmPassword = ({password, confPassword}) => {
  if (!confPassword) {
    return translate('register.errorConfPassword.undefined');
  }
  if (password !== confPassword) {
    return translate('register.errorConfPassword.regex');
  }
  return '';
};

export const validateResetPasswordForm = ({form}) => {
  const validateResult = {};
  validateResult.otp = validateOTPCode({otp: form?.otp});
  validateResult.password = validatePassword({password: form?.password});
  validateResult.confPassword = validateConfirmPassword({
    password: form?.password,
    confPassword: form?.confPassword,
  });
  return validateResult;
};
