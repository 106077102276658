import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useImageViewer} from '../../../../lib/hooks/logic/useImageViewer';
import {ImageSingle, ImageBackdrop} from './styled-component';
import ImageVertical from './image-content-vertical';
import ImageHorizontal from './image-content-horizontal';
import * as typeDef from '../../../../utils/strong-types';
import * as imageUtils from '../../utils/image';
import * as TYPES from '../../../../lib/context/images/img-content-type';

const MAX_WIDTH = 680;

const PostImages = (props) => {
  const {postImages} = props;
  const {dispatchViewImage} = useImageViewer();
  const [layout, setLayout] = useState();

  useEffect(() => {
    if (!typeDef.isNotArrayLength(postImages)) {
      setLayout(() => imageUtils.createImageLayout({images: postImages}));
    }
  }, [postImages]);

  const onEventClickImage = (snapToIndex) => {
    const data = {
      data: postImages,
      snapToIndex,
    };
    dispatchViewImage(data);
  };

  if (!layout || typeDef.isNotArrayLength(postImages)) {
    return null;
  }

  if (imageUtils.isPoorImage({images: postImages})) {
    return null;
  }

  if (layout === TYPES.CONTENT_X1) {
    const {url, width} = postImages[0];
    const isFluid = width >= MAX_WIDTH;
    return (
      <ImageBackdrop>
        <ImageSingle alt="" src={url} fluid={isFluid} width={width} onClick={() => onEventClickImage(0)} />
      </ImageBackdrop>
    );
  }

  if (imageUtils.isVertical({type: layout})) {
    return <ImageVertical type={layout} images={postImages} />;
  }

  return <ImageHorizontal type={layout} images={postImages} />;
};

PostImages.propTypes = {
  postImages: PropTypes.array,
};

PostImages.defaultProps = {
  postImages: [],
};

export default PostImages;
