export const TYPE = {
  COMPLETE_PROFILE: 'complete_profile',
  RECOMMENDED_JOB: 'recommended_job',
  READ_RESUME: 'read_resume',
};

export const TEXT = {
  COMPLETE_PROFILE: 'ไปอัพเดทข้อมูลส่วนตัว',
  RECOMMENDED_JOB: 'ไปที่งาน',
  READ_RESUME: 'ไปที่งาน',
};
