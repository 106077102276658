import styled from 'styled-components';

export const Container = styled.div`
  border: 3px solid ${(props) => props.theme.tinGray};
  border-top: 3px solid ${(props) => props.theme.primary};
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  animation: spin 2s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

//   border: ${(props) => props.stroke}px solid ${(props) => props.theme.tinGray};
//   border-top: ${(props) => props.stroke}px solid ${(props) => props.theme.primary};
//   border-radius: 50%;
//   width: ${(props) => props.size}px;
//   height: ${(props) => props.size}px;
