import styled from 'styled-components';

export const Cropper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1500px;
  padding: 3rem 2rem;
  ${(props) => props.noPadding && `padding: 0`};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.edgeInsetsVertical && 'padding: 2rem 0'};
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => (props.reverse ? 'wrap-reverse' : 'wrap')};
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.color ? `${props.color}` : props.theme.grayBackground)};
  ${(props) => props.whiteBackground && `background-color: ${props.theme.white}`};
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex: 50%;
  position: relative;
  padding: 3rem 2em;
  justify-content: ${(props) => (props.noneAlign ? 'flex-start' : 'center')};
  align-items: center;
  ${(props) => props.column && 'flex-direction: column; align-items: start'};
  ${(props) => props.center && 'justify-content: center; align-items: center'};
  @media (max-width: 767px) {
    flex: 100%;
    ${(props) => props.nonePaddingBottom && 'padding-bottom: 0'};
    ${(props) => props.nonePaddingTop && 'padding-top: 2rem'};
    ${(props) => props.onlyWidth && 'display: none'};
  }
  @media (min-width: 2500px) {
    justify-content: ${(props) => props.floatStick};
    ${(props) => props.floatStick === 'flex-start' && 'padding-left: 4rem'};
    ${(props) => props.floatStick === 'flex-end' && 'padding-right: 4rem'};
  }
  ${(props) => props.noPadding && `padding: 0`};
`;

export const ItemPresentWrapper = styled.div`
  display: flex;
  flex: 50%;
  position: relative;
  padding: 1rem 2em;
  justify-content: ${(props) => (props.noneAlign ? 'flex-start' : 'center')};
  align-items: center;
  ${(props) => props.column && 'flex-direction: column; align-items: start'};
  ${(props) => props.center && 'justify-content: center; align-items: center'};
  @media (max-width: 767px) {
    flex: 100%;
    ${(props) => props.nonePaddingBottom && 'padding-bottom: 0'};
    ${(props) => props.nonePaddingTop && 'padding-top: 2rem'};
    ${(props) => props.onlyWidth && 'display: none'};
  }
  @media (min-width: 2500px) {
    justify-content: ${(props) => props.floatStick};
    ${(props) => props.floatStick === 'flex-start' && 'padding-left: 4rem'};
    ${(props) => props.floatStick === 'flex-end' && 'padding-right: 4rem'};
  }
  ${(props) => props.noPadding && `padding: 0`};
`;

export const MainSvgWrapper = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    max-width: calc(100vw - 80px);
  }
`;

export const DescriptionWrapper = styled.div`
  max-width: 767px;
`;

export const SectionWrapper = styled.div`
  padding: 4rem 0;
  ${(props) => props.background && `background-color: ${props.theme.grayBackground}`};
  ${(props) => props.noPadding && `padding: 0`};
  ${(props) => props.center && `display: flex; flex-direction: column; align-items: center`};
`;

export const StoreImg = styled.img`
  width: 4rem;
  margin: 0.7rem 0;
`;

export const BottomDescription = styled.div``;

export const CopyrightWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 2rem;
  ${(props) => props.noEdgeInsets && `padding: 1rem 2rem 0 2rem`};
`;

export const SearchWrapper = styled.div`
  width: 100%;
  max-width: 550px;
`;

export const RecommendWrapper = styled.div`
  max-width: 1024px;
`;

export const Head1 = styled.h1`
  @media (min-width: 100px) and (max-width: 389px) {
    font-size: 18px;
  }
  @media (min-width: 390px) and (max-width: 494px) {
    font-size: 24px;
  }
  @media (min-width: 495px) and (max-width: 505px) {
    font-size: 26px;
  }
  @media (min-width: 768px) and (max-width: 820px) {
    font-size: 22px;
  }
  @media (min-width: 821px) and (max-width: 871px) {
    font-size: 24px;
  }
  @media (min-width: 872px) and (max-width: 921px) {
    font-size: 26px;
  }
  @media (min-width: 922px) and (max-width: 1024px) {
    font-size: 28px;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
  margin-bottom: 1rem;
`;

export const TextTerm = styled.span`
  padding: 0 1rem;
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.primary};
  }
`;

export const Spacer = styled.div`
  padding: 1rem 0;
`;

export const OverrideStyle = {
  walkThrough: {
    marginTop: '2rem',
  },
  bottomPresent: {
    marginTop: '1rem',
  },
  searchDescription: {
    marginBottom: '1rem',
  },
  searchButton: {
    marginTop: '1rem',
  },
  version: {
    fontSize: '0.75rem',
  },
  marginRight: {marginRight: '1rem'},
  copyRight: {padding: '0 1rem'},
};
