import {translate} from '../../res/language/translate';
import * as typeDef from '../../utils/strong-types';

export const getButtonConfirmLabel = ({limitSelect, selectedOptions}) => {
  if (limitSelect < 2 || !selectedOptions) {
    return translate('global.confirm');
  }
  return `${translate('global.confirm')}  (${selectedOptions.length}/${limitSelect})`;
};

export const getLimitLabel = ({limitSelect, selectedOptions}) => {
  return `${selectedOptions.length}/${limitSelect}`;
};

export const checkIsSelectedById = ({id, selectedOptions}) => {
  if (!id || typeDef.isNotArrayLength(selectedOptions)) {
    return false;
  }
  const isSelected = selectedOptions.find((x) => x.id === id);
  return !!isSelected;
};
