import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import * as deepState from '../../utils/deep-state';
import * as typeDef from '../../utils/strong-types';

export const updateStreamNewLike = ({stackLike, newLike}) => {
  if (typeDef.isNullOrUndefined(newLike)) {
    return stackLike;
  }
  if (typeDef.isNotArrayLength(stackLike)) {
    if (typeDef.isArray(newLike)) {
      return [...newLike];
    }
    if (typeDef.isObject(newLike)) {
      return [{...newLike}];
    }
  }
  const copyStackLike = deepState.cloneDeepState({data: stackLike});
  if (typeDef.isNotArrayLength(copyStackLike)) {
    if (typeDef.isArray(newLike)) {
      return [...newLike];
    }
    if (typeDef.isObject(newLike)) {
      return [{...newLike}];
    }
  }
  if (typeDef.isArray(newLike)) {
    return uniqBy([...copyStackLike, ...newLike], (x) => x._id);
  }
  return uniqBy([...copyStackLike, {...newLike}], (x) => x._id);
};

export const updateStreamUnlike = ({stackLike, unlike}) => {
  if (typeDef.isNullOrUndefined(unlike)) {
    return stackLike;
  }
  if (typeDef.isNotArrayLength(stackLike)) {
    return [];
  }
  return filter(stackLike, (x) => x.job_id !== unlike.job_id);
};
