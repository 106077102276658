import * as TYPES from './type';

const initLocationState = {
  lat: null,
  long: null,
  error: null,
  loading: false,
};

const initAreaState = {
  provinces: {
    loading: false,
    data: null,
    error: null,
  },
  districts: {
    currentProvince: null,
    loading: false,
    data: null,
    popular: null,
    error: null,
  },
};

const initDimensions = {};

const initMainScrollConfig = {
  enabled: true,
};

const initEventListenerConfig = {
  enabledEnterListener: true,
};

const initContactShow = {
  contactList: [],
  contactText: '',
  type: null,
};

const initImageViewer = {
  snapToIndex: 0,
  data: null,
};

const initStackDistrict = {
  loading: false,
  data: {},
  error: {},
};

const initShowBottomLogin = {
  isShow: false,
  id: null,
};

const initReportPost = {
  isVisible: false,
  id: null,
};

const initRewritePost = {};

export const rewritePostReducer = (state = initRewritePost, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_REWRITE_POST_REQ:
      return state;
    case TYPES.CONTEXT_REWRITE_POST_SUCCESS:
      return {...(payload && payload)};
    case TYPES.CONTEXT_REWRITE_POST_CLEAR:
      return {...initRewritePost};
    default:
      return state;
  }
};

export const callbackPathReducer = (state = {}, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_ADD_CALLBACK_PATH_REQ:
      return state;
    case TYPES.CONTEXT_ADD_CALLBACK_PATH_SUCCESS:
      return {...state, ...payload};
    case TYPES.CONTEXT_ADD_CALLBACK_PATH_CLEAR:
      return {};
    default:
      return state;
  }
};

export const bottomLoginReducer = (state = initShowBottomLogin, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_SHOW_BOTTOM_LOGIN_REQ:
      return state;
    case TYPES.CONTEXT_SHOW_BOTTOM_LOGIN_SUCCESS:
      return {...state, ...payload};
    default:
      return state;
  }
};

export const stackDistrictsReducer = (state = initStackDistrict, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_STACK_DISTRICT_REQ:
      return {...state, loading: true, error: {...state.error, [payload.province]: null}};
    case TYPES.CONTEXT_STACK_DISTRICT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.province]: payload.districts,
        },
      };
    case TYPES.CONTEXT_STACK_DISTRICT_FAIL:
      return {
        ...state,
        loading: false,
        error: {
          ...state.error,
          [payload.province]: payload.error,
        },
      };
    case TYPES.CONTEXT_STACK_DISTRICT_CLEAR:
      return {...initStackDistrict};
    default:
      return state;
  }
};

export const imageViewerReducer = (state = initImageViewer, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_IMAGE_VIEWER_REQ:
      return state;
    case TYPES.CONTEXT_IMAGE_VIEWER_SUCCESS:
      return {
        ...state,
        snapToIndex: payload.snapToIndex,
        data: payload.data,
      };
    case TYPES.CONTEXT_IMAGE_VIEWER_CLEAR:
      return {
        ...initImageViewer,
      };
    default:
      return state;
  }
};

export const contactShowReducer = (state = initContactShow, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_CONTACT_SHOW_REQ:
      return state;
    case TYPES.CONTEXT_CONTACT_SHOW_SUCCESS:
      return {
        ...state,
        contactList: payload.contactList,
        contactText: payload.contactText,
        type: payload.type,
      };
    case TYPES.CONTEXT_CONTACT_SHOW_FAIL:
      return initContactShow;
    case TYPES.CONTEXT_CONTACT_SHOW_CLEAR:
      return {
        ...initContactShow,
      };
    default:
      return state;
  }
};

export const locationReducer = (state = initLocationState, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_LOCATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CONTEXT_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        lat: payload.latitude,
        long: payload.longitude,
      };
    case TYPES.CONTEXT_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const areaReducer = (state = initAreaState, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_PROVINCE_REQ:
      return {
        ...state,
        provinces: {
          ...state.provinces,
          loading: true,
        },
      };
    case TYPES.CONTEXT_PROVINCE_SUCCESS:
      return {
        ...state,
        provinces: {
          ...state.provinces,
          loading: false,
          data: payload,
        },
      };
    case TYPES.CONTEXT_PROVINCE_FAIL:
      return {
        ...state,
        provinces: {
          ...state.provinces,
          loading: false,
          error: payload,
        },
      };
    case TYPES.CONTEXT_DISTRICT_REQ:
      return {
        ...state,
        districts: {
          ...state.districts,
          loading: true,
        },
      };
    case TYPES.CONTEXT_DISTRICT_SUCCESS:
      return {
        ...state,
        districts: {
          ...state.districts,
          currentProvince: payload?.province,
          loading: false,
          data: payload?.districts,
          popular: payload?.popular,
        },
      };
    case TYPES.CONTEXT_DISTRICT_FAIL:
      return {
        ...state,
        districts: {
          ...state.districts,
          loading: false,
          error: payload,
        },
      };
    case TYPES.CONTEXT_DISTRICT_CLEAR:
      return {
        ...state,
        districts: {
          ...initAreaState.districts,
        },
      };
    default:
      return state;
  }
};

export const offsetReducer = (state = {offset: {}}, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_UPDATE_OFFSET_REQ:
    case TYPES.CONTEXT_UPDATE_OFFSET_SUCCESS:
      return {
        ...state,
        offset: {...state.offset, ...payload},
      };
    case TYPES.CONTEXT_UPDATE_OFFSET_FAIL:
    default:
      return state;
  }
};

export const filterReducer = (state = {visible: false}, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_VISIBLE_FILTER_REQ:
      return {...state};
    case TYPES.CONTEXT_VISIBLE_FILTER_SUCCESS:
      return {
        visible: payload,
      };
    default:
      return state;
  }
};

export const dimensionsReducer = (state = initDimensions, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_UPDATE_DIMENSIONS_REQ:
      return {...state};
    case TYPES.CONTEXT_UPDATE_DIMENSIONS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export const mainScrollConfigReducer = (state = initMainScrollConfig, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_MAIN_SCROLL_CONFIG_REQ:
      return {...state};
    case TYPES.CONTEXT_MAIN_SCROLL_CONFIG_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export const eventListenerConfigReducer = (state = initEventListenerConfig, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_EVENT_LISTENER_CONFIG_REQ:
      return {...state};
    case TYPES.CONTEXT_EVENT_LISTENER_CONFIG_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case TYPES.CONTEXT_EVENT_LISTENER_CONFIG_CLEAR:
      return {
        ...initEventListenerConfig,
      };
    default:
      return state;
  }
};

export const reportPostReducer = (state = initReportPost, {type, payload}) => {
  switch (type) {
    case TYPES.CONTEXT_SHOW_REPORT_POST_REQ:
      return {...state};
    case TYPES.CONTEXT_SHOW_REPORT_POST_SUCCESS:
      return {...state, isVisible: true, id: payload};
    case TYPES.CONTEXT_HIDE_REPORT_POST_REQ:
      return {...state};
    case TYPES.CONTEXT_HIDE_REPORT_POST_SUCCESS:
      return {...state, isVisible: false, id: null};
    default:
      return state;
  }
};
