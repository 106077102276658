import styled from 'styled-components';

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 680px;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${(props) => props.theme.white};
  @media (max-width: 680px) {
    width: calc(100vw);
    border-radius: 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 0.5rem`};
  }
  @media (min-width: 681px) {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 1rem`};
  }
`;

export const SecondaryText = styled.span`
  padding-left: 1rem;
  font-size: 12px;
  color: ${(props) => props.theme.textSecondary};
  ${(props) => props.noPadding && `padding-left: 0.5rem`};
`;

export const PrimaryText = styled.span`
  white-space: pre-line;
  padding-left: 1rem;
  font-weight: bold;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${(props) => props.noEdgeInsets && `padding-left: 0.5rem`};
`;

export const NotifyHeaderLeft = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const NotifyTextLess = styled.div`
  white-space: pre-line;
  font-size: 14px;
  font-family: 'Prompt Light';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const TextLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Prompt Light';
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.primary};
`;

export const OverrideStyles = {
  rowHeader: {
    width: '100%',
  },
};
