import * as TYPES from './type';

const initialState = {
  formRegister: null,
};

const initialVisibleState = {
  currentStep: 1,
};

const initInterestState = {
  formRegister: [],
};

const initialOTPState = {
  formRegister: null,
  formOTP: null,
  visibleStep: 1,
  registered: null,
};

const initRegisterId = {
  _id: null,
  loading: false,
  error: null,
};

export const registerIdReducer = (state = initRegisterId, {type, payload}) => {
  switch (type) {
    case TYPES.REGISTER_GENERATE_ID_REQ:
      return {...state, loading: true, error: null};
    case TYPES.REGISTER_GENERATE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
      };
    case TYPES.REGISTER_GENERATE_ID_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.REGISTER_GENERATE_ID_CLEAR:
      return {...initRegisterId};
    default:
      return state;
  }
};

export const registerStep1Reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case TYPES.REGISTER_STEP1_UPDATE_FORM_REQ:
      return {...state};
    case TYPES.REGISTER_STEP1_UPDATE_FORM_SUCCESS:
      return {...state, formRegister: {...state.formRegister, ...payload}};
    case TYPES.REGISTER_STEP1_UPDATE_FORM_FAIL:
      return {
        ...state,
        loading: false,
        formValidation: payload,
      };
    case TYPES.REGISTER_STEP1_CLEAR:
      return {...initialState};
    case TYPES.REGISTER_STEP1_VALIDATE_EMAIL_REQ:
      return {...state, loading: true};
    case TYPES.REGISTER_STEP1_VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        emailIsValidate: true,
      };
    case TYPES.REGISTER_STEP1_VALIDATE_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        formValidation: {
          ...state.formValidation,
          email: payload,
        },
        emailIsValidate: false,
      };
    case TYPES.REGISTER_STEP1_VALIDATE_EMAIL_CLEAR:
      return {
        ...state,
        loading: false,
        formValidation: {
          ...state.formValidation,
          email: undefined,
        },
        emailIsValidate: null,
      };
    default:
      return state;
  }
};

export const registerStep2Reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case TYPES.REGISTER_STEP2_UPDATE_FORM_REQ:
      return {...state, loading: true};
    case TYPES.REGISTER_STEP2_UPDATE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        formRegister: {
          ...state.formRegister,
          ...payload,
        },
      };
    case TYPES.REGISTER_STEP2_UPDATE_FORM_FAIL:
      return {
        ...state,
        loading: false,
        formValidation: state.formValidation ? {...state.formValidation, ...payload} : payload,
      };
    case TYPES.REGISTER_STEP2_CLEAR:
      return {...initialState};
    default:
      return state;
  }
};

export const registerVisibleStepReducer = (state = initialVisibleState, {type, payload}) => {
  switch (type) {
    case TYPES.REGISTER_VISIBLE_STEP_REQ:
      return {...state};
    case TYPES.REGISTER_VISIBLE_STEP_SUCCESS:
      return {...state, currentStep: payload};
    case TYPES.REGISTER_VISIBLE_STEP_FAIL:
      return {...state};
    default:
      return state;
  }
};

export const registerOTPReducer = (state = initialOTPState, {type, payload}) => {
  switch (type) {
    case TYPES.REGISTER_OTP_REQ:
      return {...state, loading: true};
    case TYPES.REGISTER_OTP_SUCCESS:
      return {...state, loading: false, formRegister: payload};
    case TYPES.REGISTER_OTP_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.REGISTER_OTP_REQUEST_REQ:
      return {...state, loading: true};
    case TYPES.REGISTER_OTP_REQUEST_SUCCESS:
      return {...state, loading: false, formOTP: payload, visibleStep: 2};
    case TYPES.REGISTER_OTP_REQUEST_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.REGISTER_OTP_RESEND_REQ:
      return {...state, loading: true};
    case TYPES.REGISTER_OTP_RESEND_SUCCESS:
      return {...state, loading: false};
    case TYPES.REGISTER_OTP_RESEND_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.REGISTER_OTP_CONFIRM_REQ:
      return {...state, loading: true};
    case TYPES.REGISTER_OTP_CONFIRM_SUCCESS:
      return {...state};
    case TYPES.REGISTER_OTP_CONFIRM_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.REGISTER_OTP_CHANGE_PHONE_REQ:
      return {...state, loading: true};
    case TYPES.REGISTER_OTP_CHANGE_PHONE_SUCCESS:
      return {...state, loading: false, visibleStep: 1};
    case TYPES.REGISTER_OTP_CHANGE_PHONE_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.REGISTER_OTP_STEP_CLEAR:
      return {...state, loading: false, visibleStep: 1};
    case TYPES.REGISTER_MANUAL_REQ: {
      return {...state, loading: true, error: null, registered: undefined};
    }
    case TYPES.REGISTER_MANUAL_SUCCESS: {
      return {...initialOTPState, loading: false, registered: payload};
    }
    case TYPES.REGISTER_MANUAL_FAIL: {
      return {...state, loading: false, registered: undefined, error: payload};
    }
    case TYPES.REGISTER_FACEBOOK_REQ: {
      return {...state, loading: true, error: null, registered: undefined};
    }
    case TYPES.REGISTER_FACEBOOK_SUCCESS: {
      return {...initialOTPState, loading: false, registered: payload};
    }
    case TYPES.REGISTER_FACEBOOK_FAIL: {
      return {...state, loading: false, registered: undefined, error: payload};
    }
    default:
      return state;
  }
};

export const registerInterestReducer = (state = initInterestState, {type, payload}) => {
  switch (type) {
    case TYPES.REGISTER_INTEREST_REQ:
      return {...state};
    case TYPES.REGISTER_INTEREST_SUCCESS:
      return {formRegister: payload};
    case TYPES.REGISTER_INTEREST_CLEAR:
      return {...initInterestState};
    default:
      return state;
  }
};
