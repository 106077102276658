import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useLandingPageDispatch} from '../../../../lib/hooks-logic/useLandingPage';
import {
  Wrapper,
  Container,
  FeedWrapper,
  AdContentWrapper,
  HeaderSmall,
  AdRowContent,
  CardSmallWrapper,
  CompanySmallImg,
  CompanySmallName,
  DividerInner,
  RowAd,
} from './styled-component';
import Responsive from '../../../../components/responsive';
import * as LAYOUT from '../layout';

const HotListView = (props) => {
  const {view, data} = props;
  const {formatMessage} = useIntl();
  const {dispatchUpdateHotListFilter, dispatchClearFormFilter} = useLandingPageDispatch();
  const paramsState = useSelector((state) => state.search.jobGroup.params);

  const onClickSearchHotCompany = (id) => {
    dispatchClearFormFilter();
    dispatchUpdateHotListFilter({...paramsState, author_id_arr: [id]});
  };

  if (!data) {
    return null;
  }

  if (view === 'feed') {
    return (
      <Responsive maxWidth={LAYOUT.FULL_CONTENT_BREAKPOINT - 1}>
        <FeedWrapper>
          <AdContentWrapper>
            <HeaderSmall>{formatMessage({defaultMessage: 'หางานกับบริษัทยอดนิยม'})}</HeaderSmall>
            <AdRowContent>
              {data.map((item, index) => (
                <CardSmallWrapper key={index.toString()} onClick={() => onClickSearchHotCompany(item.author_id)}>
                  <CompanySmallImg src={item.author_avatar?.url} alt="" />
                  <CompanySmallName>{item.author_name}</CompanySmallName>
                </CardSmallWrapper>
              ))}
            </AdRowContent>
          </AdContentWrapper>
          <DividerInner />
        </FeedWrapper>
      </Responsive>
    );
  }

  return (
    <Responsive minWidth={LAYOUT.FULL_CONTENT_BREAKPOINT}>
      <Wrapper>
        <Container>
          <HeaderSmall>{formatMessage({defaultMessage: 'หางานกับบริษัทยอดนิยม'})}</HeaderSmall>
          <RowAd>
            {data.map((item, index) => (
              <CardSmallWrapper key={index.toString()} onClick={() => onClickSearchHotCompany(item.author_id)}>
                <CompanySmallImg src={item.author_avatar?.url} alt="" />
                <CompanySmallName>{item.author_name}</CompanySmallName>
              </CardSmallWrapper>
            ))}
          </RowAd>
        </Container>
      </Wrapper>
    </Responsive>
  );
};

HotListView.propTypes = {
  data: PropTypes.array,
  view: PropTypes.oneOf(['feed', 'banner']),
};

HotListView.defaultProps = {
  view: 'banner',
  data: null,
};

export default HotListView;
