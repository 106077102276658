export const SEARCH_LOCATION_NEAREST_REQ = '@SEARCH/LOCATION_NEAREST_REQ';
export const SEARCH_LOCATION_NEAREST_SUCCESS = '@SEARCH/LOCATION_NEAREST_SUCCESS';
export const SEARCH_LOCATION_NEAREST_FAIL = '@SEARCH/LOCATION_NEAREST_FAIL';

export const SEARCH_CACHE_LOCATION_NEAREST_REQ = '@SEARCH/CACHE_LOCATION_NEAREST_REQ';
export const SEARCH_CACHE_LOCATION_NEAREST_SUCCESS = '@SEARCH/CACHE_LOCATION_NEAREST_SUCCESS';

export const SEARCH_POPULAR_JOB_CAT_REQ = '@SEARCH/POPULAR_JOB_CAT_REQ';
export const SEARCH_POPULAR_JOB_CAT_SUCCESS = '@SEARCH/POPULAR_JOB_CAT_SUCCESS';
export const SEARCH_POPULAR_JOB_CAT_FAIL = '@SEARCH/POPULAR_JOB_CAT_FAIL';
export const SEARCH_POPULAR_UPDATE_LOCATION_REQ = '@SEARCH/POPULAR_UPDATE_LOCATION_REQ';
export const SEARCH_POPULAR_UPDATE_LOCATION_SUCCESS = '@SEARCH/POPULAR_UPDATE_LOCATION_SUCCESS';
export const SEARCH_POPULAR_JOB_CAT_CLEAR = '@SEARCH/POPULAR_JOB_CAT_CLEAR';

export const SEARCH_ALL_JOB_CAT_REQ = '@SEARCH/ALL_JOB_CAT_REQ';
export const SEARCH_ALL_JOB_CAT_SUCCESS = '@SEARCH/ALL_JOB_CAT_SUCCESS';
export const SEARCH_ALL_JOB_CAT_FAIL = '@SEARCH/ALL_JOB_CAT_FAIL';

export const SEARCH_JOB_TIMELINE_REQ = '@SEARCH/JOB_TIMELINE_REQ';
export const SEARCH_JOB_TIMELINE_SUCCESS = '@SEARCH/JOB_TIMELINE_SUCCESS';
export const SEARCH_JOB_TIMELINE_FAIL = '@SEARCH/JOB_TIMELINE_FAIL';
export const SEARCH_JOB_TIMELINE_CACHE_TOKEN_REQ = '@SEARCH/JOB_TIMELINE_CACHE_TOKEN_REQ';
export const SEARCH_JOB_TIMELINE_CACHE_TOKEN_SUCCESS = '@SEARCH/JOB_TIMELINE_CACHE_TOKEN_SUCCESS';

export const SEARCH_JOB_TIMELINE_REFRESH_REQ = '@SEARCH/JOB_TIMELINE_REFRESH_REQ';
export const SEARCH_JOB_TIMELINE_REFRESH_SUCCESS = '@SEARCH/JOB_TIMELINE_REFRESH_SUCCESS';
export const SEARCH_JOB_TIMELINE_REFRESH_FAIL = '@SEARCH/JOB_TIMELINE_REFRESH_FAIL';

export const SEARCH_JOB_LIKED_REQ = '@SEARCH/JOB_LIKED_REQ';
export const SEARCH_JOB_LIKED_SUCCESS = '@SEARCH/JOB_LIKED_SUCCESS';
export const SEARCH_JOB_LIKED_FAIL = '@SEARCH/JOB_LIKED_FAIL';

export const SEARCH_JOB_LIKED_REFRESH_REQ = '@SEARCH/JOB_LIKED_REFRESH_REQ';
export const SEARCH_JOB_LIKED_REFRESH_SUCCESS = '@SEARCH/JOB_LIKED_REFRESH_SUCCESS';
export const SEARCH_JOB_LIKED_REFRESH_FAIL = '@SEARCH/JOB_LIKED_REFRESH_FAIL';

export const SEARCH_JOB_LIKED_CLEAR_REQ = '@SEARCH/JOB_LIKED_CLEAR_REQ';
export const SEARCH_JOB_LIKED_CLEAR_SUCCESS = '@SEARCH/JOB_LIKED_CLEAR_SUCCESS';

export const SEARCH_JOB_LIKED_UNLIKE_REQ = '@SEARCH/JOB_LIKED_UNLIKE_REQ';
export const SEARCH_JOB_LIKED_UNLIKE_SUCCESS = '@SEARCH/JOB_LIKED_UNLIKE_SUCCESS';
export const SEARCH_JOB_LIKED_UNLIKE_FAIL = '@SEARCH/JOB_LIKED_UNLIKE_FAIL';

export const SEARCH_JOB_USER_LIKE_REQ = '@SEARCH/JOB_USER_LIKE_REQ';
export const SEARCH_JOB_USER_LIKE_SUCCESS = '@SEARCH/JOB_USER_LIKE_SUCCESS';
export const SEARCH_JOB_HASHTAG_LIKE_SUCCESS = '@SEARCH/JOB_HASHTAG_LIKE_SUCCESS';

export const SEARCH_JOB_USER_UNLIKE_REQ = '@SEARCH/JOB_USER_UNLIKE_REQ';
export const SEARCH_JOB_USER_UNLIKE_SUCCESS = '@SEARCH/JOB_USER_UNLIKE_SUCCESS';
export const SEARCH_JOB_HASHTAG_UNLIKE_SUCCESS = '@SEARCH/JOB_HASHTAG_UNLIKE_SUCCESS';

export const SEARCH_JOB_HISTORY_REQ = '@SEARCH/JOB_HISTORY_REQ';
export const SEARCH_JOB_HISTORY_SUCCESS = '@SEARCH/JOB_HISTORY_SUCCESS';
export const SEARCH_JOB_HISTORY_FAIL = '@SEARCH/JOB_HISTORY_FAIL';

export const SEARCH_JOB_PIN_REQ = '@SEARCH/JOB_PIN_REQ';
export const SEARCH_JOB_PIN_SUCCESS = '@SEARCH/JOB_PIN_SUCCESS';
export const SEARCH_JOB_PIN_FAIL = '@SEARCH/JOB_PIN_FAIL';

export const SEARCH_HISTORY_DELETE_REQ = '@SEARCH/HISTORY_DELETE_REQ';
export const SEARCH_HISTORY_DELETE_SUCCESS = '@SEARCH/HISTORY_DELETE_SUCCESS';
export const SEARCH_HISTORY_DELETE_FAIL = '@SEARCH/HISTORY_DELETE_FAIL';

export const SEARCH_JOB_QUERY_REQ = '@SEARCH/JOB_QUERY_REQ';
export const SEARCH_JOB_QUERY_SUCCESS = '@SEARCH/JOB_QUERY_SUCCESS';
export const SEARCH_JOB_QUERY_FAIL = '@SEARCH/JOB_QUERY_FAIL';
export const SEARCH_JOB_QUERY_CLEAR = '@SEARCH/JOB_QUERY_CLEAR';
export const SEARCH_JOB_QUERY_LIKE_SUCCESS = '@SEARCH/JOB_QUERY_LIKE_SUCCESS';
export const SEARCH_JOB_QUERY_UNLIKE_SUCCESS = '@SEARCH/JOB_QUERY_UNLIKE_SUCCESS';
export const SEARCH_JOB_QUERY_NEARBY_REQ = '@SEARCH/JOB_QUERY_NEARBY_REQ';
export const SEARCH_JOB_QUERY_NEARBY_SUCCESS = '@SEARCH/JOB_QUERY_NEARBY_SUCCESS';
export const SEARCH_JOB_QUERY_NEARBY_FAIL = '@SEARCH/JOB_QUERY_NEARBY_FAIL';
export const SEARCH_JOB_QUERY_CACHE_TOKEN_REQ = '@SEARCH/JOB_QUERY_CACHE_TOKEN_REQ';
export const SEARCH_JOB_QUERY_CACHE_TOKEN_SUCCESS = '@SEARCH/JOB_QUERY_CACHE_TOKEN_SUCCESS';
export const SEARCH_JOB_QUERY_STORE_PARAMS_REQ = '@SEARCH/JOB_QUERY_STORE_PARAMS_REQ';
export const SEARCH_JOB_QUERY_STORE_PARAMS_SUCCESS = '@SEARCH/JOB_QUERY_STORE_PARAMS_SUCCESS';
export const SEARCH_JOB_QUERY_STORE_PARAMS_CLEAR = '@SEARCH/JOB_QUERY_STORE_PARAMS_CLEAR';

export const SEARCH_JOB_HASHTAG_REQ = '@SEARCH/JOB_HASHTAG_REQ';
export const SEARCH_JOB_HASHTAG_SUCCESS = '@SEARCH/JOB_HASHTAG_SUCCESS';
export const SEARCH_JOB_HASHTAG_FAIL = '@SEARCH/JOB_HASHTAG_FAIL';
export const SEARCH_JOB_HASHTAG_CLEAR = '@SEARCH/JOB_HASHTAG_CLEAR';

export const SEARCH_JOB_ID_REQ = '@SEARCH/JOB_ID_REQ';
export const SEARCH_JOB_ID_SUCCESS = '@SEARCH/JOB_ID_SUCCESS';
export const SEARCH_JOB_ID_FAIL = '@SEARCH/JOB_ID_FAIL';
export const SEARCH_JOB_ID_CLEAR = '@SEARCH/JOB_ID_CLEAR';
export const SEARCH_JOB_ID_LIKE_SUCCESS = '@SEARCH/JOB_ID_LIKE_SUCCESS';
export const SEARCH_JOB_ID_UNLIKE_SUCCESS = '@SEARCH/JOB_ID_UNLIKE_SUCCESS';

export const SEARCH_SAVE_FORM_REQ = '@SEARCH/SAVE_FORM_REQ';
export const SEARCH_SAVE_FORM_SUCCESS = '@SEARCH/SAVE_FORM_SUCCESS';
export const SEARCH_SAVE_FORM_CLEAR = '@SEARCH/SAVE_FORM_CLEAR';

export const SEARCH_SEEN_JOB_REQ = '@SEARCH/SEEN_JOB_REQ';
export const SEARCH_SEEN_JOB_SUCCESS = '@SEARCH/SEEN_JOB_SUCCESS';
export const SEARCH_SEEN_JOB_FAIL = '@SEARCH/SEEN_JOB_FAIL';

export const SEARCH_INTEREST_DEBOUNCE_REQ = '@SEARCH/INTEREST_DEBOUNCE_REQ';
export const SEARCH_INTEREST_DEBOUNCE_SUCCESS = '@SEARCH/INTEREST_DEBOUNCE_SUCCESS';
export const SEARCH_INTEREST_DEBOUNCE_FAIL = '@SEARCH/INTEREST_DEBOUNCE_FAIL';
export const SEARCH_INTEREST_DEBOUNCE_CLEAR = '@SEARCH/INTEREST_DEBOUNCE_CLEAR';

export const SEARCH_INTEREST_STREAM_REQ = '@SEARCH/INTEREST_STREAM_REQ';
export const SEARCH_INTEREST_STREAM_SUCCESS = '@SEARCH/INTEREST_STREAM_SUCCESS';
export const SEARCH_INTEREST_STREAM_FAIL = '@SEARCH/INTEREST_STREAM_FAIL';
export const SEARCH_INTEREST_STREAM_CANCEL = '@SEARCH/INTEREST_STREAM_CANCEL';

export const SEARCH_JOB_HIDDEN_LIST_REQ = '@SEARCH/JOB_HIDDEN_LIST_REQ';
export const SEARCH_JOB_HIDDEN_LIST_SUCCESS = '@SEARCH/JOB_HIDDEN_LIST_SUCCESS';
export const SEARCH_JOB_HIDDEN_LIST_FAIL = '@SEARCH/JOB_HIDDEN_LIST_FAIL';

export const SEARCH_JOB_HIDDEN_REQ = '@SEARCH/JOB_HIDDEN_REQ';
export const SEARCH_JOB_HIDDEN_SUCCESS = '@SEARCH/JOB_HIDDEN_SUCCESS';
export const SEARCH_JOB_HIDDEN_FAIL = '@SEARCH/JOB_HIDDEN_FAIL';

export const SEARCH_JOB_UNHIDDEN_REQ = '@SEARCH/JOB_UNHIDDEN_REQ';
export const SEARCH_JOB_UNHIDDEN_SUCCESS = '@SEARCH/JOB_UNHIDDEN_SUCCESS';
export const SEARCH_JOB_UNHIDDEN_FAIL = '@SEARCH/JOB_UNHIDDEN_FAIL';

export const SEARCH_JOB_AUTHOR_REQ = '@SEARCH/JOB_AUTHOR_REQ';
export const SEARCH_JOB_AUTHOR_SUCCESS = '@SEARCH/JOB_AUTHOR_SUCCESS';
export const SEARCH_JOB_AUTHOR_FAIL = '@SEARCH/JOB_AUTHOR_FAIL';
export const SEARCH_JOB_AUTHOR_CLEAR = '@SEARCH/JOB_AUTHOR_CLEAR';
export const SEARCH_JOB_AUTHOR_LIKE_SUCCESS = '@SEARCH/JOB_AUTHOR_LIKE_SUCCESS';
export const SEARCH_JOB_AUTHOR_UNLIKE_SUCCESS = '@SEARCH/JOB_AUTHOR_UNLIKE_SUCCESS';

export const SEARCH_JOB_GROUP_PARAMS_REQ = '@SEARCH/JOB_GROUP_PARAMS_REQ';
export const SEARCH_JOB_GROUP_PARAMS_SUCCESS = '@SEARCH/JOB_GROUP_PARAMS_SUCCESS';
export const SEARCH_JOB_GROUP_PARAMS_FAIL = '@SEARCH/JOB_GROUP_PARAMS_FAIL';
export const SEARCH_JOB_GROUP_REQ = '@SEARCH/JOB_GROUP_REQ';
export const SEARCH_JOB_GROUP_SUCCESS = '@SEARCH/JOB_GROUP_SUCCESS';
export const SEARCH_JOB_GROUP_FAIL = '@SEARCH/JOB_GROUP_FAIL';
export const SEARCH_JOB_GROUP_REFRESH_REQ = '@SEARCH/JOB_GROUP_REFRESH_REQ';
export const SEARCH_JOB_GROUP_REFRESH_SUCCESS = '@SEARCH/JOB_GROUP_REFRESH_SUCCESS';
export const SEARCH_JOB_GROUP_REFRESH_FAIL = '@SEARCH/JOB_GROUP_REFRESH_FAIL';
export const SEARCH_JOB_GROUP_LIKE_SUCCESS = '@SEARCH/JOB_GROUP_LIKE_SUCCESS';
export const SEARCH_JOB_GROUP_UNLIKE_SUCCESS = '@SEARCH/JOB_GROUP_UNLIKE_SUCCESS';
export const SEARCH_JOB_GROUP_UPDATE_FILTER_REQ = '@SEARCH/JOB_GROUP_UPDATE_FILTER_REQ';
export const SEARCH_JOB_GROUP_UPDATE_FILTER_SUCCESS = '@SEARCH/JOB_GROUP_UPDATE_FILTER_SUCCESS';
export const SEARCH_JOB_GROUP_UPDATE_FILTER_CLEAR = '@SEARCH/JOB_GROUP_UPDATE_FILTER_CLEAR';
export const SEARCH_JOB_GROUP_CLEAR = '@SEARCH/JOB_GROUP_CLEAR';
export const SEARCH_JOB_GROUP_DATA_CLEAR = '@SEARCH/JOB_GROUP_DATA_CLEAR';

export const SEARCH_JOB_GROUP_LIST_REQ = '@SEARCH/JOB_GROUP_LIST_REQ';
export const SEARCH_JOB_GROUP_LIST_SUCCESS = '@SEARCH/JOB_GROUP_LIST_SUCCESS';
export const SEARCH_JOB_GROUP_LIST_FAIL = '@SEARCH/JOB_GROUP_LIST_FAIL';
export const SEARCH_JOB_GROUP_LIST_CLEAR = '@SEARCH/JOB_GROUP_LIST_CLEAR';

export const SEARCH_POPULAR_UPDATE_LOCATION_SPECIFIED = '@SEARCH/SEARCH_POPULAR_UPDATE_LOCATION_SPECIFIED';
