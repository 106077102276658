import React from 'react';
import PropTypes from 'prop-types';
import {useImageViewer} from '../../../../lib/hooks/logic/useImageViewer';
import {
  ColumnWrapper,
  RowWrapper,
  ImageBackdrop,
  ImageBackdropHorizontal2,
  ImageBackdropHorizontal3,
  ImageSingle,
  ImageSingleHorizontal,
  ImageMoreHorizontal,
  MoreNumberText,
  MorePlusText,
} from './styled-component';
import * as TYPES from '../../../../lib/context/images/img-content-type';

const MAX_WIDTH = 680;

const ImageHorizontal = (props) => {
  const {images, type} = props;
  const {dispatchViewImage} = useImageViewer();

  const onEventClickImage = (snapToIndex) => {
    const data = {
      data: images,
      snapToIndex,
    };
    dispatchViewImage(data);
  };

  if (!Array.isArray(images) || !images.length) {
    return null;
  }
  if (type === TYPES.CONTENT_HORIZONTAL_1X2) {
    const {url: firstImg, width: firstWidth} = images[0];
    const isFirstFluid = firstWidth >= MAX_WIDTH;

    return (
      <ColumnWrapper>
        <ImageBackdrop>
          <ImageSingle src={firstImg} width={firstWidth} fluid={isFirstFluid} onClick={() => onEventClickImage(0)} />
        </ImageBackdrop>
        <RowWrapper>
          {images.map((item, index) => {
            if (index === 0) {
              return null;
            }
            const {url} = item;
            const key = index.toString();
            const isNoInsets = index > 0;
            return (
              <ImageBackdropHorizontal2 key={key} noEdgeInsets={isNoInsets}>
                <ImageSingleHorizontal src={url} onClick={() => onEventClickImage(index)} />
              </ImageBackdropHorizontal2>
            );
          })}
        </RowWrapper>
      </ColumnWrapper>
    );
  }

  if (type === TYPES.CONTENT_HORIZONTAL_1X3 || type === TYPES.CONTENT_HORIZONTAL_1XN) {
    const {url: firstImg, width: firstWidth} = images[0];
    const isMoreImage = type === TYPES.CONTENT_HORIZONTAL_1XN;
    const isFirstFluid = firstWidth >= MAX_WIDTH;

    return (
      <ColumnWrapper>
        <ImageBackdrop>
          <ImageSingle src={firstImg} width={firstWidth} fluid={isFirstFluid} onClick={() => onEventClickImage(0)} />
        </ImageBackdrop>
        <RowWrapper>
          {images.map((item, index) => {
            if (index === 0 || index > 3) {
              return null;
            }
            const {url} = item;
            const key = index.toString();
            const isNoInsets = index > 0;
            return (
              <ImageBackdropHorizontal3 key={key} noEdgeInsets={isNoInsets}>
                <ImageSingleHorizontal cover src={url} onClick={() => onEventClickImage(index)} />
                {index === 3 && isMoreImage && (
                  <ImageMoreHorizontal onClick={() => onEventClickImage(3)}>
                    <MorePlusText>+</MorePlusText>
                    <MoreNumberText>{`${images.length - 3}`}</MoreNumberText>
                  </ImageMoreHorizontal>
                )}
              </ImageBackdropHorizontal3>
            );
          })}
        </RowWrapper>
      </ColumnWrapper>
    );
  }

  if (type === TYPES.CONTENT_HORIZONTAL_2X3 || type === TYPES.CONTENT_HORIZONTAL_2XN) {
    const {url: firstImg} = images[0];
    const {url: secondImg} = images[1];
    const isMoreImages = type === TYPES.CONTENT_HORIZONTAL_2XN;

    return (
      <ColumnWrapper>
        <RowWrapper>
          <ImageBackdropHorizontal2>
            <ImageSingleHorizontal src={firstImg} onClick={() => onEventClickImage(0)} />
          </ImageBackdropHorizontal2>
          <ImageBackdropHorizontal2>
            <ImageSingleHorizontal src={secondImg} onClick={() => onEventClickImage(1)} />
          </ImageBackdropHorizontal2>
        </RowWrapper>
        <RowWrapper>
          {images.map((item, index) => {
            if (index === 0 || index === 1 || index > 4) {
              return null;
            }
            const {url} = item;
            const key = index.toString();
            const isNoInsets = index > 0;

            return (
              <ImageBackdropHorizontal3 key={key} noEdgeInsets={isNoInsets}>
                <ImageSingleHorizontal cover src={url} onClick={() => onEventClickImage(index)} />
                {isMoreImages && index === 4 && (
                  <ImageMoreHorizontal onClick={() => onEventClickImage(4)}>
                    <MorePlusText>+</MorePlusText>
                    <MoreNumberText>{`${images.length - 4}`}</MoreNumberText>
                  </ImageMoreHorizontal>
                )}
              </ImageBackdropHorizontal3>
            );
          })}
        </RowWrapper>
      </ColumnWrapper>
    );
  }

  return (
    <ColumnWrapper>
      {images.map((item, index) => {
        const {url, width} = item;
        return (
          <ImageBackdrop key={index.toString()} noEdgeInsets={index > 0}>
            <ImageSingle src={url} width={width} onClick={() => onEventClickImage(index)} />
          </ImageBackdrop>
        );
      })}
    </ColumnWrapper>
  );
};

ImageHorizontal.propTypes = {
  type: PropTypes.string,
  images: PropTypes.array,
};

ImageHorizontal.defaultProps = {
  type: null,
  images: [],
};

export default ImageHorizontal;
