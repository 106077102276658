import React from 'react';
import {translate} from '../../../../../res/language/translate';
import {useWebNavigator} from '../../../../../lib/hooks/useWebNavigator';
import {useJobPostContact} from '../../../../../lib/hooks/logic/useJobPost';
import {ContentWrapper, Title, ContactBody, ContactText, RowAction, Button, ButtonIcon, ButtonIconLabel, OverrideStyles} from './styled-component';
import ReactModal from '../../../../../components/react-modal';
import Row from '../../../../../components/row';
import Divider from '../../../../../components/divider';
import Icon from '../../../../../components/icon';
import SVGView from '../../../../../components/svg-img';
import SvgLineApp from '../../../../../res/assets/images/svg/icon_line.svg';
import * as ICONS from '../../../../../components/icon/constants';
import * as logic from './logic';
import * as mainLogic from '../logic';

const ModalContact = () => {
  const {
    contactState: {type, contactList, contactText},
    dispatchClearContactShow,
    dispatchNativeLink,
  } = useJobPostContact();
  const {isCanShareNative, copyTextToClipboard} = useWebNavigator();
  const isVisible = !!type;
  const isHasContactList = Array.isArray(contactList) && contactList.length;
  const isLine = type === 'line';

  const onEventClickBackdrop = () => {
    dispatchClearContactShow();
  };

  const onEventClickCopy = (item) => {
    copyTextToClipboard(item, 'คัดลอกเรียบร้อย');
    dispatchClearContactShow();
  };

  const onEventCallNative = (item) => {
    const data = {
      urlSchema: logic.getLinkingUrl(type),
      type,
      data: item,
    };
    dispatchNativeLink(data);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <ReactModal isVisible={isVisible} onRequestClose={onEventClickBackdrop}>
      <ContentWrapper>
        {contactText && (
          <>
            <Title>{contactText}</Title>
            <Divider style={OverrideStyles.titleDivider} />
          </>
        )}

        {isHasContactList &&
          contactList.map((item, index) => {
            const [text] = logic.getContactTextLabel(type, item);
            const iconName = logic.getIconName(type);
            return (
              <ContactBody key={index.toString()}>
                <Row content="space-between" wrap="nowrap" style={OverrideStyles.rowContact}>
                  <ContactText>{text}</ContactText>
                  <Row wrap="nowrap">
                    <ButtonIcon onClick={() => onEventClickCopy(item)}>
                      <Icon name={ICONS.COPY} color={OverrideStyles.iconAction} />
                      <ButtonIconLabel>คัดลอก</ButtonIconLabel>
                    </ButtonIcon>
                    {isCanShareNative && (
                      <ButtonIcon onClick={() => onEventCallNative(item)}>
                        {isLine && <SVGView inline src={SvgLineApp} height={20} width={20} inlineStyle={{fill: OverrideStyles.iconAction}} />}
                        {!isLine && <Icon name={iconName} color={OverrideStyles.iconAction} />}
                        <ButtonIconLabel>{mainLogic.getContactLabel(type)}</ButtonIconLabel>
                      </ButtonIcon>
                    )}
                  </Row>
                </Row>
              </ContactBody>
            );
          })}
        <RowAction>
          <Button singleButton onClick={onEventClickBackdrop}>
            {translate('global.close')}
          </Button>
        </RowAction>
      </ContentWrapper>
    </ReactModal>
  );
};

ModalContact.propTypes = {};

ModalContact.defaultProps = {};

export default ModalContact;
