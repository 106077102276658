import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import SectionHeader from '../header';
import {calTrainingCourse} from '../resume-info/logic';
import * as ICONS from '../../../../components/icon/constants';
import * as ROUTES from '../../../../routes/routes-name';

const OtherInfo = (props) => {
  const {userState} = props;

  const {formatMessage} = useIntl();

  if (!userState) {
    return null;
  }

  return (
    <>
      <SectionHeader
        route={ROUTES.ROUTE_EDIT_PROFILE_OTHER}
        iconName={ICONS.CERTIFICATE}
        title={formatMessage({defaultMessage: 'ข้อมูลการฝึกอบรม'})}
        score={calTrainingCourse(userState)}
        total={5}
      />
    </>
  );
};

OtherInfo.propTypes = {
  userState: PropTypes.object,
};

OtherInfo.defaultProps = {
  userState: null,
};

export default OtherInfo;
