import ImgPresent1 from '../../../res/assets/images/landing/present3.png';
import ImgPresent2 from '../../../res/assets/images/landing/present2.png';
import ImgPresent3 from '../../../res/assets/images/landing/present1.png';
import PresentResource from '../../../res/content/present';
import IconSvgLike from '../../../res/assets/images/svg/like-br.svg';
import IconSvgFind from '../../../res/assets/images/svg/job-search.svg';
import IconSvgCheck from '../../../res/assets/images/svg/file-check.svg';
import {translate} from '../../../res/language/translate';

const getPresentImage = (id) => {
  switch (id) {
    case 1:
      return ImgPresent1;
    case 2:
      return ImgPresent2;
    default:
      return ImgPresent3;
  }
};

export const createPresetData = () => {
  const presentData = PresentResource.map((element, index) => {
    return {
      ...element,
      image: getPresentImage(index),
    };
  });
  return presentData;
};

export const createTutorialStepData = () => {
  return [
    {
      source: IconSvgFind,
      title: translate('landing.findStep1.title'),
      description: translate('landing.findStep1.description'),
    },
    {
      source: IconSvgLike,
      title: translate('landing.findStep2.title'),
      description: translate('landing.findStep2.description'),
    },
    {
      source: IconSvgCheck,
      title: translate('landing.findStep3.title'),
      description: translate('landing.findStep3.description'),
    },
  ];
};

export const createBottomPresent = () => {
  return 'เราคือสตาร์ทอัพเพื่อสังคม (social enterprise) ที่ต้องการช่วยให้คนไทย ไม่ตกงาน และเจองานที่ใช่ โดยเฉพาะอย่างยิ่งในช่วง Covid 19 ที่งานหายาก กระจัดกระจายไปตามกลุ่มเฟซบุ๊คต่างๆ มันคงจะดีถ้ามีเทคโนโลยีที่ช่วยพาบริษัทต่างๆมาเจอคนหางานได้ง่าย และตอบโจทย์คนทั้งสองฝ่าย';
};
