import React from 'react';
import PropTypes from 'prop-types';
import FaqContent from '../../../res/content/faq';
import {Container, FaqWrapper, HeadWrapper, ContainerLoggedIn, FaqWrapperLoggedIn, OverrideStyles} from './styled-component';
import Accordion from '../../../components/accordion';
import Header from '../../../components/header';
import Divider from '../../../components/divider';
import {translate} from '../../../res/language/translate';

const FaqList = (props) => {
  const {isLoggedIn} = props;

  if (isLoggedIn) {
    return (
      <ContainerLoggedIn>
        <Header variant="h3" fitLine>
          {translate('landing.faq.title')}
        </Header>
        <Divider edgeInsets bold />
        <FaqWrapperLoggedIn>
          {FaqContent.map((item) => (
            <Accordion key={item.id} title={item.question} titleStyle={OverrideStyles.faqTitle}>
              {item.answer}
            </Accordion>
          ))}
        </FaqWrapperLoggedIn>
      </ContainerLoggedIn>
    );
  }

  return (
    <Container>
      <HeadWrapper>
        <Header variant="h2">{translate('landing.faq.title')}</Header>
      </HeadWrapper>
      <FaqWrapper>
        {FaqContent.map((item) => (
          <Accordion key={item.id} title={item.question}>
            {item.answer}
          </Accordion>
        ))}
      </FaqWrapper>
    </Container>
  );
};

FaqList.propTypes = {
  isLoggedIn: PropTypes.bool,
};

FaqList.defaultProps = {
  isLoggedIn: false,
};

export default FaqList;
