import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`;

export const RowIndex = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ColumnIndex = styled.div`
  flex: 1;
  padding-right: 1rem;
  min-width: 200px;
  ${(props) => props.flex && `flex: ${props.flex}`};
  @media (max-width: 663px) {
    padding-top: 2rem;
  }
`;

export const Divider = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.textBlack};
`;

export const BodyIndex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextIndex = styled.span`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const OverrideStyles = {
  headerCompany: {
    marginTop: '1rem',
  },
};
