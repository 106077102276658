import {takeEvery, takeLatest} from 'redux-saga/effects';
import * as TYPES from './type';
import * as contextSaga from './saga';

export function* watchContextGetLocation() {
  yield takeEvery(TYPES.CONTEXT_LOCATION_REQ, contextSaga.contextGetLocationAction);
}

export function* watchContextGetProvinces() {
  yield takeEvery(TYPES.CONTEXT_PROVINCE_REQ, contextSaga.contextGetProvincesAction);
}

export function* watchContextGetDistricts() {
  yield takeEvery(TYPES.CONTEXT_DISTRICT_REQ, contextSaga.contextGetDistrictsAction);
}

export function* watchContextUpdateOffset() {
  yield takeLatest(TYPES.CONTEXT_UPDATE_OFFSET_REQ, contextSaga.contextUpdateOffsetAction);
}

export function* watchContextSetVisibleFilter() {
  yield takeEvery(TYPES.CONTEXT_VISIBLE_FILTER_REQ, contextSaga.contextSetVisibleFilterAction);
}

export function* watchContextUpdateDimensions() {
  yield takeLatest(TYPES.CONTEXT_UPDATE_DIMENSIONS_REQ, contextSaga.contextUpdateDimensionsAction);
}

export function* watchContextMainScrollConfig() {
  yield takeLatest(TYPES.CONTEXT_MAIN_SCROLL_CONFIG_REQ, contextSaga.contextMainScrollConfigAction);
}

export function* watchContextEventListener() {
  yield takeLatest(TYPES.CONTEXT_EVENT_LISTENER_CONFIG_REQ, contextSaga.contextEventListenerAction);
}

export function* watchContextContactShow() {
  yield takeEvery(TYPES.CONTEXT_CONTACT_SHOW_REQ, contextSaga.contextContactShowAction);
}

export function* watchContextImageViewer() {
  yield takeEvery(TYPES.CONTEXT_IMAGE_VIEWER_REQ, contextSaga.contextImageViewerAction);
}

export function* watchContextStackDistrict() {
  yield takeEvery(TYPES.CONTEXT_STACK_DISTRICT_REQ, contextSaga.contextGetStackDistrictsAction);
}

export function* watchContextShowBottomLogin() {
  yield takeLatest(TYPES.CONTEXT_SHOW_BOTTOM_LOGIN_REQ, contextSaga.contextShowBottomLoginAction);
}

export function* watchContextAddCallbackPath() {
  yield takeEvery(TYPES.CONTEXT_ADD_CALLBACK_PATH_REQ, contextSaga.contextAddCallbackPathAction);
}

export function* watchContextShowReportPost() {
  yield takeEvery(TYPES.CONTEXT_SHOW_REPORT_POST_REQ, contextSaga.contextShowReportPostAction);
}

export function* watchContextHideReportPost() {
  yield takeEvery(TYPES.CONTEXT_HIDE_REPORT_POST_REQ, contextSaga.contextHideReportPostAction);
}

export function* watchContextStoreRewritePost() {
  yield takeEvery(TYPES.CONTEXT_REWRITE_POST_REQ, contextSaga.contextStoreRewritePost);
}
