import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {InputHidden, ButtonUpload, TextBtn, FileListWrapper, FileDisplay, TextFileName, ButtonSee, ButtonDel} from './styled-component';
import Link from '../../../../components/link';

const FILE_IMAGES_SUPPORT = ['image/jpeg', 'image/png', 'image/jpg'];
const FILE_SUPPORT = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  ...FILE_IMAGES_SUPPORT,
];

const FileUpload = (props) => {
  const {onChangeFile, fileList, onDeleteFile} = props;
  const nodeInputRef = useRef(null);

  const onEventRequestUpload = () => {
    nodeInputRef.current.click();
  };

  const getShortLink = (link) => {
    if (!link || typeof link !== 'string') {
      return '';
    }
    if (link.length < 30) {
      return link;
    }
    return `${link.substring(0, 30)}...`;
  };

  return (
    <>
      {Array.isArray(fileList) && (
        <FileListWrapper>
          {fileList.map((item, index) => (
            <FileDisplay key={index.toString()}>
              <TextFileName>{getShortLink(item.url)}</TextFileName>
              <Link newTab url={item.memoryUrl}>
                <ButtonSee onClick={() => {}}>
                  <TextBtn>ดูไฟล์</TextBtn>
                </ButtonSee>
              </Link>
              <ButtonDel onClick={() => onDeleteFile(index)}>
                <TextBtn white>ลบไฟล์นี้</TextBtn>
              </ButtonDel>
            </FileDisplay>
          ))}
        </FileListWrapper>
      )}
      <InputHidden
        name="file-upload"
        type="file"
        ref={nodeInputRef}
        accept={FILE_SUPPORT.join(',')}
        onChange={(event) => {
          onChangeFile(event);
          event.target.value = null;
        }}
      />
      {Array.isArray(fileList) && fileList?.length < 3 && (
        <ButtonUpload onClick={onEventRequestUpload}>
          <TextBtn white>อัพโหลดไฟล์</TextBtn>
        </ButtonUpload>
      )}
      {!Array.isArray(fileList) && (
        <ButtonUpload onClick={onEventRequestUpload}>
          <TextBtn white>อัพโหลดไฟล์</TextBtn>
        </ButtonUpload>
      )}
    </>
  );
};

FileUpload.propTypes = {
  fileList: PropTypes.array,
  onChangeFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  fileList: null,
};

export default FileUpload;
