import {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import debounce from 'lodash/debounce';
import * as contextActions from '../../../redux/context/action';

const TIME_COUNT = 120 * 1000;

export const useShowBottomLoginEffect = () => {
  const dispatch = useDispatch();
  const {isCheckedAuth: isCheckedAuthState, user: userState} = useSelector((state) => state.auth.auth, shallowEqual);
  const {isShow: isShowState, id: showIdState} = useSelector((state) => state.context.bottomLogin, shallowEqual);

  const onEventShowBottomLogin = (init = false) => {
    dispatch(contextActions.contextShowBottomLogin({isShow: true, init}));
  };

  const onEventHideBottomLogin = () => {
    dispatch(contextActions.contextShowBottomLogin({isShow: false, clear: true, id: null}));
  };

  const debounceShowBottomLogin = debounce(onEventShowBottomLogin, TIME_COUNT);

  useEffect(() => {
    onEventShowBottomLogin(true);
  }, []);

  useEffect(() => {
    if (isCheckedAuthState && userState && isShowState) {
      onEventHideBottomLogin();
    }
  }, [isCheckedAuthState, userState, isShowState]);

  useEffect(() => {
    if (!isShowState && showIdState) {
      debounceShowBottomLogin();
    }
  }, [isShowState, showIdState]);
};
