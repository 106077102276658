import {useEffect} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {translate} from '../../../res/language/translate';
import {setAuthToken, setCurrentUser} from '../../../utils/storage/storage';
import * as registerAction from '../../../redux/register/actions';
import * as alertActions from '../../../redux/alert/action';
import * as navigationServices from '../../../utils/navigation/navigation-services';
import * as ROUTES from '../../../routes/routes-name';
import * as strFunc from '../../../utils/string-func';
import * as contextRegister from '../../context/register';
import * as typeDef from '../../../utils/strong-types';

export const useOTP = () => {
  const dispatch = useDispatch();
  const phoneState = useSelector((state) => state.register.otp.formOTP?.tel, shallowEqual);
  const currentStepState = useSelector((state) => state.register.otp.visibleStep, shallowEqual);
  const formId = useSelector((state) => state.register.registerId, shallowEqual);
  const formGeneral = useSelector((state) => state.register.step1.formRegister, shallowEqual);
  const formInfo = useSelector((state) => state.register.step2.formRegister, shallowEqual);
  const formInterest = useSelector((state) => state.register.interest.formRegister, shallowEqual);
  const facebookState = useSelector((state) => state.auth.facebook.user, shallowEqual);
  const isLoadingState = useSelector((state) => state.register.otp.loading, shallowEqual) || false;

  const dispatchRequestOTP = (tel) => {
    if (!tel) {
      return;
    }
    const reqBody = {
      tel: strFunc.trimSafely(tel),
    };
    dispatch(registerAction.userRegisterOTPRequest({data: reqBody}));
  };

  const dispatchConfirmOTP = (otp, isAcceptTerm) => {
    if (!otp) {
      return;
    }
    const formOTP = {tel: phoneState, otp_number: otp};
    const formRegister = contextRegister.transformRegister({formId, formGeneral, formInfo, formInterest, formOTP, facebook: facebookState});
    formRegister.tel = phoneState;
    formRegister.is_tac_agreed = isAcceptTerm || false;
    const reqBody = {
      formOTP,
      formRegister,
    };
    dispatch(registerAction.userRegisterOTPConfirm({data: reqBody}));
  };

  const dispatchChangPhone = () => {
    dispatch(registerAction.userRegisterOTPChangePhoneNumber());
  };

  const dispatchLoginOther = () => {
    const alertData = {
      message: translate('register.cancelRegister'),
      positiveTitle: translate('global.no'),
      negativeTitle: translate('global.yes'),
      action: {
        positiveAction: () => dispatch(alertActions.alertRemoveAlert()),
        negativeAction: () => {
          dispatch(alertActions.alertRemoveAlert());
          dispatch(registerAction.userRegisterClearForm());
          navigationServices.navigateReplaceTo(ROUTES.ROUTE_LOGIN);
        },
      },
    };
    dispatch(alertActions.alertSetAlert({data: alertData}));
  };

  return {
    isLoadingState,
    phoneState,
    currentStepState,
    dispatchRequestOTP,
    dispatchConfirmOTP,
    dispatchChangPhone,
    dispatchLoginOther,
  };
};

export const useOTPEffect = () => {
  const dispatch = useDispatch();
  const callbackPath = useSelector((state) => state.context.callbackPath);
  const registeredState = useSelector((state) => state.register.otp.registered, shallowEqual);

  useEffect(() => {
    if (!typeDef.isNullOrUndefined(registeredState)) {
      setAuthToken(registeredState.data?.token);
      setCurrentUser(registeredState.data?._id);
      dispatch(registerAction.userRegisterClearForm());
      if (!callbackPath?.pathname) {
        navigationServices.navigateReplaceTo({pathname: ROUTES.ROUTE_HOME});
        return;
      }
      navigationServices.navigateReplaceTo({...callbackPath});
    }
  }, [registeredState, callbackPath, dispatch]);
};
