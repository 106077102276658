import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from './styled-component';

const DivWrapper = (props) => {
  const {children} = props;
  return <Wrapper>{children}</Wrapper>;
};

DivWrapper.propTypes = {
  children: PropTypes.any,
};
DivWrapper.defaultProps = {
  children: null,
};

export default DivWrapper;
