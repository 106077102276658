import styled from 'styled-components';

const BASE_HEIGHT = 32;
const MIN_HEIGHT = 26;

export const LogoContainer = styled.img`
  height: ${BASE_HEIGHT}px;
  ${(props) => props.ratio && `height: ${BASE_HEIGHT * props.ratio}px`};
  @media (max-width: 767px) {
    ${(props) => !props.fixed && `height: ${MIN_HEIGHT}px`};
    ${(props) => props.ratio && `height: ${MIN_HEIGHT * props.ratio}px`};
  }
`;

export const LogoContainerWithEvent = styled.img`
  cursor: pointer;
  height: ${BASE_HEIGHT}px;
  ${(props) => props.ratio && `height: ${BASE_HEIGHT * props.ratio}px`};
  @media (max-width: 767px) {
    ${(props) => !props.fixed && `height: ${MIN_HEIGHT}px`};
    ${(props) => props.ratio && `height: ${MIN_HEIGHT * props.ratio}px`};
  }
`;
