import React from 'react';
import {useIntl} from 'react-intl';
import {useAuth} from '../../../lib/hooks/logic/useAuth';
import MetaTag from '../../../components/meta-tag';
import Screen from '../../../views/screen';
import CookiesPolicyView from './views';

const CookiesPolicyScreen = () => {
  const {formatMessage} = useIntl();
  const {loggedInState, isCheckedAuth} = useAuth();

  if (!isCheckedAuth) {
    return (
      <>
        <MetaTag
          title={formatMessage({defaultMessage: 'นโยบายคุกกี้ - JobFinFin'})}
          description={formatMessage({defaultMessage: 'นโยบายคุกกี้ - JobFinFin'})}
        />
      </>
    );
  }

  if (loggedInState) {
    return (
      <>
        <MetaTag
          title={formatMessage({defaultMessage: 'นโยบายคุกกี้ - JobFinFin'})}
          description={formatMessage({defaultMessage: 'นโยบายคุกกี้ - JobFinFin'})}
        />
        <Screen isChildScreen type="single-fluid" title={formatMessage({defaultMessage: 'นโยบายคุกกี้'})}>
          <CookiesPolicyView isLoggedIn />
        </Screen>
      </>
    );
  }

  return (
    <>
      <MetaTag
        title={formatMessage({defaultMessage: 'นโยบายคุกกี้ - JobFinFin'})}
        description={formatMessage({defaultMessage: 'นโยบายคุกกี้ - JobFinFin'})}
      />
      <CookiesPolicyView isLoggedIn={false} />
    </>
  );
};

CookiesPolicyScreen.propTypes = {};

export default CookiesPolicyScreen;
