import {useEffect, useState} from 'react';
import isEqual from 'lodash/isEqual';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useSearchParamsEffect} from '../../../lib/hooks/useSearchParams';
import {JOB_PAGE_SIZE} from '../../../lib/context/pagination';
import * as searchActions from '../../../redux/search/action';
import * as typeDef from '../../../utils/strong-types';
import * as coreQuery from '../../../lib/context/filter';

export const useSearchQueryEffect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const storeParamsState = useSelector((state) => state.search.jobQuery.storeParams);
  const {
    loading: isLoadingState,
    query: {newFeed: newFeedState},
  } = useSelector((state) => state.search.jobQuery);
  const {searchParamsState} = useSearchParamsEffect();

  useEffect(() => {
    const isCanFetchInit = typeDef.isObject(searchParamsState) && !isLoadingState && navigator.onLine;
    const isStoreParams = !isEqual(searchParamsState, storeParamsState);
    if (isCanFetchInit && isStoreParams) {
      dispatch(searchActions.searchJobQueryStoreParams(searchParamsState));
      window.scrollTo(0, 0);
      dispatch(searchActions.searchQuery({clear: true}));
      const reqBody = {
        params: coreQuery.transformUrlParamsToQuery(searchParamsState),
        saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
        pageIndex: 0,
        pageSize: JOB_PAGE_SIZE,
      };
      dispatch(
        searchActions.searchQuery({
          data: reqBody,
          token: isLoggedInState.user,
          saveToken: true,
        }),
      );
    }
  }, [searchParamsState, storeParamsState, isLoadingState, newFeedState, isLoggedInState, dispatch]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP' || history.action === 'REPLACE') {
        dispatch(
          searchActions.searchQuery({
            clear: true,
          }),
        );
        dispatch(searchActions.searchJobQueryStoreParams({clear: true}));
      }
    };
  }, [history, dispatch]);
};

export const useSearchQueryState = () => {
  const [isEmptyDataState, setIsEmptyDataState] = useState(false);
  const [isCanFetchMoreState, setIsCanFetchMoreState] = useState(true);

  const {
    loading: isLoadingState,
    pageIndex: pageIndexState,
    cacheToken: cacheTokenState,
    query: {newFeed: newFeedState, stackFeed: stackFeedState},
  } = useSelector((state) => state.search.jobQuery);

  useEffect(() => {
    if (!isLoadingState && newFeedState) {
      if (newFeedState.resultCount === 0) {
        setIsEmptyDataState(true);
      } else {
        setIsEmptyDataState(false);
      }
    }
  }, [isLoadingState, newFeedState]);

  useEffect(() => {
    if (isCanFetchMoreState && newFeedState?.resultCount && Array.isArray(stackFeedState)) {
      if (stackFeedState.length >= newFeedState?.resultCount || typeDef.isNotArrayLength(newFeedState?.results)) {
        setIsCanFetchMoreState(false);
      }
    }
  }, [newFeedState, stackFeedState, isCanFetchMoreState]);

  return {
    isEmptyDataState,
    isLoadingState,
    pageIndexState,
    newFeedState,
    stackFeedState,
    isCanFetchMoreState,
    cacheTokenState,
  };
};

export const useSearchQueryDispatch = () => {
  const dispatch = useDispatch();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const {searchParamsState} = useSearchParamsEffect();

  const dispatchFetchSearchQuery = (pageIndex, cacheTokenState) => {
    if (!pageIndex) {
      return;
    }
    const reqBody = {
      params: coreQuery.transformUrlParamsToQuery(searchParamsState),
      saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
      pageIndex,
      pageSize: JOB_PAGE_SIZE,
    };
    if (cacheTokenState) {
      reqBody.token = cacheTokenState;
    }
    dispatch(
      searchActions.searchQuery({
        data: reqBody,
        token: isLoggedInState.user,
        saveToken: false,
      }),
    );
  };

  const dispatchFetchSearchQueryNearby = (pageIndex, cacheToken, nonFuzzyToken) => {
    const reqBody = {
      params: coreQuery.transformUrlParamsToQuery(searchParamsState),
      saveHistory: !typeDef.isNullOrUndefined(isLoggedInState.user),
      pageIndex,
      pageSize: JOB_PAGE_SIZE,
      token: cacheToken,
    };

    if (nonFuzzyToken) {
      reqBody.nonFuzzyToken = nonFuzzyToken;
    }
    dispatch(
      searchActions.searchQueryNearby({
        data: reqBody,
        token: isLoggedInState.user,
      }),
    );
  };

  return {
    dispatchFetchSearchQuery,
    dispatchFetchSearchQueryNearby,
  };
};
