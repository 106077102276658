import * as alert from '../../utils/components/alert/provider';
import * as snackbar from '../../utils/components/toast-notification/provider';
import {getErrorMessage} from './error-store';
import ENV from '../../env.json';

export const sagaAlertSuccess = (message) => {
  snackbar.setSnackbarMessage(message);
};

export const sagaAlertError = (message, manual = false) => {
  const {IS_PRODUCTION} = ENV.PUBLIC;
  const messageDisplay = getErrorMessage(message, !IS_PRODUCTION);
  if (manual) {
    alert.setAlertManualAction(message);
    return;
  }
  alert.setAlert(messageDisplay);
};
