/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';
import {Container} from './styled-component';
import SnapshotError from './snapshot';
import Button from '../../components/button';
import Header from '../../components/header';
import * as navigationServices from '../../utils/navigation/navigation-services';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  onEventRedirect = () => {
    navigationServices.windowNavigateTo({pathname: '/'});
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <SnapshotError size={250} />
          <Header variant="h2">เกิดข้อผิดพลาด!!!</Header>
          <Button onClick={this.onEventRedirect}>ไปที่ JobFinFin</Button>
        </Container>
      );
    }
    return this.props.children;
  }
}
