import lodashOrderBy from 'lodash/orderBy';
import * as typeDef from '../../../utils/strong-types';
import * as regexPattern from './regex';
import {shuffleBody} from './shuffle';

const MAX_CHA_LEN = 80;

const cleanText = (text) => {
  if (!typeDef.isString(text)) {
    return text;
  }
  const replacePattern = new RegExp(regexPattern.REMOVE_WORD_TH.join('|'));
  return text.replace(replacePattern, '');
};

const isContact = (text) => {
  const isEmail =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return isEmail.test(text) || /\d{9}/.test(text.replace(/[-\s]/g, ''));
};

const isUselessLine = (text) => {
  // horizontal line like "================="
  if (/(.)\1{9,}/.test(text.replace(/\s/g, ''))) return true;

  // broken link
  if (/https?:\S+\.\.\./.test(text)) return true;

  if (/ติดต่อ/.test(text) && text.length < 15) return true;

  return false;
};

const removeLineText = (listText) => {
  const removePattern = new RegExp(regexPattern.REMOVE_TH.join('|').concat('|').concat(regexPattern.REMOVE_EN.join('|')), 'i');
  const newListText = listText.filter((text) => !removePattern.test(text) && !isContact(text) && !isUselessLine(text));

  return newListText.map((str) => str.replace(/\s+/g, ' '));
};

export const splitHyperLinkTag = (text) => {
  if (!text) {
    return [];
  }
  const linkPattern = /(https?:\/\/[^\s]+)|(http?:\/\/[^\s]+)/g;
  return text.split(linkPattern).filter((x) => x !== undefined && x !== '');
};

export const isHyperLinkTag = (text) => {
  if (!text) {
    return false;
  }
  const linkPattern = /(https?:\/\/[^\s]+)|(http?:\/\/[^\s]+)/g;
  return typeDef.isArrayLength(text.match(linkPattern));
};

const cleanEndChar = (text) => {
  if (!text) {
    return text;
  }
  const endChar = text[text.length - 1];
  if (endChar === '-' || endChar === '*' || endChar === '/') {
    return text.substring(0, text.length - 1);
  }
  return text;
};

const resolveMaxCharacter = (text, isReturnArray) => {
  if (!text) {
    return text;
  }
  if (text.length <= MAX_CHA_LEN) {
    if (isReturnArray) {
      return [cleanEndChar(text)];
    }
    return cleanEndChar(text);
  }

  const sentence = text.substring(0, MAX_CHA_LEN);
  const nextWord = text.substring(MAX_CHA_LEN + 1, text.length);
  const breakEndIndex = nextWord.indexOf(' ');
  if (nextWord && breakEndIndex < 0) {
    const lastWordBreakIndex = sentence.lastIndexOf(' ');
    if (lastWordBreakIndex < 2) {
      let ans;
      const conjuntionsPattern = new RegExp(regexPattern.CONJUNCTIONS.join('|'), 'gim');
      if (conjuntionsPattern.test(text)) {
        const breakPointPattern = new RegExp('(.*)'.concat('(').concat(regexPattern.CONJUNCTIONS.join('|')).concat(')'));
        text.replace(breakPointPattern, (m, p1) => {
          ans = p1;
        });
      } else {
        ans = text;
      }
      if (isReturnArray) {
        return [cleanEndChar(ans)];
      }
      return cleanEndChar(ans);
    }
    const ans = sentence.substring(0, lastWordBreakIndex);
    if (isReturnArray) {
      return [cleanEndChar(ans)];
    }
    return cleanEndChar(ans);
  }

  if (breakEndIndex > 20) {
    const lastWordBreakIndex = sentence.lastIndexOf(' ');
    let ans = sentence.substring(0, lastWordBreakIndex);
    if (ans.length < 30) {
      ans = text.substring(0, sentence.length + breakEndIndex + 1);
    }
    if (isReturnArray) {
      return [cleanEndChar(ans)];
    }
    return cleanEndChar(ans);
  }

  let ans;
  if (text.length === MAX_CHA_LEN + 1 && /(ะ|า|ิ|ี|ื)/.test(text[text.length - 1])) ans = text;
  else ans = text.substring(0, sentence.length + breakEndIndex + 1);

  if (isReturnArray) {
    return [cleanEndChar(ans)];
  }
  return cleanEndChar(ans);
};

const resolveLineSingleParagraph = (data) => {
  if (typeDef.isNotArrayLength(data)) {
    return data;
  }
  const ans = resolveMaxCharacter(data[0], true);
  return ans;
};

const resolveLineMultiParagraph = (data) => {
  const dataByFilterLen = data.filter((a) => a.length >= 40);
  const dataByOrderLen = lodashOrderBy(data, (a) => a.length, 'desc');
  if (dataByFilterLen.length === 2 || dataByOrderLen.length === 2) {
    const lineData = dataByFilterLen.length === 2 ? lodashOrderBy(dataByFilterLen, (a) => a.length, 'desc') : dataByOrderLen;
    const ans = [];
    ans.push(resolveMaxCharacter(lineData[0], true)[0]);
    ans.push(resolveMaxCharacter(lineData[1], true)[0]);
    return ans;
  }
  const ans = [];
  const linesData = dataByFilterLen.length >= 2 ? lodashOrderBy(dataByFilterLen, (a) => a.length, 'desc') : dataByOrderLen;
  const groupData = [linesData[0], linesData[1]];
  groupData.forEach((item) => {
    const text = resolveMaxCharacter(item);
    ans.push(text);
  });
  return ans;
};

export const resolveParagraphLine = (data) => {
  if (!typeDef.isArrayLength(data)) {
    return data;
  }
  if (data.length === 1) {
    return resolveLineSingleParagraph(data);
  }
  return resolveLineMultiParagraph(data);
};

export const splitBody = (text, head, count, source) => {
  if (!typeDef.isString(text)) {
    return text;
  }
  if (source === 'company') return text;

  const textClean = cleanText(text);
  const textSplit = textClean.split('\n');
  if (typeDef.isNotArrayLength(textSplit)) {
    return textClean;
  }
  if (source === 'findgovjob' || source === 'owned_facebook') return removeLineText(textSplit);
  const resultText = resolveParagraphLine(shuffleBody(removeLineText(textSplit), head, count));
  return resultText;
};
