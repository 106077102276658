import React from 'react';
import {translate} from '../../res/language/translate';
import {useAuth} from '../../lib/hooks/logic/useAuth';
import {OverrideStyles} from './views/styled-component';
import MetaTag from '../../components/meta-tag';
import Screen from '../../views/screen';
import Header from '../../components/header';
import Divider from '../../components/divider';
import SettingList from './views/setting-list';

const SettingScreen = () => {
  const {isCheckedAuth} = useAuth();

  if (!isCheckedAuth) {
    return null;
  }

  return (
    <>
      <MetaTag title={translate('meta.setting')} description={translate('meta.setting')} />
      <Screen leftSidebar isChildScreen type="single-fluid">
        <Header variant="h3" fitLine>
          {translate('setting.privacy')}
        </Header>
        <Divider bold style={OverrideStyles.dividerTop} />
        <SettingList />
      </Screen>
    </>
  );
};

SettingScreen.propTypes = {};

export default SettingScreen;
