import * as typeDef from '../../../../utils/strong-types';
import {regexNoneSymbol} from '../../../../utils/regex';

export const validateAllRow = (listState) => {
  if (typeDef.isNotArrayLength(listState)) {
    return [true, null];
  }
  let isValidate = true;
  const errorList = listState.map((elem) => {
    const errorRow = {};
    if (!elem.level.value || elem.level.value === 'ไม่ระบุ') {
      errorRow.level = 'กรุณาระบุระดับการศึกษา';
      isValidate = false;
    }
    if (!elem.school) {
      errorRow.school = 'กรุณากรอกชื่อสถานศึกษา';
      isValidate = false;
    }
    if (elem.school && !regexNoneSymbol({input: elem.school})) {
      isValidate = false;
      errorRow.branch = 'ชื่อสถานศึกษาห้ามมีอักระพิเศษ';
    }
    if (!elem.year?.value) {
      errorRow.year = 'กรุณาระบุปีที่จบ';
      isValidate = false;
    }
    const grade = parseFloat(elem.grade);
    if (!typeDef.isNumber(grade) || grade > 4) {
      errorRow.grade = 'เกรดไม่ถูกต้อง';
      isValidate = false;
    }

    if (elem.level?.value !== 'ม.3' && elem.level?.value !== 'ม.6') {
      if (!elem.branch) {
        isValidate = false;
        errorRow.branch = 'กรุณาระบุสาขาที่จบ';
      }
      if (elem.branch && !regexNoneSymbol({input: elem.branch})) {
        isValidate = false;
        errorRow.branch = 'ชื่อสาขาห้ามมีอักระพิเศษ';
      }
    }
    if (elem.level?.value === 'ป.ตรี' || elem.level?.value === 'ป.โท' || elem.level?.value === 'ป.เอก') {
      if (!elem.faculty) {
        isValidate = false;
        errorRow.faculty = 'กรุณาระบุคณะที่จบ';
      }
      if (elem.faculty && !regexNoneSymbol({input: elem.faculty})) {
        isValidate = false;
        errorRow.faculty = 'ชื่อคณะห้ามมีอักระพิเศษ';
      }
    }
    return errorRow;
  });
  return [isValidate, errorList];
};
