import styled from 'styled-components';
import {lightTheme} from '../../../../../styles/themes';

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 400px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.white};
  max-height: calc(100vh - 20px) !important;
  overflow: auto;
  @media (max-width: 420px) {
    width: calc(100vw - 60px) !important;
  }
`;

export const Title = styled.p`
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  @media (max-width: 420px) {
    padding: 1rem;
  }
`;

export const ContactBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

export const ContactText = styled.span``;

export const ButtonIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  height: 55px;
  cursor: pointer;
`;

export const ButtonIconLabel = styled.span`
  font-size: 10px;
  color: ${(props) => props.theme.blueFade};
`;

export const RowAction = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.tinGray};
`;

export const Button = styled.button`
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Prompt Light';
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => (props.danger ? props.theme.danger : props.theme.primary)};
  border-bottom-right-radius: ${(props) => (!props.danger ? '6px' : '0px')};
  border-bottom-left-radius: ${(props) => (props.danger ? '6px' : '0px')};
  ${(props) => props.singleButton && `border-radius: 0 0 6px 6px`};
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const ButtonContactWrapper = styled.div`
  ${(props) => props.disabled && `cursor: inherit`};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 1rem;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.divider};
  ${(props) => props.noBorder && `border: none`};
  &:hover {
    ${(props) => !props.disabled && `background-color: ${props.theme.chipBackground};`};
  }
`;

export const ButtonContactText = styled.span`
  ${(props) => props.disabled && `color: ${props.theme.tinGray}`};
  font-size: 15px;
  padding-left: 0.5rem;
`;

export const OverrideStyles = {
  rowContact: {
    paddingBottom: '0.5rem',
  },
  iconAction: lightTheme.blueFade,
  titleDivider: {
    height: '1px',
    marginBottom: '1rem',
  },
};
