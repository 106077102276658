import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {useIntl} from 'react-intl';
import {useGetReduxState} from '../../lib/hooks-logic/useGetReduxState';
import {PopularWrapper, PopularRow, PopularContainer, PopularLabel, PopularItem} from './styled-component';
import {InputLabel} from '../../components/select/styled-component';
import SvgView from '../../components/svg-img';
import {lightTheme} from '../../styles/themes';
import * as logic from './logic';
import * as typeDef from '../../utils/strong-types';

const SelectPopular = (props) => {
  const {selectedPopular, onChange} = props;

  const {formatMessage} = useIntl();
  const {reduxState: popularListState} = useGetReduxState('context.area.districts.popular');
  const {reduxState: provinceState} = useGetReduxState('search.jobQuery.form.province');

  const onChangePopular = (item) => {
    onChange(item?.tag);
  };

  const getSvgColor = (isActive) => {
    if (isActive) {
      return lightTheme.white;
    }
    return lightTheme.black;
  };

  if (typeDef.isNotArrayLength(popularListState) || get(provinceState, '[0].value') === 'ทุกจังหวัด') {
    return null;
  }

  return (
    <PopularContainer>
      <InputLabel>{formatMessage({defaultMessage: 'ตัวเลือกเพิ่มเติม'})}</InputLabel>
      <PopularRow>
        {popularListState.map((item, index) => (
          <PopularItem key={index.toString()} onClick={() => onChangePopular(item)}>
            <PopularWrapper isActive={logic.checkIsActiveTag(selectedPopular, item.tag)}>
              <SvgView
                inline
                src={logic.getSvgByTag(item.name)}
                height={35}
                inlineStyle={{fill: getSvgColor(logic.checkIsActiveTag(selectedPopular, item.tag))}}
              />
            </PopularWrapper>
            <PopularLabel>{item.name}</PopularLabel>
          </PopularItem>
        ))}
      </PopularRow>
    </PopularContainer>
  );
};

SelectPopular.propTypes = {
  selectedPopular: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

SelectPopular.defaultProps = {
  selectedPopular: null,
};

export default SelectPopular;
