import React from 'react';
import PropTypes from 'prop-types';
import {NotifyTextLess, TextLink} from '../styled-component';
import Link from '../../../components/link';
import * as logic from '../logic';

const NotifyBody = (props) => {
  const {type, notifyContent, searchParams} = props;

  return (
    <>
      <NotifyTextLess>{notifyContent}</NotifyTextLess>
      <Link url={logic.getLink(type)} search={searchParams}>
        <TextLink>{logic.getLinkLabel(type)}</TextLink>
      </Link>
    </>
  );
};

NotifyBody.propTypes = {
  notifyContent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  searchParams: PropTypes.string.isRequired,
};

export default NotifyBody;
