import React from 'react';
import {CoverImg, CoverDummy} from './styled-component';
import {useCompanyProfileState} from '../../../lib/hooks/logic/useCompanyProfile';

const ImageCover = () => {
  const {metaGroupState, companyLoadingState} = useCompanyProfileState();
  if (companyLoadingState || !metaGroupState?.cover_image?.url) {
    return <CoverDummy />;
  }
  return <CoverImg src={metaGroupState?.cover_image?.url} />;
};

ImageCover.propTypes = {};

export default ImageCover;
