import {put, call} from 'redux-saga/effects';
import * as sagaAlert from '../utils/saga-alert';
import * as api from './api';
import * as TYPES from './types';

export function* reportJobCreateNewReportAction({payload}) {
  try {
    const response = yield call(api.reportCreateNewReport, payload?.data);
    yield put({
      type: TYPES.REPORT_JOB_CREATE_NEW_REPORT_SUCCESS,
      payload: response.data,
    });
    sagaAlert.sagaAlertSuccess('บันทึกการแจ้งรายงานความไม่เหมาะสมเรียบร้อย');
  } catch (error) {
    const responseError = error?.response?.data?.error;
    sagaAlert.sagaAlertError(responseError);
    yield put({
      type: TYPES.REPORT_JOB_CREATE_NEW_REPORT_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}
