import {translate} from '../../../../res/language/translate';

export const initForm = {
  firstName: null,
  lastName: null,
  birthDate: null,
  address: null,
  contactableTel: null,
};

export const initFullForm = {
  firstName: '',
  lastName: '',
  birthDate: '',
  gender: '',
  province: '',
  district: '',
  resume: '',
};

export const genderData = [
  {id: 1, label: translate('register.labelGenderMale'), value: 'm'},
  {id: 2, label: translate('register.labelGenderFemale'), value: 'f'},
];

export const maritalStatusData = [
  {label: 'โสด', value: 'โสด'},
  {label: 'สมรส', value: 'สมรส'},
  {label: 'หม้าย', value: 'หม้าย'},
  {label: 'หย่า', value: 'หย่า'},
  {label: 'แยกกันอยู่', value: 'แยกกันอยู่'},
];

export const disabilityData = [
  {label: 'ปกติ', value: 'ปกติ'},
  {label: 'พิการทางการเห็น', value: 'พิการทางการเห็น'},
  {label: 'พิการทางการได้ยิน', value: 'พิการทางการได้ยิน'},
  {
    label: 'พิการทางการเคลื่อนไหว',
    value: 'พิการทางการเคลื่อนไหว',
  },
  {label: 'พิการทางสติปัญญา', value: 'พิการทางสติปัญญา'},
];

export const drivingAbilityData = [
  {label: 'รถยนต์', value: 'รถยนต์'},
  {label: 'รถจักรยานยนต์', value: 'รถจักรยานยนต์'},
  {label: 'รถบรรทุก', value: 'รถบรรทุก'},
  {label: 'รถฟอร์คลิฟท์', value: 'รถฟอร์คลิฟท์'},
];

export const vehicleData = [
  {label: 'รถยนต์', value: 'รถยนต์'},
  {label: 'รถจักรยานยนต์', value: 'รถจักรยานยนต์'},
  {label: 'รถบรรทุก', value: 'รถบรรทุก'},
];

export const drivingLicenseData = [
  {label: ' รถยนต์ชั่วคราว', value: ' รถยนต์ชั่วคราว'},
  {label: ' รถยนต์สามล้อชั่วคราว', value: ' รถยนต์สามล้อชั่วคราว'},
  {
    label: ' รถจักรยานยนต์ส่วนบุคคลชั่วคราว',
    value: ' รถจักรยานยนต์ส่วนบุคคลชั่วคราว',
  },
  {label: ' รถยนต์ส่วนบุคคล', value: ' รถยนต์ส่วนบุคคล'},
  {label: ' รถยนต์สามล้อส่วนบุคคล', value: ' รถยนต์สามล้อส่วนบุคคล'},
  {label: ' รถยนต์สาธารณะ', value: ' รถยนต์สาธารณะ'},
  {label: ' รถยนต์สามล้อสาธารณะ', value: ' รถยนต์สามล้อสาธารณะ'},
  {
    label: ' รถจักรยานยนต์ส่วนบุคคล',
    value: ' รถจักรยานยนต์ส่วนบุคคล',
  },
  {label: ' รถจักรยานยนต์สาธารณะ', value: ' รถจักรยานยนต์สาธารณะ'},
  {label: ' รถชนิดอื่น', value: ' รถชนิดอื่น'},
  {label: ' รถระหว่างประเทศ', value: ' รถระหว่างประเทศ'},
];
