const colors = {
  global: {
    primary: '#1877F2',
    secondPrimary: '#2E3192',
    subSecondPrimary: '#F15A22',
    success: '#00A400',
    danger: '#F34F46',
    blueFade: '#426782',
    blueTine: '#5890FF',
    blue200: 'rgb(237,244,251)',
    black: '#000000',
    white: '#FFFFFF',
    whiteSmoke: '#FFFAFA',
    gray: '#898F9C',
    gray10: '#e8e8e8',
    gray20: 'rgba(34, 36, 39, 0.05)',
    gray45: '#8D949E',
    gray70: '#606770',
    gray80: '#444950',
    gray239: 'rgb(239,239,239)',
    grayMenu: 'rgb(101,103,107)',
    grayDisable: '#e8e8e8',
    grayBackground: '#F0F2F3',
    tinGray: 'rgba(195,197,203, 0.5)',
    chipBackground: '#EFEFEF',
    transparent: 'transparent',
    modalBackdrop: 'rgba(0,0,0,0.5)',
    modalIndicator: 'rgba(0,0,0,0.7)',
    modalRefresh: 'rgba(0,0,0,0.1)',
    imageBackdrop: '#131317',
    imageBackdropAlpha: 'rgba(19,19,23,0.08)',
    imageBackdropAlphaMore: 'rgba(19,19,23,0.5)',
    snackBar: 'rgba(19,19,23,0.98)',
    settingIcon: '#90A4AE',
    gradient1: '#BBD2C5',
    gradient2: '#536976',
    gradient3: '#292E49',
    blackAlpha: 'rgba(0,0,0,0.9)',
    primaryAlpha: 'rgba(24,199,242, 0.08)',
    tinGrayAlpha: 'rgba(195,197,203, 0.2)',
    placeholder: 'rgba(0,0,0,0.5)',
    divider: 'rgb(235,236,239)',
    dividerBold: 'rgb(170,170,170)',
    textSecondary: 'rgb(101,103,107)',
    lightHover100: 'rgba(139,139,139, 0.1)',
    textBlack: '#373536',
    yellow: '#FFD371',
  },
  button: {
    primary: '#1877F2',
    success: '#31A24C',
    danger: '#F02849',
    light: '#E4E6EB',
    transparent: 'transparent',
    textWhite: '#FFFFFF',
    textBlack: '#000000',
    primaryHover: '#156bd9',
    successHover: '#2c9144',
    dangerHover: '#d82441',
    lightHover100: 'rgba(139,139,139, 0.1)',
    lightHover: '#cdcfd3',
    transparentHover: '#cdcfd3',
  },
};

export default colors;
