import * as TYPES from './type';

const initCheckInfoState = {
  loading: false,
  error: null,
  isChecked: false,
  isPassed: false,
  requestJobId: null,
  lessField: true,
};

const initDraftEmailState = {
  loading: false,
  error: null,
  draft: null,
  loadingUpdate: false,
};

const initModalFormState = {
  visible: false,
  email: null,
  jobPosition: null,
  companyName: null,
  config: {
    neverShowModal: false,
    segmentActive: 1,
    useCustomForm: false,
    editForm: false,
  },
};

const initResumeState = {
  loading: false,
  error: null,
  template: null,
  loadingUpload: false,
  resume: null,
};

export const checkInfoReducer = (state = initCheckInfoState, {type, payload}) => {
  switch (type) {
    case TYPES.APPLY_JOB_CHECK_INFO_REQ:
      return {...state, loading: true, requestJobId: payload?._id, error: null};
    case TYPES.APPLY_JOB_CHECK_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        isChecked: true,
        isPassed: true,
      };
    case TYPES.APPLY_JOB_CHECK_INFO_FAIL:
      return {
        ...state,
        loading: false,
        isChecked: true,
        isPassed: false,
        lessField: payload.lessField,
        error: payload,
      };
    case TYPES.APPLY_JOB_CHECK_INFO_CLEAR:
      return {...initCheckInfoState};
    case TYPES.APPLY_JOB_CHECK_INFO_IS_LOGGED_OUT:
      return {...initCheckInfoState};
    default:
      return state;
  }
};

export const draftEmailReducer = (state = initDraftEmailState, {type, payload}) => {
  switch (type) {
    case TYPES.APPLY_JOB_GET_DRAFT_REQ:
      return {...state, loading: true, error: null};
    case TYPES.APPLY_JOB_GET_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        draft: payload,
      };
    case TYPES.APPLY_JOB_GET_DRAFT_FAIL:
      return {
        ...initDraftEmailState,
        error: payload,
      };
    case TYPES.APPLY_JOB_GET_DRAFT_CLEAR:
      return {...initDraftEmailState};
    case TYPES.APPLY_JOB_UPDATE_DRAFT_REQ:
      return {
        ...state,
        loadingUpdate: true,
        error: null,
      };
    case TYPES.APPLY_JOB_UPDATE_DRAFT_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        draft: payload,
      };
    case TYPES.APPLY_JOB_UPDATE_DRAFT_FAIL:
      return {
        ...state,
        loadingUpdate: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const modalFormReducer = (state = initModalFormState, {type, payload}) => {
  switch (type) {
    case TYPES.APPLY_JOB_REQUEST_MODAL_REQ:
      return {...state};
    case TYPES.APPLY_JOB_REQUEST_MODAL_SUCCESS:
      return {
        ...state,
        visible: true,
        email: payload.email,
        jobPosition: payload.jobPosition,
        companyName: payload.companyName,
      };
    case TYPES.APPLY_JOB_REQUEST_MODAL_UPDATE_CONFIG:
      return {
        ...state,
        config: {...payload.config},
      };
    case TYPES.APPLY_JOB_REQUEST_MODAL_CLEAR:
      return {...initModalFormState, config: {...state.config}};
    default:
      return state;
  }
};

export const resumeReducer = (state = initResumeState, {type, payload}) => {
  switch (type) {
    case TYPES.APPLY_JOB_RESUME_GET_TEMPLATE_REQ:
      return {...state, loading: true, error: null};
    case TYPES.APPLY_JOB_RESUME_GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        template: payload,
      };
    case TYPES.APPLY_JOB_RESUME_GET_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case TYPES.APPLY_JOB_RESUME_GET_TEMPLATE_CLEAR:
      return {
        ...initResumeState,
      };
    case TYPES.APPLY_JOB_RESUME_GENERATE_TEMPLATE_REQ:
      return {...state, loadingUpdate: true, error: null};
    case TYPES.APPLY_JOB_RESUME_GENERATE_TEMPLATE_SUCCESS:
      return {...state, loadingUpdate: false, resume: payload};
    case TYPES.APPLY_JOB_RESUME_GENERATE_TEMPLATE_FAIL:
      return {
        ...state,
        loadingUpdate: false,
        error: payload,
      };
    default:
      return state;
  }
};
