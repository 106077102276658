import React, {useState, useEffect} from 'react';
import Switch from 'react-switch';
import get from 'lodash/get';
import {useIntl} from 'react-intl';
import {useManageNotifyGetDataState, useManageNotifyUpdateDataState, useManageNotifyUpdateDispatch} from '../hooks/useManageNotify';
import {Container, Wrapper, OverrideStyles, MenuWrapper} from './styled-component';
import {SegmentWrapper, SegmentItem, SegmentText} from '../../notify/views/styled-component';
import Loading from '../../../components/loading';
import Header from '../../../components/header';
import Button from '../../../components/button';
import Row from '../../../components/row';
import Text from '../../../components/text';
import Divider from '../../../components/divider';
import Spacer from '../../../components/spacer';
import {SCHEDULE} from '../../../lib/context/notify';

const ManageNotify = () => {
  const {formatMessage} = useIntl();
  const dataState = useManageNotifyGetDataState();
  const uploadState = useManageNotifyUpdateDataState();
  const {dispatchUpdateNotify} = useManageNotifyUpdateDispatch();
  const [formState, setFormState] = useState();

  useEffect(() => {
    if (dataState?.dataState && !formState) {
      setFormState({...dataState.dataState});
    }
  }, [dataState.dataState, formState]);

  const onEventUpdateSetting = (key, value) => {
    if (!formState) {
      return;
    }
    const newForm = {...formState};
    newForm[key] = value;
    setFormState(newForm);
  };

  const onSubmit = () => {
    if (!formState) {
      return;
    }
    const reqBody = {
      notification: {
        search_params: formState.search_params,
        is_read_resume_notify: formState.is_read_resume_notify,
        is_recommended_job_notify: formState.is_recommended_job_notify,
        is_email_notify: formState.is_email_notify,
        frequency: formState.frequency,
      },
      token: formState.manage_token,
      user_notification_id: formState._id,
    };
    dispatchUpdateNotify(reqBody);
  };

  return (
    <>
      {(formState || uploadState.isLoadingUpdateState) && <Loading />}
      <Container>
        <Wrapper>
          {(!formState || dataState.isLoadingState) && <Loading type="data" />}
          {!dataState.isLoadingState && formState && (
            <>
              <Header variant="h3">{formatMessage({defaultMessage: 'จัดการการแจ้งเตือนทางอีเมล'})}</Header>
              <MenuWrapper>
                <Row content="space-between" style={OverrideStyles.row}>
                  <Text>{formatMessage({defaultMessage: 'แจ้งเตือนทางอีเมล'})}</Text>
                  <Switch
                    onColor={OverrideStyles.switch.active}
                    onHandleColor={OverrideStyles.switch.deActive}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => onEventUpdateSetting('is_email_notify', !get(formState, 'is_email_notify'))}
                    checked={get(formState, 'is_email_notify', false)}
                  />
                </Row>
                <Divider edgeInsets />
                <Row content="space-between" wrap="nowrap">
                  <Text>{formatMessage({defaultMessage: 'การแจ้งเตือน'})}</Text>
                  <SegmentWrapper>
                    <SegmentItem isActive={get(formState, 'frequency') === 1} onClick={() => onEventUpdateSetting('frequency', SCHEDULE.day)}>
                      <SegmentText isActive={get(formState, 'frequency') === 1}>{formatMessage({defaultMessage: 'รายวัน'})}</SegmentText>
                    </SegmentItem>
                    <SegmentItem isActive={get(formState, 'frequency') === 7} onClick={() => onEventUpdateSetting('frequency', SCHEDULE.week)}>
                      <SegmentText isActive={get(formState, 'frequency') === 7}>{formatMessage({defaultMessage: 'รายสัปดาห์'})}</SegmentText>
                    </SegmentItem>
                  </SegmentWrapper>
                </Row>
                <Divider edgeInsets />
                <Spacer height={32} />
                <Button block onClick={onSubmit}>
                  {formatMessage({defaultMessage: 'บันทึกข้อมูล'})}
                </Button>
              </MenuWrapper>
            </>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

ManageNotify.propTypes = {};

export default ManageNotify;
