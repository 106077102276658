import * as ICONS from '../../../../components/icon/constants';
import * as ROUTES from '../../../../routes/routes-name';

export const createMenus = () => [
  {
    id: 'change-password',
    route: ROUTES.ROUTE_USER_CHANGE_PASSWORD,
    iconName: ICONS.PASSWORD_FILL,
    title: 'เปลี่ยนรหัสผ่าน',
  },
  {
    id: 'general',
    route: ROUTES.ROUTE_EDIT_PROFILE_GENERAL,
    iconName: ICONS.USER,
    title: 'ข้อมูลส่วนตัว',
  },
  {
    id: 'interest',
    route: ROUTES.ROUTE_EDIT_PROFILE_INTEREST,
    iconName: ICONS.BOOKMARK_STAR,
    title: 'ลักษณะงานที่สนใจ',
  },
  {
    id: 'work',
    route: ROUTES.ROUTE_EDIT_PROFILE_WORKS,
    iconName: ICONS.BAG_BUSINESS,
    title: 'ประวัติการทำงาน',
  },
  {
    id: 'education',
    route: ROUTES.ROUTE_EDIT_PROFILE_EDUCATION,
    iconName: ICONS.EDUCATION,
    title: 'ประวัติการศึกษา',
  },
  {
    id: 'skills',
    route: ROUTES.ROUTE_EDIT_PROFILE_SKILL,
    iconName: ICONS.ACHIEVEMENT,
    title: 'ทักษะและความสำเร็จ',
  },
  {
    id: 'certificate',
    route: ROUTES.ROUTE_EDIT_PROFILE_OTHER,
    iconName: ICONS.CERTIFICATE,
    title: 'ข้อมูลการฝึกอบรม',
  },
];
