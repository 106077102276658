/* eslint-disable import/no-cycle */
import {all} from 'redux-saga/effects';
import * as authWatch from './auth/watch';
import * as registerWatch from './register/watch';
import * as alertWatch from './alert/watch';
import * as contextWatch from './context/watch';
import * as searchWatch from './search/watch';
import * as userWatch from './user/watch';
import * as notifyWatch from './notify/watch';
import * as snackbarWatch from './snackbar/watch';
import * as likeWatch from './like/watch';
import * as applyJobWatch from './apply-job/watch';
import * as hotCompanyWatch from './hot-company/watch';
import * as listApplyJobWatch from './list-apply-job/watch';
import * as applyJob2Watch from './apply-job2/watch';
import * as companyDetailWatch from './company-profile/watch';
import * as seoIndexWatch from './seo-index/watch';
import * as trackEventWatch from './track-event/watch';
import * as reportJobWatch from './report-job/watch';

export default function* rootSaga() {
  yield all([
    hotCompanyWatch.watchFetchHotCompany(),
    snackbarWatch.watchSnackbarSetMessage(),
    snackbarWatch.watchSnackbarRemoveMessage(),
    snackbarWatch.watchSnackbarClearMessage(),
    alertWatch.watchAlertSetAlert(),
    alertWatch.watchAlertRemoveAlert(),
    authWatch.watchFirebaseAnonymousLogin(),
    authWatch.watchFirebaseAnonymousLogout(),
    authWatch.watchAuthCurrent(),
    authWatch.watchAuthLogout(),
    authWatch.watchUserLoginManual(),
    authWatch.watchUserLoginFacebook(),
    authWatch.watchUserLoginFromRegister(),
    authWatch.watchUserForgotPasswordRequestCode(),
    authWatch.watchUserForgotPasswordCheckCode(),
    authWatch.watchUserForgotPasswordSetPassword(),
    authWatch.watchUserForgotPasswordChangePhone(),
    authWatch.watchUserAuthorize(),
    authWatch.watchUserOTPCancelInvoke(),
    authWatch.watchUserForgotPasswordClear(),
    authWatch.watchAuthUpdateCurrent(),
    registerWatch.watchUserGenerateId(),
    registerWatch.watchUserRegisterStep1(),
    registerWatch.watchUserRegisterStep1ValidateEmail(),
    registerWatch.watchUserRegisterStep2(),
    registerWatch.watchUserRegisterUpdateVisibleStep(),
    registerWatch.watchUserRegisterNextStep(),
    registerWatch.watchUserRegisterOTP(),
    registerWatch.watchUserRegisterOTPResend(),
    registerWatch.watchUserRegisterOTPRequest(),
    registerWatch.watchUserRegisterOTPConfirm(),
    registerWatch.watchUserRegisterOTPChangePhoneNumber(),
    registerWatch.watchUserRegisterManual(),
    registerWatch.watchUserRegisterFacebook(),
    registerWatch.watchUserRegisterClearForm(),
    registerWatch.watchUserRegisterInterest(),
    contextWatch.watchContextGetLocation(),
    contextWatch.watchContextGetProvinces(),
    contextWatch.watchContextGetDistricts(),
    contextWatch.watchContextUpdateOffset(),
    contextWatch.watchContextSetVisibleFilter(),
    contextWatch.watchContextUpdateDimensions(),
    contextWatch.watchContextMainScrollConfig(),
    contextWatch.watchContextEventListener(),
    contextWatch.watchContextContactShow(),
    contextWatch.watchContextImageViewer(),
    contextWatch.watchContextStackDistrict(),
    contextWatch.watchContextShowBottomLogin(),
    contextWatch.watchContextAddCallbackPath(),
    contextWatch.watchContextShowReportPost(),
    contextWatch.watchContextHideReportPost(),
    contextWatch.watchContextStoreRewritePost(),
    userWatch.watchUserShortProfile(),
    userWatch.watchUserDetailProfile(),
    userWatch.watchClearUser(),
    userWatch.watchUserManualUploadResume(),
    userWatch.watchEditUserProfile(),
    userWatch.watchUserChangePassword(),
    userWatch.watchUserAutoGenerateResume(),
    userWatch.watchUserAcceptTerm(),
    searchWatch.watchSearchGetLocation(),
    searchWatch.watchSearchCacheLocation(),
    searchWatch.watchSearchGetPopularJobCat(),
    searchWatch.watchSearchPopularUpdateLocation(),
    searchWatch.watchSearchGetAllJobCat(),
    searchWatch.watchSearchJobTimeline(),
    searchWatch.watchSearchJobTimelineRefresh(),
    searchWatch.watchSearchJobLiked(),
    searchWatch.watchSearchJobLikedRefresh(),
    searchWatch.watchSearchHistory(),
    searchWatch.watchSearchHistoryPin(),
    searchWatch.watchSearchHistoryDelete(),
    searchWatch.watchSearchQuery(),
    searchWatch.watchSearchQueryNearby(),
    searchWatch.watchSearchHashtag(),
    searchWatch.watchSearchId(),
    searchWatch.watchSearchSaveForm(),
    searchWatch.watchSearchSeenJob(),
    searchWatch.watchSearchInterestJob(),
    searchWatch.watchSearchInterestDebounce(),
    searchWatch.watchSearchGetHiddenJob(),
    searchWatch.watchSearchHiddenJob(),
    searchWatch.watchSearchUnHiddenJob(),
    searchWatch.watchSearchJobAuthor(),
    searchWatch.watchSearchJobGroupGetParams(),
    searchWatch.watchSearchJobGroup(),
    searchWatch.watchSearchJobGroupRefresh(),
    searchWatch.watchSearchJobGroupUpdateFilter(),
    searchWatch.watchSearchJobGroupList(),
    searchWatch.watchSearchJobQueryStoreParams(),
    notifyWatch.watchNotifyGetSettingNotification(),
    notifyWatch.watchNotifyUpdateSettingNotification(),
    notifyWatch.watchNotifyGetCountNewNotification(),
    notifyWatch.watchNotifyGetListNotification(),
    notifyWatch.watchNotifyUpdateNotificationSetting(),
    notifyWatch.watchNotifySettingModal(),
    notifyWatch.watchNotifySaveInterestedJobForm(),
    notifyWatch.watchNotifyUpdateInterestedJob(),
    notifyWatch.watchNotifyGetDataBySignedUrl(),
    notifyWatch.watchNotifyUpdateDataBySignedUrl(),
    likeWatch.watchLikeNewLike(),
    likeWatch.watchLikeUnLike(),
    likeWatch.watchLikeUpdateStream(),
    applyJobWatch.watchApplyJobCheckInfo(),
    applyJobWatch.watchApplyJobGetDraftEmail(),
    applyJobWatch.watchApplyJobUpdateDraftEmail(),
    applyJobWatch.watchApplyJobRequestModal(),
    applyJobWatch.watchApplyJobGetResumeTemplate(),
    applyJobWatch.watchApplyJobGenerateResume(),
    listApplyJobWatch.watchFetchApplyJob(),
    listApplyJobWatch.watchFetchApplyJobLike(),
    listApplyJobWatch.watchFetchApplyJobUnlike(),
    applyJob2Watch.watchFetchJobDraft(),
    applyJob2Watch.watchUploadResume(),
    applyJob2Watch.watchSaveDraft(),
    applyJob2Watch.watchSaveData(),
    applyJob2Watch.watchSendMail(),
    companyDetailWatch.watchCompanyDetail(),
    seoIndexWatch.watchUpdateJobIndexData(),
    seoIndexWatch.watchUpdateCompanyIndexData(),
    seoIndexWatch.watchUpdateCompanyIndexKey(),
    trackEventWatch.watchTrackInteractJobStreamStack(),
    trackEventWatch.watchTrackInteractJobUpdateStack(),
    reportJobWatch.watchReportJobCreateNewReport(),
  ]);
}
