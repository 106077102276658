import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
`;

export const AvatarBackdrop = styled.div`
  cursor: pointer;
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.tinGray};
  @media (max-width: 680px) {
    height: 120px;
    width: 120px;
  }
`;

export const AvatarImg = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
  @media (max-width: 680px) {
    height: 120px;
    width: 120px;
  }
`;

export const IconBackdrop = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
  background-color: ${(props) => props.theme.tinGray};
  border: 1px solid ${(props) => props.theme.white};
  @media (max-width: 680px) {
    bottom: 10px;
    right: 6px;
  }
`;

export const InputAvatar = styled.input`
  display: none;
`;

export const OverrideStyles = {
  name: {
    margin: '1rem 0',
  },
};
