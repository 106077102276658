import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 6px;
  background-color: ${(props) => props.theme.grayBackground};
`;

export const ContactHeader = styled.span`
  font-weight: bold;
  padding-right: 1rem;
`;

export const ContainerLoggedIn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContactWrapperLoggedIn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  border-radius: 6px;
  background-color: ${(props) => props.theme.grayBackground};
  @media (max-width: 1199px) {
    padding: 1rem;
  }
`;

export const TextLink = styled.span`
  text-decoration: underline;
  color: ${(props) => props.theme.primary};
`;

export const OverrideStyles = {
  contactBody: {
    marginTop: '1rem',
  },
};
