import styled from 'styled-components';
import {lightTheme} from '../../../styles/themes';
import NavigationTheme from '../../../styles/navigation';

export const Container = styled.div`
  display: flex;
  position: relative;
  height: auto;
  min-height: 100%;
`;

export const PresentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.theme.secondPrimary};
  width: 514px;
  min-width: 514px;
  padding: 2rem 1rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const NavWrapper = styled.nav`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  height: 56px;
  width: 100%;
  padding: 0 1rem;
  @media (max-width: 1023px) {
    background-color: ${(props) => props.theme.white};
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: ${NavigationTheme.NavigationHeadShadow.boxShadow};
  }
`;

export const NavItemRightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavText = styled.p`
  padding-right: 0.5rem;
  @media (max-width: 576px) {
    display: none;
  }
`;

export const SideTextWrapper = styled.div`
  padding: 2rem;
  margin-left: 20px;
  padding-bottom: 0;
`;

export const SideTitle = styled.p`
  font-size: 38px;
  color: ${(props) => props.theme.white};
  font-weight: bold;
  font-family: 'Prompt Regular';
  padding: 0 2.5rem;
`;

export const SideDescription = styled.p`
  font-size: 26px;
  color: ${(props) => props.theme.white};
  font-weight: bold;
  font-family: 'Prompt Regular';
  padding: 0 2.5rem;
`;

export const SideKey = styled.p`
  font-size: 26px;
  color: ${(props) => props.theme.subSecondPrimary};
  font-weight: bold;
  font-family: 'Prompt Regular';
  padding: 0 2.5rem;
  padding-bottom: 2rem;
  margin-bottom: 50px;
`;

export const ForgotWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TextForgot = styled.p`
  color: ${(props) => props.theme.gray};
  margin-top: -0.75rem;
  margin-bottom: 2rem;
  &:hover {
    color: ${(props) => props.theme.primary};
    text-decoration: underline;
  }
`;

export const OverrideStyles = {
  registerLink: {
    color: lightTheme.primary,
  },
  formLogin: {
    padding: '2rem 0 0 0',
  },
  textOption: {
    marginTop: '1.75rem',
  },
  btnFacebook: {
    marginTop: '3rem',
  },
};
