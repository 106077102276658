import {useEffect, useState} from 'react';
import queryString from 'querystring';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as ACTIONS from '../../../redux/notify/action';

export const useManageNotifyGetDataState = () => {
  const {loading: isLoadingState, data: dataState, error: errorState} = useSelector((state) => state.notify.manage.get);
  return {
    isLoadingState,
    dataState,
    errorState,
  };
};

export const useManageNotifyUpdateDataState = () => {
  const {loading: isLoadingUpdateState, result: updateResultState, error: errorUpdateState} = useSelector((state) => state.notify.manage.update);
  return {
    isLoadingUpdateState,
    updateResultState,
    errorUpdateState,
  };
};

export const useManageNotifyUpdateDispatch = () => {
  const dispatch = useDispatch();

  const dispatchGetNotify = (data) => {
    if (!data) {
      return;
    }
    dispatch(ACTIONS.notifyGetDataBySignedUrl({data}));
  };

  const dispatchUpdateNotify = (data) => {
    if (!data) {
      return;
    }
    dispatch(ACTIONS.notifyUpdateDataBySignedUrl({data}));
  };

  return {
    dispatchGetNotify,
    dispatchUpdateNotify,
  };
};

export const useManageNotifyEffect = () => {
  const location = useLocation();
  const {dispatchGetNotify} = useManageNotifyUpdateDispatch();
  const [paramsState, setParamsState] = useState();

  useEffect(() => {
    if (location.search) {
      const searchParams = queryString.parse(location.search.replace('?', ''));
      setParamsState({token: searchParams?.token, userId: searchParams?.user_notification_id});
    }
  }, [location]);

  useEffect(() => {
    if (paramsState?.token && paramsState?.userId) {
      dispatchGetNotify(paramsState);
    }
  }, [paramsState]);
};
