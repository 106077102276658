const Size = {
  width: 293.75,
  height: 150,
  coverHeight: 108.75,
};

const CardTheme = {
  Size,
};

export default CardTheme;
