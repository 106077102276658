import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {Container, Wrapper, ContentWrapper} from './styled-component';
import Header from '../../../../components/header';
import privacyContent from '../../../../res/content/term-privacy';
import RenderText from './render-text';
import RenderTable from './render-table';

const PrivacyPolicyView = (props) => {
  const {isLoggedIn, isView} = props;
  const {formatMessage} = useIntl();

  if (!privacyContent) {
    return null;
  }

  if (isView) {
    return (
      <Wrapper>
        <Header variant="h2" center>
          {formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์'})}
        </Header>
        {Array.isArray(privacyContent) &&
          privacyContent.map((item, index) => (
            <ContentWrapper key={index.toString()}>
              {item.type === 'string' && <RenderText>{item.value}</RenderText>}
              {item.type === 'table' && <RenderTable>{item}</RenderTable>}
            </ContentWrapper>
          ))}
      </Wrapper>
    );
  }

  return (
    <Container isLoggedIn={isLoggedIn}>
      <Wrapper>
        <Header variant="h2" center>
          {formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์'})}
        </Header>
        {Array.isArray(privacyContent) &&
          privacyContent.map((item, index) => (
            <ContentWrapper key={index.toString()}>
              {item.type === 'string' && <RenderText>{item.value}</RenderText>}
              {item.type === 'table' && <RenderTable>{item}</RenderTable>}
            </ContentWrapper>
          ))}
      </Wrapper>
    </Container>
  );
};

PrivacyPolicyView.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isView: PropTypes.bool,
};

PrivacyPolicyView.defaultProps = {
  isView: false,
};

export default PrivacyPolicyView;
