/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */

export const transAttachments = (formAttach) => {
  const transformed = {};
  for (const [key, value] of Object.entries(formAttach)) {
    if (value.value.length > 0) {
      const files = value.value.map((a) => ({url: a.url}));
      transformed[key] = [...files];
    } else {
      transformed[key] = [];
    }
  }
  return transformed;
};
