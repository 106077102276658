import React from 'react';
import {useScrollResetEffect} from '../../../../lib/hooks/useScrollingEffect';
import {translate} from '../../../../res/language/translate';
import MetaTag from '../../../../components/meta-tag';
import Screen from '../../../../views/screen';
import Header from '../../../../components/header';
import Divider from '../../../../components/divider';
import EditGeneralInfoForm from './form';

const EditGeneralView = () => {
  useScrollResetEffect(null);

  return (
    <>
      <MetaTag title={translate('meta.editGeneral')} description={translate('meta.editGeneral')} />
      <Screen leftSidebar isChildScreen type="single-fluid">
        <Header variant="h3" fitLine>
          {translate('screenTitle.editGeneral')}
        </Header>
        <Divider bold edgeInsets />
        <EditGeneralInfoForm />
      </Screen>
    </>
  );
};
//
EditGeneralView.propTypes = {};

export default EditGeneralView;
