import React from 'react';
import {useIntl} from 'react-intl';
import {useCompanyIndexState} from './hooks/useCompanyIndex';
import MetaTag from '../../components/meta-tag';
import ListCompanyIndex from './views';
import BoxBottom from '../main/views/box-bottom';

const JobIndexScreen = () => {
  const {formatMessage} = useIntl();
  const {alphabetState} = useCompanyIndexState();

  return (
    <>
      <MetaTag
        title={
          alphabetState
            ? `${formatMessage({defaultMessage: 'หางาน ชื่อบริษัท'})} [${alphabetState.toUpperCase()}] - JobFinFin`
            : formatMessage({defaultMessage: 'หางาน ชื่อบริษัท - JobFinFin'})
        }
        description={
          alphabetState
            ? `${formatMessage({defaultMessage: 'หางาน ชื่อบริษัท'})} [${alphabetState.toUpperCase()}] - JobFinFin`
            : formatMessage({defaultMessage: 'หางาน ชื่อบริษัท - JobFinFin'})
        }
      />
      <ListCompanyIndex />
      <BoxBottom />
    </>
  );
};

JobIndexScreen.propTypes = {};

export default JobIndexScreen;
