export const CONTENT_EMPTY = 'CONTENT_EMPTY';
export const CONTENT_X1 = 'CONTENT_X1';
export const CONTENT_VERTICAL_1X1 = 'CONTENT_VERTICAL_1X1';
export const CONTENT_VERTICAL_1X2 = 'CONTENT_VERTICAL_1X2';
export const CONTENT_VERTICAL_1X3 = 'CONTENT_VERTICAL_1X3';
export const CONTENT_VERTICAL_1XN = 'CONTENT_VERTICAL_1XN';
export const CONTENT_HORIZONTAL_1X1 = 'CONTENT_HORIZONTAL_1X1';
export const CONTENT_HORIZONTAL_1X2 = 'CONTENT_HORIZONTAL_1X2';
export const CONTENT_HORIZONTAL_1X3 = 'CONTENT_HORIZONTAL_1X3';
export const CONTENT_HORIZONTAL_1XN = 'CONTENT_HORIZONTAL_1XN';
export const CONTENT_HORIZONTAL_2X3 = 'CONTENT_HORIZONTAL_2X3';
export const CONTENT_HORIZONTAL_2XN = 'CONTENT_HORIZONTAL_2XN';

export const DISPLAY_VERTICAL = 'DISPLAY_VERTICAL';
export const DISPLAY_HORIZONTAL = 'DISPLAY_HORIZONTAL';
