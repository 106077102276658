import React from 'react';
import {useIntl} from 'react-intl';
import {translate} from '../../../../res/language/translate';
import MetaTag from '../../../../components/meta-tag';
import Screen from '../../../../views/screen';
import Header from '../../../../components/header';
import Divider from '../../../../components/divider';
import EditEducationForm from './form';

const EditWorkView = () => {
  const {formatMessage} = useIntl();
  return (
    <>
      <MetaTag title={translate('meta.editWork')} description={translate('meta.editWork')} />
      <Screen leftSidebar isChildScreen type="single-fluid">
        <Header variant="h3" fitLine>
          {formatMessage({defaultMessage: 'แก้ไขประวัติการทำงาน'})}
        </Header>
        <Divider bold edgeInsets />
        <EditEducationForm />
      </Screen>
    </>
  );
};
//
EditWorkView.propTypes = {};

export default EditWorkView;
