import styled from 'styled-components';

export const Container = styled.div``;

export const TextUnderline = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.5rem;
`;

export const OverrideStyles = {
  button: {
    color: 'white',
    fontSize: '13px',
    backgroundColor: '#4e503b',
    fontFamily: 'Prompt Light',
  },
  background: {
    backgroundColor: '#2B373B',
  },
};
