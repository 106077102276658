import get from 'lodash/get';
import isObject from 'lodash/isObject';
import * as stringFn from '../../utils/string-func';

export const getLoginFormRegisterData = ({payload}) => {
  if (payload?.data?.data) {
    return payload.data.data;
  }
  return payload?.data;
};

export const getCallbackPath = (callbackData) => {
  if (!callbackData) {
    return '/';
  }
  let path = callbackData.pathname;
  if (callbackData.querySearch) {
    path = `${path}${callbackData.querySearch}`;
  } else if (isObject(callbackData.search)) {
    path = `${path}?${stringFn.searchStringify(callbackData.search)}`;
  } else if (callbackData.search) {
    path = `${path}${callbackData.search}`;
  }
  return path;
};

export const updateTermsStatus = (oldState, newState) => {
  if (!Array.isArray(oldState)) {
    return [...(newState && newState)];
  }
  const filterOutVersion = oldState.filter((item) => item.version !== get(newState, '[0].version', 1));
  return [...filterOutVersion, ...(newState && newState)];
};
