import React from 'react';
import LoginBottomBar from './login-bottom-bar';

const BottomBar = () => {
  return (
    <>
      <LoginBottomBar center />
    </>
  );
};

BottomBar.propTypes = {};

export default BottomBar;
