import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import Modal from '../../../components/modal';
import Icon from '../../../components/icon';
import Text from '../../../components/text';
import TextBold from '../../../components/text-bold';
import {ModalContainer, IconContainer, H3, RightText, SectionInfo, RowInfo, ModalContainer2} from '../styled-component';
import ListTile from '../../../components/list-tile';
import Link from '../../../components/link';
import * as ROUTES from '../../../routes/routes-name';

const CantApplyJobModal = (props) => {
  const {onRequestClose, general, resumeData} = props;
  const {formatMessage} = useIntl();

  if (resumeData) {
    const {checkList} = resumeData;
    return (
      <Modal visible onRequestClose={onRequestClose} id="cant-auto-resume">
        <ModalContainer2>
          <IconContainer onClick={onRequestClose} aria-hidden="true">
            <Icon name="close-outline" size={20} color="#ccc" />
          </IconContainer>
          <center>
            <H3>{formatMessage({defaultMessage: 'กรุณากรอกข้อมูลก่อนใช้เรซูเม่อัตโนมัติ'})}</H3>
          </center>
          <br />
          <>
            <Link url={ROUTES.ROUTE_EDIT_PROFILE_GENERAL} wrapperChildren>
              <SectionInfo>
                <RowInfo>
                  <TextBold>{formatMessage({defaultMessage: 'ข้อมูลส่วนตัว'})}</TextBold>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'ชื่อ'})}</Text>
                  <Text>{checkList.general.firstname}/1</Text>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'นามสกุล'})}</Text>
                  <Text>{checkList.general.lastname}/1</Text>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'เพศ'})}</Text>
                  <Text>{checkList.general.sex}/1</Text>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'วันเกิด'})}</Text>
                  <Text>{checkList.general.birthday}/1</Text>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'เบอร์โทรที่สามารติดต่อได้'})}</Text>
                  <Text>{checkList.general.contactable_tel}/1</Text>
                </RowInfo>
              </SectionInfo>
            </Link>
            <Link url={ROUTES.ROUTE_EDIT_PROFILE_INTEREST} wrapperChildren>
              <SectionInfo>
                <RowInfo>
                  <TextBold>{formatMessage({defaultMessage: 'ข้อมูลลักษณะงานที่สนใจ'})}</TextBold>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'ประเภทงานที่สนใจ'})}</Text>
                  <Text>{checkList.interest.category}/1</Text>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'ตำแหน่งงานที่ต้องการสมัคร'})}</Text>
                  <Text>{checkList.interest.position}/1</Text>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'ระดับตำแหน่ง'})}</Text>
                  <Text>{checkList.interest.level}/1</Text>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'ประเภทงานที่ต้องการ'})}</Text>
                  <Text>{checkList.interest.type}/1</Text>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'เงินเดือนขั้นต่ำ'})}</Text>
                  <Text>{checkList.interest.salaryMin}/1</Text>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'เงินเดือนสูงสุด'})}</Text>
                  <Text>{checkList.interest.salaryMax}/1</Text>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'วันที่สามารถเริ่มงานได้'})}</Text>
                  <Text>{checkList.interest.canStart}/1</Text>
                </RowInfo>
              </SectionInfo>
            </Link>
            <Link url={ROUTES.ROUTE_EDIT_PROFILE_EDUCATION} wrapperChildren>
              <SectionInfo>
                <RowInfo>
                  <TextBold>{formatMessage({defaultMessage: 'ข้อมูลการศึกษา'})}</TextBold>
                </RowInfo>
                <RowInfo>
                  <Text>{formatMessage({defaultMessage: 'ประวัติการศึกษา'})}</Text>
                  <Text>{checkList.education}/1</Text>
                </RowInfo>
              </SectionInfo>
            </Link>
          </>
        </ModalContainer2>
      </Modal>
    );
  }

  return (
    <Modal visible onRequestClose={onRequestClose} id="cant-apply-job">
      <ModalContainer>
        <IconContainer onClick={onRequestClose} aria-hidden="true">
          <Icon name="close-outline" size={20} color="#ccc" />
        </IconContainer>
        <center>
          <H3>{formatMessage({defaultMessage: 'กรุณากรอกข้อมูลเบื้องต้นก่อนสมัครงาน'})}</H3>
        </center>
        <br />
        <Link url={ROUTES.ROUTE_EDIT_PROFILE_GENERAL} wrapperChildren>
          <ListTile isActive title={formatMessage({defaultMessage: 'ชื่อ'})} onClick={() => {}} />
          <RightText>{general.list.firstname}/1</RightText>
        </Link>
        <br />
        <Link url={ROUTES.ROUTE_EDIT_PROFILE_GENERAL} wrapperChildren>
          <ListTile isActive title={formatMessage({defaultMessage: 'นามสกุล'})} onClick={() => {}} />
          <RightText>{general.list.lastname}/1</RightText>
        </Link>
        <br />
        <Link url={ROUTES.ROUTE_EDIT_PROFILE_GENERAL} wrapperChildren>
          <ListTile isActive title={formatMessage({defaultMessage: 'เพศ'})} onClick={() => {}} />
          <RightText>{general.list.sex}/1</RightText>
        </Link>
        <br />
        <Link url={ROUTES.ROUTE_EDIT_PROFILE_GENERAL} wrapperChildren>
          <ListTile isActive title={formatMessage({defaultMessage: 'วันเกิด'})} onClick={() => {}} />
          <RightText>{general.list.birthday}/1</RightText>
        </Link>
      </ModalContainer>
    </Modal>
  );
};

CantApplyJobModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  general: PropTypes.object.isRequired,
  resumeData: PropTypes.object,
};

CantApplyJobModal.defaultProps = {
  resumeData: null,
};

export default CantApplyJobModal;
