import React from 'react';
import {translate} from '../../../../res/language/translate';
import {useLocation} from '../../../../lib/hooks/logic/useLocation';
import {useAuth} from '../../../../lib/hooks/logic/useAuth';
import {Title, LocationText, OverrideStyles} from './styled-component';
import Divider from '../../../../components/divider';
import Row from '../../../../components/row';
import Responsive from '../../../../components/responsive';
import RecommendJobBuilder from '../../../../views/recommend-job-builder';
import * as logic from './logic';
import LAYOUT from '../../../../styles/layout';

const RecommendJob = () => {
  const {loggedInState} = useAuth();
  const {locationNearestState, recommendLocationState} = useLocation();
  return (
    <Responsive minWidth={LAYOUT.content.twoLayoutMinFluid}>
      <Divider edgeInsets />
      <Row style={OverrideStyles.rowLocation}>
        <Title>{translate('home.searchPopularIn')}</Title>
        <LocationText>{logic.getLocationLabel(recommendLocationState, locationNearestState)}</LocationText>
      </Row>
      <RecommendJobBuilder isLoggedIn={!!loggedInState} province={recommendLocationState || locationNearestState} />
    </Responsive>
  );
};

RecommendJob.propTypes = {};

export default RecommendJob;
