import styled from 'styled-components';

export const ImageContainer = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : '250px')};
  height: ${(props) => (props.height ? `${props.height}px` : '250px')};
  ${(props) => props.size && `width: ${props.size}px; height: ${props.size}px`};
  ${(props) =>
    props.fluid &&
    `height: auto;
     width: 100%;
     max-width: ${props.maxWidth}px;
  `};
`;
