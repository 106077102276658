import styled from 'styled-components';
import {lightTheme} from '../../../styles/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 680px;
`;

export const MenuWrapper = styled.div`
  padding: 2rem 0;
`;

export const OverrideStyles = {
  switch: {
    active: lightTheme.primary,
    deActive: lightTheme.white,
  },
  buttonSpaceLeft: {
    marginLeft: '1rem',
  },
  buttonClose: {
    width: '25px',
    minWidth: '40px',
    backgroundColor: lightTheme.white,
  },
  buttonEdit: {
    width: '70px',
    marginLeft: 0,
  },
  txtNotify: {
    fontSize: '14px',
    color: 'red',
  },
};
