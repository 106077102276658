/* eslint-disable camelcase */
import jwt from 'jsonwebtoken';
import {getAuthToken} from './storage/storage';
import {JWT_PUBLIC_KEY} from '../env/config/jwt';

export const verifyToken = async () => {
  try {
    const ans = {};
    const token = getAuthToken();

    const decode = await jwt.verify(token, JWT_PUBLIC_KEY, {algorithm: 'RS256'});

    const {access, role, user_id} = decode;

    ans.access = access;
    ans.role = role;
    ans.user_id = user_id;

    return ans;
  } catch (err) {
    return null;
  }
};
