import {useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import * as userActions from '../../../redux/user/action';
import {uploadSingleImage} from '../../../utils/firebase/firebase-storage';

export const useUserState = () => {
  const {user: userState, loading: isLoadingState, error: errorState, isInit: isInitState} = useSelector((state) => state.user.information, shallowEqual);
  return {
    userState,
    isLoadingState,
    errorState,
    isInitState
  };
};

export const useUserDispatch = () => {
  const dispatch = useDispatch();
  const [isLoadingUploadState, setIsLoadingUploadState] = useState(false);

  const dispatchUploadImage = async (reqBody) => {
    if (!reqBody) {
      return [null, true];
    }
    try {
      setIsLoadingUploadState(true);
      const {_id, name, image} = reqBody;
      const imageUrl = await uploadSingleImage(_id, name, image);
      setIsLoadingUploadState(false);
      return [imageUrl, null];
    } catch (error) {
      setIsLoadingUploadState(false);
      return [null, error];
    }
  };

  const dispatchUpdateUser = (reqBody, isProfilePicture = false) => {
    if (!reqBody) {
      return;
    }
    dispatch(userActions.editUserProfile({data: reqBody, isProfilePicture}));
  };

  return {isLoadingUploadState, dispatchUpdateUser, dispatchUploadImage};
};

export const useUserEffect = () => {};
