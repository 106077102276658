import React from 'react';
import {useIntl} from 'react-intl';
import CookieConsent from 'react-cookie-consent';
import {OverrideStyles, TextUnderline, Container} from './styled-component';
import * as ROUTES from '../../../routes/routes-name';
import * as ENV from '../../../env.json';

const CookieConsentView = () => {
  const {formatMessage} = useIntl();

  const onClickSeeCookie = () => {
    window.open(`${ENV.PUBLIC.API_URL}${ROUTES.ROUTE_COOKIE_POLICY}`);
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText={formatMessage({defaultMessage: 'ยอมรับ'})}
      cookieName="jobfinfinCookies"
      style={OverrideStyles.background}
      buttonStyle={OverrideStyles.button}
      expires={150}>
      <Container>
        {formatMessage({
          defaultMessage: 'เราใช้คุกกี้เพื่อพัฒนาเว็บไซต์ของเราให้สะดวกต่อการใช้งานของคุณ การดำเนินการต่อบนเว็บไซต์นี้ถือว่าคุณยอมรับการใช้งานคุกกี้',
        })}
        <TextUnderline onClick={onClickSeeCookie}>{formatMessage({defaultMessage: 'ดูนโยบายคุกกี้เพิ่มเติม'})}</TextUnderline>
      </Container>
    </CookieConsent>
  );
};

CookieConsentView.propTypes = {};

export default CookieConsentView;
