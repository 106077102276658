import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useRouteMatch} from 'react-router-dom';
import {useAuth} from '../../lib/hooks/logic/useAuth';
import {useScrollingEffect, isScreenEndReached} from '../../lib/hooks/useScrollingEffect';
import {
  Wrapper,
  TwoLayoutWrapper,
  LeftWrapper,
  LeftContent,
  RightCenterContent,
  CenterWrapper,
  CenterContent,
  RightWrapper,
  RightContent,
  LeftLargeWrapper,
  WrapperTwoCol,
  TimelineLayoutWrapper,
  WrapperTimeline,
  WrapperOneCol,
  OneLayoutWrapper,
} from './styled-component';
import SingleContent from './single-layout';
import Responsive from '../../components/responsive';
import ScreenNavbar from './nav-bar';
import * as ROUTES from '../../routes/routes-name';

const Screen = (props) => {
  const {
    onEndReached,
    isChildScreen,
    children,
    leftComponent,
    rightComponent,
    type,
    noEdgeInsets,
    leftSidebar,
    noHorizontalEdgeInsets,
    title,
    subTitle,
  } = props;

  const {loggedInState, isCheckedAuth} = useAuth();
  const isLoggedInState = loggedInState && isCheckedAuth;
  const isRouteAllGroup = useRouteMatch(ROUTES.ROUTE_GROUP_LIST);

  const nodeContainerRef = useRef(null);

  const onEventScrolling = () => {
    if (!nodeContainerRef.current) {
      return;
    }
    const isEnded = isScreenEndReached(nodeContainerRef);
    if (isEnded) {
      onEndReached();
    }
  };

  useScrollingEffect({
    callbackFunc: onEventScrolling,
    dependencies: [],
  });

  if (type === 'single' || type === 'single-fluid') {
    const isFluid = type === 'single-fluid';
    return (
      <>
        {isChildScreen && <ScreenNavbar title={title} subTitle={subTitle} />}
        <Wrapper>
          <SingleContent
            fluid={isFluid}
            leftSidebar={leftSidebar}
            isChildScreen={isChildScreen}
            noEdgeInsets={noEdgeInsets}
            noHorizontalEdgeInsets={noHorizontalEdgeInsets}>
            {children}
          </SingleContent>
        </Wrapper>
      </>
    );
  }

  if (type === 'timeline-layout') {
    return (
      <>
        {isChildScreen && <ScreenNavbar title={title} subTitle={subTitle} />}
        <WrapperTimeline ref={nodeContainerRef}>
          <TimelineLayoutWrapper>
            <LeftWrapper>
              <LeftContent>{leftComponent}</LeftContent>
            </LeftWrapper>
            <CenterWrapper timeline>
              <CenterContent>
                <>
                  <Responsive maxWidth={1439}>
                    <RightCenterContent>{rightComponent}</RightCenterContent>
                  </Responsive>
                  {children}
                </>
              </CenterContent>
            </CenterWrapper>
            <Responsive minWidth={1440}>
              <RightWrapper>
                <RightContent>{rightComponent}</RightContent>
              </RightWrapper>
            </Responsive>
          </TimelineLayoutWrapper>
        </WrapperTimeline>
      </>
    );
  }

  if (type === 'two-col-layout') {
    return (
      <>
        {loggedInState && isChildScreen && <ScreenNavbar title={title} subTitle={subTitle} />}
        <WrapperTwoCol id="screen-scroll" ref={nodeContainerRef}>
          <TwoLayoutWrapper noTopEdgeInsets={!isLoggedInState}>
            {leftComponent && (
              <LeftLargeWrapper>
                <LeftContent>{leftComponent}</LeftContent>
              </LeftLargeWrapper>
            )}
            <CenterWrapper singleCol={!leftComponent} noTopEdgeInsets={!isLoggedInState}>
              <CenterContent>{children}</CenterContent>
            </CenterWrapper>
          </TwoLayoutWrapper>
        </WrapperTwoCol>
      </>
    );
  }

  if (type === 'one-col-layout') {
    return (
      <>
        {loggedInState && isChildScreen && <ScreenNavbar title={title} subTitle={subTitle} />}
        <WrapperOneCol id="screen-scroll" ref={nodeContainerRef}>
          <OneLayoutWrapper noTopEdgeInsets={!isLoggedInState}>
            {leftComponent && (
              <LeftLargeWrapper>
                <LeftContent>{leftComponent}</LeftContent>
              </LeftLargeWrapper>
            )}
            <CenterWrapper singleCol={!leftComponent} noTopEdgeInsets={!isLoggedInState}>
              <CenterContent>{children}</CenterContent>
            </CenterWrapper>
          </OneLayoutWrapper>
        </WrapperOneCol>
      </>
    );
  }

  return (
    <>
      {isCheckedAuth && isChildScreen && !isRouteAllGroup && <ScreenNavbar title={title} subTitle={subTitle} />}
      {isChildScreen && isRouteAllGroup && isLoggedInState ? <ScreenNavbar title={title} subTitle={subTitle} /> : null}
      <Wrapper ref={nodeContainerRef}>{children}</Wrapper>
    </>
  );
};

Screen.propTypes = {
  isChildScreen: PropTypes.bool,
  type: PropTypes.oneOf(['pure', 'single', 'sidebar', 'single-fluid', 'timeline-layout', 'one-col-layout', 'two-col-layout']),
  children: PropTypes.any,
  leftComponent: PropTypes.any,
  rightComponent: PropTypes.any,
  leftSidebar: PropTypes.bool,
  noEdgeInsets: PropTypes.bool,
  noHorizontalEdgeInsets: PropTypes.bool,
  onEndReached: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

Screen.defaultProps = {
  isChildScreen: false,
  type: 'single',
  children: null,
  leftComponent: null,
  rightComponent: null,
  leftSidebar: false,
  noEdgeInsets: false,
  noHorizontalEdgeInsets: false,
  title: null,
  subTitle: null,
  onEndReached: () => {},
};

export default Screen;
