/* eslint-disable no-unused-vars */
import React from 'react';
import {translate} from '../../../../res/language/translate';
import MetaTag from '../../../../components/meta-tag';
import Screen from '../../../../views/screen';
import Header from '../../../../components/header';
import Divider from '../../../../components/divider';
import ChangePasswordForm from './form';

const ChangePasswordView = () => {
  return (
    <>
      <MetaTag title={translate('meta.changePassword')} description={translate('meta.changePassword')} />
      <Screen leftSidebar isChildScreen type="single-fluid">
        <Header variant="h3" fitLine>
          {translate('screenTitle.editPass')}
        </Header>
        <Divider bold edgeInsets />
        <ChangePasswordForm />
      </Screen>
    </>
  );
};
//
ChangePasswordView.propTypes = {};

export default ChangePasswordView;
