import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import qs from 'querystring';
import {useQuery} from '../hooks/useQuery';
import {useCompanyIndexState, useCompanyIndexDispatch} from '../hooks/useCompanyIndex';
import {useScrollResetAfterPopEffect} from '../../../lib/hooks/useScrollingEffect';
import {Container, IndexWrapper, HeadWrapper, JobItemWrapper, HeaderGroupWrapper, AlphabetText, AlphabetWrapper} from './styled-component';
import Header from '../../../components/header';
import Row from '../../../components/row';
import Link from '../../../components/link';
import IndexSubItem from './item';
import KEY_DATA from '../../../res/json/alphabet.json';
import * as logic from './logic';
import * as ROUTES from '../../../routes/routes-name';
import * as NAVIGATION from '../../../utils/navigation/navigation-services';

const ListCompanyIndex = () => {
  const {formatMessage} = useIntl();
  const {companiesState, alphabetState, isLoadingState, counterState, typeState} = useCompanyIndexState();
  const location = useLocation();
  const query = useQuery(location.search);
  const {dispatchFetchCompanyIndex, dispatchUpdateKey} = useCompanyIndexDispatch();

  const onRequestIndex = (alphabet, type) => {
    if (companiesState[alphabet]) {
      return;
    }
    dispatchFetchCompanyIndex(alphabet, type);
  };

  useScrollResetAfterPopEffect(true);
  useEffect(() => {
    if (location.pathname === ROUTES.ROUTE_COMPANY_INDEX) {
      onRequestIndex('ก', 'th');
      dispatchUpdateKey('ก', 'th');
    }
    if (location.pathname === ROUTES.ROUTE_COMPANY_INDEX_TH) {
      if (!query) {
        onRequestIndex('ก', 'th');
        dispatchUpdateKey('ก', 'th');
        return;
      }
      if (KEY_DATA.th.data.find((elem) => elem === query)) {
        onRequestIndex(query, 'th');
        dispatchUpdateKey(query, 'th');
        return;
      }
      NAVIGATION.navigateReplaceTo({pathname: ROUTES.ROUTE_COMPANY_INDEX_TH});
    }
    if (location.pathname === ROUTES.ROUTE_COMPANY_INDEX_EN) {
      if (!query) {
        onRequestIndex('a', 'en');
        dispatchUpdateKey('a', 'en');
        return;
      }
      if (KEY_DATA.en.data.find((elem) => elem === query)) {
        onRequestIndex(query.toLowerCase(), 'en');
        dispatchUpdateKey(query, 'en');
        return;
      }
      NAVIGATION.navigateReplaceTo({pathname: ROUTES.ROUTE_COMPANY_INDEX_EN});
    }
    if (location.pathname === ROUTES.ROUTE_COMPANY_INDEX_NUMBER) {
      if (!query) {
        onRequestIndex('0-9', 'number');
        dispatchUpdateKey('0-9', 'number');
        return;
      }
      if (KEY_DATA.num.data.find((elem) => elem === query)) {
        onRequestIndex('0-9', 'number');
        dispatchUpdateKey(query, 'number');
        return;
      }
      NAVIGATION.navigateReplaceTo({pathname: ROUTES.ROUTE_COMPANY_INDEX_NUMBER});
    }
  }, [query]);

  return (
    <Container>
      <IndexWrapper>
        <HeadWrapper>
          <Header variant="h2">{formatMessage({defaultMessage: 'หมวดหมู่บริษัททั้งหมด'})}</Header>
        </HeadWrapper>
        <AlphabetWrapper>
          <Row>
            {KEY_DATA.th.data.map((item, index) => (
              <Link key={index.toString()} url={ROUTES.ROUTE_COMPANY_INDEX_TH} search={qs.stringify({alphabet: item})}>
                <AlphabetText active={logic.getActiveAlphabet(location.pathname, item, typeState, query)}>{item}</AlphabetText>
              </Link>
            ))}
          </Row>
          <Row>
            {KEY_DATA.en.data.map((item, index) => (
              <Link key={index.toString()} url={ROUTES.ROUTE_COMPANY_INDEX_EN} search={qs.stringify({alphabet: item})}>
                <AlphabetText active={logic.getActiveAlphabet(location.pathname, item, typeState, query)}>{item.toUpperCase()}</AlphabetText>
              </Link>
            ))}
          </Row>
          <Row>
            {KEY_DATA.num.data.map((item, index) => (
              <Link key={index.toString()} url={ROUTES.ROUTE_COMPANY_INDEX_NUMBER} search={qs.stringify({alphabet: item})}>
                <AlphabetText active={logic.getActiveAlphabet(location.pathname, item, typeState, query)}>{item}</AlphabetText>
              </Link>
            ))}
          </Row>
        </AlphabetWrapper>
        <>
          <HeaderGroupWrapper>
            <Header variant="h3">{`${logic.getLabelCounter(counterState[alphabetState], alphabetState, isLoadingState)}`}</Header>
          </HeaderGroupWrapper>
          <JobItemWrapper>
            <IndexSubItem data={companiesState[alphabetState] || []} isLoading={isLoadingState} />
          </JobItemWrapper>
        </>
      </IndexWrapper>
    </Container>
  );
};

ListCompanyIndex.propTypes = {};

export default ListCompanyIndex;
