import React from 'react';
import PropTypes from 'prop-types';
import {DefaultToast} from 'react-toast-notifications';
import {ToastMessage} from './styled-component';

const ToastBody = (props) => {
  const {children} = props;
  return (
    <DefaultToast {...props}>
      <ToastMessage>{children}</ToastMessage>
    </DefaultToast>
  );
};

ToastBody.propTypes = {
  appearance: PropTypes.string,
  children: PropTypes.any,
};

ToastBody.defaultProps = {
  appearance: 'success',
  children: null,
};

export default ToastBody;
