import React from 'react';
import Responsive from '../../components/responsive';
import FilterOptions from '../filter';
import ModalFilter from '../filter/modal-filter';
import LAYOUT from '../../styles/layout';

const FilterBuilder = () => {
  return (
    <>
      <Responsive minWidth={LAYOUT.content.twoLayoutMinFluid}>
        <ModalFilter />
      </Responsive>
      <FilterOptions />
    </>
  );
};

FilterBuilder.propTypes = {};

export default FilterBuilder;
