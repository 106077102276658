import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useValidateEmail} from '../../lib/hooks/logic/useValidateEmail';
import {useRegister, useRegisterEffect} from '../../lib/hooks/logic/useRegister';
import {Container} from './views/styled-component';
import NavBar from './views/nav-bar';
import SidePresent from './views/side-present';
import Column from '../../components/column';
import Loading from '../../components/loading';
import MetaTag from '../../components/meta-tag';
import FormGeneral from './views/form-general';
import FormInfo from './views/form-info';
import FormInterest from './views/form-interest';

const KEY_RD = '?rd_path=true';

const RegisterScreen = () => {
  useRegisterEffect();
  const history = useHistory();
  const {formatMessage} = useIntl();

  const {isLoadingValidateEmail, dispatchValidateEmail} = useValidateEmail({register: true});
  const {currentStepState, dispatchUpdateFormGeneral, dispatchUpdateFormInfo, dispatchUpdateFormInterest} = useRegister();
  const callbackPath = useSelector((state) => state.context.callbackPath);

  const onEventSubmitFormGeneral = (generalFormValues) => {
    const {email, password} = generalFormValues;
    dispatchValidateEmail(email);
    dispatchUpdateFormGeneral({email, password});
  };

  const onEventSubmitFormInfo = (infoFormValues) => {
    dispatchUpdateFormInfo(infoFormValues);
  };

  const onEventSubmitFormInterest = (interestFormValues) => {
    dispatchUpdateFormInterest(interestFormValues);
  };

  useEffect(() => {
    if (callbackPath?.pathname && history) {
      history.replace({pathname: history.location.pathname, search: KEY_RD});
    }
  }, [callbackPath, history]);

  return (
    <Container>
      <MetaTag
        title={formatMessage({defaultMessage: 'สมัครสมาชิก - JobFinFin'})}
        description={formatMessage({defaultMessage: 'งาน หางาน สมัครงาน - JobFinFin'})}
      />
      <Loading loading={isLoadingValidateEmail || false} />
      <SidePresent />
      <Column>
        <NavBar />
        {currentStepState === 1 && <FormGeneral onSubmit={onEventSubmitFormGeneral} />}
        {currentStepState === 2 && <FormInfo onSubmit={onEventSubmitFormInfo} />}
        {currentStepState === 3 && <FormInterest onSubmit={onEventSubmitFormInterest} />}
      </Column>
    </Container>
  );
};

RegisterScreen.propTypes = {};

RegisterScreen.defaultProps = {};

export default RegisterScreen;
