import * as TYPES from './type';

export const contextGetLocation = (payload) => ({
  type: TYPES.CONTEXT_LOCATION_REQ,
  payload: payload,
});

export const contextGetProvinces = () => ({
  type: TYPES.CONTEXT_PROVINCE_REQ,
});

export const contextGetDistricts = (payload) => ({
  type: TYPES.CONTEXT_DISTRICT_REQ,
  payload: payload,
});

export const contextGetStackDistricts = (payload) => ({
  type: TYPES.CONTEXT_STACK_DISTRICT_REQ,
  payload: payload,
});

export const contextUpdateOffset = (payload) => ({
  type: TYPES.CONTEXT_UPDATE_OFFSET_REQ,
  payload: payload,
});

export const contextSetVisibleFilter = (payload) => ({
  type: TYPES.CONTEXT_VISIBLE_FILTER_REQ,
  payload: payload,
});

export const contextUpdateDimensions = (payload) => ({
  type: TYPES.CONTEXT_UPDATE_DIMENSIONS_REQ,
  payload: payload,
});

export const contextMainScrollConfig = (payload) => ({
  type: TYPES.CONTEXT_MAIN_SCROLL_CONFIG_REQ,
  payload: payload,
});

export const contextEventListenerConfig = (payload) => ({
  type: TYPES.CONTEXT_EVENT_LISTENER_CONFIG_REQ,
  payload: payload,
});

export const contextContactShow = (payload) => ({
  type: TYPES.CONTEXT_CONTACT_SHOW_REQ,
  payload: payload,
});

export const contextImageViewer = (payload) => ({
  type: TYPES.CONTEXT_IMAGE_VIEWER_REQ,
  payload: payload,
});

export const contextShowBottomLogin = (payload) => ({
  type: TYPES.CONTEXT_SHOW_BOTTOM_LOGIN_REQ,
  payload: payload,
});

export const contextAddCallbackPath = (payload) => ({
  type: TYPES.CONTEXT_ADD_CALLBACK_PATH_REQ,
  payload: payload,
});

export const contextShowReportPost = (payload) => ({
  type: TYPES.CONTEXT_SHOW_REPORT_POST_REQ,
  payload: payload,
});

export const contextHideReportPost = (payload) => ({
  type: TYPES.CONTEXT_HIDE_REPORT_POST_REQ,
  payload: payload,
});

export const contextStoreRewritePost = (payload) => ({
  type: TYPES.CONTEXT_REWRITE_POST_REQ,
  payload: payload,
});
