import React from 'react';
import PropTypes from 'prop-types';
import {Container} from './styled-component';

const Column = (props) => {
  const {children, fluid, style} = props;
  return (
    <Container fluid={fluid} style={style}>
      {children}
    </Container>
  );
};

Column.propTypes = {
  fluid: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.any,
};

Column.defaultProps = {
  fluid: false,
  children: null,
  style: null,
};

export default Column;
