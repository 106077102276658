import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import SectionHeader from '../header';
import {calInterestInfo} from '../resume-info/logic';
import * as ICONS from '../../../../components/icon/constants';
import * as ROUTES from '../../../../routes/routes-name';

const InterestInfo = (props) => {
  const {userState} = props;

  const {formatMessage} = useIntl();

  if (!userState) {
    return null;
  }

  return (
    <>
      <SectionHeader
        route={ROUTES.ROUTE_EDIT_PROFILE_INTEREST}
        iconName={ICONS.BOOKMARK_STAR}
        title={formatMessage({defaultMessage: 'ลักษณะงานที่สนใจ'})}
        score={calInterestInfo(userState)}
        total={20}
      />
    </>
  );
};

InterestInfo.propTypes = {
  userState: PropTypes.object,
};

InterestInfo.defaultProps = {
  userState: null,
};

export default InterestInfo;
