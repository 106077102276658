import React from 'react';
import PropTypes from 'prop-types';
import {LogoContainer, LogoContainerWithEvent} from './styled-component';
import ImgLogoHorizontal from '../../res/assets/images/jff80_logo.png';

const AppLogo = (props) => {
  const {fixed, ratio, onClick} = props;
  if (onClick) {
    return <LogoContainerWithEvent onClick={onClick} src={ImgLogoHorizontal} fixed={fixed} ratio={ratio} />;
  }
  return <LogoContainer src={ImgLogoHorizontal} fixed={fixed} ratio={ratio} />;
};

AppLogo.propTypes = {
  fixed: PropTypes.bool,
  ratio: PropTypes.number,
  onClick: PropTypes.func,
};

AppLogo.defaultProps = {
  fixed: false,
  ratio: 0,
  onClick: null,
};

export default AppLogo;
