import {put, all, delay, call} from 'redux-saga/effects';
import * as api from './api';
import * as apiAuth from '../auth/api';
import * as TYPES from './type';
import * as AUTH_TYPES from '../auth/type';
import * as ALERT_TYPES from '../alert/type';
import {translate} from '../../res/language/translate';
import {loginFirebase} from '../../utils/firebase/firebase-auth';
import {cancelInvokeOTPProvider} from '../utils/fast-dispatch';
import {getErrorMessage} from '../utils/error-store';
import * as snackbar from '../../utils/components/toast-notification/provider';

export function* userGenerateIdAction() {
  try {
    const response = yield call(api.generateId);
    const resLoginFirebase = yield call(apiAuth.userLoginFirebase, {
      user_id: response?.data,
    });
    yield call(loginFirebase, resLoginFirebase.data?.token);
    yield put({
      type: TYPES.REGISTER_GENERATE_ID_SUCCESS,
      payload: {_id: response?.data},
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.REGISTER_GENERATE_ID_FAIL,
      payload: getErrorMessage(responseError),
    });
  }
}

export function* userRegisterUpdateFormGeneralAction({payload}) {
  if (payload?.clear) {
    yield put({type: TYPES.REGISTER_STEP1_CLEAR});
  }
  if (payload?.error) {
    yield put({
      type: TYPES.REGISTER_STEP1_UPDATE_FORM_FAIL,
      payload: payload.error,
    });
  } else {
    yield put({
      type: TYPES.REGISTER_STEP1_UPDATE_FORM_SUCCESS,
      payload: payload?.data,
    });
  }
}

export function* userRegisterStep1ValidateEmailAction({payload}) {
  if (payload?.clear) {
    yield put({type: TYPES.REGISTER_STEP1_VALIDATE_EMAIL_CLEAR});
  } else {
    try {
      const response = yield call(api.userRegisterValidateEmail, payload?.data);
      yield put({
        type: TYPES.REGISTER_STEP1_VALIDATE_EMAIL_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      const responseError = error?.response?.data?.error;
      const handlerMessage = getErrorMessage(responseError);
      snackbar.setSnackbarMessage(handlerMessage, 'error');
      yield put({
        type: TYPES.REGISTER_STEP1_VALIDATE_EMAIL_FAIL,
        payload: handlerMessage,
      });
    }
  }
}

export function* userRegisterStep2Action({payload}) {
  if (payload?.clear) {
    yield put({type: TYPES.REGISTER_STEP2_CLEAR});
  }
  if (payload?.error) {
    yield put({
      type: TYPES.REGISTER_STEP2_UPDATE_FORM_FAIL,
      payload: payload.error,
    });
  } else {
    yield put({
      type: TYPES.REGISTER_STEP2_UPDATE_FORM_SUCCESS,
      payload: payload?.data,
    });
  }
}

export function* userRegisterVisibleStepRequestNextAction({payload}) {
  if (payload?.clear) {
    yield delay(500);
    yield put({
      type: TYPES.REGISTER_NEXT_STEP_CLEAR,
    });
  } else {
    yield put({
      type: TYPES.REGISTER_NEXT_STEP_SUCCESS,
    });
  }
}

export function* userRegisterUpdateVisibleStepAction({payload}) {
  if (!payload?.error) {
    yield put({
      type: TYPES.REGISTER_VISIBLE_STEP_SUCCESS,
      payload: payload?.data,
    });
  } else {
    yield put({
      type: TYPES.REGISTER_VISIBLE_STEP_FAIL,
      payload: payload.error,
    });
  }
}

export function* userRegisterOTPAction({payload}) {
  try {
    const actionClear = {clear: true};
    yield all([
      put({type: TYPES.REGISTER_STEP1_CLEAR, payload: actionClear}),
      put({type: TYPES.REGISTER_STEP2_CLEAR, payload: actionClear}),
      put({type: TYPES.REGISTER_INTEREST_CLEAR}),
      put({
        type: TYPES.REGISTER_OTP_SUCCESS,
        payload: payload?.data,
      }),
    ]);
  } catch (error) {
    yield put({
      type: TYPES.REGISTER_OTP_FAIL,
      payload: error || payload.error,
    });
  }
}

export function* userRegisterOTPRequestAction({payload}) {
  if (!payload?.error) {
    try {
      const response = yield call(api.userRegisterRequestOTP, payload?.data);
      yield put({
        type: TYPES.REGISTER_OTP_REQUEST_SUCCESS,
        payload: response?.data?.data,
      });
      snackbar.setSnackbarMessage(translate('register.sendOtpSuccess'));
    } catch (error) {
      const responseError = error?.response?.data?.error;
      const alertData = {
        message: getErrorMessage(responseError),
      };
      yield all([
        put({
          type: TYPES.REGISTER_OTP_REQUEST_FAIL,
          payload: responseError,
        }),
        put({
          type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
          payload: {data: alertData},
        }),
      ]);
      if (responseError === 'waiting for older otp expire') {
        cancelInvokeOTPProvider(payload?.data?.tel);
      }
    }
  } else {
    yield put({
      type: TYPES.REGISTER_OTP_REQUEST_FAIL,
      payload: payload.error,
    });
  }
}

export function* userRegisterOTPResendAction({payload}) {
  if (!payload?.error) {
    try {
      const response = yield call(api.userRegisterRequestOTP, payload?.data);
      snackbar.setSnackbarMessage(translate('register.sendOtpSuccess'));
      yield put({
        type: TYPES.REGISTER_OTP_RESEND_SUCCESS,
        payload: response?.data?.data,
      });
    } catch (error) {
      const responseError = error?.response?.data?.error;
      const alertData = {
        message: getErrorMessage(responseError),
      };
      yield all([
        put({
          type: TYPES.REGISTER_OTP_RESEND_FAIL,
          payload: responseError,
        }),
        put({
          type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
          payload: {data: alertData},
        }),
      ]);
      if (responseError === 'waiting for older otp expire') {
        cancelInvokeOTPProvider(payload?.data?.tel);
      }
    }
  } else {
    yield put({
      type: TYPES.REGISTER_OTP_RESEND_FAIL,
      payload: payload.error,
    });
  }
}

export function* userRegisterOTPConfirmAction({payload}) {
  try {
    const response = yield call(api.userRegisterVerifyOTP, payload?.data?.formOTP);
    yield put({
      type: TYPES.REGISTER_OTP_CONFIRM_SUCCESS,
      payload: response?.data?.data,
    });
    if (payload?.data?.formRegister?.path === 'facebook') {
      yield put({
        type: TYPES.REGISTER_FACEBOOK_REQ,
        payload: payload?.data?.formRegister,
      });
    } else {
      yield put({
        type: TYPES.REGISTER_MANUAL_REQ,
        payload: payload?.data?.formRegister,
      });
    }
    cancelInvokeOTPProvider(payload?.data?.formOTP?.tel);
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const alertData = {
      message: getErrorMessage(responseError),
    };
    yield all([
      put({
        type: TYPES.REGISTER_OTP_CONFIRM_FAIL,
        payload: getErrorMessage(responseError),
      }),
      put({
        type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
        payload: {data: alertData},
      }),
    ]);
    if (responseError === 'waiting for older otp expire') {
      cancelInvokeOTPProvider(payload?.data?.tel);
    }
  }
}

export function* userRegisterOTPChangePhoneNumberAction({payload}) {
  if (!payload?.clear) {
    yield put({
      type: TYPES.REGISTER_OTP_CHANGE_PHONE_SUCCESS,
    });
  } else {
    yield put({
      type: TYPES.REGISTER_OTP_STEP_CLEAR,
    });
  }
}

export function* userRegisterManualAction({payload}) {
  try {
    const response = yield call(api.userRegisterManual, payload);
    yield put({
      type: TYPES.REGISTER_MANUAL_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: AUTH_TYPES.AUTH_LOGIN_FROM_REGISTER_REQ,
      payload: response.data,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const alertData = {
      message: getErrorMessage(responseError),
    };
    yield all([
      put({
        type: TYPES.REGISTER_MANUAL_FAIL,
        payload: responseError,
      }),
      put({
        type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
        payload: {data: alertData},
      }),
    ]);
  }
}

export function* userRegisterFacebookAction({payload}) {
  try {
    const response = yield call(api.userRegisterFacebook, payload);
    yield put({
      type: TYPES.REGISTER_FACEBOOK_SUCCESS,
      payload: response.data,
    });

    if (!response.data?.data) {
      return;
    }
    const _payload = {data: {...response.data.data}};
    yield put({
      type: AUTH_TYPES.AUTH_LOGIN_FROM_REGISTER_REQ,
      payload: _payload,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const alertData = {
      message: getErrorMessage(responseError),
    };
    yield all([
      put({
        type: TYPES.REGISTER_FACEBOOK_FAIL,
        payload: responseError,
      }),
      put({
        type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
        payload: {data: alertData},
      }),
    ]);
  }
}

export function* userRegisterClearFormAction() {
  yield put({
    type: TYPES.REGISTER_STEP1_CLEAR,
  });
  yield put({
    type: TYPES.REGISTER_STEP2_CLEAR,
  });
  yield put({
    type: TYPES.REGISTER_GENERATE_ID_CLEAR,
  });
  yield put({
    type: TYPES.REGISTER_INTEREST_CLEAR,
  });
  yield put({
    type: AUTH_TYPES.AUTH_FACEBOOK_LOGIN_CLEAR,
  });
  yield put({
    type: TYPES.REGISTER_VISIBLE_STEP_REQ,
    payload: {data: 1},
  });
  yield put({
    type: TYPES.REGISTER_CLEAR_FORM_SUCCESS,
  });
}

export function* userRegisterInterestAction({payload}) {
  yield put({
    type: TYPES.REGISTER_INTEREST_SUCCESS,
    payload: payload?.data,
  });
}
