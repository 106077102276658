import {takeEvery, takeLatest} from 'redux-saga/effects';
import * as TYPES from './type';
import * as SAGA from './saga';

export function* watchTrackInteractJobUpdateStack() {
  yield takeEvery(TYPES.TRACK_EVENT_INTERACT_JOB_UPDATE_STACK_REQ, SAGA.trackInteractJobUpdateStack);
}

export function* watchTrackInteractJobStreamStack() {
  yield takeLatest(TYPES.TRACK_EVENT_INTERACT_JOB_STREAM_STACK_REQ, SAGA.trackInteractJobStreamStack);
}
