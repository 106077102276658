import React from 'react';
import {translate} from '../../res/language/translate';
import Snapshot404 from './views/snapshot-404';
import MetaTags from '../../components/meta-tag';

const Screen404 = () => {
  return (
    <>
      <MetaTags title={translate('meta.pageNotFound')} description={translate('meta.pageNotFound')} contentUrl={null} />
      <Snapshot404 />
    </>
  );
};

Screen404.propTypes = {};

export default Screen404;
