export const initForm = {
  oldPassword: '',
  newPassword: '',
  confPassword: '',
};

export const initValidate = {
  oldPassword: '',
  newPassword: '',
  confPassword: '',
};
