import {useEffect} from 'react';
import {lightTheme} from '../../styles/themes';

export const useSetGrayBackground = (isTransparent = false) => {
  useEffect(() => {
    if (!isTransparent) {
      document.body.style = `background: ${lightTheme.grayBackground};`;
    }
    return () => {
      document.body.style = null;
    };
  }, [isTransparent]);
};
