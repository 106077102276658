import React from 'react';
import lodashGet from 'lodash/get';
import {translate} from '../../res/language/translate';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import {useScrollResetAfterPopEffect} from '../../lib/hooks/useScrollingEffect';
import {Container, CoverWrapper, ListWrapper, PostWrapper, OverrideStyles, FixedBottom, ScreenWrapper} from './views/styled-component';
import ImageCover from './views/image-cover';
import Announcement from './views/announcement';
import About from './views/about';
import NavBar from './views/navbar';
import MetaTags from '../../components/meta-tag';
import Header from '../../components/header';
import JobPostBuilder from '../../views/job-post-builder';
import Screen404 from '../404';
import * as typeDef from '../../utils/strong-types';
import {useCompanyProfileEffect, useCompanyProfileState, useCompanyProfileDispatch} from '../../lib/hooks/logic/useCompanyProfile';
import Responsive from '../../components/responsive';

const CompanyProfileScreen = () => {
  useSetGrayBackground();
  useCompanyProfileEffect();

  const {dispatchFetchRefresh} = useCompanyProfileDispatch();

  const {
    backdropColorsState: [colors, success],
    stackFeedState,
    metaGroupState,
    companyLoadingState,
    companyErrorState,
    isEmptyDataState,
  } = useCompanyProfileState();

  useScrollResetAfterPopEffect(!metaGroupState);

  const onEventRefresh = () => {
    dispatchFetchRefresh();
  };

  if (companyErrorState === 'company not found') {
    return <Screen404 />;
  }

  return (
    <>
      {metaGroupState && (
        <MetaTags
          title={metaGroupState?.name + translate('meta.appName')}
          description={translate('meta.search')}
          image={metaGroupState?.cover_image?.url}
        />
      )}
      <ScreenWrapper>
        <NavBar onEventRefresh={onEventRefresh} />
        <Container isNotSpace>
          <CoverWrapper defaultBackground={success === false} background={colors}>
            <ImageCover />
            <Header variant="h2" style={OverrideStyles.coverTitle}>
              {typeDef.isString(metaGroupState?.name) ? metaGroupState?.name : ''}
            </Header>
          </CoverWrapper>
          <ListWrapper>
            <About />
            <PostWrapper>
              <Responsive maxWidth={1064}>
                <About isMid />
              </Responsive>
              <Announcement isCompanyVerified={lodashGet(stackFeedState, '[0].is_company_verified') || false} />
              <JobPostBuilder
                isCompany
                noTopEdgeInsets
                noBottomEdgeInsets
                data={stackFeedState}
                isEmptyData={isEmptyDataState}
                isLoadingFeed={companyLoadingState}
              />
              <Responsive maxWidth={1064}>
                <FixedBottom />
              </Responsive>
            </PostWrapper>
          </ListWrapper>
        </Container>
      </ScreenWrapper>
    </>
  );
};

CompanyProfileScreen.propTypes = {};

export default CompanyProfileScreen;
