import React, {useState, useRef, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {translate} from '../../../res/language/translate';
import {useLandingGroupSliderEffect} from '../../../lib/hooks/logic/useLandingGroup';
import {Container, RowWrapper, ItemWrapper, RecommendWrapper, Spacer} from './styled-component';
import SelectLocation from '../../../views/select-location';
import RecommendJob from '../../../views/recommend-job-builder';
import GroupSliderBuilder from '../../../views/group-slider-builder';
// import HotCompanyBuilder from '../../../views/hot-company-builder';
import * as coreLocation from '../../../lib/context/location';
import * as navigation from '../../../utils/navigation/navigation-services';

const hashLandingId = '#landing';

const BoxRecommend = () => {
  const {groupListState} = useLandingGroupSliderEffect();

  const location = useLocation();
  const refNodeLandingList = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [provinceTargetState, setProvinceTargetState] = useState('');

  const onChangeSelectLocation = (parentItem, childItem) => {
    const newLocations = coreLocation.mapSelectedLocation({
      selectedOptions: selectedLocation,
      newItems: {
        parentItem,
        childItem,
      },
    });
    setSelectedLocation(newLocations);
    if (newLocations[0]?.value) {
      setProvinceTargetState(newLocations[0].value);
    }
  };

  useEffect(() => {
    if (groupListState && location && refNodeLandingList.current) {
      if (location.hash === hashLandingId) {
        window.scrollTo({top: refNodeLandingList.current.offsetTop - 50});
        navigation.navigateTo('/');
      }
    }
  }, [groupListState, location]);

  return (
    <Container>
      <RowWrapper whiteBackground>
        <ItemWrapper floatStick="flex-end" nonePaddingBottom>
          <SelectLocation
            inlineLabel
            noneDistrict
            label={`${translate('landing.quickStep')} `}
            placeholder={translate('landing.search.select')}
            selectedOptions={selectedLocation}
            onChangeOptionTwo={onChangeSelectLocation}
          />
        </ItemWrapper>
        <ItemWrapper floatStick="flex-start" nonePaddingTop>
          <RecommendWrapper>
            <RecommendJob province={provinceTargetState} />
          </RecommendWrapper>
        </ItemWrapper>
      </RowWrapper>
      <GroupSliderBuilder data={groupListState} />
      <Spacer />
      {/* <HotCompanyBuilder /> */}
    </Container>
  );
};

BoxRecommend.propTypes = {};

export default BoxRecommend;
