import styled from 'styled-components';
import {lightTheme} from '../../../../styles/themes';

export const ButtonInteractWrapper = styled.div`
  display: flex;
  width: 120px;
  height: 35px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 0.2rem;
  @media (max-width: 680px) {
    height: 55px;
    flex-direction: column;
  }
  @media (max-width: 540px) {
    width: 80px;
  }
  @media (max-width: 400px) {
    width: 60px;
  }
  &:hover {
    ${(props) => !props.disabled && `background-color: ${props.theme.chipBackground};`};
  }
  ${(props) => props.disabled && `cursor: no-drop;`};
`;

export const ButtonInteractFluidWrapper = styled.div`
  display: flex;
  flex: 1;
  border-radius: 6px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0.5rem 1rem;
`;

export const ButtonInteractText = styled.span`
  font-size: 15px;
  padding-left: 0.5rem;
  @media (max-width: 680px) {
    padding: 0.25rem 0 0 0;
  }
  @media (max-width: 400px) {
    font-size: 10px;
  }
  ${(props) => props.active && `color: ${props.theme.primary}`}
  ${(props) => props.disabled && `color: ${props.theme.tinGray}`}
`;

export const ShareWrapper = styled.div`
  position: relative;
`;

export const ModalHover = styled.div`
  z-index: 99;
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 280px;
  right: 0px;
  bottom: ${(props) => props.bottom}px;
  border-radius: 4px;
  padding: 0.5rem 0;
  background-color: ${(props) => props.theme.white};
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1.41px 1.2px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ButtonShareWrapper = styled.div`
  ${(props) => props.disabled && `cursor: inherit`};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${(props) => props.theme.divider};
  ${(props) => props.noBorder && `border: none`};
  &:hover {
    ${(props) => !props.disabled && `background-color: ${props.theme.chipBackground};`};
  }
`;

export const ButtonShareText = styled.span`
  font-size: 15px;
  padding-left: 0.5rem;
`;

export const OverrideStyles = {
  iconInteract: {
    active: lightTheme.primary,
    unActive: lightTheme.gray,
    disabled: lightTheme.tinGray,
  },
  iconHover: {
    unActive: lightTheme.blueFade,
  },
  buttonShare: {
    fluid: {width: '100%'},
  },
};
