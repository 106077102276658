import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {translate} from '../../res/language/translate';
import {useWindowResizeEffect} from '../../lib/hooks/useWindowResizeEffect';
import MetaTag from '../../components/meta-tag';
import Screen from '../../views/screen';
import ContentMenu from './views/content-menu';
import Loading from '../../components/loading';
import LAYOUT from '../../styles/layout';
import * as ROUTES from '../../routes/routes-name';

const MenuScreen = () => {
  const {pathname} = useLocation();
  const {windowWidth} = useWindowResizeEffect();

  if (!pathname) {
    return null;
  }

  if (windowWidth >= LAYOUT.content.minFluid && pathname === ROUTES.ROUTE_MENU) {
    return <Redirect to={ROUTES.ROUTE_PROFILE} />;
  }

  return (
    <>
      <Loading loading={false} />
      <MetaTag title={translate('meta.menu')} description={translate('meta.menu')} />
      <Screen type="single-fluid" isChildScreen>
        <ContentMenu />
      </Screen>
    </>
  );
};

MenuScreen.propTypes = {};

export default MenuScreen;
