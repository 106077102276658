import styled from 'styled-components';
import NavigationTheme from '../../../styles/navigation';
import colors from '../../../styles/color';

export const Container = styled.header`
  z-index: 99;
  left: 0;
  right: 0;
  position: fixed;
  background-color: ${(props) => props.theme.white};
  @media (max-width: 1024px) {
    bottom: 0;
    border-top: 2px solid ${(props) => props.theme.gray239};
    ${(props) => props.forceChild && `display: none`};
  }
  @media (min-width: 1025px) {
    top: 0;
    box-shadow: ${NavigationTheme.NavigationHeadShadow.boxShadow2};
    ${(props) => props.forceBottom && `display: none`};
  }
`;

export const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  height: ${NavigationTheme.NavigationHeader.height}px;
  @media (max-width: 840px) {
    padding: 0 1rem;
  }
`;

export const NavCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 1024px) {
    width: calc(100vw);
  }
  @media (min-width: 1025px) {
    width: calc(50vw);
    max-width: 680px;
  }
`;

export const NavUlWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavLiItem = styled.li`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  @media (max-width: 1024px) {
    ${(props) => props.active && `border-top: 3px solid ${props.theme.primary};`};
  }
  @media (min-width: 1025px) {
    ${(props) => props.active && `border-bottom: 3px solid ${props.theme.primary}; margin-bottom: 1px;`};
  }

  :hover {
    ${(props) =>
      !props.active &&
      `border-radius: 6px;
      height: 52px;
      background-color: ${colors.button.lightHover100};`}
  }
`;

export const TextCount = styled.h3`
  color: ${colors.global.primary};
  margin-left: 0.5rem;
`;

export const TextNotifyNewCount = styled.h3`
  color: ${colors.global.danger};
  margin-left: 0.5rem;
`;

export const SpanIcon = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
`;

export const CenterAbsolute = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 250px) {
    display: none;
  }
`;

export const NavTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  overflow: hidden;
`;

export const NavTitle = styled.span`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${(props) => props.hasSubtitle && `font-size: 14px`};
`;

export const NavSubTitle = styled.span`
  margin-top: -0.2rem;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const GoBackWrapper = styled.div`
  cursor: pointer;
  margin-left: -1rem;
  margin-right: 1rem;
`;

export const Badge = styled.span`
  background-color: ${colors.global.danger};
  color: ${colors.global.white};
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1;
  height: 16px;
  width: 31px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: inherit;
  border-radius: 100px;
  position: relative;
  right: 10px;
`;

export const OverrideStyles = {
  link: {
    width: '100%',
    height: 56,
    justifyContent: 'center',
  },
};
