import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as numbers from '../../utils/number';
import * as ROUTES from '../../routes/routes-name';

export const useSearchCountState = () => {
  const {pathname} = useLocation();
  const [resultCount, setResultCount] = useState('');
  const searchQueryCount = useSelector((state) => state.search.jobQuery.query.newFeed?.countDisplay);
  const searchAuthorCount = useSelector((state) => state.search.jobAuthor.author.newFeed?.countDisplay);
  const searchHashtagCount = useSelector((state) => state.search.jobHashtag.hashtag.newFeed?.countDisplay);

  useEffect(() => {
    switch (pathname) {
      case ROUTES.ROUTE_SEARCH_RESULTS:
        setResultCount(numbers.numberWithComma({input: searchQueryCount}));
        break;
      case ROUTES.ROUTE_SEARCH_AUTHOR:
        setResultCount(numbers.numberWithComma({input: searchAuthorCount}));
        break;
      case ROUTES.ROUTE_SEARCH_HASHTAG:
        setResultCount(numbers.numberWithComma({input: searchHashtagCount}));
        break;
      case ROUTES.ROUTE_SEARCH_BY_ID:
        setResultCount(numbers.numberWithComma({input: 1}));
        break;
      default:
        setResultCount('');
        break;
    }
  }, [searchQueryCount, searchAuthorCount, searchHashtagCount, pathname]);

  return {
    resultCount,
  };
};
