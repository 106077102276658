import React from 'react';
import {translate} from '../../res/language/translate';
import {useAuth} from '../../lib/hooks/logic/useAuth';
import MetaTag from '../../components/meta-tag';
import Screen from '../../views/screen';
import ProfileView from './views';

const ProfileScreen = () => {
  const {isCheckedAuth} = useAuth();

  if (!isCheckedAuth) {
    return null;
  }

  return (
    <>
      <MetaTag title={translate('meta.profile')} description={translate('meta.profile')} />
      <Screen leftSidebar isChildScreen type="single-fluid">
        <ProfileView />
      </Screen>
    </>
  );
};

ProfileScreen.propTypes = {};

export default ProfileScreen;
