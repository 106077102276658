import React from 'react';
import queryString from 'querystring';
import * as routes from '../../routes/routes-name';
import * as storage from '../storage/storage';

export const navigationRef = React.createRef();

export const navigateTo = ({pathname, state = {}, search, querySearch}) => {
  if (!navigationRef.current) {
    return;
  }
  if (!pathname) {
    navigationRef.current.history.push(routes.ROUTE_HOME);
  }
  navigationRef.current.history.push({
    pathname: pathname,
    state: {...state},
    search: querySearch || queryString.stringify(search) || '',
  });
};

export const navigateReplaceTo = ({pathname, state = {}, search, querySearch}) => {
  if (!navigationRef.current) {
    return;
  }
  if (!pathname) {
    navigationRef.current.history.replace(routes.ROUTE_HOME);
  }
  navigationRef.current.history.replace({
    pathname: pathname,
    state: {...state},
    search: querySearch || queryString.stringify(search) || '',
  });
};

export const goBack = () => {
  if (!navigationRef.current) {
    return;
  }
  if (navigationRef.current.history?.action === 'POP' || window.history.length <= 2) {
    navigationRef.current.history.replace(routes.ROUTE_HOME);
    return;
  }
  const rdPath = storage.getItem(storage.KEY_RD);
  if (rdPath) {
    navigationRef.current.history.replace(routes.ROUTE_HOME);
    storage.clearItem(storage.KEY_RD);
    return;
  }
  navigationRef.current.history.goBack();
};

export const goBackToTop = () => {};

export const getCurrentPathname = () => {
  if (!navigationRef.current) {
    return null;
  }
  return navigationRef.current.history?.location?.pathname;
};

export const windowNavigateTo = ({pathname}) => {
  if (!pathname) {
    return;
  }
  window.location = `${pathname}`;
};

export const windowNavigateReplaceTo = ({pathname}) => {
  if (!pathname) {
    return;
  }
  window.location.replace(pathname);
};

export const windowOpen = ({pathname}) => {
  if (!pathname) {
    return;
  }
  window.open(pathname);
};
