import {createServiceURL} from '../../env/utils/env-services';
import {adapterPost} from '../../utils/adapter/post';
import {adapterGet} from '../../utils/adapter/get';

export const getDraft = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/resume/draft`;
  const response = await adapterGet({url: API_URL, privateMethod: true});
  return response;
};

export const updateResumeLink = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/resume/manual_upload`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const saveDraft = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/resume/draft`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const saveData = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/apply_job/save_data`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const sendMail = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/apply_job/send_mail`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};
