import styled from 'styled-components';

export const ChipWrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  margin: 0 0.5rem 0.5rem 0;
  border-radius: 4px;
  width: fit-content;
  background-color: ${(props) => props.theme.subSecondPrimary};
`;

export const ChipLabel = styled.span`
  color: ${(props) => props.theme.white};
  padding-right: 0.5rem;
`;

export const OptionWrapper = styled.div`
  width: 100%;
  ${(props) => props.solveTopEdgeInsets && `margin-top: -0.5rem`};
`;

export const LanguageCertItem = styled.div``;

export const OptionSpacer = styled.div`
  width: 3rem;
`;

export const InputSpacer = styled.div`
  width: 3rem;
`;

export const InputHidden = styled.input`
  display: none;
`;

export const LanguageItem = styled.div``;

export const OverrideStyles = {
  divider: {
    margin: '1rem 0 1rem 0',
  },
  textBottom: {
    marginBottom: '1rem',
  },
};
