/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {translate} from '../../../res/language/translate';
import {RowSettingMenu} from './styled-component';
import Settings from './settings';
import Text from '../../../components/text';
import Responsive from '../../../components/responsive';
import Icon from '../../../components/icon';
import LayoutContent from '../../../views/layout-content';
import LAYOUT from '../../../styles/layout';
import * as ICONS from '../../../components/icon/constants';

const ContentMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isVisibleContentMenu = LAYOUT.content.minFluid - 1;

  return (
    <Responsive maxWidth={isVisibleContentMenu}>
      <RowSettingMenu onClick={() => setIsOpen(!isOpen)}>
        <Text>{isOpen ? translate('notify.hide') : translate('notify.openSetting')}</Text>
        <Icon name={ICONS.SETTING} />
      </RowSettingMenu>
      {isOpen && (
        <LayoutContent>
          <Settings />
        </LayoutContent>
      )}
    </Responsive>
  );
};

ContentMenu.propTypes = {};

export default ContentMenu;
