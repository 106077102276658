export const formInit = {
  report_form: {
    type: 'report_form',
    require: true,
    value: [],
    message: '- ฟอร์ม Take down notice',
  },
  police_report: {
    type: 'police_report',
    require: true,
    value: [],
    message: '- บันทึกประจําวัน หรือหนังสือแจ้งความร้องทุกข์ (ต้นฉบับ)',
  },
  id_card: {
    type: 'id_card',
    require: true,
    value: [],
    message: '- บัตรประจําตัวประชาชนหรือบัตรที่ออกโดยหน่วยงานราชการที่ระบุเลขประจําตัวประชาชนของผู้ร้องเรียน',
  },
  police_proof_cert: {
    type: 'police_proof_cert',
    require: true,
    value: [],
    message: '- เอกสารหรือหลักฐานที่ได้ยื่นต่อพนักงานสอบสวนหรือเจ้าหน้าที่ตํารวจ',
  },
  name_change_cert: {
    type: 'name_change_cert',
    require: false,
    value: [],
  },
  others: {
    type: 'others',
    require: false,
    value: [],
  },
  power_of_attorney: {
    type: 'power_of_attorney',
    require: false,
    value: [],
  },
  attorney_id_card: {
    type: 'attorney_id_card',
    require: false,
    value: [],
  },
  assignee_id_card: {
    type: 'assignee_id_card',
    require: false,
    value: [],
  },
};

export const formInit2 = {
  report_form: {
    type: 'report_form',
    require: true,
    value: [],
    message: '- ฟอร์ม Take down notice',
  },
  police_report: {
    type: 'police_report',
    require: true,
    value: [],
    message: '- บันทึกประจําวัน หรือหนังสือแจ้งความร้องทุกข์ (ต้นฉบับ)',
  },
  id_card: {
    type: 'id_card',
    require: true,
    value: [],
    message: '- บัตรประจําตัวประชาชนหรือบัตรที่ออกโดยหน่วยงานราชการที่ระบุเลขประจําตัวประชาชนของผู้ร้องเรียน',
  },
  police_proof_cert: {
    type: 'police_proof_cert',
    require: true,
    value: [],
    message: '- เอกสารหรือหลักฐานที่ได้ยื่นต่อพนักงานสอบสวนหรือเจ้าหน้าที่ตํารวจ',
  },
  name_change_cert: {
    type: 'name_change_cert',
    require: false,
    value: [],
  },
  others: {
    type: 'others',
    require: false,
    value: [],
  },
  power_of_attorney: {
    type: 'power_of_attorney',
    require: false,
    value: [],
  },
  attorney_id_card: {
    type: 'attorney_id_card',
    require: false,
    value: [],
  },
  assignee_id_card: {
    type: 'assignee_id_card',
    require: false,
    value: [],
  },
};

export const formFileConfig = {
  maxSize: 1024 * 1024 * 2,
  sizeMB: 2,
  maxLength: 3,
};
