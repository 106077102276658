import React from 'react';
import PropTypes from 'prop-types';
import {RowWrapper} from './styled-component';

const Row = (props) => {
  const {wrap, children, content, alignStart, style} = props;
  return (
    <RowWrapper wrap={wrap} alignStart={alignStart} content={content} style={style}>
      {children}
    </RowWrapper>
  );
};

Row.propTypes = {
  wrap: PropTypes.oneOf(['wrap', 'nowrap']),
  children: PropTypes.any,
  content: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between']),
  alignStart: PropTypes.bool,
  style: PropTypes.any,
};

Row.defaultProps = {
  children: null,
  wrap: 'wrap',
  content: 'flex-start',
  alignStart: false,
  style: null,
};

export default Row;
