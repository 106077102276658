import React from 'react';
import PropTypes from 'prop-types';
import {DividerLI} from './styled-component';

const Divider = (props) => {
  const {style, edgeInsets, bold} = props;
  return <DividerLI style={style} edgeInsets={edgeInsets} bold={bold} />;
};

Divider.propTypes = {
  style: PropTypes.any,
  bold: PropTypes.bool,
  edgeInsets: PropTypes.bool,
};

Divider.defaultProps = {
  style: null,
  bold: false,
  edgeInsets: false,
};

export default Divider;
