import {useEffect} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import * as registerAction from '../../../redux/register/actions';
import * as navigationService from '../../../utils/navigation/navigation-services';
import * as ROUTES from '../../../routes/routes-name';
import * as mixpanel from '../../../utils/mixpanel';

export const useRegister = () => {
  const dispatch = useDispatch();
  const loggedInState = useSelector((state) => state.auth.auth.user, shallowEqual);
  const facebookRegisterState = useSelector((state) => state.auth.facebook.user, shallowEqual);
  const idState = useSelector((state) => state.register.registerId._id, shallowEqual);
  const currentStepState = useSelector((state) => state.register.visibleStep.currentStep, shallowEqual);
  const formGeneralState = useSelector((state) => state.register.step1.formRegister, shallowEqual);
  const formInfoState = useSelector((state) => state.register.step2.formRegister, shallowEqual);
  const formInterestState = useSelector((state) => state.register.interest.formRegister, shallowEqual);

  const dispatchUpdatePreviousStep = () => {
    dispatch(registerAction.userRegisterUpdateVisibleStep({data: currentStepState - 1}));
  };

  const dispatchUpdateNextStep = () => {
    dispatch(registerAction.userRegisterUpdateVisibleStep({data: currentStepState + 1}));
  };

  const dispatchUpdateFormGeneral = (form) => {
    if (!form) {
      return;
    }
    mixpanel.registerStepUsername();
    dispatch(registerAction.userRegisterUpdateFormGeneral({data: form}));
  };

  const dispatchUpdateFormInfo = (form, isNotNext = false) => {
    if (!form) {
      return;
    }
    mixpanel.registerStepInfo();
    dispatch(registerAction.userRegisterUpdateFormInfo({data: form}));
    if (!isNotNext) {
      dispatchUpdateNextStep();
    }
  };

  const dispatchUpdateFormInterest = (form, noPush = false) => {
    if (!form) {
      return;
    }
    mixpanel.registerStepInterest();
    dispatch(registerAction.userRegisterUpdateFormInterest({data: form}));
    if (!noPush) {
      navigationService.navigateReplaceTo({pathname: ROUTES.ROUTE_OTP});
    }
  };

  return {
    loggedInState,
    facebookRegisterState,
    idState,
    currentStepState,
    formGeneralState,
    formInfoState,
    formInterestState,
    dispatchUpdateFormGeneral,
    dispatchUpdateFormInfo,
    dispatchUpdateFormInterest,
    dispatchUpdatePreviousStep,
  };
};

export const useRegisterEffect = () => {
  const dispatch = useDispatch();
  const loggedInState = useSelector((state) => state.auth.auth.user, shallowEqual);
  const idState = useSelector((state) => state.register.registerId._id, shallowEqual);
  const currentStepState = useSelector((state) => state.register.visibleStep.currentStep, shallowEqual);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [currentStepState]);

  useEffect(() => {
    if (loggedInState) {
      navigationService.navigateReplaceTo({pathname: ROUTES.ROUTE_HOME});
    }
  }, [loggedInState, dispatch]);

  useEffect(() => {
    if (!idState && currentStepState === 2) {
      dispatch(registerAction.useGenerateId());
    }
  }, [idState, currentStepState, dispatch]);

  useEffect(() => {
    mixpanel.registerStep0();
  }, []);
};
