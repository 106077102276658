import * as TYPES from './type';

const initCompanyDetailState = {
  loading: false,
  error: null,
  data: null,
};

export const companyDetailReducer = (state = initCompanyDetailState, {type, payload = {}}) => {
  switch (type) {
    case TYPES.COMPANY_GET_DETAIL_REQ:
      return {...state, loading: true};
    case TYPES.COMPANY_GET_DETAIL_SUCCESS:
      return {...state, loading: false, data: payload};
    case TYPES.COMPANY_GET_DETAIL_FAIL:
      return {...state, error: payload};
    case TYPES.COMPANY_CLEAR_DETAIL:
      return {...state, error: null, data: null};
    default:
      return state;
  }
};
