import {useEffect, useState} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {average} from 'color.js';
import {useParamsEffect} from '../useSearchParams';
import * as companyProfileActions from '../../../redux/company-profile/action';
import {lightTheme} from '../../../styles/themes';

export const useCompanyProfileState = () => {
  const [isEmptyDataState, setIsEmptyDataState] = useState(false);
  const [metaGroupState, setMetaGroupState] = useState();
  const [backdropColorsState, setBackdropColorsState] = useState([]);
  const [stackFeedState, setStackFeedState] = useState([]);

  const {
    loading: companyLoadingState,
    data: companyDetailState,
    error: companyErrorState,
  } = useSelector((state) => state.company.detail, shallowEqual);

  const onReceivedImageUrl = async (url) => {
    try {
      const colors = await average(url, {format: 'hex'});
      const parserColors = Array.isArray(colors) ? colors : [colors];
      setBackdropColorsState([[...parserColors, lightTheme.white], true]);
    } catch (error) {
      setBackdropColorsState([[lightTheme.gradient2, lightTheme.white], false]);
    }
  };

  useEffect(() => {
    if (!companyDetailState?.jobs) {
      if (companyDetailState?.jobs?.length === 0) {
        setIsEmptyDataState(true);
      } else {
        setIsEmptyDataState(false);
      }
    }
  }, [companyDetailState]);

  useEffect(() => {
    if (companyDetailState?.company) {
      setMetaGroupState(companyDetailState?.company);
    }
  }, [companyDetailState]);

  useEffect(() => {
    if (companyDetailState?.jobs) {
      setStackFeedState(companyDetailState?.jobs);
    }
  }, [companyDetailState]);

  useEffect(() => {
    if (metaGroupState?.cover_image?.url) {
      onReceivedImageUrl(metaGroupState.cover_image.url);
    }
  }, [metaGroupState]);

  return {
    backdropColorsState,
    stackFeedState,
    metaGroupState,
    companyLoadingState,
    companyErrorState,
    isEmptyDataState,
  };
};

export const useCompanyProfileDispatch = () => {
  const dispatchFetchRefresh = () => {};

  return {dispatchFetchRefresh};
};

export const useCompanyProfileEffect = () => {
  const dispatch = useDispatch();
  const {paramsState} = useParamsEffect();

  useEffect(() => {
    if (paramsState?.id) {
      const reqBody = {
        author_id: paramsState?.id,
      };
      dispatch(companyProfileActions.companyDetail({data: reqBody}));
    }
    return () => dispatch(companyProfileActions.companyDetail({clear: true}));
  }, [dispatch, paramsState]);
};
