export const LOADING = 'loading';
export const HOME = 'home';
export const CHECKED_CHECKBOX = 'checked-checkbox';
export const UN_CHECKBOX = 'un-checkbox';
export const ARROW_UP = 'arrow-up';
export const ARROW_DOWN = 'arrow-down';
export const ARROW_LEFT = 'arrow-left';
export const ARROW_RIGHT = 'arrow-right';
export const MENU = 'menu';
export const CLOSE = 'close';
export const SEARCH = 'search';
export const LOCATION = 'location';
export const EMAIL_FILL = 'email-fill';
export const PASSWORD_FILL = 'pass-fill';
export const PASSWORD_SEE = 'pass-see';
export const PASSWORD_UN_SEE = 'pass-un-see';
export const CALENDAR = 'calendar';

export const FACEBOOK = 'facebook-square';
export const FACEBOOK_LOGIN = 'facebook-login';
export const EMAIL = 'email';
export const LOCK = 'lock';
export const PLUS = 'plus';
export const UPLOAD = 'upload';
export const CHECK = 'check';
export const MINUS = 'minus';
export const SETTING = 'setting';
export const HELP = 'help';
export const PHONE_CALL = 'phone-call';
export const LOCKOUT = 'logout';
export const CAMERA = 'camera';

export const BELL = 'bell';
export const BELL_FILL = 'bell-fill';
export const LIKE_FILL = 'like-fill';
export const LIKE = 'like';
export const LIKE_OUTLINE = 'like-outline';
export const LINE = 'line';
export const SHARE = 'share';
export const CONTACT = 'contact';
export const EMAIL_OUTLINE = 'email-outline';
export const BOOKMARK = 'bookmark';
export const BOOKMARK_FILL = 'bookmark-fill';
export const FILTER = 'filter';
export const BAG_BUSINESS = 'bag-business';
export const EDUCATION = 'education';
export const EXPERIENCE = 'experience';
export const DOLLAR = 'dollar';
export const COPY = 'copy';
export const GROUP = 'group';
export const NOTIFY = 'notify';
export const SHOW_PASSWORD = 'show-password';
export const HIDE_PASSWORD = 'hide-password';
export const FACEBOOK_SHARE = 'facebook-share';
export const MESSENGER_SHARE = 'messenger-share';

export const CHECKBOX_CHECKED = 'checkbox-checked';
export const CHECKBOX_UNCHECK = 'checkbox-uncheck';
export const NETWORK_WF = 'network-wf';
export const MORE_HORIZONTAL = 'more-horizontal';
export const USER = 'user';

export const DOCUMENTS = 'documents';
export const USER_VERIFY = 'user-verify';
export const BUILDING = 'building';
export const STAR = 'star';
export const DIRECTION = 'direction';
export const LOCATION_FILL = 'location-fill';
export const CHAT_FILL = 'chat-fill';
export const SHARE_OTHER = 'share-other';
export const LOGIN_OUTLINE = 'login-outline';

export const SEND_EMAIL = 'send-email';
export const COMMENT = 'comment';
export const INFO_FILL = 'info-fill';
export const ACHIEVEMENT = 'achievement';
export const DOCUMENT = 'documents';
export const CLOSE_SQUARE = 'close-square';
export const EDIT_SQUARE = 'edit-square';
export const WORLD = 'world';
export const TRAVEL_BUS = 'travel-bus';
export const CHECK_CIRCLE = 'check-circle';
export const CLOSE_CIRCLE = 'close-circle';
export const TITLE = 'title';
export const ATTACHMENT = 'attachment';
export const CLOSE_OUTLINE = 'close-outline';
export const BOOKMARK_STAR = 'bookmark-start';
export const CERTIFICATE = 'certificate';

export const NOTIFICATION = 'notification';
export const NOTIFICATION_IMPORTANT = 'notification-important';
export const NOTIFICATION_ACTIVE = 'notification-active';
export const TRASH = 'trash';
