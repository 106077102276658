/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
import keyBy from 'lodash/keyBy';
import parseInt from 'lodash/parseInt';
import {translate} from '../../res/language/translate';
import * as typeDef from '../../utils/strong-types';

export const createFilterBusinessTypes = () => {
  return [
    {
      label: translate('search.filter.business.private'),
      value: translate('search.filter.business.private'),
    },
    {
      label: translate('search.filter.business.government'),
      value: translate('search.filter.business.government'),
    },
    {
      label: translate('search.filter.business.stateEnterprise'),
      value: translate('search.filter.business.stateEnterprise'),
    },
  ];
};

export const createFilterEducationLevel = () => {
  return [
    {
      label: translate('search.filter.education.m3'),
      value: 'ม3',
    },
    {
      label: translate('search.filter.education.m6'),
      value: 'ม6',
    },
    {
      label: translate('search.filter.education.vocational'),
      value: 'ปวช',
    },
    {
      label: translate('search.filter.education.heightVocational'),
      value: 'ปวส',
    },
    {
      label: translate('search.filter.education.bachelor'),
      value: 'ปตรี',
    },
    {
      label: translate('search.filter.education.master'),
      value: 'ปโท',
    },
    {
      label: translate('search.filter.education.docker'),
      value: 'ปเอก',
    },
  ];
};

export const createFilterExp = () => {
  return [
    {
      label: translate('search.filter.exp.intern'),
      value: -1,
    },
    {
      label: translate('search.filter.exp.partTime'),
      value: -2,
    },
    {
      label: translate('search.filter.exp.empty'),
      value: 0,
    },
    {
      label: translate('search.filter.exp.1year'),
      value: 1,
    },
    {
      label: translate('search.filter.exp.2year'),
      value: 2,
    },
    {
      label: translate('search.filter.exp.3year'),
      value: 3,
    },
    {
      label: translate('search.filter.exp.4year'),
      value: 4,
    },
    {
      label: translate('search.filter.exp.5year'),
      value: 5,
    },
  ];
};

export const transformValueToLabel = ({type, key}) => {
  if (!key) {
    return null;
  }

  if (type === 'business') {
    const keyBusiness = keyBy(createFilterBusinessTypes(), 'value');
    return keyBusiness[key]?.value;
  }
  if (type === 'edu') {
    const keyEducation = keyBy(createFilterEducationLevel(), 'value');
    if (typeDef.isArrayLength(key)) {
      const edu = [];
      key.forEach((x) => {
        if (!typeDef.isNullOrUndefined(x)) {
          edu.push(keyEducation[x]?.value);
        }
      });
      return edu;
    }
    return [keyEducation[key]?.value];
  }
  if (type === 'exp') {
    const keyExperience = keyBy(createFilterExp(), 'value');
    if (typeDef.isArrayLength(key)) {
      const exp = [];
      key.forEach((x) => {
        if (!typeDef.isNullOrUndefined(x)) {
          exp.push(keyExperience[x]?.value);
        }
      });
      return exp;
    }
    return [keyExperience[key]?.value];
  }
  if (type === 'salary') {
    const keyBusiness = keyBy(createFilterBusinessTypes(), 'value');
    return keyBusiness[key]?.value;
  }
  return '';
};

export const transformUrlParamsToQuery = (rawForm) => {
  if (!rawForm || !typeDef.isObject(rawForm)) {
    return rawForm;
  }
  const searchForm = {};
  for (const key in rawForm) {
    switch (key) {
      case 'author_name':
        searchForm[key] = rawForm[key];
        break;
      case 'author_id':
        searchForm[key] = rawForm[key];
        break;
      case 'experience_requirement':
        const exp = rawForm[key] || [];
        searchForm[key] = typeDef.isArrayLength(exp) ? exp.map((x) => parseInt(x)) : parseInt(exp);
        break;
      case 'gender_requirement':
        searchForm[key] = rawForm[key];
        break;
      case 'education_requirement':
        const edu = rawForm[key];
        searchForm[key] = typeDef.isArrayLength(edu) ? edu.map((x) => x) : edu;
        break;
      case 'jobcat':
        if (typeDef.isArray(rawForm[key])) {
          searchForm[key] = rawForm[key];
        } else {
          searchForm[key] = [rawForm[key]];
        }
        break;
      case 'subjobcat':
        if (typeDef.isArray(rawForm[key])) {
          searchForm[key] = rawForm[key];
        } else {
          searchForm[key] = [rawForm[key]];
        }
        break;
      case 'salary_min':
        const sal_min = rawForm[key];
        searchForm[key] = parseInt(sal_min);
        break;
      case 'salary_max':
        const sal_max = rawForm[key];
        searchForm[key] = parseInt(sal_max);
        break;
      case 'search':
        searchForm[key] = rawForm[key];
        break;
      case 'district':
        searchForm[key] = rawForm[key];
        break;
      case 'province':
        searchForm[key] = rawForm[key];
        break;
      case 'tag':
        searchForm.tag = rawForm[key];
        break;
      default:
        break;
    }
  }
  if (searchForm.province === 'ทุกจังหวัด') {
    delete searchForm.province;
  }
  return searchForm;
};

export const checkIsActiveChip = ({selectedValues, key}) => {
  if (typeDef.isNotArrayLength(selectedValues) || typeDef.isNullOrUndefined(key)) {
    return false;
  }
  const isActive = selectedValues.findIndex((x) => x === key);
  return isActive >= 0;
};
