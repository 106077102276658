import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useWebNavigator} from '../../../../../lib/hooks/useWebNavigator';
import {translate} from '../../../../../res/language/translate';
import {ShareWrapper, ButtonInteractWrapper, ButtonInteractText, ModalHover, OverrideStyles} from '../styled-component';
import {FacebookShareDialog, MessengerShareDialog, CopyShare, OtherShare} from './share-sub';
import Icon from '../../../../../components/icon';
import ContactItem from '../contact/contact-sub';
import Responsive from '../../../../../components/responsive';
import BottomSheets from '../../../../bottom-sheets';
import * as ICONS from '../../../../../components/icon/constants';
import {interactTypes} from '../../../../../lib/hooks-logic/useTrackEvent';

const BREAK_POINT = 1024;

const PostShare = (props) => {
  const {id, title, description, jobData, onEventTracking} = props;
  const {isCanShareNative} = useWebNavigator();
  const nodeButtonRef = useRef(null);
  const [isHover, setIsHover] = useState(false);
  const [isShowSheet, setIsShowSheet] = useState(false);

  const onEventDemiseHover = () => {
    setIsHover(false);
  };

  const onEventActiveHover = () => {
    setIsHover(true);
    onEventTracking(interactTypes.share);
  };

  const onEventHideSheet = () => {
    setIsShowSheet(false);
  };

  const onEventShowSheet = () => {
    setIsShowSheet(true);
  };

  return (
    <>
      <Responsive maxWidth={BREAK_POINT}>
        <BottomSheets noEdgeInsets isOpen={isShowSheet} onRequestClose={() => setIsShowSheet(false)}>
          <FacebookShareDialog
            jobData={jobData}
            id={id}
            title={title}
            description={description}
            noBorder={false}
            onEventCallback={onEventHideSheet}
          />
          {!isCanShareNative && (
            <MessengerShareDialog
              jobData={jobData}
              id={id}
              title={title}
              description={description}
              noBorder={false}
              onEventCallback={onEventHideSheet}
            />
          )}
          {isCanShareNative && (
            <OtherShare jobData={jobData} id={id} title={title} description={description} noBorder={false} onEventCallback={onEventHideSheet} />
          )}
          <CopyShare jobData={jobData} id={id} noBorder={false} onEventCallback={onEventHideSheet} />
          <ContactItem type="close" onEventCallback={onEventHideSheet} />
        </BottomSheets>
      </Responsive>
      <ShareWrapper ref={nodeButtonRef} onMouseEnter={onEventActiveHover} onMouseLeave={onEventDemiseHover}>
        {isHover && (
          <ModalHover bottom={nodeButtonRef.current?.clientHeight}>
            <FacebookShareDialog jobData={jobData} id={id} title={title} description={description} onEventCallback={onEventDemiseHover} />
            {!isCanShareNative && (
              <MessengerShareDialog jobData={jobData} id={id} title={title} description={description} onEventCallback={onEventDemiseHover} />
            )}
            {isCanShareNative && (
              <OtherShare jobData={jobData} id={id} title={title} description={description} noBorder={false} onEventCallback={onEventDemiseHover} />
            )}
            <CopyShare jobData={jobData} id={id} onEventCallback={onEventDemiseHover} />
          </ModalHover>
        )}
        <Responsive minWidth={BREAK_POINT + 1}>
          <ButtonInteractWrapper>
            <Icon name={ICONS.SHARE} size={20} color={OverrideStyles.iconInteract.unActive} />
            <ButtonInteractText>{translate('timeline.share')}</ButtonInteractText>
          </ButtonInteractWrapper>
        </Responsive>
        <Responsive maxWidth={BREAK_POINT}>
          <ButtonInteractWrapper onClick={onEventShowSheet}>
            <Icon name={ICONS.SHARE} size={20} color={OverrideStyles.iconInteract.unActive} />
            <ButtonInteractText>{translate('timeline.share')}</ButtonInteractText>
          </ButtonInteractWrapper>
        </Responsive>
      </ShareWrapper>
    </>
  );
};

PostShare.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  jobData: PropTypes.object.isRequired,
  onEventTracking: PropTypes.func.isRequired,
};

PostShare.defaultProps = {
  id: null,
  title: null,
  description: null,
};

export default PostShare;
