import React, {useState} from 'react';
import {Container, SectionWrapper, OverrideStyle} from './styled-component';
import Header from '../../../components/header';
import WalkThrough from '../../../components/walk-through';
import Row from '../../../components/row';
import {translate} from '../../../res/language/translate';
import {lightTheme} from '../../../styles/themes';
import * as utils from './utils';

const BoxWalkThrough = () => {
  const [presentDataState] = useState(() => utils.createTutorialStepData());

  return (
    <Container>
      <SectionWrapper>
        <Header center variant="h2" color={lightTheme.primary}>
          {translate('landing.findStep')}
        </Header>
        <Row content="center" style={OverrideStyle.walkThrough}>
          {presentDataState.map((item, index) => (
            <WalkThrough key={index.toString()} title={item.title} description={item.description} icon={item.source} />
          ))}
        </Row>
      </SectionWrapper>
    </Container>
  );
};

BoxWalkThrough.propTypes = {};

export default BoxWalkThrough;
