import {NavItem, NavSearchWrapper, NavNoneWrapper, OverrideStyles} from './styled-component';
import Icon from '../../components/icon';
import Text from '../../components/text';
import Responsive from '../../components/responsive';
import {translate} from '../../res/language/translate';
import * as navigationServices from '../../utils/navigation/navigation-services';
import * as ICONS from '../../components/icon/constants';
import * as ROUTES from '../../routes/routes-name';

const MIN_FLUID = 876;

const Searcher = () => {
  const onEventSearch = () => {
    navigationServices.navigateTo({pathname: ROUTES.ROUTE_SEARCH});
  };

  return (
    <NavNoneWrapper>
      <NavItem noSpacer>
        <NavSearchWrapper onClick={onEventSearch}>
          <Icon name={ICONS.SEARCH} size={20} />
          <Responsive minWidth={MIN_FLUID}>
            <Text style={OverrideStyles.textSearch}>{translate('screenTitle.search')}</Text>
          </Responsive>
        </NavSearchWrapper>
      </NavItem>
    </NavNoneWrapper>
  );
};

Searcher.propTypes = {};

export default Searcher;
