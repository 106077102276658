import {useInitEventEffect} from './init-event/index';
import {useTrackingEventEffect} from './tracking-event/index';
import CookieConsentView from './views/cookie-consent';

const RootView = () => {
  useInitEventEffect();
  useTrackingEventEffect();

  return (
    <>
      <CookieConsentView />
    </>
  );
};
export default RootView;
