import styled from 'styled-components';

export const SVGContainer = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : '250px')};
  height: ${(props) => (props.height ? `${props.height}px` : '250px')};
  ${(props) => props.size && `width: ${props.size}px; height: ${props.size}px`};
  ${(props) =>
    props.autoScale &&
    `height: auto;
     width: auto;
     max-height: ${props.maxHeight}px;
  `};
`;
