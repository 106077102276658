import styled from 'styled-components';

export const JobApplyContainer = styled.div`
  background: ${(props) => props.theme.lightHover100};
  padding: 1rem;
  margin: 0.5rem -1rem 0 -1rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 680px) {
    margin: 0;
  }
`;

export const H5 = styled.h5`
  margin-left: 0.5rem;
  font-size: 16px;
  display: block;
  ${(props) => `color: ${props.isCheck ? 'green' : props.theme.gray45}`}
`;

export const Text = styled.div`
  font-size: 14px;
  margin-left: 0.5rem;
  ${(props) => `color: ${props.theme.gray45}`}
`;
