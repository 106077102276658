import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import debounce from 'lodash/debounce';
import {useIntl} from 'react-intl';
import get from 'lodash/get';
import ReCAPTCHA from 'react-google-recaptcha';
import {useWindowResizeEffect} from '../../../../lib/hooks/useWindowResizeEffect';
import Modal from '../../../../components/modal';
import Header from '../../../../components/header';
import Divider from '../../../../components/divider';
import Input from '../../../../components/input';
import Row from '../../../../components/row';
import Button from '../../../../components/button';
import Checkbox from '../../../../components/checkbox';
import {Wrapper, TopicWrapper, Section, ReasonTextArea, Body, Bottom, Title, ErrorText, ErrorTextWrapper, BottomHiddenDiv} from '../styled-component';
import DownloadForm from './download-form';
import UploadForm from './upload-form';
import {formInit, formInit2} from './form-init';
import * as CONTEXT_ACTIONS from '../../../../redux/context/action';
import * as REPORT_ACTIONS from '../../../../redux/report-job/action';
import * as AUTH_ACTIONS from '../../../../redux/auth/action';
import * as ALERT from '../../../../utils/components/alert/provider';
import * as dataInit from '../data-init';
import * as validate from '../validate';
import * as logic from './logic';
import ENV from '../../../../env.json';

const OTHER_ELEM = 120;

const ModalReportPost = () => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const reportState = useSelector((state) => state.context.reportPost);
  const isLoadingCreateReportState = useSelector((state) => state.reportJob.delete.loading);

  const nodeScrollEndRef = useRef(null);
  const {windowHeight, windowWidth} = useWindowResizeEffect();
  const [topicOptions] = useState([...dataInit.reportPostTopic]);
  const [errorState, setErrorState] = useState({});
  const [formState, setFormState] = useState({...dataInit.formFields});
  const [reCaptchaState, setReCaptchaState] = useState({value: null, expired: false});
  const [formFileState, setFormFileState] = useState({...formInit});

  useEffect(() => {
    if (reportState?.id) {
      setFormState({...formState, job_id: reportState.id});
    }
  }, [reportState]);

  useEffect(() => {
    if (get(reportState, 'isVisible', false) || get(reportState, 'id', null)) {
      dispatch(AUTH_ACTIONS.firebaseAnonymousLogin());
    }
  }, [dispatch, reportState]);

  useEffect(() => {
    return () => dispatch(AUTH_ACTIONS.firebaseAnonymousLogout());
  }, [dispatch]);

  const cloneState = () => {
    return {...formState};
  };

  const cloneErrorState = () => {
    return {...errorState};
  };

  const onRequestCloseModal = () => {
    dispatch(CONTEXT_ACTIONS.contextHideReportPost());
    setFormState({...dataInit.formFields});
    setErrorState({});
    setReCaptchaState({value: null, expired: false});
    setFormFileState({...formInit2});
    dispatch(AUTH_ACTIONS.firebaseAnonymousLogout());
  };

  const onChangeReason = (reason) => {
    const newFormState = cloneState();
    const newErrorState = cloneErrorState();
    newFormState.reason = reason;
    if (reason !== 'other' && newFormState.other_reason) {
      newFormState.other_reason = '';
    }
    delete newErrorState.reason;
    setErrorState(newErrorState);
    setFormState(newFormState);
  };

  const onChangeField = (key, value) => {
    if (!key) {
      return;
    }
    const newFormState = cloneState();
    const newErrorState = cloneErrorState();
    newFormState[key] = value;
    delete newErrorState[key];
    if (key === 'other_reason') {
      delete newErrorState.reason;
    }
    setErrorState(newErrorState);
    setFormState(newFormState);
  };

  const onChangeReCaptcha = (value) => {
    if (!value) {
      setReCaptchaState({value, expired: true});
      return;
    }
    const newErrorState = cloneErrorState();
    delete newErrorState.reCaptcha;
    setReCaptchaState({value, expired: false});
    setErrorState(newErrorState);
  };

  const debounceScrollToEnd = debounce(() => {
    if (nodeScrollEndRef.current) {
      nodeScrollEndRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, 300);

  const onSubmit = () => {
    if (isLoadingCreateReportState) {
      return;
    }
    const [isValidate, errorField] = validate.validateFormReportPost(formState, reCaptchaState);
    if (!isValidate) {
      setErrorState({...errorField});
      debounceScrollToEnd();
      return;
    }
    const validateFiles = validate.validateAttachRequire(formFileState);
    if (validateFiles) {
      ALERT.setAlert(validateFiles);
      return;
    }
    const reqBody = {...formState};
    if (reqBody.reason === 'other') {
      reqBody.reason = formState.other_reason;
    }
    delete reqBody.other_reason;
    reqBody.attachments = logic.transAttachments(formFileState);
    onRequestCloseModal();
    dispatch(REPORT_ACTIONS.reportCreateNewReport({data: reqBody}));
  };

  const getBodyHeight = () => {
    if (!windowHeight) {
      return 0;
    }
    if (windowWidth < 1024) {
      return windowHeight - OTHER_ELEM;
    }
    return windowHeight * 0.9 - OTHER_ELEM;
  };

  if (!get(reportState, 'isVisible', false) || !get(reportState, 'id', null)) {
    return null;
  }

  return (
    <Modal zIndex={899} visible onRequestClose={() => {}}>
      <Wrapper>
        <Title>
          <Header variant="h3" center>
            {formatMessage({defaultMessage: 'แจ้งรายงานความไม่เหมาะสม'})}
          </Header>
        </Title>
        <Body height={getBodyHeight()}>
          <Section>
            <Header variant="h4">{formatMessage({defaultMessage: 'เนื่องจาก'})}</Header>
            <TopicWrapper>
              {Array.isArray(topicOptions) &&
                topicOptions.map((item, index) => (
                  <Checkbox
                    key={index.toString()}
                    fullText
                    checked={get(formState, 'reason') === item.value}
                    label={item.label}
                    onChange={() => onChangeReason(item.value)}
                  />
                ))}
              {get(formState, 'reason') === 'other' && (
                <Input
                  borderOutline
                  type="text"
                  placeholder={formatMessage({defaultMessage: 'กรอกเหตุผลที่แจ้งรายงาน'})}
                  value={formState.other_reason || ''}
                  onChange={(value) => onChangeField('other_reason', value)}
                />
              )}
            </TopicWrapper>
            {errorState?.reason && (
              <ErrorTextWrapper top="-0.75rem">
                <ErrorText noTop>{errorState?.reason}</ErrorText>
              </ErrorTextWrapper>
            )}
          </Section>
          <Section>
            <Header variant="h4">{formatMessage({defaultMessage: 'ระบุเหตุผลของคุณเพิ่มเติมเพื่อประกอบการตัดสินใจ'})}</Header>
            <ReasonTextArea value={formState.detail || ''} onChange={(event) => onChangeField('detail', event.target.value)} />
            {errorState?.detail && (
              <ErrorTextWrapper top="-0.25rem">
                <ErrorText>{errorState?.detail}</ErrorText>
              </ErrorTextWrapper>
            )}
          </Section>
          <Section mini bottom="1rem">
            <DownloadForm />
          </Section>
          <Divider />
          <Section mini bottom="1rem">
            <UploadForm jobId={reportState?.id || ''} formFileState={formFileState} setFormFileState={setFormFileState} />
          </Section>
          <Divider />
          <Section>
            <Header variant="h4">{formatMessage({defaultMessage: 'ข้อมูลติดต่อกลับของคุณ'})}</Header>
            <Input
              borderOutline
              label={formatMessage({defaultMessage: 'อีเมล'})}
              placeholder={formatMessage({defaultMessage: 'กรอกอีเมล'})}
              type="email"
              value={formState.email || ''}
              onChange={(value) => onChangeField('email', value)}
              warningText={errorState?.email || ''}
            />
            <Input
              borderOutline
              type="text"
              label={formatMessage({defaultMessage: 'เบอร์โทรศัพท์'})}
              placeholder={formatMessage({defaultMessage: 'กรอกเบอร์โทรศัพท์'})}
              value={formState.tel || ''}
              onChange={(value) => onChangeField('tel', value)}
              warningText={errorState?.tel || ''}
            />
          </Section>

          <Section bottom="2rem">
            <ReCAPTCHA sitekey={ENV.PUBLIC.GOOGLE_CAPTCHA} onChange={onChangeReCaptcha} />
            {errorState?.reCaptcha && (
              <ErrorTextWrapper top="-0.25rem">
                <ErrorText noTop>{errorState.reCaptcha}</ErrorText>
              </ErrorTextWrapper>
            )}
          </Section>
          <BottomHiddenDiv ref={nodeScrollEndRef} />
        </Body>
        <Bottom>
          <Row>
            <Button variant="light" onClick={onRequestCloseModal}>
              {formatMessage({defaultMessage: 'ยกเลิก'})}
            </Button>
            <Button onClick={onSubmit}>{formatMessage({defaultMessage: 'แจ้งรายงานโพสต์นี้'})}</Button>
          </Row>
        </Bottom>
      </Wrapper>
    </Modal>
  );
};

ModalReportPost.propTypes = {};

export default ModalReportPost;
