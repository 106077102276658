import {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import * as contextActions from '../../../redux/context/action';

export const useImageViewer = () => {
  const dispatch = useDispatch();
  const imagesState = useSelector((state) => state.context.imageViewer, shallowEqual);

  const dispatchViewImage = (data) => {
    if (!Array.isArray(data?.data)) {
      return;
    }
    dispatch(contextActions.contextImageViewer({data}));
  };
  const dispatchClearImage = () => {
    dispatch(contextActions.contextImageViewer({clear: true}));
  };

  return {
    imagesState,
    dispatchViewImage,
    dispatchClearImage,
  };
};

export const useImageViewerEffect = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(contextActions.contextImageViewer({clear: true}));
    };
  }, [dispatch]);
};
