/* eslint-disable no-restricted-syntax */
import get from 'lodash/get';
import {regexEmail, regexTel} from '../../../utils/regex';

export const validateFormReportPost = (form, reCaptcha) => {
  const errorField = {};
  if (!get(form, 'reason')) {
    errorField.reason = 'กรุณาเลือกเหตุผลที่แจ้งรายงานโพสต์';
  }
  if (get(form, 'reason') && form.reason === 'other' && !get(form, 'other_reason')) {
    errorField.reason = 'กรุณาระบุเหตุผลที่แจ้งรายงานโพสต์';
  }
  if (get(form, 'reason') && form.reason === 'other' && get(form, 'other_reason')) {
    if (form.other_reason.length > 200) {
      errorField.reason = 'ระบุเหตุผลที่แจ้งรายงานโพสต์ไม่เกิน 200 ตัวอักษร';
    }
  }
  if (!get(form, 'detail')) {
    errorField.detail = 'กรุณาระบุรายละเอียดที่แจ้งรายงานโพสต์';
  }
  if (get(form, 'detail') && form.detail.length > 500) {
    errorField.detail = 'ระบุรายละเอียดที่แจ้งรายงานโพสต์ ไม่เกิน 500 ตัวอักษร';
  }
  if (!get(form, 'email')) {
    errorField.email = 'กรุณากรอกอีเมลสำหรับติดต่อกลับ';
  }
  if (get(form, 'email')) {
    const isValidEmail = regexEmail({input: form.email});
    if (!isValidEmail) {
      errorField.email = 'อีเมลไม่ถูกต้อง';
    }
  }
  if (get(form, 'tel')) {
    const isValidTel = regexTel(form.tel);
    if (!isValidTel) {
      errorField.tel = 'เบอร์โทรศัพท์ไม่ถูกต้อง';
    }
  }
  if (!get(reCaptcha, 'value')) {
    errorField.reCaptcha = 'กรุณายืนยันว่าคุณไม่ใช่โปรแกรมอัตโนมัติ';
  }
  const isValidate = Object.keys(errorField).length === 0;
  return [isValidate, errorField];
};

export const validateAttachRequire = (formAttach) => {
  if (Object.keys(formAttach).length === 0) {
    return 'กรุณาอัพโหลดไฟล์ที่ระบุให้ถูกต้อง';
  }
  let errorMsg = '';
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(formAttach)) {
    if (value.require && value.value.length < 1 && value.message) {
      errorMsg += `${value.message}\n`;
    }
  }
  if (errorMsg) errorMsg = 'กรุณาอัพโหลดไฟล์ต่อไปนี้\n'.concat(errorMsg);
  return errorMsg;
};
