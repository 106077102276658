import * as number from '../../../utils/number';
import * as typeDef from '../../../utils/strong-types';
import * as bodyRewrite from './body-rewrite';
import * as bodyCleanup from './body-cleanup';
import {BLACKLIST_BODY_LINE, BLACKLIST_FIRST_LINE} from '../../job-post-builder/utils/backlist-text';
import {TH_PATTERN, EN_PATTERN} from '../../job-post-builder/utils/header-regex';

export const MAGIC_CHAR = 'ü';

export const getValueOfFirst = (data) => {
  try {
    return data[0];
  } catch (error) {
    return '';
  }
};

export const replaceDescriptionHashtag = ({text}) => {
  if (!text) {
    return '';
  }

  const regex = /#+([\u0E00-\u0E7Fa-zA-Z0-9_]+)/gi;
  const replaced = text.replace(regex, () => '');
  return replaced.replace(/(\n\s*?\n)\s*\n/, '$1');
};

export const replacePrefixLocation = ({location}) => {
  try {
    const startIndex = location.indexOf('|');
    return location.substr(startIndex + 1, location.length - 1);
  } catch (error) {
    return location;
  }
};

export const getSalary = ({min, max}) => {
  if (!min && !max) {
    return '';
  }
  if (min && !max) {
    return number.numberWithK({input: min});
  }
  if (!min && max) {
    return number.numberWithK({input: max});
  }
  if (min === max) {
    return number.numberWithK({input: max});
  }
  const salary = ''.concat(number.numberWithK({input: min}), ' - ', number.numberWithK({input: max}));
  return salary;
};

const cleanText = (str) =>
  str
    .trim()
    .replace(/(\r?\n)+/g, '\n')
    .replace(/[ \t]+/g, ' ');

export const createLessText = ({rawText}) => {
  if (!rawText) {
    return '';
  }
  const blacklistEN = 'Job Descriptions\n|Job Description\n|';
  const blacklistTH = 'รายละเอียด\n|รายละเอียดงาน\n|รายละเอียดตำแหน่งงาน\n|หน้าที่และความรับผิดชอบ\n';
  const blacklistPattern = new RegExp(blacklistEN.concat(blacklistTH), 'g');
  return cleanText(rawText.replace(blacklistPattern, ''));
};

const removeDoubleHeader = (finalText) => {
  const ans = [];
  let isHeader = false;
  for (let i = 0, ii = finalText.length; i < ii; i++) {
    const isNewHeader = /<h>/.test(finalText[i]);
    if (isNewHeader && isHeader) ans.pop();
    isHeader = isNewHeader;
    ans.push(finalText[i]);
  }
  return ans;
};

const isRowHeader = (text) => {
  return /<h>/g.test(text);
};

const finalTransformBody = (finalTextArr, translationSections) => {
  if (!Array.isArray(finalTextArr)) {
    return finalTextArr;
  }
  const renderText = [];
  finalTextArr.forEach((txt, i) => {
    if (i === 0) {
      if (!isRowHeader(txt) && !isRowHeader(translationSections[0])) {
        const textTrans = translationSections[0];
        const pushText = textTrans[textTrans.length - 1] === '\n' ? textTrans.slice(0, -1) : textTrans;
        renderText.push(pushText + MAGIC_CHAR);
      }
    }
    if (isRowHeader(txt)) {
      renderText.push(txt);
    }
    if (!isRowHeader(txt) && i > 0) {
      const prevIsHeader = isRowHeader(finalTextArr[i - 1]);
      if (!prevIsHeader) {
        renderText.push(txt);
      } else {
        const transHeaderIndex = translationSections.findIndex((a) => a === finalTextArr[i - 1]);
        if (transHeaderIndex > -1) {
          const textTrans = translationSections[transHeaderIndex + 1];
          const pushText = textTrans[textTrans.length - 1] === '\n' ? textTrans.slice(0, -1) : textTrans;
          renderText.push(pushText + MAGIC_CHAR);
        } else {
          renderText.push(txt);
        }
      }
    }
  });
  // show console
  console.log(finalTextArr, 'base');
  console.log(translationSections, 'section');
  console.log(renderText, 'trans');
  return renderText;
};

export const createPrettyText = ({rawText, source, location, postSections}) => {
  if (!rawText) {
    return '';
  }
  const patternEN = EN_PATTERN.join('|');
  const patternTH = TH_PATTERN.join('|');
  const regexPattern = new RegExp(patternEN.concat('|').concat(patternTH), 'g');
  const headText = rawText.trim().replace(regexPattern, (m) => `<h>${m.trim().replace(/^[: \-*]+|[: \-*]+$/g, '')}</h>`);
  const finalText = headText.split(/(<h>.*?<\/h>)/g).filter((v) => v !== '' && v !== undefined);
  if (typeDef.isArrayLength(finalText)) {
    const finalCleanedText = removeDoubleHeader(finalText.map(cleanText));
    const rewriteText = [];
    const hasHeader = finalCleanedText.filter((txt) => /^<h>/.test(txt));
    if (!hasHeader.length) finalCleanedText.unshift('<h>รายละเอียดงานเบื้องต้น</h>');
    let cleanedBody = bodyCleanup.cleanUpLocation(finalCleanedText, location, source);
    cleanedBody = bodyCleanup.cleanUpSalary(cleanedBody, source);
    cleanedBody.forEach((a, i) => {
      if (a.includes('<h>')) {
        rewriteText.push(a);
      } else {
        const head = cleanedBody[i - 1];
        const count = cleanedBody.length;
        const renderText = bodyRewrite.splitBody(a, head, count, source);
        const joinText = Array.isArray(renderText) ? renderText.join('\n').concat(MAGIC_CHAR) : renderText;
        rewriteText.push(joinText);
      }
    });
    if (Array.isArray(postSections)) {
      return finalTransformBody(rewriteText, postSections);
    }
    return rewriteText;
  }
  const cleanedText = cleanText(rawText);
  const reWriteText = ['<h>รายละเอียดงานเบื้องต้น</h>', cleanedText];
  if (Array.isArray(postSections) && postSections.length === 1 && !isRowHeader(postSections[0])) {
    reWriteText.pop();
    reWriteText.push(postSections[0]);
  }
  return reWriteText;
};

export const checkBackListFirstLine = ({text}) => {
  if (!text || !typeDef.isString(text)) {
    return false;
  }
  if (BLACKLIST_FIRST_LINE[text.trim()]) {
    return true;
  }
  return false;
};

export const checkBackListBodyLine = ({text}) => {
  if (!text || !typeDef.isString(text)) {
    return false;
  }
  if (BLACKLIST_BODY_LINE[text.trim()]) {
    return true;
  }
  return false;
};

export const isHeaderTag = ({text}) => {
  if (!text) {
    return false;
  }
  const headerPattern = /<h>.*?<\/h>/g;
  return headerPattern.test(text);
};

export const isHyperLinkTag = ({text}) => {
  if (!text) {
    return false;
  }
  const linkPattern = /(https?:\/\/[^\s]+)|(http?:\/\/[^\s]+)/g;
  return typeDef.isArrayLength(text.match(linkPattern));
};

export const splitHyperLinkTag = ({text}) => {
  if (!text) {
    return '';
  }
  const linkPattern = /(https?:\/\/[^\s]+)|(http?:\/\/[^\s]+)/g;
  return text.split(linkPattern).filter((x) => x !== undefined && x !== '');
};

export const prettyHyperLink = ({hyper}) => {
  if (!hyper) {
    return hyper;
  }
  if (hyper.length <= 40) {
    return hyper;
  }
  return `${hyper.substring(0, 40)}...`;
};

export const replaceHeaderTag = ({text}) => {
  const pattern = /<h>|<\/h>/g;
  return text.replace(pattern, '');
};
