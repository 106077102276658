import {put, call, delay} from 'redux-saga/effects';
import * as snackbar from '../../utils/components/toast-notification/provider';
import * as api from './api';
import * as TYPES from './type';
import * as debounceEffect from '../utils/effect';

export function* notifyGetSettingNotificationAction() {
  yield delay(debounceEffect.DEBOUNCE_400);
  try {
    const response = yield call(api.notifyGetSettingNotification);
    yield put({
      type: TYPES.NOTIFY_SETTING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.NOTIFY_SETTING_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}

export function* notifyUpdateNotificationAction({payload}) {
  yield delay(debounceEffect.DEBOUNCE_400);
  try {
    const response = yield call(api.notifyUpdateNotification, payload?.data);
    yield put({
      type: TYPES.NOTIFY_UPDATE_SETTING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.NOTIFY_UPDATE_SETTING_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}

export function* notifyGetCountNewNotification() {
  yield delay(debounceEffect.DEBOUNCE_400);
  try {
    const response = yield call(api.notifyGetCountNewNotification);
    yield put({
      type: TYPES.NOTIFY_COUNT_NEW_SUCCESS,
      payload: response.data.count,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.NOTIFY_COUNT_NEW_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}

export function* notifyGetListNotification({payload}) {
  yield delay(debounceEffect.DEBOUNCE_400);
  try {
    const {pageSize, pageIndex} = payload;
    const response = yield call(api.notifyGetListNotification, {pageSize, pageIndex});
    yield put({
      type: TYPES.NOTIFY_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.NOTIFY_LIST_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}

export function* notifyUpdateNotificationSetting({payload}) {
  yield delay(debounceEffect.DEBOUNCE_400);
  try {
    const response = yield call(api.notifyUpdateNotificationSetting, payload.data);
    yield put({
      type: TYPES.NOTIFY_UPDATE_NOTIFICATION_SETTING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.NOTIFY_UPDATE_NOTIFICATION_SETTING_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}

export function* notifySettingModal({payload}) {
  yield put({
    type: TYPES.NOTIFY_SETTING_MODAL_SUCCESS,
    payload: payload,
  });
}

export function* notifySaveInterestedJobForm({payload}) {
  if (payload?.data?.clear) {
    yield put({
      type: TYPES.NOTIFY_SAVE_FORM_CLEAR,
    });
    return;
  }
  yield put({
    type: TYPES.NOTIFY_SAVE_FORM_SUCCESS,
    payload: payload,
  });
}

export function* notifyUpdateInterestedJob({payload}) {
  yield delay(debounceEffect.DEBOUNCE_400);
  try {
    const response = yield call(api.notifyUpdateInterestedJob, payload.data);
    yield put({
      type: TYPES.NOTIFY_UPDATE_INTERESTED_JOB_SUCCESS,
      payload: response.data,
    });
    snackbar.setSnackbarMessage('บันทึกข้อมูลเรียบร้อย');
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.NOTIFY_UPDATE_INTERESTED_JOB_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}

export function* notifyGetDataBySingedUrl({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.NOTIFY_GET_DATA_BY_SIGNED_URL_CLEAR,
    });
  }
  try {
    const response = yield call(api.notifyGetDataBySignedUrl, payload?.data);
    yield put({
      type: TYPES.NOTIFY_GET_DATA_BY_SIGNED_URL_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.NOTIFY_GET_DATA_BY_SIGNED_URL_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}

export function* notifyUpdateDataBySingedUrl({payload}) {
  try {
    const response = yield call(api.notifyUpdateDataBySignedJob, payload?.data);
    yield put({
      type: TYPES.NOTIFY_UPDATE_DATA_BY_SIGNED_URL_SUCCESS,
      payload: response?.data,
    });
    snackbar.setSnackbarMessage('บันทึกข้อมูลเรียบร้อย');
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.NOTIFY_UPDATE_DATA_BY_SIGNED_URL_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}
