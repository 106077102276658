import * as typeDef from '../../../../utils/strong-types';
import * as numbers from '../../../../utils/number';

export const getLocation = (location) => {
  if (!location) {
    return '';
  }
  const {address, district} = location;
  let res = '';
  if (typeDef.isString(address)) {
    res += address;
  }
  if (typeDef.isString(district)) {
    if (typeDef.isString(address)) {
      res += ', ';
    }
    if (typeDef.isString(district.split('|')[1])) {
      res += district.split('|')[1];
    }
    if (typeDef.isString(district.split('|')[0])) {
      if (typeDef.isString(district.split('|')[1])) {
        res += ', ';
      }
      res += district.split('|')[0];
    }
  }
  return res;
};

export const getValue = (value) => {
  if (!value) {
    return '';
  }
  return value;
};

export const getAnnouncementText = (welfare) => {
  let res = '';
  if (typeDef.isArrayLength(welfare)) {
    res += welfare.map((item, index) => `${index + 1}. ${item}\n`).join('');
  }
  return res;
};

export const getDefaultValue = (value) => {
  if (!value) {
    return '-';
  }
  return value;
};

export const getHeadCount = (count) => {
  if (!count) {
    return '-';
  }
  return `${numbers.numberWithComma({input: count})} คน`;
};

export const getWebsiteUrl = (webUrl) => {
  if (!webUrl) {
    return '';
  }
  const pattern = /^https:\/\//i;
  const isFullUrl = pattern.test(webUrl);
  if (isFullUrl) {
    return webUrl;
  }
  return `https://${webUrl}`;
};

export const displayWebsiteUrl = (webUrl) => {
  if (!webUrl) {
    return '';
  }
  const pattern = /^https:\/\//i;
  const isFullUrl = pattern.test(webUrl);
  if (isFullUrl) {
    return webUrl.replace('https://', '');
  }
  return webUrl;
};

export const checkWrapLine = (line) => {
  if (typeof line !== 'string') {
    return false;
  }
  if (line.length < 20) {
    return false;
  }
  return true;
};
