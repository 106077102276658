import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  margin-bottom: 1rem;
`;

export const SearchFilterWrapper = styled.div`
  margin: 2rem 1.5rem -20px 1.5rem; ;
`;

export const InputHint = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.gray70};
  ${(props) => props.warning && `color: ${props.theme.danger}`}
`;

export const PopularContainer = styled.div``;

export const PopularRow = styled.div`
  display: flex;
  padding: 1rem 0;
`;

export const PopularItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
`;

export const PopularWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.tinGray};
  ${(props) => props.isActive && `background-color: ${props.theme.primary}`};
`;

export const PopularLabel = styled.span`
  margin-top: 1rem;
  font-weight: bold;
`;

export const OverrideThisStyles = {
  textNoResult: {
    textAlign: 'center',
  },
  recommend: {
    backgroundColor: lightTheme.grayBackground,
  },
  textRecommend: {
    marginRight: '1rem',
    fontSize: 24,
  },
  iconRecommendLocation: {
    color: lightTheme.danger,
  },
};
