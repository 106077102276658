import React from 'react';
import {useSelector} from 'react-redux';
import {translate} from '../../../res/language/translate';
import {ThumbnailWrapper, ThumbnailImg, OverrideStyles} from './styled-component';
import Header from '../../../components/header';
import Text from '../../../components/text';
import ForgotPasswordImage from '../../../res/assets/images/forgot.png';

const ResetPassHeader = () => {
  const visibleViewState = useSelector((state) => state.auth.forgotPassword?.visibleStep);

  return (
    <>
      <Header variant="h2" color={OverrideStyles.textHeader.color}>
        {translate('forgotPassword.title')}
      </Header>
      <ThumbnailWrapper>
        <ThumbnailImg src={ForgotPasswordImage} />
      </ThumbnailWrapper>
      {visibleViewState === 1 && (
        <>
          <Header variant="h4">{translate('forgotPassword.titleInputPhone')}</Header>
          <Text style={OverrideStyles.textBottom}>{translate('forgotPassword.subTitleInputPhone')}</Text>
        </>
      )}
      {visibleViewState === 2 && <Header variant="h4">{translate('forgotPassword.TitleSendSMSSuccess')}</Header>}
    </>
  );
};

ResetPassHeader.propTypes = {};

export default ResetPassHeader;
