/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useUserState, useUserDispatch} from '../../../../lib/hooks-logic/useUser';
import {
  FormWrapper,
  FormCenter,
  OverrideStyles,
  RowFiledWrapper,
  FieldWrapper,
  SpacerField,
  InputGroup,
  InputBlockWrapper,
  RowInput,
} from '../styled-component';
import Form from '../../../../components/form';
import Loading from '../../../../components/loading';
import Button from '../../../../components/button';
import ButtonIcon from '../../../../components/button-icon';
import Input from '../../../../components/input';
import Select from '../../../../components/select';
import OptionBuilder from '../../../../views/option-builder/new-version';
import SelectProvince from '../../../../views/select-location-sibling/select-province';
import * as ICONS from '../../../../components/icon/constants';
import * as initData from './init-data';
import * as typeDef from '../../../../utils/strong-types';
import * as logic from './logic';
import * as validate from './validate';
import * as transform from './logic-transform';
import * as arrayState from '../../../../lib/context/array-state';
import * as coreJobCat from '../../../../lib/context/job-cat';
import * as alert from '../../../../utils/components/alert/provider';
import * as coreUser from '../../../../lib/context/user';

const EditInterestForm = () => {
  const {formatMessage} = useIntl();
  const {userState, isLoadingState} = useUserState();
  const {dispatchUpdateProfile} = useUserDispatch();

  const [jobCatsData] = useState(coreJobCat.createJobCatData());
  const [experienceData] = useState(initData.jobExperience());

  const [provinceState, setProvinceState] = useState();
  const [availableDate, setAvailableDate] = useState();
  const [jobPositionState, setJobPositionState] = useState();
  const [jobLevelState, setJobLevelState] = useState();
  const [jobTypeState, setJobTypeState] = useState();
  const [experienceState, setExperienceState] = useState();
  const [formInputState, setFormInputState] = useState();
  const [selectedJobCats, setSelectedJobCats] = useState();
  const [errorTextsState, setErrorTextsState] = useState({});

  const onSubmit = () => {
    if (!userState) {
      return;
    }
    const userGeneral = coreUser.getGeneralNewAge(userState.general);
    const params = {
      ...userState,
      ...userGeneral,
    };
    const jobInterest = transform.transformJobByType(selectedJobCats);
    if (!jobInterest) {
      setErrorTextsState({...errorTextsState, jobInterest: formatMessage({defaultMessage: 'กรุณาเลือกอย่างน้อย 1 ประเภท'})});
      alert.setAlert(formatMessage({defaultMessage: 'กรุณาเลือกประเภทงานอย่างน้อย 1 ประเภท'}));
      return;
    }
    const [salary, errorSalary] = validate.validateSalary(formInputState?.salaryMin, formInputState?.salaryMax);
    if (errorSalary) {
      setErrorTextsState({...errorTextsState, ...errorSalary});
      return;
    }
    const [positionSuccess, positionError] = validate.validateJobPosition(jobPositionState);
    if (positionError && !positionSuccess) {
      const copyPosition = [...positionError];
      setJobPositionState(copyPosition);
      return;
    }
    const {salaryMin, salaryMax} = salary;

    if (salaryMin) {
      params.salary_min = parseInt(salaryMin, 10);
    } else {
      params.salary_min = 0;
    }
    if (salaryMax) {
      params.salary_max = parseInt(salaryMax, 10);
    } else {
      params.salary_max = 0;
    }
    params.job_interest = {...jobInterest};
    params.job_type_interest = transform.transformArrayData(jobTypeState);
    params.job_level = transform.transformArrayData(jobLevelState);
    params.available_start_date = availableDate?.value;
    if (typeDef.isNumber(experienceState?.value) && experienceState.value >= 0) {
      params.job_experience = experienceState.value;
    } else {
      params.job_experience = 0;
    }
    if (typeDef.isArrayLength(provinceState)) {
      params.desire_work_province = transform.getFirstOrDefault(provinceState);
    }

    params.interesting_positions = transform.transformArrayDataAndTrim(jobPositionState);
    dispatchUpdateProfile(params);
  };

  const onChangeTextInput = (key, value) => {
    setErrorTextsState({});
    if (!key) {
      return;
    }
    const copyField = {...formInputState};
    copyField[key] = value;
    setFormInputState(copyField);
  };

  const onChangeAvailableDate = (value) => {
    setAvailableDate(value);
  };

  const onChangeExperience = (value) => {
    setExperienceState(value);
  };

  const onChangeJobLevel = (value) => {
    const newJobLevel = arrayState.setNewAndPopExistValue(jobLevelState, value);
    setJobLevelState([...newJobLevel]);
  };

  const onChangeJobType = (value) => {
    const newJobType = arrayState.setNewAndPopExistValue(jobTypeState, value);
    setJobTypeState([...newJobType]);
  };

  const onChangeSelectJobCategory = (parentItem, childItem) => {
    const mapSelected = coreJobCat.mapSelectedByTypes({
      selectedOptions: selectedJobCats || [],
      newItems: {
        parentItem,
        childItem,
      },
    });
    if (mapSelected.length > coreJobCat.LIMIT_JOB_CAT) {
      return;
    }
    setSelectedJobCats(mapSelected);
  };

  const onClickedChip = (id) => {
    const newItems = coreJobCat.filterOutById({id, selectedOptions: selectedJobCats || []});
    setSelectedJobCats([...newItems]);
  };

  const shapeTextJobCatFunc = (text, item) => {
    if (item.parentId) {
      return text;
    }
    const shapeText = coreJobCat.shapeTextParentCategory({text});
    return shapeText;
  };

  const onChangeProvince = (province) => {
    if (!province) {
      return;
    }
    setProvinceState([province]);
  };

  const onChangeJobPosition = (value, index) => {
    const copyPositions = [...jobPositionState];
    copyPositions[index].value = value;
    setJobPositionState(copyPositions);
  };

  const onDeleteJobPosition = (index) => {
    const copyPositions = [...jobPositionState];
    copyPositions.splice(index, 1);
    setJobPositionState(copyPositions);
  };

  const onAddJobPosition = () => {
    if (jobPositionState.length >= 10) {
      alert.setAlert(formatMessage({defaultMessage: 'ตำแหน่งงานที่ต้องการสมัครสูงสุด 10 ตำแหน่ง'}));
      return;
    }
    const [success, error] = validate.validateJobPosition(jobPositionState);
    if (error && !success) {
      const copyPosition = [...error];
      setJobPositionState(copyPosition);
      return;
    }
    const copyPosition = [...jobPositionState, {value: ''}];
    setJobPositionState(copyPosition);
  };

  useEffect(() => {
    const isCanDefault = typeDef.isArray(initData.availableStart) && userState && typeDef.isNullOrUndefined(availableDate);
    if (isCanDefault) {
      const defaultData = logic.mapDefaultSingleValue(initData.availableStart, userState?.available_start_date);
      setAvailableDate(defaultData);
    }
  }, [initData.availableStart, userState, availableDate]);

  useEffect(() => {
    const isCanDefault = typeDef.isArray(experienceData) && userState && typeDef.isNullOrUndefined(experienceState);
    if (isCanDefault) {
      const defaultData = logic.mapDefaultSingleValue(experienceData, userState?.job_experience);
      setExperienceState(defaultData);
    }
  }, [experienceData, userState, experienceState]);

  useEffect(() => {
    const isCanDefault = userState && typeDef.isNullOrUndefined(formInputState);
    if (isCanDefault) {
      const defaultData = logic.mapDefaultSalary(userState?.salary_min, userState?.salary_max);
      setFormInputState({...defaultData});
    }
  }, [userState, formInputState]);

  useEffect(() => {
    const isCanDefault = userState && typeDef.isArrayLength(jobCatsData) && typeDef.isNullOrUndefined(selectedJobCats);
    if (isCanDefault) {
      const {jobcat, subjobcat} = userState.job_interest;
      const defaultData = logic.mapDefaultJobCat(jobCatsData, jobcat, subjobcat);
      setSelectedJobCats([...defaultData]);
    }
  }, [userState, jobCatsData, selectedJobCats]);

  useEffect(() => {
    const isCanDefault = typeDef.isArray(initData.jobLevel) && userState && typeDef.isNullOrUndefined(jobLevelState);
    if (isCanDefault) {
      const defaultData = logic.mapDefaultMultiValue(initData.jobLevel, userState?.job_level?.length ? userState?.job_level : ['เจ้าหน้าที่']);
      setJobLevelState(defaultData);
    }
  }, [initData.jobLevel, userState, jobLevelState]);

  useEffect(() => {
    const isCanDefault = typeDef.isArray(initData.jobType) && userState && typeDef.isNullOrUndefined(jobTypeState);
    if (isCanDefault) {
      const defaultData = logic.mapDefaultMultiValue(
        initData.jobType,
        userState?.job_type_interest?.length ? userState?.job_type_interest : ['full-time'],
      );
      setJobTypeState(defaultData);
    }
  }, [initData.jobType, userState, jobTypeState]);

  useEffect(() => {
    if (userState?.general && typeDef.isNullOrUndefined(provinceState)) {
      const defaultProvince = logic.mapStateToProvince(userState?.desire_work_province || userState?.location?.province);
      setProvinceState(defaultProvince);
    }
  }, [userState, provinceState]);

  useEffect(() => {
    if (userState?.general && typeDef.isNullOrUndefined(jobPositionState)) {
      const defaultJobPositions = logic.mapDefaultJobPosition(userState?.interesting_positions);
      setJobPositionState(defaultJobPositions);
    }
  }, [userState, jobPositionState]);

  return (
    <FormCenter>
      <FormWrapper>
        <Loading type="data" loading={isLoadingState && !userState} />
        <Loading type="action" loading={isLoadingState && !!userState} />
        {userState && (
          <>
            <Form formId="edit-interest" onSubmit={onSubmit}>
              <Select
                name="jobCat"
                sandboxColor="gray"
                label={formatMessage({defaultMessage: 'ประเภทงานที่สนใจ'})}
                placeholder={formatMessage({defaultMessage: 'เลือกประเภทงานที่สนใจ'})}
                limitSelect={10}
                options={jobCatsData}
                selectedOptions={selectedJobCats || []}
                shapeTextFunc={shapeTextJobCatFunc}
                onClickChip={onClickedChip}
                onChange={onChangeSelectJobCategory}
                onClear={() => setSelectedJobCats([])}
              />
              <InputGroup>
                {typeDef.isArrayLength(jobPositionState) &&
                  jobPositionState.map((item, index) => (
                    <RowInput key={index.toString()}>
                      <InputBlockWrapper singleItem={jobPositionState.length === 1}>
                        <Input
                          name="jobPosition"
                          type="text"
                          fillBackground
                          label={index === 0 ? formatMessage({defaultMessage: 'ตำแหน่งงานที่ต้องการสมัคร'}) : null}
                          placeholder={`${index + 1}. ${formatMessage({defaultMessage: 'กรอกตำแหน่งงานที่คุณสนใจสมัคร'})}`}
                          value={item.value || ''}
                          onChange={(value) => onChangeJobPosition(value, index)}
                          warningText={jobPositionState[index].error}
                        />
                      </InputBlockWrapper>
                      {jobPositionState.length > 1 && (
                        <Button
                          small
                          edgeInsets="none"
                          variant="danger"
                          style={index === 0 ? OverrideStyles.buttonDelete : OverrideStyles.buttonDelete2}
                          onClick={() => onDeleteJobPosition(index)}>
                          {formatMessage({defaultMessage: 'ลบแถว'})}
                        </Button>
                      )}
                    </RowInput>
                  ))}
                {typeDef.isArrayLength(jobPositionState) && (
                  <ButtonIcon small iconName={ICONS.PLUS} variant="success" edgeInsets="none" onClick={onAddJobPosition}>
                    {formatMessage({defaultMessage: 'เพิ่มตำแหน่งงานที่ต้องการสมัคร'})}
                  </ButtonIcon>
                )}
              </InputGroup>
              <OptionBuilder
                name="op-job-lv"
                multiple
                label={formatMessage({defaultMessage: 'ระดับตำแหน่ง'})}
                placeholder={formatMessage({defaultMessage: 'เลือกระดับตำแหน่ง'})}
                data={initData.jobLevel || []}
                value={jobLevelState || []}
                onChange={onChangeJobLevel}
                onChangeDel={onChangeJobLevel}
              />
              <OptionBuilder
                name="op-job-type"
                multiple
                label={formatMessage({defaultMessage: 'ประเภทงานที่ต้องการ'})}
                placeholder={formatMessage({defaultMessage: 'เลือกประเภทงาน'})}
                data={initData.jobType || []}
                value={jobTypeState || []}
                onChange={onChangeJobType}
                onChangeDel={onChangeJobType}
              />
              <RowFiledWrapper>
                <FieldWrapper>
                  <Input
                    name="salaryMin"
                    type="number"
                    fillBackground
                    label={formatMessage({defaultMessage: 'เงินเดือนขั้นต่ำที่ต้องการ'})}
                    placeholder={formatMessage({defaultMessage: 'กรอกเงินเดือนขั้นต่ำ'})}
                    value={formInputState?.salaryMin || ''}
                    onChange={(value) => onChangeTextInput('salaryMin', value)}
                    warningText={errorTextsState.salaryMin}
                  />
                </FieldWrapper>
                <SpacerField />
                <FieldWrapper>
                  <Input
                    name="salaryMax"
                    type="number"
                    fillBackground
                    label={formatMessage({defaultMessage: 'เงินเดือนสูงสุดที่ต้องการ'})}
                    placeholder={formatMessage({defaultMessage: 'กรอกเงินเดือนสูงสุด'})}
                    value={formInputState?.salaryMax || ''}
                    onChange={(value) => onChangeTextInput('salaryMax', value)}
                    warningText={errorTextsState.salaryMax}
                  />
                </FieldWrapper>
              </RowFiledWrapper>
              <OptionBuilder
                name="op-available-date"
                label={formatMessage({defaultMessage: 'วันที่สามารถเริ่มงานได้'})}
                data={initData.availableStart || []}
                value={availableDate}
                onChange={onChangeAvailableDate}
              />
              <OptionBuilder
                name="op-experience"
                label={formatMessage({defaultMessage: 'ประสบการณ์การทำงาน'})}
                data={experienceData || []}
                value={experienceState}
                onChange={onChangeExperience}
              />
              <SelectProvince
                checkBy="value"
                label={formatMessage({defaultMessage: 'สนใจทำงานที่จังหวัด'})}
                placeholder={formatMessage({defaultMessage: 'เลือกจังหวัด'})}
                selectedProvince={provinceState || []}
                onChange={onChangeProvince}
              />
              <Button block type="submit" style={OverrideStyles.buttonSubmit}>
                {formatMessage({defaultMessage: 'บันทึกข้อมูล'})}
              </Button>
            </Form>
          </>
        )}
      </FormWrapper>
    </FormCenter>
  );
};

EditInterestForm.propTypes = {};

export default EditInterestForm;
