import {useEffect, useState} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import get from 'lodash/get';
import {JOB_PAGE_SIZE} from '../../context/pagination';
import * as searchActions from '../../../redux/search/action';

export const useSearchLikedState = () => {
  const [isEmptyDataState, setIsEmptyDataState] = useState(false);
  const {
    liked: {stackFeed: stackFeedState, newFeed: newFeedState},
    pageIndex: pageIndexState,
    loading: isLoadingState,
    loadingRefresh: isLoadingRefreshState,
    error: errorState,
  } = useSelector((state) => state.search.jobLiked, shallowEqual);

  useEffect(() => {
    if (!isLoadingState && newFeedState) {
      if (newFeedState.resultCount === 0) {
        setIsEmptyDataState(true);
      } else {
        setIsEmptyDataState(false);
      }
      if (!isLoadingState && Array.isArray(stackFeedState) && !stackFeedState.length && newFeedState.resultCount > 0) {
        setIsEmptyDataState(true);
      }
    }
  }, [isLoadingState, newFeedState, stackFeedState]);

  return {
    isEmptyDataState,
    stackFeedState,
    newFeedState,
    pageIndexState,
    isLoadingState,
    isLoadingRefreshState,
    errorState,
  };
};

export const useSearchLikedSingleState = (name) => {
  const singleState = useSelector((state) => {
    const res = get(state, `search.jobLiked.${name}`);
    return res;
  }, shallowEqual);
  return {singleState};
};

export const useSearchLikedEffect = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const reqBody = {
      params: {},
      pageIndex: 0,
      pageSize: JOB_PAGE_SIZE,
    };
    dispatch(searchActions.searchJobLiked({data: reqBody}));
  }, [dispatch]);
};

export const useSearchLikedDispatch = () => {
  const dispatch = useDispatch();

  const dispatchFetchRefreshLiked = () => {
    const reqBody = {
      params: {},
      pageIndex: 0,
      pageSize: JOB_PAGE_SIZE,
    };
    dispatch(searchActions.searchJobLikedRefresh({data: reqBody}));
  };

  return {
    dispatchFetchRefreshLiked,
  };
};
