import {createServiceURL} from '../../env/utils/env-services';
import {adapterPost} from '../../utils/adapter/post';
import {adapterGet} from '../../utils/adapter/get';

export const reportCreateNewReport = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/delete_job_request/save_request`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const generateId = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/authen/generate_id`;
  const response = await adapterGet({url: API_URL, privateMethod: false});
  return response;
};
