import React from 'react';
import {useIntl} from 'react-intl';
import get from 'lodash/get';
import {useNotifyDispatch, useNotifyState} from '../../../../lib/hooks/logic/useNotify';
import Input from '../../../../components/input';
import * as ICONS from '../../../../components/icon/constants';

const KeyForm = () => {
  const {formatMessage} = useIntl();
  const {interestedJobState} = useNotifyState();
  const {dispatchUpdateInterestJobForm} = useNotifyDispatch();

  const onChangeText = (key, value) => {
    if (!key) {
      return;
    }
    const copyForm = {...interestedJobState};
    copyForm[key] = value;
    dispatchUpdateInterestJobForm(copyForm);
  };

  return (
    <>
      <Input
        borderOutline
        iconName={ICONS.BAG_BUSINESS}
        placeholder={formatMessage({defaultMessage: 'ตำแหน่งที่สนใจ'})}
        value={get(interestedJobState, 'search') || ''}
        onChange={(val) => onChangeText('search', val)}
      />

      <Input
        borderOutline
        iconName={ICONS.BUILDING}
        value={get(interestedJobState, 'author_name') || ''}
        placeholder={formatMessage({defaultMessage: 'บริษัทที่สนใจ'})}
        onChange={(val) => onChangeText('author_name', val)}
      />
    </>
  );
};

KeyForm.propTypes = {};

export default KeyForm;
