import styled from 'styled-components';

export const FULL_FILTER_BREAKPOINT = 1440;
export const FILTER_NAV_HEIGHT = 50;

export const Wrapper = styled.div``;

export const Container = styled.div`
  width: 340px;
  border-radius: 8px;
  padding: 1rem;
  margin-right: 1rem;
  background-color: ${(props) => props.theme.white};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
  display: block;
  transition: width 0.2s ease-out;
  ${(props) => props.fixed && `position: fixed;`};
  top: 60px;
  @media (max-width: ${FULL_FILTER_BREAKPOINT}px) {
    visibility: hidden;
    width: 0px;
    position: absolute;
  }
`;

export const ContainerHidden = styled.div`
  height: 10px;
  width: 340px;
  margin-right: 1rem;
  position: relative;
  visibility: hidden;
  @media (max-width: ${FULL_FILTER_BREAKPOINT}px) {
    display: none;
  }
`;

export const NavbarFilterWrapper = styled.div`
  z-index: 90;
  position: fixed;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${FILTER_NAV_HEIGHT}px;
  background-color: red;
  top: 56px;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.gray};
  transform: ${(props) => (props.isShow ? `translateY(0)` : `translateY(-60px)`)};
  transition: transform 0.5s ease-in-out;
  @media (min-width: ${FULL_FILTER_BREAKPOINT}px) {
    transform: translateY(-60px);
  }
`;

export const NavFilterLabel = styled.span`
  color: ${(props) => props.theme.white};
  font-weight: bold;
  padding: 0.5rem;
`;
