/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useAuth} from '../../../lib/hooks/logic/useAuth';
import {useCallbackPathDispatch} from '../../../lib/hooks-logic/useCallbackPath';
import {
  NavParent,
  NavHidden,
  NavContainer,
  NavCenter,
  OverrideStyles,
  SearchWrapper,
  BtnRegisterWrapper,
  BtnAllGroupWrapper,
} from './styled-component';
import AppLogo from '../../../views/logo/logo';
import GestureDetector from '../../../components/gesture';
import Icon from '../../../components/icon';
import Button from '../../../components/button';
import Row from '../../../components/row';
import Link from '../../../components/link';
import Searcher from '../../../views/searcher';
import * as navigationServices from '../../../utils/navigation/navigation-services';
import * as ICONS from '../../../components/icon/constants';
import * as ROUTES from '../../../routes/routes-name';

const NavBar = () => {
  const {formatMessage} = useIntl();

  const location = useLocation();
  const {dispatchAddCallbackPath} = useCallbackPathDispatch();
  const {isCheckedAuth, loggedInState} = useAuth();

  const onEventGoBack = () => {
    navigationServices.goBack();
  };

  const onRequestRegister = () => {
    dispatchAddCallbackPath({...location});
    navigationServices.navigateTo({
      pathname: ROUTES.ROUTE_REGISTER,
      search: {rd_path: true},
    });
  };

  return (
    <>
      <NavParent>
        <NavContainer>
          <GestureDetector withEdgeInsets={false} onClick={onEventGoBack} style={OverrideStyles.leftArrow}>
            <Icon name={ICONS.ARROW_LEFT} size={35} />
          </GestureDetector>
          <NavCenter>
            <Link url={ROUTES.ROUTE_MAIN}>
              <AppLogo />
            </Link>
          </NavCenter>
          <Row>
            {isCheckedAuth && !loggedInState && (
              <BtnRegisterWrapper>
                <Button edgeInsets="none" style={OverrideStyles.btn} onClick={onRequestRegister}>
                  {formatMessage({defaultMessage: 'สมัครสมาชิก'})}
                </Button>
              </BtnRegisterWrapper>
            )}
            <BtnAllGroupWrapper>
              <Link url={ROUTES.ROUTE_GROUP_LIST}>
                <Button edgeInsets="none" outline style={OverrideStyles.btn}>
                  {formatMessage({defaultMessage: 'รวมกลุ่มงาน'})}
                </Button>
              </Link>
            </BtnAllGroupWrapper>
            <SearchWrapper>
              <Searcher />
            </SearchWrapper>
          </Row>
        </NavContainer>
      </NavParent>
      <NavHidden />
    </>
  );
};

NavBar.propTypes = {};

export default NavBar;
