import styled from 'styled-components';
import {lightTheme} from '../../../styles/themes';

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 680px;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${(props) => props.theme.white};
  @media (max-width: 680px) {
    width: calc(100vw);
    border-radius: 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 0.5rem`};
  }
  @media (min-width: 681px) {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 1rem`};
  }
`;

export const PostHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PostHeaderLeft = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;
`;

export const PostHeaderRight = styled.div`
  display: flex;
`;

export const PrimaryText = styled.span`
  white-space: pre-line;
  padding-left: 1rem;
  font-weight: bold;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${(props) => props.noEdgeInsets && `padding-left: 0.5rem`};
`;

export const SecondaryText = styled.span`
  padding-left: 1rem;
  font-size: 12px;
  color: ${(props) => props.theme.textSecondary};
  ${(props) => props.noPadding && `padding-left: 0.5rem`};
`;

export const JobTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => props.theme.blueFade};
`;

export const JobHashTag = styled.span`
  color: ${(props) => props.theme.primary};
  padding-right: 1rem;
`;

export const JobTextHeader = styled.p`
  padding-left: 0.5rem;
  white-space: pre-line;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.blueFade};
`;

export const JobText = styled.span`
  white-space: pre-line;
  font-size: 14px;
`;

export const JobTextHyperLink = styled.a`
  white-space: pre-line;
  font-size: 14px;
  word-break: break-all !important;
  display: inline-block !important;
  color: ${(props) => props.theme.primary};
`;

export const JobTextLess = styled.div`
  white-space: pre-line;
  font-size: 14px;
  font-family: 'Prompt Light';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const RowHeader = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.isHeader && `margin-top: 1rem;`};
`;

export const JobSalary = styled.p`
  font-weight: bold;
`;

export const HyperLinkWrapper = styled.span``;

export const CounterText = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.textSecondary};
  ${(props) => props.paddingLeft && `padding-left: 0.40rem`};
`;

export const ButtonSeeMore = styled.div`
  cursor: pointer;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Prompt Light';
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.primary};
`;

export const LikeCountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.primary};
`;

export const VerifyWrapper = styled.div`
  display: flex;
  padding-left: 1rem;
  flex-wrap: nowrap;
  align-items: center;
`;

export const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const HoverWrapper = styled.div`
  position: absolute;
  padding: 1rem;
  background-color: ${(props) => props.theme.imageBackdrop};
  border-radius: 8px;
  width: fit-content;
  top: 100%;
`;

export const AvatarWrapper = styled.div``;

export const Text = styled.span`
  color: white;
`;

export const OverrideStyles = {
  iconLocation: {
    color: lightTheme.danger,
  },
  rowHashtag: {
    paddingBottom: '1rem',
  },
  iconBody: {
    color: lightTheme.blueFade,
  },
  iconLikeActive: {
    color: lightTheme.white,
  },
  iconInteract: {
    active: lightTheme.primary,
    unActive: lightTheme.gray,
  },
  divider: {
    margin: '0.5rem 0',
  },
  rowCounter: {
    paddingTop: '1rem',
  },
  rowHeader: {
    width: '100%',
  },
  iconVerifiedColor: lightTheme.success,
  rowRegister: {
    margin: '1rem 0',
  },
};
