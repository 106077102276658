import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledButtonSuccess,
  StyledButtonDanger,
  StyledButtonPrimary,
  StyledButtonLight,
  StyledButtonTransparent,
  StyledButtonLighter,
  ButtonLabel,
  OverrideStyles,
} from './styled-component';
import Icon from '../icon';
import layout from '../../styles/layout';

const ButtonIcon = (props) => {
  const {children, variant, iconName, onClick, iconStyle, small, iconSize} = props;
  const size = iconSize || (small ? 16 : undefined);

  if (variant === 'success') {
    return (
      <StyledButtonSuccess {...props} onClick={onClick}>
        <Icon name={iconName} size={size} color={OverrideStyles.iconColorWhite} style={iconStyle} />
        <ButtonLabel>{children}</ButtonLabel>
      </StyledButtonSuccess>
    );
  }
  if (variant === 'danger') {
    return (
      <StyledButtonDanger {...props} onClick={onClick}>
        <Icon name={iconName} size={size} color={OverrideStyles.iconColorWhite} style={iconStyle} />
        <ButtonLabel>{children}</ButtonLabel>
      </StyledButtonDanger>
    );
  }
  if (variant === 'transparent') {
    return (
      <StyledButtonTransparent {...props} onClick={onClick}>
        <Icon name={iconName} size={size} color={OverrideStyles.iconColorBlack} style={iconStyle} />
        <ButtonLabel>{children}</ButtonLabel>
      </StyledButtonTransparent>
    );
  }
  if (variant === 'light') {
    return (
      <StyledButtonLight {...props} onClick={onClick}>
        <Icon name={iconName} size={size} color={OverrideStyles.iconColorBlack} />
        <ButtonLabel>{children}</ButtonLabel>
      </StyledButtonLight>
    );
  }
  if (variant === 'lighter') {
    return (
      <StyledButtonLighter {...props} onClick={onClick}>
        <Icon name={iconName} size={size} color={OverrideStyles.iconColorLighter} style={iconStyle} />
        <ButtonLabel>{children}</ButtonLabel>
      </StyledButtonLighter>
    );
  }
  return (
    <StyledButtonPrimary {...props} onClick={onClick}>
      <Icon name={iconName} size={size} color={OverrideStyles.iconColorWhite} style={iconStyle} />
      <ButtonLabel>{children}</ButtonLabel>
    </StyledButtonPrimary>
  );
};

ButtonIcon.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
  style: PropTypes.any,
  iconStyle: PropTypes.any,
  block: PropTypes.bool,
  small: PropTypes.bool,
  nested: PropTypes.bool,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  underline: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  variant: PropTypes.oneOf(['primary', 'success', 'danger', 'transparent', 'light', 'lighter']),
  edgeInsets: PropTypes.oneOf([layout.edgeInsets.all, layout.edgeInsets.vertical, layout.edgeInsets.horizontal, layout.edgeInsets.none]),
};

ButtonIcon.defaultProps = {
  onClick: () => {},
  variant: 'primary',
  outline: false,
  block: false,
  small: false,
  nested: false,
  disabled: false,
  edgeInsets: layout.edgeInsets.all,
  style: null,
  iconStyle: null,
  underline: false,
  iconSize: 0,
};

export default ButtonIcon;
