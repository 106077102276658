/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useImageViewer} from '../../lib/hooks/logic/useImageViewer';
import {Container, CloseWrapper, SnapLeftWrapper, SnapRightWrapper, SnapButton, ImageWrapper} from './styled-component';
import Icon from '../../components/icon';
import * as ICONS from '../../components/icon/constants';

const ImageViewer = () => {
  const {
    imagesState: {data, snapToIndex},
    dispatchClearImage,
  } = useImageViewer();
  const [currentIndex, setCurrentIndex] = useState();
  const isEmpty = !Array.isArray(data) || !data.length || typeof currentIndex !== 'number';

  useEffect(() => {
    if (Array.isArray(data)) {
      setCurrentIndex(snapToIndex);
    }
    return () => setCurrentIndex(null);
  }, [data, snapToIndex]);

  const onEventRequestClose = () => {
    setCurrentIndex(null);
    dispatchClearImage();
  };

  const onEventSnapToPreviousIndex = () => {
    if (currentIndex === 0) {
      setCurrentIndex(data.length - 1);
      return;
    }
    setCurrentIndex((prev) => prev - 1);
  };

  const onEventSnapToNextIndex = () => {
    if (currentIndex === data.length - 1) {
      setCurrentIndex(0);
      return;
    }
    setCurrentIndex((prev) => prev + 1);
  };

  if (isEmpty) {
    return null;
  }

  return (
    <Container>
      <CloseWrapper onClick={onEventRequestClose}>
        <Icon name={ICONS.CLOSE} />
      </CloseWrapper>
      {data.length > 1 && (
        <>
          <SnapLeftWrapper onClick={onEventSnapToPreviousIndex}>
            <SnapButton>
              <Icon name={ICONS.ARROW_LEFT} />
            </SnapButton>
          </SnapLeftWrapper>
          <SnapRightWrapper onClick={onEventSnapToNextIndex}>
            <SnapButton>
              <Icon name={ICONS.ARROW_RIGHT} />
            </SnapButton>
          </SnapRightWrapper>
        </>
      )}
      <ImageWrapper src={data[currentIndex].url} width={data[currentIndex].width} height={data[currentIndex].height} />
    </Container>
  );
};

ImageViewer.propTypes = {};

export default ImageViewer;
