import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {translate} from '../../../res/language/translate';
import {FormWrapper, LinkWrapper, OverrideStyles} from './styled-component';
import Form from '../../../components/form';
import Input from '../../../components/input';
import Button from '../../../components/button';
import ButtonUnderline from '../../../components/button-underline';
import OtpInput from '../../../components/otp-input';
import Row from '../../../components/row';
import Link from '../../../components/link';
import * as authAction from '../../../redux/auth/action';
import * as validates from './validate';
import * as USER from '../../../lib/context/user';
import * as ROUTES from '../../../routes/routes-name';
import * as typeDef from '../../../utils/strong-types';
import * as alert from '../../../utils/components/alert/provider';
import * as navigationServices from '../../../utils/navigation/navigation-services';

const ResetForm = () => {
  const dispatch = useDispatch();
  const [otpState, setOtpState] = useState('');
  const [newPasswordState, setNewPasswordState] = useState('');
  const [confPasswordState, setConfPasswordState] = useState('');
  const [errorTexts, setErrorTexts] = useState({
    password: '',
    confPassword: '',
    otp: '',
  });
  const phoneState = useSelector((state) => state.auth.forgotPassword?.phone);
  const changedPasswordState = useSelector((state) => state.auth.forgotPassword?.user);

  const onChangeError = (key) => {
    const newError = {...errorTexts};
    newError[key] = '';
    setErrorTexts({...newError});
  };

  const onChangeText = (key, value) => {
    onChangeError(key);
    if (key === 'otp') {
      setOtpState(value);
    }
    if (key === 'password') {
      setNewPasswordState(value);
    }
    if (key === 'confPassword') {
      setConfPasswordState(value);
    }
  };

  const onSubmit = () => {
    const reqForm = {
      tel: phoneState,
      otp: otpState,
      password: newPasswordState,
      confPassword: confPasswordState,
      role: USER.ROLE.USER,
    };
    const validateResult = validates.validateResetPasswordForm({form: reqForm});
    const isValidate = !validateResult.otp && !validateResult.password && !validateResult.confPassword;
    if (!isValidate) {
      setErrorTexts({...validateResult});
      return;
    }
    const reqVerify = {
      tel: phoneState,
      otp: otpState,
      role: USER.ROLE.USER,
    };
    dispatch(authAction.userForgotPasswordCheckCode({data: {reqForm, reqVerify}}));
  };

  const onClickResendOtpCode = () => {
    const reqBody = {
      tel: phoneState,
    };
    dispatch(authAction.userForgotPasswordRequestCode({data: reqBody}));
  };

  const onClickBackToPrevious = () => {
    dispatch(authAction.userForgotPasswordChangePhone());
  };

  const onEventListenerResetPassword = () => {
    const alertData = {
      message: translate('forgotPassword.messageSetSuccess'),
      action: {
        positiveAction: () => {
          alert.removeAlert();
          navigationServices.navigateReplaceTo({
            pathname: ROUTES.ROUTE_LOGIN,
          });
        },
      },
    };
    alert.setAlertManualAction(alertData);
  };

  useEffect(() => {
    if (!typeDef.isNullOrUndefined(changedPasswordState)) {
      onEventListenerResetPassword();
    }
  }, [changedPasswordState]);

  return (
    <FormWrapper>
      <Form formId="form-reset-pass-reset" onSubmit={onSubmit}>
        <OtpInput name="otp" value={otpState} onChange={(value) => onChangeText('otp', value)} errorText={errorTexts.otp} />
        <Input
          borderOutline
          type="password"
          name="password"
          placeholder={translate('forgotPassword.textFieldNewPassword')}
          value={newPasswordState}
          onChange={(value) => onChangeText('password', value)}
          warningText={errorTexts.password}
        />
        <Input
          borderOutline
          type="password"
          name="conf-password"
          placeholder={translate('forgotPassword.textFieldConfNewPassword')}
          value={confPasswordState}
          onChange={(value) => onChangeText('confPassword', value)}
          warningText={errorTexts.confPassword}
        />
        <Button block type="submit" edgeInsets="none" style={OverrideStyles.button}>
          {translate('forgotPassword.buttonSendCode')}
        </Button>
      </Form>
      <Button block variant="light" type="button" edgeInsets="none" onClick={onClickResendOtpCode}>
        {translate('forgotPassword.buttonResendCode')}
      </Button>
      <Row content="space-between" style={OverrideStyles.rowButton}>
        <ButtonUnderline onClick={onClickBackToPrevious}>{translate('forgotPassword.buttonChangePhone')}</ButtonUnderline>
        <Link url={ROUTES.ROUTE_LOGIN}>
          <LinkWrapper>{translate('forgotPassword.buttonBack')}</LinkWrapper>
        </Link>
      </Row>
    </FormWrapper>
  );
};

ResetForm.propTypes = {};

export default ResetForm;
