import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {translate} from '../../res/language/translate';
import {useLocation, useLocationPopularDispatch} from '../../lib/hooks/logic/useLocation';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import {OverrideStyles} from './styled-component';
import Screen from '../../views/screen';
import MetaTags from '../../components/meta-tag';
import Divider from '../../components/divider';
import RecommendJobBuilder from '../../views/recommend-job-builder';
import LayoutContent from '../../views/layout-content';
import SelectLocation from '../../views/select-location';
import * as coreLocation from '../../lib/context/location';
import * as generate from '../../utils/generate';
import {SEARCH_POPULAR_UPDATE_LOCATION_SPECIFIED} from '../../redux/search/type';

const JobCategoryScreen = () => {
  useSetGrayBackground();

  const {formatMessage} = useIntl();
  const {recommendLocationState} = useLocation();
  const {dispatchUpdateCurrentLocation} = useLocationPopularDispatch();
  const dispatch = useDispatch();

  const [selectedLocation, setSelectedLocation] = useState([]);
  const [isSetInit, setIsSetInit] = useState(false);

  const onChangeSelectLocation = (parentItem, childItem) => {
    const newLocations = coreLocation.mapSelectedLocation({
      selectedOptions: selectedLocation,
      newItems: {
        parentItem,
        childItem,
      },
    });
    setSelectedLocation(newLocations);
    const newProvince = newLocations[0]?.value;
    if (!recommendLocationState && !isSetInit) {
      dispatchUpdateCurrentLocation(newProvince);
      setIsSetInit(true);
      return;
    }
    if (isSetInit) {
      dispatchUpdateCurrentLocation(newProvince);
      dispatch({type: SEARCH_POPULAR_UPDATE_LOCATION_SPECIFIED, payload: newProvince});
      dispatchUpdateCurrentLocation(newProvince, newProvince);
    }
  };

  const onChangeInit = (initLocation) => {
    setSelectedLocation([initLocation]);
  };

  useEffect(() => {
    if (recommendLocationState && !isSetInit) {
      const init = {
        id: generate.generateId('PV'),
        label: recommendLocationState,
        value: recommendLocationState,
        sub: [],
      };
      onChangeInit(init);
      setIsSetInit(true);
    }
  }, [recommendLocationState, isSetInit]);

  return (
    <>
      <MetaTags title={translate('meta.main')} description={translate('meta.main')} />
      <Screen noEdgeInsets isChildScreen title={formatMessage({defaultMessage: 'งานแนะนำทั้งหมด'})}>
        <LayoutContent>
          <SelectLocation
            normalStyle
            inlineLabel
            noneDistrict
            label={`${translate('landing.quickStep')} `}
            placeholder={translate('landing.search.select')}
            selectedOptions={selectedLocation}
            onChangeOptionTwo={onChangeSelectLocation}
          />
          <Divider bold style={OverrideStyles.divider} />
          <RecommendJobBuilder isLoggedIn={false} province={recommendLocationState} />
        </LayoutContent>
      </Screen>
    </>
  );
};

JobCategoryScreen.propTypes = {};

export default JobCategoryScreen;
