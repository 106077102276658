import {put, call, delay} from 'redux-saga/effects';
import * as api from './api';
import * as TYPES from './type';
import * as effects from '../utils/effect';
import {generateProvince} from '../../lib/context/create-province';

export function* contextGetLocationAction({payload}) {
  try {
    const response = yield call(api.contextLocation, payload?.data);
    yield put({
      type: TYPES.CONTEXT_LOCATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.CONTEXT_LOCATION_FAIL,
      payload: responseError,
    });
  }
}

export function* contextGetProvincesAction() {
  try {
    const response = generateProvince(); // yield call(api.contextProvinces);
    yield put({
      type: TYPES.CONTEXT_PROVINCE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.CONTEXT_PROVINCE_FAIL,
      payload: responseError,
    });
  }
}

export function* contextGetDistrictsAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.CONTEXT_DISTRICT_CLEAR,
    });
    return;
  }
  try {
    const response = yield call(api.contextDistricts, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.CONTEXT_DISTRICT_SUCCESS,
        payload: {
          province: payload?.data?.province,
          districts: response.data?.districts,
          popular: response.data?.popular_tags,
        },
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.CONTEXT_DISTRICT_FAIL,
      payload: responseError,
    });
  }
}

export function* contextGetStackDistrictsAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.CONTEXT_STACK_DISTRICT_CLEAR,
    });
    return;
  }
  try {
    const response = yield call(api.contextDistricts, payload?.data);
    yield put({
      type: TYPES.CONTEXT_STACK_DISTRICT_SUCCESS,
      payload: {
        province: payload?.data?.province,
        districts: response.data?.districts,
      },
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.CONTEXT_STACK_DISTRICT_FAIL,
      payload: {
        province: payload?.data?.province,
        error: responseError,
      },
    });
  }
}

export function* contextUpdateOffsetAction({payload}) {
  yield delay(effects.DEBOUNCE_OFFSET);
  if (payload) {
    yield put({
      type: TYPES.CONTEXT_UPDATE_OFFSET_SUCCESS,
      payload: payload,
    });
  }
}

export function* contextSetVisibleFilterAction({payload}) {
  yield put({
    type: TYPES.CONTEXT_VISIBLE_FILTER_SUCCESS,
    payload: !!payload?.visible,
  });
}

export function* contextUpdateDimensionsAction({payload}) {
  yield delay(effects.DEBOUNCE_DIMENSIONS);
  if (payload) {
    yield put({
      type: TYPES.CONTEXT_UPDATE_DIMENSIONS_SUCCESS,
      payload: payload,
    });
  }
}

export function* contextMainScrollConfigAction({payload}) {
  yield put({
    type: TYPES.CONTEXT_MAIN_SCROLL_CONFIG_SUCCESS,
    payload: payload,
  });
}

export function* contextEventListenerAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.CONTEXT_EVENT_LISTENER_CONFIG_CLEAR,
      payload: payload,
    });
    return;
  }
  yield put({
    type: TYPES.CONTEXT_EVENT_LISTENER_CONFIG_SUCCESS,
    payload: payload?.data,
  });
}

export function* contextContactShowAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.CONTEXT_CONTACT_SHOW_CLEAR,
      payload: payload,
    });
    return;
  }
  yield put({
    type: TYPES.CONTEXT_CONTACT_SHOW_SUCCESS,
    payload: payload?.data,
  });
}

export function* contextImageViewerAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.CONTEXT_IMAGE_VIEWER_CLEAR,
      payload: payload,
    });
    return;
  }
  yield put({
    type: TYPES.CONTEXT_IMAGE_VIEWER_SUCCESS,
    payload: payload?.data,
  });
}

export function* contextShowBottomLoginAction({payload}) {
  if (payload?.close || payload?.init) {
    yield put({
      type: TYPES.CONTEXT_SHOW_BOTTOM_LOGIN_SUCCESS,
      payload: payload,
    });
    return;
  }
  yield delay(5000);
  yield put({
    type: TYPES.CONTEXT_SHOW_BOTTOM_LOGIN_SUCCESS,
    payload: payload,
  });
}

export function* contextAddCallbackPathAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.CONTEXT_ADD_CALLBACK_PATH_CLEAR,
      payload: payload,
    });
    return;
  }
  yield put({
    type: TYPES.CONTEXT_ADD_CALLBACK_PATH_SUCCESS,
    payload: payload,
  });
}

export function* contextShowReportPostAction({payload}) {
  yield put({
    type: TYPES.CONTEXT_SHOW_REPORT_POST_SUCCESS,
    payload: payload,
  });
}

export function* contextHideReportPostAction({payload}) {
  yield put({
    type: TYPES.CONTEXT_HIDE_REPORT_POST_SUCCESS,
    payload: payload,
  });
}

export function* contextStoreRewritePost({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.CONTEXT_REWRITE_POST_CLEAR,
      payload: payload,
    });
    return;
  }
  yield put({
    type: TYPES.CONTEXT_REWRITE_POST_SUCCESS,
    payload: payload,
  });
}
