/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {translate} from '../../../../res/language/translate';
import {OverrideStyles} from './styled-component';
import {ButtonWrapper, ButtonText} from '../styled-component';
import Link from '../../../../components/link';
import Header from '../../../../components/header';
import Row from '../../../../components/row';
import Icon from '../../../../components/icon';
import StarView from '../star';
import * as ICONS from '../../../../components/icon/constants';

const SectionHeader = (props) => {
  const {route, title, iconName, score, total} = props;

  const [isHover, setIsHover] = useState(false);

  return (
    <>
      <Row content="space-between">
        <Row>
          <Icon name={iconName} color={OverrideStyles.iconColor} />
          <Header variant="h4" style={OverrideStyles.header}>
            {title}
          </Header>
          <StarView score={score || 0} total={total || 0} />
        </Row>
        <Row>
          <Link wrapperChildren url={route}>
            <ButtonWrapper onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
              {isHover && <Icon name={ICONS.EDIT_SQUARE} size={16} color={OverrideStyles.iconColor} />}
              <ButtonText>{translate('profile.edit')}</ButtonText>
            </ButtonWrapper>
          </Link>
        </Row>
      </Row>
    </>
  );
};

SectionHeader.propTypes = {
  route: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  score: PropTypes.number,
  total: PropTypes.number,
};

SectionHeader.defaultProps = {
  score: 0,
  total: 0,
};

export default SectionHeader;
