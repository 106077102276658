import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {translate} from '../../../../res/language/translate';
import {useUserState} from '../../../../lib/hooks/logic/useUser';
import {ListItemWrapper, OverrideStyles} from './styled-components';
import ListTile from '../../../../components/list-tile';
import Link from '../../../../components/link';
import Row from '../../../../components/row';
import Icon from '../../../../components/icon';
import Text from '../../../../components/text';
import Header from '../../../../components/header';
import * as ICONS from '../../../../components/icon/constants';
import * as ROUTES from '../../../../routes/routes-name';
import * as mainLogic from '../main-logic';
import * as verify from '../../../../utils/verify-token';

const LoginInfo = () => {
  const {formatMessage} = useIntl();

  const {userState, isLoadingState} = useUserState();
  const [isHover, setIsHover] = useState(false);
  const [accessState, setAccessState] = useState(null);
  const hoveredTextStyle = isHover ? {...OverrideStyles.textRight, ...OverrideStyles.textRightHover} : OverrideStyles.textRight;

  const onReceiveToken = async () => {
    const {access} = await verify.verifyToken();
    setAccessState(access);
  };

  useEffect(() => {
    if (userState) {
      onReceiveToken();
    }
  }, [userState]);

  const onClickItem = () => {};

  if (isLoadingState) {
    return null;
  }

  return (
    <>
      {accessState === 'general' && (
        <>
          <Row style={OverrideStyles.rowHeader}>
            <Icon name={ICONS.PASSWORD_FILL} color={OverrideStyles.iconColor} />
            <Header variant="h4" style={OverrideStyles.header}>
              {translate('profile.infoLogin.title')}
            </Header>
          </Row>
          <ListTile
            divider
            title={translate('profile.infoLogin.email')}
            description={mainLogic.getFieldValue(userState?.email)}
            titleStyle={OverrideStyles.title}
          />
        </>
      )}
      {accessState === 'facebook' && (
        <Row style={OverrideStyles.rowHeader}>
          <Icon name={ICONS.FACEBOOK_LOGIN} color={OverrideStyles.iconColor} />
          <Header variant="h4" style={OverrideStyles.header}>
            {formatMessage({defaultMessage: 'เข้าสู่ระบบโดย Facebook'})}
          </Header>
        </Row>
      )}
      {accessState === 'general' && (
        <Link url={ROUTES.ROUTE_USER_CHANGE_PASSWORD}>
          <ListItemWrapper onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <ListTile
              divider
              title={translate('profile.infoLogin.password')}
              description="********"
              titleStyle={OverrideStyles.title}
              rightComponent={
                <Row style={OverrideStyles.rowRightComponent}>
                  {isHover && <Icon name={ICONS.EDIT_SQUARE} size={16} color={OverrideStyles.iconColor} />}
                  <Text variant="span" style={hoveredTextStyle}>
                    {translate('profile.edit')}
                  </Text>
                </Row>
              }
              onClick={onClickItem}
            />
          </ListItemWrapper>
        </Link>
      )}
    </>
  );
};

LoginInfo.propTypes = {};

export default LoginInfo;
