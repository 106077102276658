import React from 'react';
import {translate} from '../../../res/language/translate';
import {PresentWrapper, SideTextWrapper, SideTitle, SideDescription, SideKey} from './styled-component';
import AnimationTransitionY from '../../../components/animations/transition-y';
import AnimationTransitionX from '../../../components/animations/transition-x';
import SVGView from '../../../components/svg-img';
import SvgLogIn from '../../../res/assets/images/svg/Login.svg';

const SidePresent = () => {
  return (
    <PresentWrapper>
      <AnimationTransitionX x={-20}>
        <SideTextWrapper>
          <SideTitle>{translate('login.presentTitle1')}</SideTitle>
          <SideDescription>{translate('login.presentTitle41')}</SideDescription>
          <SideKey>{translate('login.presentTitle42')}</SideKey>
        </SideTextWrapper>
      </AnimationTransitionX>
      <AnimationTransitionY y={-50}>
        <SVGView autoScale src={SvgLogIn} maxHeight={514} />
      </AnimationTransitionY>
    </PresentWrapper>
  );
};

SidePresent.propTypes = {};

export default SidePresent;
