/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {Range, getTrackBackground} from 'react-range';
import {Container, Wrapper, OverrideStyles} from './styled-component';
import * as number from '../../utils/number';
import Row from '../row';
import Text from '../text';

const RangeSlider = (mainProps) => {
  const {isActive, step, minRange, maxRange, values, onChange} = mainProps;
  const colorStyle = isActive ? OverrideStyles.colorStyles.active : OverrideStyles.colorStyles.deActive;
  const thumbStyle = isActive ? OverrideStyles.thumbStyles.active : OverrideStyles.thumbStyles.deActive;

  return (
    <Container>
      <Range
        step={step}
        min={minRange}
        max={maxRange}
        values={values}
        onChange={(val) => {
          onChange(val);
        }}
        renderTrack={({props, children}) => (
          <Wrapper
            {...props}
            style={{
              ...props.style,
              ...OverrideStyles.baseColor,
              background: getTrackBackground({
                values: values,
                colors: colorStyle,
                min: minRange,
                max: maxRange,
              }),
            }}>
            {children}
          </Wrapper>
        )}
        renderThumb={({props}) => (
          <Wrapper
            {...props}
            style={{
              ...props.style,
              ...thumbStyle,
            }}
          />
        )}
      />
      <Row content="space-between" style={OverrideStyles.outputStyle}>
        <Text> {number.numberWithComma({input: values[0]})}</Text>
        <Row>
          <Text>{number.numberWithComma({input: values[1]})}</Text>
          <>{values[1] >= maxRange && <Text>+</Text>}</>
        </Row>
      </Row>
    </Container>
  );
};

RangeSlider.propTypes = {
  isActive: PropTypes.bool,
  step: PropTypes.number,
  minRange: PropTypes.number,
  maxRange: PropTypes.number,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

RangeSlider.defaultProps = {
  isActive: true,
  step: 5000,
  minRange: 5000,
  maxRange: 150000,
};

export default RangeSlider;
