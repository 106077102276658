import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {isArrayLength} from '../../utils/strong-types';
import {translate} from '../../res/language/translate';
import {
  InputField,
  InputWrapper,
  InputLabel,
  BottomWrapper,
  Container,
  SelectBoxWrapper,
  SelectBox,
  OverrideStyles,
  ModalWrapper,
} from '../../components/select/styled-component';
import {SearchFilterWrapper, OverrideThisStyles} from './styled-component';
import Icon from '../../components/icon';
import Text from '../../components/text';
import Modal from '../../components/modal';
import Button from '../../components/button';
import Spinner from '../../components/spinner';
import Row from '../../components/row';
import Input from '../../components/input';
import OptionItem from '../../components/select/sub-comp';
import * as coreLocation from '../../lib/context/location';
import * as contextActions from '../../redux/context/action';
import * as generate from '../../utils/generate';
import * as deepState from '../../utils/deep-state';
import * as typeDef from '../../utils/strong-types';
import * as logic from './logic';
import * as ICONS from '../../components/icon/constants';
import {useLocation} from '../../lib/hooks/logic/useLocation';

const SelectLocation = (props) => {
  const {id, selectedOptions, onChange, placeholder, label, noneDistrict, inlineLabel, onChangeOptionTwo, normalStyle} = props;
  const dispatch = useDispatch();
  const nodeItemRef = useRef();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [idFor] = useState(id || generate.generateIdString('select'));
  const [openChildList, setOpenChildList] = useState({});
  const [filterKey, setFilterKey] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [areaState, setAreaState] = useState([]);
  const [currentCallbackId, setCurrentCallbackId] = useState();

  const {locationNearestState} = useLocation();
  const areaProvincesState = useSelector((state) => state.context.area.provinces.data);
  const areaDistrictsState = useSelector((state) => state.context.area.districts.data);
  const isLoadingDistrict = useSelector((state) => state.context.area.districts.loading);

  const onChangeOptions = (parentItem, childItem) => {
    if (!onChange) {
      return;
    }
    onChange(parentItem, childItem);
  };

  const onEventChangeOptionsTwo = (parentItem, childItem) => {
    if (!onChangeOptionTwo) {
      return;
    }
    onChangeOptionTwo(parentItem, childItem);
  };

  const onConfirmOption = () => {
    setIsVisibleModal(false);
  };

  const onEventReceivedDistricts = () => {
    const _districts = logic.createDistrictsData({
      rawDistricts: areaDistrictsState,
      provinceId: currentCallbackId,
    });

    const _mergeDistrictsProvinces = logic.mergeDistrictsToProvincesData({
      provinces: areaState,
      provinceId: currentCallbackId,
      districts: _districts,
    });
    if (typeDef.isArrayLength(_mergeDistrictsProvinces)) {
      setAreaState(_mergeDistrictsProvinces);
    }
    setCurrentCallbackId(null);
  };

  const onEventGetDistrict = (provinceId) => {
    const province = logic.findProvinceData({province: areaState, provinceId});
    if (!province) {
      return;
    }
    if (typeDef.isArrayLength(province.sub)) {
      return;
    }
    const reqDistrictsBody = {
      province: province.value,
    };
    dispatch(contextActions.contextGetDistricts({data: reqDistrictsBody}));
  };

  const onChangeOpenChildList = (callbackId) => {
    const newOpenChildList = deepState.cloneDeepState({data: openChildList});
    if (newOpenChildList[callbackId]) {
      delete newOpenChildList[callbackId];
      setOpenChildList({...newOpenChildList});
      return;
    }
    setCurrentCallbackId(callbackId);
    onEventGetDistrict(callbackId);
    newOpenChildList[callbackId] = true;
    setOpenChildList({...newOpenChildList});
  };

  useEffect(() => {
    if (typeDef.isArrayLength(areaProvincesState)) {
      const _provinces = logic.switchProvinceData({
        rawProvinces: areaProvincesState,
        targetProvince: locationNearestState,
        isOutAll: typeDef.isFunction(onChangeOptionTwo),
      });
      setAreaState(_provinces);
    }
  }, [areaProvincesState, locationNearestState]);

  useEffect(() => {
    if (typeDef.isArrayLength(areaDistrictsState)) {
      onEventReceivedDistricts();
    }
  }, [areaDistrictsState]);

  useEffect(() => {
    if (typeDef.isArrayLength(areaState) && locationNearestState && typeDef.isNotArrayLength(selectedOptions)) {
      const initLocationOptionTwo = logic.creatInitLocations({
        location: areaState,
        nearest: locationNearestState,
      });
      if (typeDef.isArrayLength(initLocationOptionTwo)) {
        onEventChangeOptionsTwo(initLocationOptionTwo[0]);
      }

      const initLocation = logic.createInitLocationAllProvince(areaState);
      if (typeDef.isArrayLength(initLocation)) {
        onChangeOptions(initLocation[0]);
      }
    }
  }, [areaState, locationNearestState, selectedOptions]);

  useEffect(() => {
    if (!filterKey) {
      setFilterOptions([]);
      return;
    }
    if (filterKey) {
      const filteredArea = areaState.filter((element) => element.label.toLowerCase().includes(filterKey.toLowerCase()));
      setFilterOptions(filteredArea);
    }
  }, [areaState, filterKey]);

  useEffect(() => {
    if (isVisibleModal && nodeItemRef.current) {
      nodeItemRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [isVisibleModal]);

  return (
    <Container>
      <Modal visible={isVisibleModal} onRequestClose={() => setIsVisibleModal(false)}>
        <ModalWrapper>
          <SearchFilterWrapper>
            <Input
              borderOutline
              placeholder={translate('landing.searchProvince')}
              iconName={ICONS.SEARCH}
              value={filterKey}
              onChange={(key) => setFilterKey(key)}
            />
          </SearchFilterWrapper>
          <SelectBoxWrapper id="slb">
            <SelectBox>
              {isArrayLength(areaState) &&
                !filterKey &&
                areaState.map((item) => (
                  <OptionItem
                    ref={(ref) => {
                      if (logic.checkIsSelectedById({id: item.id, selectedOptions})) {
                        nodeItemRef.current = ref;
                      }
                    }}
                    key={item.id}
                    forceChild={logic.checkHasDistrict(item.label) && Array.isArray(item.sub)}
                    noneRightArrow={noneDistrict}
                    isChecked={logic.checkIsSelectedByValue({value: item.value, selectedOptions})}
                    text={item.label}
                    parentId={item.id}
                    openChildList={openChildList}
                    options={item.sub}
                    selectedOptions={selectedOptions}
                    onClickExpand={onChangeOpenChildList}
                    onChange={(childItem) => {
                      if (!typeDef.isFunction(onChangeOptionTwo)) {
                        onChangeOptions(item, childItem);
                        return;
                      }
                      onEventChangeOptionsTwo(item, childItem);
                    }}
                    rightComponent={!!isLoadingDistrict && currentCallbackId === item.id && <Spinner />}
                  />
                ))}
              {filterKey && (
                <>
                  {filterOptions.map((item) => (
                    <OptionItem
                      key={item.id}
                      forceChild={logic.checkHasDistrict(item.label) && Array.isArray(item.sub)}
                      noneRightArrow={noneDistrict}
                      isChecked={logic.checkIsSelectedByValue({value: item.value, selectedOptions})}
                      text={item.label}
                      parentId={item.id}
                      openChildList={openChildList}
                      options={item.sub}
                      selectedOptions={selectedOptions}
                      onClickExpand={onChangeOpenChildList}
                      onChange={(childItem) => {
                        if (!typeDef.isFunction(onChangeOptionTwo)) {
                          onChangeOptions(item, childItem);
                          return;
                        }
                        onEventChangeOptionsTwo(item, childItem);
                      }}
                      rightComponent={!!isLoadingDistrict && currentCallbackId === item.id && <Spinner />}
                    />
                  ))}
                  {!isArrayLength(filterOptions) && <Text style={OverrideThisStyles.textNoResult}>{translate('emptyData.defaultSearch')}</Text>}
                </>
              )}
            </SelectBox>
          </SelectBoxWrapper>
          <BottomWrapper>
            <Button block onClick={() => onConfirmOption()}>
              {translate('global.confirm')}
            </Button>
          </BottomWrapper>
        </ModalWrapper>
      </Modal>
      {!inlineLabel && (
        <>
          <Row>
            <InputLabel>{label}</InputLabel>
          </Row>
          <InputWrapper onClick={() => setIsVisibleModal(true)} style={OverrideStyles.inputWrapper.locationPadding}>
            <Icon name={ICONS.LOCATION} color={OverrideStyles.icon.color} />
            <InputField id={idFor} name="location" value={JSON.stringify(selectedOptions)} onChange={() => {}} />
            <Text color={OverrideStyles.placeholder.color} style={OverrideStyles.text}>
              {isLoadingDistrict ? translate('landing.preLoading') : coreLocation.mapSelectedLabel({selectedOptions}) || placeholder}
            </Text>
          </InputWrapper>
        </>
      )}
      {inlineLabel && (
        <>
          <Row>
            <InputLabel style={!normalStyle ? OverrideThisStyles.textRecommend : OverrideThisStyles.textRecommendNormal}>{label}</InputLabel>
            <InputWrapper normalStyle grayBackground onClick={() => setIsVisibleModal(true)} style={OverrideStyles.inputWrapper.locationPadding}>
              <Icon name={ICONS.LOCATION} color={OverrideThisStyles.iconRecommendLocation.color} />
              <InputField id={idFor} name="location" value={JSON.stringify(selectedOptions)} onChange={() => {}} />
              <Text color={OverrideStyles.placeholder.color} style={OverrideStyles.textActiveNormal}>
                {isLoadingDistrict ? translate('landing.preLoading') : coreLocation.mapSelectedLabel({selectedOptions}) || placeholder}
              </Text>
            </InputWrapper>
          </Row>
        </>
      )}
    </Container>
  );
};

SelectLocation.propTypes = {
  id: PropTypes.string,

  selectedOptions: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  noneDistrict: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  normalStyle: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeOptionTwo: PropTypes.func,
};

SelectLocation.defaultProps = {
  id: '',
  selectedOptions: [],
  placeholder: '',
  label: '',
  normalStyle: false,
  noneDistrict: false,
  inlineLabel: false,
  onChange: null,
  onChangeOptionTwo: null,
};

export default SelectLocation;
