import styled from 'styled-components';
import {lightTheme} from '../../../../styles/themes';

export const ListItemWrapper = styled.div`
  width: 100%;
`;

export const ResumeView = styled.div`
  padding: 0.75rem;
`;

export const ResumeHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ResumeDropZone = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const InputHidden = styled.input`
  display: none;
`;

export const OverrideStyles = {
  header: {
    paddingLeft: '0.5rem',
  },
  title: {
    color: lightTheme.textBlack,
    fontWeight: 'bold',
  },
  rowRightComponent: {
    width: 100,
    justifyContent: 'flex-end',
  },
  textRight: {
    paddingLeft: '0.5rem',
    color: lightTheme.primary,
  },
  textRightHover: {
    textDecoration: 'underline',
  },
  iconColor: lightTheme.blueFade,
};
