import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useUserState, useUserDispatch} from '../../../../lib/hooks-logic/useUser';
import {FormWrapper, FormCenter, OverrideStyles, RowItemWrapper} from '../styled-component';
import Form from '../../../../components/form';
import InputArea from '../../../../components/input-area';
import Button from '../../../../components/button';
import OptionBuilder from '../../../../views/option-builder/new-version';
import Header from '../../../../components/header';
import Loading from '../../../../components/loading';
import ButtonIcon from '../../../../components/button-icon';
import * as initData from './init-data';
import * as typeDef from '../../../../utils/strong-types';
import * as logic from './logic';
import * as validate from './validate';
import * as snackbar from '../../../../utils/components/toast-notification/provider';
import * as ICONS from '../../../../components/icon/constants';
import * as coreUser from '../../../../lib/context/user';

const EditInterestForm = () => {
  const {formatMessage} = useIntl();
  const {userState, isLoadingState} = useUserState();
  const {dispatchUpdateProfile} = useUserDispatch();

  const [yearData] = useState(initData.yearData());

  const [listDataState, setListDataState] = useState();
  const [errorState, setErrorState] = useState([]);

  const onSubmit = () => {
    const [isValidate, errorList] = validate.validateAllRow(listDataState);
    if (typeDef.isArrayLength(errorList) && !isValidate) {
      setErrorState(errorList);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรอกข้อมูลให้ครบก่อนบันทึก'}), 'warning');
      return;
    }
    if (!userState) {
      return;
    }
    const userGeneral = coreUser.getGeneralNewAge(userState.general);
    const formSubmit = {
      ...userState,
      ...userGeneral,
      training_courses: logic.transformRow(listDataState),
    };
    dispatchUpdateProfile(formSubmit);
  };

  const onChangeYear = (value, index) => {
    if (Array.isArray(listDataState)) {
      const copyForm = [...listDataState];
      copyForm[index].year = value;
      setListDataState(copyForm);
    }
  };

  const onChangeDetail = (value, index) => {
    if (typeDef.isArrayLength(errorState)) {
      setErrorState([]);
    }
    const copyForm = [...listDataState];
    copyForm[index].detail = value;
    setListDataState(copyForm);
  };

  const onEventRequestAddRow = () => {
    const rowItem = logic.getRowItem(yearData);
    if (typeDef.isNotArrayLength(listDataState)) {
      setListDataState([rowItem]);
      return;
    }
    const [isValidate, errorList] = validate.validateAllRow(listDataState);
    if (typeDef.isArrayLength(errorList) && !isValidate) {
      setErrorState(errorList);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรอกข้อมูลให้ครบก่อนเพิ่มแถว'}), 'warning');
      return;
    }
    const copyForm = [...listDataState, rowItem];
    setListDataState(copyForm);
  };

  const onEventRequestDelRow = (index) => {
    if (Array.isArray(listDataState)) {
      const copyForm = [...listDataState];
      copyForm.splice(index, 1);
      setListDataState(copyForm);
    }
  };

  useEffect(() => {
    const isCanDefault = userState?.general && typeDef.isArrayLength(yearData) && typeDef.isNullOrUndefined(listDataState);
    if (isCanDefault) {
      const defaultList = logic.mapStateToList(yearData, userState.training_courses);
      setListDataState(defaultList);
    }
  }, [yearData, listDataState, userState]);

  useEffect(() => {
    if (Array.isArray(listDataState) && !listDataState.length && typeDef.isArrayLength(userState.training_courses)) {
      onSubmit();
    }
  }, [listDataState, userState]);

  return (
    <FormCenter>
      <FormWrapper>
        <Loading type="data" loading={isLoadingState && !userState} />
        <Loading type="action" loading={isLoadingState && !!userState} />
        {userState && (
          <Form formId="edit-interest" onSubmit={onSubmit}>
            {typeDef.isArrayLength(listDataState) &&
              listDataState.map((item, index) => (
                <RowItemWrapper key={index.toString()}>
                  <OptionBuilder
                    name={`op-year-${index}`}
                    label={formatMessage({defaultMessage: 'ปี'})}
                    data={yearData || []}
                    value={item.year}
                    onChange={(value) => onChangeYear(value, index)}
                    rightLabelComponent={
                      <Button small type="button" edgeInsets="none" variant="danger" onClick={() => onEventRequestDelRow(index)}>
                        {formatMessage({defaultMessage: 'ลบแถว'})}
                      </Button>
                    }
                  />
                  <InputArea
                    height={200}
                    label={formatMessage({defaultMessage: 'รายละเอียด'})}
                    placeholder={formatMessage({defaultMessage: 'เช่น ชื่อองค์กรหรือหน่วยงาน, ชื่อคอร์ส, ชื่อใบประกาศ, ข้อมูลรายละเอียด'})}
                    value={item.detail}
                    onChange={(value) => onChangeDetail(value, index)}
                    warningText={errorState[index]?.value}
                  />
                </RowItemWrapper>
              ))}
            {typeDef.isArrayLength(listDataState) && (
              <>
                <ButtonIcon small variant="success" edgeInsets="none" iconName={ICONS.PLUS} onClick={onEventRequestAddRow}>
                  {formatMessage({defaultMessage: 'เพิ่มประวัติการฝึกอบรม'})}
                </ButtonIcon>
                <Button block type="submit" edgeInsets="none" style={OverrideStyles.buttonSubmit}>
                  {formatMessage({defaultMessage: 'บันทึกข้อมูล'})}
                </Button>
              </>
            )}
            {typeDef.isNotArrayLength(listDataState) && (
              <>
                <Header center variant="h3">
                  {formatMessage({defaultMessage: 'ยังไม่มีข้อมูลการฝึกอบรม'})}
                </Header>
                <Button block type="button" onClick={onEventRequestAddRow} style={OverrideStyles.buttonSubmit}>
                  {formatMessage({defaultMessage: 'เพิ่มข้อมูล'})}
                </Button>
              </>
            )}
          </Form>
        )}
      </FormWrapper>
    </FormCenter>
  );
};

EditInterestForm.propTypes = {};

export default EditInterestForm;
