import qs from 'querystring';
import * as typeDef from '../../utils/strong-types';

export function getSubJobcat(jobcatForm) {
  if (typeDef.isArrayLength(jobcatForm)) {
    const filterSubJobCat = jobcatForm.filter((item) => !typeDef.isNullOrUndefined(item.parentId));
    const filterJobCat = jobcatForm.filter((item) => typeDef.isNullOrUndefined(item.parentId));

    const subjobcat = filterSubJobCat.map((item) => {
      if (!typeDef.isNullOrUndefined(item.value)) {
        return item.value;
      }
      return item;
    });

    const jobcat = filterJobCat.map((item) => {
      if (!typeDef.isNullOrUndefined(item.value)) {
        return item.value;
      }
      return item;
    });

    return {subjobcat, jobcat};
  }
  return null;
}

export function checkSaveInterestJob(interestedJobForm) {
  let check = false;
  if (typeDef.isObject(interestedJobForm)) {
    const fromKey = Object.keys(interestedJobForm);
    if (typeDef.isArrayLength(fromKey)) {
      fromKey.map((item) => {
        if (!typeDef.isNullOrUndefined(item[fromKey])) {
          check = true;
        }
        return item;
      });
      return check;
    }
  }
  return check;
}

export function getParamsSearch(searchParams) {
  let params = {};
  const searchKey = Object.keys(searchParams);
  if (typeDef.isArrayLength(searchKey)) {
    searchKey.map((item) => {
      if (!typeDef.isNullOrUndefined(searchParams[item])) {
        params[item] = searchParams[item];
        return item;
      }
      return item;
    });
  }

  params = qs.stringify(params);
  return params;
}
