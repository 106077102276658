import filter from 'lodash/filter';
import map from 'lodash/map';
import {translateApi} from '../../res/language/translate';

export const checkGrantedPermission = (permission) => {
  if (!permission) {
    return {isValid: false};
  }
  const missingPermission = filter(permission, (x) => x.status !== 'granted');
  if (missingPermission.length) {
    const permissionNeeded = filter(missingPermission, (x) => x.permission === 'email' || x.permission === 'public_profile');
    if (permissionNeeded.length) {
      return false;
    }
  }
  return {
    isValid: true,
    data: map(missingPermission, (x) => {
      return x.permission;
    }),
  };
};

export const handlerError = (error) => {
  switch (error) {
    case 'access_denied':
      return translateApi('facebookLogin.accessDenied');
    default:
      return error;
  }
};
