// const tab = '\u00A0\u00A0\u00A0';

const faq = [
  {
    id: 4,
    question: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์',
    answer:
      `เว็บไซต์จ็อบฟินฟิน – www.jobfinfin.com (ต่อไปนี้เรียกว่า “บริษัท”) ยินดีต้อนรับผู้ใช้บริการทุกท่านเข้าสู่เว็บไซต์ซึ่งอยู่ในความดูแลของบริษัท โดยเว็บไซต์นี้จะให้บริการข้อมูลการรับสมัครงานของผู้ประกอบการ ซึ่งเป็นหน่วยงานรัฐหรือหน่วยงานเอกชนต่างๆ แก่ผู้ใช้บริการเว็บไซต์ ผู้ค้นหางาน ให้บริการข้อมูล ข่าวสาร และ บริการออนไลน์ประเภทต่าง ๆ มากมาย ภายใต้ข้อกำหนดและเงื่อนไข ดังต่อไปนี้
ผู้ใช้บริการทุกท่านรับทราบว่า การใช้บริการต่าง ๆ ของท่านในเว็บไซต์นี้ ถือว่าท่านได้ตกลงและยอมรับข้อกำหนดและเงื่อนไขในการให้บริการของบริษัททุกประการและท่านรับทราบว่าเว็บไซต์ของบริษัทนี้ เป็นเพียงสื่อกลางในการให้ข้อมูลแก่ผู้ใช้บริการเท่านั้น
1. ขอบเขตการใช้บริการ
1.1ท่านตกลงและยอมรับว่า บริการของบริษัทเป็นการนำเสนอข้อมูล ข่าวสาร ที่เกี่ยวข้องกับการรับสมัครงาน หรือข้อมูลที่เกี่ยวข้องกับการรับสมัครงานเท่านั้น โดยข้อมูล เนื้อหา รูปภาพ หรือรายละเอียดที่ปรากฏในเว็บไซต์ของบริษัท เป็นข้อมูลจากผู้ประกอบการที่เข้าใช้บริการเว็บไซต์ ซึ่งอยู่ภายใต้ความรับผิดชอบของผู้ประกอบการแต่เพียงผู้เดียว เนื่องจากบริษัทมิได้มีอำนาจในการควบคุม ตรวจสอบ ดัดแปลง แก้ไข เปลี่ยนแปลง แต่อย่างใด ดังนั้น บริษัทจึงไม่รับประกันความถูกต้อง ความสมบูรณ์ หรือความน่าเชื่อถือของข้อมูล เนื้อหา รูปภาพ หรือรายละเอียดที่ถูกนำเสนอโดยผู้ประกอบการผ่านเว็บไซต์ได้ 
1.2ท่านตกลงและยอมรับว่า ท่านจะใช้เว็บไซต์นี้เพื่อประโยชน์ส่วนตัวหรือวัตถุประสงค์ที่ถูกต้องตามกฎหมายภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้ที่ระบุไว้ในเว็บไซต์นี้เท่านั้น และจะไม่ใช้เว็บไซต์นี้เพื่อประโยชน์ในทางการค้าหรือประโยชน์อื่นใดที่ขัดต่อกฎหมายและศีลธรรมอันดีของประชาชน นอกจากนี้ ท่านตกลงว่าจะไม่กระทำการอย่างใดอย่างหนึ่งดังต่อไปนี้
ก) ทำซ้ำ จัดพิมพ์ขึ้นใหม่ ดัดแปลง ดาวน์โหลด อัพโหลด ติดประกาศ เผยแพร่ หรือทำให้ปรากฏต่อสาธารณชน ซึ่งงานอันผิดกฎหมายหรือศีลธรรมโดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจากบริษัทก่อนล่วงหน้า
ข) กระทำละเมิดสิทธิในทรัพย์สินทางปัญญาของบริษัทหรือของบุคคลอื่น เช่น ลิขสิทธิ์ สิทธิบัตร เครื่องหมายการค้า ความลับทางการค้า หรือ ทรัพย์สินทางปัญญาอื่น ตามที่กฎหมายกำหนดไม่ว่าโดยวิธีการใด
ค) ลบหรือเปลี่ยนแปลงข้อมูลบริหารสิทธิ ซึ่งเป็นข้อมูลที่ติดอยู่หรือปรากฏบนเนื้อหา หรือ ข้อความงานใดๆ ในเว็บไซต์ เพื่อบ่งชี้ถึงผู้สร้างสรรค์ งานสร้างสรรค์ เจ้าของลิขสิทธิ์ อาทิเช่น ชื่อบริษัท ชื่อเจ้าของผู้สร้างสรรค์ ลายน้ำ ตัวเลข หรือรหัสแทนข้อมูลดังกล่าว เป็นต้น รวมถึงไม่เผยแพร่เนื้อหา หรือ ข้อความงานใดๆ ในเว็บไซต์ซึ่งมีการลบหรือเปลี่ยนแปลงข้อมูลบริหารสิทธิ
ง) ดาวน์โหลด อัพโหลด ติดประกาศ หรือ กระทำการใด ๆ เพื่อแสวงหาประโยชน์โดยมิชอบจากเนื้อหา หรือ ข้อความ งานใด ๆ จากเว็บไซต์นี้โดยปราศจากความยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจากบริษัท และ/หรือตั้งกระทู้หรือทำให้ปรากฏซึ่งข้อความที่ก่อให้เกิดความเสียหายต่อบริษัท สมาชิกผู้ใช้บริการอินเทอร์เน็ต และ/หรือบุคคลภายนอก
จ) อัพโหลด ติดประกาศ ส่งอีเมล หรือ ดำเนินการอื่นใดอันมีผลทำให้เป็นการรบกวนหรือแทรกแซงการทำงานของระบบ คอมพิวเตอร์โดยการส่งไวรัส(Virus)หรือโปรแกรมคอมพิวเตอร์ในรูปแบบต่าง ๆ ที่ออกแบบมาเพื่อขัดขวาง ทำลาย จำกัดการทำงานของระบบคอมพิวเตอร์ หรือ คอมพิวเตอร์ฮาร์ดแวร์ หรือ โปรแกรมซอฟท์แวร์ในการสื่อสารของสมาชิกของบริษัทหรือ ผู้ใช้บริการอินเทอร์เน็ตหรือบุคคลภายนอก
ฉ) ใช้โปรแกรมคอมพิวเตอร์ซอฟท์แวร์ ขัดขวาง แทรกแซงการทำงานหรือให้บริการของบริษัท หรือ เครื่องคอมพิวเตอร์และ/หรือระบบคอมพิวเตอร์ของบุคคลภายนอก อาทิเช่น ใช้โปรแกรมซอฟท์แวร์ประเภทม้าโทรจัน(Trojan Horse)ไวรัสในรูปแบบของไทม์บอมส์(Time Bombs)ฯลฯ
ช) กระทำการใด ๆ อันขัดต่อประกาศหรือนโยบายของบริษัทที่ระบุไว้ในข้อกำหนดและเงื่อนไขฉบับนี้ และ/หรือ การแก้ไขเพิ่มเติมข้อกำหนดและเงื่อนไขที่บริษัทจะประกาศหรือกำหนดขึ้นภายหลัง
ซ) กระทำการใด ๆ อันขัดต่อประกาศหรือนโยบายของบริษัทที่ระบุไว้ในข้อกำหนดและเงื่อนไขฉบับนี้ และ/หรือ การแก้ไขเพิ่มเติมข้อกำหนดและเงื่อนไขที่บริษัทจะประกาศหรือกำหนดขึ้นภายหลัง
หากบริษัททราบว่าท่านดำเนินการอย่างหนึ่งอย่างใดอันเป็นการขัดต่อข้อกำหนด และเงื่อนไขที่กำหนดในเว็บไซต์นี้ บริษัทมีสิทธิที่จะระงับการให้บริการแก่ท่านโดยไม่บอกกล่าวล่วงหน้าและ/หรือ อาจดำเนินการในทางกฎหมายกับท่านตามที่บริษัทเห็นสมควรได้ทันที
2.ข้อปฏิบัติก่อนการใช้บริการเว็บไซต์
ก่อนการใช้บริการ ผู้ใช้บริการได้อ่านข้อกฎหมายและเงื่อนไขทั้งหมดโดยละเอียดและได้ทำความเข้าใจและยอมรับข้อกำหนดและเงื่อนไขต่างๆ ของบริษัททั้งหมด บริษัทจะถือว่าผู้ใช้บริการเข้าใจและยอมรับข้อกำหนดและเงื่อนไขต่างๆ หากผู้ใช้บริการดำเนินการดังต่อไปนี้
2.1ผู้ใช้บริการกดปุ่มตกลงเพื่อยอมรับ ข้อกำหนดและเงื่อนไขต่างๆ ซึ่งทางบริษัทจะแสดงทางเลือกในการทำความตกลงหรือปฏิเสธข้อกำหนดและเงื่อนไขต่างๆ  ก่อนการใช้บริการ หรือ แสดงแจ้งเตือนให้แสดงการยินยอมหรือยอมรับโดยผู้ใช้บริการก่อนการใช้บริการ
2.2ผู้ใช้บริการเข้าใช้บริการระบบของบริษัท ซึ่งในกรณีนี้บริษัทย่อมถือได้ว่า ผู้ใช้บริการเข้าใจและยอมรับข้อกำหนดและเงื่อนไขต่างๆ ตั้งแต่เริ่มใช้บริการเป็นต้นไป
3.การเป็นสมาชิก
3.1ในการใช้บริการในเว็บไซต์ของบริษัท ท่านจะต้องสมัครเป็นสมาชิกของเว็บไซต์
3.2บริษัทอาจกำหนดคุณสมบัติพื้นฐานของสมาชิก ตามที่บริษัทแจ้งให้ท่านทราบเป็นครั้งคราว
3.3วิธีสมัครสมาชิก
3.3.1.ท่านสามารถลงทะเบียนเป็นสมาชิกกับบริษัท บนเว็บไซต์ www.jobfinfin.com โดยท่านสามารถสร้างข้อมูลบัญชีผู้ใช้งานด้วยวิธีการตามที่บริษัทกำหนด
3.3.2.เมื่อท่านลงทะเบียนเป็นสมาชิกเพื่อเข้าใช้บริการกับบริษัทเรียบร้อยแล้ว บริษัทอาจจะดำเนินการยืนยันตัวตนและตรวจสอบคุณสมบัติพื้นฐานของท่านตามวิธีการที่บริษัทเห็นว่าเหมาะสม 
3.3.3.ผู้ใช้บริการจะต้องยืนยันตัวตนโดยการส่งข้อมูลส่วนบุคคลตามที่บริษัทร้องขอ อาทิเช่น เลขบัตรประชาชน ที่อยู่ วันเดือนปีเกิด อีเมลล์ หรือนำส่งเอกสารใดๆ เพิ่มเติมตามที่บริษัทร้องขอ หรือดำเนินการยืนยันตัวตนด้วยวิธีการอื่นใดที่บริษัทกำหนด อาทิเช่น การใส่รหัสผ่านครั้งเดียว (OTP) ทั้งนี้ ผู้ใช้บริการยืนยันและรับรองว่า วิธีการยืนยันตัวตนของผู้ให้บริการเป็นวิธีการที่เพียงพอและครบถ้วนตามกฎหมายสำหรับการยืนยันตัวตนของผู้ใช้บริการแล้ว โดยจะไม่ยกข้อต่อสู้ใดๆ ในทางกฎหมายต่อผู้ให้บริการหรือบุคคลที่เกี่ยวข้องถึงความถูกต้องของการยืนยันตัวตนผู้ใช้บริการในภายหลัง ดังนั้น ผู้ใช้บริการจึงยืนยันว่า การทำธุรกรรมใดๆ ผ่านระบบของบริษัทจึงถือเป็นการดำเนินการโดยผู้ใช้บริการด้วยตนเองโดยความสมัครใจ
3.3.4.ผู้ใช้บริการต้องตรวจสอบข้อมูลส่วนตัวของผู้ใช้บริการที่ปรากฏผ่านเว็บไซต์ หากปรากฏว่ามีข้อมูลใดที่ไม่ถูกต้อง ผู้ใช้บริการต้องแจ้งบริษัททราบโดยทันที
3.3.5.การลงทะเบียนเป็นสมาชิกเพื่อเข้าใช้บริการกับบริษัท ให้ถือว่าผู้ใช้บริการตกลงให้ความยินยอมกับบริษัท ในการเข้าถึง รวบรวม ใช้ ประเมินผล หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการเพื่อวัตถุประสงค์ในการให้บริการตามข้อกำหนดและเงื่อนไขการให้บริการฉบับนี้ ทั้งนี้ บริษัทอาจรวบรวมข้อมูลส่วนบุคคลจากผู้ใช้บริการ หรือจากฐานข้อมูลของบุคคลอื่นไม่ว่าผู้ใช้บริการได้ให้ความยินยอมบุคคลอื่นในการรวบรวมหรือเปิดเผยข้อมูลส่วนบุคคลดังกล่าวหรือไม่ก็ตาม สำหรับรายละเอียดเพิ่มเติมเกี่ยวกับวิธีการที่บริษัทใช้ข้อมูลส่วนบุคคลของท่านโปรดดูที่นโยบายความเป็นส่วนตัวของเว็บไซต์จ็อบฟินฟิน – www.jobfinfin.com
3.3.6.บริษัทมีดุลยพินิจเด็ดขาดแต่เพียงผู้เดียวในการรับท่านเป็นสมาชิกหรือปฏิเสธท่านมิให้เป็นสมาชิก
3.3.7.ท่านมีสิทธิถอนใบสมัครสมาชิกได้ตลอดเวลาโดยการแจ้งบริษัททางอีเมลล์ cs.jobfinfin@gmail.com หรือ Line : @jobfinfin  ของบริษัทที่ปรากฏอยู่ในเว็บไซต์ของบริษัท
3.3.8.เมื่อผู้ให้บริการรับท่านเข้าเป็นสมาชิกแล้ว ท่านมีสิทธิในการเข้าใช้บริการในเว็บไซต์ของบริษัท ซึ่งรวมถึง การรับข้อมูลการสมัครงานของผู้ประกอบการที่นำเข้าและเผยแพร่ผ่านทางเว็บไซต์ของบริษัท
3.3.9.เว็บไซต์จะจัดให้มีชื่อผู้ใช้และรหัสผ่านสำหรับการเข้าสู่เว็บไซต์ของผู้ใช้บริการ และยืนยันตัวตนของผู้ใช้บริการ ทั้งนี้ ผู้ใช้บริการตกลงว่า การระบุ/ป้อนหรือใส่ชื่อผู้ใช้และรหัสผ่านของผู้ใช้บริการดังกล่าวเข้าสู่เว็บไซต์ ให้ถือว่าเป็นการลงลายมือชื่ออิเล็กทรอนิกส์ของผู้ใช้บริการตามพระราชบัญญัติว่าด้วยธุรกรรมทางอิเล็กทรอนิกส์ พ.ศ. 2544
3.3.10.ชื่อผู้ใช้และรหัสผ่านสำหรับการเข้าสู่ระบบของผู้ใช้บริการถือเป็นทรัพย์สินของผู้ใช้บริการ ดังนั้น ผู้ใช้บริการจะต้องเก็บรักษาผู้ชื่อใช้และรหัสผ่านสำหรับการเข้าสู่ระบบอย่างเป็นความลับ และไม่เปิดเผยให้แก่บุคคลอื่นรับทราบ ในกรณีที่มีบุคคลอื่นล่วงรู้ชื่อผู้ใช้และรหัสผ่านหรือมีเหตุอันควรสงสัยว่ามีบุคคลอื่นล่วงรู้ชื่อผู้ใช้และรหัสผ่านโดยไม่ชอบด้วยกฎหมาย ผู้ใช้บริการจะต้องแจ้งบริษัทเพื่อเปลี่ยนแปลงชื่อผู้ใช้และรหัสผ่านโดยทันที ทั้งนี้ ผู้ใช้บริการไม่สามารถอ้างเหตุว่าถูกบุคคลอื่นใช้ชื่อผู้ใช้และรหัสผ่านในการเข้าสู่ระบบโดยมิชอบด้วยกฎหมายเพื่อปฏิเสธการใช้บริการบนเว็บไซต์ได้
4.ข้อมูลของผู้ใช้บริการ/ผู้สมัครงาน
ในกรณีที่ผู้ใช้บริการบันทึกข้อมูล ประวัติการทำงาน หรือรายละเอียดข้อมูลส่วนบุคคลของผู้ใช้บริการสำหรับการสมัครงานผ่านทางเว็บไซต์ของบริษัท
4.1ท่านตกลงให้ความยินยอมแก่บริษัทในการเก็บรวบรวม ใช้ ประมวลผล หรือส่งต่อข้อมูลส่วนบุคคลแก่ผู้ประกอบการ เพื่อวัตถุประสงค์ในการสมัครงาน และการดำเนินการอื่นใดที่เกี่ยวข้องกับธุรกิจของบริษัท
4.2บริษัทมีสิทธิตรวจสอบประวัติหรือข้อมูลของผู้ใช้บริการ ในกรณีที่บริษัทตรวจสอบพบว่าประวัติหรือข้อมูลของผู้ใช้บริการมีความไม่ถูกต้องหรือไม่เหมาะสม บริษัทมีสิทธิแจ้งให้ผู้ใช้บริการแก้ไขประวัติหรือข้อมูลของผู้ใช้บริการได้
4.3บริษัทมีสิทธิเด็ดขาดที่จะปฏิเสธไม่แจกจ่าย เผยแพร่ ส่งต่อ หรือ ยกเลิก รวมทั้งนำออกจากเว็บไซต์ซึ่ง ประวัติหรือข้อมูลของผู้ใช้บริการที่ได้ส่งให้แก่บริษัท หรือถูกบันทึกและจัดเก็บในเว็บไซต์
4.4บริษัทอาจปฏิเสธ ถอดถอน หรือแก้ไขประวัติหรือข้อมูลของผู้ใช้บริการที่ขัดต่อกฎหมาย ศีลธรรมอันดี หรือมีเนื้อหาอนาจาร น่ารังเกียจ หรือด้วยเหตุอื่นใดอันมิชอบด้วยกฎหมาย ตลอดจนข้อมูลไม่ถูกต้อง ผิดพลาด ไม่ครบถ้วน ไม่สมบูรณ์ ถูกลบ ถูกแก้ไข หรือมีข้อบกพร่อง บริษัทสามารถแก้ไขข้อมูลเพื่อให้ถูกต้องสมบูรณ์ตามที่เห็นสมควรได้โดยมิต้องบอกกล่าว
4.5ผู้ใช้บริการขอรับรองและยืนยันว่า
4.5.1ประวัติหรือข้อมูลของผู้ใช้บริการ ตลอดจนรายละเอียดและข้อมูลอื่นใดที่ถูกบันทึกและจัดเก็บไว้ในเว็บไซต์ของบริษัทเป็นข้อมูลที่ถูกต้อง ครบถ้วน ตามจริง และเป็นปัจจุบัน หากมีการเปลี่ยนแปลงใด ๆ ของรายละเอียดและข้อมูลดังกล่าว ผู้ใช้บริการมีหน้าที่ต้องแก้ไขปรับปรุงให้ถูกต้อง ครบถ้วน ตามจริง และเป็นปัจจุบันเสมอ
4.5.2ผู้บริการตกลงว่าจะไม่กระทำการใด ๆ อันเป็นการขัดต่อข้อกฎหมาย ศีลธรรม รวมทั้งจะไม่กระทำการใด ๆ ซึ่งถือเป็นการละเมิดสิทธิใด ๆ ของบริษัทหรือบุคคลอื่น หรืออาจจะก่อให้เกิดความเสียหายต่อบริษัท หรือบุคคลอื่น
4.5.3หากพบว่าผู้ใช้บริการได้ฝ่าฝืนกฎหมายหรือเงื่อนไขข้อตกลงฉบับนี้ไม่ว่าบางส่วนหรือทั้งหมด บริษัทสงวนสิทธิในการ ลบบัญชีผู้ใช้งาน และ/หรือ ระงับการใช้บริการตามดุลพินิจของบริษัทแต่เพียงผู้เดียว โดยมิต้องบอกกล่าว
4.5.4ประวัติหรือข้อมูลของผู้ใช้บริการที่ได้ส่งให้แก่บริษัท หรือถูกบันทึกและจัดเก็บในเว็บไซต์ หากมี ชื่อ ภาพ ข้อมูลติดต่อ หรือข้อมูลส่วนบุคคลของบุคคลอื่น ไม่ว่าส่วนใดส่วนหนึ่งหรือทั้งหมด ผู้ใช้บริการขอรับรองและยืนยันว่าได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลนั้นแล้ว และมีสิทธิที่จะนำข้อมูลหรือเอกสารของบุคคลนั้นมาใช้ประกอบการสมัครงานหรือที่เกี่ยวข้องกับการสมัครงาน ในการนี้ บริษัทจะไม่รับผิดชอบต่อความเสียหายใด ๆ ที่เกิดขึ้น ตลอดจนหากมีการฟ้องร้องหรือเรียกร้องใด ๆ ในการนำข้อมูลหรือเอกสารดังกล่าวข้างต้นมาใช้
5.การสมัครงาน
5.1วิธีการสมัครงานผ่านทางเว็บไซต์ของบริษัท
5.1.1ค้นหางานที่ต้องการจะสมัคร
5.1.2กดปุ่ม “สมัครงาน”
5.1.3ระบบจะแสดงอีเมลสมัครงานที่ประกอบด้วย ชื่ออีเมลของบริษัท หัวข้ออีเมล รายละเอียดในการสมัคร และเอกสารเรซูเม่ที่ต้องการแนบในอีเมล
5.1.4หากผู้สมัครต้องการแนบเรซูเม่ที่สร้างด้วยตนเองให้กดที่ “อัพโหลดไฟล์” หากต้องการใช้เรซูเม่ที่สร้างโดย Jobfifin ให้กดที่ปุ่ม “ใช้เรซูเม่อัตโนมัติ”
5.1.5จากนั้นให้กด “ตั้งค่า” เพื่อเลือกเครื่องมือในการส่งอีเมลสมัครงาน เช่น Gmail, Outlook เป็นต้น
5.1.6กดที่ “สมัครงาน” การส่งอีเมลสำเร็จก็จะทำให้การสมัครงานเรียบร้อย 
5.2ผู้ใช้บริการรับทราบและตกลงว่า เว็บไซต์ของบริษัทเป็นเพียงช่องทางในการรับสมัครงาน และบริษัทจะส่งต่อข้อมูลและประวัติของผู้สมัครงานให้แก่ผู้ประกอบการโดยระบบคอมพิวเตอร์ซึ่งทำงานอัตโนมัติ ดังนั้น บริษัทจึงไม่มีส่วนเกี่ยวข้องในการพิจารณารับสมัครงานของผู้ประกอบการแต่อย่างใด
5.3การสมัครงานของผู้ใช้บริการ เป็นนิติกรรมระหว่างผู้ประกอบการกับผู้ใช้บริการโดยตรง โดยบริษัทไม่มีส่วนเกี่ยวข้องหรือความสัมพันธ์กับผู้ใช้บริการหรือผู้ประกอบการแต่อย่างใด
5.4บริษัทอาจตรวจสอบข้อมูลหรือประวัติของผู้ใช้บริการเป็นครั้งคราว และหากบริษัทตรวจสอบพบว่าข้อมูลหรือประวัติของผู้ใช้บริการไม่ถูกต้อง บริษัทอาจร้องขอให้ผู้ใช้บริการแก้ไขข้อมูลให้มีความถูกต้องและเหมาะสม ผ่านช่องทางการติดต่อที่ผู้ใช้บริการได้ให้ข้อมูลกับบริษัท เช่น อีเมลล์ หรือโทรศัพท์ ทั้งนี้ หากผู้ใช้บริการไม่แก้ไขข้อมูล หรือประวัติดังกล่าวภายในเวลาที่กำหนด บริษัทสงวนสิทธิในลบบัญชีผู้ใช้งาน และ/หรือ ระงับการใช้บริการตามดุลพินิจของบริษัทแต่เพียงผู้เดียว โดยมิต้องบอกกล่าวแก่ผู้ใช้บริการล่วงหน้า
5.5ผู้ใช้บริการรับทราบว่า ข้อมูลการรับสมัครงานของผู้ประกอบการบนเว็บไซต์ของบริษัท เป็นข้อมูลที่ผู้ประกอบการนำเสนอ โดยบริษัทไม่มีหน้าที่ในการตรวจสอบความถูกต้องของข้อมูลของผู้ประกอบการ ดังนั้น บริษัทจึงไม่รับผิดชอบในเนื้อหา หรือข้อมูลการรับสมัครงานของผู้ประกอบการบนเว็บไซต์
6. ทรัพย์สินทางปัญญา
ผู้ใช้บริการตกลงและยอมรับว่า บรรดาข้อมูล ข้อความ รูปภาพ เสียง โลโก้ และ/หรือส่วนหนึ่งส่วนใดของเว็บไซต์นี้ รวมถึงแต่ไม่จำกัดเพียง ลิขสิทธิ์ เครื่องหมายการค้า เครื่องหมายบริการ ชื่อทางการค้า ฐานข้อมูล ความลับทางการค้า สิทธิบัตร โนว์ฮาว์ ข้อมูลธุรกิจ ฯลฯ ที่ปรากฏบนเว็บไซต์ เป็นงานที่ได้รับความคุ้มครองตามกฎหมายทรัพย์สินทางปัญญาซึ่งเป็นกรรมสิทธิ์ ลิขสิทธิ์ สิทธิและทรัพย์สินของบริษัทแต่เพียงผู้เดียว 
ท่านตกลงและรับทราบว่า เว็บไซต์ของบริษัทเป็นเพียงสื่อกลางในการเผยแพร่ข้อมูลการสมัครงานแก่ผู้ใช้บริการเท่านั้น บริษัทมิได้ให้คำรับรองหรือรับประกันใดๆ เกี่ยวกับความถูกต้องแท้จริง หรือความสมบูรณ์ของเนื้อหา ภาพ หรือรายละเอียดใดๆ ในเว็บไซต์ข้างต้น ในกรณีที่เกิดข้อพิพาทใดๆ ในทางกฎหมาย ท่านตกลงสละสิทธิเรียกร้องในการดำเนินคดีใดๆ ไม่ว่าทางแพ่งหรืออาญา ต่อบริษัท และ/หรือ กรรมการของบริษัท
7.การจำกัดความรับผิด
นอกจากข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวมท่านตกลงและยอมรับว่า บรรดาข้อมูลบางส่วนที่ปรากฏในเว็บไซต์นี้ ข้อความดังกล่าวเป็นข้อความที่เขียนหรือทำขึ้นโดยบุคคลภายนอกหรือ ผู้ใช้บริการเว็บไซต์บางส่วน ซึ่งไม่เกี่ยวข้องและอยู่เหนือความควบคุมของบริษัท บริษัทไม่มีความรับผิด ใด ๆ ที่เกิดจากเนื้อหาหรือข้อความดังกล่าว ไม่ว่าจะเป็นความเสียหาย การฟ้องร้อง เรียกร้อง หรือ การกระทำละเมิดใด ๆ ไม่ว่าในทางแพ่งหรือทางอาญา อาชญากรรมทางคอมพิวเตอร์หรือความรับผิดใด ๆ ในทางกฎหมาย ซึ่งมีผลกระทบต่ออุปกรณ์คอมพิวเตอร์ ทรัพย์สิน สิทธิ หรือ ทรัพย์สินใด ๆ ที่เกี่ยวข้องกับท่านหรือบุคคลภายนอก ไม่ว่าจะโดยความผูกพันตามสัญญา ละเมิด ความรับผิดทางอาญา หรือ โดยประการอื่น
ข้อยกเว้นความรับผิดของบริษัทดังกล่าวนี้ ให้รวมไปถึงความผิดพลาดจากการให้บริการข้อมูล และหรือ บทความ รับส่งข้อมูล หรือการกระทำการใด ๆ ของบริษัท พันธมิตรทางธุรกิจของบริษัท ผู้ให้บริการร่วมหรือผู้ใช้บริการอินเทอร์เน็ตอื่น ๆ
8.ข้อสละสิทธิในเนื้อหาบนเว็บไซต์
ข้อความ ภาพวาด ภาพถ่าย งานรวบรวม หรือข้อมูลต่าง ๆ บนเว็บไซต์ บางส่วนเป็นการรวบรวมข้อมูลจากแหล่งต่าง ๆ มาเพื่อเป็นข้อมูล และประโยชน์แก่ผู้ใช้บริการ ซึ่งบริษัทได้ใช้ความพยายามอย่างเต็มที่ในการรวบรวมข้อมูลต่าง ๆ ให้ถูกต้อง และทันสมัย (update) อยู่เสมอ อย่างไรก็ตาม บริษัทไม่รับรองความน่าเชื่อถือ ความถูกต้อง และความทันสมัย(update)ของข้อมูลดังกล่าว ท่านตกลงและยอมรับว่า บริษัทไม่มีอำนาจในการดูแลควบคุมข้อมูลทั้งหมดบนเว็บไซต์นี้ เนื่องจาก การให้บริการของบริษัทเป็นเพียงสื่อกลางในการให้บริการข้อมูล และ/หรือ บทความแก่ผู้ใช้บริการเท่านั้น
9. การลบหรือระงับการเผยแพร่ข้อความตามพระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ พ.ศ. 2550 และ/หรือ กฎหมายทรัพย์สินทางปัญญา
ในกรณีที่ท่านตรวจพบว่า บริษัทเผยแพร่ข้อมูลที่ผิดกฎหมายตามมาตรา 14 แห่งพระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ พ.ศ. 2550 และ/หรือ กฎหมายทรัพย์สินทางปัญญา ท่านอาจแจ้งเตือนบริษัทเพื่อขอให้ระงับหรือลบการแพร่หลายของข้อมูลที่ผิดกฎหมายด้วยวิธีการดังต่อไปนี้
(ก) ลงบันทึกประจำวันเพื่อเป็นหลักฐานต่อเจ้าพนักงานสอบสวน โดยแจ้งรายละเอียดที่เกี่ยวข้องกับการเผยแพร่ข้อมูลที่ผิดกฎหมายตามมาตรา 14 แห่งพระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ พ.ศ. 2550 และ/หรือ กฎหมายทรัพย์สินทางปัญญา รายละเอียดของบริษัท และรายละเอียดความเสียหายที่เกิดขึ้นกับท่านหรือบุคคลภายนอก เป็นต้น พร้อมยื่นเอกสารที่แสดงให้เห็นถึงการกระทำความผิดดังกล่าวและเอกสารอื่นที่เกี่ยวข้องแก่เจ้าพนักงานสอบสวน
(ข)แจ้งรายละเอียดที่เกี่ยวข้องกับการเผยแพร่ข้อมูลที่ผิดกฎหมายตามมาตรา 14 แห่งพระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ พ.ศ. 2550 และ/หรือ กฎหมายทรัพย์สินทางปัญญา ต่อบริษัท โดยการกรอกรายละเอียดในแบบฟอร์มที่บริษัทกำหนด พร้อมยื่นเอกสารที่แสดงให้เห็นถึงการกระทำความผิดดังกล่าวและเอกสารอื่นที่เกี่ยวข้องแก่บริษัท
เมื่อบริษัทได้รับข้อร้องเรียนตามแบบฟอร์มข้างต้น และเอกสารที่เกี่ยวข้อง บริษัทจะดำเนินการดังต่อไปนี้
(ก) ดำเนินการลบหรือแก้ไขเปลี่ยนแปลงข้อมูลเพื่อไม่ให้แพร่หลายต่อไปโดยเร็ว
(ข)จัดทำสำเนาข้อร้องเรียนรวมถึงรายละเอียดข้อร้องเรียนของบุคคลที่ร้องเรียนดังกล่าวส่งให้กับผู้ใช้บริการซึ่งอยู่ในความควบคุมดูแลของบริษัท
(ค) ระงับซึ่งการแพร่หลายข้อมูลดังกล่าวโดยรวดเร็วที่สุดเท่าที่จะทำได้ตามความเหมาะสมและขึ้นอยู่กับสภาพของการให้บริการแต่ละประเภทไป แต่ต้องไม่เกิน 3 วันทำการนับแต่วันที่ได้รับข้อร้องเรียนดังกล่าว หรือระยะเวลาอื่นที่เหมาะสมเพื่อเยียวยาความเสียหายและระงับการกระทำความผิดให้เร็วที่สุด

ทั้งนี้ เมื่อบริษัทได้ดำเนินการข้างต้นครบถ้วนแล้ว ท่านตกลงสละสิทธิข้อต่อสู้ทางกฎหมายทั้งปวงต่อบริษัท และให้บริษัทหลุดพ้นจากความรับผิดตามกฎหมายทั้งปวง
10. การส่งข้อมูลประชาสัมพันธ์
บริษัทอาจจะส่งไปรษณีย์อิเล็กทรอนิกส์ (Email) หรือข้อความอิเล็กทรอนิกส์เกี่ยวกับผลิตภัณฑ์และบริการที่ท่านอาจสนใจไปยังท่านเป็นคราวๆ ไป โดยท่านตกลงและยินยอมในการรับไปรษณีย์อิเล็กทรอนิกส์ (Email) หรือข้อความอิเล็กทรอนิกส์ อย่างไรก็ตามท่านมีทางเลือกหรืออาจจะปฏิเสธที่จะไปรษณีย์อิเล็กทรอนิกส์ (Email) หรือข้อความอิเล็กทรอนิกส์ทั้งหมดก็ได้ ทั้งนี้ หากท่านปฏิเสธที่จะรับไปรษณีย์อิเล็กทรอนิกส์ (Email) หรือข้อความอิเล็กทรอนิกส์ทั้งหมด ถือว่า ท่านรับทราบว่าท่านอาจไม่สามารถใช้บริการของบริษัทและบริษัทในเครือได้อย่างครบถ้วน หรือเข้าใช้บริการบางเว็บไซต์ได้อย่างมีประสิทธิภาพได้
11.การชำระเงิน
ท่านตกลงและรับทราบว่า บริษัทเป็นเพียงสื่อกลางในการให้บริการให้แก่ผู้ใช้บริการทุกท่าน ดังนั้น บริษัทจึงไม่มีหน้าที่หรือนิติสัมพันธ์ใดกับท่านในการทำธุรกรรมในการซื้อขายสินค้าหรือบริการในเว็บไซต์นี้ หากเกิดปัญหาเกี่ยวกับการชำระเงิน ท่านต้องติดต่อผู้ที่จำหน่ายสินค้าและ/หรือบริการดังกล่าวโดยตรง ตามที่อยู่ที่ผู้จำหน่ายสินค้าหรือบริการแต่ละรายระบุไว้ในเว็บไซต์ รวมถึงภาระภาษีและความรับผิดอื่น ๆ ที่เกี่ยวข้อง
12. นโยบายการคุ้มครองข้อมูลส่วนบุคล
ท่านรับทราบว่า การดำเนินการให้บริการเนื้อหาต่างๆ บนเว็บไซต์นี้เป็นการประมวลผลโดยเครื่องคอมพิวเตอร์โดยอัตโนมัติ ซึ่งอยู่เหนือความควบคุมของกรรมการและผู้บริหารของบริษัท บริษัทไม่สามารถตรวจสอบเนื้อหา ข้อความ รูปภาพ หรือรายละเอียดทั้งหมดได้ ดังนั้น เพื่อเป็นการระงับข้อพิพาทต่าง ๆ ที่อาจเกิดขึ้นจากการให้บริการบนเว็บไซต์นี้ของบริษัท หากผู้ใช้บริการประสบปัญหาหรือได้รับความเสียหายจากข้อความ เนื้อหา หรือ บริการใด ๆ รวมถึงการละเมิดสิทธิ และสิทธิทรัพย์สินทางปัญญาใดๆ ที่ปรากฏบนเว็บไซต์ของบริษัท บริษัทมีนโยบายในการระงับข้อพิพาทต่าง ๆ ดังนี้
ก)  ท่านตกลงและรับทราบว่า บริษัทอาจเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่านตลอดระยะเวลาการประกอบกิจการให้บริการของบริษัท ทั้งนี้ เพื่อประโยชน์ในกิจการของบริษัท ซึ่งรวมถึงการพัฒนาและปรับปรุงคุณภาพการให้บริการทางเว็บไซต์ของบริษัท
ข) ในกรณีที่ท่านเปลี่ยนแปลงข้อมูลส่วนบุคคลของท่าน ท่านต้องดำเนินการแจ้งต่อบริษัท เพื่อให้บริษัทปรับปรุงข้อมูลให้ถูกต้อง ทันสมัย สมบูรณ์มากที่สุด อย่างไรก็ตาม หากท่านไม่แจ้งการเปลี่ยนแปลงข้อมูลส่วนบุคคลดังกล่าวต่อบริษัท ถือว่าข้อมูลส่วนบุคคลของท่านที่บริษัทควบคุมดูแลเป็นข้อมูลที่ถูกต้อง ทันสมัย และสมบูรณ์ โดยท่านสละสิทธิในการโต้แย้งหรือดำเนินการทางกฎหมายต่อบริษัทต่อความไม่ถูกต้องสมบูรณ์ของข้อมูลส่วนบุคคลดังกล่าว
ค) บริษัทจะใช้ความพยายามอย่างดีที่สุดในการจัดให้มีมาตรการการรักษาความปลอดภัยของข้อมูลส่วนบุคคลของท่าน เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผยข้อมูลส่วนบุคคลของท่านโดยมิชอบ
ง) ท่านรับทราบว่า บริษัทมีหน้าที่ในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลตามที่กฎหมายกำหนด 
จ) ท่านรับทราบและตกลงว่า บริษัทอาจเก็บรวบรวมข้อมูลของท่านจากเว็บไซต์ องค์กรอื่น หรือแหล่งอื่นเพิ่มเติม ทั้งนี้เพื่อประโยชน์ในกิจการของบริษัท รวมถึงการพัฒนาและปรับปรุงคุณภาพการให้บริการทางเว็บไซต์ของบริษัท
ฉ) ท่านรับทราบและตกลงว่า เพื่อประโยชน์ในกิจการของบริษัท รวมถึงการพัฒนาและปรับปรุงคุณภาพการให้บริการทางเว็บไซต์ของบริษัท บริษัทอาจส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังประเทศอื่น ทั้งนี้ประเทศดังกล่าวต้องมีมาตรการในการคุ้มครองข้อมูลส่วนบุคคลไม่ต่ำกว่ากฎหมายคุ้มครองข้อมูลส่วนบุคคลของประเทศไทย
ช) ท่านรับทราบและตกลงว่า ท่านมีสิทธิในการตรวจสอบหรือเข้าถึงข้อมูลส่วนบุคคลของท่าน ทั้งนี้ ท่านต้องแจ้งความประสงค์ในการตรวจสอบหรือเข้าถึงข้อมูลส่วนบุคคลรวมถึงเหตุผลในการตรวจสอบแก่บริษัททราบเป็นลายลักษณ์อักษรอย่างน้อย 14 วัน
อย่างไรก็ตาม บริษัทอาจปฏิเสธมิให้ท่านตรวจสอบหรือเข้าถึงข้อมูลส่วนบุคคลของท่านหากเป็นกรณีดังต่อไปนี้
1) เป็นการขัดแย้งกับกฎหมายอื่นหรือคำสั่งศาล
2) กระทบต่อความมั่นคงแห่งราชอาณาจักร
3) กระทบต่อเศรษฐกิจและการพาณิชย์ของประเทศ
4) มีผลกระทบต่อการสืบสวน สอบสวนของพนักงานเจ้าหน้าที่ตามกฎหมายหรือการพิจารณาคดีของศาล
5) เพื่อคุ้มครองเจ้าของข้อมูล หรือสิทธิเสรีภาพของบุคคลอื่น
ซ) ท่านรับทราบว่า มาตรการคุ้มครองข้อมูลส่วนบุคคลของบริษัทเป็นไปตามมาตรฐานสากลและสอดคล้องกับหลักกฎหมายที่เกี่ยวข้อง หากท่านไม่ประสงค์ให้บริษัทเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ท่านต้องแจ้งเป็นลายลักษณ์อักษรแก่บริษัทล่วงหน้าอย่างน้อย 7 วัน เพื่อบริษัทจะได้ยุติการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
13.นโยบายระงับข้อพิพาท
ท่านรับทราบว่า การดำเนินการให้บริการเนื้อหาต่างๆ บนเว็บไซต์นี้เป็นการประมวลผลโดยเครื่องคอมพิวเตอร์โดยอัตโนมัติ ซึ่งอยู่เหนือความควบคุมของกรรมการและผู้บริหารของบริษัท บริษัทไม่สามารถตรวจสอบเนื้อหา ข้อความ รูปภาพ หรือรายละเอียดทั้งหมดได้ 
เพื่อเป็นการระงับข้อพิพาทต่าง ๆ ที่อาจเกิดขึ้นจากการให้บริการบนเว็บไซต์นี้ของบริษัท หากผู้ใช้บริการประสบปัญหาหรือได้รับความเสียหายจากข้อความ เนื้อหา หรือ บริการใด ๆ รวมถึงการละเมิดสิทธิ และสิทธิทรัพย์สินทางปัญญาใดๆ ที่ปรากฏบนเว็บไซต์ของบริษัท บริษัทมีนโยบายในการระงับข้อพิพาทดังนี้
1)ผู้ที่ได้รับความเสียหายต้องดำเนินการแจ้งข้อเรียกร้องเป็นลายลักษณ์อักษร ที่ชัดเจนแก่พนักงานของบริษัทตามที่อยู่ข้างล่างนี้ โดยระบุรายละเอียด ดังต่อไปนี้
(ก) ชื่อ ที่อยู่ ที่สามารถติดต่อได้
(ข) หมายเลขโทรศัพท์/อีเมล ที่สามารถติดต่อได้
(ค) รายละเอียดของข้อร้องเรียนและ/หรือพยานหลักฐานเกี่ยวกับการกระทำผิดกฎหมาย (ถ้ามี)
(ง) ข้อเสนอแนะหรือคำแนะนำหรือคำร้องที่ต้องการให้บริษัทดำเนินการ\n` +
      `2) ท่านต้องดำเนินการแจ้งข้อเรียกร้องและ/หรือข้อเสนอแนะดังกล่าวโดยติดต่อมายังบริษัท
      ภายหลังจากบริษัทรับทราบข้อเรียกร้องดังกล่าวแล้ว บริษัทจะดำเนินการติดต่อกับผู้ที่เกี่ยวข้องและแจ้งให้กรรมการผู้จัดการหรือผู้ได้รับมอบหมายของบริษัททราบ โดยจะดำเนินการแก้ไขปัญหาดังกล่าว ภายใน 15 วันทำการ นับแต่วันที่ท่านได้แจ้งให้บริษัททราบเป็นลายลักษณ์อักษร โดยมีเนื้อหาตามที่บริษัทระบุไว้ข้างต้น ในระหว่างการดำเนินการดังกล่าว ท่านตกลงที่จะไม่ดำเนินคดีหรือท่านสละสิทธิในการดำเนินคดี ไม่ว่าในทางแพ่งและ/หรือทางอาญากับบริษัท เนื่องจาก บริษัทจำเป็นต้องใช้เวลาในการตรวจสอบและสอบสวนข้อเท็จจริงเพิ่มเติม
      14. การชดเชยค่าเสียหาย
      ท่านตกลงและยอมรับว่า ท่านจะให้ความร่วมมือกับบริษัทในการดำเนินการต่าง ๆ เพื่อแก้ไขและยุติปัญหาที่เกิดจากการผิดสัญญา การละเมิด หรือ ปัญหาข้อกฎหมายอื่น ๆ กับบริษัทอย่างเต็มที่ รวมทั้งตกลงและปกป้องไม่ให้บริษัท กรรมการ ผู้บริหารหรือพนักงานของบริษัทหรือบริษัทในเครือถูกฟ้องร้องหรือเรียกร้อง ค่าเสียหายจากบุคคลภายนอก หากท่านกระทำผิดข้อกำหนดและเงื่อนไขใด ๆ ในเว็บไซต์นี้ ท่านจะชดเชยค่าเสียหายให้แก่บริษัทตามที่กฎหมายกำหนด
      15. การแก้ไขเพิ่มเติม
      บริษัทขอสงวนไว้ซึ่งสิทธิในการแก้ไข เปลี่ยนแปลง เพิ่มเติม ตัดทอน บรรดาข้อกำหนดและเงื่อนไขใด ๆ ที่ระบุไว้ในเว็บไซต์นี้ โดยไม่จำต้องบอกกล่าวล่วงหน้า
      16. กฎหมายที่ใช้บังคับ
      หากเกิดข้อพิพาทใด ๆ ที่เกิดจากการให้บริการของบริษัท อันเนื่องมาจากเว็บไซต์นี้ ท่านและบริษัทตกลงใช้กฎหมายไทยในการระงับข้อพิพาทการใช้บริการในเว็บไซต์นี้ของท่าน ถือเป็นการตกลงและยอมรับข้อกำหนดและเงื่อนไขของเว็บไซต์ฉบับนี้ และการที่ท่านใช้บริการภายหลังจากการมีการแก้ไข เพิ่มเติม ข้อกำหนดและเงื่อนไขของเว็บไซต์ฉบับนี้ของบริษัทในอนาคต ย่อมถือว่าท่านยอมรับข้อกำหนดและเงื่อนไขของเว็บไซต์ของบริษัทที่ได้มีการแก้ไข เพิ่มเติม ดังกล่าว`,
  },
  {
    id: 5,
    question: 'เราคือใคร',
    answer:
      'เราคือสตาร์ทอัพเพื่อสังคม (social enterprise) ที่ต้องการช่วยให้คนไทย ไม่ตกงาน และเจองานที่ใช่ โดยเฉพาะอย่างยิ่งในช่วง Covid 19 ที่งานหายาก กระจัดกระจายไปตามกลุ่มเฟซบุ๊คต่างๆ มันคงจะดีถ้ามีเทคโนโลยีที่ช่วยพาบริษัทต่างๆมาเจอคนหางานได้ง่าย และตอบโจทย์คนทั้งสองฝ่าย',
  },
];

export default faq;
