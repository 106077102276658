import React from 'react';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {Container, Content} from './styled-component';
import NotifyPostItem from '../notify-post-item';
import Header from '../../components/header';
import * as typeDef from '../../utils/strong-types';

const NotifyPostBuilder = (props) => {
  const {allNotify, searchParams} = props;
  const {formatMessage} = useIntl();

  if (typeDef.isNullOrUndefined(allNotify) || !typeDef.isArrayLength(allNotify)) {
    return (
      <Content>
        <Header variant="h4" center>
          {formatMessage({defaultMessage: 'ไม่มีรายการแจ้งเตือน'})}
        </Header>
      </Content>
    );
  }

  return (
    <Container>
      {typeDef.isArrayLength(allNotify) &&
        allNotify.map((item) => {
          return <NotifyPostItem notifyData={item} key={item?._id} searchParams={searchParams} />;
        })}
    </Container>
  );
};

NotifyPostBuilder.propTypes = {
  allNotify: PropTypes.array,
  searchParams: PropTypes.string,
};

NotifyPostBuilder.defaultProps = {
  allNotify: [],
  searchParams: '',
};

export default NotifyPostBuilder;
