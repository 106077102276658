import {useState, useEffect} from 'react';

export const useWindowResizeEffect = () => {
  const [windowSizeState, setWindowSizeState] = useState({width: window.innerWidth, height: window.innerHeight});

  useEffect(() => {
    function onSizeChange() {
      setWindowSizeState({width: window.innerWidth, height: window.innerHeight});
    }
    window.addEventListener('resize', onSizeChange);
    return () => window.removeEventListener('resize', onSizeChange);
  }, []);

  return {
    windowWidth: windowSizeState.width,
    windowHeight: windowSizeState.height,
  };
};
