import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {isArrayLength} from '../../utils/strong-types';
import {translate} from '../../res/language/translate';
import {
  InputField,
  InputWrapper,
  InputLabel,
  LimitLabel,
  BottomWrapper,
  Container,
  SelectBoxWrapper,
  SelectBox,
  OverrideStyles,
  ModalWrapper,
} from './styled-component';
import Icon from '../icon';
import Text from '../text';
import Modal from '../modal';
import Button from '../button';
import Row from '../row';
import OptionItem from './sub-comp';
import SelectSandBox from './select-sandbox';
import * as generate from '../../utils/generate';
import * as deepState from '../../utils/deep-state';
import * as utils from './utils';

const Select = (props) => {
  const {
    id,
    outline,
    iconName,
    limitSelect,
    name,
    selectedOptions,
    onChange,
    placeholder,
    label,
    shapeTextFunc,
    onClickChip,
    onClear,
    options,
    sandboxColor,
    iconLabel,
    disabled,
  } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [idFor] = useState(id || generate.generateIdString('select'));
  const [openChildList, setOpenChildList] = useState({});

  const onChangeOptions = (parentItem, childItem) => {
    if (!onChange) {
      return;
    }
    onChange(parentItem, childItem);
  };

  const onConfirmOption = () => {
    setIsVisibleModal(false);
  };

  const onChangeOpenChildList = (callbackId) => {
    const newOpenChildList = deepState.cloneDeepState({data: openChildList});
    if (newOpenChildList[callbackId]) {
      delete newOpenChildList[callbackId];
      setOpenChildList({...newOpenChildList});
      return;
    }
    newOpenChildList[callbackId] = true;
    setOpenChildList({...newOpenChildList});
  };

  return (
    <>
      <Modal visible={isVisibleModal} onRequestClose={() => setIsVisibleModal(false)}>
        <ModalWrapper>
          <SelectBoxWrapper id="slb">
            <SelectBox>
              {isArrayLength(options) &&
                options.map((item) => (
                  <OptionItem
                    key={item.id}
                    isChecked={utils.checkIsSelectedById({id: item.id, selectedOptions})}
                    text={item.label}
                    parentId={item.id}
                    openChildList={openChildList}
                    options={item.sub}
                    selectedOptions={selectedOptions}
                    onClickExpand={onChangeOpenChildList}
                    onChange={(childItem) => onChangeOptions(item, childItem)}
                  />
                ))}
            </SelectBox>
          </SelectBoxWrapper>
          <BottomWrapper>
            <Button variant="light" style={OverrideStyles.button} onClick={() => onClear()}>
              {translate('global.clear')}
            </Button>
            <Button block onClick={() => onConfirmOption()}>
              {utils.getButtonConfirmLabel({limitSelect, selectedOptions})}
            </Button>
          </BottomWrapper>
        </ModalWrapper>
      </Modal>
      <Container>
        <Row content="space-between">
          {!iconLabel && <InputLabel>{label}</InputLabel>}
          {iconLabel && (
            <Row>
              <Icon name={iconLabel} color={OverrideStyles.icon.blueFade} />
              <InputLabel edgeInsets>{label}</InputLabel>
            </Row>
          )}
          {limitSelect && <LimitLabel>{utils.getLimitLabel({limitSelect, selectedOptions})}</LimitLabel>}
        </Row>
        <InputWrapper
          disabled={disabled}
          outline={outline}
          sandboxColor={sandboxColor}
          onClick={() => {
            if (!disabled) setIsVisibleModal(true);
          }}>
          {iconName && <Icon name={iconName} color={OverrideStyles.icon.color} />}
          <InputField id={idFor} name={name} value={JSON.stringify(selectedOptions)} onChange={() => {}} />
          <Text color={OverrideStyles.placeholder.color}>{placeholder}</Text>
        </InputWrapper>
        <SelectSandBox
          outline={outline}
          sandboxColor={sandboxColor}
          selectedOptions={selectedOptions}
          shapeTextFunc={shapeTextFunc}
          onClickChip={onClickChip}
        />
      </Container>
    </>
  );
};

Select.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  iconName: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  limitSelect: PropTypes.number,
  shapeTextFunc: PropTypes.func,
  sandboxColor: PropTypes.oneOf(['white', 'gray']),
  onClickChip: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  outline: PropTypes.bool,
  iconLabel: PropTypes.string,
};

Select.defaultProps = {
  id: '',
  options: null,
  name: '',
  selectedOptions: [],
  iconName: '',
  placeholder: '',
  label: '',
  limitSelect: null,
  onChange: null,
  shapeTextFunc: null,
  onClickChip: null,
  sandboxColor: 'white',
  outline: false,
  disabled: false,
  iconLabel: null,
  onClear: () => {},
};

export default Select;
