/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {Container, Label, TextArea, InputHint} from './styled-component';

const InputArea = (props) => {
  const {name, label, placeholder, value, onChange, onkeydown, warningText, height} = props;
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <TextArea
        height={height}
        placeholder={placeholder}
        warningText={!!warningText}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onkeydown}
      />
      {warningText && <InputHint warning={!!warningText}>{warningText}</InputHint>}
    </Container>
  );
};

InputArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onkeydown: PropTypes.func,
  warningText: PropTypes.string,
  placeholder: PropTypes.string,
  height: PropTypes.number,
};

InputArea.defaultProps = {
  name: null,
  label: null,
  value: null,
  warningText: null,
  placeholder: null,
  height: 200,
  onChange: () => {},
  onkeydown: () => {},
};

export default InputArea;
