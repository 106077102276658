import {translate} from '../../res/language/translate';

export const webHandlerErrorImageAndFilePicker = (error) => {
  let errorString = '';
  if (error) {
    errorString = error.toString().replace('Error:', '').trim();
  }
  switch (errorString) {
    case 'cannot select file maybe browser is not support':
      return translate('webPicker.browserNotSupport');
    case 'empty selected images':
      return translate('webPicker.emptyImage');
    case 'image type not support':
      return translate('webPicker.invalidImageType');
    case 'empty selected files':
      return translate('webPicker.emptyFile');
    case 'file type was not support':
      return translate('webPicker.invalidFileType');
    default:
      return translate('global.error.default');
  }
};
