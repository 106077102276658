import styled from 'styled-components';

export const StyledGesture = styled.div`
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
  ${(props) => props.withEdgeInsets && 'padding: 1rem;'};
  ${(props) => props.circle && `border-radius: 50%`};
  &:hover {
    ${(props) => props.hovered && `background-color: ${props.theme.lightHover100}`};
  }
`;
