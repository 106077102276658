/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {translate} from '../../../res/language/translate';
import {ButtonSmallWrapper, HeaderWrapper, ButtonLargeWrapper, OverrideStyles} from './styled-component';
import {useRegister} from '../../../lib/hooks/logic/useRegister';
import Select from '../../../components/select';
import Form from '../../../components/form';
import Button from '../../../components/button';
import Header from '../../../components/header';
import FormWrapper from '../../../views/form';
import Responsive from '../../../components/responsive';
import * as typeDef from '../../../utils/strong-types';
import * as coreJobCat from '../../../lib/context/job-cat';
import * as alert from '../../../utils/components/alert/provider';

const BREAKPOINT_SMALL = 1024;

const FormInterest = (props) => {
  const {onSubmit} = props;
  const {formatMessage} = useIntl();

  const {formInterestState, dispatchUpdatePreviousStep, dispatchUpdateFormInterest} = useRegister();
  const [jobCatsData] = useState(coreJobCat.createJobCatData());
  const [selectedJobCats, setSelectedJobCats] = useState([]);

  useEffect(() => {
    if (jobCatsData && Array.isArray(formInterestState) && formInterestState.length) {
      setSelectedJobCats(formInterestState);
    }
  }, [jobCatsData, formInterestState]);

  const onChangeSelectJobCategory = (parentItem, childItem) => {
    const mapSelected = coreJobCat.mapSelectedByTypes({
      selectedOptions: selectedJobCats,
      newItems: {
        parentItem,
        childItem,
      },
    });
    if (mapSelected.length > coreJobCat.LIMIT_JOB_CAT) {
      return;
    }
    setSelectedJobCats(mapSelected);
  };

  const onClickedChip = (id) => {
    const newItems = coreJobCat.filterOutById({id, selectedOptions: selectedJobCats});
    setSelectedJobCats([...newItems]);
  };

  const shapeTextJobCatFunc = (text, item) => {
    if (item.parentId) {
      return text;
    }
    const shapeText = coreJobCat.shapeTextParentCategory({text});
    return shapeText;
  };

  const handlerOnSubmit = () => {
    if (typeDef.isNotArrayLength(selectedJobCats)) {
      alert.setAlert(formatMessage({defaultMessage: 'กรุณาเลือกอย่างน้อย 1 ประเภท'}));
      return;
    }
    onSubmit(selectedJobCats);
  };

  const onClickPrevious = () => {
    dispatchUpdateFormInterest(selectedJobCats, true);
    dispatchUpdatePreviousStep();
  };

  return (
    <FormWrapper position="start">
      <HeaderWrapper>
        <Header variant="h2">{translate('register.stepInterest')}</Header>
      </HeaderWrapper>
      <Form formId="form-register-interest" style={OverrideStyles.form} onSubmit={handlerOnSubmit}>
        <Select
          name="jobCat"
          sandboxColor="gray"
          label={translate('landing.search.jobCategory')}
          placeholder={translate('landing.search.select')}
          limitSelect={10}
          options={jobCatsData}
          selectedOptions={selectedJobCats}
          shapeTextFunc={shapeTextJobCatFunc}
          onClickChip={onClickedChip}
          onChange={onChangeSelectJobCategory}
          onClear={() => setSelectedJobCats([])}
        />
        <ButtonLargeWrapper row>
          <Button type="button" edgeInsets="none" variant="light" style={OverrideStyles.button} onClick={onClickPrevious}>
            {translate('register.buttonPrevious')}
          </Button>
          <Button block type="submit" style={{...OverrideStyles.button, ...OverrideStyles.buttonLeftSpace}}>
            {`${translate('register.buttonSubmit')}`}
          </Button>
        </ButtonLargeWrapper>
      </Form>
      <Responsive maxWidth={BREAKPOINT_SMALL}>
        <ButtonSmallWrapper row>
          <Button type="button" edgeInsets="none" variant="light" onClick={onClickPrevious}>
            {translate('register.buttonPrevious')}
          </Button>
          <Button block type="submit" style={OverrideStyles.buttonLeftSpace} onClick={handlerOnSubmit}>
            {`${translate('register.buttonSubmit')}`}
          </Button>
        </ButtonSmallWrapper>
      </Responsive>
    </FormWrapper>
  );
};

FormInterest.propTypes = {
  onSubmit: PropTypes.func,
};

FormInterest.defaultProps = {
  onSubmit: () => {},
};

export default FormInterest;
