import lodash from 'lodash';

export const getPolicyVersion = (userPolicyState) => {
  if (!Array.isArray(userPolicyState)) {
    return 1;
  }
  const orderedVersion = lodash.orderBy(userPolicyState, 'version', 'desc');
  const version = lodash.get(orderedVersion, '[0].version', 1);
  return version;
};
