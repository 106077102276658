import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Container, ChildWrapper} from './styled-component';
import {useClickOutsideEffect} from '../../lib/hooks/useClickOutSideEffect';
import * as generate from '../../utils/generate';

const Modal = (props) => {
  const {id, onRequestClose, visible, children, zIndex} = props;
  const childRef = useRef();
  const [idFor] = useState(id || generate.generateIdString('modal'));

  const onCallbackRequestClose = () => {
    if (!onRequestClose) {
      return;
    }
    onRequestClose();
  };

  useClickOutsideEffect(childRef, onCallbackRequestClose);

  if (!visible) {
    return null;
  }

  return (
    <Container id={idFor} zIndex={zIndex}>
      <ChildWrapper ref={childRef}>{children}</ChildWrapper>
    </Container>
  );
};

Modal.propTypes = {
  id: PropTypes.string,
  onRequestClose: PropTypes.func,
  visible: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  zIndex: PropTypes.number,
};
Modal.defaultProps = {
  id: null,
  onRequestClose: null,
  visible: true,
  zIndex: null,
};

export default Modal;
