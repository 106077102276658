import {auth} from './index';

export const loginFirebase = async (token) => {
  try {
    return await auth.signInWithCustomToken(token);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;

    return {errorCode, errorMessage};
  }
};

export const logoutFirebase = async () => {
  try {
    return await auth.signOut();
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;

    return {errorCode, errorMessage};
  }
};
