import styled from 'styled-components';
import BoxShadow from '../../../styles/box';

export const Container = styled.div`
  width: 100%;
  max-width: 680px;
  padding: 1rem;
  background-color: ${(props) => props.theme.white};
  @media (min-width: 681px) {
    border-radius: 6px;
    box-shadow: ${BoxShadow.content};
  }
`;

export const RowButton = styled.div`
  display: flex;
  padding: 1rem 0;
`;

export const OverrideStyles = {
  buttonSpaceLeft: {
    marginLeft: '1rem',
  },
};
