/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {useImageViewer} from '../../../../lib/hooks/logic/useImageViewer';
import {
  ColumnWrapper,
  RowWrapper,
  ImageBackdrop,
  ImageBackdropVerticalParent1,
  ImageBackdropVertical1,
  ImageBackdropVertical2,
  ImageBackdropVertical3,
  ImageSingle,
  ImageVertical3,
  ImageVertical1,
  ImageVertical2,
  ImageMore,
  MoreNumberText,
  MorePlusText,
} from './styled-component';
import * as TYPES from '../../../../lib/context/images/img-content-type';

const MAX_WIDTH = 680;

const ImageVertical = (props) => {
  const {images, type} = props;
  const {dispatchViewImage} = useImageViewer();

  const onEventClickImage = (snapToIndex) => {
    const data = {
      data: images,
      snapToIndex,
    };
    dispatchViewImage(data);
  };

  if (!Array.isArray(images) || !images.length) {
    return null;
  }

  if (type === TYPES.CONTENT_VERTICAL_1X1) {
    return (
      <ColumnWrapper>
        <RowWrapper>
          {images.map((item, index) => {
            const {url, height} = item;
            const key = index.toString();
            return (
              <ImageBackdropVertical1 key={key}>
                <ImageVertical1 src={url} height={height} onClick={() => onEventClickImage(index)} />
              </ImageBackdropVertical1>
            );
          })}
        </RowWrapper>
      </ColumnWrapper>
    );
  }

  if (type === TYPES.CONTENT_VERTICAL_1X2) {
    const {url: firstImg, width: firstWidth} = images[0];

    const isFirstFluid = firstWidth >= MAX_WIDTH;
    return (
      <ColumnWrapper>
        <ImageBackdrop>
          <ImageSingle src={firstImg} width={firstWidth} fluid={isFirstFluid} onClick={() => onEventClickImage(0)} />
        </ImageBackdrop>
        <RowWrapper>
          {images.map((item, index) => {
            if (index === 0) {
              return null;
            }
            const {url, height} = item;
            const key = index.toString();
            const isNoInsets = index > 0;
            return (
              <ImageBackdropVertical2 key={key} noEdgeInsets={isNoInsets}>
                <ImageVertical2 isChild src={url} height={height} onClick={() => onEventClickImage(index)} />
              </ImageBackdropVertical2>
            );
          })}
        </RowWrapper>
      </ColumnWrapper>
    );
  }

  if (type === TYPES.CONTENT_VERTICAL_1X3 || type === TYPES.CONTENT_VERTICAL_1XN) {
    const {url: firstImg, width: firstWidth} = images[0];
    const isFirstFluid = firstWidth >= MAX_WIDTH;
    const isMoreImage = type === TYPES.CONTENT_VERTICAL_1XN;

    return (
      <ColumnWrapper>
        <ImageBackdropVerticalParent1>
          <ImageSingle src={firstImg} width={firstWidth} fluid={isFirstFluid} onClick={() => onEventClickImage(0)} />
        </ImageBackdropVerticalParent1>
        <RowWrapper>
          {images.map((item, index) => {
            if (index === 0 || index > 3) {
              return null;
            }
            const {url, width} = item;
            const key = index.toString();
            const isFluid = width >= MAX_WIDTH;
            const isNoInsets = index > 0;
            return (
              <ImageBackdropVertical3 key={key} noEdgeInsets={isNoInsets}>
                <ImageVertical3 cover src={url} width={width} fluid={isFluid} onClick={() => onEventClickImage(index)} />
                {index === 3 && isMoreImage && (
                  <ImageMore onClick={() => onEventClickImage(3)}>
                    <MorePlusText>+</MorePlusText>
                    <MoreNumberText>{`${images.length - 3}`}</MoreNumberText>
                  </ImageMore>
                )}
              </ImageBackdropVertical3>
            );
          })}
        </RowWrapper>
      </ColumnWrapper>
    );
  }

  return (
    <ColumnWrapper>
      {images.map((item, index) => {
        const {url, width} = item;
        const isFluid = width >= MAX_WIDTH;
        return (
          <ImageBackdrop key={index.toString()} noEdgeInsets={index > 0}>
            <ImageSingle src={url} width={width} fluid={isFluid} onClick={() => onEventClickImage(index)} />
          </ImageBackdrop>
        );
      })}
    </ColumnWrapper>
  );
};

ImageVertical.propTypes = {
  type: PropTypes.string,
  images: PropTypes.array,
};

ImageVertical.defaultProps = {
  type: null,
  images: [],
};

export default ImageVertical;
