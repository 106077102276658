import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import * as trackActions from '../../redux/track-event/action';

export const useTrackJobInteractState = () => {
  const {
    error: errorJobInteractEvent,
    stackEvent: stackJobInteractEvent,
    streamEvent: streamJobInteractEvent,
  } = useSelector((state) => state.trackEvent.jobInteract, shallowEqual);
  return {
    errorJobInteractEvent,
    stackJobInteractEvent,
    streamJobInteractEvent,
  };
};

export const useTrackJobInteractDispatch = () => {
  const dispatch = useDispatch();

  const dispatchJobInteractUpdateStack = (data) => {
    if (!data) {
      return;
    }
    dispatch(trackActions.trackInteractJobUpdateStack(data));
  };

  const dispatchJobInteractStreamStack = (data) => {
    if (!data) {
      return;
    }
    dispatch(trackActions.trackInteractJobStreamStack({data: {jobs: data}}));
  };

  return {dispatchJobInteractUpdateStack, dispatchJobInteractStreamStack};
};

export const interactTypes = {
  like: 'like',
  applyJob: 'apply',
  contact: 'contact',
  share: 'share',
  seeMore: 'see_more',
};
