import * as TYPES from './types';

const initReportJob = {
  error: null,
  loading: false,
  result: null,
};

export const reportJobReducer = (state = initReportJob, {type, payload}) => {
  switch (type) {
    case TYPES.REPORT_JOB_CREATE_NEW_REPORT_REQ:
      return {...state, error: null, loading: true};
    case TYPES.REPORT_JOB_CREATE_NEW_REPORT_SUCCESS:
      return {...state, loading: false, result: payload};
    case TYPES.REPORT_JOB_CREATE_NEW_REPORT_FAIL:
      return {...state, error: payload, loading: false};
    default:
      return {...state};
  }
};
