import get from 'lodash/get';
import {regexEmail, regexPassword, regexName} from '../../../utils/regex';
import {translate} from '../../../res/language/translate';
import * as initForm from './init-form';

const validatePassword = ({password}) => {
  if (!password) {
    return translate('register.errorPassword.undefined');
  }
  if (!regexPassword({input: password})) {
    return translate('register.errorPassword.regex');
  }
  return '';
};

const validateConfirmPassword = ({password, confPassword}) => {
  if (!confPassword) {
    return translate('register.errorConfPassword.undefined');
  }
  if (password !== confPassword) {
    return translate('register.errorConfPassword.regex');
  }
  return '';
};

const validateEmail = ({email}) => {
  if (!email) {
    return translate('register.errorEmail.undefined');
  }
  if (!regexEmail({input: email})) {
    return translate('register.errorEmail.regex');
  }
  return '';
};

const validateFirstName = ({firstName}) => {
  if (!firstName) {
    return translate('register.errorFirstName.undefined');
  }
  if (!regexName({input: firstName})) {
    return translate('register.errorFirstName.regex');
  }
  return '';
};

const validateLastName = ({lastName}) => {
  if (!lastName) {
    return translate('register.errorLastName.undefined');
  }
  if (!regexName({input: lastName})) {
    return translate('register.errorLastName.regex');
  }
  return '';
};

const validateLocation = (location) => {
  if (!get(location, '[0].value')) {
    return 'กรุณาเลือกจังหวัดที่อยู่ปัจจุบัน';
  }
  return '';
};

const validateDate = (date, month) => {
  if (!date) {
    return 'เลือกวัน';
  }
  if (month === 2 && date > 29) {
    return 'วันไม่ถูกต้อง';
  }
  return '';
};

const validateMonth = (month) => {
  if (!month) {
    return 'เลือกเดือน';
  }
  return '';
};

const validateYear = (year) => {
  if (!year) {
    return 'เลือกปี';
  }
  return '';
};

const validateGender = (gender) => {
  if (!gender) {
    return 'เลือกเพศ';
  }
  return '';
};

export const validateGeneralForm = (form) => {
  const {email, password, confPassword} = form;
  const validateResult = {};
  validateResult.passwordResult = validatePassword({password});
  validateResult.confPasswordResult = validateConfirmPassword({
    password,
    confPassword,
  });
  validateResult.emailResult = validateEmail({email});
  return validateResult;
};

export const validateInfoForm = (form) => {
  const firstName = validateFirstName({firstName: form.firstName});
  const lastName = validateLastName({lastName: form.lastName});
  const email = validateEmail({email: form.email});
  const location = validateLocation(form.location);
  const birthDate = validateDate(form.birthDate?.value, form.birthMonth?.value);
  const birthMonth = validateMonth(form.birthMonth?.value);
  const birthYear = validateYear(form.birthYear?.value);
  const sex = validateGender(form.sex?.value);

  const isValidate = !firstName && !lastName && !birthDate && !email && !location && !birthDate && !birthMonth && !birthYear && !sex;
  return [
    isValidate,
    {
      ...initForm.initFormInfo,
      firstName,
      lastName,
      email,
      location,
      birthDate,
      birthMonth,
      birthYear,
      sex,
    },
  ];
};
