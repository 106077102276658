import {useSelector, useDispatch} from 'react-redux';
import * as likesActions from '../../../redux/like/action';

export const useLikes = () => {
  const dispatch = useDispatch();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const keyLikesState = useSelector((state) => state.likes.likes.keyedLikes);

  const checkJobIsLiked = (id) => {
    if (!keyLikesState) {
      return false;
    }
    return !!keyLikesState[id];
  };

  const dispatchLike = (props) => {
    const {id, path} = props;
    if (!id) {
      return;
    }
    const reqBody = {
      job_id: id,
    };
    dispatch(
      likesActions.likeNewLike({
        data: reqBody,
        path,
        isLoggedOut: !isLoggedInState?.user,
      }),
    );
  };

  const dispatchUnLike = (props) => {
    const {id, path} = props;
    if (!id) {
      return;
    }
    const reqBodyUnlike = {
      like_id: keyLikesState[id]._id,
      job_id: id,
    };
    if (path === 'like') {
      dispatch(
        likesActions.likeUnlike({
          data: reqBodyUnlike,
          path,
          isLoggedOut: !isLoggedInState?.user,
        }),
      );
      return;
    }
    if (keyLikesState[id]) {
      dispatch(
        likesActions.likeUnlike({
          data: reqBodyUnlike,
          path,
          isLoggedOut: !isLoggedInState?.user,
        }),
      );
    }
  };

  return {
    checkJobIsLiked,
    dispatchLike,
    dispatchUnLike,
  };
};
