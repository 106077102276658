import styled from 'styled-components';
import NavigationTheme from '../../../styles/navigation';

export const Container = styled.header`
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  background-color: ${(props) => props.theme.white};
  ${(props) => !props.noUnderLine && `border-bottom: 3px solid ${props.theme.primary}`};
  ${(props) => props.noUnderLine && `box-shadow: ${NavigationTheme.NavigationHeadShadow.boxShadow}`};
  @media (max-width: 1024px) {
    ${(props) => props.isSearchId && `display: none`};
  }
`;
export const ContainerHidden = styled.div`
  height: 59px;
  width: 100%;
  background-color: transparent;
  @media (max-width: 1024px) {
    ${(props) => props.isSearchId && `display: none;`};
  }
  @media (min-width: 1025px) {
    ${(props) => props.isSearchId && `height: 0;`};
  }
  @media (width: 1440px) {
    ${(props) => props.isNoTopEdgeInsets && `height: 0.75rem;`};
  }
`;

export const NavContainer = styled.nav`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  height: ${NavigationTheme.NavigationHeader.height}px;
  @media (max-width: 936px) {
    padding: 0 1rem;
  }
`;

export const NavWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 936px) {
    position: fixed;
    left: -100%;
    top: 5rem;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }
`;

export const NavNoneWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavItem = styled.li`
  list-style: none;
  margin-left: 2rem;
  @media (max-width: 936px) {
    ${(props) => !props.noSpacer && ` margin: 0.5rem 1.5rem`};
  }
`;

export const NavItemChild = styled.span`
  text-decoration: none;
  color: ${(props) => props.theme.text};
  font-weight: bold;
  :hover {
    color: ${(props) => props.theme.primary};
  }
`;

export const NavHamburger = styled.div`
  display: none;
  @media (max-width: 936px) {
    display: flex;
    flex-wrap: nowrap;
  }
`;

export const NavIconWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.gray20};
  cursor: pointer;
`;

export const NavButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 936px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 0 1rem;
  }
`;

export const NavLine = styled.div`
  width: 1px;
  height: 26px;
  background-color: ${(props) => props.theme.gray};
  margin: 0 0.75rem;
  @media (max-width: 936px) {
    width: 100%;
    height: 1px;
    margin: 0.75rem 0;
  }
`;

export const NavMobileWrapper = styled.div`
  z-index: 1;
  background-color: ${(props) => props.theme.white};
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  padding: 1rem;
  width: 70%;
  transform: ${(props) => (props.open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  @media (min-width: 937px) {
    display: none;
  }
`;

export const NavCloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const NavSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 17.5px;
  padding: 0 1rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.tinGray};
  @media (max-width: 875px) {
    height: 35px;
    width: 35px;
    border-radius: 17.5px;
    padding: 0;
    align-items: center;
    justify-content: center;
  } ;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AbsoluteHeader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const OverrideStyles = {
  leftArrow: {
    marginLeft: '-10px',
    width: '77px',
  },
  leftArrowLarge: {
    marginLeft: '-10px',
  },
  textSearch: {
    paddingLeft: '0.5rem',
  },
  appLogo: {
    marginLeft: '0.5rem',
  },
  headerResultCount: {
    marginLeft: '0.5rem',
  },
  btnSignUpSmall: {
    marginRight: '1rem',
  },
  btnSignUpSmall2: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
};
