import React from 'react';
import Environment from '../../env.json';

export const envServicesRef = React.createRef();

export const createServiceURL = () => {
  const ENV = Environment;

  if (envServicesRef.current === 'APPLE_STORE') {
    return ENV.APPLE_STORE.API_URL;
  }
  return ENV.PUBLIC.API_URL;
};

export const envFacebook = () => {
  const ENV = Environment;

  if (envServicesRef.current === 'APPLE_STORE') {
    return ENV.APPLE_STORE.FACEBOOK;
  }
  return ENV.PUBLIC.FACEBOOK;
};
