import * as typeDef from '../../../../utils/strong-types';
import * as dateTime from '../../../../utils/date-time';

export const validateAllRow = (listState) => {
  if (typeDef.isNotArrayLength(listState)) {
    return [true, null];
  }
  let isValidate = true;
  const errorList = listState.map((elem) => {
    const error = {};
    if (!elem.company_name) {
      isValidate = false;
      error.company_name = 'กรุณากรอกชื่อบริษัท';
    }
    if (!elem.position) {
      isValidate = false;
      error.position = 'กรุณากรอกตำแหน่งงาน';
    }
    if (!elem.start) {
      isValidate = false;
      error.start = 'วันที่เริ่มงาน';
    }
    if (!elem.is_now && !elem.end) {
      isValidate = false;
      error.end = 'วันที่สิ้นสุดงาน';
    }
    if (!elem.job_description) {
      isValidate = false;
      error.job_description = 'รายละเอียด ลักษณะงานที่รับผิดชอบ';
    }
    if (!elem.is_now && elem.start && elem.end && !dateTime.isAfterDate({dateStart: elem.start, dateEnd: elem.end})) {
      isValidate = false;
      error.end = 'วันที่สิ้นสุดงานต้องระบุหลังวันที่เริ่มงาน';
    }
    return error;
  });
  return [isValidate, errorList];
};
