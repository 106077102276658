export const countUserGeneral = (user) => {
  const checkList = {
    firstname: user.general?.firstname ? 1 : 0,
    lastname: user.general?.lastname ? 1 : 0,
    sex: user.general?.sex ? 1 : 0,
    birthday: user.general?.birthday ? 1 : 0,
  };
  let counter = 0;
  if (checkList.firstname) {
    counter++;
  }
  if (checkList.lastname) {
    counter++;
  }
  if (checkList.sex) {
    counter++;
  }
  if (checkList.birthday) {
    counter++;
  }
  return [checkList, counter];
};

export const countUserField = (user) => {
  if (!user)
    return {
      general: {
        list: {},
        count: 0,
      },
      canApplyJob: false,
    };
  const [requireList, countField] = countUserGeneral(user);

  return {
    general: {
      list: requireList,
      count: countField,
    },
    canApplyJob: countField === 4,
  };
};
