import React, {useRef, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import get from 'lodash/get';
import {useHistory, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useCallbackPathDispatch} from '../../lib/hooks-logic/useCallbackPath';
import {useTrackJobInteractDispatch} from '../../lib/hooks-logic/useTrackEvent';
import {translate} from '../../res/language/translate';
import {useLikes} from '../../lib/hooks/logic/useLikes';
import {Container, Content, BottomHidden} from './styled-component';
import {useUserState} from '../../lib/hooks/logic/useUser';
import {countUserField} from './utils/logic';
import JobPostItem from '../job-post-item';
import Skeleton from '../skeleton';
import Header from '../../components/header';
import Button from '../../components/button';
import SnapshotError from '../error-boundary/snapshot';
import ModalContact from '../job-post-item/components/interact/contact/modal-contact';
import ModalJobApply from '../apply-job-modal';
import CantApplyModal from './utils/modal';
import ModalReportPost from './components/modal-report-post/index';
import * as bodyParser from './utils/body-parser';
import * as dateTime from '../../utils/date-time';
import * as typeDef from '../../utils/strong-types';
import * as mixpanel from '../../utils/mixpanel';
import * as alertActions from '../../redux/alert/action';
import * as ROUTES from '../../routes/routes-name';
import * as navigation from '../../utils/navigation/navigation-services';

const JobPostBuilder = (props) => {
  const {
    data,
    isMeta,
    path,
    isLoadingFeed,
    isEmptyData,
    emptyLabel,
    isVisibleFindNearby,
    onEventFindNearby,
    loadingCount,
    noTopEdgeInsets,
    noBottomEdgeInsets,
    likeLogic,
    isCompany,
  } = props;
  const {checkJobIsLiked, dispatchLike, dispatchUnLike} = likeLogic || useLikes();
  const nodeBottomHiddenRef = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const history = useHistory();
  const location = useLocation();
  const {dispatchJobInteractUpdateStack} = useTrackJobInteractDispatch();
  const {dispatchAddCallbackPath} = useCallbackPathDispatch();

  useEffect(() => {
    if (!Array.isArray(data) && isLoadingFeed) {
      setIsLoadingMore(false);
    }
    if (Array.isArray(data) && data.length && isLoadingFeed) {
      setIsLoadingMore(true);
    }
    if (Array.isArray(data) && data.length && !isLoadingFeed) {
      setIsLoadingMore(false);
    }
  }, [data, isLoadingFeed]);

  useEffect(() => {
    if (isLoadingMore) {
      nodeBottomHiddenRef.current.scrollIntoView();
    }
  }, [isLoadingMore]);

  const [cantApplyData, setCantApplyData] = useState({visible: false});
  const onCantApplyClose = () => setCantApplyData({visible: false});

  // start apply job data
  const [applyJobData, setApplyJobData] = useState({visible: false});
  const {userState} = useUserState();

  const onApplyJobClose = () => setApplyJobData({visible: false});

  const onForceLogin = (jobId) => {
    const rdPath = {
      pathname: ROUTES.ROUTE_SEARCH_BY_ID,
      search: {id: jobId},
    };
    const alertData = {
      message: formatMessage({defaultMessage: 'กรุณาเข้าสู่ระบบก่อนสมัครงาน'}),
      positiveTitle: formatMessage({defaultMessage: 'สมัครสมาชิก'}),
      negativeTitle: formatMessage({defaultMessage: 'ปิด'}),
      action: {
        positiveAction: () => {
          dispatchAddCallbackPath(rdPath);
          navigation.navigateTo({pathname: ROUTES.ROUTE_REGISTER, search: {rd_path: true}});
          dispatch(alertActions.alertRemoveAlert());
        },
        negativeAction: () => dispatch(alertActions.alertRemoveAlert()),
        closeAction: () => dispatch(alertActions.alertRemoveAlert()),
      },
    };
    dispatch(alertActions.alertSetAlert({data: alertData}));
  };

  const onEventClickApplyJob = (id, email, title, isActivated, srcRemoved, postUrl, companyName, jobData) => {
    if (!userState) {
      onForceLogin(id);
      return;
    }

    const countField = countUserField(userState);

    if (srcRemoved || !isActivated) {
      const alertData = {
        message: [
          formatMessage({defaultMessage: 'ประกาศรับสมัครงานปิดไปแล้ว'}),
          formatMessage({defaultMessage: 'แต่ช่องทางการติดต่อ HR ยังอาจใช้ได้อยู่'}),
          formatMessage({defaultMessage: 'ต้องการดำเนินการต่อหรือไม่?'}),
        ].join('\n'),
        positiveTitle: formatMessage({defaultMessage: 'ดำเนินการต่อ'}),
        negativeTitle: formatMessage({defaultMessage: 'ปิด'}),
        action: {
          positiveAction: () => {
            dispatch(alertActions.alertRemoveAlert());
            if (countField.canApplyJob) setApplyJobData({visible: true, id, email, title, postUrl});
            else setCantApplyData({...countField, visible: true});
          },
          negativeAction: () => dispatch(alertActions.alertRemoveAlert()),
          closeAction: () => dispatch(alertActions.alertRemoveAlert()),
        },
      };
      dispatch(alertActions.alertSetAlert({data: alertData}));
      mixpanel.jobApplyFailInfo(jobData, 'closed');
    } else if (countField.canApplyJob) {
      mixpanel.jobApplyModal(jobData);
      if (typeDef.isNotArrayLength(email) && postUrl) {
        navigation.windowOpen({pathname: postUrl});
        return;
      }
      const newCompanyName = companyName ? companyName[0] : '';
      setApplyJobData({visible: true, id, email, title, companyName: newCompanyName, postUrl});
    } else {
      setCantApplyData({...countField, visible: true});

      mixpanel.jobApplyFailInfo(jobData, 'info required');
    }
  };

  const onEventClickLike = (id) => {
    if (!checkJobIsLiked(id)) {
      dispatchLike({id, path});
      return;
    }
    dispatchUnLike({id, path});
  };

  const onEventSetHotJarFragment = (isVisible) => {
    if (isVisible) {
      history.replace({pathname: location.pathname, search: location.search, state: location.state, hash: '#ev_apply_job'});
      return;
    }
    history.replace({pathname: location.pathname, search: location.search, state: location.state, hash: ''});
  };

  const onEventTrackingEventInteract = (type, id) => {
    if (!type || !id || !userState) {
      return;
    }
    dispatchJobInteractUpdateStack({job_id: id, type});
  };

  useEffect(() => {
    onEventSetHotJarFragment(applyJobData?.visible);
  }, [applyJobData]);

  if (isEmptyData) {
    if (typeDef.isString(emptyLabel) || !emptyLabel) {
      return (
        <Container noTopEdgeInsets={noTopEdgeInsets} noBottomEdgeInsets={noBottomEdgeInsets}>
          <Content>
            <Header variant="h4">{emptyLabel || translate('emptyData.defaultSearch')}</Header>
            {isVisibleFindNearby && (
              <>
                <SnapshotError />
                <Button onClick={onEventFindNearby}>{translate('search.searchNearby')}</Button>
              </>
            )}
          </Content>
        </Container>
      );
    }
    return <Container>{emptyLabel}</Container>;
  }

  if (!Array.isArray(data) && isLoadingFeed) {
    const countArray = [...Array(loadingCount).keys()];
    return (
      <Container noTopEdgeInsets={noTopEdgeInsets} noBottomEdgeInsets={noBottomEdgeInsets}>
        {countArray.map((item, index) => (
          <Skeleton key={index.toString()} data={item} />
        ))}
      </Container>
    );
  }

  return (
    <Container noTopEdgeInsets={noTopEdgeInsets} noBottomEdgeInsets={noBottomEdgeInsets}>
      <ModalContact />
      <ModalReportPost />
      {applyJobData.visible && <ModalJobApply {...applyJobData} onRequestClose={onApplyJobClose} />}
      {cantApplyData.visible && <CantApplyModal {...cantApplyData} onRequestClose={onCantApplyClose} />}
      {Array.isArray(data) &&
        data.map((item) => (
          <JobPostItem
            key={item._id}
            isCompany={isCompany}
            id={item._id}
            authorId={item.author_id}
            isMeta={isMeta}
            avatarSrc={item.author_avatar?.url}
            authorName={bodyParser.getValueOfFirst(item.company_name) || item.author_name}
            postDate={
              isMeta
                ? translate('post.announcement')
                : dateTime.postDateFormat({
                    date: item.post_date,
                  })
            }
            location={
              bodyParser.replacePrefixLocation({
                location: bodyParser.getValueOfFirst(item.district),
              }) ||
              bodyParser.getValueOfFirst(item.province) ||
              'ไม่ระบุตำแหน่ง'
            }
            locations={get(item.district, '[0]') ? item.district : item.province}
            jobTitle={bodyParser.getValueOfFirst(item.job_title)}
            salary={bodyParser.getSalary({
              min: item.salary_min,
              max: item.salary_max,
            })}
            postText={item.post_text}
            postImages={item.post_image}
            hashtags={item.hashtags}
            likeCounter={item.like_count || 0}
            seenCounter={item.seen_count || 0}
            interestedCounter={item.interact_count || 0}
            isLiked={'jobLike' in item ? item.jobLike : checkJobIsLiked(item._id)}
            onClickLike={onEventClickLike}
            onClickApplyJob={onEventClickApplyJob}
            srcRemoved={item.source_removed}
            isActivated={item.is_activated}
            lineIds={item.line_id}
            lineText={item.line_text}
            emails={item.email}
            emailText={item.email_text}
            tels={item.tel_no}
            telText={item.tel_text}
            jobApply={item.jobApply}
            authorUrl={item.post_url}
            authorSrc={item.src}
            isCompanyVerified={item.is_company_verified}
            jobData={item}
            companyName={item.company_name}
            isSalaryHidden={item.is_salary_hidden || false}
            postSections={item.post_sections}
            onEventTracking={(type) => onEventTrackingEventInteract(type, item._id)}
          />
        ))}
      {isLoadingFeed && <Skeleton />}
      <BottomHidden ref={nodeBottomHiddenRef} />
    </Container>
  );
};

JobPostBuilder.propTypes = {
  data: PropTypes.array,
  isMeta: PropTypes.bool,
  path: PropTypes.string,
  isLoadingFeed: PropTypes.bool,
  isEmptyData: PropTypes.bool,
  emptyLabel: PropTypes.any,
  isVisibleFindNearby: PropTypes.bool,
  loadingCount: PropTypes.number,
  onEventFindNearby: PropTypes.func,
  noTopEdgeInsets: PropTypes.bool,
  noBottomEdgeInsets: PropTypes.bool,
  likeLogic: PropTypes.object,
  isCompany: PropTypes.bool,
};

JobPostBuilder.defaultProps = {
  data: null,
  isMeta: false,
  path: '',
  isLoadingFeed: false,
  isEmptyData: false,
  emptyLabel: null,
  isVisibleFindNearby: false,
  loadingCount: 2,
  onEventFindNearby: () => {},
  noTopEdgeInsets: false,
  noBottomEdgeInsets: false,
  likeLogic: null,
  isCompany: false,
};

export default JobPostBuilder;
