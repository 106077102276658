import keyBy from 'lodash/keyBy';
import * as typeDef from '../../../../utils/strong-types';
import * as generate from '../../../../utils/generate';
import * as initData from './init-data';

const generateProvince = (province) => {
  if (!province) {
    return '';
  }
  const response = {
    id: generate.generateId('PV'),
    label: province,
    value: province,
  };
  return [response];
};

const generateDistrict = (districtState) => {
  if (!districtState) {
    return [];
  }
  const label = districtState.split('|');
  const response = {
    id: generate.generateId('DT'),
    label: label[1] || label[0],
    value: districtState,
  };
  return [response];
};

export const mapWork = (listData) => {
  if (typeDef.isNotArrayLength(listData)) {
    return [];
  }
  const keyLevel = keyBy(initData.jobLevel, 'value');
  const keySize = keyBy(initData.companySizeData, 'value');

  const response = listData.map((elem) => {
    return {
      ...elem,
      company_size: keySize[elem.company_size] || null,
      salary: `${elem.salary}`,
      job_level: keyLevel[elem.job_level] || null,
      province: generateProvince(elem.province),
      district: generateDistrict(elem.district),
    };
  });
  return response;
};

export const transformWorks = (listData) => {
  if (typeDef.isNotArrayLength(listData)) {
    return [];
  }
  const response = listData.map((elem) => ({
    ...elem,
    company_size: elem.company_size?.value,
    district: elem.district[0]?.value,
    job_level: elem.job_level?.value,
    province: elem.province[0]?.value,
    salary: parseInt(elem.salary, 10),
  }));
  return response;
};
