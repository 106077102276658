import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  ${(props) => props.noTopEdgeInsets && `margin-top: 0`};
  ${(props) => props.noBottomEdgeInsets && `margin-bottom: 0`};
  @media (max-width: 680px) {
    margin: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 680px;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${(props) => props.theme.white};
  align-items: center;
  @media (max-width: 680px) {
    border-radius: 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 0.5rem`};
  }
  @media (min-width: 681px) {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 1rem`};
  }
`;

export const BottomHidden = styled.div``;

export const ModalContainer = styled.div`
  position: relative;
  background: white;
  min-height: 220px;
  min-width: 400px;
  border-radius: 6px;
  padding: 1.5rem;
`;

export const IconContainer = styled.div.attrs((props) => props)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  @media (max-width: 1024px) {
    right: 25px;
    top: 10px;
  }
`;

export const H3 = styled.h3`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const RightText = styled.div`
  height: 30px;
  margin-top: -42px;
  margin-right: 12px;
  float: right;
`;

export const SectionInfo = styled.div`
  width: 100%;
  padding: 1rem 0.5rem;
  border-radius: 6px;
  background-color: ${(props) => props.theme.grayBackground};
  margin-bottom: 1rem;
`;

export const RowInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const ModalContainer2 = styled.div`
  position: relative;
  background: white;
  min-height: 220px;
  border-radius: 6px;
  padding: 1.5rem;
  max-height: calc(100vh);
  overflow: auto;
  @media (min-width: 520px) {
    min-width: 500px;
  }
`;
