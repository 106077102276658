export const APPLY_JOB_CHECK_INFO_REQ = '@APPLY_JOB/CHECK_INFO_REQ';
export const APPLY_JOB_CHECK_INFO_SUCCESS = '@APPLY_JOB/CHECK_INFO_SUCCESS';
export const APPLY_JOB_CHECK_INFO_FAIL = '@APPLY_JOB/CHECK_INFO_FAIL';
export const APPLY_JOB_CHECK_INFO_CLEAR = '@APPLY_JOB/CHECK_INFO_CLEAR';
export const APPLY_JOB_CHECK_INFO_IS_LOGGED_OUT = '@APPLY_JOB/CHECK_INFO_IS_LOGGED_OUT';

export const APPLY_JOB_GET_DRAFT_REQ = '@APPLY_JOB/GET_DRAFT_REQ';
export const APPLY_JOB_GET_DRAFT_SUCCESS = '@APPLY_JOB/GET_DRAFT_SUCCESS';
export const APPLY_JOB_GET_DRAFT_FAIL = '@APPLY_JOB/GET_DRAFT_FAIL';
export const APPLY_JOB_GET_DRAFT_CLEAR = '@APPLY_JOB/GET_DRAFT_CLEAR';

export const APPLY_JOB_UPDATE_DRAFT_REQ = '@APPLY_JOB/UPDATE_DRAFT_REQ';
export const APPLY_JOB_UPDATE_DRAFT_SUCCESS = '@APPLY_JOB/UPDATE_DRAFT_SUCCESS';
export const APPLY_JOB_UPDATE_DRAFT_FAIL = '@APPLY_JOB/UPDATE_DRAFT_FAIL';

export const APPLY_JOB_REQUEST_MODAL_REQ = '@APPLY_JOB/REQUEST_MODAL_REQ';
export const APPLY_JOB_REQUEST_MODAL_SUCCESS = '@APPLY_JOB/REQUEST_MODAL_SUCCESS';
export const APPLY_JOB_REQUEST_MODAL_CLEAR = '@APPLY_JOB/REQUEST_MODAL_CLEAR';
export const APPLY_JOB_REQUEST_MODAL_UPDATE_CONFIG = '@APPLY_JOB/REQUEST_MODAL_UPDATE_CONFIG';

export const APPLY_JOB_RESUME_GET_TEMPLATE_REQ = '@APPLY_JOB/RESUME_GET_TEMPLATE_REQ';
export const APPLY_JOB_RESUME_GET_TEMPLATE_SUCCESS = '@APPLY_JOB/RESUME_GET_TEMPLATE_SUCCESS';
export const APPLY_JOB_RESUME_GET_TEMPLATE_FAIL = '@APPLY_JOB/RESUME_GET_TEMPLATE_FAIL';
export const APPLY_JOB_RESUME_GET_TEMPLATE_CLEAR = '@APPLY_JOB/RESUME_GET_TEMPLATE_CLEAR';

export const APPLY_JOB_RESUME_GENERATE_TEMPLATE_REQ = '@APPLY_JOB/RESUME_GENERATE_TEMPLATE_REQ';
export const APPLY_JOB_RESUME_GENERATE_TEMPLATE_SUCCESS = '@APPLY_JOB/RESUME_GENERATE_TEMPLATE_SUCCESS';
export const APPLY_JOB_RESUME_GENERATE_TEMPLATE_FAIL = '@APPLY_JOB/RESUME_GENERATE_TEMPLATE_FAIL';
export const APPLY_JOB_RESUME_GENERATE_TEMPLATE_CLEAR = '@APPLY_JOB/RESUME_GENERATE_TEMPLATE_CLEAR';
