import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1040;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.imageBackdropAlphaMore};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  width: 150px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.modalIndicator};
`;

export const Title = styled.p`
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.white};
  padding-top: 0.5rem;
`;

export const DataLoadingWrapper = styled.div`
  margin: 1rem;
  display: flex;
  justify-content: center;
`;

export const ContainerRefresh = styled.div`
  z-index: 1040;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

export const ContentRefresh = styled.div`
  z-index: 1041;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 70px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
`;
