import React, {useState, useEffect} from 'react';
import get from 'lodash/get';
import {translate} from '../../../../res/language/translate';
import {useLocationContextDispatch} from '../../../../lib/hooks/logic/useLocation';
import {useNotifyDispatch, useNotifyState} from '../../../../lib/hooks/logic/useNotify';
import SelectProvince from '../../../../views/select-location-sibling/select-province';
import SelectDistrict from '../../../../views/select-location-sibling/select-district';
import * as ICONS from '../../../../components/icon/constants';
import * as typeDef from '../../../../utils/strong-types';
import * as logic from './logic';

const KEY_ALL_PROVINCE = 'ทุกจังหวัด';
const KEY_HAS_DISTRICT = 'กรุงเทพมหานคร';

const LocationForm = () => {
  const {interestedJobState} = useNotifyState();
  const {dispatchUpdateInterestJobForm} = useNotifyDispatch();
  const {dispatchGetDistrictByProvince} = useLocationContextDispatch();
  const [provinceState, setProvinceState] = useState();

  useEffect(() => {
    if (interestedJobState && typeDef.isNullOrUndefined(provinceState)) {
      const defaultProvince = logic.mapStateToProvince(interestedJobState?.province);
      setProvinceState(defaultProvince);
    }
  }, [interestedJobState, provinceState]);

  const onChangeProvince = (province) => {
    if (!province) {
      return;
    }
    if (province.value !== KEY_ALL_PROVINCE) {
      dispatchGetDistrictByProvince(province.value);
    }
    const copyForm = {...interestedJobState, province: province?.value, district: []};
    dispatchUpdateInterestJobForm(copyForm);
    setProvinceState([province]);
  };

  const onChangeDistrict = (district) => {
    let copyForm = {...interestedJobState, district: district.value};
    if (district.value === interestedJobState.district) {
      copyForm = {...interestedJobState, district: null};
    } else {
      copyForm = {...interestedJobState, district: district.value};
    }
    dispatchUpdateInterestJobForm(copyForm);
  };

  return (
    <>
      <SelectProvince
        outline
        addAll
        iconLabel={ICONS.LOCATION}
        placeholder={translate('landing.search.select')}
        label={translate('editProfile.general.province')}
        selectedProvince={provinceState}
        onChange={onChangeProvince}
        checkBy="value"
      />
      {interestedJobState?.province !== KEY_ALL_PROVINCE && interestedJobState?.province === KEY_HAS_DISTRICT && (
        <SelectDistrict
          outline
          iconLabel={ICONS.LOCATION}
          disabled={typeDef.isNullOrUndefined(interestedJobState?.province)}
          label={translate('editProfile.general.district')}
          selectedDistrict={logic.mapStateToDistrict(get(interestedJobState, 'district')) || []}
          onChange={onChangeDistrict}
          checkBy="value"
        />
      )}
    </>
  );
};

LocationForm.propTypes = {};

export default LocationForm;
