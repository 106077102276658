import {createServiceURL} from '../../env/utils/env-services';
import {adapterPost} from '../../utils/adapter/post';
import {adapterGet} from '../../utils/adapter/get';
import {adapterDelete} from '../../utils/adapter/delete';

export const searchLocationNearest = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/location/nearest_districts`;
  const response = await adapterGet({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const searchPopularJobCat = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/recommended_job/`;
  const response = await adapterGet({url: API_URL, body: reqBody, privateMethod: false, fixedPath: true});
  return response;
};

export const searchAllJobCat = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/job_categories`;
  const response = await adapterGet({url: API_URL, privateMethod: false});
  return response;
};

export const searchJobTimeline = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/timeline`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const searchJobLiked = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/liked`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const searchJobHistory = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/search_history`;
  const response = await adapterGet({url: API_URL, privateMethod: true});
  return response;
};

export const searchJobHistoryPin = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/pin`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const searchJobHistoryDelete = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/remove_search_history`;
  const response = await adapterDelete({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const searchUserLike = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/like/like_job`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const searchUserUnlike = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/like/unlike_job`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const searchQuery = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/search`;
  const isPrivate = !!reqBody?.token;
  const response = await adapterPost({url: API_URL, body: reqBody?.data, privateMethod: isPrivate});
  return response;
};

export const searchQueryNearby = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/fuzzy_search`;
  const isPrivate = !!reqBody?.token;
  const response = await adapterPost({url: API_URL, body: reqBody?.data, privateMethod: isPrivate});
  return response;
};

export const searchHashTag = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/tag`;
  const isPrivate = !!reqBody?.token;
  const response = await adapterPost({url: API_URL, body: reqBody?.data, privateMethod: isPrivate});
  return response;
};

export const searchJobId = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/job_id/${reqBody?.data}`;
  const isPrivate = !!reqBody?.token;
  const response = await adapterGet({url: API_URL, privateMethod: isPrivate});
  return response;
};

export const searchSeenJob = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/user_seen_job`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const searchInterestJob = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/user_interest_job`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const searchGetHiddenJob = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/list_hidden_job`;
  const response = await adapterGet({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const searchHiddenJob = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/hidden_job`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const searchUnHiddenJob = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/undo_hidden_job`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const searchAuthor = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/author`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const searchJobLandingPage = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/landing_page`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};

export const searchGroupList = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/all_landing`;
  const input = {province: reqBody};
  const response = await adapterGet({url: API_URL, body: input, privateMethod: false});
  return response;
};
