import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import FaLogin from './assets/fa-login.json';

const DEFAULT_WIDTH = 250;
const DEFAULT_HEIGHT = 250;

const LottieAnimation = (props) => {
  const {refAnimationName, size} = props;
  let animationData;

  switch (refAnimationName) {
    case 'facebook-loading':
      animationData = FaLogin;
      break;
    default:
      animationData = FaLogin;
      break;
  }

  const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie options={options} height={size || DEFAULT_HEIGHT} width={size || DEFAULT_WIDTH} />;
};

LottieAnimation.propTypes = {
  refAnimationName: PropTypes.string.isRequired,
  size: PropTypes.number,
};

LottieAnimation.defaultProps = {
  size: 250,
};

export default LottieAnimation;
