import lodash from 'lodash';
import isObject from 'lodash/isObject';
import * as stringFn from '../../../utils/string-func';
import * as storage from '../../../utils/storage/storage';

export const getCallbackPath = () => {
  const cacheRdPath = storage.getItem(storage.KEY_RD);
  if (!cacheRdPath) {
    return null;
  }
  const rdPath = JSON.parse(cacheRdPath);
  return rdPath;
};

export const checkUserAcceptedPolicy = (userPolicyState) => {
  if (!Array.isArray(userPolicyState)) {
    return false;
  }
  const orderedVersion = lodash.orderBy(userPolicyState, 'version', 'desc');
  const isAccepted = lodash.get(orderedVersion, '[0].is_agreed', false);
  return isAccepted;
};

export const getCallbackParsePath = (callbackData) => {
  if (!callbackData) {
    return '/';
  }
  let path = callbackData.pathname;
  if (callbackData.querySearch) {
    path = `${path}${callbackData.querySearch}`;
  } else if (isObject(callbackData.search)) {
    path = `${path}?${stringFn.searchStringify(callbackData.search)}`;
  } else if (callbackData.search) {
    path = `${path}${callbackData.search}`;
  }
  return path;
};
