import React from 'react';
import PropTypes from 'prop-types';
import {ImageContainer} from './styled-component';

const Image = (props) => {
  const {width, height, size, src, maxWidth, fluid} = props;
  return <ImageContainer fluid={fluid} maxWidth={maxWidth} height={height} width={width} src={src} size={size} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  size: PropTypes.number,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fluid: PropTypes.bool,
};

Image.defaultProps = {
  width: 250,
  height: 250,
  size: 0,
  maxWidth: 250,
  fluid: true,
};

export default Image;
