import * as typeDef from '../strong-types';

export const checkAuthTokenIsValid = (token) => {
  if (typeDef.isNullOrUndefined(token)) {
    return false;
  }
  if (token === 'undefined' || token === 'null') {
    return false;
  }
  return true;
};

export const checkCurrentUserIsValid = (user) => {
  if (typeDef.isNullOrUndefined(user)) {
    return false;
  }
  if (user === 'undefined' || user === 'null') {
    return false;
  }
  return true;
};

export const checkAppleEnv = (value) => {
  if (typeDef.isNullOrUndefined(value)) {
    return false;
  }
  if (value === 'undefined' || value === 'null') {
    return false;
  }
  return value;
};
