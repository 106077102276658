const size = {
  default: 24,
  inputWidth: 42,
  inputHeight: 42,
};

const CheckboxTheme = {
  size,
};

export default CheckboxTheme;
