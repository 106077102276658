import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import debounce from 'lodash/debounce';
import {translate} from '../../../res/language/translate';
import {useNetworkStatus} from '../../../lib/hooks/useNetworkStatus';
import * as ROUTES from '../../../routes/routes-name';
import * as navigationServices from '../../../utils/navigation/navigation-services';
import * as snackbar from '../../../utils/components/toast-notification/provider';

export const useEssentialsEffect = () => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {isOnlineState} = useNetworkStatus();

  const onTrackingEventInternetChange = (action = 'PUSH') => {
    if (action === 'PUSH') {
      navigationServices.navigateTo({pathname: ROUTES.ROUTE_NO_INTERNET});
      return;
    }
    navigationServices.goBack();
  };

  const debounceInternet = debounce(onTrackingEventInternetChange, 15000);

  useEffect(() => {
    if (isOnlineState) {
      if (pathname === ROUTES.ROUTE_NO_INTERNET) {
        onTrackingEventInternetChange('POP');
      }
    }
    if (!isOnlineState) {
      if (pathname !== ROUTES.ROUTE_NO_INTERNET) {
        debounceInternet('PUSH');
      } else {
        debounceInternet.cancel();
      }
    }
  }, [isOnlineState, pathname]);

  useEffect(() => {
    function changeStatus() {
      const snackData = {
        type: navigator.onLine ? 'success' : 'warning',
        message: navigator.onLine ? translate('status.networkOnline') : translate('status.networkOffline'),
      };
      snackbar.setSnackbarMessage(snackData.message, snackData.type);
    }
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, [dispatch]);
};
