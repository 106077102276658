import React from 'react';
import {TextDownLoad, TextUnderline} from './styled-component';
import Link from '../../../../components/link';

const DownloadForm = () => {
  return (
    <>
      <TextDownLoad>กรุณาดาวน์โหลดแบบฟอร์ม Take down notice และอัพโหลดเพื่อแจ้งดำเนินการ</TextDownLoad>
      <Link url="/take-down-notice.pdf" target="_blank" newTab>
        <TextUnderline>ดาวน์โหลดแบบฟอร์มที่นี่</TextUnderline>
      </Link>
    </>
  );
};

DownloadForm.propTypes = {};

export default DownloadForm;
