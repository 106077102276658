import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import qs from 'querystring';
import {RowBody, SubItem, SubText, UL, LI} from './styled-component';
import Link from '../../../components/link';
import Loading from '../../../components/loading';
import * as typeDef from '../../../utils/strong-types';
import * as ROUTES from '../../../routes/routes-name';

const IndexSubItem = (props) => {
  const {data, isLoading} = props;
  const {formatMessage} = useIntl();

  if (isLoading) {
    return <Loading type="data" loading={isLoading} />;
  }

  if (typeDef.isNotArrayLength(data)) {
    return null;
  }

  return (
    <>
      {data.map((item, index) => (
        <RowBody key={index.toString()}>
          {item.map((childItem, childIndex) => (
            <SubItem key={childIndex.toString()}>
              <Link url={ROUTES.ROUTE_SEARCH_AUTHOR} search={qs.stringify({author_id: childItem.author_id})}>
                <UL>
                  <LI>
                    <SubText>{`${formatMessage({defaultMessage: 'หางาน'})} ${childItem._id}`}</SubText>
                  </LI>
                </UL>
              </Link>
            </SubItem>
          ))}
          {item.length < 3 && <SubItem empty />}
        </RowBody>
      ))}
    </>
  );
};

IndexSubItem.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
};

export default IndexSubItem;
