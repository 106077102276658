import {regexEmail} from '../../../../utils/regex';

export const getBodyText = (text) => {
  if (!text) {
    return '';
  }
  const patternHeader = /(<head>.*?<\/head>)|(<link>.*?<\/link>)/g;
  const newText = text.split(patternHeader);
  return newText;
};

export const getIsHeader = (text) => {
  const patternHeader = /(<head>.*?<\/head>)/g;
  return patternHeader.test(text);
};

export const replaceHeadTag = (text) => {
  if (!text) {
    return '';
  }
  const pattern = /<head>|<\/head>/g;
  return text.replace(pattern, '');
};

export const getIsLink = (text) => {
  const patternHeader = /(<link>.*?<\/link>)/g;
  return patternHeader.test(text);
};

export const replaceLinkTag = (text) => {
  if (!text) {
    return '';
  }
  const pattern = /<link>|<\/link>/g;
  return text.replace(pattern, '');
};

export const getWebsiteUrl = (webUrl) => {
  if (!webUrl) {
    return '';
  }
  const pattern = /^https:\/\//i;
  const isFullUrl = pattern.test(webUrl);
  if (isFullUrl) {
    return webUrl;
  }
  return `https://${webUrl}`;
};

export const getIsEmail = (url) => {
  return regexEmail({input: url});
};
