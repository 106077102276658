import {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {JOB_PAGE_SIZE} from '../../context/pagination';
import * as notifyActions from '../../../redux/notify/action';

export const useNotifyState = () => {
  const {loading: isLoadingState} = useSelector((state) => state.notify.settings, shallowEqual);
  const isLoadingNotifyState = useSelector((state) => state.notify.settings.loadingNotifyState, shallowEqual);
  const settingsState = useSelector((state) => state.notify.settings.setting, shallowEqual);
  const notify = useSelector((state) => state.notify.notifyList?.notify, shallowEqual);
  const userNotification = useSelector((state) => state.notify.notifyList?.userNotification, shallowEqual);
  const idState = useSelector((state) => state.notify.settings.setting?._id, shallowEqual);
  const interestedJobState = useSelector((state) => state.notify.settings.interestedJobForm, shallowEqual);
  const isModal = useSelector((state) => state.notify.settings.modal, shallowEqual);

  return {
    notify,
    userNotification,
    settingsState,
    interestedJobState,
    idState,
    isLoadingState,
    isModal,
    isLoadingNotifyState,
  };
};

export const useNotifyEffect = () => {
  const dispatch = useDispatch();

  const reqBody = {
    pageIndex: 0,
    pageSize: JOB_PAGE_SIZE,
  };
  useEffect(() => {
    dispatch(notifyActions.notifyGetSettingNotification());
    dispatch(notifyActions.notifyGetListNotification(reqBody));
    dispatch(notifyActions.notifyGetNewCountNotification());
  }, [dispatch]);
};

export const useNotifyDispatch = () => {
  const dispatch = useDispatch();

  const dispatchUpdateSetting = (reqBody) => {
    if (!reqBody) {
      return;
    }
    dispatch(notifyActions.notifyUpdateSettingNotification({data: reqBody}));
  };

  const dispatchUpdateSettingToggle = (reqBody) => {
    if (!reqBody) {
      return;
    }
    dispatch(notifyActions.notifyUpdateNotificationSetting({data: reqBody}));
  };

  const dispatchSettingModal = (isModal) => {
    if (!isModal) {
      return;
    }
    dispatch(notifyActions.notifySettingModal({data: isModal}));
  };

  const dispatchUpdateInterestJobForm = (interestJobForm) => {
    if (!interestJobForm) {
      return;
    }
    dispatch(notifyActions.notifySaveInterestedJobForm({data: interestJobForm}));
  };

  const dispatchUpdateInterestJob = (reqBody) => {
    if (!reqBody) {
      return;
    }
    dispatch(notifyActions.notifyUpdateInterestedJob({data: reqBody}));
  };

  return {
    dispatchUpdateSetting,
    dispatchUpdateSettingToggle,
    dispatchSettingModal,
    dispatchUpdateInterestJobForm,
    dispatchUpdateInterestJob,
  };
};
