import React from 'react';
import PropTypes from 'prop-types';
import {translate} from '../../res/language/translate';
import {
  ContentWrapper,
  Container,
  RowLoggedWrapper,
  OverrideStyle,
  CardWrapper,
  CardImage,
  CardTitle,
  SeeAllButton,
  LoggedWrapper,
  Section,
} from './styled-component';
import SlickCarousel from './slick';
import Header from '../../components/header';
import Divider from '../../components/divider';
import Link from '../../components/link';
import Icon from '../../components/icon';
import * as ICONS from '../../components/icon/constants';
import * as logic from './logic';
import * as ROUTES from '../../routes/routes-name';
import * as mixpanel from '../../utils/mixpanel';

const GroupSliderBuilder = React.forwardRef((props, ref) => {
  const {data, loggedInLayout, as, rightArrow, id} = props;

  if (as === 'raw') {
    return (
      <>
        {Array.isArray(data) &&
          data.map((item) => (
            <Link key={item._id} url={logic.getGroupLink(item.url_name)} onClick={() => mixpanel.homeHighlightLanding(true, logic.getGroupLink(item.url_name), window.location.pathname)}>
              <CardWrapper edgeInsetsRight EdgeInsetsBottom>
                <CardImage src={item.cover_photo_sm?.url || item.cover_photo?.url} />
                <CardTitle>{item.th_name}</CardTitle>
              </CardWrapper>
            </Link>
          ))}
      </>
    );
  }

  if (loggedInLayout) {
    if (as === 'link') {
      return (
        <LoggedWrapper>
          <RowLoggedWrapper>
            {Array.isArray(data) &&
              data.map((item, index) => (
                <Link key={item._id} url={logic.getGroupLink(item.url_name)} onClick={() => mixpanel.homeHighlightLanding(true, logic.getGroupLink(item.url_name), window.location.pathname)}>
                  <CardWrapper edgeInsetsRight={index < data.length - 1} noEdgeInsetsBottom>
                    <CardImage src={item.cover_photo_sm?.url || item.cover_photo?.url} />
                    <CardTitle>{item.th_name}</CardTitle>
                  </CardWrapper>
                </Link>
              ))}
          </RowLoggedWrapper>
          {rightArrow && (
            <Link url={ROUTES.ROUTE_GROUP_LIST}>
              <SeeAllButton>
                <Icon name={ICONS.ARROW_RIGHT} size={32} />
              </SeeAllButton>
            </Link>
          )}
        </LoggedWrapper>
      );
    }
  }

  return (
    <Section ref={ref} id={id}>
      <Divider style={OverrideStyle.divider} />
      <Container>
        <ContentWrapper>
          <Header variant="h2" style={OverrideStyle.header}>
            {translate('landing.groupList')}
          </Header>
          <SlickCarousel data={data} />
        </ContentWrapper>
      </Container>
    </Section>
  );
});

GroupSliderBuilder.propTypes = {
  id: PropTypes.string,
  as: PropTypes.oneOf(['link', 'div', 'raw']),
  rightArrow: PropTypes.bool,
  loggedInLayout: PropTypes.bool,
  data: PropTypes.array,
};

GroupSliderBuilder.defaultProps = {
  as: 'div',
  rightArrow: false,
  loggedInLayout: false,
  data: null,
  id: null,
};

export default GroupSliderBuilder;
