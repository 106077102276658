import React from 'react';
import Sidebar from '../../../views/app-bar/side-bar';
import Settings from './settings';

const NotifySidebar = () => {
  return (
    <Sidebar>
      <Settings />
    </Sidebar>
  );
};

NotifySidebar.propTypes = {};

export default NotifySidebar;
