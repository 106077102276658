import React from 'react';
import {useRegister} from '../../../lib/hooks/logic/useRegister';
import {translate} from '../../../res/language/translate';
import {PresentWrapper, SideTextWrapper, SideTitle, SideDescription, SideKey} from './styled-component';
import AnimationTransitionY from '../../../components/animations/transition-y';
import AnimationTransitionX from '../../../components/animations/transition-x';
import SVGView from '../../../components/svg-img';
import SvgRegister1 from '../../../res/assets/images/svg/Register-1.svg';
import SvgRegister2 from '../../../res/assets/images/svg/Register-2.svg';
import SvgRegister3 from '../../../res/assets/images/svg/Fine-interest.svg';

const SidePresent = () => {
  const {currentStepState} = useRegister();
  const isVisibleGeneral = currentStepState === 1;
  const isVisibleInfo = currentStepState === 2;
  const isVisibleInterest = currentStepState === 3;

  return (
    <>
      {isVisibleGeneral && (
        <PresentWrapper step={currentStepState}>
          <AnimationTransitionX x={-20}>
            <SideTextWrapper>
              <SideTitle>{translate('login.presentTitle1')}</SideTitle>
              <SideDescription>{translate('login.presentTitle11')}</SideDescription>
              <SideKey> {translate('login.presentTitle12')}</SideKey>
            </SideTextWrapper>
          </AnimationTransitionX>
          <AnimationTransitionY y={-50}>
            <SVGView autoScale src={SvgRegister1} maxHeight={514} />
          </AnimationTransitionY>
        </PresentWrapper>
      )}
      {isVisibleInfo && (
        <PresentWrapper>
          <AnimationTransitionX x={-20}>
            <SideTextWrapper>
              <SideTitle>{translate('login.presentTitle1')}</SideTitle>
              <SideDescription>{translate('login.presentTitle21')}</SideDescription>
              <SideKey> {translate('login.presentTitle22')}</SideKey>
            </SideTextWrapper>
          </AnimationTransitionX>
          <AnimationTransitionY y={-50}>
            <SVGView autoScale src={SvgRegister2} maxHeight={514} />
          </AnimationTransitionY>
        </PresentWrapper>
      )}
      {isVisibleInterest && (
        <PresentWrapper>
          <AnimationTransitionX x={-20}>
            <SideTextWrapper>
              <SideTitle>{translate('login.presentTitle1')}</SideTitle>
              <SideDescription>{translate('login.presentTitle31')}</SideDescription>
              <SideKey> {translate('login.presentTitle32')}</SideKey>
            </SideTextWrapper>
          </AnimationTransitionX>
          <AnimationTransitionY y={-50}>
            <SVGView autoScale src={SvgRegister3} maxHeight={514} />
          </AnimationTransitionY>
        </PresentWrapper>
      )}
    </>
  );
};

SidePresent.propTypes = {};

export default SidePresent;
