import queryString from 'querystring';

export const trimSafely = (valueStr) => {
  if (typeof valueStr !== 'string') {
    return valueStr;
  }
  return valueStr.trim();
};

export const searchStringify = (search) => {
  return queryString.stringify(search);
};
