import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useLocation, useRouteMatch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {translate} from '../../res/language/translate';
import {useSearchParamsEffect} from '../../lib/hooks/useSearchParams';
import {useFilterOptions, useFilterModal, useFilterOptionEffect} from '../../lib/hooks-logic/useFilter';
import {useLandingPageDispatch} from '../../lib/hooks-logic/useLandingPage';
import {CheckboxSalaryWrapper, Title, ButtonWrapper, OverrideStyles, SearchLabelWrapper} from './styled-component';
import RangeSlider from '../../components/range-slider';
import Checkbox from '../../components/checkbox';
import Row from '../../components/row';
import Icon from '../../components/icon';
import Divider from '../../components/divider';
import Button from '../../components/button';
import GestureDetector from '../../components/gesture';
import Input from '../../components/input';
import Text from '../../components/text';
import Chip from '../../components/chip';
import Responsive from '../../components/responsive';
import Form from '../../components/form';
import * as filterUtils from '../../lib/context/filter';
import * as typeDef from '../../utils/strong-types';
import * as generate from '../../utils/generate';
import * as navigation from '../../utils/navigation/navigation-services';
import * as ROUTES from '../../routes/routes-name';
import * as ICONS from '../../components/icon/constants';
import * as logic from './logic';
import * as mixpanel from '../../utils/mixpanel';

const FilterOptions = (props) => {
  const {isLanding} = props;

  const {pathname} = useLocation();
  const matchLandingRoute = useRouteMatch(ROUTES.ROUTE_LANDING_GROUP);

  const paramsState = useSelector((state) => state.search.jobGroup.params);
  const {checkIsSelectChip, transformFilterToParams} = useFilterOptions();
  const {dispatchSetVisibleFilter} = useFilterModal();
  const {dispatchUpdateFormFilter, dispatchClearFormFilter} = useLandingPageDispatch();
  const {searchParamsState} = useSearchParamsEffect();
  const {formatMessage} = useIntl();

  const [filterEdu, setFilterEdu] = useState([]);
  const [filterExp, setFilterExp] = useState([]);
  const [filterSalary, setFilterSalary] = useState([15000, 80000]);
  const [isFilterSalary, setIsFilterSalary] = useState(false);
  const [filterKey, setFilterKey] = useState({});
  const [educationLevels] = useState(() => filterUtils.createFilterEducationLevel());
  const [experiences] = useState(() => filterUtils.createFilterExp());
  const [paramsLabelState, setParamsLabelState] = useState('');

  useFilterOptionEffect({
    setFilterKey,
    setFilterEdu,
    setFilterExp,
    setFilterSalary,
    setIsFilterSalary,
  });

  useEffect(() => {
    if (searchParamsState) {
      const paramsLabel = logic.transformSearchParamsToLabel(searchParamsState);
      setParamsLabelState(paramsLabel);
    }
  }, [searchParamsState]);

  const onChangeKey = (value, key) => {
    if (!key) {
      return;
    }
    const newKeySearch = {};
    newKeySearch[key] = value;
    setFilterKey({...filterKey, ...newKeySearch});
  };

  const onChangeFilterEdu = (value) => {
    if (filterEdu.find((x) => x === value)) {
      setFilterEdu(() => filterEdu.filter((x) => x !== value));
      return;
    }
    setFilterEdu([...filterEdu, value]);
  };

  const onChangeFilterExp = (value) => {
    const isActive = filterExp.find((x) => x === value);
    if (!typeDef.isNullOrUndefined(isActive)) {
      setFilterExp(() => filterExp.filter((x) => x !== value));
      return;
    }
    setFilterExp([...filterExp, value]);
  };

  const onClickClearFilter = () => {
    setFilterKey({});
    setFilterEdu([]);
    setFilterExp([]);
    setFilterSalary([15000, 80000]);
    setIsFilterSalary(false);
    if (matchLandingRoute) {
      dispatchClearFormFilter();
    }
  };

  const isActiveExperienceEmpty = (value) => {
    const findEmpty = filterExp.find((elem) => elem === 0);
    return value === 0 && findEmpty === 0;
  };

  const onEventSubmit = () => {
    const formValues = {
      filterEdu,
      filterExp,
      filterKey,
      filterSalary,
      isFilterSalary,
    };
    dispatchSetVisibleFilter(false);
    const params = transformFilterToParams(formValues);
    mixpanel.filterClick(window.location.pathname, params);
    if (!matchLandingRoute) {
      navigation.navigateTo({pathname: pathname, search: params});
    }
    dispatchUpdateFormFilter(params, formValues);
  };

  return (
    <>
      <Row content="space-between">
        <Title noLeftEdgeInsets>{translate('search.buttonFilter')}</Title>
        <GestureDetector withEdgeInsets={false} onClick={onClickClearFilter}>
          <Title danger>{translate('search.clear')}</Title>
        </GestureDetector>
      </Row>
      {!matchLandingRoute && (
        <SearchLabelWrapper>
          <Text>{`${formatMessage({defaultMessage: 'คำค้นหา: '})} ${paramsLabelState}`}</Text>
        </SearchLabelWrapper>
      )}

      <Divider style={OverrideStyles.divider} />
      <Form formId={generate.generateIdString('form-filter')} onSubmit={() => {}}>
        <Input
          borderOutline
          iconName={ICONS.SEARCH}
          placeholder={translate('search.placeholderJob')}
          value={filterKey.keyJob || ''}
          onChange={(key) => onChangeKey(key, 'keyJob')}
        />
        {!paramsState?.author_id_arr && (
          <Input
            borderOutline
            containerStyle={OverrideStyles.inputCompany}
            iconName={ICONS.SEARCH}
            placeholder={translate('search.placeholderCompany')}
            value={filterKey.keyCompany || ''}
            onChange={(key) => onChangeKey(key, 'keyCompany')}
          />
        )}
      </Form>
      <Row>
        <Icon name={ICONS.EDUCATION} color={OverrideStyles.iconColor} />
        <Title>{translate('search.filter.education.title')}</Title>
      </Row>
      <Row style={OverrideStyles.rowChip}>
        {educationLevels.map((item, index) => (
          <Chip
            key={index.toString()}
            square
            style={OverrideStyles.chipItem}
            active={checkIsSelectChip(filterEdu, item.value)}
            text={item.label}
            onClick={() => onChangeFilterEdu(item.value)}
          />
        ))}
      </Row>
      <Divider style={OverrideStyles.divider} />
      <Row>
        <Icon name={ICONS.EXPERIENCE} color={OverrideStyles.iconColor} />
        <Title>{translate('search.filter.exp.title')}</Title>
      </Row>
      <Row style={OverrideStyles.rowChip}>
        {experiences.map((item, index) => (
          <Chip
            key={index.toString()}
            square
            style={OverrideStyles.chipItem}
            active={checkIsSelectChip(filterExp, item.value) || isActiveExperienceEmpty(item.value)}
            text={item.label}
            onClick={() => onChangeFilterExp(item.value)}
          />
        ))}
      </Row>
      <Divider style={OverrideStyles.divider} />
      <Row>
        <Icon name={ICONS.DOLLAR} color={OverrideStyles.iconColor} />
        <Title>{translate('search.filter.salary.title')}</Title>
      </Row>
      <RangeSlider
        noBottomEdgeInsets
        isActive={isFilterSalary}
        values={filterSalary}
        onChange={(val) => {
          setIsFilterSalary(true);
          setFilterSalary(val);
        }}
      />
      <CheckboxSalaryWrapper>
        <Checkbox label={translate('search.filter.salary.none')} onChange={() => setIsFilterSalary(!isFilterSalary)} checked={!isFilterSalary} />
      </CheckboxSalaryWrapper>
      <ButtonWrapper>
        <Responsive maxWidth={!isLanding ? 1439 : 1065}>
          <Button variant="light" onClick={() => dispatchSetVisibleFilter(false)}>
            {formatMessage({defaultMessage: 'ปิด'})}
          </Button>
        </Responsive>
        <Button block onClick={onEventSubmit}>
          {translate('search.filter.submit')}
        </Button>
      </ButtonWrapper>
    </>
  );
};

FilterOptions.propTypes = {
  isLanding: PropTypes.bool,
};

FilterOptions.defaultProps = {
  isLanding: false,
};

export default FilterOptions;
