export const availableStart = [
  {
    label: 'ไม่แน่ใจ',
    value: 'ไม่แน่ใจ',
  },
  {
    label: 'ทันที',
    value: 'ทันที',
  },
  {
    label: '7 วัน',
    value: '7วัน',
  },
  {
    label: '15 วัน',
    value: '15วัน',
  },
  {
    label: '1 เดือน',
    value: '1เดือน',
  },
  {
    label: '2 เดือน',
    value: '2เดือน',
  },
  {
    label: '3 เดือน',
    value: '3เดือน',
  },
];

export const jobLevel = [
  {
    label: 'เจ้าหน้าที่',
    value: 'เจ้าหน้าที่',
  },
  {
    label: 'หัวหน้างาน',
    value: 'หัวหน้างาน',
  },
  {
    label: 'ผู้จัดการ/ผู้อาวุโส',
    value: 'ผู้จัดการ/ผู้อาวุโส',
  },
  {
    label: 'ผู้บริหาร',
    value: 'ผู้บริหาร',
  },
];

export const jobType = [
  {
    label: 'พนักงานประจำ',
    value: 'full-time',
  },
  {
    label: 'Part-time',
    value: 'part-time',
  },
  {
    label: 'Freelance',
    value: 'freelance',
  },
  {
    label: 'ผู้บริหาร',
    value: 'internship',
  },
];

export const jobExperience = () => {
  const expGenerated = [...Array(50).keys()];
  const response = expGenerated.map((elem) => ({
    label: elem === 0 ? 'จบใหม่' : `${elem} ปี`,
    value: elem,
  }));
  response.splice(0, 0, {label: 'ไม่ระบุ', value: -1});
  return response;
};
