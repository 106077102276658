import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import {isArrayLength, isArray} from './strong-types';
import * as typeDef from './strong-types';

export const getObjectDeepState = ({container, key}) => {
  return get(container, key);
};

export const addRowDeepState = ({dataArray, rowData}) => {
  if (!isArrayLength(dataArray)) {
    return [];
  }
  const copyData = cloneDeep(dataArray);
  copyData.push(rowData);
  return copyData;
};

export const deleteRowDeepState = ({dataArray}) => {
  if (!isArrayLength(dataArray)) {
    return [];
  }
  const copyData = cloneDeep(dataArray);
  copyData.pop();
  return copyData;
};

export const cloneDeepState = ({data}) => {
  return cloneDeep(data);
};

export const mergeArray = ({data1, data2}) => {
  let newArray = [];
  if (isArray(data1)) {
    newArray = [...data1];
  }
  if (isArray(data2)) {
    newArray = [...newArray, ...data2];
  }
  return newArray;
};

export const mergeArrayState = ({prevState, newState}) => {
  let newArray = [];
  if (typeDef.isArrayLength(prevState)) {
    newArray = [...prevState];
  }
  if (typeDef.isArrayLength(newState)) {
    newArray = [...newArray, ...newState];
  }
  return uniqBy(newArray, '_id');
};

export const mergeObjectToArrayState = ({prevState, newState, uniq}) => {
  let newArray = [];
  if (typeDef.isArrayLength(prevState)) {
    newArray = [...prevState];
  }
  if (typeDef.isObject(newState)) {
    newArray = [...newArray, {...newState}];
  }
  if (uniq) {
    return uniqBy(newArray, '_id');
  }
  return newArray;
};

export const mergeObject = ({obj1, obj2}) => {
  let newObject = {};
  if (typeDef.isObject(obj1)) {
    newObject = {...obj1};
  }
  if (typeDef.isObject(obj2)) {
    newObject = {...newObject, ...obj2};
  }
  return newObject;
};
