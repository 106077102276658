import {put, call, delay} from 'redux-saga/effects';
import {authorizeProvider} from '../utils/authorize';
import {snackbarManualProvider} from '../utils/fast-dispatch';
import {getErrorMessage} from '../utils/error-store';
import * as api from './api';
import * as TYPES from './type';
import * as LIKE_TYPES from '../like/type';
import * as typeDef from '../../utils/strong-types';

export function* searchLocationNearestAction({payload}) {
  try {
    const response = yield call(api.searchLocationNearest, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_LOCATION_NEAREST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_LOCATION_NEAREST_FAIL,
      payload: responseError,
    });
  }
}

export function* searchCacheLocationNearestAction({payload}) {
  yield put({
    type: TYPES.SEARCH_CACHE_LOCATION_NEAREST_SUCCESS,
    payload: payload,
  });
}

export function* searchPopularJobCatAction({payload}) {
  if (payload?.clear) {
    yield put({type: TYPES.SEARCH_POPULAR_JOB_CAT_CLEAR});
    return;
  }
  try {
    const response = yield call(api.searchPopularJobCat, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_POPULAR_JOB_CAT_SUCCESS,
        payload: {data: response.data, province: payload.data},
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_POPULAR_JOB_CAT_FAIL,
      payload: responseError,
    });
  }
}

export function* searchPopularJobUpdateLocationAction({payload}) {
  yield delay(500);
  yield put({
    type: TYPES.SEARCH_POPULAR_UPDATE_LOCATION_SUCCESS,
    payload: payload?.data,
  });
}

export function* searchAllJobCatAction() {
  try {
    const response = yield call(api.searchAllJobCat);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_ALL_JOB_CAT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_ALL_JOB_CAT_FAIL,
      payload: responseError,
    });
  }
}

export function* searchJobTimelineAction({payload}) {
  try {
    const response = yield call(api.searchJobTimeline, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_TIMELINE_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: TYPES.SEARCH_SEEN_JOB_REQ,
        payload: {
          data: response.data?.results,
        },
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.likedJob,
        },
      });
      if (payload?.saveToken) {
        yield put({
          type: TYPES.SEARCH_JOB_TIMELINE_CACHE_TOKEN_SUCCESS,
          payload: response.data?.token,
        });
      }
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.SEARCH_JOB_TIMELINE_FAIL,
        payload: responseError,
      });
    }
  }
}

export function* searchJobTimelineRefreshAction({payload}) {
  try {
    const response = yield call(api.searchJobTimeline, payload?.data);
    if (response.status === 200) {
      yield delay(1000);
      yield put({
        type: TYPES.SEARCH_JOB_TIMELINE_REFRESH_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: TYPES.SEARCH_SEEN_JOB_REQ,
        payload: {
          data: response.data?.results,
        },
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.likedJob,
        },
      });
      if (payload?.saveToken) {
        yield put({
          type: TYPES.SEARCH_JOB_TIMELINE_CACHE_TOKEN_SUCCESS,
          payload: response.data?.token,
        });
      }
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.SEARCH_JOB_TIMELINE_REFRESH_FAIL,
        payload: responseError,
      });
    }
  }
}

export function* searchJobLikedAction({payload}) {
  try {
    const response = yield call(api.searchJobLiked, payload?.data);
    if (response.status === 200) {
      yield delay(500);
      yield put({
        type: TYPES.SEARCH_JOB_LIKED_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.likedJob,
        },
      });
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.SEARCH_JOB_LIKED_FAIL,
        payload: responseError,
      });
    }
  }
}

export function* searchJobLikedRefreshAction({payload}) {
  try {
    const response = yield call(api.searchJobLiked, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_LIKED_REFRESH_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.likedJob,
        },
      });
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.SEARCH_JOB_LIKED_REFRESH_FAIL,
        payload: responseError,
      });
    }
  }
}

export function* searchHistoryAction() {
  try {
    const response = yield call(api.searchJobHistory);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_HISTORY_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_HISTORY_FAIL,
      payload: responseError,
    });
  }
}

export function* searchHistoryPinAction({payload}) {
  try {
    const response = yield call(api.searchJobHistoryPin, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_PIN_SUCCESS,
        payload: payload.item,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    snackbarManualProvider({
      message: getErrorMessage(responseError),
      danger: true,
    });
    yield put({
      type: TYPES.SEARCH_JOB_PIN_FAIL,
      payload: responseError,
    });
  }
}

export function* searchHistoryDeleteAction({payload}) {
  try {
    const response = yield call(api.searchJobHistoryDelete, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_HISTORY_DELETE_SUCCESS,
        payload: payload.item,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_HISTORY_DELETE_FAIL,
      payload: responseError,
    });
  }
}

export function* searchQueryAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.SEARCH_JOB_QUERY_CLEAR,
    });
    return;
  }
  if (payload?.saveForm) {
    yield put({
      type: TYPES.SEARCH_SAVE_FORM_SUCCESS,
      payload: payload?.data,
    });
  }

  try {
    const response = yield call(api.searchQuery, payload);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_QUERY_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: TYPES.SEARCH_SEEN_JOB_REQ,
        payload: {
          data: response.data?.results,
        },
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.likedJob,
        },
      });
      if (payload?.saveToken) {
        yield put({
          type: TYPES.SEARCH_JOB_QUERY_CACHE_TOKEN_SUCCESS,
          payload: response.data?.token,
        });
      }
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_QUERY_FAIL,
      payload: responseError,
    });
  }
}

export function* searchQueryNearbyAction({payload}) {
  try {
    const response = yield call(api.searchQueryNearby, payload);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_QUERY_NEARBY_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: TYPES.SEARCH_SEEN_JOB_REQ,
        payload: {
          data: response.data?.results,
        },
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.likedJob,
        },
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_QUERY_NEARBY_FAIL,
      payload: responseError,
    });
  }
}

export function* searchHashtagAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.SEARCH_JOB_HASHTAG_CLEAR,
    });
    return;
  }
  try {
    const response = yield call(api.searchHashTag, payload);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_HASHTAG_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: TYPES.SEARCH_SEEN_JOB_REQ,
        payload: {
          data: response.data?.results,
        },
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.likedJob,
        },
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_HASHTAG_FAIL,
      payload: responseError,
    });
  }
}

export function* searchJobIdAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.SEARCH_JOB_ID_CLEAR,
    });
    return;
  }
  try {
    const response = yield call(api.searchJobId, payload);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_ID_SUCCESS,
        payload: response.data?.job,
      });
      yield put({
        type: TYPES.SEARCH_SEEN_JOB_REQ,
        payload: {
          data: [response.data?.job],
        },
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.jobLike || [],
        },
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_ID_FAIL,
      payload: responseError,
    });
  }
}

export function* searchSaveFormAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.SEARCH_SAVE_FORM_CLEAR,
    });
    return;
  }
  yield put({
    type: TYPES.SEARCH_SAVE_FORM_SUCCESS,
    payload: payload?.data,
  });
}

export function* searchSeenJobAction({payload}) {
  try {
    if (typeDef.isNotArrayLength(payload?.data)) {
      throw new Error('jobs is not array');
    }
    const jobIds = payload.data.map((item) => item._id);
    const reqBody = {
      job_id: jobIds,
    };
    const response = yield call(api.searchSeenJob, reqBody);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_SEEN_JOB_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_SEEN_JOB_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}

export function* searchInterestDebounceAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.SEARCH_INTEREST_DEBOUNCE_CLEAR,
    });
    return;
  }
  yield put({
    type: TYPES.SEARCH_INTEREST_DEBOUNCE_SUCCESS,
    payload: payload?.data,
  });
}

export function* searchInterestJobAction({payload}) {
  yield delay(10000);
  try {
    if (typeDef.isNotArrayLength(payload?.data)) {
      throw new Error('jobs is not array');
    }
    const jobIds = payload.data.map((item) => item._id);
    const reqBody = {
      job_id: jobIds,
    };
    const response = yield call(api.searchInterestJob, reqBody);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_INTEREST_STREAM_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_INTEREST_STREAM_FAIL,
      payload: responseError || error?.response || error,
    });
  }
}

export function* searchGetHiddenJobAction({payload}) {
  try {
    const response = yield call(api.searchGetHiddenJob, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_HIDDEN_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_HIDDEN_LIST_FAIL,
      payload: responseError,
    });
  }
}

export function* searchHiddenJobAction({payload}) {
  try {
    const response = yield call(api.searchHiddenJob, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_HIDDEN_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_HIDDEN_FAIL,
      payload: {
        error: responseError,
        newState: payload?.newState,
      },
    });
  }
}

export function* searchUnHiddenJobAction({payload}) {
  try {
    const response = yield call(api.searchUnHiddenJob, payload?.data);
    if (response.status === 200) {
      yield delay(1000);
      yield put({
        type: TYPES.SEARCH_JOB_UNHIDDEN_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_UNHIDDEN_FAIL,
      payload: {
        error: responseError,
        newState: payload?.newState,
      },
    });
  }
}

export function* searchJobAuthorAction({payload}) {
  if (payload?.clear) {
    yield put({type: TYPES.SEARCH_JOB_AUTHOR_CLEAR});
    return;
  }
  try {
    const response = yield call(api.searchAuthor, payload?.data);
    if (response.status === 200) {
      yield delay(1000);
      yield put({
        type: TYPES.SEARCH_JOB_AUTHOR_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.likedJob,
        },
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_AUTHOR_FAIL,
      payload: responseError,
    });
  }
}

export function* searchJobGroupGetParamsAction() {
  try {
    // const response = yield call(api.searchAuthor, payload?.data);
    const response = {
      status: 200,
      data: {
        jobcat: ['การตลาด'],
        province: 'กรุงเทพมหานคร',
      },
    };
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_GROUP_PARAMS_SUCCESS,
        payload: response.data,
      });
      // yield put({
      //   type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
      //   payload: {
      //     data: response?.data?.likedJob,
      //   },
      // });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_GROUP_PARAMS_FAIL,
      payload: responseError,
    });
  }
}

export function* searchJobGroupAction({payload}) {
  if (payload?.clear) {
    yield put({type: TYPES.SEARCH_JOB_GROUP_CLEAR});
    return;
  }
  if (payload?.clearData) {
    yield put({type: TYPES.SEARCH_JOB_GROUP_DATA_CLEAR});
    return;
  }
  try {
    const response = yield call(api.searchJobLandingPage, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_GROUP_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.likedJob,
        },
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_GROUP_FAIL,
      payload: responseError,
    });
  }
}

export function* searchJobGroupRefreshAction({payload}) {
  try {
    const response = yield call(api.searchJobLandingPage, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_GROUP_REFRESH_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: LIKE_TYPES.LIKE_UPDATE_STREAM_REQ,
        payload: {
          data: response?.data?.likedJob,
        },
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_GROUP_REFRESH_FAIL,
      payload: responseError,
    });
  }
}

export function* searchJobGroupUpdateFilterAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.SEARCH_JOB_GROUP_UPDATE_FILTER_CLEAR,
    });
    return;
  }
  yield put({
    type: TYPES.SEARCH_JOB_GROUP_UPDATE_FILTER_SUCCESS,
    payload: payload?.data,
  });
}

export function* searchJobGroupListAction({payload}) {
  if (payload?.clear) {
    yield put({type: TYPES.SEARCH_JOB_GROUP_LIST_CLEAR});
    return;
  }
  try {
    const response = yield call(api.searchGroupList, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.SEARCH_JOB_GROUP_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.SEARCH_JOB_GROUP_LIST_CLEAR,
      payload: responseError,
    });
  }
}

export function* searchJobQueryStoreParamsAction({payload}) {
  if (payload?.clear) {
    yield put({type: TYPES.SEARCH_JOB_QUERY_STORE_PARAMS_CLEAR});
    return;
  }
  yield put({type: TYPES.SEARCH_JOB_QUERY_STORE_PARAMS_SUCCESS, payload: payload});
}
