import React from 'react';
import PropTypes from 'prop-types';
import {Sandbox} from './styled-component';
import Row from '../row';
import Chip from '../chip';
import * as typeDef from '../../utils/strong-types';
import * as ICONS from '../icon/constants';

const SelectSandBox = (props) => {
  const {sandboxColor, selectedOptions, onClickChip, shapeTextFunc, outline} = props;
  if (typeDef.isNotArrayLength(selectedOptions)) {
    return null;
  }

  if (typeof shapeTextFunc === 'function') {
    return (
      <Sandbox sandboxColor={sandboxColor} outline={outline}>
        <Row>
          {selectedOptions.map((item) => (
            <Chip key={item.id} square text={shapeTextFunc(item.label, item)} rightIcon={ICONS.CLOSE} onClick={() => onClickChip(item.id)} />
          ))}
        </Row>
      </Sandbox>
    );
  }

  return (
    <Sandbox sandboxColor={sandboxColor} outline={outline}>
      <Row>
        {selectedOptions.map((item) => (
          <Chip key={item.id} square text={item.label} rightIcon={ICONS.CLOSE} onClick={() => onClickChip(item.id)} />
        ))}
      </Row>
    </Sandbox>
  );
};

SelectSandBox.propTypes = {
  sandboxColor: PropTypes.oneOf(['white', 'gray']),
  selectedOptions: PropTypes.array,
  shapeTextFunc: PropTypes.func,
  onClickChip: PropTypes.func,
  outline: PropTypes.bool,
};

SelectSandBox.defaultProps = {
  selectedOptions: null,
  shapeTextFunc: null,
  sandboxColor: 'white',
  outline: false,
  onClickChip: () => {},
};

export default SelectSandBox;
