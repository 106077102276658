import React from 'react';
import PropTypes from 'prop-types';
import {Container} from './styled-component';

const LayoutContent = (props) => {
  const {children} = props;
  return <Container>{children}</Container>;
};

LayoutContent.propTypes = {
  children: PropTypes.any,
};

LayoutContent.defaultProps = {
  children: null,
};

export default LayoutContent;
