import React from 'react';
import {useIntl} from 'react-intl';
import {useGetReduxState} from '../../../lib/hooks-logic/useGetReduxState';
import {useSearchQueryDispatch} from '../hooks/useSearchQuery';
import {CenterWrapper} from './styled-component';
import LayoutContent from '../../../views/layout-content';
import Button from '../../../components/button';
import Header from '../../../components/header';

const FuzzySearch = () => {
  const {formatMessage} = useIntl();
  const {
    reduxState: {loading: isLoadingState, visibleNearbyButton: isVisibleState, pageIndex, cacheNearByToken, sinkQueryNearby},
  } = useGetReduxState('search.jobQuery.queryNearby');
  const {
    reduxState: {stackFeed, newFeed},
  } = useGetReduxState('search.jobQuery.query');
  const {
    reduxState: {cacheToken: nonFuzzyTokenState},
  } = useGetReduxState('search.jobQuery');

  const {dispatchFetchSearchQueryNearby} = useSearchQueryDispatch();

  const onEventFuzzySearch = () => {
    if (isLoadingState) {
      return;
    }
    dispatchFetchSearchQueryNearby(pageIndex, cacheNearByToken, nonFuzzyTokenState);
  };

  if (isLoadingState) {
    return null;
  }

  if (!isVisibleState) {
    const isArrayFeed = Array.isArray(stackFeed);
    const isNumberTotal = typeof newFeed?.countDisplay === 'number';
    if (!isArrayFeed || !isNumberTotal) {
      return null;
    }
    if (stackFeed.length !== newFeed.countDisplay) {
      return null;
    }
    if (sinkQueryNearby) {
      return null;
    }
  }

  return (
    <LayoutContent>
      <CenterWrapper>
        <Header variant="h2">{formatMessage({defaultMessage: 'ค้นหางานอื่นๆ ที่ใกล้เคียง'})}</Header>
        <Button onClick={onEventFuzzySearch}>{formatMessage({defaultMessage: 'ดูงานใกล้เคียง'})}</Button>
      </CenterWrapper>
    </LayoutContent>
  );
};

FuzzySearch.propTypes = {};

export default FuzzySearch;
