import {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {checkAuthTokenIsValid} from '../../utils/storage/helper';
import {getAuthToken} from '../../utils/storage/storage';
import {getPlatform} from '../../utils/platform';
import PackageVersion from '../../../package.json';
import * as authAction from '../../redux/auth/action';
import * as notificationAction from '../../redux/notify/action';

export const useAuthState = () => {
  const {
    user: currentState,
    loading: isLoadingState,
    error: errorState,
    isCheckedAuth: isCheckedAuthState,
  } = useSelector((state) => state.auth.auth, shallowEqual);
  const isCurrentLoggedInState = !!currentState && isCheckedAuthState;

  return {
    isCurrentLoggedInState,
    currentState,
    isLoadingState,
    errorState,
    isCheckedAuthState,
  };
};

export const useAuthEffect = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.auth.auth.user);

  useEffect(() => {
    if (!userState) {
      if (!checkAuthTokenIsValid(getAuthToken())) {
        dispatch(authAction.userAuthCurrent({callCheck: true}));
        return;
      }
      const reqBody = {
        version: PackageVersion.version,
        platform: getPlatform(),
        token: getAuthToken(),
      };
      dispatch(authAction.userAuthCurrent({data: reqBody}));
    }
    if (userState) {
      dispatch(notificationAction.notifyGetNewCountNotification());
    }
  }, [dispatch, userState]);
};
