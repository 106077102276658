import React from 'react';
import {useIntl} from 'react-intl';
import {translate} from '../../../../res/language/translate';
import {ContainerModal, RowButtonModal, OverrideStyles, Row, Div} from '../styled-component';
import {useNotifyDispatch, useNotifyState} from '../../../../lib/hooks/logic/useNotify';
import Form from '../../../../components/form';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';
import KeyForm from './key-form';
import CategoryForm from './category-form';
import LocationForm from './location-form';

const FormSearch = () => {
  const {interestedJobState} = useNotifyState();
  const {dispatchSettingModal, dispatchUpdateInterestJobForm, dispatchUpdateInterestJob} = useNotifyDispatch();
  const {formatMessage} = useIntl();

  const onSubmitSearch = () => {
    dispatchUpdateInterestJob({search_params: interestedJobState});
  };

  const onClearForm = () => {
    dispatchUpdateInterestJobForm({clear: true});
  };

  return (
    <ContainerModal>
      <Row>
        <Div />
        <Div>
          <Button style={OverrideStyles.buttonClose} onClick={() => dispatchSettingModal({visible: false, isEdit: false})}>
            <Icon style={OverrideStyles.iconClose} name="close" />
          </Button>
        </Div>
      </Row>
      <Form formId="form-full-search" onSubmit={onSubmitSearch}>
        <KeyForm />
        <CategoryForm />
        <LocationForm />

        <RowButtonModal>
          <Button variant="light" edgeInsets="none" onClick={onClearForm}>
            {translate('search.clear')}
          </Button>
          <Button block edgeInsets="none" onClick={() => onSubmitSearch()} style={OverrideStyles.buttonSpaceLeft}>
            {formatMessage({defaultMessage: 'บันทึก'})}
          </Button>
        </RowButtonModal>
      </Form>
    </ContainerModal>
  );
};

FormSearch.propTypes = {};

export default FormSearch;
