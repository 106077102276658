import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import SectionHeader from '../header';
import {calEducationInfo} from '../resume-info/logic';
import * as ICONS from '../../../../components/icon/constants';
import * as ROUTES from '../../../../routes/routes-name';

const EducationInfo = (props) => {
  const {userState} = props;
  const {formatMessage} = useIntl();

  if (!userState) {
    return null;
  }
  return (
    <>
      <SectionHeader
        route={ROUTES.ROUTE_EDIT_PROFILE_EDUCATION}
        iconName={ICONS.EDUCATION}
        title={formatMessage({defaultMessage: 'ประวัติการศึกษา'})}
        score={calEducationInfo(userState)}
        total={20}
      />
    </>
  );
};

EducationInfo.propTypes = {
  userState: PropTypes.object,
};

EducationInfo.defaultProps = {
  userState: null,
};

export default EducationInfo;
