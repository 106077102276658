/* eslint-disable no-unused-vars */
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import View from './view';
import {useUserState} from '../../lib/hooks/logic/useUser';
import CantApplyModal from '../job-post-builder/utils/modal';
import {genUrl, checkCanAutoResume} from './logic';
import {
  GET_APPLY_JOB_DRAFT_REQ,
  GET_APPLY_JOB_RESUME_REQ,
  GET_APPLY_JOB_SAVE_DRAFT_REQ,
  GET_APPLY_JOB_RESUME_LOADING,
  GET_APPLY_JOB_SAVE_DATA_REQ,
  GET_APPLY_JOB_CLEAR,
  APPLY_JOB_SEND_MAIL_REQ,
} from '../../redux/apply-job2/type';
import {useDispatchFileUpload} from '../../lib/hooks/logic/useUpload';

const Controller = (props) => {
  const {userState} = useUserState();
  const dispatch = useDispatch();

  const reduxState = useSelector((state) => state.applyJob2);
  const defaultResumeMode = userState && !userState.resume && userState.auto_resume ? 'auto' : 'upload';
  const [autoResumeData, setAutoResumeData] = useState();

  useEffect(() => {
    dispatch({type: GET_APPLY_JOB_DRAFT_REQ});
    dispatch({
      type: GET_APPLY_JOB_SAVE_DATA_REQ,
      payload: {
        jobId: props.id,
        useAutoResume: (reduxState.resumeMode || defaultResumeMode) === 'auto',
      },
    });
  }, [dispatch]);

  const {dispatchUploadFile} = useDispatchFileUpload();
  const onUploadResume = async (event) => {
    const file = event.target.files[0];
    const arg = {
      file: file,
      name: file?.name,
      type: file?.type,
      size: file?.size,
    };
    dispatch({type: GET_APPLY_JOB_RESUME_LOADING});
    const [uploadUrl] = await dispatchUploadFile(userState?._id, arg, 'manual_resume');
    dispatch({type: GET_APPLY_JOB_RESUME_REQ, payload: {url: uploadUrl, jobId: props.id}});
  };

  const onRequestCloseHook = () => {
    dispatch({type: GET_APPLY_JOB_CLEAR});
    props.onRequestClose();
  };

  const onSetResumeMode = () => {
    const [checkList, isCanAuto] = checkCanAutoResume(userState);
    if (!isCanAuto) {
      setAutoResumeData({checkList, isCanAuto});
      return;
    }
    setAutoResumeData(null);
    dispatch({
      type: GET_APPLY_JOB_SAVE_DATA_REQ,
      payload: {jobId: props.id, useAutoResume: reduxState.resumeMode !== 'auto'},
    });
  };

  const onCloseCantApply = () => {
    setAutoResumeData(null);
  };

  const onSubmit = (subj, body, vendor) => {
    dispatch({
      type: GET_APPLY_JOB_SAVE_DRAFT_REQ,
      payload: {subj, body, vendor, email: props.email.join(','), jobId: props.id},
    });
    dispatch({
      type: GET_APPLY_JOB_SAVE_DATA_REQ,
      payload: {
        jobId: props.id,
        useAutoResume: reduxState.resumeMode === 'auto',
      },
    });
    dispatch({
      type: APPLY_JOB_SEND_MAIL_REQ,
      payload: {
        data: {
          title: subj?.text,
          body: body?.text,
          destination: props?.email,
          jobApplyId: reduxState?.jobApplyId,
        },
      },
    });
    onRequestCloseHook();
    // const url = genUrl(vendor, subj.text, props.email.join(','), body.text, reduxState.notifyEmail, reduxState.resumeDownloadLink);
    // if (vendor === 'mailto') window.location.href = url;
    // else window.open(url, '_blank').focus();
  };

  return (
    <>
      {autoResumeData && <CantApplyModal onRequestClose={onCloseCantApply} general={{}} resumeData={autoResumeData} />}
      {!autoResumeData && (
        <View
          {...props}
          user={userState || {}}
          onSubmit={onSubmit}
          onUploadResume={onUploadResume}
          onSetResumeMode={onSetResumeMode}
          {...reduxState}
          resumeMode={reduxState.resumeMode || defaultResumeMode}
          onRequestClose={onRequestCloseHook}
        />
      )}
    </>
  );
};

Controller.propTypes = {
  visible: PropTypes.bool,
  id: PropTypes.string,
  email: PropTypes.array,
  title: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
};
Controller.defaultProps = {
  visible: false,
  id: '',
  email: [],
  title: '',
};

export default Controller;
