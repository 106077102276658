import {takeEvery} from 'redux-saga/effects';
import * as TYPES from './type';
import * as alertSaga from './saga';

export function* watchAlertSetAlert() {
  yield takeEvery(TYPES.ALERT_SET_ALERT_REQ, alertSaga.alertSetAlertAction);
}

export function* watchAlertRemoveAlert() {
  yield takeEvery(TYPES.ALERT_REMOVE_ALERT_REQ, alertSaga.alertRemoveAlertAction);
}
