import * as API_REGISTER from '../../redux/register/api';

export const directCheckEmailAvailable = async (email) => {
  try {
    const response = await API_REGISTER.userRegisterValidateEmail({email});
    return [response, null];
  } catch (error) {
    const responseError = error?.response?.data?.error;
    if (responseError === 'email already exists') {
      return [null, 'อีเมลนี้ถูกใช้งานไปแล้ว'];
    }
    return [null, error];
  }
};
