import keyBy from 'lodash/keyBy';
import * as typeDef from '../../../../utils/strong-types';
import * as initData from './init-data';
import * as strFunc from '../../../../lib/context/string';

export const mapEducations = (listData) => {
  if (typeDef.isNotArrayLength(listData)) {
    return [];
  }
  const keyLevel = keyBy(initData.graduationData, 'value');
  const keyYear = keyBy(initData.yearData(), 'value');

  const response = listData.map((elem) => {
    return {
      level: keyLevel[elem.level] || null,
      school: elem.school,
      branch: elem.branch || '',
      faculty: elem.faculty || '',
      grade: elem.grade,
      year: keyYear[elem.year] || null,
    };
  });
  return response;
};

export const transformEducation = (listData) => {
  if (typeDef.isNotArrayLength(listData)) {
    return [];
  }
  const response = listData.map((elem) => {
    const res = {
      level: elem.level?.value,
      school: strFunc.trimText(elem?.school),
      branch: strFunc.trimText(elem.branch),
      faculty: strFunc.trimText(elem.faculty),
      grade: elem.grade,
      year: elem.year?.value,
    };
    if (res.level === 'ม.3' || res.value === 'ม.6') {
      res.branch = '';
      res.faculty = '';
    }
    if (res.level === 'ปวช.' || res.value === 'ปวส.') {
      res.faculty = '';
    }
    return res;
  });
  return response;
};
