import React from 'react';
import PropTypes from 'prop-types';
import {translate} from '../../../../res/language/translate';
import {Container} from './styled-component';
import GroupSliderBuilder from '../../../../views/group-slider-builder';
import Button from '../../../../components/button';
import Divider from '../../../../components/divider';
import Row from '../../../../components/row';
import Link from '../../../../components/link';
import Spacer from '../../../../components/spacer';
import Responsive from '../../../../components/responsive';
import * as ROUTES from '../../../../routes/routes-name';

const BREAK_POINT_FLUID_BUTTON = 500;

const JobGroup = (props) => {
  const {fluid, data} = props;

  if (!data) {
    return null;
  }

  if (!fluid) {
    return (
      <Container>
        <Divider edgeInsets />
        <GroupSliderBuilder loggedInLayout as="link" data={data} />
        <Responsive minWidth={BREAK_POINT_FLUID_BUTTON + 1}>
          <Row content="center">
            <Link url={ROUTES.ROUTE_GROUP_LIST}>
              <Button variant="light">{translate('primaryBox.seeAllJobGroup')}</Button>
            </Link>

            <Link url={ROUTES.ROUTE_JOB_CATEGORY}>
              <Button block variant="light">
                {translate('primaryBox.seeAllJobCat')}
              </Button>
            </Link>
          </Row>
        </Responsive>
        <Responsive maxWidth={BREAK_POINT_FLUID_BUTTON}>
          <Link url={ROUTES.ROUTE_GROUP_LIST}>
            <Button block variant="light">
              {translate('primaryBox.seeAllJobGroup')}
            </Button>
          </Link>
          <Spacer height={8} />
          <Link url={ROUTES.ROUTE_JOB_CATEGORY}>
            <Button block variant="light">
              {translate('primaryBox.seeAllJobCat')}
            </Button>
          </Link>
        </Responsive>
      </Container>
    );
  }

  return <GroupSliderBuilder rightArrow loggedInLayout as="link" data={data} />;
};

JobGroup.propTypes = {
  fluid: PropTypes.bool,
  data: PropTypes.array,
};

JobGroup.defaultProps = {
  fluid: false,
  data: null,
};

export default JobGroup;
