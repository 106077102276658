import {useEffect, useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import View from './view';
import * as TYPES from '../../redux/list-apply-job/type';
// import {data3 as data} from './data'; // for testing

const ApplyJobController = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({type: TYPES.LIST_APPLY_JOB_REQ});
	}, [dispatch]);

	const reduxData = useSelector((state) => state.listApplyJob, shallowEqual);

	const checkJobIsLiked = useCallback((id) => reduxData.jobsLike[id], [reduxData.jobsLike]);
	const dispatchLike = useCallback(
		(a) => {
			dispatch({type: TYPES.LIST_APPLY_JOB_LIKE_REQ, payload: {job_id: a.id}});
		},
		[dispatch],
	);
	const dispatchUnLike = useCallback(
		(a) => {
			dispatch({type: TYPES.LIST_APPLY_JOB_UNLIKE_REQ, payload: {job_id: a.id}});
		},
		[dispatch],
	);

	return <View {...reduxData} likeLogic={{checkJobIsLiked, dispatchLike, dispatchUnLike}} />;
};

// const ApplyJobController = () => <View {...data} />; // for testing
export default ApplyJobController;
