import * as numberFormat from '../../../../../utils/number';
import * as ICONS from '../../../../../components/icon/constants';

export const getContactTextLabel = (type, item) => {
  if (type !== 'tel') {
    return [item, null];
  }
  try {
    const phoneText = numberFormat.numberPhone({input: item});
    return [phoneText, null];
  } catch (error) {
    return [null, error];
  }
};

export const getIconName = (type) => {
  switch (type) {
    case 'tel':
      return ICONS.PHONE_CALL;
    case 'email':
      return ICONS.EMAIL_FILL;
    default:
      return ICONS.LINE;
  }
};

export const getLinkingUrl = (type) => {
  switch (type) {
    case 'tel':
      return 'tel:';
    case 'email':
      return 'mailto:';
    case 'line':
      return 'https://line.me/R/ti/p/';
    default:
      return null;
  }
};
