import {put, call} from 'redux-saga/effects';
import * as api from './api';
import * as TYPES from './type';

export function* listApplyJob({payload}) {
  try {
    const response = yield call(api.listApplyJob, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.LIST_APPLY_JOB_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.LIST_APPLY_JOB_FAIL,
      payload: responseError,
    });
  }
}

export function* listApplyJobLike({payload}) {
  try {
    const response = yield call(api.listApplyJobLike, payload);
    if (response.status === 200) {
      yield put({
        type: TYPES.LIST_APPLY_JOB_LIKE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // do nothing
  }
}


export function* listApplyJobUnlike({payload}) {
  try {
    const response = yield call(api.listApplyJobUnlike, payload);
    if (response.status === 200) {
      yield put({
        type: TYPES.LIST_APPLY_JOB_UNLIKE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // do nothing
  }
}