export const REGISTER_STEP1_UPDATE_FORM_REQ = '@REGISTER/STEP1_UPDATE_FORM_REQ';
export const REGISTER_STEP1_UPDATE_FORM_SUCCESS = '@REGISTER/STEP1_UPDATE_FORM_SUCCESS';
export const REGISTER_STEP1_UPDATE_FORM_FAIL = '@REGISTER/STEP1_UPDATE_FORM_FAIL';
export const REGISTER_STEP1_CLEAR = '@REGISTER/STEP1_CLEAR';
export const REGISTER_STEP1_VALIDATE_EMAIL_REQ = '@REGISTER/STEP1_VALIDATE_EMAIL_REQ';
export const REGISTER_STEP1_VALIDATE_EMAIL_SUCCESS = '@REGISTER/STEP1_VALIDATE_EMAIL_SUCCESS';
export const REGISTER_STEP1_VALIDATE_EMAIL_FAIL = '@REGISTER/STEP1_VALIDATE_EMAIL_FAIL';
export const REGISTER_STEP1_VALIDATE_EMAIL_CLEAR = '@REGISTER/STEP1_VALIDATE_EMAIL_CLEAR';

export const REGISTER_STEP2_UPDATE_FORM_REQ = '@REGISTER/STEP2_UPDATE_FORM_REQ';
export const REGISTER_STEP2_UPDATE_FORM_SUCCESS = '@REGISTER/STEP2_UPDATE_FORM_SUCCESS';
export const REGISTER_STEP2_UPDATE_FORM_FAIL = '@REGISTER/STEP2_UPDATE_FORM_FAIL';
export const REGISTER_STEP2_CLEAR = '@REGISTER/STEP2_CLEAR';

export const REGISTER_NEXT_STEP_REQ = '@REGISTER/NEXT_STEP_REQ';
export const REGISTER_NEXT_STEP_SUCCESS = '@REGISTER/NEXT_STEP_SUCCESS';
export const REGISTER_NEXT_STEP_CLEAR = '@REGISTER/NEXT_STEP_CLEAR';

export const REGISTER_VISIBLE_STEP_REQ = '@REGISTER/VISIBLE_STEP_REQ';
export const REGISTER_VISIBLE_STEP_SUCCESS = '@REGISTER/VISIBLE_STEP_SUCCESS';
export const REGISTER_VISIBLE_STEP_FAIL = '@REGISTER/VISIBLE_STEP_FAIL';

export const REGISTER_OTP_REQ = '@REGISTER/OTP_REQ';
export const REGISTER_OTP_SUCCESS = '@REGISTER/OTP_SUCCESS';
export const REGISTER_OTP_FAIL = '@REGISTER/OTP_FAIL';

export const REGISTER_OTP_REQUEST_REQ = '@REGISTER/OTP_REQUEST_REQ';
export const REGISTER_OTP_REQUEST_SUCCESS = '@REGISTER/OTP_REQUEST_SUCCESS';
export const REGISTER_OTP_REQUEST_FAIL = '@REGISTER/OTP_REQUEST_FAIL';
export const REGISTER_OTP_RESEND_REQ = '@REGISTER/OTP_RESEND_REQ';
export const REGISTER_OTP_RESEND_SUCCESS = '@REGISTER/OTP_RESEND_SUCCESS';
export const REGISTER_OTP_RESEND_FAIL = '@REGISTER/OTP_RESEND_FAIL';
export const REGISTER_OTP_CHANGE_PHONE_REQ = '@REGISTER/OTP_CHANGE_PHONE_REQ';
export const REGISTER_OTP_CHANGE_PHONE_SUCCESS = '@REGISTER/OTP_CHANGE_PHONE_SUCCESS';
export const REGISTER_OTP_CHANGE_PHONE_FAIL = '@REGISTER/OTP_CHANGE_PHONE_FAIL';
export const REGISTER_OTP_STEP_CLEAR = '@REGISTER/OTP_STEP_CLEAR';
export const REGISTER_OTP_CONFIRM_REQ = '@REGISTER/OTP_CONFIRM_REQ';
export const REGISTER_OTP_CONFIRM_SUCCESS = '@REGISTER/OTP_CONFIRM_SUCCESS';
export const REGISTER_OTP_CONFIRM_FAIL = '@REGISTER/OTP_CONFIRM_FAIL';

export const REGISTER_MANUAL_REQ = '@REGISTER/MANUAL_REQ';
export const REGISTER_MANUAL_SUCCESS = '@REGISTER/MANUAL_SUCCESS';
export const REGISTER_MANUAL_FAIL = '@REGISTER/MANUAL_FAIL';

export const REGISTER_FACEBOOK_REQ = '@REGISTER/FACEBOOK_REQ';
export const REGISTER_FACEBOOK_SUCCESS = '@REGISTER/FACEBOOK_SUCCESS';
export const REGISTER_FACEBOOK_FAIL = '@REGISTER/FACEBOOK_FAIL';

export const REGISTER_GENERATE_ID_REQ = '@REGISTER/REGISTER_GENERATE_ID_REQ';
export const REGISTER_GENERATE_ID_SUCCESS = '@REGISTER/REGISTER_GENERATE_ID_SUCCESS';
export const REGISTER_GENERATE_ID_FAIL = '@REGISTER/REGISTER_GENERATE_ID_FAIL';
export const REGISTER_GENERATE_ID_CLEAR = '@REGISTER/REGISTER_GENERATE_ID_CLEAR';

export const REGISTER_CLEAR_FORM_REQ = '@REGISTER/CLEAR_FORM_REQ';
export const REGISTER_CLEAR_FORM_SUCCESS = '@REGISTER/CLEAR_FORM_SUCCESS';

export const REGISTER_INTEREST_REQ = '@REGISTER/INTEREST_REQ';
export const REGISTER_INTEREST_SUCCESS = '@REGISTER/INTEREST_SUCCESS';
export const REGISTER_INTEREST_CLEAR = '@REGISTER/INTEREST_CLEAR';
