import {put, call} from 'redux-saga/effects';
import {getErrorMessage} from '../utils/error-store';
import * as TYPES from './type';
import * as API from './api';
import * as sagaAlert from '../utils/saga-alert';

export function* updateJobIndexDataAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.SEO_UPDATE_JOB_INDEX_CLEAR,
      payload: payload.data,
    });
    return;
  }
  yield put({
    type: TYPES.SEO_UPDATE_JOB_INDEX_SUCCESS,
    payload: payload.data,
  });
}

export function* updateCompanyIndexDataAction({payload}) {
  try {
    const response = yield call(API.seoGetCompanyIndex, payload?.data);
    yield put({
      type: TYPES.SEO_UPDATE_COMPANY_INDEX_SUCCESS,
      payload: {
        data: response.data,
        alphabet: payload?.data?.alphabet,
      },
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const message = getErrorMessage(responseError);
    yield put({
      type: TYPES.SEO_UPDATE_COMPANY_INDEX_FAIL,
      payload: {
        error: responseError,
        message,
      },
    });
    sagaAlert.sagaAlertError(message);
  }
}

export function* updateCompanyIndexKeyAction({payload}) {
  yield put({
    type: TYPES.SEO_UPDATE_COMPANY_INDEX_KEY_SUCCESS,
    payload: payload.data,
  });
}
