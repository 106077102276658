import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useUserState, useUserDispatch} from '../../../../lib/hooks-logic/useUser';
import {FormWrapper, FormCenter, OverrideStyles} from '../styled-component';
import {SectionItem, OverrideStyles as InnerStyles} from './styled-component';
import Form from '../../../../components/form';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Divider from '../../../../components/divider';
import Loading from '../../../../components/loading';
import Header from '../../../../components/header';
import OptionBuilder from '../../../../views/option-builder/new-version';
import ButtonIcon from '../../../../components/button-icon';
import SchoolAutoComplete from './auto-complete';
import * as ICONS from '../../../../components/icon/constants';
import * as initData from './init-data';
import * as typeDef from '../../../../utils/strong-types';
import * as logic from './logic';
import * as validate from './validate';
import * as snackbar from '../../../../utils/components/toast-notification/provider';
import * as coreUser from '../../../../lib/context/user';

const EditEducationForm = () => {
  const {formatMessage} = useIntl();
  const {userState, isLoadingState} = useUserState();
  const {dispatchUpdateProfile} = useUserDispatch();

  const [yearData] = useState(initData.yearData());

  const [formEducationsState, setFormEducationsState] = useState();
  const [errorTextState, setErrorTextState] = useState([]);

  useEffect(() => {
    if (!initData.branchData || !initData.graduationData) {
      return;
    }
    if (userState?.educations && typeDef.isNullOrUndefined(formEducationsState)) {
      setFormEducationsState(() => logic.mapEducations(userState.educations));
    }
  }, [userState?.educations, formEducationsState, initData.branchData, initData.graduationData]);

  const onSubmit = () => {
    const [isValidate, errorList] = validate.validateAllRow(formEducationsState);
    if (typeDef.isArrayLength(errorList) && !isValidate) {
      setErrorTextState(errorList);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรอกข้อมูลให้ครบให้ครบ หรือลบออก'}), 'warning');
      return;
    }
    if (!userState) {
      return;
    }
    const userGeneral = coreUser.getGeneralNewAge(userState.general);
    const formSubmit = {
      ...userState,
      ...userGeneral,
      educations: logic.transformEducation(formEducationsState),
    };
    dispatchUpdateProfile(formSubmit);
  };

  const resetError = () => {
    if (typeDef.isArrayLength(errorTextState)) {
      setErrorTextState([]);
    }
  };

  const onChangeText = (key, value, index) => {
    const copyForm = [...formEducationsState];
    copyForm[index][key] = value;
    setFormEducationsState(copyForm);
    resetError();
  };

  const onChangeEduLevel = (value, index) => {
    const copyForm = [...formEducationsState];
    copyForm[index].level = value;
    copyForm[index].branch = null;
    setFormEducationsState(copyForm);
  };

  const onChangeEduYear = (value, index) => {
    const copyForm = [...formEducationsState];
    copyForm[index].year = value;
    setFormEducationsState(copyForm);
    resetError();
  };

  const onAddRow = () => {
    if (formEducationsState?.length === 0) {
      const copyForm = [...formEducationsState, {...initData.rowEducation}];
      setFormEducationsState(copyForm);
    }
    const [isValidate, errorList] = validate.validateAllRow(formEducationsState);
    if (typeDef.isArrayLength(errorList) && !isValidate) {
      setErrorTextState(errorList);
      snackbar.setSnackbarMessage(formatMessage({defaultMessage: 'กรอกข้อมูลให้ครบให้ครบ หรือลบออก'}), 'warning');
      return;
    }
    const copyForm = [...formEducationsState, {...initData.rowEducation}];
    setFormEducationsState(copyForm);
  };

  const onDelRow = (index) => {
    const copyList = [...formEducationsState];
    copyList.splice(index, 1);
    setFormEducationsState(copyList);
    resetError();
  };

  return (
    <FormCenter>
      <FormWrapper>
        <Loading type="data" loading={isLoadingState && !userState} />
        <Loading type="action" loading={isLoadingState && !!userState} />
        {userState && typeDef.isNotArrayLength(formEducationsState) && (
          <Header center variant="h3" style={InnerStyles.emptyHeader}>
            {formatMessage({defaultMessage: '-ยังไม่มีข้อมูล-'})}
          </Header>
        )}
        {userState && (
          <Form formId="edit-edu" onSubmit={() => {}}>
            {typeDef.isArrayLength(formEducationsState) &&
              formEducationsState.map((item, index) => (
                <SectionItem key={index.toString()}>
                  <OptionBuilder
                    name="op-edu-level"
                    label={formatMessage({defaultMessage: 'ระดับการศึกษา'})}
                    data={[...initData.graduationData] || []}
                    value={item.level}
                    onChange={(value) => onChangeEduLevel(value, index)}
                  />
                  {item.level.value !== 'ไม่ระบุ' && (
                    <>
                      <SchoolAutoComplete
                        name="school"
                        fillBackground
                        type="text"
                        label={formatMessage({defaultMessage: 'ชื่อสถานศึกษา'})}
                        placeholder={formatMessage({defaultMessage: 'กรอกชื่อสถานศึกษา'})}
                        value={item.school}
                        onChange={(value) => onChangeText('school', value, index)}
                        warningText={errorTextState[index]?.school}
                      />
                      {item.level.value !== 'ม.3' && item.level.value !== 'ม.6' && (
                        <>
                          {item.level.value !== 'ปวช.' && item.value !== 'ปวส.' && (
                            <Input
                              name="faculty"
                              fillBackground
                              type="text"
                              label={formatMessage({defaultMessage: 'คณะ'})}
                              placeholder={formatMessage({defaultMessage: 'กรอกชื่อคณะ'})}
                              value={item.faculty || ''}
                              onChange={(value) => onChangeText('faculty', value, index)}
                              warningText={errorTextState[index]?.faculty}
                            />
                          )}
                          <Input
                            name="branch"
                            fillBackground
                            type="text"
                            label={formatMessage({defaultMessage: 'สาขาวิชา'})}
                            placeholder={formatMessage({defaultMessage: 'กรอกชื่อสาขาวิชา'})}
                            value={item.branch || ''}
                            onChange={(value) => onChangeText('branch', value, index)}
                            warningText={errorTextState[index]?.branch}
                          />
                        </>
                      )}
                      <Input
                        name="grade"
                        fillBackground
                        type="text"
                        label={formatMessage({defaultMessage: 'เกรดเฉลี่ย'})}
                        placeholder={formatMessage({defaultMessage: 'เกรดเฉลี่ย'})}
                        value={item.grade}
                        onChange={(value) => onChangeText('grade', value, index)}
                        warningText={errorTextState[index]?.grade}
                      />
                      <OptionBuilder
                        name="op-edu-year"
                        label={formatMessage({defaultMessage: 'ปีที่จบ'})}
                        placeholder={formatMessage({defaultMessage: 'เลือกปีที่จบ'})}
                        data={yearData || []}
                        value={item.year}
                        onChange={(value) => onChangeEduYear(value, index)}
                      />
                    </>
                  )}
                  <Button small edgeInsets="none" variant="danger" onClick={() => onDelRow(index)}>
                    {formatMessage({defaultMessage: 'ลบแถว'})}
                  </Button>
                  <Divider bold edgeInsets />
                </SectionItem>
              ))}
            <ButtonIcon small variant="success" edgeInsets="none" iconName={ICONS.PLUS} onClick={onAddRow}>
              {formatMessage({defaultMessage: 'เพิ่มประวัติการศึกษา'})}
            </ButtonIcon>
            <Button block type="button" style={OverrideStyles.buttonSubmit} onClick={onSubmit}>
              {formatMessage({defaultMessage: 'บันทึกข้อมูล'})}
            </Button>
          </Form>
        )}
      </FormWrapper>
    </FormCenter>
  );
};

EditEducationForm.propTypes = {};

export default EditEducationForm;
