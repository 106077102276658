import React from 'react';
import {useIntl} from 'react-intl';
import {useAuth} from '../../lib/hooks/logic/useAuth';
import {translate} from '../../res/language/translate';
import {useLandingGroupSliderEffect} from '../../lib/hooks/logic/useLandingGroup';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import {Wrapper, ScreenWrapper, LayoutWrapper} from './styled-component';
import GroupSliderBuilder from '../../views/group-slider-builder';
import Screen from '../../views/screen';
import MetaTags from '../../components/meta-tag';
import Loading from '../../components/loading';

const LandingListScreen = () => {
  useSetGrayBackground();

  const {formatMessage} = useIntl();
  const {loggedInState, isCheckedAuth} = useAuth();
  const isLoggedInState = loggedInState && isCheckedAuth;

  const {groupListState, isLoadingState} = useLandingGroupSliderEffect();

  return (
    <>
      <MetaTags title={translate('meta.main')} description={translate('meta.main')} />
      <Screen type="pure" noEdgeInsets isChildScreen title={formatMessage({defaultMessage: 'กลุ่มงานทั้งหมด'})}>
        <ScreenWrapper>
          <LayoutWrapper>
            <Wrapper isLoggedIn={isLoggedInState}>
              <GroupSliderBuilder loggedInLayout as="raw" data={groupListState} />
              <Loading type="data" loading={isLoadingState} />
            </Wrapper>
          </LayoutWrapper>
        </ScreenWrapper>
      </Screen>
    </>
  );
};

LandingListScreen.propTypes = {};

export default LandingListScreen;
