import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import JobCatData from '../../res/json/job-cat.json';
import {translate} from '../../res/language/translate';
import * as typeDef from '../../utils/strong-types';
import * as deepState from '../../utils/deep-state';
import * as number from '../../utils/number';
import * as generate from '../../utils/generate';

const provinceDistricts = ['กรุงเทพมหานคร'];

export const checkHasDistrict = (targetProvince) => {
  return provinceDistricts.findIndex((elem) => elem === targetProvince) > -1;
};

export const createProvincesData = ({rawProvinces}) => {
  if (typeDef.isNotArrayLength(rawProvinces)) {
    return [];
  }
  const provinces = [];
  rawProvinces.forEach((element) => {
    const item = {
      id: generate.generateId('PV'),
      label: element,
      value: element,
      sub: [],
    };
    provinces.push(item);
  });
  return provinces;
};

export const createDistrictsData = ({rawDistricts, provinceId}) => {
  if (typeDef.isNotArrayLength(rawDistricts)) {
    return [];
  }
  const districts = [];
  rawDistricts.forEach((element) => {
    const startIndex = element.indexOf('|');
    const item = {
      id: generate.generateId('DT'),
      label: element.substr(startIndex + 1, element.length),
      value: element,
      parentId: provinceId,
    };
    districts.push(item);
  });
  return districts;
};

export const mergeDistrictsToProvincesData = ({provinces, provinceId, districts}) => {
  if (typeDef.isNotArrayLength(provinces) || typeDef.isNotArrayLength(districts) || typeDef.isNullOrUndefined(provinceId)) {
    return provinces;
  }
  const copyProvinces = deepState.cloneDeepState({data: provinces});
  const targetProvinceIndex = findIndex(copyProvinces, (x) => x.id === provinceId);
  if (targetProvinceIndex < 0) {
    return [];
  }
  copyProvinces[targetProvinceIndex].sub = districts;
  return copyProvinces;
};

export const checkHasDistricts = ({targetProvinceIndex, provinces}) => {
  if (typeDef.isNotArrayLength(targetProvinceIndex) || typeDef.isNotArrayLength(provinces)) {
    return false;
  }
  return typeDef.isArrayLength(provinces[targetProvinceIndex]?.sub);
};

export const setIsExpanded = ({expandsItem, rowIndex}) => {
  if (typeDef.isNotArrayLength(expandsItem)) {
    return [{rowIndex}];
  }
  const newExpandsItem = deepState.cloneDeepState({data: expandsItem});
  const findIsExpanded = find(expandsItem, (x) => x.rowIndex === rowIndex);
  if (typeDef.isNullOrUndefined(findIsExpanded)) {
    return [...newExpandsItem, {rowIndex}];
  }
  return filter(newExpandsItem, (x) => x.rowIndex !== rowIndex);
};

export const switchProvinceData = ({rawProvinces, targetProvince, targetProvinceIndex, isOutAll}) => {
  if (typeDef.isNotArrayLength(rawProvinces)) {
    return [];
  }
  const provinces = [];
  rawProvinces.forEach((element) => {
    const id = generate.generateId('PV');
    const item = {
      id,
      label: element,
      value: element,
      sub: [],
    };
    provinces.push(item);
  });
  if (typeDef.isNullOrUndefined(targetProvince)) {
    return provinces;
  }
  const index = typeDef.isNumber(targetProvinceIndex) ? targetProvinceIndex : 0;
  provinces.splice(index, 0, {
    id: generate.generateId('PV'),
    label: targetProvince,
    value: targetProvince,
    sub: [],
  });
  if (!isOutAll) {
    provinces.splice(0, 0, {
      id: generate.generateId('PV'),
      label: 'ทุกจังหวัด',
      value: 'ทุกจังหวัด',
    });
  }
  return uniqBy(provinces, (x) => x.label);
};

export const mapPopularJobCat = ({listJobCat, countJobCat}) => {
  if (typeDef.isNotArrayLength(countJobCat)) {
    return listJobCat;
  }
  const newJobCats = [];
  const keyCountJobCat = keyBy(countJobCat, '_id');
  listJobCat.forEach((element) => {
    const matchItem = keyCountJobCat[element.value];
    const item = {
      ...element,
      total: matchItem ? `(${number.numberWithComma({input: matchItem.count})})` : '(0)',
    };
    newJobCats.push(item);
  });
  return newJobCats;
};
export const createMockAllJobCat = ({countJobCat}) => {
  const mockJobs = JobCatData;
  const shapeJobs = Object.entries(mockJobs);
  const newJobCats = [];
  const keyCountJobCat = keyBy(countJobCat, '_id');
  shapeJobs.forEach((element, index) => {
    const matchItem = keyCountJobCat[element[0]];
    const item = {
      id: index,
      label: element[0],
      value: element[0],
      total: matchItem ? `(${number.numberWithComma({input: matchItem.count})})` : '(0)',
    };
    newJobCats.push(item);
  });
  newJobCats.push({
    id: 777,
    label: translate('landing.search.seeLess'),
    value: translate('landing.search.seeLess'),
  });

  return newJobCats;
};

export const transformToQuery = ({jobCats, location, defaultLocation}) => {
  const query = {
    jobcat: [],
    subjobcat: [],
  };
  if (typeDef.isArrayLength(jobCats)) {
    jobCats.forEach((element) => {
      if (element.level === 1) {
        query.jobcat.push(element.value);
      } else {
        query.subjobcat.push(element.value);
      }
    });
  }
  if (typeDef.isArrayLength(location)) {
    if (location[0].level === 1) {
      query.province = location[0].value;
    } else {
      query.district = location.map((x) => x.value);
    }
  } else if (defaultLocation[0]?.value?.includes('|')) {
    query.district = defaultLocation[0]?.value;
  } else {
    query.province = defaultLocation[0]?.value || 'กรุงเทพมหานคร';
  }
  if (typeDef.isNotArrayLength(query.jobcat)) {
    delete query.jobcat;
  }
  if (typeDef.isNotArrayLength(query.subjobcat)) {
    delete query.subjobcat;
  }

  return query;
};

export const findProvinceData = ({province, provinceId}) => {
  if (typeDef.isNotArrayLength(province) || !provinceId) {
    return null;
  }
  const provinceItem = province.find((x) => x.id === provinceId);
  return provinceItem;
};

export const creatInitLocations = ({location, nearest}) => {
  try {
    if (typeDef.isNotArrayLength(location) && typeDef.isNotArrayLength(nearest)) {
      return [];
    }
    const provinceIndex = location.findIndex((x) => x.value === nearest);
    if (typeDef.isNullOrUndefined(provinceIndex)) {
      return [];
    }
    const provinceData = location.find((x) => x.value === nearest);
    const response = {};
    response.id = provinceData.id;
    response.label = provinceData.label;
    response.value = provinceData.value;
    response.sub = [];
    return [response];
  } catch (error) {
    return [];
  }
};

export const createInitLocationAllProvince = (location) => {
  if (!Array.isArray(location)) {
    return [];
  }
  const response = {};
  response.id = location[0].id;
  response.label = location[0].label;
  response.value = location[0].value;
  return [response];
};

export const checkIsSelectedById = ({id, selectedOptions}) => {
  if (!id || typeDef.isNotArrayLength(selectedOptions)) {
    return false;
  }
  const isSelected = selectedOptions.find((x) => x.id === id);
  return !!isSelected;
};

export const checkIsSelectedByValue = ({value, selectedOptions}) => {
  if (!value || typeDef.isNotArrayLength(selectedOptions)) {
    return false;
  }
  const isSelected = selectedOptions.find((x) => x.value === value);
  return !!isSelected;
};
