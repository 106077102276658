import * as numbers from '../../../utils/number';
import * as ROUTES from '../../../routes/routes-name';

export const getLabelCounter = (total, alphabet, isLoading) => {
  if (isLoading) {
    return '';
  }
  const prefix = alphabet || '';
  if (!total) {
    return `${prefix.toUpperCase()} (ไม่พบข้อมูลชื่อบริษัท ${prefix.toUpperCase()})`;
  }
  return `${prefix.toUpperCase()} (ทั้งหมด ${numbers.numberWithComma({input: total})} บริษัท)`;
};

export const getActiveAlphabet = (pathname, alphabet, type, query) => {
  if (!pathname) {
    return false;
  }
  if (!type) {
    return alphabet === 'ก' && !query;
  }
  if (type === 'th') {
    if ((pathname === ROUTES.ROUTE_COMPANY_INDEX || pathname === ROUTES.ROUTE_COMPANY_INDEX_TH) && alphabet === 'ก' && !query) {
      return true;
    }
    return alphabet === query;
  }
  if (type === 'en') {
    if (pathname === ROUTES.ROUTE_COMPANY_INDEX_EN && alphabet === 'a' && !query) {
      return true;
    }
    return alphabet === query;
  }
  if (type === 'number') {
    if (pathname === ROUTES.ROUTE_COMPANY_INDEX_NUMBER && alphabet === '0-9' && query) {
      return true;
    }
    return false;
  }
  return false;
};
