import {useDispatch} from 'react-redux';
import ENV from '../../../env.json';
import * as routes from '../../../routes/routes-name';
import * as searchActions from '../../../redux/search/action';
import * as itrTypes from '../../../redux/interest/type';
import * as mixpanel from '../../../utils/mixpanel';

export const useShare = () => {
  const dispatch = useDispatch();

  const getGetShareById = (shareId) => {
    if (!shareId) {
      return ENV.PUBLIC.API_URL;
    }
    return `${window.location.origin}${routes.ROUTE_SEARCH_BY_ID}?id=${shareId}`;
  };

  const dispatchInterest = (shareId, job, type) => {
    if (!shareId) {
      return;
    }
    dispatch(
      searchActions.searchInterestDebounce({
        data: {
          _id: shareId,
          type: itrTypes.INTEREST_SHARE,
        },
      }),
    );
    if (job && type && mixpanel[type]) mixpanel[type](job);
  };

  return {getGetShareById, dispatchInterest};
};
