import styled from 'styled-components';

export const ScreenWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const LayoutWrapper = styled.div`
  max-width: 1500px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 1rem;
  margin-bottom: -1rem;
  @media (max-width: 1024px) {
    ${(props) => props.isLoggedIn && `margin: 4.5rem 0 3.75rem 0;`};
  }
  @media (min-width: 1025px) {
    ${(props) => !props.isLoggedIn && `margin-top: -2.5rem;`};
  }
`;
