import React from 'react';
import PropTypes from 'prop-types';
import * as ICON from './constants';
import IconTheme from '../../styles/icon';
import {
  IconLoad,
  IconHome,
  IconCheckbox,
  IconUnCheckbox,
  IconMenu,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconClose,
  IconSearch,
  IconLocation,
  IconEmailFill,
  IconPassFill,
  IconPassSee,
  IconPassUnSee,
  IconCalendar,
  IconDocument,
  IconShield,
  IconBuilding,
  IconStar,
  IconDirection,
  IconLike,
  IconSendEmail,
  IconShare,
  IconContact,
  IconFacebook,
  IconMessenger,
  IconCopy,
  IconPhoneCall,
  IconShareOther,
  IconGraduation,
  IconTime,
  IconDollar,
  IconLikeOutline,
  IconBell,
  IconBellOutline,
  IconSettings,
  IconHelp,
  IconLogout,
  IconCloseSquare,
  IconCamera,
  IconEditSquare,
  IconUserCircle,
  IconBusiness,
  IconMedal,
  IconUpload,
  IconPlus,
  IconMinus,
  IconBookMark,
  IconBookMarkFill,
  IconInfoFill,
  IconWorld,
  IconTravelBus,
  IconCheckCircle,
  IconCloseCircle,
  IconTitle,
  IconAttachment,
  IconCloseOutline,
  IconFilter,
  IconFacebookSquare,
  IconBookMarkStar,
  IconCertificate,
  IconGroup,
  IconNotificationImportant,
  IconNotifications,
  IconNotificationsActive,
  IconMoreHorizontal,
  IconTrash,
} from './styled-component';

const IconGenerator = (props) => {
  const {name, color, size} = props;
  switch (name) {
    case ICON.TRASH:
      return <IconTrash color={color} size={size} />;
    case ICON.MORE_HORIZONTAL:
      return <IconMoreHorizontal color={color} size={size} />;
    case ICON.GROUP:
      return <IconGroup color={color} size={size} />;
    case ICON.CERTIFICATE:
      return <IconCertificate color={color} size={size} />;
    case ICON.BOOKMARK_STAR:
      return <IconBookMarkStar color={color} size={size} />;
    case ICON.FACEBOOK_LOGIN:
      return <IconFacebookSquare color={color} size={size} />;
    case ICON.FILTER:
      return <IconFilter color={color} size={size} />;
    case ICON.TRAVEL_BUS:
      return <IconTravelBus color={color} size={size} />;
    case ICON.WORLD:
      return <IconWorld color={color} size={size} />;
    case ICON.INFO_FILL:
      return <IconInfoFill color={color} size={size} />;
    case ICON.BOOKMARK:
      return <IconBookMark color={color} size={size} />;
    case ICON.BOOKMARK_FILL:
      return <IconBookMarkFill color={color} size={size} />;
    case ICON.PLUS:
      return <IconPlus color={color} size={size} />;
    case ICON.MINUS:
      return <IconMinus color={color} size={size} />;
    case ICON.UPLOAD:
      return <IconUpload color={color} size={size} />;
    case ICON.ACHIEVEMENT:
      return <IconMedal color={color} size={size} />;
    case ICON.BAG_BUSINESS:
      return <IconBusiness color={color} size={size} />;
    case ICON.USER:
      return <IconUserCircle color={color} size={size} />;
    case ICON.EDIT_SQUARE:
      return <IconEditSquare color={color} size={size} />;
    case ICON.CAMERA:
      return <IconCamera color={color} size={size} />;
    case ICON.CLOSE_SQUARE:
      return <IconCloseSquare color={color} size={size} />;
    case ICON.HELP:
      return <IconHelp color={color} size={size} />;
    case ICON.LOCKOUT:
      return <IconLogout color={color} size={size} />;
    case ICON.SETTING:
      return <IconSettings color={color} size={size} />;
    case ICON.LIKE_OUTLINE:
      return <IconLikeOutline color={color} size={size} />;
    case ICON.BELL:
      return <IconBell color={color} size={size} />;
    case ICON.BELL_FILL:
      return <IconBellOutline color={color} size={size} />;
    case ICON.DOLLAR:
      return <IconDollar color={color} size={size} />;
    case ICON.EXPERIENCE:
      return <IconTime color={color} size={size} />;
    case ICON.EDUCATION:
      return <IconGraduation color={color} size={size} />;
    case ICON.SHARE_OTHER:
      return <IconShareOther color={color} size={size} />;
    case ICON.PHONE_CALL:
      return <IconPhoneCall color={color} size={size} />;
    case ICON.COPY:
      return <IconCopy color={color} size={size} />;
    case ICON.FACEBOOK_SHARE:
      return <IconFacebook color={color} size={size} />;
    case ICON.MESSENGER_SHARE:
      return <IconMessenger color={color} size={size} />;
    case ICON.SHARE:
      return <IconShare color={color} size={size} />;
    case ICON.CONTACT:
      return <IconContact color={color} size={size} />;
    case ICON.SEND_EMAIL:
      return <IconSendEmail color={color} size={size} />;
    case ICON.LIKE:
      return <IconLike color={color} size={size} />;
    case ICON.CALENDAR:
      return <IconCalendar color={color} size={size} />;
    case ICON.LOADING:
      return <IconLoad color={color} size={size} />;
    case ICON.HOME:
      return <IconHome color={color} size={size} />;
    case ICON.CHECKED_CHECKBOX:
      return <IconCheckbox color={color} size={size} />;
    case ICON.UN_CHECKBOX:
      return <IconUnCheckbox color={color} size={size} />;
    case ICON.ARROW_DOWN:
      return <IconArrowDown color={color} size={size} />;
    case ICON.ARROW_LEFT:
      return <IconArrowLeft color={color} size={size} />;
    case ICON.ARROW_RIGHT:
      return <IconArrowRight color={color} size={size} />;
    case ICON.ARROW_UP:
      return <IconArrowUp color={color} size={size} />;
    case ICON.MENU:
      return <IconMenu color={color} size={size} />;
    case ICON.CLOSE:
      return <IconClose color={color} size={size} />;
    case ICON.SEARCH:
      return <IconSearch color={color} size={size} />;
    case ICON.LOCATION:
      return <IconLocation color={color} size={size} />;
    case ICON.EMAIL_FILL:
      return <IconEmailFill color={color} size={size} />;
    case ICON.PASSWORD_FILL:
      return <IconPassFill color={color} size={size} />;
    case ICON.PASSWORD_SEE:
      return <IconPassSee color={color} size={size} />;
    case ICON.PASSWORD_UN_SEE:
      return <IconPassUnSee color={color} size={size} />;
    case ICON.DOCUMENTS:
      return <IconDocument color={color} size={size} />;
    case ICON.USER_VERIFY:
      return <IconShield color={color} size={size} />;
    case ICON.BUILDING:
      return <IconBuilding color={color} size={size} />;
    case ICON.STAR:
      return <IconStar color={color} size={size} />;
    case ICON.DIRECTION:
      return <IconDirection color={color} size={size} />;
    case ICON.CHECK_CIRCLE:
      return <IconCheckCircle color={color} size={size} />;
    case ICON.CLOSE_CIRCLE:
      return <IconCloseCircle color={color} size={size} />;
    case ICON.TITLE:
      return <IconTitle color={color} size={size} />;
    case ICON.ATTACHMENT:
      return <IconAttachment color={color} size={size} />;
    case ICON.CLOSE_OUTLINE:
      return <IconCloseOutline color={color} size={size} />;
    case ICON.NOTIFICATION:
      return <IconNotifications color={color} size={size} />;
    case ICON.NOTIFICATION_IMPORTANT:
      return <IconNotificationImportant color={color} size={size} />;
    case ICON.NOTIFICATION_ACTIVE:
      return <IconNotificationsActive color={color} size={size} />;
    default:
      return null;
  }
};

IconGenerator.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

IconGenerator.defaultProps = {
  size: IconTheme.size.webDefault,
  color: '',
};

export default IconGenerator;
