import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import * as typeDef from '../../utils/strong-types';
import * as deepState from '../../utils/deep-state';

export const DEFAULT_LOCATION = 'กรุงเทพมหานคร';

export const smartLocation = ({locations}) => {
  if (typeDef.isNotArrayLength(locations)) {
    return null;
  }
  const mapNewLocation = map(locations, (x) => {
    const targetIndex = x.indexOf('|');
    const newTarget = x.substr(0, targetIndex);
    return newTarget;
  });
  if (typeDef.isNotArrayLength(mapNewLocation)) {
    if (typeDef.isArrayLength(locations)) {
      return locations[0];
    }
    return null;
  }
  const groupLocation = groupBy(mapNewLocation, (x) => x);
  const finalTarget = sortBy(groupLocation, (x) => [x].length);
  if (typeDef.isNotArrayLength(finalTarget)) {
    return null;
  }

  if (finalTarget[0][0]) {
    return finalTarget[0][0];
  }
  return locations[0];
};

export const browserLocation = () => {
  if (!navigator.geolocation) {
    return null;
  }
  let geolocation;
  navigator.geolocation.getCurrentPosition((location) => {
    geolocation = location;
  });
  if (geolocation) {
    return {
      latitude: geolocation.coords.latitude,
      longitude: geolocation.coords.longitude,
    };
  }
  return null;
};

export const historyLocationProvince = ({history}) => {
  if (typeDef.isNotArrayLength(history)) {
    return DEFAULT_LOCATION;
  }
  const lastHistory = history[0];
  if (!lastHistory?.params) {
    return DEFAULT_LOCATION;
  }
  if (lastHistory.params.district) {
    if (typeDef.isArrayLength(lastHistory.params.district)) {
      const _province = lastHistory.params.district[0].split('|');
      return _province[0];
    }
    const _province = lastHistory.params.district.split('|');
    return _province[0];
  }
  return lastHistory.params.province || DEFAULT_LOCATION;
};

export const historyLocationDistrict = ({history}) => {
  if (typeDef.isNotArrayLength(history)) {
    return null;
  }
  const lastHistory = history[history.length - 1];
  if (!lastHistory?.params?.district) {
    return null;
  }
  if (typeDef.isArrayLength(lastHistory.params.district)) {
    const district = map(lastHistory.params.district, (x) => {
      const _district = x.split('|');
      return _district[1];
    });
    return district;
  }
  const _district = lastHistory.params.district.split('|');
  return _district[1];
};

export const mapSelectedLocation = ({selectedOptions, newItems}) => {
  const {parentItem, childItem} = newItems;
  const newOptions = deepState.cloneDeepState({data: selectedOptions});
  if (parentItem && !childItem) {
    return [{...parentItem}];
  }
  const hasValue = !!newOptions.find((x) => x.id === childItem.id);
  if (hasValue) {
    const filterOutValue = newOptions.filter((x) => x.id !== childItem.id);
    return filterOutValue;
  }
  const filterOut = newOptions.filter((x) => !!x.parentId && x.parentId === childItem.parentId);
  const mapSelected = [...filterOut, {...childItem}];
  return mapSelected;
};

export const mapSelectedLabel = ({selectedOptions}) => {
  if (typeDef.isNotArrayLength(selectedOptions)) {
    return '';
  }
  const mapValues = selectedOptions.map((x) => x.value);
  if (mapValues.length === 1) {
    return mapValues.join('');
  }
  const newMapValues = mapValues.map((x) => {
    const splitText = x.split('|')[1];
    if (!splitText) {
      return x;
    }
    return splitText;
  });
  return newMapValues.join(', ');
};
