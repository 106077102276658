import React from 'react';
import PropTypes from 'prop-types';
import {NavSideContainer, NavSideContent} from './styled-component';

const SideBar = (props) => {
  const {children} = props;
  return (
    <NavSideContainer>
      <NavSideContent>{children}</NavSideContent>
    </NavSideContainer>
  );
};

SideBar.propTypes = {
  children: PropTypes.any,
};

SideBar.defaultProps = {
  children: null,
};

export default SideBar;
