import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {translate} from '../../../../res/language/translate';
import {useSearchHistoryDispatch} from '../../../../lib/hooks/logic/useSearchHistory';
import {HistoryText, OverrideStyles, HistoryItemWrapper, ButtonWrapper} from './styled-component';
import GestureDetector from '../../../../components/gesture';
import Row from '../../../../components/row';
import Icon from '../../../../components/icon';
import Link from '../../../../components/link';
import Button from '../../../../components/button';
import * as ICONS from '../../../../components/icon/constants';
import * as typeDef from '../../../../utils/strong-types';
import * as searchUtils from '../../../../lib/context/search';

const HistoryItemBuilder = (props) => {
  const {data, isPin} = props;
  const {dispatchAddPin, dispatchDelPin, dispatchDelHistory} = useSearchHistoryDispatch();
  const [renderDataState, setRenderDataState] = useState([]);
  const [isShowState, setIsShowState] = useState(false);

  useEffect(() => {
    if (!isPin && typeDef.isArrayLength(data) && data.length > 3) {
      if (isShowState) {
        setRenderDataState(data);
      }
      if (!isShowState) {
        const copyData = data.slice(0, 3);
        setRenderDataState(copyData);
      }
    } else {
      setRenderDataState(data);
    }
  }, [isPin, data, isShowState]);

  useEffect(() => {
    if (isPin) {
      setRenderDataState(data);
    }
  }, [isPin, data]);

  const onClickAddPin = (item) => {
    dispatchAddPin(item);
  };

  const onClickUnPin = (item) => {
    dispatchDelPin(item);
  };

  const onClickDel = (item) => {
    dispatchDelHistory(item);
  };

  const onClickShowLessMore = () => {
    setIsShowState(!isShowState);
  };

  if (typeDef.isNotArrayLength(renderDataState)) {
    return null;
  }

  return (
    <HistoryItemWrapper>
      {renderDataState.map((item, index) => (
        <Row key={index.toString()} wrap="nowrap" content="space-between" style={OverrideStyles.rowItem}>
          <Link url={searchUtils.createLinkSearch()} search={searchUtils.stringifyHistoryParams(item.params)}>
            <HistoryText>{item.label}</HistoryText>
          </Link>
          {!isPin && (
            <Row wrap="nowrap">
              <GestureDetector withEdgeInsets={false} onClick={() => onClickAddPin(item)}>
                <Icon name={ICONS.BOOKMARK} color={OverrideStyles.iconGray} size={24} />
              </GestureDetector>
              <GestureDetector withEdgeInsets={false} style={OverrideStyles.gestureEdgeInsets} onClick={() => onClickDel(item)}>
                <Icon name={ICONS.CLOSE} color={OverrideStyles.iconGray} size={24} />
              </GestureDetector>
            </Row>
          )}
          {isPin && (
            <GestureDetector withEdgeInsets={false} onClick={() => onClickUnPin(item)}>
              <Icon name={ICONS.BOOKMARK_FILL} color={OverrideStyles.iconFill} size={20} />
            </GestureDetector>
          )}
        </Row>
      ))}
      {!isPin && data?.length > 3 && (
        <>
          <ButtonWrapper>
            <Button block variant="light" onClick={onClickShowLessMore} style={OverrideStyles.buttonSee}>
              {isShowState ? translate('primaryBox.seeLessHistory') : translate('primaryBox.seeAllHistory')}
            </Button>
          </ButtonWrapper>
        </>
      )}
    </HistoryItemWrapper>
  );
};

HistoryItemBuilder.propTypes = {
  isPin: PropTypes.bool,
  data: PropTypes.array.isRequired,
};

HistoryItemBuilder.defaultProps = {
  isPin: false,
};

export default HistoryItemBuilder;
