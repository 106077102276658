import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextCounter = styled.span`
  padding-left: 0.5rem;
  color: ${(props) => props.theme.primary};
`;

export const OverrideStyles = {
  header: {
    margin: '0 0 0.5rem 0',
  },
  header2: {
    margin: '1rem 0',
  },
  row: {
    marginTop: '1rem',
  },
};
