import keyBy from 'lodash/keyBy';
import * as typeDef from '../../../../utils/strong-types';
import * as generate from '../../../../utils/generate';

export const mapStateToProvince = (provinceState) => {
  if (!provinceState) {
    return [];
  }
  const response = {
    id: generate.generateId('PV'),
    label: provinceState,
    value: provinceState,
  };
  return [response];
};

export const mapDefaultSingleValue = (listData, state) => {
  if (!state) {
    return listData[0];
  }
  const response = listData.find((elem) => elem.value === state);
  if (!response) {
    return listData[0];
  }
  return response;
};

export const mapDefaultMultiValue = (listData, state) => {
  if (typeDef.isNotArrayLength(state)) {
    return [];
  }
  const response = state.map((elem) => listData.find((item) => item.value === elem));
  return response.filter((elem) => elem);
};

export const mapDefaultSalary = (salaryMin, salaryMax) => {
  const response = {
    salaryMin: salaryMin ? `${salaryMin}` : '',
    salaryMax: salaryMax ? `${salaryMax}` : '',
  };
  return response;
};

export const mapDefaultJobCat = (listData, jobCat, subJobCat) => {
  if (typeDef.isNotArrayLength(jobCat) && typeDef.isNotArrayLength(subJobCat)) {
    return [];
  }

  const allSelect = [];
  const keyJobCat = keyBy(listData, 'value');
  if (typeDef.isArrayLength(jobCat)) {
    jobCat.forEach((elem) => {
      if (keyJobCat[elem]) {
        allSelect.push(keyJobCat[elem]);
      }
    });
  }
  if (typeDef.isArrayLength(subJobCat)) {
    subJobCat.forEach((elem) => {
      const splitParent = elem.split('|');
      const parentItems = keyJobCat[splitParent[0]];
      if (typeDef.isArrayLength(parentItems?.sub)) {
        const subItem = parentItems.sub.find((item) => item.value === elem);
        if (subItem) {
          allSelect.push(subItem);
        }
      }
    });
  }

  return allSelect;
};

export const mapDefaultJobPosition = (position) => {
  if (typeDef.isNotArrayLength(position)) {
    return [{value: ''}];
  }
  return position.map((elem) => ({value: elem.trim()}));
};
