import {put, call} from 'redux-saga/effects';
import * as api from './api';
import * as TYPES from './type';
import {getErrorMessage} from '../utils/error-store';

export function* companyDetailGetAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.COMPANY_CLEAR_DETAIL,
    });
    return;
  }
  try {
    const response = yield call(api.companyDetail, payload?.data);
    yield put({
      type: TYPES.COMPANY_GET_DETAIL_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const responseStatus = error?.response?.status || 400;
    yield put({
      type: TYPES.COMPANY_GET_DETAIL_FAIL,
      payload: {
        message: getErrorMessage(responseError),
        responseMessage: responseError,
        status: responseStatus,
      },
    });
  }
}
