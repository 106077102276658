import {createGlobalStyle} from 'styled-components';
import Prompt from '../res/assets/fonts/Prompt-Regular.ttf';
import PromptLight from '../res/assets/fonts/Prompt-Light.ttf';

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Prompt Regular';
    src: url(${Prompt});
  }
@font-face {
    font-family: 'Prompt Light';
    src: url(${PromptLight});
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
   height: 100%;
}
body {
    height: 100%;
    width: 100%;
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    overflow-y: auto;
    overflow-x: hidden !important;
  }
#root {
    height: 100%;
}
h1, h2, h3, h4 {
  font-family: 'Prompt Regular';
}
h5,h6 {
  font-family: 'Prompt Light';
}
span, p, a, input, label, div {
  font-family: 'Prompt Light';
  font-weight: 200;
}
button: {
  font-family: 'Prompt Regular';
}
`;
