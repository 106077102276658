import {put, call} from 'redux-saga/effects';
import {authorizeProvider} from '../utils/authorize';
import * as api from './api';
import * as TYPES from './type';

export function* hotCompanyFetchList({payload}) {
  try {
    const response = yield call(api.hotCompanyList, payload?.data);
    yield put({
      type: TYPES.HOT_COMPANY_GET_LIST_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.HOT_COMPANY_GET_LIST_FAIL,
        payload: responseError,
      });
    }
  }
}
