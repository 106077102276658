/* eslint-disable import/named */
import React, {useRef, useEffect} from 'react';
import {throttle, get} from 'lodash';
import {translate} from '../../res/language/translate';
import {useScrollYDirectionEffect, useScrollResetAfterPopEffect} from '../../lib/hooks/useScrollingEffect';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import {
  useLandingGroup,
  useLandingGroupDispatch,
  useLandingGroupCleanupEffect,
  useLandingGroupSearchFilterEffect,
} from '../../lib/hooks/logic/useLandingGroup';
import {useParamsEffect} from '../../lib/hooks/useSearchParams';
import {Container, CoverWrapper, ListWrapper, PostWrapper, OverrideStyles, ScreenWrapper, FakeAnnouncement} from './views/styled-component';
import ImageCover from './views/image-cover';
// import Announcement from './views/announcement';
import Filter from './views/filter';
import NavBar from './views/navbar';
import NavbarFilter from './views/filter/navbar-filter';
import MetaTags from '../../components/meta-tag';
import Loading from '../../components/loading';
import Header from '../../components/header';
import JobPostBuilder from '../../views/job-post-builder';
import HotListView from './views/hot-list';
import Screen404 from '../404';
import {isEndReached} from './logic';
import {parseJsonLd} from '../../lib/context/parse-jsonld';
import {useDescription} from './hooks/useDescription';

const FIX_SHOW_SCROLL_POS = -100;

const LandingGroupScreen = () => {
  useSetGrayBackground();
  useLandingGroupCleanupEffect();
  useLandingGroupSearchFilterEffect();

  const {paramsState} = useParamsEffect();
  const {scrollDirection} = useScrollYDirectionEffect();
  const nodeContainerRef = useRef(null);
  const {
    backdropColorsState: [colors, success],
    metaGroupState,
    stackFeedState,
    isEmptyDataState,
    isLoadingState,
    isLoadingRefreshState,
    errorState,
    hotListState,
  } = useLandingGroup();
  const {dispatchFetchEndReached, dispatchFetchRefresh} = useLandingGroupDispatch();

  const description = useDescription(stackFeedState);

  useScrollResetAfterPopEffect(!metaGroupState);

  const onEventScrolling = () => {
    if (!nodeContainerRef.current) {
      return;
    }
    const isEnded = isEndReached(nodeContainerRef, document.documentElement.offsetHeight);
    if (isEnded) {
      dispatchFetchEndReached(paramsState);
    }
  };

  useEffect(() => {
    const debounceScrolling = throttle(onEventScrolling, 400);
    window.addEventListener('scroll', debounceScrolling);
    return () => window.removeEventListener('scroll', debounceScrolling);
  }, [paramsState]);

  useEffect(() => {
    if (isLoadingRefreshState) {
      window.scrollTo({top: 0});
    }
  }, [isLoadingRefreshState]);

  const onEventRefresh = () => {
    dispatchFetchRefresh(isLoadingState, isLoadingRefreshState);
  };

  if (errorState === 'landing not found') {
    return <Screen404 />;
  }

  if (!metaGroupState) {
    return (
      <Container>
        <CoverWrapper defaultBackground={success === false} background={colors}>
          <ImageCover />
          <Header variant="h2" style={OverrideStyles.coverTitle}>
            ...
          </Header>
        </CoverWrapper>
      </Container>
    );
  }

  return (
    <>
      {metaGroupState && (
        <MetaTags
          title={metaGroupState.th_name + translate('meta.appName')}
          description={description}
          image={metaGroupState.cover_photo?.url}
          scriptLdJson={parseJsonLd(stackFeedState, 'search.landing-page')}
        />
      )}
      <ScreenWrapper ref={nodeContainerRef}>
        <Loading type="refresh" loading={isLoadingRefreshState} />
        <NavBar onEventRefresh={onEventRefresh} />
        <NavbarFilter isShow={scrollDirection.top || scrollDirection.scrollPos >= FIX_SHOW_SCROLL_POS} />
        <Container>
          <CoverWrapper defaultBackground={success === false} background={colors}>
            <ImageCover />
            <Header variant="h2" style={OverrideStyles.coverTitle}>
              {`${metaGroupState?.th_name}` || ''}
            </Header>
          </CoverWrapper>
          <ListWrapper>
            <Filter isNotEmpty={!!get(stackFeedState, '[0]._id')} />
            <PostWrapper>
              <FakeAnnouncement />
              <HotListView view="feed" data={hotListState} />
              {/* <Announcement /> */}
              <JobPostBuilder
                noTopEdgeInsets
                noBottomEdgeInsets
                data={stackFeedState}
                isEmptyData={isEmptyDataState}
                isLoadingFeed={isLoadingState}
                isLoadingRefresh={isLoadingRefreshState}
              />
            </PostWrapper>
            <HotListView view="banner" data={hotListState} />
          </ListWrapper>
        </Container>
      </ScreenWrapper>
    </>
  );
};

LandingGroupScreen.propTypes = {};

export default LandingGroupScreen;
