import * as ROUTES from '../../../routes/routes-name';

export const isRouteSearch = (pathname) => {
  return pathname === ROUTES.ROUTE_SEARCH;
};

export const isValidRoute = ({pathname}) => {
  switch (pathname) {
    case ROUTES.ROUTE_SEARCH_BY_ID:
    case ROUTES.ROUTE_SEARCH_RESULTS:
    case ROUTES.ROUTE_SEARCH_AUTHOR:
    case ROUTES.ROUTE_SEARCH_HASHTAG:
    case ROUTES.ROUTE_SEARCH:
    case ROUTES.ROUTE_MAIN:
    case ROUTES.ROUTE_CONTACT:
    case ROUTES.ROUTE_FAQ:
    case ROUTES.ROUTE_JOB_INDEX:
    case ROUTES.ROUTE_COMPANY_INDEX:
    case ROUTES.ROUTE_COMPANY_INDEX_TH:
    case ROUTES.ROUTE_COMPANY_INDEX_EN:
    case ROUTES.ROUTE_COMPANY_INDEX_NUMBER:
    case ROUTES.ROUTE_GROUP_LIST:
    case ROUTES.ROUTE_TERM_AND_AGREEMENT:
    case ROUTES.ROUTE_PRIVACY_POLICY:
    case ROUTES.ROUTE_COOKIE_POLICY:
    case ROUTES.ROUTE_NOTIFY_MANAGE:
      return true;
    default:
      return false;
  }
};

export const isValidateRouteLoggedOutNav = ({pathname}) => {
  switch (pathname) {
    case ROUTES.ROUTE_HOME:
    case ROUTES.ROUTE_CONTACT:
    case ROUTES.ROUTE_FAQ:
    case ROUTES.ROUTE_JOB_INDEX:
    case ROUTES.ROUTE_COMPANY_INDEX:
    case ROUTES.ROUTE_COMPANY_INDEX_TH:
    case ROUTES.ROUTE_COMPANY_INDEX_EN:
    case ROUTES.ROUTE_COMPANY_INDEX_NUMBER:
    case ROUTES.ROUTE_TERM_AND_AGREEMENT:
    case ROUTES.ROUTE_PRIVACY_POLICY:
    case ROUTES.ROUTE_COOKIE_POLICY:
    case ROUTES.ROUTE_NOTIFY_MANAGE:
      return true;
    default:
      return false;
  }
};

export const isNotShowResultCount = ({pathname}) => {
  switch (pathname) {
    case ROUTES.ROUTE_SEARCH_BY_ID:
      return true;
    default:
      return false;
  }
};
