import _ from 'lodash';
import * as TYPES from './type';

const initialState = {
	loading: false,
	error: null,
	jobs: [],
	jobsLike: {},
	jobsApply: {},
};

export const listApplyJobReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case TYPES.LIST_APPLY_JOB_REQ:
			return {...state, loading: true};
		case TYPES.LIST_APPLY_JOB_FAIL:
			return {...state, loading: false, error: payload};
		case TYPES.LIST_APPLY_JOB_CLEAR:
			return {...initialState};
		case TYPES.LIST_APPLY_JOB_SUCCESS:
			return {
				...state,
				loading: false,
				jobs: payload.jobs,
				jobsLike: _.keyBy(payload.jobsLike.map((a) => a.job_id)),
				jobsApply: _.keyBy(payload.jobsApply, 'job_id'),
			};
		case TYPES.LIST_APPLY_JOB_LIKE_SUCCESS:
			return {
				...state,
				jobs: _.map(state.jobs, (a) => (a._id === payload.job._id ? {...a, like_count: a.like_count + 1} : a)),
				jobsLike: _.set(state.jobsLike, payload.job._id, payload.job._id),
			};
		case TYPES.LIST_APPLY_JOB_UNLIKE_SUCCESS:
			return {
				...state,
				jobs: _.map(state.jobs, (a) => (a._id === payload.job._id ? {...a, like_count: a.like_count - 1} : a)),
				jobsLike: _.omit(state.jobsLike, payload.job._id),
			};
		default:
			return state;
	}
};
