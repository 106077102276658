export const GET_APPLY_JOB_DRAFT_REQ = 'GET_APPLY_JOB_DRAFT_REQ';
export const GET_APPLY_JOB_DRAFT_SUCCESS = 'GET_APPLY_JOB_DRAFT_SUCCESS';
export const GET_APPLY_JOB_DRAFT_FAIL = 'GET_APPLY_JOB_DRAFT_FAIL';

export const GET_APPLY_JOB_RESUME_REQ = 'GET_APPLY_JOB_RESUME_REQ';
export const GET_APPLY_JOB_RESUME_SUCCESS = 'GET_APPLY_JOB_RESUME_SUCCESS';
export const GET_APPLY_JOB_RESUME_FAIL = 'GET_APPLY_JOB_RESUME_FAIL';
export const GET_APPLY_JOB_RESUME_LOADING = 'GET_APPLY_JOB_RESUME_LOADING';

export const GET_APPLY_JOB_SAVE_DRAFT_REQ = 'GET_APPLY_JOB_DRAFT_REQ';

export const GET_APPLY_JOB_SAVE_DATA_REQ = 'GET_APPLY_JOB_SAVE_DATA_REQ';
export const GET_APPLY_JOB_SAVE_DATA_SUCCESS = 'GET_APPLY_JOB_SAVE_DATA_SUCCESS';
export const GET_APPLY_JOB_SAVE_DATA_FAIL = 'GET_APPLY_JOB_SAVE_DATA_FAIL';

export const GET_APPLY_JOB_CLEAR = 'GET_APPLY_JOB_CLEAR';

export const APPLY_JOB_SEND_MAIL_REQ = '@APPLY_JOB/SEND_MAIL_REQ';
export const APPLY_JOB_SEND_MAIL_SUCCESS = '@APPLY_JOB/SEND_MAIL_SUCCESS';
export const APPLY_JOB_SEND_MAIL_FAIL = '@APPLY_JOB/SEND_MAIL_FAIL';
export const APPLY_JOB_SEND_MAIL_CLEAR = '@APPLY_JOB/SEND_MAIL_CLEAR';
