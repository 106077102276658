import React from 'react';
import {translate} from '../../../res/language/translate';
import {useLocationContextDispatch} from '../../../lib/hooks/logic/useLocation';
import {useFormSearchState, useFormSearchDispatch} from '../../../lib/hooks/logic/useForm';
import SelectProvince from '../../../views/select-location-sibling/select-province';
import SelectDistrict from '../../../views/select-location-sibling/select-district';
import SelectPopular from '../../../views/select-location-sibling/select-popular';
import * as ICONS from '../../../components/icon/constants';
import * as typeDef from '../../../utils/strong-types';
import * as arrayState from '../../../lib/context/array-state';

const KEY_ALL_PROVINCE = 'ทุกจังหวัด';
const KEY_HAS_DISTRICT = 'กรุงเทพมหานคร';

const LocationForm = () => {
  const {formSearchState} = useFormSearchState();
  const {dispatchUpdateForm} = useFormSearchDispatch();
  const {dispatchGetDistrictByProvince} = useLocationContextDispatch();

  const onChangeProvince = (province) => {
    if (!province) {
      return;
    }
    if (province.value !== KEY_ALL_PROVINCE) {
      dispatchGetDistrictByProvince(province.value);
    }
    const copyForm = {...formSearchState};
    copyForm.province = [province];
    copyForm.district = [];
    dispatchUpdateForm(copyForm);
  };

  const onChangeDistrict = (district) => {
    const copyForm = {...formSearchState};
    const newDistricts = arrayState.setNewAndPopExistValueCompareValue(formSearchState.district, district);
    copyForm.district = [...newDistricts];
    dispatchUpdateForm(copyForm);
  };

  const onChangePopular = (pop) => {
    const copyForm = {...formSearchState};
    const newTag = arrayState.setNewAndPopExistValue(formSearchState.tag, pop);
    copyForm.tag = [...newTag];
    dispatchUpdateForm(copyForm);
  };

  return (
    <>
      <SelectProvince
        outline
        addAll
        iconLabel={ICONS.LOCATION}
        placeholder={translate('landing.search.select')}
        label={translate('editProfile.general.province')}
        selectedProvince={formSearchState.province || []}
        onChange={onChangeProvince}
      />
      {Array.isArray(formSearchState.province) &&
        formSearchState?.province[0].value !== KEY_ALL_PROVINCE &&
        formSearchState?.province[0].value === KEY_HAS_DISTRICT && (
          <SelectDistrict
            outline
            iconLabel={ICONS.LOCATION}
            disabled={typeDef.isNotArrayLength(formSearchState.province)}
            label={translate('editProfile.general.district')}
            selectedDistrict={formSearchState.district || []}
            onChange={onChangeDistrict}
          />
        )}
      <SelectPopular selectedPopular={formSearchState.tag || []} onChange={onChangePopular} />
    </>
  );
};

LocationForm.propTypes = {};

export default LocationForm;
