import {createServiceURL} from '../../env/utils/env-services';
import {adapterPost} from '../../utils/adapter/post';
import {adapterGet} from '../../utils/adapter/get';

export const listApplyJob = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/list_job/applied`;
  const response = await adapterGet({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const listApplyJobLike = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/like/like_job`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const listApplyJobUnlike = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/like/unlike_job`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};
