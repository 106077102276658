import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import './style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const initSettings = {
  arrows: false,
  dots: true,
  infinite: true,
  swipeToSlide: true,
  speed: 500,
  autoplay: navigator.userAgent.indexOf("prerender") === -1,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const SlickSlider = (props) => {
  const {children, settings, afterChange} = props;
  const slickSettings = {
    ...initSettings,
    ...settings,
  };

  return (
    <Slider afterChange={(index) => afterChange(index)} {...slickSettings}>
      {children}
    </Slider>
  );
};

SlickSlider.propTypes = {
  children: PropTypes.array,
  settings: PropTypes.object,
  afterChange: PropTypes.func,
};

SlickSlider.defaultProps = {
  children: null,
  settings: {},
  afterChange: () => {},
};

export default SlickSlider;
