import * as typeDef from '../../../../utils/strong-types';
import * as ICONS from '../../../../components/icon/constants';

export const checkIsDisabledButton = (email) => {
  return typeDef.isNotArrayLength(email);
};

export const getIconName = (type) => {
  switch (type) {
    case 'tel':
      return ICONS.PHONE_CALL;
    case 'email':
      return ICONS.EMAIL_FILL;
    case 'close':
      return ICONS.CLOSE;
    case 'link':
      return ICONS.SHARE;
    default:
      return ICONS.LINE;
  }
};

export const getContactLabel = (type) => {
  switch (type) {
    case 'tel':
      return 'โทร';
    case 'email':
      return 'อีเมล';
    case 'close':
      return 'ยกเลิก';
    case 'link':
      return 'ไปที่โพสต์ต้นทาง';
    default:
      return 'ไลน์';
  }
};
