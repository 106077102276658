import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import OtpInputLib from 'react-otp-input';
import {SeparatorWrapper, Container, TextWarning, OverrideStyles} from './styled-component';

const MINIMUM_PRETTY_WIDTH = 443;
const INPUT_SIZE = 50;
const INPUT_MIN_SIZE = 30;

const OtpInput = (props) => {
  const {value, onChange, numInputs, errorText} = props;
  const [boxSizeState, setBoxSizeState] = useState({width: INPUT_SIZE, height: INPUT_SIZE});

  useEffect(() => {
    function onChangeSize() {
      const changedWidth = window.innerWidth;
      if (changedWidth < MINIMUM_PRETTY_WIDTH && boxSizeState.width === INPUT_SIZE) {
        setBoxSizeState({
          width: INPUT_MIN_SIZE,
          height: INPUT_SIZE,
        });
      }
      if (changedWidth >= MINIMUM_PRETTY_WIDTH && boxSizeState.width === INPUT_MIN_SIZE) {
        setBoxSizeState({
          width: INPUT_SIZE,
          height: INPUT_SIZE,
        });
      }
    }
    window.addEventListener('resize', onChangeSize);
    return () => window.removeEventListener('resize', onChangeSize);
  }, [boxSizeState.width]);

  useEffect(() => {
    const initWidth = window.innerWidth;
    if (initWidth < MINIMUM_PRETTY_WIDTH) {
      setBoxSizeState({
        width: INPUT_MIN_SIZE,
        height: INPUT_SIZE,
      });
    }
  }, []);

  const boxStyle = {...OverrideStyles.boxInput, ...boxSizeState};

  return (
    <Container>
      <OtpInputLib
        hasErrored={!!errorText}
        value={value}
        onChange={onChange}
        numInputs={numInputs}
        inputStyle={boxStyle}
        separator={<SeparatorWrapper>-</SeparatorWrapper>}
      />
      {errorText && <TextWarning>{errorText}</TextWarning>}
    </Container>
  );
};

OtpInput.propTypes = {
  numInputs: PropTypes.number,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorText: PropTypes.string,
};

OtpInput.defaultProps = {
  numInputs: 6,
  errorText: null,
};

export default OtpInput;
