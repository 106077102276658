import React from 'react';
import PropTypes from 'prop-types';
import {translate} from '../../../res/language/translate';
import {Container, ContactWrapper, ContactHeader, ContainerLoggedIn, ContactWrapperLoggedIn, OverrideStyles, TextLink} from './styled-component';
import Header from '../../../components/header';
import Row from '../../../components/row';
import Divider from '../../../components/divider';
import Link from '../../../components/link';
import CONTACT from '../../../res/json/JFF-contact.json';

const ContactList = () => {
  return (
    <>
      <Header variant="h3">จ็อบฟินฟิน</Header>
      <Row style={OverrideStyles.contactBody}>
        <ContactHeader>Email:</ContactHeader>
        <Link newTab url={`mailto:${CONTACT.EMAIL_OFFICIAL}`}>
          <TextLink>{CONTACT.EMAIL_OFFICIAL}</TextLink>
        </Link>
      </Row>
      <Row>
        <ContactHeader>Facebook Official:</ContactHeader>
        <Link newTab url={CONTACT.FACEBOOK_OFFICIAL}>
          <TextLink>Jobfinfin</TextLink>
        </Link>
      </Row>
      <Row>
        <ContactHeader>Line Official:</ContactHeader>
        <Link newTab url={CONTACT.LINE_OFFICIAL}>
          <TextLink>@jobfinfin</TextLink>
        </Link>
      </Row>
    </>
  );
};

const ContactView = (props) => {
  const {isLoggedIn} = props;

  if (isLoggedIn) {
    return (
      <ContainerLoggedIn>
        <Header variant="h3" fitLine>
          {translate('landing.contact.title')}
        </Header>
        <Divider edgeInsets bold />
        <ContactWrapperLoggedIn>
          <ContactList />
        </ContactWrapperLoggedIn>
      </ContainerLoggedIn>
    );
  }

  return (
    <Container>
      <Header variant="h2">{translate('landing.contact.title')}</Header>
      <ContactWrapper>
        <ContactList />
      </ContactWrapper>
    </Container>
  );
};

ContactView.propTypes = {
  isLoggedIn: PropTypes.bool,
};

ContactView.defaultProps = {
  isLoggedIn: false,
};

export default ContactView;
