import React from 'react';
import get from 'lodash/get';
import {useIntl} from 'react-intl';
import {translate} from '../../../../res/language/translate';
import {useCompanyProfileState} from '../../../../lib/hooks/logic/useCompanyProfile';
import {
  IconWrapper,
  LinkWrapper,
  TextContactHead,
  HeaderWrapper,
  GroupTextWrapper,
  ContactWrapper,
  TextMail,
  TextLine,
  TextTel,
  OverrideStyles,
} from './styled-component';
import Header from '../../../../components/header';
import Icon from '../../../../components/icon';
import Row from '../../../../components/row';
import Link from '../../../../components/link';
import Text from '../../../../components/text';
import {getLocation, getValue, getDefaultValue, getHeadCount, getWebsiteUrl, displayWebsiteUrl, checkWrapLine} from './logic';
import * as iconName from '../../../../components/icon/constants';
import * as numbers from '../../../../utils/number';

const AboutCompany = () => {
  const {metaGroupState} = useCompanyProfileState();
  const {formatMessage} = useIntl();

  return (
    <>
      <HeaderWrapper>
        <Header variant="h3">{`${translate('about.header')} ${getValue(metaGroupState?.name)}`}</Header>
      </HeaderWrapper>
      <Row alignStart wrap="nowrap">
        <IconWrapper>
          <Icon name={iconName.LOCATION} size={20} />
        </IconWrapper>
        <GroupTextWrapper>
          <Header variant="h4" style={OverrideStyles.header}>
            {formatMessage({defaultMessage: 'ที่อยู่บริษัท : '})}
          </Header>
          <Text>{getLocation(metaGroupState?.location)}</Text>
        </GroupTextWrapper>
      </Row>

      <Row alignStart wrap="nowrap">
        <IconWrapper>
          <Icon name={iconName.TRAVEL_BUS} size={20} />
        </IconWrapper>
        <GroupTextWrapper>
          <Header variant="h4" style={OverrideStyles.header}>
            {formatMessage({defaultMessage: 'วิธีการเดินทาง :'})}
          </Header>
          <Text>{getDefaultValue(metaGroupState?.location?.how_to_travel)}</Text>
        </GroupTextWrapper>
      </Row>

      <Row alignStart wrap="nowrap">
        <IconWrapper>
          <Icon name={iconName.WORLD} size={20} />
        </IconWrapper>
        <GroupTextWrapper nowrap>
          <Header variant="h4" style={OverrideStyles.header}>
            {formatMessage({defaultMessage: 'เว็บไซต์ :'})}
          </Header>
          {metaGroupState?.website && (
            <Link newTab url={`${getWebsiteUrl(metaGroupState.website)}`}>
              <LinkWrapper>{`${displayWebsiteUrl(metaGroupState.website)}`}</LinkWrapper>
            </Link>
          )}
          {!metaGroupState?.website && <Text>{getDefaultValue(metaGroupState?.website)}</Text>}
        </GroupTextWrapper>
      </Row>

      <Row alignStart wrap="nowrap">
        <IconWrapper>
          <Icon name={iconName.USER} size={20} />
        </IconWrapper>
        <GroupTextWrapper>
          <Header variant="h4" style={OverrideStyles.header}>
            {formatMessage({defaultMessage: 'จำนวนพนักงาน :'})}
          </Header>
          <Text>{getHeadCount(metaGroupState?.head_count)}</Text>
        </GroupTextWrapper>
      </Row>

      <Row alignStart wrap="nowrap">
        <IconWrapper>
          <Icon name={iconName.PHONE_CALL} size={20} />
        </IconWrapper>
        <Header variant="h4">{translate('about.contacts')}</Header>
      </Row>

      <>
        {metaGroupState?.email && (
          <ContactWrapper>
            <TextContactHead>อีเมล : </TextContactHead>
            {metaGroupState?.email?.map((item) => (
              <Link key={item} newTab url={`mailto:${item}`}>
                <TextMail>{item}</TextMail>
              </Link>
            ))}
          </ContactWrapper>
        )}
        {metaGroupState?.tel_no && (
          <ContactWrapper>
            <TextContactHead>โทร : </TextContactHead>
            {metaGroupState?.tel_no?.map((item) => (
              <Link key={item} newTab url={`tel:${item}`}>
                <TextTel>{numbers.numberPhone({input: item}) || item}</TextTel>
              </Link>
            ))}
          </ContactWrapper>
        )}
        {metaGroupState?.line_id && checkWrapLine(get(metaGroupState.line_id, '[0]')) ? (
          <ContactWrapper wrapLine>
            <TextContactHead>ไลน์ไอดี : </TextContactHead>
            {metaGroupState?.line_id?.map((item) => (
              <TextLine key={item}>{item}</TextLine>
            ))}
          </ContactWrapper>
        ) : (
          <ContactWrapper>
            <TextContactHead>ไลน์ไอดี : </TextContactHead>
            {metaGroupState?.line_id?.map((item) => (
              <Text key={item}>{item}</Text>
            ))}
          </ContactWrapper>
        )}
      </>
    </>
  );
};

AboutCompany.propTypes = {};

export default AboutCompany;
