import React from 'react';
import {translate} from '../../res/language/translate';
import MetaTags from '../../components/meta-tag';
import BoxSearch from './views/box-search';
import BoxPresent from './views/box-present';
import BoxRecommend from './views/box-recommend';
import BoxWalkThrough from './views/box-walk-through';
import BoxBottom from './views/box-bottom';
import ENV from '../../env.json';

const MainScreen = () => {
  return (
    <>
      <MetaTags contentUrl={ENV.PUBLIC.API_URL} title={translate('meta.main')} description={translate('meta.main')} />
      <BoxSearch />
      <BoxRecommend />
      <BoxPresent />
      <BoxWalkThrough />
      <BoxBottom />
    </>
  );
};

MainScreen.propTypes = {};

export default MainScreen;
