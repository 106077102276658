import {useEffect, useState} from 'react';
import queryString from 'querystring';
import {useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';

export const useSearchParamsEffect = () => {
  const location = useLocation();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const [searchParamsState, setSearchParamsState] = useState(null);
  useEffect(() => {
    if (location.search && isLoggedInState.isCheckedAuth) {
      const searchParams = queryString.parse(location.search.replace('?', ''));
      setSearchParamsState(searchParams);
    }
  }, [location.search, isLoggedInState]);
  return {searchParamsState};
};

export const useParamsEffect = () => {
  const params = useParams();
  const isLoggedInState = useSelector((state) => state.auth.auth);
  const [paramsState, setParamsState] = useState(null);
  useEffect(() => {
    if (params && isLoggedInState.isCheckedAuth) {
      setParamsState(params);
    }
  }, [params, isLoggedInState]);
  return {paramsState};
};
