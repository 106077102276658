import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => (props.square ? '4px' : '20px')};
  background-color: ${(props) => props.theme.chipBackground};
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
  ${(props) =>
    !props.square &&
    !props.wrapText &&
    `  height: 40px;
    line-height: 40px;
    padding: 0 1rem;`};
  ${(props) => props.active && ` background-color: ${props.theme.primary}`};
`;

export const ChipLabel = styled.span`
  ${(props) => props.active && `color: ${props.theme.white}`};
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20px;
  height: 20px;
`;

export const OverrideStyles = {
  icon: {
    size: 18,
    color: lightTheme.danger,
  },
};
