import React, {useState} from 'react';
import {translate} from '../../../../res/language/translate';
import {useUserDispatch} from '../../../../lib/hooks-logic/useUser';
import {FormWrapper, FormCenter, OverrideStyles} from '../styled-component';
import Form from '../../../../components/form';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import * as validate from './validate';
import * as logic from './logic';

const ChangePasswordForm = () => {
  const {dispatchChangePassword} = useUserDispatch();

  const [formState, setFormState] = useState(logic.initForm);
  const [errorTextState, setErrorTextState] = useState(logic.initValidate);

  const onChangeError = (key) => {
    const newErrorState = {...errorTextState};
    newErrorState[key] = '';
    setErrorTextState(newErrorState);
  };

  const onChangeText = (key, value) => {
    onChangeError(key);
    const newFormState = {...formState};
    newFormState[key] = value;
    setFormState(newFormState);
  };

  const onClearForm = () => {
    setErrorTextState(logic.initValidate);
    setFormState(logic.initForm);
  };

  const onSubmit = () => {
    const [errorOld, errorNew, errorConf] = validate.validateForm(formState);
    if (errorOld || errorNew || errorConf) {
      const newError = {
        oldPassword: errorOld,
        newPassword: errorNew,
        confPassword: errorConf,
      };
      setErrorTextState(newError);
      return;
    }
    const reqBody = {
      older_password: formState.oldPassword,
      new_password: formState.newPassword,
    };
    dispatchChangePassword(reqBody, onClearForm);
  };

  return (
    <FormCenter>
      <FormWrapper>
        <Form formId="change-password-form" onSubmit={onSubmit}>
          <Input
            name="change-pass-old"
            fillBackground
            type="password"
            label={translate('changePassword.oldPassword')}
            placeholder={translate('changePassword.oldPassword')}
            value={formState.oldPassword}
            onChange={(value) => onChangeText('oldPassword', value)}
            warningText={errorTextState.oldPassword}
          />
          <Input
            name="change-pass-old"
            fillBackground
            type="password"
            label={translate('changePassword.newPassword')}
            placeholder={translate('changePassword.newPassword')}
            value={formState.newPassword}
            onChange={(value) => onChangeText('newPassword', value)}
            warningText={errorTextState.newPassword}
            hint={translate('register.errorPassword.regex')}
          />
          <Input
            name="change-pass-old"
            fillBackground
            type="password"
            label={translate('changePassword.confNewPassword')}
            placeholder={translate('changePassword.confNewPassword')}
            value={formState.confPassword}
            onChange={(value) => onChangeText('confPassword', value)}
            warningText={errorTextState.confPassword}
          />
          <Button block type="submit" style={OverrideStyles.buttonSubmit}>
            {translate('changePassword.saveNewPassword')}
          </Button>
        </Form>
      </FormWrapper>
    </FormCenter>
  );
};

ChangePasswordForm.propTypes = {};

export default ChangePasswordForm;
