import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {Container, JobTextHyperLink, JobTextHyperLinkWrapper, TextCredit} from './styled-component';
import Link from '../../../../components/link';
import {getShortLink} from '../../utils/short-link';
import * as logic from './logic';

const CreditView = (props) => {
  const {postUrl, textAnnouncement} = props;

  const {formatMessage} = useIntl();

  if (!logic.checkIsValidPostUrl(postUrl) && !textAnnouncement) {
    return null;
  }

  let announcementUrl;
  if (textAnnouncement) {
    textAnnouncement.replace(/>>(.*)/, (m, p1) => {
      announcementUrl = p1;
    });
  }

  return (
    <Container>
      <JobTextHyperLinkWrapper>
        <TextCredit>{formatMessage({defaultMessage: 'ดูรายละเอียดเพิ่มเติม คลิก >>'})}</TextCredit>
        <Link newTab url={announcementUrl || postUrl}>
          <JobTextHyperLink>{getShortLink(announcementUrl || postUrl)}</JobTextHyperLink>
        </Link>
      </JobTextHyperLinkWrapper>
    </Container>
  );
};

CreditView.propTypes = {
  postUrl: PropTypes.string,
  textAnnouncement: PropTypes.string,
};

CreditView.defaultProps = {
  postUrl: null,
  textAnnouncement: null,
};

export default CreditView;
