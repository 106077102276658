import * as typeDef from '../../../../utils/strong-types';

export const validateAllRow = (listState) => {
  if (typeDef.isNotArrayLength(listState)) {
    return [true, null];
  }
  let isValidate = true;
  const errorList = listState.map((elem) => {
    if (elem.detail) {
      return {
        value: '',
      };
    }
    isValidate = false;
    return {
      value: 'กรุณากรอกรายละเอียด',
    };
  });
  return [isValidate, errorList];
};
