import {put, call, all, delay} from 'redux-saga/effects';
import {authorizeProvider} from '../utils/authorize';
import {getErrorMessage} from '../utils/error-store';
import {translateApi} from '../../res/language/translate';
import {loginFirebase} from '../../utils/firebase/firebase-auth';
import * as api from './api';
import * as apiAuth from '../auth/api';
import * as apiJob from '../apply-job/api';
import * as TYPES from './type';
import * as ALERT_TYPES from '../alert/type';
import * as AUTH_TYPES from '../auth/type';
import * as snackbar from '../../utils/components/toast-notification/provider';
import * as navigationServices from '../../utils/navigation/navigation-services';
import * as alert from '../../utils/components/alert/provider';
import * as sagaAlert from '../utils/saga-alert';
import * as logic from './logic';

export function* userManualUploadProfile({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.USER_UPLOAD_RESUME_MANUAL_CLEAR,
    });
    return;
  }
  try {
    const response = yield call(api.userUploadResumeManual, payload?.data);
    yield put({
      type: TYPES.USER_UPLOAD_RESUME_MANUAL_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: TYPES.USER_UPDATE_RESUME,
      payload: {
        resume: response?.data?.resume,
      },
    });
    snackbar.setSnackbarMessage('อัพเดทเรซูเม่เรียบร้อย');
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      const responseStatus = error?.response?.status || 400;
      const message = getErrorMessage(responseError);
      yield all([
        put({
          type: TYPES.USER_ERROR,
          payload: {message: responseError, status: responseStatus},
        }),
      ]);
      alert.setAlert(message);
    }
  }
}

export function* userShortProfile({payload}) {
  try {
    const response = yield call(api.userShortProfile, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.USER_SHORT_PROFILE,
        payload: response?.data,
      });
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      const responseStatus = error?.response?.status || 400;
      yield all([
        put({
          type: TYPES.USER_ERROR,
          payload: {message: responseError, status: responseStatus},
        }),
        put({
          type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
          payload: {data: {message: getErrorMessage(responseError)}},
        }),
      ]);
    }
  }
}

export function* userDetailProfile({payload}) {
  yield delay(200);
  try {
    const response = yield call(api.userDetailProfile, payload?.data);
    if (response.status === 200) {
      const {contactTel, contactEmail} = logic.getContactable(response.data);
      const resLoginFirebase = yield call(apiAuth.userLoginFirebase);
      yield call(loginFirebase, resLoginFirebase.data?.token);
      yield put({
        type: TYPES.USER_DETAIL_PROFILE,
        payload: {...response?.data, contactable_tel: contactTel, contactable_email: contactEmail},
      });
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      const responseStatus = error?.response?.status || 400;

      if (responseStatus === 400) {
        yield all([
          put({
            type: TYPES.USER_ERROR,
            payload: {message: responseError, status: responseStatus},
          }),
          put({
            type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
            payload: {data: {message: getErrorMessage(responseError)}},
          }),
        ]);
      }
    }
  }
}

export function* clearStateUserProfile() {
  yield put({type: TYPES.CLEAT_USER});
}

export function* editUserProfile({payload}) {
  try {
    const response = yield call(api.editUserProfile, payload?.data);
    yield put({
      type: TYPES.EDIT_USER_PROFILE,
      payload: response?.data?.update || response?.data,
    });
    try {
      const responseCheckInfo = yield call(apiJob.applyJobCheckInfo);
      if (responseCheckInfo) {
        yield put({
          type: TYPES.USER_GENERATE_AUTO_RESUME_REQ,
        });
      }
    } catch (error) {
      // un handle for this case
    }
    sagaAlert.sagaAlertSuccess(translateApi('global.saveDefault'));
    if (!payload?.isProfilePicture && !payload?.noPop) {
      navigationServices.goBack();
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      const responseStatus = error?.response?.status || 400;
      yield put({
        type: TYPES.USER_ERROR,
        payload: {message: responseError, status: responseStatus},
      });
      sagaAlert.sagaAlertError(responseError);
    }
  }
}

export function* userChangePassword({payload}) {
  try {
    const response = yield call(api.userChangePassword, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.USER_CHANGE_PASSWORD,
        payload: response?.data,
      });
      snackbar.setSnackbarMessage(translateApi('global.saveDefault'));
      if (payload?.clearForm) {
        payload.clearForm();
      }
      if (payload?.pop) {
        navigationServices.goBack();
      }
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      const responseStatus = error?.response?.status || 400;

      if (responseStatus === 400) {
        yield all([
          put({
            type: TYPES.USER_ERROR,
            payload: {message: responseError, status: responseStatus},
          }),
          put({
            type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
            payload: {data: {message: getErrorMessage(responseError)}},
          }),
        ]);
      }
    }
  }
}

export function* userAutoGenerateResume() {
  try {
    const response = yield call(api.userGenerateAutoResume);
    yield put({
      type: TYPES.USER_GENERATE_AUTO_RESUME_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: TYPES.USER_UPDATE_AUTO_RESUME,
      payload: {
        auto_resume: response?.data?.auto_resume,
      },
    });
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      const responseStatus = error?.response?.status || 400;
      yield put({
        type: TYPES.USER_GENERATE_AUTO_RESUME_FAIL,
        payload: {data: {message: getErrorMessage(responseError), status: responseStatus}},
      });
    }
  }
}

export function* userAcceptTermAction({payload}) {
  try {
    const response = yield call(api.userAcceptTerm, payload?.data);
    yield put({
      type: TYPES.USER_ACCEPT_TERM_SUCCESS,
      payload: response?.data?.terms_and_condition,
    });
    yield put({
      type: AUTH_TYPES.AUTH_UPDATE_CURRENT_REQ,
      payload: response?.data?.terms_and_conditions || {},
    });
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      sagaAlert.sagaAlertError(responseError);
      yield put({
        type: TYPES.USER_ACCEPT_TERM_FAIL,
        payload: responseError,
      });
    }
  }
}
