import React from 'react';
import PropTypes from 'prop-types';
import {ButtonWrapper} from './style-component';

const ButtonUnderline = (props) => {
  const {children, onClick} = props;
  return <ButtonWrapper onClick={onClick}>{children}</ButtonWrapper>;
};

ButtonUnderline.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClick: PropTypes.func,
};

ButtonUnderline.defaultProps = {
  children: null,
  onClick: () => {},
};

export default ButtonUnderline;
