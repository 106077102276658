import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {TextLink} from './styled-component';
import Header from '../../../../components/header';
import Text from '../../../../components/text';
import Link from '../../../../components/link';
import * as logic from './logic';

const RenderText = (props) => {
  const {children} = props;
  const [renderText, setRenderText] = useState();

  useEffect(() => {
    if (children) {
      setRenderText(() => logic.getBodyText(children));
    }
  }, [children]);

  if (!Array.isArray(renderText)) {
    return null;
  }

  return (
    <>
      {renderText.map((item, index) => {
        const isHeader = logic.getIsHeader(item);
        const isLink = logic.getIsLink(item);
        if (isHeader) {
          return (
            <Header variant="h3" key={index.toString()}>
              {logic.replaceHeadTag(item)}
            </Header>
          );
        }
        if (isLink) {
          const url = logic.replaceLinkTag(item);
          const isEmail = logic.getIsEmail(url);
          return (
            <Link key={index.toString()} newTab url={isEmail ? `mailto:${url}` : url}>
              <TextLink>{isEmail ? url : logic.getWebsiteUrl(url)}</TextLink>
            </Link>
          );
        }
        return <Text key={index.toString()}>{item}</Text>;
      })}
    </>
  );
};

RenderText.propTypes = {
  children: PropTypes.string.isRequired,
};

export default RenderText;
