import React from 'react';
import {useIntl} from 'react-intl';
import {translate} from '../../../res/language/translate';
import ListTile from '../../../components/list-tile';
import Icon from '../../../components/icon';
import * as ICONS from '../../../components/icon/constants';
import * as ROUTES from '../../../routes/routes-name';
import * as NAVIGATION from '../../../utils/navigation/navigation-services';
import ENV from '../../../env.json';

const SettingList = () => {
  const {formatMessage} = useIntl();

  const onClickedSetting = () => {
    NAVIGATION.navigateTo({pathname: ROUTES.ROUTE_NOTIFY});
  };

  const onClickLink = (url) => {
    window.open(`${ENV.PUBLIC.API_URL}${url}`);
  };

  return (
    <>
      <ListTile
        topEdgeInsets
        divider
        icon={ICONS.BELL}
        title={translate('notify.settings.notify')}
        rightComponent={<Icon name={ICONS.ARROW_RIGHT} />}
        onClick={onClickedSetting}
      />
      <ListTile
        topEdgeInsets
        divider
        title={formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์'})}
        onClick={() => onClickLink(ROUTES.ROUTE_TERM_AND_AGREEMENT)}
      />
      <ListTile
        topEdgeInsets
        divider
        title={formatMessage({defaultMessage: 'นโยบายคุกกี้'})}
        onClick={() => onClickLink(ROUTES.ROUTE_COOKIE_POLICY)}
      />
    </>
  );
};

SettingList.propTypes = {};

export default SettingList;
