import {put, call} from 'redux-saga/effects';
import {authorizeProvider} from '../utils/authorize';
import {alertManualProvider, alertRemoveProvider} from '../utils/fast-dispatch';
import {translate} from '../../res/language/translate';
import * as snackbar from '../../utils/components/toast-notification/provider';
import * as navigationServices from '../../utils/navigation/navigation-services';
import * as api from './api';
import * as TYPES from './type';
import * as USER_TYPES from '../user/type';
import * as routes from '../../routes/routes-name';

export function* applyJobCheckInfoAction({payload}) {
  const {data, isLoggedOut, clear, action} = payload;
  if (isLoggedOut) {
    yield put({type: TYPES.APPLY_JOB_CHECK_INFO_IS_LOGGED_OUT});
    const alertData = {
      message: translate('login.pleaseLogin'),
      positiveTitle: translate('global.goToLogin'),
      negativeTitle: translate('global.cancel'),
      action: {
        negativeAction: () => alertRemoveProvider(),
        positiveAction: () => {
          navigationServices.navigateTo({pathName: routes.ROUTE_LOGIN});
          alertRemoveProvider();
        },
      },
    };
    alertManualProvider(alertData);
    return;
  }

  if (clear) {
    yield put({type: TYPES.APPLY_JOB_CHECK_INFO_CLEAR});
    return;
  }

  try {
    const response = yield call(api.applyJobCheckInfo, data);
    if (response.status === 200) {
      yield put({
        type: TYPES.APPLY_JOB_CHECK_INFO_SUCCESS,
        payload: response.data,
      });
      if (data?.is_check && action?.onCallbackSendEmail) {
        action.onCallbackSendEmail();
        return;
      }
      if (data?.is_check === false) {
        yield put({
          type: TYPES.APPLY_JOB_RESUME_GET_TEMPLATE_REQ,
        });
      }
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.APPLY_JOB_CHECK_INFO_FAIL,
        payload: {
          message: responseError,
          data: error?.response?.data?.data,
          lessField: payload?.data?.is_check,
        },
      });
    }
    if (action?.onCallbackForceUpdate) {
      action.onCallbackForceUpdate(payload?.is_check);
    }
  }
}

export function* applyJobGetDraftEmailAction({payload}) {
  if (payload?.clear) {
    yield put({type: TYPES.APPLY_JOB_GET_DRAFT_CLEAR});
    return;
  }
  try {
    const response = yield call(api.applyJobGetDraft);
    if (response.status === 200) {
      yield put({
        type: TYPES.APPLY_JOB_GET_DRAFT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.APPLY_JOB_GET_DRAFT_FAIL,
        payload: responseError,
      });
    }
  }
}

export function* applyJobUpdateDraftEmailAction({payload}) {
  try {
    const response = yield call(api.applyJobUpdateDraft, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.APPLY_JOB_UPDATE_DRAFT_SUCCESS,
        payload: response.data,
      });
      if (payload?.alert) {
        snackbar.setSnackbarMessage(translate('global.saveDefault'));
      }
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.APPLY_JOB_UPDATE_DRAFT_FAIL,
        payload: responseError,
      });
    }
  }
}

export function* applyJobRequestModalAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.APPLY_JOB_REQUEST_MODAL_CLEAR,
    });
    return;
  }
  if (payload?.config) {
    yield put({
      type: TYPES.APPLY_JOB_REQUEST_MODAL_UPDATE_CONFIG,
      payload: payload,
    });
    return;
  }
  yield put({
    type: TYPES.APPLY_JOB_REQUEST_MODAL_SUCCESS,
    payload: payload,
  });
}

export function* applyJobGetResumeTemplateAction({payload}) {
  if (payload?.clear) {
    yield put({
      type: TYPES.APPLY_JOB_RESUME_GET_TEMPLATE_CLEAR,
    });
    return;
  }
  try {
    const response = yield call(api.applyJobGetResumeTemplate, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.APPLY_JOB_RESUME_GET_TEMPLATE_SUCCESS,
        payload: response.data,
      });
      navigationServices.navigateTo({pathName: routes.ROUTE_RESUME_TEMPLATE});
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.APPLY_JOB_RESUME_GET_TEMPLATE_FAIL,
        payload: responseError,
      });
    }
  }
}

export function* applyJobGenerateResumeAction({payload}) {
  if (payload?.clear) {
    return;
  }
  try {
    const response = yield call(api.applyJobGenerateResume, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.APPLY_JOB_RESUME_GENERATE_TEMPLATE_SUCCESS,
        payload: response.data,
      });
      yield put({type: USER_TYPES.USER_DETAIL_PROFILE_REQ});
      if (response.data?.resume) {
        window.open(response.data.resume);
        navigationServices.goBack();
      }
    }
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.APPLY_JOB_RESUME_GENERATE_TEMPLATE_FAIL,
        payload: responseError,
      });
    }
  }
}
