import styled from 'styled-components';

export const Container = styled.div`
  z-index: 999;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: flex-end;
  background-color: ${(props) => props.theme.modalBackdrop};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
`;

export const SheetContent = styled.div`
  overflow-y: auto;
  max-height: 100%;
  width: 100vw;
  padding: 1rem;
  background-color: ${(props) => props.theme.white};
  transform: ${(props) => (props.isOpen ? 'translateY(0)' : 'translateY(100%)')};
  transition: transform 0.15s ease-in-out;
  ${(props) => props.noEdgeInsets && `padding: 1rem 0`};
  &::-webkit-scrollbar {
    width: 0px;
  }
`;
