import React from 'react';
import Center from '../../components/center';
import AppLogo from '../../views/logo/logo';
import {Container} from './styled-component';

const SplashScreen = () => {
  return (
    <Center>
      <Container>
        <AppLogo ratio={1.7} />
      </Container>
    </Center>
  );
};

SplashScreen.propTypes = {};

export default SplashScreen;
