import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import * as typeDef from '../../utils/strong-types';

export const setNewUniqueArray = (list, newItem) => {
  if (!Array.isArray(list) && typeDef.isNullOrUndefined(newItem)) {
    return [];
  }
  if (!Array.isArray(list)) {
    return [newItem];
  }
  const mergeItems = [...list, newItem];
  const uniqueList = uniq(mergeItems);
  return uniqueList;
};

export const setNewAndPopExistValue = (list, newItem) => {
  if (!Array.isArray(list) && typeDef.isNullOrUndefined(newItem)) {
    return [];
  }
  if (!Array.isArray(list)) {
    return [newItem];
  }
  const findValue = list.find((item) => isEqual(item, newItem));
  if (!findValue) {
    return [...list, newItem];
  }
  const findOutValues = list.filter((item) => !isEqual(item, newItem));
  return findOutValues;
};

export const setNewAndPopExistValueCompareValue = (list, newItem) => {
  if (!Array.isArray(list) && typeDef.isNullOrUndefined(newItem)) {
    return [];
  }
  if (!Array.isArray(list)) {
    return [newItem];
  }
  const findValue = list.find((item) => item.value === newItem.value);
  if (!findValue) {
    return [...list, newItem];
  }
  const findOutValues = list.filter((item) => item.value !== newItem.value);
  return findOutValues;
};

export const setNewDifferenceValue = (array1, array2) => {
  if (!Array.isArray(array1) && !Array.isArray(array2)) {
    return [];
  }
  return difference(array1, array2);
};
