import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import environment from '../env.json';

const {IS_PRODUCTION} = environment.PUBLIC;

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

/*eslint-disable */
//eruda plugin
(function () {
  // if (IS_PRODUCTION) return;
  if (!/eruda/.test(window.location) && localStorage.getItem('active-eruda') != 'true') return;
  const src = '//cdn.jsdelivr.net/npm/eruda';
  document.write('<scr' + 'ipt src="' + src + '"></scr' + 'ipt>');
  document.write('<scr' + 'ipt>eruda.init();</scr' + 'ipt>');
  middlewares.push(logger);
})();

let storeConfig;
if (IS_PRODUCTION) {
  storeConfig = createStore(rootReducer, initialState, applyMiddleware(...middlewares));
  // storeConfig = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
} else {
  storeConfig = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
}
sagaMiddleware.run(rootSaga);
const store = storeConfig;

export default store;
