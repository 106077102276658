exports.JWT_PUBLIC_KEY = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsZ188RA4G6oyKu9sWW5u
HnmbYxvprjSMBV01YOJGJ1VJwBa+4InMZFyEpF28lJrd7UMtEUFeuAMtGmloY8jN
0AvgOHBhShcIH/jehE+XDs6VQgJ1MwlRYcpGpJxoo9lYdHzQNSxNTlsAOVAfuDPk
hHfLb/0EHzzjZK5S2s3CwS4diAzko3zoScArCw82uy9P6A9Z6+81Zu0Lhc7q1fFy
jIqsUF57GgrwnBoTLRB/VDyD5cNpoq/a+2nEXMeVKzlYjLfT58hHzAIdmEcKX4kV
PAQLrvOMWA3yztSvEamLsYwqUGcTpJRNkvHRUGlhRPRevJLb+WPxjdV6H5Wq1lxF
YQIDAQAB
-----END PUBLIC KEY-----
`;
