import styled from 'styled-components';

export const SkeletonJobPost = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 680px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.white};
  padding: 1rem;
  @media (max-width: 680px) {
    border-radius: 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 0.5rem`};
  }
  @media (min-width: 681px) {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 1rem`};
  }
`;

export const PostBodyWrapper = styled.div`
  margin: 1rem 0;
`;

export const OverrideStyles = {
  marginLeft: {
    marginLeft: '1rem',
  },
  marginRight: {
    marginRight: '1rem',
  },
  marginTop: {
    marginTop: '1rem',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  marginHorizontal: {
    margin: '0 1rem',
  },
  marginVertical: {
    margin: '1rem ',
  },
};
