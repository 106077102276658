import {takeEvery, takeLatest} from 'redux-saga/effects';
import * as TYPES from './type';
import * as snackbarSaga from './saga';

export function* watchSnackbarSetMessage() {
  yield takeEvery(TYPES.SNACKBAR_SET_MESSAGE_REQ, snackbarSaga.snackbarSetMessageAction);
}

export function* watchSnackbarRemoveMessage() {
  yield takeLatest(TYPES.SNACKBAR_REMOVE_MESSAGE_REQ, snackbarSaga.snackbarRemoveMessageAction);
}

export function* watchSnackbarClearMessage() {
  yield takeLatest(TYPES.SNACKBAR_CLEAR_MESSAGE_REQ, snackbarSaga.snackbarClearMessageAction);
}
