import styled from 'styled-components';
import {lightTheme} from '../../../styles/themes';
import NavigationTheme from '../../../styles/navigation';

export const Container = styled.div`
  display: flex;
  position: relative;
  height: auto;
  min-height: 100%;
`;

export const PresentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondPrimary};
  width: 514px;
  min-width: 514px;
  justify-content: center;
  padding: 2rem 1rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const NavWrapper = styled.nav`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  height: 56px;
  width: 100%;
  padding: 0 1rem;
  @media (max-width: 1023px) {
    background-color: ${(props) => props.theme.white};
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: ${NavigationTheme.NavigationHeadShadow.boxShadow};
  }
`;

export const NavItemRightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavText = styled.p`
  padding-right: 0.5rem;
  @media (max-width: 576px) {
    display: none;
  }
`;

export const SideTextWrapper = styled.div`
  padding: 2rem;
  margin-left: 20px;
  padding-bottom: 0;
`;

export const SideTitle = styled.p`
  font-size: 38px;
  color: ${(props) => props.theme.white};
  font-weight: bold;
  font-family: 'Prompt Regular';
`;

export const SideDescription = styled.p`
  font-size: 26px;
  color: ${(props) => props.theme.white};
  font-weight: bold;
  font-family: 'Prompt Regular';
`;

export const SideKey = styled.p`
  font-size: 26px;
  color: ${(props) => props.theme.subSecondPrimary};
  font-weight: bold;
  font-family: 'Prompt Regular';
  padding-bottom: 2rem;
  margin-bottom: 50px;
`;

export const ForgotWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonSmallWrapper = styled.div`
  position: fixed;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 2rem;
  background-color: ${(props) => props.theme.grayBackground};
  ${(props) => props.row && `display: flex; flex-direction: row;`};
`;

export const ButtonLargeWrapper = styled.div`
  display: none;
  @media (min-width: 1025px) {
    display: block;
    ${(props) => props.row && `display: flex; flex-direction: row;`};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  border-bottom: 1px solid ${(props) => props.theme.divider};
  padding-bottom: 0.5rem;
  @media (max-width: 1024px) {
    justify-content: center;
    border-bottom: none;
    padding-bottom: none;
  }
`;

export const FacebookRegisterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 2rem 0;
`;

export const InputHidden = styled.input`
  display: none;
`;

export const RowResume = styled.div`
  display: flex;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
`;

export const LabelResume = styled.label`
  font-weight: bold;
`;

export const RowBirthDay = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  @media (max-width: 380px) {
    flex-direction: column;
  }
`;

export const SelectBirthDayWrapper = styled.div`
  width: 100%;
  @media (min-width: 381px) {
    ${(props) => !props.noMargin && `margin-left: 1rem;`};
  }
`;

export const OverrideStyles = {
  form: {
    padding: '2rem 0',
  },
  button: {
    marginTop: '2rem',
  },
  buttonLeftSpace: {
    marginLeft: '0.5rem',
  },
  registerLink: {
    color: lightTheme.primary,
  },
  textRegisFacebook: {
    paddingTop: '2rem',
  },
  option: {
    marginBottom: '1rem',
  },
};
