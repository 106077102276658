import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {AvatarImg, AvatarDummy} from './styled-component';

const Avatar = (props) => {
  const {src} = props;
  const [isErrorState, setIsErrorState] = useState(false);

  if (!src || isErrorState) {
    return <AvatarDummy />;
  }
  return <AvatarImg src={src} onError={() => setIsErrorState(true)} />;
};

Avatar.propTypes = {
  src: PropTypes.string,
};

Avatar.defaultProps = {
  src: null,
};

export default Avatar;
