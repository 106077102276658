import styled from 'styled-components';
import BoxShadow from '../../styles/box';

export const Container = styled.div`
  width: 680px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: ${(props) => props.theme.white};
  @media (max-width: 680px) {
    width: calc(100vw);
  }
  @media (min-width: 681px) {
    box-shadow: ${BoxShadow.content};
    border-radius: 6px;
  }
`;
