import * as dateTime from '../../../../utils/date-time';

const BDH = 543;
export const yearData = () => {
  const data = [];
  const maxYear = dateTime.yearBdh();
  const startYear = maxYear - 60;
  for (let i = maxYear; i > startYear; i--) {
    const item = {
      label: `ปี ${i}`,
      value: `${i - BDH}`,
    };
    data.push(item);
  }
  return data;
};

export const graduationData = [
  {label: 'ไม่ระบุ', value: 'ไม่ระบุ'},
  {label: 'ม.3', value: 'ม.3'},
  {label: 'ม.6', value: 'ม.6'},
  {label: 'ปวช.', value: 'ปวช.'},
  {label: 'ปวส.', value: 'ปวส.'},
  {label: 'ป.ตรี', value: 'ป.ตรี'},
  {label: 'ป.โท', value: 'ป.โท'},
  {label: 'ป.เอก', value: 'ป.เอก'},
];

export const branchData = [
  {label: 'กฎหมาย', value: 'กฎหมาย'},
  {label: 'การเกษตร/การประมง/ปศุสัตว์', value: 'การเกษตร/การประมง/ปศุสัตว์'},
  {label: 'การตลาด/การขาย', value: 'การตลาด/การขาย'},
  {label: 'การบริหาร/การจัดการ/บุคคล', value: 'การบริหาร/การจัดการ/บุคคล'},
  {label: 'การโรงแรม/ท่องเที่ยว', value: 'การโรงแรม/ท่องเที่ยว'},
  {label: 'ครุศาสตร์/ศึกษาศาสตร์', value: 'ครุศาสตร์/ศึกษาศาสตร์'},
  {label: 'คลังสินค้า/Logistics', value: 'คลังสินค้า/Logistics'},
  {label: 'ความปลอดภัย/จป./อนามัย', value: 'ความปลอดภัย/จป./อนามัย'},
  {label: 'คหกรรมศาสตร์/เสื้อผ้า/สิ่งทอ', value: 'คหกรรมศาสตร์/เสื้อผ้า/สิ่งทอ'},
  {label: 'ช่างกลโรงงาน/เทคนิคอุตสาหกรรม', value: 'ช่างกลโรงงาน/เทคนิคอุตสาหกรรม'},
  {label: 'ช่างก่อสร้าง/ ช่างสำรวจ', value: 'ช่างก่อสร้าง/ ช่างสำรวจ'},
  {label: 'ช่างเครื่อง/ช่างยนต์', value: 'ช่างเครื่อง/ช่างยนต์'},
  {label: 'ช่างโลหะ', value: 'ช่างโลหะ'},
  {label: 'ช่างอิเลคโทรนิค/ช่างไฟฟ้า/ช่างคอมพิวเตอร์', value: 'ช่างอิเลคโทรนิค/ช่างไฟฟ้า/ช่างคอมพิวเตอร์'},
  {label: 'ช่างอื่นๆ', value: 'ช่างอื่นๆ'},
  {label: 'ติดต่อต่างประเทศ/Import-Export', value: 'ติดต่อต่างประเทศ/Import-Export'},
  {label: 'เทคโนโลยีการผลิต', value: 'เทคโนโลยีการผลิต'},
  {label: 'เทคโนโลยีการพิมพ์', value: 'เทคโนโลยีการพิมพ์'},
  {label: 'เทคโนโลยีชีวภาพ/Biotech', value: 'เทคโนโลยีชีวภาพ/Biotech'},
  {label: 'นิเทศศาสตร์/สื่อสารมวลชน', value: 'นิเทศศาสตร์/สื่อสารมวลชน'},
  {label: 'บัญชี/การเงิน/การธนาคาร', value: 'บัญชี/การเงิน/การธนาคาร'},
  {label: 'โปรแกรมเมอร์/วิทยาศาสตร์คอมพิวเตอร์/IT', value: 'โปรแกรมเมอร์/วิทยาศาสตร์คอมพิวเตอร์/IT'},
  {label: 'แพทย์/พยาบาล/เภสัชกร/สาธารณสุข', value: 'แพทย์/พยาบาล/เภสัชกร/สาธารณสุข'},
  {label: 'ภูมิศาสตร์/GIS', value: 'ภูมิศาสตร์/GIS'},
  {label: 'รัฐศาสตร์', value: 'รัฐศาสตร์'},
  {label: 'เลขา/ประชาสัมพันธ์/ธุรการ/คอมพิวเตอร์ธุรกิจ', value: 'เลขา/ประชาสัมพันธ์/ธุรการ/คอมพิวเตอร์ธุรกิจ'},
  {label: 'วิทยาศาสตร์/ฟิสิกส์/เคมี', value: 'วิทยาศาสตร์/ฟิสิกส์/เคมี'},
  {label: 'วิทยาศาสตร์สิ่งแวดล้อม', value: 'วิทยาศาสตร์สิ่งแวดล้อม'},
  {label: 'วิศวกรรมการเกษตร/การชลประทาน', value: 'วิศวกรรมการเกษตร/การชลประทาน'},
  {label: 'วิศวกรรมคอมพิวเตอร์/วิศวกรรมระบบ', value: 'วิศวกรรมคอมพิวเตอร์/วิศวกรรมระบบ'},
  {label: 'วิศวกรรมเคมี', value: 'วิศวกรรมเคมี'},
  {label: 'วิศวกรรมไฟฟ้า/อิเลคโทรนิค', value: 'วิศวกรรมไฟฟ้า/อิเลคโทรนิค'},
  {label: 'วิศวกรรมโยธา/สำรวจ', value: 'วิศวกรรมโยธา/สำรวจ'},
  {label: 'วิศวกรรมสาขาอื่นๆ', value: 'วิศวกรรมสาขาอื่นๆ'},
  {label: 'วิศวกรรมสิ่งแวดล้อม/สุขาภิบาล', value: 'วิศวกรรมสิ่งแวดล้อม/สุขาภิบาล'},
  {label: 'วิศวกรรมสื่อสาร/โทรคมนาคม', value: 'วิศวกรรมสื่อสาร/โทรคมนาคม'},
  {label: 'วิศวกรรมอุตสาหการ/เครื่องกล/โรงงาน', value: 'วิศวกรรมอุตสาหการ/เครื่องกล/โรงงาน'},
  {label: 'ศิลปกรรมศาสตร์/Graphics Design', value: 'ศิลปกรรมศาสตร์/Graphics Design'},
  {label: 'เศรษฐศาสตร์', value: 'เศรษฐศาสตร์'},
  {label: 'สถาปัตยกรรมศาสตร์/ตกแต่งภายใน', value: 'สถาปัตยกรรมศาสตร์/ตกแต่งภายใน'},
  {label: 'สถิติศาสตร์', value: 'สถิติศาสตร์'},
  {label: 'อักษรศาสตร์/ศิลปศาสตร์/มนุษย์ศาสตร์', value: 'อักษรศาสตร์/ศิลปศาสตร์/มนุษย์ศาสตร์'},
  {label: 'อาหาร-โภชนาการ/ วิทยาศาสตร์การอาหาร', value: 'อาหาร-โภชนาการ/ วิทยาศาสตร์การอาหาร'},
];

export const rowEducation = {
  level: {...graduationData[0]},
  school: '',
  branch: null,
  faculty: '',
  grade: '',
  year: null,
};
