import {takeEvery, takeLatest} from 'redux-saga/effects';
import * as TYPES from './type';
import * as registerSaga from './saga';

export function* watchUserGenerateId() {
  yield takeLatest(TYPES.REGISTER_GENERATE_ID_REQ, registerSaga.userGenerateIdAction);
}

export function* watchUserRegisterStep1() {
  yield takeEvery(TYPES.REGISTER_STEP1_UPDATE_FORM_REQ, registerSaga.userRegisterUpdateFormGeneralAction);
}

export function* watchUserRegisterStep1ValidateEmail() {
  yield takeEvery(TYPES.REGISTER_STEP1_VALIDATE_EMAIL_REQ, registerSaga.userRegisterStep1ValidateEmailAction);
}

export function* watchUserRegisterStep2() {
  yield takeEvery(TYPES.REGISTER_STEP2_UPDATE_FORM_REQ, registerSaga.userRegisterStep2Action);
}

export function* watchUserRegisterNextStep() {
  yield takeEvery(TYPES.REGISTER_NEXT_STEP_REQ, registerSaga.userRegisterVisibleStepRequestNextAction);
}

export function* watchUserRegisterUpdateVisibleStep() {
  yield takeEvery(TYPES.REGISTER_VISIBLE_STEP_REQ, registerSaga.userRegisterUpdateVisibleStepAction);
}

export function* watchUserRegisterOTP() {
  yield takeEvery(TYPES.REGISTER_OTP_REQ, registerSaga.userRegisterOTPAction);
}

export function* watchUserRegisterOTPRequest() {
  yield takeEvery(TYPES.REGISTER_OTP_REQUEST_REQ, registerSaga.userRegisterOTPRequestAction);
}

export function* watchUserRegisterOTPResend() {
  yield takeEvery(TYPES.REGISTER_OTP_RESEND_REQ, registerSaga.userRegisterOTPResendAction);
}

export function* watchUserRegisterOTPConfirm() {
  yield takeEvery(TYPES.REGISTER_OTP_CONFIRM_REQ, registerSaga.userRegisterOTPConfirmAction);
}

export function* watchUserRegisterOTPChangePhoneNumber() {
  yield takeEvery(TYPES.REGISTER_OTP_CHANGE_PHONE_REQ, registerSaga.userRegisterOTPChangePhoneNumberAction);
}

export function* watchUserRegisterManual() {
  yield takeEvery(TYPES.REGISTER_MANUAL_REQ, registerSaga.userRegisterManualAction);
}

export function* watchUserRegisterFacebook() {
  yield takeEvery(TYPES.REGISTER_FACEBOOK_REQ, registerSaga.userRegisterFacebookAction);
}

export function* watchUserRegisterClearForm() {
  yield takeLatest(TYPES.REGISTER_CLEAR_FORM_REQ, registerSaga.userRegisterClearFormAction);
}

export function* watchUserRegisterInterest() {
  yield takeEvery(TYPES.REGISTER_INTEREST_REQ, registerSaga.userRegisterInterestAction);
}
