import styled from 'styled-components';
import buttonTheme from '../../../styles/button';
import {lightTheme} from '../../../styles/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${buttonTheme.size.defaultHeight}px;
  padding: 0 0.75rem;
  border-radius: ${buttonTheme.size.borderRadius}px;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.primary};
    text-decoration: underline;
    background-color: ${(props) => props.theme.lightHover100};
  }
`;

export const ButtonText = styled.span`
  color: ${(props) => props.theme.primary};
  padding-left: 0.5rem;
`;

export const StarWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem 0 0;
`;

export const OverrideStyles = {
  title: {
    color: lightTheme.textBlack,
    fontWeight: 'bold',
  },
  iconStarColor: lightTheme.yellow,
  textStar: {
    color: lightTheme.gray,
    padding: '0 0.5rem',
  },
  textStarEnd: {
    color: lightTheme.gray,
  },
};
