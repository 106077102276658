import shuffle from 'lodash/shuffle';
import {v4 as UUIDv4} from 'uuid';

export const generateId = (prefix) => {
  if (prefix) {
    return `${prefix}-${UUIDv4()}`;
  }
  return UUIDv4();
};

export const generateIdString = (prefix) => {
  const result = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < charactersLength; i += 1) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  const cutOffIds = result.slice(0, 20);
  const reRandom = shuffle(cutOffIds);
  const generated = reRandom.slice(0, 6);
  if (prefix) {
    return `${prefix}-${generated.join('')}`;
  }
  return generated.join('');
};
