import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {AccordionContainer, AccordionWrapper, AccordionTextWrapper, AccordionText, AccordionTitle} from './styled-component';
import Icon from '../icon';
import Divider from '../divider';
import * as iconName from '../icon/constants';

const Accordion = (props) => {
  const refNodeText = useRef(null);
  const {children, title, titleStyle} = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AccordionContainer onClick={() => setIsOpen(!isOpen)}>
        <AccordionTitle style={titleStyle}>{title}</AccordionTitle>
        <Icon name={!isOpen ? iconName.ARROW_DOWN : iconName.ARROW_UP} />
      </AccordionContainer>
      <AccordionWrapper open={isOpen} height={refNodeText.current}>
        <AccordionTextWrapper>
          <AccordionText
            ref={(ref) => {
              if (ref) {
                refNodeText.current = ref.clientHeight;
              }
            }}>
            {children}
          </AccordionText>
        </AccordionTextWrapper>
      </AccordionWrapper>
      <Divider />
    </>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.any,
  children: PropTypes.string,
};

Accordion.defaultProps = {
  children: '',
  titleStyle: null,
};

export default Accordion;
