import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  padding: 0 0 1rem 0;
`;

export const InputWrapper = styled.div`
  display: flex;
  height: 44px;
  padding: 0 0.5rem 0 0.5rem;
  background-color: white;
  align-items: center;
  border-radius: 6px;
  margin: 0.25rem 0 0.1rem 0;
  ${(props) => props.borderOutline && `border: 1px solid ${props.theme.tinGray};`};
  ${(props) => props.fillBackground && `border: none; background-color: ${props.theme.grayBackground}`};
  ${(props) => props.isFocus && `background-color: transparent; border: 1px solid ${props.theme.primary}`};
  ${(props) => props.warningText && `background-color: transparent; border: 1px solid ${props.theme.danger}`};
  ${(props) => props.isFocus && props.focusWhite && `background-color: white`};
`;

export const InputField = styled.input.attrs((props) => ({
  ...props,
}))`
  ${(props) => !props.outlineBorder && 'border: none'};
  ${(props) => !props.focusBorder && 'outline: none'};
  font-size: 16px;
  width: 100%;
  height: 44px;
  background-color: transparent;
  padding: 0 0.5rem;
  ${(props) => props.icon && 'padding-left: 0.5rem'};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const InputLabel = styled.label`
  font-weight: bold;
  display: inline-block;
`;

export const InputHint = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.gray70};
  ${(props) => props.warning && `color: ${props.theme.danger}`}
`;

export const OverrideStyles = {
  icon: {
    color: lightTheme.grayDisable,
    colorGray: lightTheme.gray,
  },
};
