import * as number from '../../../utils/number';

export const getValueOfFirst = (data) => {
  try {
    return data[0];
  } catch (error) {
    return '';
  }
};

export const replacePrefixLocation = ({location}) => {
  try {
    const splitLocation = location.split('|');
    if (splitLocation[0] === 'กรุงเทพมหานคร') {
      return splitLocation[1];
    }
    return splitLocation[0];
  } catch (error) {
    return location;
  }
};

export const getSalary = ({min, max}) => {
  if (!min && !max) {
    return '';
  }
  if (min && !max) {
    return `${number.numberWithComma({input: min})} บาท`;
  }
  if (!min && max) {
    return `${number.numberWithComma({input: max})} บาท`;
  }
  if (min === max) {
    return `${number.numberWithComma({input: max})} บาท`;
  }
  const salary = ''.concat(number.numberWithComma({input: min}), ' - ', number.numberWithComma({input: max}), ' บาท');
  return salary;
};
