import styled from 'styled-components';

export const StyledText = styled.p`
  ${(props) => props.color && `color: ${props.color}`};
  white-space: pre-line;
  &:hover {
    ${(props) => props.hoverUnderline && `text-decoration: underline`};
  }
`;

export const StyledTextInLine = styled.span`
  ${(props) => props.color && `color: ${props.color}`};
  white-space: pre-line;
  &:hover {
    ${(props) => props.hoverUnderline && `text-decoration: underline`};
  }
`;
