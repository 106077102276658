import React from 'react';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {ChildrenWrapper, OverrideStyles} from './styled-component';

const Link = (props) => {
  const {url, children, wrapperChildren, search, state, newTab, onClick, style} = props;
  const composeStyle = style ? {...OverrideStyles.link, ...style} : OverrideStyles.link;

  if (newTab) {
    return (
      <RouterLink
        to={{
          pathname: url,
          search,
          state: state,
        }}
        onClick={() => {
          onClick();
        }}
        target="_blank"
        rel="noopener noreferrer"
        style={composeStyle}>
        {wrapperChildren && <ChildrenWrapper>{children}</ChildrenWrapper>}
        {!wrapperChildren && <>{children}</>}
      </RouterLink>
    );
  }

  return (
    <RouterLink
      to={{
        pathname: url,
        search,
        state: state,
      }}
      onClick={() => {
        onClick();
      }}
      style={composeStyle}>
      {wrapperChildren && <ChildrenWrapper>{children}</ChildrenWrapper>}
      {!wrapperChildren && <>{children}</>}
    </RouterLink>
  );
};

Link.propTypes = {
  url: PropTypes.string,
  newTab: PropTypes.bool,
  wrapperChildren: PropTypes.bool,
  search: PropTypes.string,
  children: PropTypes.any.isRequired,
  state: PropTypes.object,
  style: PropTypes.any,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  url: '#',
  newTab: false,
  search: null,
  state: {},
  style: null,
  wrapperChildren: false,
  onClick: () => {},
};

export default Link;
