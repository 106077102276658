import React, {useEffect, useState} from 'react';
import {translate} from '../../../../res/language/translate';
import {useSearchHistoryState, useSearchHistoryDispatch} from '../../../../lib/hooks/logic/useSearchHistory';
import {Container, Title, EmptyText, TextDelAll} from './styled-component';
import Divider from '../../../../components/divider';
import Row from '../../../../components/row';
import GestureDetector from '../../../../components/gesture';
import HistoryItemBuilder from './history-item';
import * as historyUtils from '../../../../lib/context/history';
import * as typeDef from '../../../../utils/strong-types';

const SearchHistory = () => {
  const {historyState, isLoadingHistoryState} = useSearchHistoryState();
  const {dispatchDelHistoryAll} = useSearchHistoryDispatch();
  const [dataHistoryState, setDataHistoryState] = useState([]);
  const [dataPinState, setDataPinState] = useState([]);

  useEffect(() => {
    if (historyState) {
      if (typeDef.isArrayLength(historyState.history)) {
        const transHistory = historyState.history.map((elem) => ({
          label: historyUtils.transformHistoryLabel(elem.params),
          ...elem,
        }));
        setDataHistoryState(transHistory);
      } else {
        setDataHistoryState([]);
      }

      if (typeDef.isArrayLength(historyState.pin)) {
        const transPin = historyState.pin.map((elem) => ({
          label: historyUtils.transformHistoryLabel(elem.params),
          ...elem,
        }));
        setDataPinState(transPin);
      } else {
        setDataPinState([]);
      }
    }
  }, [historyState]);

  const onClickDelAll = () => {
    dispatchDelHistoryAll(dataHistoryState);
  };

  return (
    <Container>
      <Title>{translate('home.searchBookmark')}</Title>
      {!isLoadingHistoryState && typeDef.isNotArrayLength(dataPinState) && <EmptyText>{translate('home.emptyBookmark')}</EmptyText>}
      {typeDef.isArrayLength(dataPinState) && <HistoryItemBuilder isPin data={dataPinState} />}
      {typeDef.isArrayLength(dataHistoryState) && <Divider edgeInsets />}
      {!isLoadingHistoryState && typeDef.isArrayLength(dataHistoryState) && (
        <>
          <Row content="space-between">
            <Title>{translate('home.searchHistory')}</Title>
            <GestureDetector withEdgeInsets={false} onClick={onClickDelAll}>
              <TextDelAll>{translate('home.deleteAll')}</TextDelAll>
            </GestureDetector>
          </Row>
          <HistoryItemBuilder data={dataHistoryState} />
        </>
      )}
    </Container>
  );
};

SearchHistory.propTypes = {};

export default SearchHistory;
