import React from 'react';
import {useIntl} from 'react-intl';
import MetaTag from '../../components/meta-tag';
import ListJobIndex from './views';
import BoxBottom from '../main/views/box-bottom';

const JobIndexScreen = () => {
  const {formatMessage} = useIntl();

  return (
    <>
      <MetaTag
        title={formatMessage({defaultMessage: 'งาน หางานกับบริษัทชั้นนำทั่วประเทศ'})}
        description={formatMessage({defaultMessage: 'หางานกับบริษัทชั้นนำทั่วประเทศ'})}
      />
      <ListJobIndex />
      <BoxBottom />
    </>
  );
};

JobIndexScreen.propTypes = {};

export default JobIndexScreen;
