import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useSelector, useDispatch} from 'react-redux';
import qs from 'querystring';
import {useScrollResetAfterPopEffect} from '../../../lib/hooks/useScrollingEffect';
import {Container, IndexWrapper, HeadWrapper, JobItemWrapper, JobItemTitle, JobTitleWrapper, HeaderGroupWrapper} from './styled-component';
import Header from '../../../components/header';
import IndexSubItem from './item';
import * as logic from './logic';
import Link from '../../../components/link';
import * as ROUTES from '../../../routes/routes-name';
import * as seoActions from '../../../redux/seo-index/action';

const ListJobIndex = () => {
  const {formatMessage} = useIntl();
  const dispatch = useDispatch();
  const {job: jobState, location: locationState} = useSelector((state) => state.seoIndex.jobIndex.jobIndex.data);

  useScrollResetAfterPopEffect(true);
  useEffect(() => {
    if (!jobState && !locationState) {
      const job = logic.createJobIndex();
      const location = logic.createLocationIndex();
      dispatch(
        seoActions.updateJobIndex({
          data: {
            job: [...job],
            location: [...location],
          },
        }),
      );
    }
  }, [jobState, locationState, dispatch]);

  return (
    <Container>
      <IndexWrapper>
        <HeadWrapper>
          <Header variant="h2">{formatMessage({defaultMessage: 'หมวดหมู่งานทั้งหมด'})}</Header>
        </HeadWrapper>
        <>
          <HeaderGroupWrapper>
            <Header variant="h3">{formatMessage({defaultMessage: 'หางาน ตามประเภทงาน'})}</Header>
          </HeaderGroupWrapper>
          {Array.isArray(jobState) &&
            jobState.map((item, index) => (
              <JobItemWrapper key={index.toString()}>
                <JobTitleWrapper>
                  <Link url={ROUTES.ROUTE_SEARCH_RESULTS} search={qs.stringify({jobcat: [item.jobCat]})}>
                    <JobItemTitle>{`${formatMessage({defaultMessage: 'หางาน'})} ${item.jobCat}`}</JobItemTitle>
                  </Link>
                </JobTitleWrapper>
                <IndexSubItem keyJob={item.jobCat} data={item.child} keyChild="subjobcat" />
              </JobItemWrapper>
            ))}
        </>
        <>
          <HeaderGroupWrapper>
            <Header variant="h3">{formatMessage({defaultMessage: 'หางาน ตามสถานที่ปฏิบัติงาน'})}</Header>
          </HeaderGroupWrapper>
          {Array.isArray(locationState) &&
            locationState.map((item, index) => (
              <JobItemWrapper key={index.toString()}>
                <JobTitleWrapper>
                  <Link url={ROUTES.ROUTE_SEARCH_RESULTS} search={qs.stringify({province: [item.province]})}>
                    <JobItemTitle>{`${formatMessage({defaultMessage: 'หางาน'})} ${item.province}`}</JobItemTitle>
                  </Link>
                </JobTitleWrapper>
                <IndexSubItem keyJob={item.province} data={item.district} keyChild="district" />
              </JobItemWrapper>
            ))}
        </>
        <>
          <JobTitleWrapper>
            <Link url={ROUTES.ROUTE_SEARCH_RESULTS} search={qs.stringify({tag: '#BTS'})}>
              <JobItemTitle>{`${formatMessage({defaultMessage: 'หางาน'})} ใกล้ BTS`}</JobItemTitle>
            </Link>
          </JobTitleWrapper>
          <JobTitleWrapper>
            <Link url={ROUTES.ROUTE_SEARCH_RESULTS} search={qs.stringify({tag: '#MRT'})}>
              <JobItemTitle>{`${formatMessage({defaultMessage: 'หางาน'})} ใกล้ MRT`}</JobItemTitle>
            </Link>
          </JobTitleWrapper>
        </>
      </IndexWrapper>
    </Container>
  );
};

ListJobIndex.propTypes = {};

export default ListJobIndex;
