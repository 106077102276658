import React from 'react';
import {translate} from '../../res/language/translate';
import NoInternet from './views';
import MetaTags from '../../components/meta-tag';

const NoInternetScreen = () => {
  return (
    <>
      <MetaTags title={translate('meta.noInternet')} description={translate('meta.noInternet')} contentUrl={null} />
      <NoInternet />
    </>
  );
};

NoInternetScreen.propTypes = {};

export default NoInternetScreen;
