import {get, isEmpty, isNull} from 'lodash';
import * as numbers from '../../../utils/number';

const getJobDetail = (joblist, count) => {
  const provice = [];
  const jobTitle = [];
  const salary = [];

  joblist.forEach((job) => {
    provice.push(get(job, 'province[0]'));
    jobTitle.push(get(job, 'job_title[0]'));
    if (!isNull(job.salary_max)) {
      salary.push(get(job, 'salary_max'));
    }
  });

  const uniqProvince = Array.from(new Set(provice));
  const setTitle = jobTitle.length > 3 ? `${jobTitle[0]} ${jobTitle[1]} ${jobTitle[2]}` : jobTitle.join(' ');
  const setProvice = uniqProvince.length > 3 ? `${uniqProvince[0]} ${uniqProvince[1]} ${uniqProvince[2]}` : uniqProvince.join(' ');
  const setDescription = `มีตำแหน่งาน ${numbers.numberWithComma({input: count})} ตำแหน่ง ในจังหวัด ${setProvice} เช่น ${setTitle}`;

  return setDescription;
};
export const useDescription = (joblist, count) => {
  let description = `มีตำแหน่งงานมากมายให้ท่านเลือก ค้นหางานผ่าน Jobfinfin`;
  if (!isEmpty(joblist)) {
    description = getJobDetail(joblist, count);
  }
  return description;
};
