import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {translate} from '../../../res/language/translate';
import {
  Container,
  SectionWrapper,
  BottomDescription,
  CopyrightWrapper,
  RowWrapper,
  DescriptionWrapper,
  ItemWrapper,
  StoreImg,
  Cropper,
  TextTerm,
  OverrideStyle,
} from './styled-component';
import BoxSEOView from './seo/box-seo';
import Header from '../../../components/header';
import Text from '../../../components/text';
import Link from '../../../components/link';
import AppLogo from '../../../views/logo/logo';
import Facebook from '../../../res/assets/images/landing/iconFacebook.png';
import Line from '../../../res/assets/images/landing/iconLine.png';
import CONTACT from '../../../res/json/JFF-contact.json';
import PACKAGE from '../../../../package.json';
import * as ROUTES from '../../../routes/routes-name';
import * as utils from './utils';

const BoxBottom = () => {
  const [presentState] = useState(() => utils.createBottomPresent());
  const {formatMessage} = useIntl();

  return (
    <Container>
      <SectionWrapper background noPadding center>
        <Cropper>
          <BoxSEOView />
          <RowWrapper>
            <ItemWrapper noneAlign noPadding floatStick="flex-end">
              <BottomDescription>
                <AppLogo fixed />
                <DescriptionWrapper>
                  <Text style={OverrideStyle.bottomPresent}>{presentState}</Text>
                </DescriptionWrapper>
              </BottomDescription>
            </ItemWrapper>
            <ItemWrapper column center noPadding floatStick="flex-start">
              <Header variant="h3">{translate('landing.application')}</Header>
              <ItemWrapper row noPadding floatStick="flex-start">
                <Link newTab url={CONTACT.FACEBOOK_OFFICIAL} style={OverrideStyle.marginRight}>
                  <StoreImg src={Facebook} />
                </Link>
                <Link newTab url={CONTACT.LINE_OFFICIAL}>
                  <StoreImg src={Line} />
                </Link>
              </ItemWrapper>
            </ItemWrapper>
          </RowWrapper>
        </Cropper>
      </SectionWrapper>
      <CopyrightWrapper noEdgeInsets>
        <Text style={OverrideStyle.version}>{`version: ${PACKAGE.version}`}</Text>
      </CopyrightWrapper>
      <CopyrightWrapper>
        <Text style={OverrideStyle.copyRight}>{`${translate('landing.copyrightPrefix')} \u00A9 2563`}</Text>
        <Link newTab url={ROUTES.ROUTE_TERM_AND_AGREEMENT}>
          <TextTerm>{formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์'})}</TextTerm>
        </Link>
        <Link newTab url={ROUTES.ROUTE_COOKIE_POLICY}>
          <TextTerm>{formatMessage({defaultMessage: 'นโยบายคุกกี้'})}</TextTerm>
        </Link>
      </CopyrightWrapper>
    </Container>
  );
};

BoxBottom.propTypes = {};

export default BoxBottom;
