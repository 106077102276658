import {put, call, all, delay} from 'redux-saga/effects';
import {getErrorMessage} from '../utils/error-store';
import {setAuthToken, setCurrentUser, setAppleEnv, clearStorage} from '../../utils/storage/storage';
import {getPlatform} from '../../utils/platform';
import {translate} from '../../res/language/translate';
import {cancelInvokeOTPProvider} from '../utils/fast-dispatch';
import {logoutFirebase, loginFirebase} from '../../utils/firebase/firebase-auth';
import PackageVersion from '../../../package.json';
import * as snackbar from '../../utils/components/toast-notification/provider';
import * as api from './api';
import * as regisApi from '../register/api';
import * as TYPES from './type';
import * as ALERT_TYPES from '../alert/type';
import * as SEARCH_TYPES from '../search/type';
import * as USER_TYPES from '../user/type';
import * as REGISTER_TYPES from '../register/type';
import * as navigationServices from '../../utils/navigation/navigation-services';
import * as routeName from '../../routes/routes-name';
import * as authUtils from './logic';
import * as mixpanel from '../../utils/mixpanel';

export function* userAuthCurrentAction({payload}) {
  if (payload?.callCheck) {
    yield put({
      type: TYPES.AUTH_IS_CHECKED_SUCCESS,
    });
    yield put({
      type: USER_TYPES.USER_SET_INIT,
    });
    return;
  }
  try {
    const response = yield call(api.userAuthCurrent, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.AUTH_CURRENT_SUCCESS,
        payload: response.data?.data?.user,
      });
      mixpanel.authCurrent(response.data?.data?.user);
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const responseStatus = error?.response?.status || 400;
    yield all([
      put({
        type: TYPES.AUTH_CURRENT_FAIL,
        payload: {
          message: responseError,
          status: responseStatus,
        },
      }),
    ]);
  }
}

export function* userLoginManual({payload}) {
  if (!payload) {
    yield put({type: TYPES.INVALID_PAYLOAD, payload: 'invalid payload'});
  }
  try {
    const reqBody = payload?.data || payload;
    const response = yield call(api.userLoginManual, reqBody);
    const isAppleStore = response.data?.data?.apple || false;
    if (getPlatform() !== 'web') {
      setAppleEnv(`${isAppleStore}`);
    }
    setAuthToken(response?.data?.data?.token);
    setCurrentUser(response?.data?.data?._id);
    yield all([
      put({
        type: SEARCH_TYPES.SEARCH_POPULAR_JOB_CAT_CLEAR,
      }),
      put({type: USER_TYPES.USER_DETAIL_PROFILE_REQ}),
    ]);
    yield delay(500);
    yield put({
      type: TYPES.AUTH_CURRENT_REQ,
      payload: {
        data: {version: PackageVersion.version, platform: getPlatform(), token: response?.data?.data?.token},
      },
    });
    mixpanel.loginEmail(response.data?.data);
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const responseStatus = error?.response?.status || 400;
    yield all([
      put({
        type: TYPES.AUTH_LOGIN_FAIL,
        payload: {
          message: responseError,
          status: responseStatus,
        },
      }),
    ]);
    mixpanel.loginFail(error);
  }
}

export function* userLoginFacebookAction({payload}) {
  try {
    const reqBody = payload?.data || payload;
    const response = yield call(api.userLoginFacebook, reqBody);
    if (response.status === 200) {
      const isAppleStore = response.data?.data?.apple || false;
      if (getPlatform() !== 'web') {
        setAppleEnv(`${isAppleStore}`);
      }
      setAuthToken(response?.data?.data?.token);
      setCurrentUser(response?.data?.data?._id);
      yield delay(500);
      yield put({
        type: TYPES.AUTH_FACEBOOK_LOGIN_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: TYPES.AUTH_CURRENT_REQ,
        payload: {
          data: {version: PackageVersion.version, platform: getPlatform(), token: response?.data?.data?.token},
        },
      });
      mixpanel.loginFacebook(response.data);
      navigationServices.windowNavigateTo({pathname: routeName.ROUTE_HOME});
    }
  } catch (error) {
    yield put({
      type: REGISTER_TYPES.REGISTER_VISIBLE_STEP_REQ,
      payload: {data: 2},
    });
    const responseError = error?.response?.data?.error;
    yield all([
      put({
        type: TYPES.AUTH_FACEBOOK_LOGIN_FAIL,
        payload: {
          error: responseError,
          data: payload,
        },
      }),
    ]);
    mixpanel.loginFail(error);
  }
}

export function* userAuthLogoutAction({payload}) {
  try {
    const response = yield call(api.userAuthLogout, payload);
    clearStorage();
    yield call(logoutFirebase);
    mixpanel.logout();
    yield delay(500);
    yield put({
      type: TYPES.AUTH_LOGOUT_SUCCESS,
      payload: {
        message: response.data?.message,
      },
    });
    yield put({
      type: ALERT_TYPES.ALERT_REMOVE_ALERT_REQ,
    });
    navigationServices.windowNavigateTo({pathname: routeName.ROUTE_HOME});
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const responseStatus = error?.response?.status || 400;
    yield all([
      put({
        type: TYPES.AUTH_LOGOUT_FAIL,
        payload: {
          message: responseError,
          status: responseStatus,
        },
      }),
    ]);
  }
}

export function* userLoginFromRegisterAction({payload}) {
  if (!payload?.error) {
    if (!payload?.data) {
      return;
    }
    const payloadData = authUtils.getLoginFormRegisterData({payload});
    const isAppleStore = payloadData?.apple || false;
    if (getPlatform() !== 'web') {
      setAppleEnv(`${isAppleStore}`);
    }
    setAuthToken(payloadData?.token);
    setCurrentUser(payloadData?._id);
    mixpanel.register(payloadData);
    yield delay(1000);
    yield put({
      type: TYPES.AUTH_LOGIN_FROM_REGISTER_SUCCESS,
      payload: payloadData,
    });
  } else {
    yield put({
      type: TYPES.AUTH_LOGIN_FROM_REGISTER_FAIL,
      payload: payload?.error,
    });
    mixpanel.registerFail(payload.error);
  }
}

export function* userForgotPasswordRequestCodeAction({payload}) {
  try {
    yield call(api.userForgotPasswordRequestCode, payload?.data);
    yield put({
      type: TYPES.AUTH_FORGOT_PASSWORD_REQUEST_CODE_SUCCESS,
      payload: payload?.data?.tel,
    });
    snackbar.setSnackbarMessage(translate('register.sendOtpSuccess'));
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const alertData = {
      message: getErrorMessage(responseError),
    };
    yield all([
      put({
        type: TYPES.AUTH_FORGOT_PASSWORD_REQUEST_CODE_FAIL,
        payload: alertData.message,
      }),
      put({
        type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
        payload: {data: alertData},
      }),
    ]);
    if (responseError === 'waiting for older otp expire') {
      cancelInvokeOTPProvider(payload?.data?.tel);
    }
  }
}

export function* userForgotPasswordCheckCodeAction({payload}) {
  try {
    const response = yield call(api.userForgotPasswordCheckCode, payload?.data?.reqVerify);
    yield put({
      type: TYPES.AUTH_FORGOT_PASSWORD_CHECK_CODE_SUCCESS,
      payload: response.data?.user,
    });
    const reqForm = payload?.data?.reqForm || {};
    const reqSetPasswordForm = {
      user_id: response.data?.data?.user?._id,
      tel: reqForm.tel,
      role: reqForm.role,
      password: reqForm.password,
    };
    yield put({
      type: TYPES.AUTH_FORGOT_PASSWORD_RESET_PASSWORD_REQ,
      payload: {data: reqSetPasswordForm},
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const alertData = {
      message: getErrorMessage(responseError),
    };
    yield all([
      put({
        type: TYPES.AUTH_FORGOT_PASSWORD_CHECK_CODE_FAIL,
        payload: responseError,
      }),
      put({
        type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
        payload: {data: alertData},
      }),
    ]);
    if (responseError === 'waiting for older otp expire') {
      cancelInvokeOTPProvider(payload?.data?.reqForm?.tel);
    }
  }
}

export function* userForgotPasswordSetPasswordAction({payload}) {
  try {
    const response = yield call(api.userForgotPasswordSetPassword, payload?.data);
    yield put({
      type: TYPES.AUTH_FORGOT_PASSWORD_RESET_PASSWORD_SUCCESS,
      payload: response?.data?.data?.user,
    });
  } catch (error) {
    const responseError = error?.response?.data?.error;
    const alertData = {
      message: getErrorMessage(responseError),
    };
    yield all([
      put({
        type: TYPES.AUTH_FORGOT_PASSWORD_RESET_PASSWORD_FAIL,
        payload: responseError,
      }),
      put({
        type: ALERT_TYPES.ALERT_SET_ALERT_REQ,
        payload: {data: alertData},
      }),
    ]);
  }
}

export function* userForgotPasswordChangePhoneAction() {
  yield put({
    type: TYPES.AUTH_FORGOT_PASSWORD_CHANGE_PHONE_SUCCESS,
  });
}

export function* userAuthorizeDeniedAction({payload}) {
  yield put({
    type: TYPES.AUTH_AUTHORIZE_DENIED,
    payload: payload,
  });
}

export function* userOTPCancelInvokeAction({payload}) {
  if (payload?.clear) {
    return;
  }
  try {
    const response = yield call(api.userCancelOTP, payload?.data);
    if (response.status === 200) {
      yield put({
        type: TYPES.AUTH_OTP_CANCEL_INVOKE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    const responseError = error?.response?.data?.error;
    yield put({
      type: TYPES.AUTH_OTP_CANCEL_INVOKE_FAIL,
      payload: responseError,
    });
  }
}

export function* userForgotPasswordClearAction() {
  yield put({
    type: TYPES.AUTH_FORGOT_PASSWORD_CLEAR_SUCCESS,
  });
}

export function* authUpdateCurrentAction({payload}) {
  yield put({
    type: TYPES.AUTH_UPDATE_CURRENT_SUCCESS,
    payload: payload,
  });
}

export function* firebaseAnonymousLogin() {
  try {
    const response = yield call(regisApi.generateId);
    const resLoginFirebase = yield call(api.userLoginFirebase, {
      user_id: response?.data,
    });
    yield call(loginFirebase, resLoginFirebase.data?.token);
    yield put({
      type: TYPES.FIREBASE_ANONYMOUS_LOGIN_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: TYPES.FIREBASE_ANONYMOUS_LOGIN_FAIL,
      payload: error,
    });
  }
}

export function* firebaseAnonymousLogout() {
  try {
    yield call(logoutFirebase);
    yield put({
      type: TYPES.FIREBASE_ANONYMOUS_LOGOUT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: TYPES.FIREBASE_ANONYMOUS_LOGOUT_FAIL,
      payload: error,
    });
  }
}
