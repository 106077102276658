import React from 'react';
import {useIntl} from 'react-intl';
import {useScrollYDirectionEffect} from '../../lib/hooks/useScrollingEffect';
import {useSearchCountState} from '../../lib/hooks-logic/useSearchResultCount';
import {useScrollSearchEffect} from '../../lib/hooks-logic/useScrollSearch';
import {useGetReduxState} from '../../lib/hooks-logic/useGetReduxState';
import {useSearchQueryEffect, useSearchQueryState, useSearchQueryDispatch} from './hooks/useSearchQuery';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import JobPostBuilder from '../../views/job-post-builder';
import Screen from '../../views/screen';
import MetaTags from '../../components/meta-tag';
import FilterBuilder from '../../views/filter-builder';
import FilterSmallBuilder from '../../views/app-bar-filter';
import NavbarFilter from '../../views/app-bar-filter/nav-bar-filter';
import FuzzySearch from './views/fuzzy-search';
import * as deepState from '../../utils/deep-state';
import {parseJsonLd} from '../../lib/context/parse-jsonld';
import {useDescription} from './hooks/useDescription';
import {useTitle} from './hooks/useTitle';

const SearchResultScreen = () => {
  useSetGrayBackground();
  useSearchQueryEffect();
  useScrollSearchEffect();

  const {formatMessage} = useIntl();
  const {scrollDirection} = useScrollYDirectionEffect();
  const {resultCount} = useSearchCountState();
  const {dispatchFetchSearchQueryNearby} = useSearchQueryDispatch();
  const {dispatchFetchSearchQuery} = useSearchQueryDispatch();

  const {
    reduxState: {loading: isLoadingFuzzyState, sinkQueryNearby, pageIndex, cacheNearByToken, stackFeed: fuzzyFeedState, newFeed: fuzzyNewFeedState},
  } = useGetReduxState('search.jobQuery.queryNearby');
  const {
    reduxState: {cacheToken: nonFuzzyTokenState},
  } = useGetReduxState('search.jobQuery');
  const {stackFeedState, isLoadingState, isEmptyDataState, isCanFetchMoreState, pageIndexState, cacheTokenState, newFeedState} =
    useSearchQueryState();
  const description = useDescription(stackFeedState);
  const title = useTitle(stackFeedState, newFeedState?.countDisplay);

  const onEventFuzzySearch = () => {
    if (isLoadingState) {
      return;
    }
    dispatchFetchSearchQueryNearby(pageIndex, cacheNearByToken, nonFuzzyTokenState);
  };

  const onEventEndReached = () => {
    if (!isLoadingState && !isEmptyDataState && isCanFetchMoreState) {
      dispatchFetchSearchQuery(pageIndexState, cacheTokenState);
    }
    const isCanFetchFuzzy =
      sinkQueryNearby && !isLoadingFuzzyState && Array.isArray(fuzzyFeedState) && fuzzyNewFeedState?.resultCount > fuzzyFeedState.length;
    if (isCanFetchFuzzy) {
      onEventFuzzySearch();
    }
  };

  return (
    <>
      <MetaTags title={title} description={description} scriptLdJson={parseJsonLd(stackFeedState, 'search.tag')} />
      <Screen
        noEdgeInsets
        isChildScreen
        title={formatMessage({defaultMessage: 'ผลลัพธ์การค้นหา'})}
        subTitle={`${resultCount} รายการ`}
        type="two-col-layout"
        leftComponent={<FilterBuilder />}
        onEndReached={onEventEndReached}>
        <>
          <FilterSmallBuilder />
          <NavbarFilter isShow={scrollDirection.top} />
          <JobPostBuilder
            noTopEdgeInsets
            noBottomEdgeInsets
            data={deepState.mergeArray({data1: stackFeedState, data2: fuzzyFeedState})}
            isEmptyData={isEmptyDataState}
            isLoadingFeed={isLoadingState || isLoadingFuzzyState}
          />
          <FuzzySearch />
        </>
      </Screen>
    </>
  );
};

SearchResultScreen.propTypes = {};

export default SearchResultScreen;
