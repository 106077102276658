import {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';
import {useAuthState} from '../../../lib/hooks-logic/useAuth';
import {useTrackJobInteractState, useTrackJobInteractDispatch} from '../../../lib/hooks-logic/useTrackEvent';
import {getAuthToken} from '../../../utils/storage/storage';
import * as searchActions from '../../../redux/search/action';
import * as typeDef from '../../../utils/strong-types';

export const useJobEventEffect = () => {
  const dispatch = useDispatch();

  const {isCurrentLoggedInState} = useAuthState();
  const {stackJobInteractEvent, streamJobInteractEvent} = useTrackJobInteractState();
  const {dispatchJobInteractStreamStack} = useTrackJobInteractDispatch();
  const interestStackState = useSelector((state) => state.search.jobInterestAndSeen.interest.stackInterest, shallowEqual);

  const onTrackingEventStreamInteract = () => {
    const diffEvent = differenceWith(stackJobInteractEvent, streamJobInteractEvent, isEqual);
    if (!diffEvent.length || !getAuthToken()) {
      return;
    }
    dispatchJobInteractStreamStack(diffEvent);
  };

  const onTrackingEventStreamInterest = () => {
    const streamBody = uniqBy(interestStackState, '_id');
    dispatch(searchActions.searchInterestJob({data: streamBody}));
  };

  useEffect(() => {
    if (isCurrentLoggedInState && typeDef.isArrayLength(stackJobInteractEvent)) {
      onTrackingEventStreamInteract();
    }
  }, [isCurrentLoggedInState, stackJobInteractEvent]);

  useEffect(() => {
    if (typeDef.isArrayLength(interestStackState)) {
      onTrackingEventStreamInterest();
    }
  }, [interestStackState]);
};
