import {createServiceURL} from '../../env/utils/env-services';
import {adapterGet} from '../../utils/adapter/get';
import {adapterPost} from '../../utils/adapter/post';

export const applyJobCheckInfo = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/check_information_user`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const applyJobGetDraft = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/draft`;
  const response = await adapterGet({url: API_URL, privateMethod: true});
  return response;
};

export const applyJobUpdateDraft = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/job/draft_email`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};

export const applyJobGetResumeTemplate = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/resume/templates`;
  const response = await adapterGet({url: API_URL, privateMethod: true});
  return response;
};

export const applyJobGenerateResume = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/resume/upload`;
  const response = await adapterPost({url: API_URL, body: reqBody, privateMethod: true});
  return response;
};
