import {takeEvery} from 'redux-saga/effects';
import * as TYPES from './type';
import * as authSaga from './saga';

export function* watchAuthCurrent() {
  yield takeEvery(TYPES.AUTH_CURRENT_REQ, authSaga.userAuthCurrentAction);
}

export function* watchAuthLogout() {
  yield takeEvery(TYPES.AUTH_LOGOUT_REQ, authSaga.userAuthLogoutAction);
}

export function* watchUserLoginManual() {
  yield takeEvery(TYPES.AUTH_LOGIN_MANUAL_REQ, authSaga.userLoginManual);
}

export function* watchUserLoginFacebook() {
  yield takeEvery(TYPES.AUTH_FACEBOOK_LOGIN_REQ, authSaga.userLoginFacebookAction);
}
export function* watchUserRegisterFacebook() {
  yield takeEvery(TYPES.AUTH_FACEBOOK_LOGIN_REQ, authSaga.userLoginFacebookAction);
}

export function* watchUserLoginFromRegister() {
  yield takeEvery(TYPES.AUTH_LOGIN_FROM_REGISTER_REQ, authSaga.userLoginFromRegisterAction);
}

export function* watchUserForgotPasswordRequestCode() {
  yield takeEvery(TYPES.AUTH_FORGOT_PASSWORD_REQUEST_CODE_REQ, authSaga.userForgotPasswordRequestCodeAction);
}

export function* watchUserForgotPasswordCheckCode() {
  yield takeEvery(TYPES.AUTH_FORGOT_PASSWORD_CHECK_CODE_REQ, authSaga.userForgotPasswordCheckCodeAction);
}

export function* watchUserForgotPasswordSetPassword() {
  yield takeEvery(TYPES.AUTH_FORGOT_PASSWORD_RESET_PASSWORD_REQ, authSaga.userForgotPasswordSetPasswordAction);
}

export function* watchUserForgotPasswordChangePhone() {
  yield takeEvery(TYPES.AUTH_FORGOT_PASSWORD_CHANGE_PHONE_REQ, authSaga.userForgotPasswordChangePhoneAction);
}

export function* watchUserAuthorize() {
  yield takeEvery(TYPES.AUTH_AUTHORIZE_REQ, authSaga.userAuthorizeDeniedAction);
}

export function* watchUserOTPCancelInvoke() {
  yield takeEvery(TYPES.AUTH_OTP_CANCEL_INVOKE_REQ, authSaga.userOTPCancelInvokeAction);
}

export function* watchUserForgotPasswordClear() {
  yield takeEvery(TYPES.AUTH_FORGOT_PASSWORD_CLEAR_REQ, authSaga.userForgotPasswordClearAction);
}

export function* watchAuthUpdateCurrent() {
  yield takeEvery(TYPES.AUTH_UPDATE_CURRENT_REQ, authSaga.authUpdateCurrentAction);
}

export function* watchFirebaseAnonymousLogin() {
  yield takeEvery(TYPES.FIREBASE_ANONYMOUS_LOGIN_REQ, authSaga.firebaseAnonymousLogin);
}

export function* watchFirebaseAnonymousLogout() {
  yield takeEvery(TYPES.FIREBASE_ANONYMOUS_LOGOUT_REQ, authSaga.firebaseAnonymousLogout);
}
