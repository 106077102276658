import {takeEvery, takeLatest} from 'redux-saga/effects';
import * as TYPES from './type';
import * as searchSaga from './saga';

export function* watchSearchGetLocation() {
  yield takeEvery(TYPES.SEARCH_LOCATION_NEAREST_REQ, searchSaga.searchLocationNearestAction);
}

export function* watchSearchCacheLocation() {
  yield takeEvery(TYPES.SEARCH_CACHE_LOCATION_NEAREST_REQ, searchSaga.searchCacheLocationNearestAction);
}

export function* watchSearchGetPopularJobCat() {
  yield takeEvery(TYPES.SEARCH_POPULAR_JOB_CAT_REQ, searchSaga.searchPopularJobCatAction);
}

export function* watchSearchPopularUpdateLocation() {
  yield takeLatest(TYPES.SEARCH_POPULAR_UPDATE_LOCATION_REQ, searchSaga.searchPopularJobUpdateLocationAction);
}

export function* watchSearchGetAllJobCat() {
  yield takeEvery(TYPES.SEARCH_ALL_JOB_CAT_REQ, searchSaga.searchAllJobCatAction);
}

export function* watchSearchJobTimeline() {
  yield takeEvery(TYPES.SEARCH_JOB_TIMELINE_REQ, searchSaga.searchJobTimelineAction);
}

export function* watchSearchJobTimelineRefresh() {
  yield takeEvery(TYPES.SEARCH_JOB_TIMELINE_REFRESH_REQ, searchSaga.searchJobTimelineRefreshAction);
}

export function* watchSearchJobLiked() {
  yield takeEvery(TYPES.SEARCH_JOB_LIKED_REQ, searchSaga.searchJobLikedAction);
}

export function* watchSearchJobLikedRefresh() {
  yield takeEvery(TYPES.SEARCH_JOB_LIKED_REFRESH_REQ, searchSaga.searchJobLikedRefreshAction);
}

export function* watchSearchHistory() {
  yield takeEvery(TYPES.SEARCH_JOB_HISTORY_REQ, searchSaga.searchHistoryAction);
}

export function* watchSearchHistoryPin() {
  yield takeEvery(TYPES.SEARCH_JOB_PIN_REQ, searchSaga.searchHistoryPinAction);
}

export function* watchSearchHistoryDelete() {
  yield takeEvery(TYPES.SEARCH_HISTORY_DELETE_REQ, searchSaga.searchHistoryDeleteAction);
}

export function* watchSearchQuery() {
  yield takeEvery(TYPES.SEARCH_JOB_QUERY_REQ, searchSaga.searchQueryAction);
}

export function* watchSearchQueryNearby() {
  yield takeEvery(TYPES.SEARCH_JOB_QUERY_NEARBY_REQ, searchSaga.searchQueryNearbyAction);
}

export function* watchSearchHashtag() {
  yield takeEvery(TYPES.SEARCH_JOB_HASHTAG_REQ, searchSaga.searchHashtagAction);
}

export function* watchSearchId() {
  yield takeEvery(TYPES.SEARCH_JOB_ID_REQ, searchSaga.searchJobIdAction);
}

export function* watchSearchSaveForm() {
  yield takeEvery(TYPES.SEARCH_SAVE_FORM_REQ, searchSaga.searchSaveFormAction);
}

export function* watchSearchSeenJob() {
  yield takeEvery(TYPES.SEARCH_SEEN_JOB_REQ, searchSaga.searchSeenJobAction);
}

export function* watchSearchInterestDebounce() {
  yield takeLatest(TYPES.SEARCH_INTEREST_DEBOUNCE_REQ, searchSaga.searchInterestDebounceAction);
}

export function* watchSearchInterestJob() {
  yield takeLatest(TYPES.SEARCH_INTEREST_STREAM_REQ, searchSaga.searchInterestJobAction);
}

export function* watchSearchGetHiddenJob() {
  yield takeEvery(TYPES.SEARCH_JOB_HIDDEN_LIST_REQ, searchSaga.searchGetHiddenJobAction);
}

export function* watchSearchHiddenJob() {
  yield takeEvery(TYPES.SEARCH_JOB_HIDDEN_REQ, searchSaga.searchHiddenJobAction);
}

export function* watchSearchUnHiddenJob() {
  yield takeEvery(TYPES.SEARCH_JOB_UNHIDDEN_REQ, searchSaga.searchUnHiddenJobAction);
}

export function* watchSearchJobAuthor() {
  yield takeEvery(TYPES.SEARCH_JOB_AUTHOR_REQ, searchSaga.searchJobAuthorAction);
}

export function* watchSearchJobGroupGetParams() {
  yield takeEvery(TYPES.SEARCH_JOB_GROUP_PARAMS_REQ, searchSaga.searchJobGroupGetParamsAction);
}

export function* watchSearchJobGroup() {
  yield takeEvery(TYPES.SEARCH_JOB_GROUP_REQ, searchSaga.searchJobGroupAction);
}

export function* watchSearchJobGroupRefresh() {
  yield takeEvery(TYPES.SEARCH_JOB_GROUP_REFRESH_REQ, searchSaga.searchJobGroupRefreshAction);
}

export function* watchSearchJobGroupUpdateFilter() {
  yield takeEvery(TYPES.SEARCH_JOB_GROUP_UPDATE_FILTER_REQ, searchSaga.searchJobGroupUpdateFilterAction);
}

export function* watchSearchJobGroupList() {
  yield takeEvery(TYPES.SEARCH_JOB_GROUP_LIST_REQ, searchSaga.searchJobGroupListAction);
}

export function* watchSearchJobQueryStoreParams() {
  yield takeEvery(TYPES.SEARCH_JOB_QUERY_STORE_PARAMS_REQ, searchSaga.searchJobQueryStoreParamsAction);
}
