import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

export const mergeEvent = (oldEvent, newEvent) => {
  let state = [];
  if (Array.isArray(oldEvent)) {
    state = [...oldEvent];
  }
  if (typeof newEvent === 'object') {
    state = [...state, {...newEvent}];
  }
  return uniqWith(state, isEqual);
};

export const mergeEventArray = (oldEvent, newEvent) => {
  let state = [];
  if (Array.isArray(oldEvent)) {
    state = [...oldEvent];
  }
  if (Array.isArray(newEvent)) {
    state = [...state, ...newEvent];
  }
  return state;
};
