import React from 'react';
import {useSelector} from 'react-redux';
import {getAuthToken} from '../../utils/storage/storage';
import LoggedOutAppBar from './logged-out';
import LoggedInAppBar from './logged-in';

const AppBar = () => {
  const isLoggedInState = getAuthToken();
  const isLoading = useSelector((state) => state.auth.auth.loading);
  if (isLoading) {
    return null;
  }
  if (isLoggedInState) {
    return <LoggedInAppBar />;
  }
  return <LoggedOutAppBar />;
};

AppBar.propTypes = {};

export default AppBar;
