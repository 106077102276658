import React from 'react';
import PropTypes from 'prop-types';
import {Container, ContentWrapper, Title, DataLoadingWrapper, ContainerRefresh, ContentRefresh} from './styled-component';
import Spinner from '../spinner';

const TEXT_LOADING = 'กำลังโหลด';

const Loading = (props) => {
  const {loading, type} = props;
  if (!loading) {
    return null;
  }

  if (type === 'data') {
    return (
      <DataLoadingWrapper>
        <Spinner />
      </DataLoadingWrapper>
    );
  }

  if (type === 'refresh') {
    return (
      <ContainerRefresh>
        <ContentRefresh>
          <Spinner size={44} />
        </ContentRefresh>
      </ContainerRefresh>
    );
  }

  return (
    <Container>
      <ContentWrapper>
        <Spinner />
        <Title>{TEXT_LOADING}</Title>
      </ContentWrapper>
    </Container>
  );
};

Loading.propTypes = {
  type: PropTypes.oneOf(['action', 'refresh', 'data']),
  loading: PropTypes.bool,
};

Loading.defaultProps = {
  type: 'action',
  loading: false,
};

export default Loading;
