import * as ICONS from '../../../components/icon/constants';
import * as ROUTES from '../../../routes/routes-name';

export const createMenus = () => [
  {
    id: 'setting',
    route: ROUTES.ROUTE_SETTING,
    type: 'icon',
    iconName: ICONS.SETTING,
    borderType: 'bottom',
    title: 'การตั้งค่าและความเป็นส่วนตัว',
  },
  // {
  //   id: 'mail-form',
  //   type: 'icon',
  //   iconName: ICONS.DOCUMENTS,
  //   borderType: 'bottom',
  //   title: 'ฟอร์มอีเมลสมัครงาน',
  // },
  {
    id: 'faq',
    route: ROUTES.ROUTE_FAQ,
    type: 'icon',
    iconName: ICONS.HELP,
    borderType: 'bottom',
    title: 'คำถามที่พบบ่อย',
  },
  {
    id: 'call',
    route: ROUTES.ROUTE_CONTACT,
    type: 'icon',
    iconName: ICONS.PHONE_CALL,
    borderType: 'bottom',
    title: 'ติดต่อเรา',
  },
  {
    id: 'logout',
    type: 'icon',
    iconName: ICONS.LOCKOUT,
    borderType: null,
    title: 'ออกจากระบบ',
  },
];
