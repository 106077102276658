import {useDispatch, useSelector} from 'react-redux';
import * as userActions from '../../redux/user/action';

export const useUserState = () => {
  const {loading: isLoadingState, user: userState, error: errorState} = useSelector((state) => state.user.information);
  return {
    isLoadingState,
    userState,
    errorState,
  };
};

export const useUserResumeState = () => {
  const {loading: isLoadingState, data: dataState, error: errorState} = useSelector((state) => state.user.resume);
  return {
    isLoadingState,
    dataState,
    errorState,
  };
};

export const useUserDispatch = () => {
  const dispatch = useDispatch();

  const dispatchUpdateProfile = (form, noPop = true) => {
    if (!form) {
      return;
    }
    dispatch(userActions.editUserProfile({data: form, noPop}));
  };

  const dispatchChangePassword = (form, callback) => {
    if (!form) {
      return;
    }
    dispatch(userActions.userChangePassword({data: form, onClear: callback ? callback() : null}));
  };

  const dispatchUploadManualResume = (form, clear) => {
    if (!form) {
      return;
    }
    dispatch(userActions.userManualUploadResume({data: form, clear}));
  };

  return {
    dispatchUpdateProfile,
    dispatchUploadManualResume,
    dispatchChangePassword,
  };
};
