import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import * as deepState from '../../utils/deep-state';
import * as typeDef from '../../utils/strong-types';

export const updateSearchUserLike = ({stackLike, newLike}) => {
  if (typeDef.isNullOrUndefined(newLike)) {
    return stackLike;
  }
  if (typeDef.isNotArrayLength(stackLike)) {
    return [{job_id: newLike._id}];
  }
  const copyStackLike = deepState.cloneDeepState({data: stackLike});
  if (typeDef.isNotArrayLength(copyStackLike)) {
    return [{job_id: newLike._id}];
  }
  return [...copyStackLike, {job_id: newLike._id}];
};

export const updateLikeTimelineStackFeed = ({stackFeed, newLike, unlike}) => {
  if (typeDef.isNotArrayLength(stackFeed)) {
    return stackFeed;
  }
  const id = newLike?._id || unlike?._id;
  const copyStackFeed = deepState.cloneDeepState({data: stackFeed});
  const index = findIndex(copyStackFeed, (x) => x._id === id);
  if (index < 0) {
    return stackFeed;
  }
  if (newLike) {
    if (newLike.like_count) {
      copyStackFeed[index].like_count = newLike.like_count;
    } else {
      const currentCount = copyStackFeed[index].like_count;
      copyStackFeed[index].like_count = currentCount + 1;
    }
  }
  if (unlike) {
    if (unlike.like_count) {
      copyStackFeed[index].like_count = unlike.like_count;
    } else {
      const currentCount = copyStackFeed[index].like_count;
      copyStackFeed[index].like_count = currentCount > 0 ? currentCount - 1 : 0;
    }
  }
  return copyStackFeed;
};

export const updateSearchUserUnlike = ({stackLike, unlike}) => {
  if (typeDef.isNullOrUndefined(unlike)) {
    return stackLike;
  }
  if (typeDef.isNotArrayLength(stackLike)) {
    return [];
  }
  return filter(stackLike, (x) => x.job_id !== unlike._id);
};

export const updateJobLikeStackFeed = ({stackFeed, unlike}) => {
  if (typeDef.isNotArrayLength(stackFeed)) {
    return stackFeed;
  }
  const id = unlike?._id;
  const copyStackFeed = deepState.cloneDeepState({data: stackFeed});
  return filter(copyStackFeed, (x) => x._id !== id);
};

export const updateSearchHistory = ({history, newItem}) => {
  if (typeDef.isNullOrUndefined(history) || typeDef.isNullOrUndefined(newItem)) {
    return history;
  }
  const newHistory = {
    pin: [],
    history: [],
  };
  const copyHistory = deepState.cloneDeepState({data: history});
  if (typeDef.isNullOrUndefined(copyHistory)) {
    return history;
  }
  delete newItem.label;
  if (newItem.is_pin) {
    newHistory.pin = deepState.mergeObjectToArrayState({
      prevState: copyHistory.pin,
      newState: newItem,
    });
    newHistory.history = filter(copyHistory.history, (x) => x._id !== newItem._id);
  }
  if (!newItem.is_pin) {
    newHistory.history = deepState.mergeObjectToArrayState({
      prevState: copyHistory.history,
      newState: newItem,
    });
    newHistory.pin = filter(copyHistory.pin, (x) => x._id !== newItem._id);
  }
  return newHistory;
};

export const deleteSearchHistory = ({history, deleteItems}) => {
  if (typeDef.isNullOrUndefined(history) || typeDef.isNullOrUndefined(deleteItems)) {
    return history;
  }
  const newHistory = deepState.cloneDeepState({data: history});
  if (!typeDef.isArray(deleteItems)) {
    newHistory.history = filter(history?.history, (x) => x._id !== deleteItems._id);
    return newHistory;
  }
  newHistory.history = [];
  return newHistory;
};

export const mapPopularJobCat = ({rawData}) => {
  if (typeDef.isNotArrayLength(rawData)) {
    return [];
  }
  const newElements = [...rawData];
  newElements.push({
    id: 999,
    name: 'ดูงานทั้งหมด',
    tag: 'ดูงานทั้งหมด',
  });
  return newElements;
};

export const updateHiddenJob = ({prevState, newState, action = 'add'}) => {
  const mergeState = deepState.cloneDeepState({data: prevState}) || {};
  if (action === 'add') {
    mergeState[newState] = newState;
  } else if (mergeState[newState]) {
    delete mergeState[newState];
  }
  return mergeState;
};

export const createHiddenJobStream = ({prevState, newState}) => {
  if (typeDef.isNotArrayLength(newState)) {
    return prevState;
  }
  let streamState;
  if (typeDef.isObject(prevState)) {
    streamState = {...prevState};
  } else {
    streamState = {};
  }
  newState.forEach((element) => {
    streamState[element.post_id] = element.post_id;
  });
  return streamState;
};

export const updateJobIdStream = ({job, newState}) => {
  if (typeDef.isNotObject(job) && typeDef.isNullOrUndefined(newState?.job)) {
    return job;
  }
  const copyPrevState = deepState.cloneDeepState({data: job});
  copyPrevState.like_count = newState?.job?.like_count;
  return copyPrevState;
};

export const isFindNearbyJob = ({stackFeed, resultCount}) => {
  if (stackFeed?.length === resultCount) {
    return true;
  }
  return false;
};
