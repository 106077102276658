import React from 'react';
import PropTypes from 'prop-types';
import {StyledText, StyledTextInLine} from './styled-component';

const Text = (props) => {
  const {children, style, color, variant, hoverUnderline} = props;
  if (variant === 'span') {
    return (
      <StyledTextInLine color={color} style={style} hoverUnderline={hoverUnderline}>
        {children}
      </StyledTextInLine>
    );
  }
  return (
    <StyledText color={color} style={style} hoverUnderline={hoverUnderline}>
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  variant: PropTypes.oneOf(['p', 'span']),
  children: PropTypes.any,
  color: PropTypes.string,
  style: PropTypes.any,
  hoverUnderline: PropTypes.bool,
};

Text.defaultProps = {
  variant: 'p',
  children: '',
  style: null,
  color: '',
  hoverUnderline: false,
};

export default Text;
