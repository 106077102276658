import {regexName, regexTel, regexEmail} from '../../../../utils/regex';
import {translate} from '../../../../res/language/translate';

const validateFirstName = (firstName) => {
  if (!firstName) {
    return translate('register.errorFirstName.undefined');
  }
  if (!regexName({input: firstName})) {
    return translate('register.errorFirstName.regex');
  }
  return '';
};

const validateLastName = (lastName) => {
  if (!lastName) {
    return translate('register.errorLastName.undefined');
  }
  if (!regexName({input: lastName})) {
    return translate('register.errorLastName.regex');
  }
  return '';
};

const validateTel = (tel) => {
  if (!tel) {
    return 'กรุณากรอกเบอร์โทรที่สามารถติดต่อได้';
  }
  if (!regexTel(tel)) {
    return 'เบอร์โทรไม่ถูกต้อง';
  }
  return '';
};

const validateEmail = (email) => {
  if (!email) {
    return 'กรุณากรอกอีเมลที่สามารถติดต่อได้';
  }
  if (!regexEmail({input: email})) {
    return 'อีเมลไม่ถูกต้อง';
  }
  return '';
};

const validateDate = (date, month) => {
  if (!date) {
    return 'เลือกวัน';
  }
  if (month === 2 && date > 29) {
    return 'วันไม่ถูกต้อง';
  }
  return '';
};

const validateMonth = (month) => {
  if (!month) {
    return 'เลือกเดือน';
  }
  return '';
};

const validateYear = (year) => {
  if (!year) {
    return 'เลือกปี';
  }
  return '';
};

const validateGender = (gender) => {
  if (!gender) {
    return 'เลือกเพศ';
  }
  return '';
};

export const validateForm = (form, birthday, gender) => {
  const firstName = validateFirstName(form.firstName);
  const lastName = validateLastName(form.lastName);
  const birthDate = validateDate(birthday?.birthDate?.value, birthday?.birthMonth?.value);
  const birthMonth = validateMonth(birthday?.birthMonth?.value);
  const birthYear = validateYear(birthday?.birthYear?.value);
  const contactableTel = validateTel(form.contactableTel);
  const contactableEmail = validateEmail(form.contactableEmail);
  const sex = validateGender(gender?.value);

  const isValidate = !firstName && !lastName && !birthDate && !contactableTel && !contactableEmail && !sex && !birthMonth && !birthYear;
  return [isValidate, {firstName, lastName, birthDate, birthMonth, birthYear, sex, contactableTel, contactableEmail}];
};
