import keyBy from 'lodash/keyBy';
import * as firebase from '../../../../utils/firebase/firebase-storage';
import * as typeDef from '../../../../utils/strong-types';
import * as initData from './init-data';

export const mapLanguage = (list) => {
  if (typeDef.isNotArrayLength(list)) {
    return [{name: initData.languageData[0], level: initData.languageLevelData[1]}];
  }
  const keyLanguage = keyBy(initData.languageData, 'value');
  const keyLevel = keyBy(initData.languageLevelData, 'value');

  const response = [];
  list.forEach((elem) => {
    const name = keyLanguage[elem.language] || {label: 'อื่นๆ', value: 'อื่นๆ'};
    const level = keyLevel[elem.level];
    const customName = !keyLanguage[elem.language] ? elem.language : '';
    if (name && level) {
      response.push({name, level, customName});
    }
  });
  return response;
};

export const transformExamCert = (listData) => {
  if (typeDef.isNotArrayLength(listData)) {
    return [];
  }
  const response = listData.map((elem) => ({
    url: elem.url,
    organization: elem.organization.trim(),
    score: elem.score.trim(),
  }));
  return response;
};

export const transformLanguage = (listData) => {
  if (typeDef.isNotArrayLength(listData)) {
    return [];
  }
  const result = listData.map((elem) => {
    return {
      language: elem.customName || elem.name.value,
      level: elem.level.value,
    };
  });
  return result;
};

export const deleteStorageFile = async (filesTarget, prevState) => {
  if (typeDef.isArrayLength(filesTarget) && typeDef.isNotArrayLength(prevState?.language_certification)) {
    return;
  }
  if (typeDef.isNotArrayLength(filesTarget) && typeDef.isNotArrayLength(prevState?.language_certification)) {
    return;
  }
  if (typeDef.isNotArrayLength(filesTarget) && typeDef.isArrayLength(prevState?.language_certification)) {
    const oldList = [...prevState.language_certification];
    const promises = oldList
      .filter((elem) => elem.url)
      .map(async (elem) => {
        await firebase.deleteFile(elem.url);
      });
    await Promise.all(promises);
    return;
  }
  const deleteTarget = [];
  prevState.language_certification.forEach((elem) => {
    const currentTarget = filesTarget.find((item) => item.url === elem.url);
    if (!currentTarget) {
      deleteTarget.push(elem);
    }
  });
  const promises = deleteTarget
    .filter((elem) => elem.url)
    .map(async (elem) => {
      await firebase.deleteFile(elem.url);
    });
  await Promise.all(promises);
};
