import get from 'lodash/get';
import * as dateTime from '../../utils/date-time';

export const transformBirthDay = (d, m, y) => {
  const date = d < 10 ? `0${d}` : d;
  const month = m < 10 ? `0${m}` : m;
  return `${y}-${month}-${date}`;
};

const transformGeneral = (formInfo) => {
  const birthday = transformBirthDay(formInfo?.birthDate?.value, formInfo?.birthMonth?.value, formInfo?.birthYear?.value);
  const general = {
    firstname: formInfo?.firstName,
    lastname: formInfo?.lastName,
    birthday,
    sex: formInfo?.sex?.value || formInfo?.sex,
  };
  return general;
};

export const transformLocation = (locationValue) => {
  const location = {
    province: locationValue?.value || locationValue,
  };
  return location;
};

const transformInterest = (formInterest) => {
  const jobItems = formInterest.filter((element) => element.sub);
  const subJobItems = formInterest.filter((element) => !element.sub);

  const mapJobs = jobItems.map((item) => item.value);
  const mapSubJobs = subJobItems.map((item) => item.value);
  return {
    jobcat: [...mapJobs],
    subjobcat: [...mapSubJobs],
  };
};

export const transformRegister = ({formId, formGeneral, formInfo, formInterest, facebook}) => {
  const isNullForm = !formId || !formGeneral || !formInfo || !formInterest;
  if (isNullForm && !facebook) {
    return null;
  }
  const general = transformGeneral(formInfo);
  const interest = transformInterest(formInterest);

  if (facebook) {
    const facebookBody = facebook?.data || facebook || {};
    return {
      _id: formId._id,
      ...formGeneral,
      platform: 'web',
      general: {
        ...general,
      },
      email: formInfo.email,
      job_interest: {
        ...interest,
      },
      path: 'facebook',
      facebook: {...facebookBody},
      resume: formInfo?.resume || null,
      location: {
        province: get(formInfo?.location, '[0].value', 'กรุงเทพมหานคร'),
      },
    };
  }

  return {
    _id: formId._id,
    ...formGeneral,
    platform: 'web',
    general: {
      ...general,
    },
    job_interest: {
      ...interest,
    },
    resume: formInfo?.resume || null,
    location: {
      province: get(formInfo?.location, '[0].value', 'กรุงเทพมหานคร'),
    },
  };
};

export const generateDateData = () => {
  const date = [...Array(31).keys()];
  const dateData = date.map((elem) => ({
    id: elem,
    label: `${elem + 1}`,
    value: elem + 1,
  }));
  return dateData;
};

export const generateMonthData = () => {
  return [
    {id: 1, label: 'มกราคม', value: 1},
    {id: 2, label: 'กุมภาพันธ์', value: 2},
    {id: 3, label: 'มีนาคม', value: 3},
    {id: 4, label: 'เมษายน', value: 4},
    {id: 5, label: 'พฤษภาคม', value: 5},
    {id: 6, label: 'มิถุนายน', value: 6},
    {id: 7, label: 'กรกฎาคม', value: 7},
    {id: 8, label: 'สิงหาคม', value: 8},
    {id: 9, label: 'กันยายน', value: 9},
    {id: 10, label: 'ตุลาคม', value: 10},
    {id: 11, label: 'พฤศจิกายน', value: 11},
    {id: 12, label: 'ธันวาคม', value: 12},
  ];
};

export const generateYearData = () => {
  const diffBd = 543;
  const yearData = [];
  const maxYear = dateTime.yearNow();
  const minYear = maxYear - 100;
  for (let i = maxYear; i >= minYear; i--) {
    yearData.push({
      id: i,
      label: `${i + diffBd}`,
      value: i,
    });
  }
  return yearData;
};

export const generateGenderData = () => {
  return [
    {id: 1, label: 'ชาย', value: 'm'},
    {id: 2, label: 'หญิง', value: 'f'},
  ];
};
