import React from 'react';
import PropTypes from 'prop-types';
import {translate} from '../../../../res/language/translate';
import {ButtonInteractWrapper, ButtonInteractText, OverrideStyles} from './styled-component';
import Row from '../../../../components/row';
import Icon from '../../../../components/icon';
import PostShare from './share/share';
import PostContact from './contact/contact';
import {interactTypes} from '../../../../lib/hooks-logic/useTrackEvent';
import * as ICONS from '../../../../components/icon/constants';
import * as logic from './logic';

const PostInteractions = (props) => {
  const {
    id,
    onClickLike,
    onClickApplyJob,
    isLiked,
    lineIds,
    lineText,
    tels,
    telText,
    emails,
    emailText,
    jobTitle,
    jobSalary,
    authorUrl,
    authorSrc,
    disableJobApply,
    jobData,
    onEventTracking,
  } = props;
  const {active, unActive, disabled} = OverrideStyles.iconInteract;

  const isDisabledButton = logic.checkIsDisabledButton(emails) || disableJobApply;
  const iconLikeColor = isLiked ? active : unActive;
  const iconApplyColor = !isDisabledButton ? unActive : disabled;

  const onEventClickLike = () => {
    onClickLike();
    onEventTracking(interactTypes.like);
  };

  const onEventClickApplyJob = () => {
    onClickApplyJob();
    onEventTracking(interactTypes.applyJob);
  };

  return (
    <Row content="space-between">
      <ButtonInteractWrapper onClick={onEventClickLike}>
        <Icon name={ICONS.LIKE} size={20} color={iconLikeColor} />
        <ButtonInteractText active={isLiked}>{translate('timeline.like')}</ButtonInteractText>
      </ButtonInteractWrapper>
      <ButtonInteractWrapper
        disabled={isDisabledButton}
        onClick={() => {
          if (isDisabledButton) {
            return;
          }
          onEventClickApplyJob();
        }}>
        <Icon name={ICONS.SEND_EMAIL} size={20} color={iconApplyColor} />
        <ButtonInteractText disabled={isDisabledButton}>{translate('timeline.applyJob')}</ButtonInteractText>
      </ButtonInteractWrapper>
      <PostContact
        id={id}
        lineIds={lineIds}
        lineText={lineText}
        tels={tels}
        telText={telText}
        emails={emails}
        emailText={emailText}
        authorUrl={authorUrl}
        authorSrc={authorSrc}
        jobData={jobData}
        onEventTracking={onEventTracking}
      />
      <PostShare id={id} title={jobTitle} description={jobSalary} jobData={jobData} onEventTracking={onEventTracking} />
    </Row>
  );
};

PostInteractions.propTypes = {
  id: PropTypes.string.isRequired,
  onClickLike: PropTypes.func.isRequired,
  onClickApplyJob: PropTypes.func.isRequired,
  isLiked: PropTypes.bool,
  lineIds: PropTypes.array,
  lineText: PropTypes.string,
  tels: PropTypes.array,
  telText: PropTypes.string,
  emails: PropTypes.array,
  emailText: PropTypes.string,
  jobTitle: PropTypes.string,
  jobSalary: PropTypes.string,
  authorUrl: PropTypes.string,
  authorSrc: PropTypes.string,
  jobData: PropTypes.object.isRequired,
  disableJobApply: PropTypes.bool,
  onEventTracking: PropTypes.func.isRequired,
};

PostInteractions.defaultProps = {
  isLiked: false,
  lineIds: [],
  lineText: '',
  tels: [],
  telText: '',
  emails: [],
  emailText: '',
  jobTitle: '',
  jobSalary: '',
  authorUrl: null,
  authorSrc: '',
  disableJobApply: false,
};

export default PostInteractions;
