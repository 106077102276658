import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {TextLink} from './styled-component';
import Text from '../../../../components/text';
import Link from '../../../../components/link';
import * as logic from './logic';
import * as ROUTES from '../../../../routes/routes-name';

const RenderLink = (props) => {
  const {children} = props;
  const [renderText, setRenderText] = useState();

  useEffect(() => {
    if (children) {
      setRenderText(() => logic.getBodyText(children));
    }
  }, [children]);

  const getUrl = (key) => {
    switch (key) {
      case 'นโยบายคุกกี้':
        return ROUTES.ROUTE_COOKIE_POLICY;
      case 'คำแถลงเกี่ยวกับนโยบายส่วนบุคคล':
        return ROUTES.ROUTE_PRIVACY_POLICY;
      default:
        return ROUTES.ROUTE_MAIN;
    }
  };

  if (!Array.isArray(renderText)) {
    return null;
  }

  return (
    <span>
      {renderText.map((item, index) => {
        const isLink = logic.getIsLink(item);
        if (isLink) {
          const url = logic.replaceLinkTag(item);
          return (
            <Link key={index.toString()} newTab url={getUrl(url)}>
              <TextLink>{url}</TextLink>
            </Link>
          );
        }
        return (
          <Text variant="span" key={index.toString()}>
            {item}
          </Text>
        );
      })}
    </span>
  );
};

RenderLink.propTypes = {
  children: PropTypes.string.isRequired,
};

export default RenderLink;
