import styled from 'styled-components';

export const FormCenter = styled.div`
  display: flex;
  width: 100%;
  ${(props) => props.edgeInsetsTop && `margin-top: 3rem`};
  ${(props) => props.edgeInsetsTopLess && `margin-top: 1rem`};
  ${(props) => props.column && `flex-direction: column; align-items: center`};
  ${(props) => !props.column && `justify-content: center`};
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  ${(props) => props.marginBottom && `margin-bottom: 2rem`};
`;

export const ListSkillWrapper = styled.div`
  padding: 0 0 1rem 0;
`;

export const RowFiledWrapper = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const FieldWrapper = styled.div`
  width: 100%;
`;

export const SpacerField = styled.div`
  width: 100px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const RowItemWrapper = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme.gray};
`;

export const OptionYearWrapper = styled.div`
  width: 100%;
  max-width: 200px;
`;

export const InputGroup = styled.div`
  padding-bottom: 1rem;
`;

export const RowInput = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const InputBlockWrapper = styled.div`
  width: 100%;
  margin-right: 1rem;
  ${(props) => props.singleItem && `margin-right: 0`};
`;

export const RowBirthDay = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  @media (max-width: 380px) {
    flex-direction: column;
  }
`;

export const SelectBirthDayWrapper = styled.div`
  width: 100%;
  @media (min-width: 381px) {
    ${(props) => !props.noMargin && `margin-left: 1rem;`};
  }
`;

export const OverrideStyles = {
  buttonSubmit: {
    marginTop: '1.5rem',
  },
  textTitle: {
    fontWeight: 'bold',
    lineHeight: '1rem',
  },
  buttonDelete: {
    marginTop: '1.7rem',
  },
  buttonDelete2: {
    marginTop: '0.30rem',
  },
  option: {
    marginBottom: '1rem',
  },
};
