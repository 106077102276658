import * as TYPES from './type';
import * as logic from './logic';

const initTrackJobInteractState = {
  error: null,
  stackEvent: [],
  streamEvent: [],
};

export const trackJobInteractReducer = (state = initTrackJobInteractState, {type, payload = {}}) => {
  switch (type) {
    case TYPES.TRACK_EVENT_INTERACT_JOB_UPDATE_STACK_REQ:
      return {...state};
    case TYPES.TRACK_EVENT_INTERACT_JOB_UPDATE_STACK_SUCCESS:
      return {...state, stackEvent: logic.mergeEvent(state.stackEvent, payload)};
    case TYPES.TRACK_EVENT_INTERACT_JOB_STREAM_STACK_REQ:
      return {...state, error: null};
    case TYPES.TRACK_EVENT_INTERACT_JOB_STREAM_STACK_SUCCESS:
      return {...state, streamEvent: logic.mergeEventArray(state.streamEvent, payload)};
    case TYPES.TRACK_EVENT_INTERACT_JOB_STREAM_STACK_FAIL:
      return {...state, error: payload};
    default:
      return state;
  }
};
