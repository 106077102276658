import React from 'react';
import Divider from '../../../components/divider';
import MenuList from './menu-list';
import AvatarProfile from '../../../views/avatar-profile';

const ContentMenu = () => {
  return (
    <>
      <AvatarProfile size="default" />
      <Divider />
      <MenuList />
    </>
  );
};

ContentMenu.propTypes = {};

export default ContentMenu;
