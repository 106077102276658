import {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import * as searchActions from '../../../redux/search/action';

export const useSearchHistoryState = () => {
  const {history: historyState, loading: isLoadingHistoryState} = useSelector((state) => state.search.history, shallowEqual);
  return {
    historyState,
    isLoadingHistoryState,
  };
};

export const useSearchHistoryEffect = () => {
  const dispatch = useDispatch();
  const isLoggedInState = useSelector((state) => state.auth.auth.user, shallowEqual);
  useEffect(() => {
    if (isLoggedInState) {
      dispatch(searchActions.searchHistory());
    }
  }, [dispatch, isLoggedInState]);
};

export const useSearchHistoryDispatch = () => {
  const dispatch = useDispatch();

  const dispatchAddPin = (item) => {
    if (!item) {
      return;
    }
    const reqBody = {
      _id: item?._id,
      is_pin: true,
    };
    item.is_pin = true;
    dispatch(searchActions.searchHistoryPin({data: reqBody, item}));
  };

  const dispatchDelPin = (item) => {
    if (!item) {
      return;
    }
    const reqBody = {
      _id: item?._id,
      is_pin: false,
    };
    item.is_pin = false;
    dispatch(searchActions.searchHistoryPin({data: reqBody, item}));
  };

  const dispatchDelHistory = (item) => {
    if (!item) {
      return;
    }
    const reqBody = {
      search_id: [item._id],
    };
    dispatch(searchActions.searchHistoryDelete({data: reqBody, item}));
  };

  const dispatchDelHistoryAll = (items) => {
    if (!items) {
      return;
    }
    const searchIds = items.map((elem) => elem._id);
    const reqBody = {
      search_id: searchIds,
    };
    dispatch(
      searchActions.searchHistoryDelete({
        data: reqBody,
        item: items,
      }),
    );
  };

  return {
    dispatchAddPin,
    dispatchDelPin,
    dispatchDelHistory,
    dispatchDelHistoryAll,
  };
};
