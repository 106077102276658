/* eslint-disable no-unused-vars */
import * as numbers from '../../utils/number';
import * as typeDef from '../../utils/strong-types';

const getCompanyName = (companyName, authorName) => {
  if (typeDef.isArrayLength(companyName)) {
    return companyName[0];
  }
  return authorName;
};
const onChangePatternToJsonLd = (jobs, source) => {
  const jsonLdFormatList = [];
  jobs.forEach((job) => {
    const item = {
      '@context': 'https://schema.org',
      '@type': 'JobPosting',
      title: job.job_title[0],
      description: `<p>${job.post_text}</p>`,
      identifier: {
        '@type': 'PropertyValue',
        name: getCompanyName(job.company_name, job.author_name),
        value: job.author_id,
      },
      jobLocation: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          addressLocality: job.province[0] || 'กรุงเทพมหานคร',
          addressCountry: 'THAILAND',
        },
      },
      datePosted: job.post_date,
      employmentType: 'CONTRACTOR',
      hiringOrganization: {
        '@type': 'Organization',
        name: getCompanyName(job.company_name, job.author_name),
        logo: job?.author_avatar?.url,
      },
    };

    if (job.salary_min || job.salary_max) {
      let salary = '';
      if (job.salary_min && job.salary_max) {
        salary = `${numbers.numberWithComma({input: job.salary_min})} - ${numbers.numberWithComma({input: job.salary_max})}`;
      } else if (job.salary_min) {
        salary = numbers.numberWithComma({input: job.salary_min});
      } else {
        salary = numbers.numberWithComma({input: job.salary_max});
      }
      item.baseSalary = {
        '@type': 'MonetaryAmount',
        currency: 'THB',
        value: {
          '@type': 'QuantitativeValue',
          value: salary,
          unitText: 'HOUR',
        },
      };
    }
    jsonLdFormatList.push(item);
  });

  return jsonLdFormatList;
};

export const parseJsonLd = (joblist, source) => {
  let result = [];

  if (joblist) {
    result = onChangePatternToJsonLd(joblist, source);
  }
  return result;
};
