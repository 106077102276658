import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  ${(props) => props.isLoggedIn && `padding: 2rem`};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
`;

export const ContentWrapper = styled.div``;

export const TextLink = styled.span`
  text-decoration: underline;
  color: ${(props) => props.theme.primary};
`;

export const TableWrapper = styled.div`
  padding: 2rem 0;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableHead = styled.th`
  width: 50%;
  border: 1px solid;
  padding: 0.75rem;
`;

export const TableData = styled.td`
  border: 1px solid;
  padding: 0.75rem;
  vertical-align: top;
`;
