import React from 'react';
import PropTypes from 'prop-types';
import SVGView from '../../components/svg-img';
import ImgJob from '../../res/assets/images/vector-Jobs.svg';

const SnapshotError = (props) => {
  const {size} = props;
  return (
    <>
      <SVGView inline src={ImgJob} width={size} />
    </>
  );
};

SnapshotError.propTypes = {
  size: PropTypes.number,
};

SnapshotError.defaultProps = {
  size: 150,
};

export default SnapshotError;
