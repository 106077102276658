import React from 'react';
import PropTypes from 'prop-types';
import {useJobPostContact} from '../../../../../lib/hooks/logic/useJobPost';
import {OverrideStyles} from '../styled-component';
import {ButtonContactText, ButtonContactWrapper} from './styled-component';
import Icon from '../../../../../components/icon';
import SVGView from '../../../../../components/svg-img';
import SvgLineApp from '../../../../../res/assets/images/svg/icon_line.svg';
import * as logic from '../logic';
import * as typeDef from '../../../../../utils/strong-types';
import * as mixpanel from '../../../../../utils/mixpanel';

const ContactItem = (props) => {
  const {type, contactList, contactText, noBorder, onEventCallback, jobData} = props;
  const {dispatchAddContactShow} = useJobPostContact();
  const isLine = type === 'line';
  const isClose = type === 'close';
  const isLink = type === 'link';
  const isNoBorder = noBorder;

  const isEmptyContact = typeDef.isNotArrayLength(contactList) && !isClose && !isLink;
  const iconColor = isEmptyContact ? OverrideStyles.iconInteract.disabled : OverrideStyles.iconInteract.unActive;

  const onClickContact = () => {
    onEventCallback();
    if (isLink) mixpanel.jobContactUrl(jobData);
    if (isEmptyContact || isClose || isLink) {
      return;
    }
    const data = {
      type,
      contactList,
      contactText,
    };
    dispatchAddContactShow(data);
    if (type === 'line') mixpanel.jobContactLine(jobData);
    else if (type === 'email') mixpanel.jobContactEmail(jobData);
    else if (type === 'tel') mixpanel.jobContactPhone(jobData);
  };

  if (isEmptyContact) {
    return (
      <ButtonContactWrapper onClick={onClickContact} disabled={isEmptyContact} noBorder={isClose || isNoBorder}>
        {isLine && <SVGView inline src={SvgLineApp} height={20} width={20} inlineStyle={{fill: iconColor}} />}
        {!isLine && <Icon name={logic.getIconName(type)} size={20} color={iconColor} />}
        <ButtonContactText disabled={isEmptyContact}>{logic.getContactLabel(type)}</ButtonContactText>
      </ButtonContactWrapper>
    );
  }

  return (
    <ButtonContactWrapper onClick={onClickContact} noBorder={isClose || isNoBorder} disabled={isEmptyContact}>
      {isLine && <SVGView inline src={SvgLineApp} height={20} width={20} inlineStyle={{fill: iconColor}} />}
      {!isLine && <Icon name={logic.getIconName(type)} size={20} color={iconColor} />}
      <ButtonContactText disabled={isEmptyContact}>{logic.getContactLabel(type)}</ButtonContactText>
    </ButtonContactWrapper>
  );
};

ContactItem.propTypes = {
  type: PropTypes.oneOf(['tel', 'email', 'line', 'close', 'link']).isRequired,
  noBorder: PropTypes.bool,
  contactList: PropTypes.array,
  contactText: PropTypes.string,
  onEventCallback: PropTypes.func.isRequired,
  jobData: PropTypes.object,
};

ContactItem.defaultProps = {
  contactList: [],
  noBorder: false,
  contactText: '',
  jobData: null,
};

export default ContactItem;
