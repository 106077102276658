import {takeLatest, takeEvery} from 'redux-saga/effects';
import * as TYPES from './type';
import * as notifySaga from './saga';

export function* watchNotifyGetSettingNotification() {
  yield takeLatest(TYPES.NOTIFY_SETTING_REQ, notifySaga.notifyGetSettingNotificationAction);
}

export function* watchNotifyUpdateSettingNotification() {
  yield takeLatest(TYPES.NOTIFY_UPDATE_SETTING_REQ, notifySaga.notifyUpdateNotificationAction);
}

export function* watchNotifyGetCountNewNotification() {
  yield takeLatest(TYPES.NOTIFY_COUNT_NEW_REQ, notifySaga.notifyGetCountNewNotification);
}

export function* watchNotifyGetListNotification() {
  yield takeLatest(TYPES.NOTIFY_LIST_REQ, notifySaga.notifyGetListNotification);
}

export function* watchNotifyUpdateNotificationSetting() {
  yield takeLatest(TYPES.NOTIFY_UPDATE_NOTIFICATION_SETTING_REQ, notifySaga.notifyUpdateNotificationSetting);
}

export function* watchNotifySettingModal() {
  yield takeLatest(TYPES.NOTIFY_SETTING_MODAL_REQ, notifySaga.notifySettingModal);
}

export function* watchNotifySaveInterestedJobForm() {
  yield takeLatest(TYPES.NOTIFY_SAVE_FORM_REQ, notifySaga.notifySaveInterestedJobForm);
}

export function* watchNotifyUpdateInterestedJob() {
  yield takeLatest(TYPES.NOTIFY_UPDATE_INTERESTED_JOB_REQ, notifySaga.notifyUpdateInterestedJob);
}

export function* watchNotifyGetDataBySignedUrl() {
  yield takeEvery(TYPES.NOTIFY_GET_DATA_BY_SIGNED_URL_REQ, notifySaga.notifyGetDataBySingedUrl);
}

export function* watchNotifyUpdateDataBySignedUrl() {
  yield takeEvery(TYPES.NOTIFY_UPDATE_DATA_BY_SIGNED_URL_REQ, notifySaga.notifyUpdateDataBySingedUrl);
}
