import styled from 'styled-components';

export const SectionItem = styled.div``;

export const Wrapper = styled.div`
  margin-top: -1rem;
  margin-bottom: 1rem;
`;

export const OverrideStyles = {
  emptyHeader: {
    marginBottom: '1.5rem',
  },
};
