/* eslint-disable no-self-compare */
import queryString from 'querystring';
import get from 'lodash/get';
import * as typeDef from '../../utils/strong-types';
import * as ROUTES from '../../routes/routes-name';

export const mergeLocation = (province, district) => {
  if (typeDef.isArrayLength(district)) {
    return [...district];
  }
  if (Array.isArray(province)) {
    return [...province];
  }
  return [];
};

const mapJobCategoryByTypes = (jobCat, jsonParser = true) => {
  const category = {
    jobCat: null,
    subJobCat: null,
  };
  const targetJobCat = jsonParser ? JSON.parse(jobCat) : jobCat;
  if (typeDef.isNotArrayLength(targetJobCat)) {
    return category;
  }

  category.jobCat = targetJobCat.filter((element) => typeDef.isArray(element.sub)).map((element) => element.value);
  category.subJobCat = targetJobCat.filter((element) => !typeDef.isArray(element.sub)).map((element) => element.value);
  return category;
};

const mapLocation = (location, jsonParser = true) => {
  const searchLocation = {province: '', district: null};
  if (!location) {
    return searchLocation;
  }

  const targetLocation = jsonParser ? JSON.parse(location) || [] : location;
  let isDistrict = false;
  if (targetLocation.length > 1 || get(targetLocation, '[0].parentId')) {
    isDistrict = true;
  }
  if (get(targetLocation, '[0].value') !== get(targetLocation, '[0].label')) {
    isDistrict = true;
  }

  if (!isDistrict) {
    if (targetLocation[0]?.value === 'ทุกจังหวัด') {
      searchLocation.province = '';
    } else {
      searchLocation.province = targetLocation[0]?.value;
    }
    return searchLocation;
  }

  searchLocation.district = targetLocation.map((element) => element.value);
  return searchLocation;
};

export const transformSearchMiniForm = (form) => {
  if (!form) {
    return null;
  }
  if (!typeDef.isObject(form)) {
    return null;
  }
  const jobCategory = mapJobCategoryByTypes(form.jobCat);
  const searchLocation = mapLocation(form.location);

  const searchForm = {};
  if (searchLocation.province) {
    searchForm.province = searchLocation.province;
  }
  if (!searchLocation.province && searchLocation.district) {
    searchForm.district = searchLocation.district;
  }
  if (jobCategory.jobCat) {
    searchForm.jobcat = jobCategory.jobCat;
  }
  if (jobCategory.subJobCat) {
    searchForm.subjobcat = jobCategory.subJobCat;
  }
  if (form.keyCompany) {
    searchForm.author_name = form.keyCompany;
  }
  searchForm.search = form.keySearch;
  return searchForm;
};

export const transformSearchFullForm = (form) => {
  if (!form) {
    return null;
  }
  if (!typeDef.isObject(form)) {
    return null;
  }

  const jobCategory = mapJobCategoryByTypes(form.jobcat || [], false);
  const searchLocation = mapLocation(mergeLocation(form.province, form.district), false);

  const searchForm = {};
  if (searchLocation.province) {
    searchForm.province = searchLocation.province;
  }
  if (!searchLocation.province && searchLocation.district) {
    searchForm.district = searchLocation.district;
  }
  if (jobCategory.jobCat) {
    searchForm.jobcat = jobCategory.jobCat;
  }
  if (jobCategory.subJobCat) {
    searchForm.subjobcat = jobCategory.subJobCat;
  }

  if (form.keyCompany) {
    searchForm.author_name = form.keyCompany;
  }
  if (form.tag) {
    searchForm.tag = form.tag;
  }
  searchForm.search = form.keyJob || '';
  return searchForm;
};

export const stringifyHistoryParams = (params) => {
  if (!params) {
    return '';
  }
  return queryString.stringify(params);
};

export const createLinkSearch = () => {
  return ROUTES.ROUTE_SEARCH_RESULTS;
};
