import * as TYPES from './type';

export const likeNewLike = (payload) => ({
  type: TYPES.LIKE_NEW_LIKE_REQ,
  payload: payload,
});

export const likeUnlike = (payload) => ({
  type: TYPES.LIKE_UNLIKE_REQ,
  payload: payload,
});

export const likeUpdateStream = (payload) => ({
  type: TYPES.LIKE_UPDATE_STREAM_REQ,
  payload: payload,
});
