import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {average} from 'color.js';
import {useParamsEffect} from '../useSearchParams';
import {useLocation} from './useLocation';
import {useLandingPageDispatch} from '../../hooks-logic/useLandingPage';
import {lightTheme} from '../../../styles/themes';
import {JOB_PAGE_SIZE} from '../../context/pagination';
import store from '../../../redux/store';
import * as typeDef from '../../../utils/strong-types';
import * as searchActions from '../../../redux/search/action';
import * as deepState from '../../../utils/deep-state';

export const useLandingGroup = () => {
  const [isEmptyDataState, setIsEmptyDataState] = useState(false);
  const [metaGroupState, setMetaGroupState] = useState();
  const [backdropColorsState, setBackdropColorsState] = useState([]);
  const {
    group: {stackFeed: stackFeedState, newFeed: newFeedState},
    meta: metaState,
    hotList: hotListState,
    pageIndex: pageIndexState,
    loading: isLoadingState,
    loadingRefresh: isLoadingRefreshState,
    filter: filterState,
    error: errorState,
  } = useSelector((state) => state.search.jobGroup, shallowEqual);

  const onReceivedImageUrl = async (url) => {
    try {
      const colors = await average(url, {format: 'hex'});
      const parserColors = Array.isArray(colors) ? colors : [colors];
      setBackdropColorsState([[...parserColors, lightTheme.white], true]);
    } catch (error) {
      setBackdropColorsState([[lightTheme.gradient2, lightTheme.white], false]);
    }
  };

  useEffect(() => {
    if (!isLoadingState && newFeedState) {
      if (newFeedState.resultCount === 0) {
        setIsEmptyDataState(true);
      } else {
        setIsEmptyDataState(false);
      }
    }
  }, [isLoadingState, newFeedState]);

  useEffect(() => {
    if (metaState?.url_name) {
      setMetaGroupState(metaState);
    }
  }, [metaState]);

  useEffect(() => {
    if (metaGroupState?.cover_photo?.url) {
      onReceivedImageUrl(metaGroupState.cover_photo.url);
    }
  }, [metaGroupState]);

  return {
    hotListState,
    backdropColorsState,
    pageIndexState,
    isLoadingRefreshState,
    filterState,
    newFeedState,
    stackFeedState,
    metaGroupState,
    isLoadingState,
    errorState,
    isEmptyDataState,
  };
};

export const useLandingGroupDispatch = () => {
  const dispatch = useDispatch();
  const {paramsState} = useParamsEffect();
  const {
    group: {newFeed: newFeedInitState},
    loading: isLoadingInitState,
    loadingRefresh: isLoadingInitRefreshState,
  } = useSelector((state) => state.search.jobGroup, shallowEqual);
  const {dispatchClearFormFilter} = useLandingPageDispatch();

  const dispatchFetchInit = (params) => {
    if (!params) {
      return;
    }
    const reqBody = {
      params,
      saveHistory: false,
      pageIndex: 0,
      pageSize: JOB_PAGE_SIZE,
    };
    dispatch(searchActions.searchJobGroup({data: reqBody}));
  };

  const dispatchFetchEndReached = (params) => {
    const state = store.getState();
    const {
      group: {newFeed: newFeedState},
      pageIndex: pageIndexState,
      loading: isLoadingState,
      loadingRefresh: isLoadingRefreshState,
      params: paramsSTate,
      error: errorState,
    } = state.search.jobGroup;
    const isEndedResult = newFeedState?.results?.length < JOB_PAGE_SIZE;
    const blockedFetchMore = isLoadingState || isLoadingRefreshState || isEndedResult || errorState || !params;
    if (blockedFetchMore) {
      return;
    }
    const optionParams = deepState.mergeObject({obj1: {url_name: params.id}, obj2: paramsSTate});
    const reqBody = {
      params: {...optionParams},
      saveHistory: false,
      pageIndex: pageIndexState,
      pageSize: JOB_PAGE_SIZE,
    };
    dispatch(searchActions.searchJobGroup({data: reqBody}));
  };

  const dispatchFetchRefresh = (isLoadingState, isLoadingRefreshState) => {
    const {id} = paramsState;
    if (!id || isLoadingState || isLoadingRefreshState) {
      return;
    }
    const params = {
      url_name: id,
    };
    const reqBody = {
      params,
      saveHistory: false,
      pageIndex: 0,
      pageSize: JOB_PAGE_SIZE,
    };
    dispatchClearFormFilter();
    dispatch(searchActions.searchJobGroupRefresh({data: reqBody}));
  };

  useEffect(() => {
    if (typeDef.isObject(paramsState) && !newFeedInitState && !isLoadingInitRefreshState && !isLoadingInitState) {
      const {id} = paramsState;
      const params = {
        url_name: id,
      };
      dispatchFetchInit(params);
    }
  }, [paramsState, newFeedInitState, isLoadingInitState, isLoadingInitRefreshState]);

  return {
    dispatchFetchInit,
    dispatchFetchEndReached,
    dispatchFetchRefresh,
  };
};

export const useLandingGroupCleanupEffect = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (history.action !== 'PUSH') {
        dispatch(searchActions.searchJobGroup({clear: true}));
      }
    };
  }, [history, dispatch]);
};

export const useLandingGroupSliderEffect = () => {
  const dispatch = useDispatch();
  const {locationNearestState, recommendLocationState} = useLocation();
  const {data: groupListState, loading: isLoadingState, error: errorState} = useSelector((state) => state.search.jobGroupList, shallowEqual);

  useEffect(() => {
    if (locationNearestState) {
      if (errorState) {
        return;
      }
      if (recommendLocationState && recommendLocationState !== locationNearestState) {
        dispatch(searchActions.searchJobGroupList({data: recommendLocationState}));
        return;
      }
      if (recommendLocationState && recommendLocationState === locationNearestState) {
        dispatch(searchActions.searchJobGroupList({data: locationNearestState}));
        return;
      }
      if (!recommendLocationState) {
        dispatch(searchActions.searchJobGroupList({data: locationNearestState}));
      }
    }
  }, [locationNearestState, recommendLocationState, errorState]);

  return {groupListState, isLoadingState};
};

export const useLandingGroupSearchFilterEffect = () => {
  const dispatch = useDispatch();
  const {params: filterState} = useSelector((state) => state.search.jobGroup, shallowEqual);
  const {paramsState} = useParamsEffect();

  useEffect(() => {
    if (!typeDef.isNullOrUndefined(filterState) && !typeDef.isEmptyObject(filterState) && paramsState) {
      const reqBody = {
        params: {url_name: paramsState.id, ...filterState},
        saveHistory: false,
        pageIndex: 0,
        pageSize: JOB_PAGE_SIZE,
      };
      dispatch(searchActions.searchJobGroupRefresh({data: reqBody}));
    }
  }, [paramsState, filterState, dispatch]);
};
