import * as TYPES from './type';

export const notifyGetSettingNotification = () => ({
  type: TYPES.NOTIFY_SETTING_REQ,
});

export const notifyGetNewCountNotification = () => ({
  type: TYPES.NOTIFY_COUNT_NEW_REQ,
});

export const notifyUpdateSettingNotification = (payload) => ({
  type: TYPES.NOTIFY_UPDATE_SETTING_REQ,
  payload: payload,
});

export const notifyGetListNotification = (payload) => ({
  type: TYPES.NOTIFY_LIST_REQ,
  payload: payload,
});

export const notifyUpdateNotificationSetting = (payload) => ({
  type: TYPES.NOTIFY_UPDATE_NOTIFICATION_SETTING_REQ,
  payload: payload,
});

export const notifySettingModal = (payload) => ({
  type: TYPES.NOTIFY_SETTING_MODAL_REQ,
  payload: payload,
});

export const notifySaveInterestedJobForm = (payload) => ({
  type: TYPES.NOTIFY_SAVE_FORM_REQ,
  payload: payload,
});

export const notifyUpdateInterestedJob = (payload) => ({
  type: TYPES.NOTIFY_UPDATE_INTERESTED_JOB_REQ,
  payload: payload,
});

export const notifyGetDataBySignedUrl = (payload) => ({
  type: TYPES.NOTIFY_GET_DATA_BY_SIGNED_URL_REQ,
  payload: payload,
});

export const notifyUpdateDataBySignedUrl = (payload) => ({
  type: TYPES.NOTIFY_UPDATE_DATA_BY_SIGNED_URL_REQ,
  payload: payload,
});
