import styled from 'styled-components';
import {lightTheme} from '../../../styles/themes';
import BoxShadow from '../../../styles/box';
import colors from '../../../styles/color';

export const ContainerModal = styled.div`
  width: 100%;
  max-width: 680px;
  padding: 1rem;
  background-color: ${(props) => props.theme.white};
  overflow: auto;

  @media (min-width: 1200px) {
    min-width: 680px;
  }
  @media (min-width: 681px) {
    border-radius: 6px;
    box-shadow: ${BoxShadow.content};
  }
`;

export const Div = styled.div``;

export const RowButtonModal = styled.div`
  display: flex;
  padding: 1rem 0;
`;

export const SegmentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  background-color: ${(props) => props.theme.grayBackground};
`;

export const SegmentItem = styled.div`
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  background-color: ${(props) => (props.isActive ? props.theme.primary : props.theme.grayBackground)};
`;

export const SegmentText = styled.span`
  ${(props) => props.isActive && `color: ${props.theme.white}`};
`;

export const RowSettingMenu = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width: 700px) {
    padding: 0 1rem;
  }
`;

export const TextLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Prompt Light';
  font-size: 16px;
  font-weight: bold;
  margin-left: 3px;
  color: ${(props) => props.theme.primary};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const OverrideStyles = {
  switch: {
    active: lightTheme.primary,
    deActive: lightTheme.white,
  },
  buttonSpaceLeft: {
    marginLeft: '1rem',
  },
  buttonClose: {
    width: '25px',
    minWidth: '40px',
    backgroundColor: colors.global.white,
  },
  buttonEdit: {
    width: '70px',
    marginLeft: 0,
  },
  txtNotify: {
    fontSize: '14px',
    color: 'red',
  },
};
