import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const BottomBarContainer = styled.div`
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.white};
`;

export const BottomContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  padding: 1rem;
  ${(props) => props.center && `align-items: center; justify-content: center;`};
  @media (min-width: 1025px) {
    padding: 1rem 1.5rem;
  }
`;

export const CloseWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: -30px;
  width: 80px;
  height: 30px;
  cursor: pointer;
  border-top-left-radius: 4px;
  background-color: ${(props) => props.theme.gray};
`;

export const CloseContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseText = styled.span`
  color: ${(props) => props.theme.white};
  padding-right: 0.25rem;
`;

export const ContentLayout = styled.div`
  width: 100%;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px`};
`;

export const OverrideStyles = {
  icon: lightTheme.white,
  buttonLeft: {
    marginRight: '0.5rem',
  },
  buttonRight: {
    marginLeft: '0.5rem',
  },
};
