import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  ${(props) => props.noTopEdgeInsets && `margin-top: 0`};
  ${(props) => props.noBottomEdgeInsets && `margin-bottom: 0`};
  @media (max-width: 680px) {
    margin: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 680px;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${(props) => props.theme.white};
  align-items: center;
  @media (max-width: 680px) {
    border-radius: 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 0.5rem`};
  }
  @media (min-width: 681px) {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
    ${(props) => !props.noBottomInsets && `margin-bottom: 1rem`};
  }
`;
