export const REMOVE_TH = [
  'มีมนุษยสัมพันธ์ดี',
  'มีความตั้งใจ',
  'เรียนรู้เร็ว',
  'อดทน',
  'รับผิดชอบต่องาน',
  'รับผิดชอบสูง',
  'ซื่อสัตย์',
  'ตามที่ได้รับมอบหมาย',
  'มีความยืดหยุ่น',
  'สนใจติดต่อ',
  'อีเมล',
  'เมลล์',
  'อีเมลล์',
  'ส่งประวัติ',
  'สอบถามรายละเอียดเพิ่มเติม',
  'ตามอีเมล์ที่ระบุ',
  'งานอื่นๆ',
  'กรอกใบสมัครออนไลน์',
  'ใบสมัคร',
  'ส่ง portfolio',
  'ส่งพร้อมผลงาน',
  'ไลน์ไอดี',
  'ไลน์ ไอดี',
  'สอบถามข้อมูล',
  'สนใจสมัคร',
  'ฝากประวัติ',
  'เพิ่มเติม',
  'ฝากงาน',
  'สนใจสอบถาม',
  'เรซูเม่',
  'แอด\\s?line',
  'แอดไลน์',
  'สมัครทาง',
  'ติดต่อสอบถาม',
  'ติดต่อ HR',
  'ทักไลน์',
];
export const REMOVE_EN = [
  'resume',
  'Collaborate',
  'Outstanding',
  'Passionate',
  'JobThai',
  'JobTopGun',
  'Job Thai',
  'Job TopGun',
  'JOBBKK',
  '(Click Apply Now)',
  'training',
  'inbox',
  'email',
  'tel',
  'See more',
  'Line Official',
  'Line :',
  'Facebook :',
  'Tel.',
  'inbox',
  'line id',
  'id line',
  'forms.gle',
  'docs.google',
  'facebook.com',
  'line:',
  'lin\\.ee/',
  'line\\.me/',
];

export const REMOVE_WORD_TH = ['ค่ะ', 'คะ', 'คับ', 'ครับ'];

export const CONJUNCTIONS = ['เพื่อ', 'และ', 'หรือ', 'เช่น', 'เพราะ', 'รวมถึง', 'รวมไปถึง', 'รวมทั้ง', 'อาทิ', 'ได้แก่', '/', 'and ', 'or ', 'so '];

export const TRANSLATION_SECTIONS = [
  'หน้าที่',
  'หน้าที่รับผิดชอบ',
  'เนื้องานโดยย่อ',
  'หน้าที่และความรับผิดชอบ',
  'หน้าที่ความรับผิดชอบ',
  'รายละเอียดงาน',
  'รายละเอียดตำแหน่งงาน',
  'รายละเอียด',
  'ลักษณะงาน',
  'คุณสมบัติ',
  'คุณสมบัติอื่นๆ',
  'จุดเด่น',
  'คุณสมบัติผู้สมัคร',
  'รายละเอียดงานเบื้องต้น',
  'คุณสมบัติเด่นที่มองหา',
  'Description',
  'Job Descriptions',
  'Job Description',
  'Job Summary',
  'Key Responsibilities',
  'Main Responsibilities',
  'Major Responsibilities',
  'Brief Responsibility',
  'Responsibilities',
  'Qualification',
  'Qualifications',
];
