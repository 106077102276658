import styled from 'styled-components';
import CardTheme from '../../styles/card';

const SLIDER_SIDE = 32;

export const Section = styled.div``;

export const Container = styled.div`
  padding: 3rem 2rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const CardWrapper = styled.div`
  width: ${CardTheme.Size.width}px;
  height: ${CardTheme.Size.height}px;
  margin-bottom: 1rem;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-right: ${(props) => (props.edgeInsetsRight ? '1rem' : 0)};
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  ${(props) => props.noEdgeInsetsBottom && `margin-bottom: 6px`};
`;

export const CardImage = styled.img`
  width: ${CardTheme.Size.width}px;
  height: ${CardTheme.Size.coverHeight}px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const CardTitle = styled.p`
  padding: 0.25rem 1rem 0 1rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SliderWrapper = styled.div`
  width: ${(props) => props.width + SLIDER_SIDE * 2}px;
  padding: 0 1rem;
`;

export const SlickPageWrapper = styled.div`
  width: ${(props) => props.width + SLIDER_SIDE * 2}px;
`;

export const SlickRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: ${(props) => props.width + SLIDER_SIDE}px;
  padding: 0 1rem;
`;

export const SliderCounter = styled.p`
  text-align: center;
`;

export const LoggedWrapper = styled.div`
  position: relative;
`;

export const RowLoggedWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 680px;
  overflow: auto;
  margin-bottom: 1rem;
`;

export const SeeAllButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  top: 51px;
  right: -24px;
  border: 1px solid ${(props) => props.theme.tinGray};
  background-color: ${(props) => props.theme.white};
`;

export const OverrideStyle = {
  card: {
    width: CardTheme.Size.width,
  },
  header: {
    marginBottom: '2rem',
  },
  divider: {
    margin: '0 2rem',
  },
};
