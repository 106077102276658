import {useState, useRef} from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useRouteMatch} from 'react-router-dom';
import {useCallbackPathDispatch} from '../../../lib/hooks-logic/useCallbackPath';
import {useSearchResultCounter} from '../../../lib/hooks/logic/useSearchCounter';
import {
  Container,
  ContainerHidden,
  NavContainer,
  NavWrapper,
  NavItem,
  NavItemChild,
  NavMobileWrapper,
  NavHamburger,
  NavLine,
  NavIconWrapper,
  NavButtonGroup,
  NavCloseWrapper,
  NavSearchWrapper,
  NavNoneWrapper,
  HeaderWrapper,
  AbsoluteHeader,
  OverrideStyles,
} from './styled-component';
import Icon from '../../../components/icon';
import Text from '../../../components/text';
import AppLogo from '../../logo/logo';
import Button from '../../../components/button';
import Link from '../../../components/link';
import Row from '../../../components/row';
import Header from '../../../components/header';
import GestureDetector from '../../../components/gesture';
import Responsive from '../../../components/responsive';
import {translate} from '../../../res/language/translate';
import {useClickOutsideEffect} from '../../../lib/hooks/useClickOutSideEffect';
import * as navigationServices from '../../../utils/navigation/navigation-services';
import * as ICONS from '../../../components/icon/constants';
import * as ROUTES from '../../../routes/routes-name';
import * as logic from './logic';
import * as mixpanel from '../../../utils/mixpanel';
import ENV from '../../../env.json';

const MIN_FLUID = 876;
const MIN_FULL_HEADER = 280;

const LoggedOutAppBar = () => {
  const NavMobileRef = useRef();
  const {formatMessage} = useIntl();

  const {dispatchClearCallbackPath} = useCallbackPathDispatch();
  const isRouteSearchById = useRouteMatch(ROUTES.ROUTE_SEARCH_BY_ID);
  const isRouteManageNotify = useRouteMatch(ROUTES.ROUTE_NOTIFY_MANAGE);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const {resultCount} = useSearchResultCounter();

  useClickOutsideEffect(NavMobileRef, () => setIsOpen(false));

  const onClickLogin = () => {
    setIsOpen(false);
    dispatchClearCallbackPath();
    navigationServices.navigateTo({pathname: ROUTES.ROUTE_LOGIN});
  };

  const onClickRegister = () => {
    setIsOpen(false);
    mixpanel.registerClickNavbarHome();
    dispatchClearCallbackPath();
    navigationServices.navigateTo({pathname: ROUTES.ROUTE_REGISTER});
  };

  const onClickGoBack = () => {
    navigationServices.goBack();
  };

  if (!logic.isValidRoute({pathname: location.pathname}) && !isRouteManageNotify) {
    return null;
  }

  if (isRouteManageNotify) {
    return (
      <>
        <ContainerHidden />
        <Container>
          <NavContainer>
            <Row>
              <Responsive maxWidth={MIN_FLUID - 1}>
                <GestureDetector withEdgeInsets={false} onClick={onClickGoBack} style={OverrideStyles.leftArrow}>
                  <Icon name={ICONS.ARROW_LEFT} size={35} />
                </GestureDetector>
              </Responsive>
              <Responsive minWidth={MIN_FLUID}>
                <GestureDetector withEdgeInsets={false} onClick={onClickGoBack} style={OverrideStyles.leftArrowLarge}>
                  <Icon name={ICONS.ARROW_LEFT} size={35} />
                </GestureDetector>
                <Link url={ROUTES.ROUTE_HOME} style={OverrideStyles.appLogo}>
                  <AppLogo />
                </Link>
              </Responsive>
              <AbsoluteHeader id="absolute">
                <Header variant="h4">{formatMessage({defaultMessage: 'ตั้งค่าการแจ้งเตือนทางอีเมล'})}</Header>
              </AbsoluteHeader>
            </Row>
          </NavContainer>
        </Container>
      </>
    );
  }

  if (!logic.isValidateRouteLoggedOutNav({pathname: location.pathname})) {
    const solveSearch = location.pathname === ROUTES.ROUTE_SEARCH;
    return (
      <>
        <ContainerHidden isSearchId={isRouteSearchById} isNoTopEdgeInsets={!isRouteSearchById && !solveSearch} />
        <Container noUnderLine isSearchId={isRouteSearchById}>
          <NavContainer>
            <Row>
              <Responsive maxWidth={MIN_FLUID - 1}>
                <GestureDetector withEdgeInsets={false} onClick={onClickGoBack} style={OverrideStyles.leftArrow}>
                  <Icon name={ICONS.ARROW_LEFT} size={35} />
                </GestureDetector>
              </Responsive>
              <Responsive minWidth={MIN_FLUID}>
                <GestureDetector withEdgeInsets={false} onClick={onClickGoBack} style={OverrideStyles.leftArrowLarge}>
                  <Icon name={ICONS.ARROW_LEFT} size={35} />
                </GestureDetector>
                <Link url={ROUTES.ROUTE_HOME} style={OverrideStyles.appLogo}>
                  <AppLogo />
                </Link>
              </Responsive>
            </Row>

            <AbsoluteHeader id="absolute">
              {location.pathname === ROUTES.ROUTE_GROUP_LIST && <Header variant="h4">{formatMessage({defaultMessage: 'กลุ่มงานทั้งหมด'})}</Header>}
              {logic.isRouteSearch(location.pathname) && <Header variant="h4">{translate('screenTitle.search')}</Header>}
              {!logic.isRouteSearch(location.pathname) && location.pathname !== ROUTES.ROUTE_GROUP_LIST && (
                <>
                  <Responsive minWidth={MIN_FLUID}>
                    <Row>
                      <Header variant="h4">{translate('search.searchResultsTotal')}</Header>
                      {resultCount && (
                        <Header variant="h4" style={OverrideStyles.headerResultCount}>{` ${resultCount} ${translate('search.resultUnit')}`}</Header>
                      )}
                    </Row>
                  </Responsive>
                  <Responsive maxWidth={MIN_FLUID - 1}>
                    <HeaderWrapper>
                      {logic.isNotShowResultCount({pathname: location.pathname}) && (
                        <Header variant="h4">{translate('search.searchResultsTotal')}</Header>
                      )}
                      {!logic.isNotShowResultCount({pathname: location.pathname}) && (
                        <>
                          <Header variant="h6">{translate('search.searchResultsTotal')}</Header>
                          <Responsive minWidth={MIN_FULL_HEADER}>
                            {resultCount && <Header variant="h4">{`${resultCount} ${translate('search.resultUnit')}`}</Header>}
                          </Responsive>
                        </>
                      )}
                    </HeaderWrapper>
                  </Responsive>
                </>
              )}
            </AbsoluteHeader>

            <NavNoneWrapper>
              <NavItem noSpacer>
                {!logic.isRouteSearch(location.pathname) && (
                  <Link url={ROUTES.ROUTE_SEARCH}>
                    <NavSearchWrapper>
                      <Icon name={ICONS.SEARCH} size={20} />
                      <Responsive minWidth={MIN_FLUID}>
                        <Text style={OverrideStyles.textSearch}>{translate('screenTitle.search')}</Text>
                      </Responsive>
                    </NavSearchWrapper>
                  </Link>
                )}
              </NavItem>
            </NavNoneWrapper>
          </NavContainer>
        </Container>
      </>
    );
  }

  return (
    <>
      <ContainerHidden />
      <Container>
        <NavContainer>
          <Link url={ROUTES.ROUTE_HOME}>
            <AppLogo />
          </Link>
          <NavWrapper>
            <NavItem>
              <Link url={ROUTES.ROUTE_HOME}>
                <NavItemChild>{translate('tab.home')}</NavItemChild>
              </Link>
            </NavItem>
            <NavItem>
              <Link url={ROUTES.ROUTE_FAQ}>
                <NavItemChild>{translate('tab.faq')}</NavItemChild>
              </Link>
            </NavItem>
            <NavItem>
              <Link url={ROUTES.ROUTE_CONTACT}>
                <NavItemChild>{translate('tab.contactUs')}</NavItemChild>
              </Link>
            </NavItem>
            <NavItem>
              <Link newTab url={ENV.PUBLIC.COMPANY_URL}>
                <NavItemChild>{formatMessage({defaultMessage: 'สำหรับบริษัท'})}</NavItemChild>
              </Link>
            </NavItem>
            <NavButtonGroup>
              <NavLine />
              <Button outline onClick={onClickLogin}>
                {translate('login.buttonLoginWithManual')}
              </Button>
              <Button edgeInsets="none" onClick={onClickRegister}>
                {translate('login.buttonRegister')}
              </Button>
            </NavButtonGroup>
          </NavWrapper>
          <NavHamburger>
            <Responsive maxWidth={340}>
              <Button edgeInsets="none" style={OverrideStyles.btnSignUpSmall2} onClick={onClickRegister}>
                {translate('login.buttonRegister')}
              </Button>
            </Responsive>
            <Responsive minWidth={341}>
              <Button edgeInsets="vertical" style={OverrideStyles.btnSignUpSmall} onClick={onClickRegister}>
                {translate('login.buttonRegister')}
              </Button>
            </Responsive>
            <NavIconWrapper
              onClick={() => {
                setIsOpen(!isOpen);
              }}>
              <Icon name={ICONS.MENU} />
            </NavIconWrapper>
          </NavHamburger>
        </NavContainer>

        <NavMobileWrapper open={isOpen} ref={NavMobileRef}>
          <NavCloseWrapper>
            <NavIconWrapper
              position="end"
              onClick={() => {
                setIsOpen(!isOpen);
              }}>
              <Icon name={ICONS.CLOSE} />
            </NavIconWrapper>
          </NavCloseWrapper>
          <NavItem>
            <Link url={ROUTES.ROUTE_HOME} onClick={() => setIsOpen(false)}>
              <NavItemChild>{translate('tab.home')}</NavItemChild>
            </Link>
          </NavItem>
          <NavItem>
            <Link url={ROUTES.ROUTE_FAQ} onClick={() => setIsOpen(false)}>
              <NavItemChild>{translate('tab.faq')}</NavItemChild>
            </Link>
          </NavItem>
          <NavItem>
            <Link url={ROUTES.ROUTE_CONTACT} onClick={() => setIsOpen(false)}>
              <NavItemChild>{translate('tab.contactUs')}</NavItemChild>
            </Link>
          </NavItem>
          <NavItem>
            <Link newTab url={ENV.PUBLIC.COMPANY_URL}>
              <NavItemChild>{formatMessage({defaultMessage: 'สำหรับบริษัท'})}</NavItemChild>
            </Link>
          </NavItem>
          <NavButtonGroup>
            <NavLine />
            <Button outline onClick={onClickLogin}>
              {translate('login.buttonLoginWithManual')}
            </Button>
            <Button onClick={onClickRegister}>{translate('login.buttonRegister')}</Button>
          </NavButtonGroup>
        </NavMobileWrapper>
      </Container>
    </>
  );
};

LoggedOutAppBar.propTypes = {};

export default LoggedOutAppBar;
