import React from 'react';
import {useOTP} from '../../../lib/hooks/logic/useOTP';
import {translate} from '../../../res/language/translate';
import {ThumbnailWrapper, ThumbnailImg, OverrideStyles} from './styled-component';
import Header from '../../../components/header';
import Text from '../../../components/text';
import MobileEmailImage from '../../../res/assets/images/email.png';

const OTPHeader = () => {
  const {currentStepState} = useOTP();

  return (
    <>
      <Header variant="h2" color={OverrideStyles.textHeader.color}>
        {translate('otp.verifyTitle')}
      </Header>
      <ThumbnailWrapper>
        <ThumbnailImg src={MobileEmailImage} />
      </ThumbnailWrapper>
      {currentStepState === 1 && (
        <>
          <Header variant="h4" breakLine center>
            {translate('otp.titleInputPhone')}
          </Header>
          <Text style={OverrideStyles.textBottom}>{translate('otp.subTitleInputPhone')}</Text>
        </>
      )}
      {currentStepState === 2 && (
        <>
          <Header variant="h4" breakLine center>
            {translate('otp.titleInputCode')}
          </Header>
          <Text style={OverrideStyles.textBottom}>{translate('otp.subTitleInputCode')}</Text>
        </>
      )}
    </>
  );
};

OTPHeader.propTypes = {};

export default OTPHeader;
