import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {isArrayLength} from '../../utils/strong-types';
import {OptionGroup, OptionItemWrapper} from './styled-component';
import Checkbox from '../checkbox';
import Icon from '../icon';
import GestureDetector from '../gesture';
import Divider from '../divider';
import * as ICONS from '../icon/constants';
import * as utils from './utils';

const OptionItem = forwardRef((props, ref) => {
  const {parentId, isChecked, openChildList, text, noneRightArrow, options, onChange, onClickExpand, selectedOptions, forceChild, rightComponent} =
    props;

  const handlerOnchange = (item) => {
    if (!onChange) {
      return;
    }
    onChange(item);
  };

  return (
    <OptionGroup ref={ref}>
      <OptionItemWrapper>
        <Checkbox label={text} checked={isChecked} onChange={handlerOnchange} />
        {(isArrayLength(options) || forceChild) && !noneRightArrow && (
          <GestureDetector withEdgeInsets={false} onClick={() => onClickExpand(parentId)}>
            {!rightComponent && <Icon name={openChildList[parentId] ? ICONS.ARROW_UP : ICONS.ARROW_DOWN} />}
            {!!rightComponent && <>{rightComponent}</>}
          </GestureDetector>
        )}
      </OptionItemWrapper>
      {openChildList[parentId] &&
        options.map((item) => (
          <OptionItemWrapper key={item.id} child>
            <Checkbox label={item.label} checked={utils.checkIsSelectedById({id: item.id, selectedOptions})} onChange={() => handlerOnchange(item)} />
          </OptionItemWrapper>
        ))}
      <Divider />
    </OptionGroup>
  );
});

OptionItem.propTypes = {
  parentId: PropTypes.string.isRequired,
  openChildList: PropTypes.object.isRequired,
  isChecked: PropTypes.bool,
  text: PropTypes.string.isRequired,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  forceChild: PropTypes.bool,
  rightComponent: PropTypes.any,
  noneRightArrow: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClickExpand: PropTypes.func,
};

OptionItem.defaultProps = {
  isChecked: false,
  options: null,
  onClickExpand: null,
  selectedOptions: null,
  forceChild: false,
  rightComponent: null,
  noneRightArrow: false,
};
export default OptionItem;
