import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useOTP, useOTPEffect} from '../../lib/hooks/logic/useOTP';
import {Container, ContentWrapper} from './views/styled-component';
import Loading from '../../components/loading';
import OTPHeader from './views/header';
import MetaTag from '../../components/meta-tag';
import FormPhone from './views/form-phone';
import FormOTP from './views/form-otp';

const KEY_RD = '?rd_path=true';

const OTPScreen = () => {
  useOTPEffect();
  const history = useHistory();
  const {formatMessage} = useIntl();

  const callbackPath = useSelector((state) => state.context.callbackPath);
  const {currentStepState, isLoadingState} = useOTP();
  const showFormPhone = currentStepState === 1;
  const showFormOTP = currentStepState === 2;

  useEffect(() => {
    if (callbackPath?.pathname && history) {
      history.replace({pathname: history.location.pathname, search: KEY_RD});
    }
  }, [callbackPath, history]);

  return (
    <Container>
      <MetaTag
        title={formatMessage({defaultMessage: 'ยืนยันตัวตน OTP - JobFinFin'})}
        description={formatMessage({defaultMessage: 'งาน หางาน สมัครงาน - JobFinFin'})}
      />
      <Loading loading={isLoadingState} />
      <ContentWrapper>
        <OTPHeader />
        {showFormPhone && <FormPhone />}
        {showFormOTP && <FormOTP />}
      </ContentWrapper>
    </Container>
  );
};

OTPScreen.propTypes = {};

export default OTPScreen;
