/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {CounterText, LikeCountWrapper, OverrideStyles} from './styled-component';
import Row from '../../../components/row';
import Icon from '../../../components/icon';
import * as ICONS from '../../../components/icon/constants';
import * as number from '../../../utils/number';

const BULLET = '\u2022';

const PostCounter = (props) => {
  const {likeCounter, seenCounter, interestedCounter} = props;
  return (
    <Row content="space-between" style={OverrideStyles.rowCounter}>
      <Row>
        <LikeCountWrapper>
          <Icon name={ICONS.LIKE} color={OverrideStyles.iconLikeActive.color} size={10} />
        </LikeCountWrapper>
        <CounterText paddingLeft>{`${number.numberWithComma({
          input: likeCounter,
        })}`}</CounterText>
      </Row>
      <Row>
        <CounterText>{`คนเห็น ${number.numberWithComma({
          input: seenCounter,
        })} ${BULLET}`}</CounterText>
        <CounterText paddingLeft>
          {`คนสนใจ ${number.numberWithComma({
            input: interestedCounter,
          })}`}
        </CounterText>
      </Row>
    </Row>
  );
};

PostCounter.propTypes = {
  likeCounter: PropTypes.number,
  seenCounter: PropTypes.number,
  interestedCounter: PropTypes.number,
};

PostCounter.defaultProps = {
  likeCounter: 0,
  seenCounter: 0,
  interestedCounter: 0,
};

export default PostCounter;
