import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch, useLocation} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {useCallbackPathDispatch} from '../../lib/hooks-logic/useCallbackPath';
import Icon from '../../components/icon';
import Button from '../../components/button';
import Row from '../../components/row';
import {BottomBarContainer, BottomContent, CloseWrapper, CloseContent, CloseText, ContentLayout, OverrideStyles} from './styled-component';
import * as ICONS from '../../components/icon/constants';
import * as ROUTES from '../../routes/routes-name';
import * as generator from '../../utils/generate';
import * as contextActions from '../../redux/context/action';
import * as navigation from '../../utils/navigation/navigation-services';
import * as mixpanel from '../../utils/mixpanel';

const LoginBottomBar = (props) => {
  const {center} = props;

  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const location = useLocation();

  const {dispatchAddCallbackPath} = useCallbackPathDispatch();
  const isShowState = useSelector((state) => state.context.bottomLogin.isShow);
  const isLoggedInState = useSelector((state) => state.auth.auth?.user);
  const isCheckedAuthState = useSelector((state) => state.auth.auth?.isCheckedAuth);

  const matchRouteSearch = useRouteMatch(ROUTES.ROUTE_SEARCH);
  const matchRouteSearchResult = useRouteMatch(ROUTES.ROUTE_SEARCH_RESULTS);
  const matchRouteSearchHashtags = useRouteMatch(ROUTES.ROUTE_SEARCH_HASHTAG);
  const matchRouteSearchId = useRouteMatch(ROUTES.ROUTE_SEARCH_BY_ID);
  const matchRouteSearchAuthor = useRouteMatch(ROUTES.ROUTE_SEARCH_AUTHOR);
  const isNotMatchRoute = !matchRouteSearch && !matchRouteSearchResult && !matchRouteSearchHashtags && !matchRouteSearchId && !matchRouteSearchAuthor;

  const onRequestRegister = () => {
    mixpanel.registerClickFooterSearch(window.location.pathname);
    dispatchAddCallbackPath({...location, querySearch: location.search});
    navigation.navigateTo({
      pathname: ROUTES.ROUTE_REGISTER,
      search: {rd_path: true},
    });
  };

  const onRequestLogin = () => {
    dispatchAddCallbackPath({...location, querySearch: location.search});
    navigation.navigateTo({pathname: ROUTES.ROUTE_LOGIN, search: {rd_path: true}});
  };

  const onRequestClose = () => {
    dispatch(contextActions.contextShowBottomLogin({isShow: false, id: generator.generateId(), close: true}));
  };

  if (!isCheckedAuthState || isNotMatchRoute || !isShowState || isLoggedInState) {
    return null;
  }

  return (
    <BottomBarContainer>
      <BottomContent center={center}>
        <CloseWrapper onClick={onRequestClose}>
          <CloseContent>
            <CloseText>{formatMessage({defaultMessage: 'ปิด'})}</CloseText>
            <Icon name={ICONS.CLOSE} size={20} color={OverrideStyles.icon} />
          </CloseContent>
        </CloseWrapper>
        <ContentLayout maxWidth={500}>
          <Row wrap="nowrap">
            <Button block outline edgeInsets="vertical" style={OverrideStyles.buttonLeft} onClick={onRequestLogin}>
              {formatMessage({defaultMessage: 'เข้าสู่ระบบ'})}
            </Button>
            <Button block edgeInsets="vertical" style={OverrideStyles.buttonRight} onClick={onRequestRegister}>
              {formatMessage({defaultMessage: 'สมัครสมาชิก'})}
            </Button>
          </Row>
        </ContentLayout>
      </BottomContent>
    </BottomBarContainer>
  );
};

LoginBottomBar.propTypes = {
  center: PropTypes.bool,
};

LoginBottomBar.defaultProps = {
  center: true,
};

export default LoginBottomBar;
