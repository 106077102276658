import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {nanoid} from 'nanoid';
import {TextUploadTitle} from './styled-component';
import Header from '../../../../components/header';
import Divider from '../../../../components/divider';
import Loading from '../../../../components/loading';
import FileUpload from './file-upload';
import {formFileConfig, formInit} from './form-init';
import * as ALERT from '../../../../utils/components/alert/provider';
import * as firebase from '../../../../utils/firebase/firebase-storage';

const UploadForm = (props) => {
  const {jobId, formFileState, setFormFileState} = props;
  const {formatMessage} = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const onUploadAttachFile = async (id, fileName, file, type) => {
    setIsLoading(true);
    try {
      const response = await firebase.uploadJobReport(id, fileName, file);
      const copyFormFile = {...formFileState};
      const memoryUrl = URL.createObjectURL(file.file);
      copyFormFile[type].value.push({url: response, memoryUrl});
      setFormFileState(copyFormFile);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      ALERT.setAlert(`ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง`);
    }
  };

  const onDeleteAttachFile = async (type, index) => {
    const copyFormFile = {...formFileState};
    if (!get(copyFormFile, `${type}.value[${index}]`)) {
      return;
    }
    setIsLoading(true);
    const deleteUrl = copyFormFile[type].value[index].url;
    try {
      const [response, error] = await firebase.deleteFile(deleteUrl);
      if (response || !error) {
        copyFormFile[type].value.splice(index, 1);
        setFormFileState({...copyFormFile});
      }
      setIsLoading(false);
    } catch (error) {
      ALERT.setAlert(`ไม่สามารถลบไฟล์ได้ กรุณาลองใหม่อีกครั้ง`);
      setIsLoading(false);
    }
  };

  const getFileName = (type) => {
    return `${type}_${nanoid()}`;
  };

  const onEventChangeFile = (type, event) => {
    if (!type || !event || !jobId) {
      return;
    }
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (file.size > formFileConfig.maxSize) {
      ALERT.setAlert(`ขนาดไฟล์เกิน ${formFileConfig.sizeMB}MB`);
      return;
    }
    const fileName = getFileName(type);
    onUploadAttachFile(jobId, fileName, {file}, type);
  };

  const onEventDeleteFile = (type, index) => {
    if (!type || index < 0) {
      return;
    }
    onDeleteAttachFile(type, index);
  };

  return (
    <>
      <Loading loading={isLoading} />
      <Header variant="h4">{formatMessage({defaultMessage: `อัพโหลดไฟล์เพื่อแจ้งดำเนินการ `})}</Header>
      <TextUploadTitle
        sm
        style={{
          paddingBottom: '0.5rem',
        }}>{`(ขนาดไฟล์ไม่เกิน ${formFileConfig.sizeMB}MB/ไฟล์) ไม่เกินข้อละ ${formFileConfig.maxLength} ไฟล์`}</TextUploadTitle>
      <>
        <TextUploadTitle sm bold>
          กรุณาอัพโหลดเอกสารต่อไปนี้ (ต้องมี)
        </TextUploadTitle>
        <TextUploadTitle sm>1. แบบฟอร์ม Take down notice</TextUploadTitle>
        <FileUpload
          fileList={formFileState.report_form.value}
          onChangeFile={(event) => onEventChangeFile(formInit.report_form.type, event)}
          onDeleteFile={(index) => onEventDeleteFile(formInit.report_form.type, index)}
        />
        <TextUploadTitle sm>2. บันทึกประจําวัน หรือหนังสือแจ้งความร้องทุกข์(ต้นฉบับ)</TextUploadTitle>
        <FileUpload
          fileList={formFileState.police_report.value}
          onChangeFile={(event) => onEventChangeFile(formInit.police_report.type, event)}
          onDeleteFile={(index) => onEventDeleteFile(formInit.police_report.type, index)}
        />
        <TextUploadTitle sm>3. บัตรประจําตัวประชาชนหรือบัตรที่ออกโดยหน่วยงานราชการที่ระบุเลขประจําตัวประชาชนของผู้ร้องเรียน</TextUploadTitle>
        <FileUpload
          fileList={formFileState.id_card.value}
          onChangeFile={(event) => onEventChangeFile(formInit.id_card.type, event)}
          onDeleteFile={(index) => onEventDeleteFile(formInit.id_card.type, index)}
        />
        <TextUploadTitle sm>4. เอกสารหรือหลักฐานที่ได้ยื่นต่อพนักงานสอบสวนหรือเจ้าหน้าที่ตํารวจ</TextUploadTitle>
        <FileUpload
          fileList={formFileState.police_proof_cert.value}
          onChangeFile={(event) => onEventChangeFile(formInit.police_proof_cert.type, event)}
          onDeleteFile={(index) => onEventDeleteFile(formInit.police_proof_cert.type, index)}
        />
      </>
      <Divider edgeInsets />
      <>
        <TextUploadTitle sm bold>
          เอกสารเพิ่มเติม (ถ้ามี)
        </TextUploadTitle>
        <TextUploadTitle sm>1. หลักฐานแสดงการเปลี่ยนชื่อสกุล</TextUploadTitle>
        <FileUpload
          fileList={formFileState.name_change_cert.value}
          onChangeFile={(event) => onEventChangeFile(formInit.name_change_cert.type, event)}
          onDeleteFile={(index) => onEventDeleteFile(formInit.name_change_cert.type, index)}
        />
        <TextUploadTitle sm>
          2. เอกสารหรือหลักฐานอื่น ๆ ที่แสดงรายละเอียดที่แสดงให้เห็นถึงการกระทําความผิดตามกฎหมาย และ
          ความเสียหายที่เกิดขึ้นกับผู้ใช้บริการหรือบุคคลภายนอก
        </TextUploadTitle>
        <FileUpload
          fileList={formFileState.others.value}
          onChangeFile={(event) => onEventChangeFile(formInit.others.type, event)}
          onDeleteFile={(index) => onEventDeleteFile(formInit.others.type, index)}
        />
      </>
      <Divider edgeInsets />
      <>
        <TextUploadTitle sm bold>
          กรณีไม่ได้มาด้วยตนเอง (ต้องยื่นเอกสารต่อไปนี้เพิ่มเติมด้วย)
        </TextUploadTitle>
        <TextUploadTitle sm>1. หนังสือมอบอํานาจ</TextUploadTitle>
        <FileUpload
          fileList={formFileState.power_of_attorney.value}
          onChangeFile={(event) => onEventChangeFile(formInit.power_of_attorney.type, event)}
          onDeleteFile={(index) => onEventDeleteFile(formInit.power_of_attorney.type, index)}
        />
        <TextUploadTitle sm>2. สําเนาบัตรประจําตัวประชาชนหรือบัตรที่ออกโดยหน่วยงานราชการที่ระบุเลขประจําตัวประชาชนของผู้มอบอํานาจ</TextUploadTitle>
        <FileUpload
          fileList={formFileState.attorney_id_card.value}
          onChangeFile={(event) => onEventChangeFile(formInit.attorney_id_card.type, event)}
          onDeleteFile={(index) => onEventDeleteFile(formInit.attorney_id_card.type, index)}
        />
        <TextUploadTitle sm>2. สําเนาบัตรประจําตัวประชาชนหรือบัตรที่ออกโดยหน่วยงานราชการที่ระบุเลขประจําตัวประชาชนของผู้รับมอบอํานาจ</TextUploadTitle>
        <FileUpload
          fileList={formFileState.assignee_id_card.value}
          onChangeFile={(event) => onEventChangeFile(formInit.assignee_id_card.type, event)}
          onDeleteFile={(index) => onEventDeleteFile(formInit.assignee_id_card.type, index)}
        />
      </>
    </>
  );
};

UploadForm.propTypes = {
  jobId: PropTypes.string.isRequired,
  formFileState: PropTypes.object.isRequired,
  setFormFileState: PropTypes.any.isRequired,
};

export default UploadForm;
