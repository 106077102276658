import React from 'react';
import PropTypes from 'prop-types';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import {SingleWrapper, SingleContent, SingleContentFluid, DeltaSidebar, OverrideStyles} from './styled-component';
import Row from '../../components/row';

const SingleLayout = (props) => {
  const {children, leftSidebar, fluid, noHorizontalEdgeInsets, noEdgeInsets, isChildScreen} = props;
  const isTransparent = fluid;
  useSetGrayBackground(isTransparent);

  if (leftSidebar) {
    if (fluid) {
      return (
        <Row wrap="nowrap" alignStart style={OverrideStyles.row}>
          <DeltaSidebar />
          <SingleWrapper isChildScreen={isChildScreen}>
            <SingleContentFluid noHorizontalEdgeInsets={noHorizontalEdgeInsets}>{children}</SingleContentFluid>
          </SingleWrapper>
        </Row>
      );
    }

    return (
      <Row wrap="nowrap">
        <DeltaSidebar />
        <SingleWrapper isChildScreen={isChildScreen}>
          <SingleContent noHorizontalEdgeInsets={noHorizontalEdgeInsets}>{children}</SingleContent>
        </SingleWrapper>
      </Row>
    );
  }
  return (
    <SingleWrapper isChildScreen={isChildScreen}>
      <SingleContent noEdgeInsets={noEdgeInsets} noHorizontalEdgeInsets={noHorizontalEdgeInsets}>
        {children}
      </SingleContent>
    </SingleWrapper>
  );
};

SingleLayout.propTypes = {
  children: PropTypes.any,
  leftSidebar: PropTypes.bool,
  fluid: PropTypes.bool,
  isChildScreen: PropTypes.bool,
  noEdgeInsets: PropTypes.bool,
  noHorizontalEdgeInsets: PropTypes.bool,
};

SingleLayout.defaultProps = {
  children: null,
  leftSidebar: false,
  fluid: false,
  noEdgeInsets: false,
  noHorizontalEdgeInsets: false,
  isChildScreen: false,
};

export default SingleLayout;
