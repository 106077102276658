import {translate} from '../../res/language/translate';
import * as ROUTES from '../../routes/routes-name';

export const getDefaultScreenTitle = (pathname) => {
  switch (pathname) {
    case ROUTES.ROUTE_SETTING:
      return translate('screenTitle.setting');
    case ROUTES.ROUTE_FAQ:
      return translate('screenTitle.faq');
    case ROUTES.ROUTE_CONTACT:
      return translate('screenTitle.contact');
    case ROUTES.ROUTE_PROFILE:
      return translate('screenTitle.profile');
    case ROUTES.ROUTE_USER_CHANGE_PASSWORD:
      return translate('screenTitle.editPass');
    case ROUTES.ROUTE_EDIT_PROFILE_GENERAL:
      return translate('screenTitle.editGeneral');
    case ROUTES.ROUTE_EDIT_PROFILE_WORKS:
      return translate('screenTitle.editWork');
    case ROUTES.ROUTE_EDIT_PROFILE_EDUCATION:
      return translate('screenTitle.editEducation');
    case ROUTES.ROUTE_EDIT_PROFILE_SKILL:
      return translate('screenTitle.editSkill');
    case ROUTES.ROUTE_EDIT_PROFILE_INTEREST:
      return 'ลักษณะงานที่สนใจ';
    case ROUTES.ROUTE_EDIT_PROFILE_OTHER:
      return 'ประวัติการฝึกอบรม';
    case ROUTES.ROUTE_LIKE:
      return translate('screenTitle.like');
    case ROUTES.ROUTE_NOTIFY:
      return translate('screenTitle.notify');
    case ROUTES.ROUTE_HOME:
      return translate('screenTitle.home');
    case ROUTES.ROUTE_MENU:
      return translate('screenTitle.menu');
    case ROUTES.ROUTE_SEARCH:
      return translate('screenTitle.search');
    case ROUTES.ROUTE_SEARCH_AUTHOR:
    case ROUTES.ROUTE_SEARCH_HASHTAG:
    case ROUTES.ROUTE_SEARCH_BY_ID:
      return translate('screenTitle.searchResult');
    case ROUTES.ROUTE_APPLY_JOB:
      return translate('screenTitle.applyJob');
    default:
      return '';
  }
};

export const matchUrlRouteEdit = (pathname) => {
  switch (pathname) {
    case ROUTES.ROUTE_USER_CHANGE_PASSWORD:
    case ROUTES.ROUTE_EDIT_PROFILE_GENERAL:
    case ROUTES.ROUTE_EDIT_PROFILE_WORKS:
    case ROUTES.ROUTE_EDIT_PROFILE_SKILL:
    case ROUTES.ROUTE_EDIT_PROFILE_EDUCATION:
      return true;
    default:
      return false;
  }
};

export const matchParentRoute = (pathname) => {
  switch (pathname) {
    case ROUTES.ROUTE_HOME:
    case ROUTES.ROUTE_LIKE:
    case ROUTES.ROUTE_NOTIFY:
    case ROUTES.ROUTE_MENU:
    case ROUTES.ROUTE_APPLY_JOB:
      return true;
    default:
      return false;
  }
};
