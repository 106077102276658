import styled from 'styled-components';
import * as LAYOUT from '../layout';

export const Wrapper = styled.div``;

export const Container = styled.div`
  width: 340px;
  border-radius: 8px;
  padding: 1rem;
  margin-left: 1rem;
  background-color: ${(props) => props.theme.white};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px 0px;
  display: block;
  transition: width 0.2s ease-out;
  top: 60px;
  @media (max-width: ${LAYOUT.FULL_FILTER_BREAKPOINT}px) {
    visibility: hidden;
    width: 0px;
    position: absolute;
  }
`;

export const FeedWrapper = styled.div`
  width: 680px;
  @media (max-width: 680px) {
    width: calc(100vw);
    border-radius: 0px;
    /* margin-top: 0.25rem; */
    margin-top: -1rem;
    ${(props) => !props.noBottomInsets && `margin-bottom: 0.5rem`};
  }
  @media (min-width: 681px) {
    margin-top: -1rem;
    ${(props) => !props.noBottomInsets && `margin-bottom: 1rem`};
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;
  width: 240px;
  padding: 0.5rem;
  border-radius: 10px;
  @media (max-width: 610px) {
    width: calc(100vw * 35 / 100);
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.5s;
  }
`;

export const CompanyImg = styled.img`
  object-fit: contain;
  height: 150px;
  width: 150px;
  @media (max-width: 610px) {
    width: calc(100vw * 24 / 100);
    height: calc(100vw * 24 / 100);
  }
`;

export const CompanyName = styled.span`
  padding-top: 1rem;
  font-size: 16px;
  text-align: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  margin-bottom: 4rem;
`;

export const Header = styled.span`
  font-size: 26px;
  font-weight: bold;
`;

export const HeaderSmall = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

export const CardSmallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;
  width: 100px;
  padding: 0.5rem;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const CompanySmallImg = styled.img`
  object-fit: contain;
  height: 96px;
  width: 96px;
`;

export const CompanySmallName = styled.span`
  padding-top: 1rem;
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const AdContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 1rem;
  background-color: white;
  @media (min-width: 681px) {
    margin-top: 1rem;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px;
  }
`;

export const DividerInner = styled.div`
  height: 6px;
  background-color: rgb(239, 239, 239);
  @media (min-width: 681px) {
    display: none;
  }
`;

export const AdRowContent = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  align-items: flex-start;
`;

export const RowAd = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
`;
