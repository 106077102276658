/* eslint-disable no-restricted-syntax */
import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';
import jobCatJSON from '../../../res/json/job-cat.json';
import provinceJSON from '../../../res/json/district_data.json';

export const createJobIndex = () => {
  const JobData = Object.entries(jobCatJSON);
  const shapeData = JobData.map((elem) => {
    const subJobData = Object.entries(elem[1].subcat) || [];
    const shapeSubCat = subJobData.map((subCat) => subCat[0]);
    const item = {
      jobCat: elem[0],
      child: chunk([...shapeSubCat], 3),
    };
    return item;
  });
  return shapeData;
};

export const createLocationIndex = () => {
  const LocationData = groupBy(provinceJSON.District, 'province_name');
  const shapeData = [];
  for (const [key, value] of Object.entries(LocationData)) {
    const districtData = value.map((val) => val.district_name.replace(/อ. |เขต /, ''));
    const item = {
      province: key.replace(/จ. /, ''),
      district: chunk([...districtData], 3),
    };
    shapeData.push(item);
  }
  return shapeData || [];
};
