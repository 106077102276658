import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from './styled-component';

const BoxDecoration = (props) => {
  const {children, paddingRem, paddingPixel, marginRem, marginPixel, noEdgeInsets} = props;
  return (
    <Wrapper noEdgeInsets={noEdgeInsets} paddingRem={paddingRem} paddingPixel={paddingPixel} marginRem={marginRem} marginPixel={marginPixel}>
      {children}
    </Wrapper>
  );
};

BoxDecoration.propTypes = {
  children: PropTypes.any,
  paddingRem: PropTypes.number,
  paddingPixel: PropTypes.number,
  marginRem: PropTypes.number,
  marginPixel: PropTypes.number,
  noEdgeInsets: PropTypes.bool,
};

BoxDecoration.defaultProps = {
  children: null,
  paddingRem: 1,
  paddingPixel: 0,
  marginRem: 0,
  marginPixel: 0,
  noEdgeInsets: false,
};

export default BoxDecoration;
