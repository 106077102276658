import styled from 'styled-components';

export const ImageBackdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0;
  max-width: 680px;
  border-top: 1px solid ${(props) => props.theme.whiteSmoke};
  border-bottom: 1px solid ${(props) => props.theme.whiteSmoke};
  background-color: ${(props) => props.theme.imageBackdrop};
  ${(props) => props.noEdgeInsets && `margin-top: 0px; border-top: none;`};
`;

export const ImageSingle = styled.img`
  cursor: pointer;
  max-height: 700px;
  width: ${(props) => (props.fluid ? '100%' : `${props.width}px`)};
  ${(props) => props.cover && `object-fit: cover`};
  @media (min-width: 680px) {
    max-width: 680px;
  }
  @media (max-width: 679px) {
    max-width: 100vw;
  }
`;

export const ImageBase = styled.img`
  @media (min-width: 680px) {
    max-width: 680px;
  }
  @media (max-width: 679px) {
    max-width: 100vw;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 680px;
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImageBackdropHorizontal2 = styled(ImageBackdrop)`
  position: relative;
  width: 339px;
  height: 339px;
  @media (max-width: 679px) {
    height: calc(100vw / 2 - 1px);
    width: calc(100vw / 2 - 1px);
  }
`;

export const ImageBackdropHorizontal3 = styled(ImageBackdrop)`
  position: relative;
  border: none;
  margin-top: 1px;
  width: 225.66px;
  height: 225.66px;
  @media (max-width: 679px) {
    height: calc(100vw / 3 - 0.75px);
    width: calc(100vw / 3 - 0.75px);
  }
`;

export const ImageMoreHorizontal = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.imageBackdropAlphaMore};
`;

export const ImageSingleHorizontal = styled(ImageBase)`
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
`;

/**
 ************************* Vertical ******************************
 *****************************************************************
 */

export const ImageBackdropVertical = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0;
  max-width: 680px;
  border-top: 1px solid ${(props) => props.theme.whiteSmoke};
  border-bottom: 1px solid ${(props) => props.theme.whiteSmoke};
  background-color: ${(props) => props.theme.imageBackdrop};
  ${(props) => props.noEdgeInsets && `margin-top: 0px; border-top: none;`};
`;

export const ImageBackdropVerticalParent1 = styled(ImageBackdropVertical)`
  position: relative;
  border: none;
  width: 680px;
  height: 100%;
  max-height: 500px;
  @media (max-width: 679px) {
    width: 100vw;
  }
`;

export const ImageBackdropVertical1 = styled(ImageBackdropVertical)`
  position: relative;
  height: 100%;
  width: 339px;
  max-height: 500px;
  margin-top: 1rem;
  @media (max-width: 679px) {
    width: calc(100vw / 2 - 1px);
  }
`;

export const ImageBackdropVertical2 = styled(ImageBackdropVertical)`
  position: relative;
  border: none;
  width: 339px;
  height: 339px;
  @media (max-width: 679px) {
    height: calc(100vw / 2 - 1px);
    width: calc(100vw / 2 - 1px);
  }
`;

export const ImageBackdropVertical3 = styled(ImageBackdropVertical)`
  position: relative;
  border: none;
  margin-top: 1px;
  width: 225.66px;
  height: 225.66px;
  @media (max-width: 679px) {
    height: calc(100vw / 3 - 0.75px);
    width: calc(100vw / 3 - 0.75px);
  }
`;

/** Vertical image */

export const ImageSingleVertical = styled(ImageBase)`
  cursor: pointer;
  width: 100%;
  object-fit: cover;
`;

export const ImageVertical1 = styled(ImageSingleVertical)`
  height: 500px;
  @media (max-width: 679px) {
    height: calc(100vw * 70 / 100) !important;
  }
`;

export const ImageVertical2 = styled(ImageSingleVertical)`
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
`;

export const ImageVertical3 = styled(ImageBase)`
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
`;

export const ImageMore = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.imageBackdropAlphaMore};
`;

export const MorePlusText = styled.h1`
  padding-bottom: 0.25rem;
  color: white;
`;

export const MoreNumberText = styled.h2`
  color: white;
`;
//
