export const checkIsValidPostUrl = (postUrl) => {
  if (!postUrl || typeof postUrl !== 'string') {
    return false;
  }
  const matchUrl = postUrl.match(/jobfinfin/);
  if (!matchUrl) {
    return true;
  }
  return !matchUrl.length;
};
