import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IndexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1500px;
  padding: 3rem 2rem;
`;

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.divider};
  padding-bottom: 2rem;
  width: 100%;
`;

export const HeaderGroupWrapper = styled.div`
  padding: 1rem 0;
`;

export const JobItemWrapper = styled.div``;

export const JobTitleWrapper = styled.div`
  padding-bottom: 1rem;
  ${(props) => props.spaceTop && `padding-top: 1rem`};
`;

export const JobItemTitle = styled.span`
  font-weight: bold;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${(props) => props.theme.primary};
  }
`;

export const RowBody = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 759px) {
    flex-direction: column;
  }
`;

export const SubItem = styled.div`
  flex: 1;
  width: 100%;
  max-width: 34%;
  @media (max-width: 759px) {
    max-width: 100%;
  }
  ${(props) => props.empty && `height: 1px`};
`;

export const SubText = styled.span`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${(props) => props.theme.primary};
  }
`;

export const UL = styled.ul`
  padding: 0 1rem 1rem 0;
  list-style: none;
`;

export const LI = styled.li`
  &:before {
    content: '• ';
    color: ${(props) => props.theme.primary};
    font-size: 1.1rem;
  }
`;

export const AlphabetWrapper = styled.div`
  margin: 1rem 0 0.5rem 0;
`;

export const AlphabetText = styled.span`
  font-size: 20px;
  padding-right: 0.75rem;
  font-weight: bold;
  ${(props) => !props.active && `color: ${props.theme.gray}`};
`;
