import {useState} from 'react';
import {webFilePickerManager} from '../../../utils/file-picker';
import {webHandlerErrorImageAndFilePicker} from '../../context/image';
import {uploadResume, uploadFile} from '../../../utils/firebase/firebase-storage';
import * as alert from '../../../utils/components/alert/provider';
import * as typeDef from '../../../utils/strong-types';

export const useFilePickerHandler = () => {
  const handlerEventFilePickerHandler = async (event, multiple = false) => {
    try {
      const fileInfo = await webFilePickerManager({event, multiple});
      return [fileInfo, null];
    } catch (error) {
      return [null, error];
    }
  };

  const handlerFilePickerError = (error) => {
    const message = webHandlerErrorImageAndFilePicker(error);
    alert.setAlert(message);
  };
  return {handlerEventFilePickerHandler, handlerFilePickerError};
};

export const useDispatchFileUpload = () => {
  const [isLoadingUploadState, setIsLoadingUploadState] = useState(false);

  const dispatchUploadFile = async (userId, file, fileName) => {
    setIsLoadingUploadState(true);
    try {
      const shapeFile = !typeDef.isString(file) ? file : {file};
      const response = await uploadResume(userId, fileName || 'resume', shapeFile);
      setIsLoadingUploadState(false);
      return [response, null];
    } catch (error) {
      setIsLoadingUploadState(false);
      return [null, error];
    }
  };
  const dispatchUploadFileAllType = async (userId, file) => {
    setIsLoadingUploadState(true);
    try {
      const shapeFile = !typeDef.isString(file) ? file : {file};
      const response = await uploadFile(userId, 'score', shapeFile);
      setIsLoadingUploadState(false);
      return [response, null];
    } catch (error) {
      setIsLoadingUploadState(false);
      return [null, error];
    }
  };
  return {isLoadingUploadState, dispatchUploadFile, dispatchUploadFileAllType};
};
