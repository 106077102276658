import styled from 'styled-components';

export const NavNoneWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavItem = styled.li`
  list-style: none;
  margin-left: 2rem;
  @media (max-width: 840px) {
    ${(props) => !props.noSpacer && ` margin: 0.5rem 1.5rem`};
  }
`;

export const NavSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 17.5px;
  padding: 0 1rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.tinGray};
  @media (max-width: 875px) {
    height: 35px;
    width: 35px;
    border-radius: 17.5px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 876px) {
    width: 130px;
  }
  @media (min-width: 1200px) {
    width: 200px;
  }
`;

export const OverrideStyles = {
  textSearch: {
    paddingLeft: '0.5rem',
  },
};
