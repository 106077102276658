import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useCallbackPathDispatch} from '../../hooks-logic/useCallbackPath';
import * as authAction from '../../../redux/auth/action';
import * as navigationService from '../../../utils/navigation/navigation-services';
import * as ROUTES from '../../../routes/routes-name';
import * as routeLogic from '../../../routes/logic';

export const useLogin = () => {
  const dispatch = useDispatch();
  const isLoadingState = useSelector((state) => state.auth.auth.loading) || false;

  const dispatchLogin = (reqBody) => {
    dispatch(authAction.userLoginManual(reqBody));
  };

  return {
    isLoadingState,
    dispatchLogin,
  };
};

export const useLoginEffect = () => {
  const {dispatchClearCallbackPath} = useCallbackPathDispatch();
  const loggedInState = useSelector((state) => state.auth.auth.user);
  const callbackPath = useSelector((state) => state.context.callbackPath);

  useEffect(() => {
    if (loggedInState) {
      if (!callbackPath?.pathname) {
        navigationService.navigateReplaceTo({pathname: ROUTES.ROUTE_HOME});
        return;
      }
      navigationService.navigateReplaceTo({...callbackPath});
      if (routeLogic.checkUserAcceptedPolicy(loggedInState?.terms_and_conditions)) {
        dispatchClearCallbackPath();
      }
    }
  }, [loggedInState, callbackPath]);
};
