import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {lightTheme} from '../../styles/themes';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: 'none',
    overflow: 'hidden',
  },
  overlay: {
    zIndex: 2040,
    backgroundColor: lightTheme.modalBackdrop,
  },
};

const ReactModal = (props) => {
  const {isVisible, onRequestClose, children} = props;

  return (
    <Modal isOpen={isVisible || false} onRequestClose={onRequestClose} style={customStyles} ariaHideApp={false}>
      {children}
    </Modal>
  );
};

ReactModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ReactModal.defaultProps = {
  onRequestClose: () => {},
  children: null,
};

export default ReactModal;
