import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const ChildrenWrapper = styled.div`
  width: 100%;
`;

export const OverrideStyles = {
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: lightTheme.textBlack,
  },
};
