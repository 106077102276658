import styled from 'styled-components';

export const AccordionContainer = styled.div`
  cursor: pointer;
  transition: 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0 0;
`;

export const AccordionWrapper = styled.div`
  max-height: ${(props) => (props.open ? `${props.height + 48}px` : '0px')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  margin-bottom: 1rem;
`;

export const AccordionTextWrapper = styled.div`
  background-color: ${(props) => props.theme.gray20};
  padding: 0 1rem;
`;

export const AccordionText = styled.p`
  white-space: pre-line;
  padding: 1rem 0;
`;

export const AccordionTitle = styled.span`
  padding: 0.5rem 1rem 0.5rem 0;
  font-size: 20px;
  font-weight: bold;
`;
