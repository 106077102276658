import colors from './color';

export const lightTheme = {
  body: 'transparent', // '#FFF',
  text: '#363537',
  toggleBorder: '#FFF',
  background: '#363537',
  // Custom theme
  ...colors.global,
};

export const darkTheme = {
  body: '#363537',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  background: '#999',
  // Custom theme
  ...colors.global,
};
