import styled from 'styled-components';
import NavigationTheme from '../../../styles/navigation';

export const NavSideContainer = styled.div`
  position: fixed;
  top: 56px;
  bottom: 0;
  width: 360px;
  background-color: ${(props) => props.theme.white};
  box-shadow: ${NavigationTheme.NavigationHeadShadow.boxShadow2};
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateX(-100%);
  transition: all 0.5s ease-in-out;
  @media (min-width: 1200px) {
    transform: translateX(0);
  }
`;

export const NavSideContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 360px;
`;
