import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {translate} from '../../../res/language/translate';
import {useClickOutsideEffect} from '../../../lib/hooks/useClickOutSideEffect';
import {Container, ContentWrapper, Title, RowAction, Button} from './styled-component';
import * as alertAction from '../../../redux/alert/action';
import * as typeDef from '../../strong-types';

const Alert = () => {
  const dispatch = useDispatch();
  const nodePopupRef = useRef();
  const alertState = useSelector((state) => state.alert);
  const {message: messageState} = alertState;
  const isVisibleModal = !!alertState?.message;
  const hasNegativeButton = alertState?.action?.negativeAction;

  const onClickOutSide = () => {
    if (typeDef.isFunction(alertState?.action?.closeAction)) {
      alertState?.action?.closeAction();
      return;
    }
    if (typeDef.isFunction(alertState?.action?.positiveAction)) {
      alertState?.action?.positiveAction();
      return;
    }
    dispatch(alertAction.alertRemoveAlert());
  };

  const onClickPositive = () => {
    if (typeDef.isFunction(alertState?.action?.positiveAction)) {
      alertState?.action?.positiveAction();
      return;
    }
    dispatch(alertAction.alertRemoveAlert());
  };

  const onClickNegative = () => {
    if (typeDef.isFunction(alertState?.action?.negativeAction)) {
      alertState?.action?.negativeAction();
      return;
    }
    dispatch(alertAction.alertRemoveAlert());
  };

  useClickOutsideEffect(nodePopupRef, onClickOutSide);

  if (!isVisibleModal) {
    return null;
  }

  return (
    <Container isVisible={isVisibleModal}>
      <ContentWrapper ref={nodePopupRef} isVisible={isVisibleModal}>
        <Title left={/\n/g.test(messageState)}>{messageState}</Title>
        <RowAction>
          {hasNegativeButton && (
            <Button danger onClick={onClickNegative}>
              {alertState?.negativeTitle || translate('global.cancel')}
            </Button>
          )}
          <Button singleButton={!hasNegativeButton} onClick={onClickPositive}>
            {alertState?.positiveTitle || translate('global.ok')}
          </Button>
        </RowAction>
      </ContentWrapper>
    </Container>
  );
};

Alert.propTypes = {};

export default Alert;
