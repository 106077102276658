import React from 'react';
import PropTypes from 'prop-types';
import ReactToolTip from 'react-tooltip';
import {DataTip} from './styled-component';

const ToolTip = (props) => {
  const {idFor, dataTip, place, type, effect} = props;
  return (
    <ReactToolTip id={idFor} place={place} type={type} effect={effect}>
      <DataTip>{dataTip}</DataTip>
    </ReactToolTip>
  );
};

ToolTip.propTypes = {
  idFor: PropTypes.string.isRequired,
  dataTip: PropTypes.string.isRequired,
  place: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  type: PropTypes.oneOf(['dark', 'success', 'warning', 'error', 'info', 'light']),
  effect: PropTypes.oneOf(['float', 'solid']),
};
ToolTip.defaultProps = {
  place: 'bottom',
  type: 'dark',
  effect: 'solid',
};

export default ToolTip;
