import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {translate} from '../../res/language/translate';
import {useUserState} from '../../lib/hooks/logic/useUser';
import {Container, AvatarImg, AvatarBackdrop, Title, SubTitle, BodyWrapper} from './styled-component';
import * as userUtils from '../../lib/context/user';
import * as ROUTES from '../../routes/routes-name';
import * as navigationServices from '../../utils/navigation/navigation-services';

const AvatarProfile = (props) => {
  const {noTitle, noSubTitle, size} = props;
  const [isError, setIsError] = useState(false);
  const {userState, isLoadingState} = useUserState();
  const name = userUtils.getFullName(userState?.general?.firstname, userState?.general?.lastname);

  const onClickProfile = () => {
    navigationServices.navigateTo({pathname: ROUTES.ROUTE_PROFILE});
  };

  if (isError || isLoadingState || !userState?.general?.picture_url?.url) {
    return (
      <Container onClick={onClickProfile}>
        <AvatarBackdrop size={size} />
        <BodyWrapper>
          {!noTitle && <Title>{name}</Title>}
          {!noSubTitle && <SubTitle>{translate('profile.screenTitle')}</SubTitle>}
        </BodyWrapper>
      </Container>
    );
  }

  return (
    <Container onClick={onClickProfile}>
      <AvatarImg alt="" src={userState.general.picture_url.url} onError={() => setIsError(true)} size={size} />
      <BodyWrapper>
        {!noTitle && <Title>{name}</Title>}
        {!noSubTitle && <SubTitle>{translate('profile.screenTitle')}</SubTitle>}
      </BodyWrapper>
    </Container>
  );
};

AvatarProfile.propTypes = {
  size: PropTypes.oneOf(['default', 'small', 'large']),
  noTitle: PropTypes.bool,
  noSubTitle: PropTypes.bool,
};

AvatarProfile.defaultProps = {
  size: 'default',
  noTitle: false,
  noSubTitle: false,
};

export default AvatarProfile;
