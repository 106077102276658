import mixpanel from 'mixpanel-browser';
import {debounce} from 'lodash';
import axios from 'axios';
import ENV from '../env.json';
import * as ROUTES from '../routes/routes-name';

const API_KEY = ENV?.PUBLIC?.MIXPANEL;
const option = {debug: false, api_host: ENV?.PUBLIC?.MIXPANEL_PROXY};

window.onerror = (msg, url, lineNo, columnNo, error) => {
  debounce(() => mixpanel.track('JS Error', {msg, url, lineNo, columnNo, stack: error.stack}), 100);
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const {config, status, data} = error.response;
    mixpanel.track('HTTP Error', {
      status,
      route: config?.url,
      method: config?.method,
      error: data?.error || data,
    });
    return Promise.reject(error);
  },
);

const cleanPath = (path) => {
  return path.split('?')[0];
};

export const init = () => {
  if (!API_KEY) return;

  mixpanel.init(API_KEY, option);
  const path = cleanPath(window.location.pathname);
  mixpanel.register_once({first_path: path});
  mixpanel.track('Visit');
  mixpanel.track(`${path} Page View`, {path: path, init: true, is_page_view: true});
};

export const page = (pagePath) => {
  if (!API_KEY) return;

  mixpanel.init(API_KEY, option);
  const path = cleanPath(pagePath);
  mixpanel.track(`${path} Page View`, {path: path, is_page_view: true});
};

// ================ auth ================

const identify = (event, user, extraKey, extraValue) => {
  if (!API_KEY || !user) return;

  mixpanel.init(API_KEY, option);
  if (event === 'Register') mixpanel.alias(user._id);
  mixpanel.identify(user._id);
  mixpanel.people.set_once({
    $email: user?.email,
    $avatar: user?.facebook?.picture?.url || user?.general?.picture_url?.url,
    $phone: user?.tel,
    $first_name: user?.general?.firstname,
    $last_name: user?.general?.lastname,
    $name: `${user?.general?.firstname} ${user?.general?.lastname}`,
    $created: user?.create_date,
    birthday: user?.general?.birthday,
  });
  if (extraKey && extraValue) mixpanel.track(event, {[extraKey]: extraValue});
  else mixpanel.track(event);
};

export const loginFacebook = (user) => identify('Login', user, 'login_type', 'facebook');
export const loginEmail = (user) => identify('Login', user, 'login_type', 'email');
export const register = (user) => identify('Register', user);
export const authCurrent = (user) => identify('Auto Login', user);

const track = (event, data, extraKey, extraValue) => {
  if (!API_KEY) return;

  mixpanel.init(API_KEY, option);
  if (data) data[extraKey] = extraValue;
  if (data) mixpanel.track(event, data);
  else mixpanel.track(event);
};

export const loginFail = (cause) => track('Login Fail', {cause});
export const registerFail = (cause) => track('Register Fail', {cause});

export const logout = () => {
  if (!API_KEY) return;

  mixpanel.init(API_KEY, option);
  mixpanel.track(`Logout`);
  mixpanel.reset();
};

// ================ key action ================

const keyAction = (event, data, extraKey, extraValue) => {
  if (!API_KEY) return;

  mixpanel.init(API_KEY, option);
  if (data) data[extraKey] = extraValue;
  mixpanel.people.set_once({$convert_date: new Date().toUTCString()});
  mixpanel.track(event, data);
};

export const jobLike = (job) => keyAction('Job Like', job);
export const jobApplyModal = (job) => keyAction('Job Apply Modal', job);
// interested is enough
export const jobApplyFailInfo = (job, info) => keyAction('Job Apply Fail Info', job, 'fail_info', info);
export const jobShareFacebook = (job) => keyAction('Job Share', job, 'share_type', 'facebook');
export const jobShareMessenger = (job) => keyAction('Job Share', job, 'share_type', 'messenger');
export const jobShareUrl = (job) => keyAction('Job Share', job, 'share_type', 'url');
export const jobShareOther = (job) => keyAction('Job Share', job, 'share_type', 'other');
export const jobContactPhone = (job) => keyAction('Job Contact', job, 'contact_type', 'phone');
export const jobContactLine = (job) => keyAction('Job Contact', job, 'contact_type', 'line');
export const jobContactEmail = (job) => keyAction('Job Contact', job, 'contact_type', 'email');
export const jobContactUrl = (job) => keyAction('Job Contact', job, 'contact_type', 'url');

// ================ register step ================

export const registerStep0 = () => track('Register Step 0');
export const registerStepUsername = () => track('Register Step Username');
export const registerStepInfo = () => track('Register Step Info');
export const registerStepInterest = () => track('Register Step Interest');

// register position
export const registerClickNavbarLogin = () => track('Register Click', {url: ROUTES.ROUTE_LOGIN, position: 'navbar_login'});
export const registerClickNavbarHome = () => track('Register Click', {url: ROUTES.ROUTE_MAIN, position: 'navbar_home'});
// export const registerClickHamburger = () => track('Register Click', { url: ROUTES.ROUTE_MAIN, position: "hamburger_home" });
export const registerClickFooterSearch = (url) => track('Register Click', {url: cleanPath(url), position: 'footer_search'});
export const registerClickSeeMore = (url) => track('Register Click', {url: cleanPath(url), position: 'see_more'});

// search position
export const searchClickHome = (param) => track('Search Click', param, 'url', ROUTES.ROUTE_MAIN);
export const searchClickForm = (param) => track('Search Click', param, 'url', ROUTES.ROUTE_SEARCH);

// filter
export const filterClick = (url, param) => track('Filter Click', param, 'url', cleanPath(url));

// highlight home
export const homeHighlightRecommend = (isLogin, job, from) =>
  track('Home HighLight', {is_login: isLogin, type: 'recommend_job', job, from: cleanPath(from)});
export const homeHighlightLanding = (isLogin, url, from) => track('Home HighLight', {is_login: isLogin, type: 'landing', url, from: cleanPath(from)});
export const homeHighlightHotCompany = (isLogin, company, from) =>
  track('Home HighLight', {is_login: isLogin, type: 'hot_company', company, from: cleanPath(from)});
