import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import {useIntl} from 'react-intl';
import {useNotifyState, useNotifyDispatch} from '../../../lib/hooks/logic/useNotify';
import {SegmentWrapper, SegmentItem, SegmentText, OverrideStyles, TextLink} from './styled-component';
import {checkSaveInterestJob} from '../logic';
import {SCHEDULE} from '../../../lib/context/notify';
import {useLocationContextDispatch} from '../../../lib/hooks/logic/useLocation';
import Row from '../../../components/row';
import Text from '../../../components/text';
import Divider from '../../../components/divider';
import Header from '../../../components/header';

const Settings = (props) => {
  const {contentMenu} = props;
  const {formatMessage} = useIntl();
  const {settingsState, interestedJobState} = useNotifyState();
  const {dispatchUpdateSettingToggle, dispatchSettingModal} = useNotifyDispatch();
  const {dispatchGetDistrictByProvince} = useLocationContextDispatch();

  const onEventUpdateSetting = (valiant, value) => {
    const reqBody = {
      details: {
        is_read_resume_notify: settingsState?.is_read_resume_notify,
        frequency: settingsState?.frequency,
        is_recommended_job_notify: settingsState?.is_recommended_job_notify,
        is_email_notify: settingsState?.is_email_notify || false,
        [valiant]: value,
      },
    };
    dispatchUpdateSettingToggle(reqBody);
  };

  const handleClick = () => {
    dispatchSettingModal({visible: true});
    dispatchGetDistrictByProvince(interestedJobState?.province);
  };

  return (
    <>
      <Header variant="h3">{formatMessage({defaultMessage: 'ตั้งค่าการแจ้งเตือน'})}</Header>
      <Divider edgeInsets />
      <Row content="space-between">
        <Text>{formatMessage({defaultMessage: 'แจ้งเตือน HR อ่านเรซูเม่แล้ว'})}</Text>
        <Switch
          onColor={OverrideStyles.switch.active}
          onHandleColor={OverrideStyles.switch.deActive}
          height={28}
          width={56}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={() => onEventUpdateSetting('is_read_resume_notify', !get(settingsState, 'is_read_resume_notify'))}
          checked={get(settingsState, 'is_read_resume_notify')}
        />
      </Row>
      <Divider edgeInsets />

      {contentMenu && <Divider edgeInsets />}

      <Header variant="h3">{formatMessage({defaultMessage: 'ตั้งค่าแจ้งเตือนงานที่สนใจ'})}</Header>
      <Divider edgeInsets />
      <Row content="space-between">
        <Text>{formatMessage({defaultMessage: 'แจ้งเตือนงานที่ตรงกับความสนใจ'})}</Text>
        <Switch
          onColor={OverrideStyles.switch.active}
          onHandleColor={OverrideStyles.switch.deActive}
          height={28}
          width={56}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={() => onEventUpdateSetting('is_recommended_job_notify', !get(settingsState, 'is_recommended_job_notify'))}
          checked={get(settingsState, 'is_recommended_job_notify')}
        />
      </Row>
      <Divider edgeInsets />
      <Row content="space-between">
        <Text>{formatMessage({defaultMessage: 'แจ้งเตือนทางอีเมล'})}</Text>
        <Switch
          onColor={OverrideStyles.switch.active}
          onHandleColor={OverrideStyles.switch.deActive}
          height={28}
          width={56}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={() => onEventUpdateSetting('is_email_notify', !get(settingsState, 'is_email_notify'))}
          checked={get(settingsState, 'is_email_notify', false)}
        />
      </Row>
      <Divider edgeInsets />
      <Row content="space-between" wrap="nowrap">
        <Text>{formatMessage({defaultMessage: 'การแจ้งเตือน'})}</Text>
        <SegmentWrapper>
          <SegmentItem isActive={get(settingsState, 'frequency') === 1} onClick={() => onEventUpdateSetting('frequency', SCHEDULE.day)}>
            <SegmentText isActive={get(settingsState, 'frequency') === 1}>{formatMessage({defaultMessage: 'รายวัน'})}</SegmentText>
          </SegmentItem>
          <SegmentItem isActive={get(settingsState, 'frequency') === 7} onClick={() => onEventUpdateSetting('frequency', SCHEDULE.week)}>
            <SegmentText isActive={get(settingsState, 'frequency') === 7}>{formatMessage({defaultMessage: 'รายสัปดาห์'})}</SegmentText>
          </SegmentItem>
        </SegmentWrapper>
      </Row>
      <Divider edgeInsets />
      <Row content="flex-start">
        <Text>
          {checkSaveInterestJob(interestedJobState)
            ? formatMessage({defaultMessage: 'บันทึกงานที่คุณสนใจ'})
            : formatMessage({defaultMessage: 'ดูงานที่คุณสนใจ'})}
        </Text>
        <TextLink onClick={() => handleClick()}>{formatMessage({defaultMessage: 'คลิก'})}</TextLink>
      </Row>
      {checkSaveInterestJob(interestedJobState) && (
        <Text style={OverrideStyles.txtNotify}>{formatMessage({defaultMessage: '*เพื่อให้เราได้บอกคุณเมื่อมีงานที่คุณสนใจเปิดรับ'})}</Text>
      )}
      <Divider edgeInsets />
    </>
  );
};

Settings.propTypes = {
  contentMenu: PropTypes.bool,
};

Settings.defaultProps = {
  contentMenu: false,
};

export default Settings;
