import {get, isEmpty} from 'lodash';
import * as numbers from '../../../utils/number';

const getJobDetail = (joblist, count) => {
  const jobTitle = [];

  joblist.forEach((job) => {
    jobTitle.push(get(job, 'job_title[0]'));
  });
  const setTitleList = jobTitle.length > 3 ? `${jobTitle[0]} ${jobTitle[1]} ${jobTitle[2]}` : jobTitle.join(' ');
  const setText = `มีตำแหน่งงาน ${numbers.numberWithComma({input: count})} ตำแหน่ง เช่น ${setTitleList} - JobFinFin`;
  return setText;
};
export const useTitle = (joblist, count) => {
  let title = `มีตำแหน่งงานมากมายให้ท่านเลือก ค้นหางานผ่าน - Jobfinfin`;
  if (!isEmpty(joblist)) {
    title = getJobDetail(joblist, count);
  }
  return title;
};
