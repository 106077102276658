import {useEssentialsEffect} from './essentialsEvent';
import {useAuthorizeEventEffect} from './authorizeEvent';
import {useJobEventEffect} from './jobEvent';
import {useShowBottomLoginEffect} from './loginEvent';

export const useTrackingEventEffect = () => {
  useEssentialsEffect();
  useAuthorizeEventEffect();
  useJobEventEffect();
  useShowBottomLoginEffect();
};
