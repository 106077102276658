import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useCallbackPathDispatch} from '../../../lib/hooks-logic/useCallbackPath';
import * as navigationService from '../../../utils/navigation/navigation-services';
import * as logic from './logic';
import * as storage from '../../../utils/storage/storage';

export const useRouteCallBackPathEffect = () => {
  const {dispatchClearCallbackPath, dispatchAddCallbackPath} = useCallbackPathDispatch();
  const callbackPath = useSelector((state) => state.context.callbackPath);
  const authState = useSelector((state) => state.auth.auth);

  const checkIsAcceptPolicy = () => {
    return authState?.isCheckedAuth && logic.checkUserAcceptedPolicy(authState?.user?.terms_and_conditions);
  };

  useEffect(() => {
    if (callbackPath?.pathname) {
      if (callbackPath?.window) {
        const rdPath = logic.getCallbackParsePath(callbackPath);
        navigationService.windowNavigateTo({pathname: rdPath});
      } else {
        navigationService.navigateReplaceTo({...callbackPath});
        dispatchClearCallbackPath();
      }
    }
  }, [callbackPath]);

  useEffect(() => {
    if (checkIsAcceptPolicy()) {
      const rdPath = logic.getCallbackPath();
      if (typeof rdPath === 'object') {
        dispatchAddCallbackPath({...rdPath, window: true});
        storage.clearItem(storage.KEY_RD);
      }
    }
  }, [authState]);
};
