import styled from 'styled-components';

export const DividerLI = styled.li`
  height: 1px;
  background-color: ${(props) => props.theme.divider};
  list-style-type: none;
  ${(props) => props.edgeInsets && `margin: 1rem 0`};
  ${(props) => props.bold && `background-color: ${props.theme.dividerBold};`};
`;

export const DividerDiv = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.divider};
  list-style-type: none;
  ${(props) => props.edgeInsets && `margin: 1rem 0`};
  ${(props) => props.bold && `background-color: ${props.theme.dividerBold};`};
`;
