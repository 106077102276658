/* eslint-disable no-unused-vars */
import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useLocationContextState} from '../../lib/hooks/logic/useLocation';
import {translate} from '../../res/language/translate';
import {isArrayLength} from '../../utils/strong-types';
import {
  InputField,
  InputWrapper,
  InputLabel,
  BottomWrapper,
  SelectBoxWrapper,
  SelectBox,
  OverrideStyles,
  ModalWrapper,
} from '../../components/select/styled-component';
import OptionItem from '../../components/select/sub-comp';
import {Container, SearchFilterWrapper, InputHint, OverrideThisStyles} from './styled-component';
import Input from '../../components/input';
import Row from '../../components/row';
import Icon from '../../components/icon';
import Text from '../../components/text';
import Modal from '../../components/modal';
import Button from '../../components/button';
import * as ICONS from '../../components/icon/constants';
import * as typeDef from '../../utils/strong-types';
import * as logic from './logic';

const SelectProvince = (props) => {
  const {checkBy, outline, placeholder, iconLabel, selectedProvince, label, warningText, addAll, onChange, disabled} = props;

  const {contextProvinceState, isLoadingProvinceState} = useLocationContextState();

  const nodeItemRef = useRef();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [provinceState, setProvinceState] = useState([]);
  const [filterKey, setFilterKey] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const isEmptyProvince = !Array.isArray(provinceState) && !provinceState.length;

  useEffect(() => {
    if (Array.isArray(contextProvinceState)) {
      const transformProvince = logic.createProvincesData(contextProvinceState, addAll);
      setProvinceState(transformProvince);
    }
  }, [contextProvinceState, addAll]);

  useEffect(() => {
    if (!filterKey) {
      setFilterOptions([]);
      return;
    }
    if (filterKey) {
      const filteredArea = provinceState.filter((element) => element.label.toLowerCase().includes(filterKey.toLowerCase()));
      setFilterOptions(filteredArea);
    }
  }, [provinceState, filterKey]);

  useEffect(() => {
    if (isVisibleModal && nodeItemRef.current) {
      nodeItemRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [isVisibleModal]);

  useEffect(() => {
    if (addAll && typeDef.isNotArrayLength(selectedProvince) && typeDef.isArrayLength(provinceState)) {
      onChange(provinceState[0]);
    }
  }, [selectedProvince, provinceState, addAll]);

  const onChangeProvince = (item) => {
    if (!onChange) {
      return;
    }
    onChange(item);
  };

  const onConfirmChange = () => {
    setIsVisibleModal(false);
  };

  return (
    <>
      <Modal visible={isVisibleModal} onRequestClose={() => setIsVisibleModal(false)}>
        <ModalWrapper>
          <SearchFilterWrapper>
            <Input
              borderOutline
              placeholder={translate('landing.searchProvince')}
              iconName={ICONS.SEARCH}
              value={filterKey}
              onChange={(key) => setFilterKey(key)}
            />
          </SearchFilterWrapper>
          <SelectBoxWrapper id="slb">
            <SelectBox>
              {isArrayLength(provinceState) &&
                !filterKey &&
                provinceState.map((item) => (
                  <OptionItem
                    ref={(ref) => {
                      if (checkBy === 'id') {
                        if (logic.checkIsSelectedById({id: item.id, selectedOptions: selectedProvince})) {
                          nodeItemRef.current = ref;
                        }
                      } else if (logic.checkIsSelectedByValue({value: item.value, selectedOptions: selectedProvince})) {
                        nodeItemRef.current = ref;
                      }
                    }}
                    key={item.id}
                    forceChild
                    noneRightArrow
                    isChecked={
                      checkBy === 'id'
                        ? logic.checkIsSelectedById({id: item.id, selectedOptions: selectedProvince})
                        : logic.checkIsSelectedByValue({value: item.value, selectedOptions: selectedProvince})
                    }
                    text={item.label}
                    parentId={item.id}
                    openChildList={{}}
                    selectedOptions={selectedProvince}
                    onChange={() => onChangeProvince(item)}
                  />
                ))}
              {filterKey && (
                <>
                  {filterOptions.map((item) => (
                    <OptionItem
                      key={item.id}
                      forceChild
                      noneRightArrow
                      isChecked={
                        checkBy === 'id'
                          ? logic.checkIsSelectedById({id: item.id, selectedOptions: selectedProvince})
                          : logic.checkIsSelectedByValue({value: item.value, selectedOptions: selectedProvince})
                      }
                      text={item.label}
                      parentId={item.id}
                      openChildList={{}}
                      selectedOptions={selectedProvince}
                      onChange={() => onChangeProvince(item)}
                    />
                  ))}
                  {!isArrayLength(filterOptions) && <Text style={OverrideThisStyles.textNoResult}>{translate('emptyData.defaultSearch')}</Text>}
                </>
              )}
            </SelectBox>
          </SelectBoxWrapper>
          <BottomWrapper>
            <Button block onClick={() => onConfirmChange()}>
              {translate('global.confirm')}
            </Button>
          </BottomWrapper>
        </ModalWrapper>
      </Modal>
      <Container>
        <Row>
          {!iconLabel && <InputLabel>{label}</InputLabel>}
          {iconLabel && (
            <>
              <Icon name={iconLabel} color={OverrideStyles.icon.blueFade} />
              <InputLabel edgeInsets>{label}</InputLabel>
            </>
          )}
        </Row>
        <InputWrapper
          disabled={disabled}
          outline={outline}
          grayBackground={!outline}
          onClick={() => {
            if (!isEmptyProvince && !disabled) {
              setIsVisibleModal(true);
            }
          }}
          style={OverrideStyles.inputWrapper.locationPadding}>
          {!placeholder && <Icon name={ICONS.LOCATION} color={OverrideStyles.icon.color} />}
          <InputField id="province" name="location-province" value={JSON.stringify(selectedProvince)} onChange={() => {}} />
          <Text color={outline ? OverrideStyles.placeholder.gray : OverrideStyles.placeholder.color} style={OverrideStyles.text}>
            {logic.getSelectedLabel(isLoadingProvinceState, isEmptyProvince, selectedProvince, placeholder)}
          </Text>
        </InputWrapper>
        {warningText && <InputHint warning>{warningText}</InputHint>}
      </Container>
    </>
  );
};

SelectProvince.propTypes = {
  disabled: PropTypes.bool,
  addAll: PropTypes.bool,
  placeholder: PropTypes.string,
  iconLabel: PropTypes.string,
  outline: PropTypes.bool,
  label: PropTypes.string,
  warningText: PropTypes.string,
  selectedProvince: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  checkBy: PropTypes.oneOf(['id', 'value']),
};

SelectProvince.defaultProps = {
  addAll: false,
  outline: false,
  iconLabel: null,
  placeholder: null,
  label: null,
  warningText: null,
  selectedProvince: null,
  checkBy: 'id',
  disabled: false,
};

export default SelectProvince;
