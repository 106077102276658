export const regexNumber = ({input}) => {
  if (!input) {
    return false;
  }
  const pattern = /^\d+$/;
  return pattern.test(input);
};

export const regexEngThaiChar = ({input}) => {
  if (!input) {
    return false;
  }
  const pattern = /^[a-z,ก-๙]+$/;
  return pattern.test(input);
};

export const regexEmail = ({input}) => {
  if (!input) {
    return false;
  }
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(input);
};

export const regexHashtag = () => {
  // const regex = /#+([a-zA-Z0-9_]+)/gi;
};

export const regexName = ({input}) => {
  if (!input) {
    return false;
  }
  const pattern = /^[a-zA-Z ก-๛]+$/;
  return pattern.test(input);
};

export const regexNoneSymbol = ({input}) => {
  if (!input) {
    return false;
  }
  const pattern = /^[a-zA-Z ก-๛ 0-9]+$/;
  return pattern.test(input);
};

export const regexPassword = ({input}) => {
  if (!input) {
    return false;
  }
  // const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,20}$/;
  const pattern = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{6,20}$/;
  return pattern.test(input);
};

export const regexTel = (input) => {
  if (!input) {
    return false;
  }
  const pattern = /^[\d-]{9,10}$/;
  return pattern.test(input);
};
