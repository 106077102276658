import {takeEvery} from 'redux-saga/effects';
import * as TYPES from './type';
import * as SAGA from './saga';

export function* watchUpdateJobIndexData() {
  yield takeEvery(TYPES.SEO_UPDATE_JOB_INDEX_REQ, SAGA.updateJobIndexDataAction);
}

export function* watchUpdateCompanyIndexData() {
  yield takeEvery(TYPES.SEO_UPDATE_COMPANY_INDEX_REQ, SAGA.updateCompanyIndexDataAction);
}

export function* watchUpdateCompanyIndexKey() {
  yield takeEvery(TYPES.SEO_UPDATE_COMPANY_INDEX_KEY_REQ, SAGA.updateCompanyIndexKeyAction);
}
