export const CONTEXT_PROVINCE_REQ = '@CONTEXT/PROVINCE_REQ';
export const CONTEXT_PROVINCE_SUCCESS = '@CONTEXT/PROVINCE_SUCCESS';
export const CONTEXT_PROVINCE_FAIL = '@CONTEXT/PROVINCE_FAIL';

export const CONTEXT_DISTRICT_REQ = '@CONTEXT/DISTRICT_REQ';
export const CONTEXT_DISTRICT_SUCCESS = '@CONTEXT/DISTRICT_SUCCESS';
export const CONTEXT_DISTRICT_FAIL = '@CONTEXT/DISTRICT_FAIL';
export const CONTEXT_DISTRICT_CLEAR = '@CONTEXT/DISTRICT_CLEAR';

export const CONTEXT_LOCATION_REQ = '@CONTEXT/LOCATION_REQ';
export const CONTEXT_LOCATION_SUCCESS = '@CONTEXT/LOCATION_SUCCESS';
export const CONTEXT_LOCATION_FAIL = '@CONTEXT/LOCATION_FAIL';

export const CONTEXT_UPDATE_OFFSET_REQ = '@CONTEXT/UPDATE_OFFSET_REQ';
export const CONTEXT_UPDATE_OFFSET_SUCCESS = '@CONTEXT/UPDATE_OFFSET_SUCCESS';
export const CONTEXT_UPDATE_OFFSET_FAIL = '@CONTEXT/UPDATE_OFFSET_FAIL';

export const CONTEXT_VISIBLE_FILTER_REQ = '@CONTEXT/VISIBLE_FILTER_REQ';
export const CONTEXT_VISIBLE_FILTER_SUCCESS = '@CONTEXT/VISIBLE_FILTER_SUCCESS';

export const CONTEXT_UPDATE_DIMENSIONS_REQ = '@CONTEXT/UPDATE_DIMENSIONS_REQ';
export const CONTEXT_UPDATE_DIMENSIONS_SUCCESS = '@CONTEXT/UPDATE_DIMENSIONS_SUCCESS';

export const CONTEXT_MAIN_SCROLL_CONFIG_REQ = '@CONTEXT/MAIN_SCROLL_CONFIG_REQ';
export const CONTEXT_MAIN_SCROLL_CONFIG_SUCCESS = '@CONTEXT/MAIN_SCROLL_CONFIG_SUCCESS';

export const CONTEXT_EVENT_LISTENER_CONFIG_REQ = '@CONTEXT/EVENT_LISTENER_CONFIG_REQ';
export const CONTEXT_EVENT_LISTENER_CONFIG_SUCCESS = '@CONTEXT/EVENT_LISTENER_CONFIG_SUCCESS';
export const CONTEXT_EVENT_LISTENER_CONFIG_CLEAR = '@CONTEXT/EVENT_LISTENER_CONFIG_CLEAR';

export const CONTEXT_CONTACT_SHOW_REQ = '@CONTEXT/CONTACT_SHOW_REQ';
export const CONTEXT_CONTACT_SHOW_SUCCESS = '@CONTEXT/CONTACT_SHOW_SUCCESS';
export const CONTEXT_CONTACT_SHOW_FAIL = '@CONTEXT/CONTACT_SHOW_FAIL';
export const CONTEXT_CONTACT_SHOW_CLEAR = '@CONTEXT/CONTACT_SHOW_CLEAR';

export const CONTEXT_IMAGE_VIEWER_REQ = '@CONTEXT/IMAGE_VIEWER_REQ';
export const CONTEXT_IMAGE_VIEWER_SUCCESS = '@CONTEXT/IMAGE_VIEWER_SUCCESS';
export const CONTEXT_IMAGE_VIEWER_CLEAR = '@CONTEXT/IMAGE_VIEWER_CLEAR';

export const CONTEXT_STACK_DISTRICT_REQ = '@CONTEXT/STACK_DISTRICT_REQ';
export const CONTEXT_STACK_DISTRICT_SUCCESS = '@CONTEXT/STACK_DISTRICT_SUCCESS';
export const CONTEXT_STACK_DISTRICT_FAIL = '@CONTEXT/STACK_DISTRICT_FAIL';
export const CONTEXT_STACK_DISTRICT_CLEAR = '@CONTEXT/STACK_DISTRICT_CLEAR';

export const CONTEXT_SHOW_BOTTOM_LOGIN_REQ = '@CONTEXT/SHOW_BOTTOM_LOGIN_REQ';
export const CONTEXT_SHOW_BOTTOM_LOGIN_SUCCESS = '@CONTEXT/SHOW_BOTTOM_LOGIN_SUCCESS';

export const CONTEXT_ADD_CALLBACK_PATH_REQ = '@CONTEXT/ADD_CALLBACK_PATH_REQ';
export const CONTEXT_ADD_CALLBACK_PATH_SUCCESS = '@CONTEXT/ADD_CALLBACK_PATH_SUCCESS';
export const CONTEXT_ADD_CALLBACK_PATH_CLEAR = '@CONTEXT/ADD_CALLBACK_PATH_CLEAR';

export const CONTEXT_SHOW_REPORT_POST_REQ = '@CONTEXT/SHOW_REPORT_POST_REQ';
export const CONTEXT_SHOW_REPORT_POST_SUCCESS = '@CONTEXT/SHOW_REPORT_POST_SUCCESS';
export const CONTEXT_HIDE_REPORT_POST_REQ = '@CONTEXT/HIDE_REPORT_POST_REQ';
export const CONTEXT_HIDE_REPORT_POST_SUCCESS = '@CONTEXT/HIDE_REPORT_POST_SUCCESS';

export const CONTEXT_REWRITE_POST_REQ = '@CONTEXT/REWRITE_POST_REQ';
export const CONTEXT_REWRITE_POST_SUCCESS = '@CONTEXT/REWRITE_POST_SUCCESS';
export const CONTEXT_REWRITE_POST_CLEAR = '@CONTEXT/REWRITE_POST_CLEAR';
