import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  padding: 0 0 1rem 0;
`;

export const InputWrapper = styled.div`
  cursor: pointer;
  display: flex;
  height: 44px;
  padding: 0 0.5rem 0 0.5rem;
  background-color: white;
  align-items: center;
  border-radius: 6px;
  margin: 0.25rem 0 0.1rem 0;
  border: none;
  background-color: ${(props) => props.theme.grayBackground};
  ${(props) => props.warningText && `background-color: transparent; border: 1px solid ${props.theme.danger}`};
`;

export const InputLabel = styled.label`
  font-weight: bold;
  display: inline-block;
`;

export const Input = styled.input`
  height: 44px;
  border: none;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
  padding-left: 0.5rem;
  font-size: 16px;
`;

export const InputHint = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.gray70};
  ${(props) => props.warning && `color: ${props.theme.danger}`}
`;

export const OverrideStyles = {
  icon: {
    color: lightTheme.grayDisable,
    colorGray: lightTheme.gray,
  },
};
