import * as iconName from '../../../components/icon/constants';

export const TH_PATTERN = [
  '\n[: \\-*]*หน้าที่[: \\-*]*\n',
  '^[: \\-*]*หน้าที่[: \\-*]*\n',
  '\n?[: \\-*]*หน้าที่รับผิดชอบ[: \\-*]*\n',
  '^[: \\-*]*หน้าที่รับผิดชอบ[: \\-*]*\n',
  '\n?[: \\-*]*เนื้องานโดยย่อ[: \\-*]*\n',
  '^เนื้องานโดยย่อ[: \\-*]*\n',
  '\n?[: \\-*]*หน้าที่และความรับผิดชอบ[: \\-*]*\n',
  '^[: \\-*]*หน้าที่และความรับผิดชอบ[: \\-*]*\n',
  '\n?[: \\-*]*หน้าที่ความรับผิดชอบ[: \\-*]*\n',
  '^หน้าที่ความรับผิดชอบ[: \\-*]*\n',
  'รายละเอียดงาน[: \\-*]*\n',
  'รายละเอียดตำแหน่งงาน[: \\-*]*\n',
  'รายละเอียด[: \\-*]*\n',
  '\n[: \\-*]*ลักษณะงาน[: \\-*]*\n',
  '^คุณสมบัติ[: \\-*]*\n',
  '\n[: \\-*]*คุณสมบัติ[: \\-*]*\n',
  '\n[: \\-*]*คุณสมบัติอื่นๆ[: \\-*]*\n',
  '\n[: \\-*]*จุดเด่น[: \\-*]*\n',
  '^คุณสมบัติผู้สมัคร[: \\-*]*\n',
  '\nคุณสมบัติผู้สมัคร[: \\-*]*\n',
  '\nข้อมูลบริษัท[: \\-*]*\n',
  '\nเกี่ยวกับบริษัท[: \\-*]*\n',
  '\n[: \\-*]*สวัสดิการ[: \\-*]*\n',
  '\n[: \\-*]*รายได้และสวัสดิการ[: \\-*]*\n',
  '\nสิทธิประโยชน์[: \\-*]*\n',
  '\nสถานที่ทำงาน[: \\-*]*\n',
  '\nสถานที่ปฏิบัติงาน[: \\-*]*\n',
  '\nวิธีการเดินทาง[: \\-*]*\n',
  '\nการเดินทาง[: \\-*]*\n',
  '\nวิธีเดินทาง[: \\-*]*\n',
  '\nช่วงเวลาที่เปิดรับสมัคร[: \\-*]*\n',
  '\nค่าตอบแทน[: \\-*]*\n',
  '\nรายละเอียดงานเบื้องต้น[: \\-*]*\n',
  '\nคุณสมบัติเด่นที่มองหา[: \\-*]*\n',
];

export const EN_PATTERN = [
  'Description[: \\-*]*\n',
  'Job Descriptions[: \\-*]*\n',
  'Job Description[: \\-*]*\n',
  'Job Summary[: \\-*]*\n',
  'Key Responsibilities[: \\-*]*\n',
  'Main Responsibilities[: \\-*]*\n',
  'Major Responsibilities[: \\-*]*\n',
  'Brief Responsibility[: \\-*]*\n',
  '\n[: \\-*]*Responsibilities[: \\-*]*\n',
  '\n[: \\-*]*Qualification[: \\-*]*\n',
  '\n[: \\-*]*Qualifications[: \\-*]*\n',
  '\n[: \\-*]*Company information[: \\-*]*\n',
  '\n[: \\-*]*Privileges[: \\-*]*\n',
  '\n[: \\-*]*Privilege[: \\-*]*\n',
  '\n[: \\-*]*Benefit[: \\-*]*\n',
  '\n[: \\-*]*Benefits[: \\-*]*\n',
  '\n?[: \\-*]*Location[: \\-*]*\n',
  '\n?[: \\-*]*location[: \\-*]*\n',
  '\n[: \\-*]*Directions[: \\-*]*\n',
  '\n[: \\-*]*Direction[: \\-*]*\n',
];

export const getIconName = ({header}) => {
  switch (header) {
    case 'Responsibilities':
    case 'Key Responsibilities':
    case 'Main Responsibilities':
    case 'Major Responsibilities':
    case 'Brief Responsibility':
    case 'Job Summary':
    case 'Description':
    case 'Job Descriptions':
    case 'Job Description':
    case 'รายละเอียดงาน':
    case 'รายละเอียด':
    case 'รายละเอียดตำแหน่งงาน':
    case 'หน้าที่และความรับผิดชอบ':
    case 'หน้าที่ความรับผิดชอบ':
    case 'เนื้องานโดยย่อ':
    case 'ลักษณะงาน':
    case 'จุดเด่น':
    case 'หน้าที่':
    case 'หน้าที่รับผิดชอบ':
    case 'คุณสมบัติอื่นๆ':
    case 'รายละเอียดงานเบื้องต้น':
      return iconName.DOCUMENTS;
    case 'Qualifications':
    case 'Qualification':
    case 'คุณสมบัติ':
    case 'คุณสมบัติผู้สมัคร':
      return iconName.USER_VERIFY;
    case 'Company information':
    case 'ข้อมูลบริษัท':
    case 'เกี่ยวกับบริษัท':
    case 'หน่วยงาน/สังกัด':
      return iconName.BUILDING;
    case 'Privileges':
    case 'Privilege':
    case 'Benefit':
    case 'Benefits':
    case 'สวัสดิการ':
    case 'สิทธิประโยชน์':
    case 'รายได้และสวัสดิการ':
    case 'คุณสมบัติเด่นที่มองหา':
      return iconName.STAR;
    case 'Location':
    case 'location':
    case 'สถานที่ทำงาน':
    case 'สถานที่ปฏิบัติงาน':
      return iconName.LOCATION;
    case 'Directions':
    case 'Direction':
    case 'การเดินทาง':
    case 'วิธีเดินทาง':
    case 'วิธีการเดินทาง':
      return iconName.DIRECTION;
    case 'ช่วงเวลาที่เปิดรับสมัคร':
      return iconName.CALENDAR;
    case 'ค่าตอบแทน':
      return iconName.DOLLAR;
    default:
      return '';
  }
};

export const setStackHeader = ({header}) => {
  if (!header) {
    return null;
  }
  switch (header) {
    case 'Responsibilities':
    case 'Key Responsibilities':
    case 'Main Responsibilities':
    case 'Major Responsibilities':
    case 'Brief Responsibility':
    case 'Job Summary':
    case 'Description':
    case 'Job Descriptions':
    case 'Job Description':
    case 'รายละเอียดงาน':
    case 'รายละเอียดตำแหน่งงาน':
    case 'หน้าที่และความรับผิดชอบ':
    case 'หน้าที่ความรับผิดชอบ':
    case 'เนื้องานโดยย่อ':
    case 'รายละเอียด':
    case 'ลักษณะงาน':
    case 'จุดเด่น':
    case 'หน้าที่':
    case 'หน้าที่รับผิดชอบ':
    case 'คุณสมบัติอื่นๆ':
      return 'head1';
    case 'Qualifications':
    case 'Qualification':
    case 'คุณสมบัติ':
    case 'คุณสมบัติผู้สมัคร':
      return 'head2';
    case 'Company information':
    case 'ข้อมูลบริษัท':
    case 'เกี่ยวกับบริษัท':
      return 'head3';
    case 'Privileges':
    case 'Privilege':
    case 'Benefit':
    case 'Benefits':
    case 'สวัสดิการ':
    case 'สิทธิประโยชน์':
    case 'รายได้และสวัสดิการ':
      return 'head4';
    case 'Location':
    case 'location':
    case 'สถานที่ทำงาน':
    case 'สถานที่ปฏิบัติงาน':
      return 'head5';
    case 'Directions':
    case 'Direction':
    case 'การเดินทาง':
    case 'วิธีเดินทาง':
    case 'วิธีการเดินทาง':
      return 'head6';
    default:
      return null;
  }
};
