export const SEO_UPDATE_JOB_INDEX_REQ = 'SEO@UPDATE_JOB_INDEX_REQ';
export const SEO_UPDATE_JOB_INDEX_SUCCESS = 'SEO@UPDATE_JOB_INDEX_SUCCESS';
export const SEO_UPDATE_JOB_INDEX_CLEAR = 'SEO@UPDATE_JOB_INDEX_CLEAR';

export const SEO_UPDATE_COMPANY_INDEX_REQ = 'SEO@UPDATE_COMPANY_INDEX_REQ';
export const SEO_UPDATE_COMPANY_INDEX_SUCCESS = 'SEO@UPDATE_COMPANY_INDEX_SUCCESS';
export const SEO_UPDATE_COMPANY_INDEX_FAIL = 'SEO@UPDATE_COMPANY_INDEX_FAIL';
export const SEO_UPDATE_COMPANY_INDEX_CLEAR = 'SEO@UPDATE_COMPANY_INDEX_CLEAR';

export const SEO_UPDATE_COMPANY_INDEX_KEY_REQ = 'SEO@UPDATE_COMPANY_INDEX_KEY_REQ';
export const SEO_UPDATE_COMPANY_INDEX_KEY_SUCCESS = 'SEO@UPDATE_COMPANY_INDEX_KEY_SUCCESS';
