import * as typeDef from '../../../../utils/strong-types';

export const getFirstOrDefault = (list) => {
  if (typeDef.isNotArrayLength(list)) {
    return null;
  }
  return list[0].value;
};

export const getValueOrDefault = (objItem) => {
  return objItem?.value || null;
};

const dummy = [
  {year: '2021', detail: 'DELTA'},
  {year: '2020', detail: 'DELTA'},
];
export const mapStateToList = (listData, state) => {
  if (typeDef.isNotArrayLength(state)) {
    return null;
  }

  const defaultList = [];
  dummy.forEach((elem) => {
    const findValue = listData.find((item) => item.value === elem.year);
    if (!findValue) {
      return;
    }
    const item = {
      year: {
        label: findValue.label,
        value: findValue.value,
      },
      detail: elem.detail,
    };
    defaultList.push(item);
  });
  return defaultList;
};

export const getRowItem = (listData) => {
  return {
    year: {
      label: listData[0].label,
      value: listData[0].value,
    },
    detail: '',
  };
};

export const transformRow = (listData) => {
  if (typeDef.isNotArrayLength(listData)) {
    return [];
  }
  const response = listData.map((elem) => ({
    year: elem.year.value,
    detail: elem.detail,
  }));
  return response;
};
