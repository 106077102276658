/* eslint-disable camelcase */
import map from 'lodash/map';
import {translate} from '../../res/language/translate';
import * as number from '../../utils/number';
import * as typeDef from '../../utils/strong-types';

const getExperienceLabel = (value) => {
  switch (value) {
    case 0:
      return 'จบใหม่';
    case -1:
      return translate('search.filter.exp.intern');
    case -2:
      return translate('search.filter.exp.partTime');
    case 5:
      return `ปสก${5}ปี+`;
    default:
      return `ปสก${value}ปี`;
  }
};

const mapJobCat = (jobcat) => {
  let _jobcat;
  if (typeDef.isArrayLength(jobcat)) {
    _jobcat = [...jobcat];
  }
  if (!typeDef.isArrayLength(jobcat)) {
    _jobcat = [jobcat];
  }
  return _jobcat.join(', ');
};

const mapSubJobCat = (subjobcat) => {
  let _subJobCat;
  if (typeDef.isArrayLength(subjobcat)) {
    _subJobCat = map(subjobcat, (x) => x.split('|')[1]).join(', ');
  } else {
    _subJobCat = map([subjobcat], (x) => x.split('|')[1]).join(', ');
  }
  return _subJobCat;
};

const mapTags = (tags) => {
  let _tag;
  if (typeDef.isArrayLength(tags)) {
    _tag = [...tags];
  } else {
    _tag = [tags];
  }
  return _tag.join(', ');
};

const mapExperience = (exps) => {
  let labelExperience;
  if (typeDef.isArrayLength(exps)) {
    labelExperience = map(exps, (x) => getExperienceLabel(parseInt(x, 16)));
  }
  if (!typeDef.isArrayLength(exps)) {
    labelExperience = map([exps], (x) => getExperienceLabel(parseInt(x, 16)));
  }
  return labelExperience.join(', ');
};

const mapEducation = (edu) => {
  if (typeDef.isArrayLength(edu)) {
    return edu.join(', ');
  }
  return [edu].join(', ');
};

const mapSalary = (salMin, salMax) => {
  return {
    salaryMin: number.numberWithComma({input: salMin}),
    salaryMax: number.numberWithComma({input: salMax}),
  };
};

const mapProvince = (province) => {
  return `ใกล้-${province}`;
};

const mapDistrict = (districts) => {
  if (typeDef.isArrayLength(districts)) {
    const province = districts[0].split('|')[0];
    const labelDistrict = map(districts, (x) => x.split('|')[1]);
    return `ใกล้-${province}(${labelDistrict.join(', ')})`;
  }
  return `ใกล้-${districts.replace('|', '-')}`;
};

const mapGender = (gender) => {
  return gender;
};

export const transformSearchParamsToLabel = (searchParams) => {
  if (!searchParams) {
    return '';
  }
  const {
    search,
    jobcat,
    subjobcat,
    experience_requirement,
    education_requirement,
    salary_min,
    salary_max,
    tag,
    province,
    district,
    gender_requirement,
    author_id,
    author_name,
  } = searchParams;
  let label = '';
  if (author_name) {
    label = label.concat('บริษัท-', author_name, ', ');
  }
  if (search) {
    label = label.concat('ตำแหน่ง-', search, ', ');
  }

  if (jobcat || subjobcat) {
    let jobTypes = '';
    if (jobcat) {
      jobTypes += mapJobCat(jobcat);
      if (subjobcat) {
        jobTypes += ', ';
      }
    }
    if (subjobcat) {
      jobTypes += mapSubJobCat(subjobcat);
    }
    label = label.concat('ประเภทงาน', `(${jobTypes})`, ', ');
  }
  if (tag) {
    label = label.concat(mapTags(tag), ', ');
  }
  if (experience_requirement) {
    label = label.concat(mapExperience(experience_requirement), ', ');
  }
  if (education_requirement) {
    label = label.concat(mapEducation(education_requirement), ', ');
  }
  if (salary_min && salary_max) {
    const {salaryMin, salaryMax} = mapSalary(salary_min, salary_max);
    label = label.concat('เงินเดือน ', salaryMin, '-');
    label = label.concat(salaryMax, ', ');
  }
  if (province) {
    label = label.concat(mapProvince(province), ', ');
  }
  if (district) {
    label = label.concat(mapDistrict(district), ', ');
  }
  if (gender_requirement) {
    label = label.concat(mapGender(gender_requirement), ', ');
  }
  if (label[label.length - 2] === ',') {
    return label.substr(0, label.length - 2);
  }
  if (!label && author_id) {
    return label.concat('-');
  }
  return label;
};
