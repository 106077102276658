/* eslint-disable react/prop-types */
import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getAuthToken} from '../utils/storage/storage';
import * as routeName from './routes-name';
import * as logic from './logic';

const ProtectedRoute = ({component: Component, ...rest}) => {
  const registerState = useSelector((state) => state.register.registerId._id);
  const authState = useSelector((state) => state.auth.auth);
  const userState = useSelector((state) => state.user.information);

  const checkIsAcceptPolicy = () => {
    if (!userState?.user || !authState?.isCheckedAuth) {
      return true;
    }
    const isAccepted =
      logic.checkUserAcceptedPolicy(authState?.user?.terms_and_conditions) || logic.checkUserAcceptedPolicy(userState?.user?.terms_and_conditions);
    return isAccepted;
  };

  if (rest.main || rest.verifyPolicy) {
    if (!authState?.isCheckedAuth || !authState?.user) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }

    return (
      <Route
        {...rest}
        render={(props) =>
          checkIsAcceptPolicy() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: routeName.ROUTE_ACCEPT_POLICY,
              }}
            />
          )
        }
      />
    );
  }

  if (rest.acceptPolicy) {
    if (!authState?.isCheckedAuth) {
      return (
        <Redirect
          to={{
            pathname: routeName.ROUTE_MAIN,
          }}
        />
      );
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          checkIsAcceptPolicy() ? (
            <Redirect
              to={{
                pathname: routeName.ROUTE_MAIN,
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }

  if (rest.protectedVerify) {
    return (
      <Route
        {...rest}
        render={(props) =>
          !registerState ? (
            <Redirect
              to={{
                pathname: routeName.ROUTE_MAIN,
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        getAuthToken() ? (
          <Redirect
            to={{
              pathname: routeName.ROUTE_MAIN,
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default ProtectedRoute;
