import React from 'react';
import Environment from '../../env.json';

export const envServicesRef = React.createRef();

export const createServiceFirebaseStore = () => {
  const ENV = Environment;

  if (envServicesRef.current === 'APPLE_STORE') {
    return ENV.APPLE_STORE.FIREBASE_STORE;
  }
  return ENV.PUBLIC.FIREBASE_STORE;
};
