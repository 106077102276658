import * as dateTime from '../../../../utils/date-time';

const BDH = 543;
export const yearData = () => {
  const data = [];
  const maxYear = dateTime.yearBdh();
  const startYear = maxYear - 60;
  for (let i = maxYear; i > startYear; i--) {
    const item = {
      label: `ปี ${i}`,
      value: `${i - BDH}`,
    };
    data.push(item);
  }
  return data;
};
