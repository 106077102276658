import React from 'react';
import {useIntl} from 'react-intl';
import {useScrollYDirectionEffect} from '../../lib/hooks/useScrollingEffect';
import {useSearchAuthorEffect, useSearchAuthorState, useSearchAuthorDispatch} from '../../lib/hooks/logic/useSearch';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import {useSearchCountState} from '../../lib/hooks-logic/useSearchResultCount';
import JobPostBuilder from '../../views/job-post-builder';
import Screen from '../../views/screen';
import MetaTags from '../../components/meta-tag';
import FilterBuilder from '../../views/filter-builder';
import FilterSmallBuilder from '../../views/app-bar-filter';
import NavbarFilter from '../../views/app-bar-filter/nav-bar-filter';
import {parseJsonLd} from '../../lib/context/parse-jsonld';
import {useDescription} from './hooks/useDescription';
import {useTitle} from './hooks/useTitle';

const SearchAuthorScreen = () => {
  useSetGrayBackground();
  useSearchAuthorEffect();

  const {scrollDirection} = useScrollYDirectionEffect();
  const {formatMessage} = useIntl();
  const {resultCount} = useSearchCountState();
  const {stackFeedState, isLoadingState, isEmptyDataState, isCanFetchMoreState, pageIndexState, newFeedState} = useSearchAuthorState();
  const {dispatchFetchSearchAuthor} = useSearchAuthorDispatch();
  const description = useDescription(stackFeedState, newFeedState?.countDisplay);
  const title = useTitle(stackFeedState);

  const onEventEndReached = () => {
    if (!isLoadingState && !isEmptyDataState && isCanFetchMoreState) {
      dispatchFetchSearchAuthor(pageIndexState);
    }
  };

  return (
    <>
      <MetaTags title={title} description={description} scriptLdJson={parseJsonLd(stackFeedState, 'search.author')} />
      <Screen
        noEdgeInsets
        isChildScreen
        type="two-col-layout"
        title={formatMessage({defaultMessage: 'ผลลัพธ์การค้นหา'})}
        subTitle={`${resultCount} รายการ`}
        leftComponent={<FilterBuilder />}
        onEndReached={onEventEndReached}>
        <>
          <FilterSmallBuilder />
          <NavbarFilter isShow={scrollDirection.top} />
          <JobPostBuilder
            noTopEdgeInsets
            noBottomEdgeInsets
            data={stackFeedState}
            isEmptyData={isEmptyDataState}
            isLoadingFeed={isLoadingState}
            scriptLdJson={parseJsonLd(stackFeedState, 'search.author')}
          />
        </>
      </Screen>
    </>
  );
};

SearchAuthorScreen.propTypes = {};

export default SearchAuthorScreen;
