import * as TYPES from './type';

const initState = {
  type: null,
  message: null,
};
export const snackbarReducer = (state = initState, {type, payload = {}}) => {
  switch (type) {
    case TYPES.SNACKBAR_SET_MESSAGE_REQ:
      return state;
    case TYPES.SNACKBAR_SET_MESSAGE_SUCCESS:
      return {...payload};
    case TYPES.SNACKBAR_REMOVE_MESSAGE_REQ:
      return {...state};
    case TYPES.SNACKBAR_REMOVE_MESSAGE_SUCCESS:
      return {...initState};
    case TYPES.SNACKBAR_CLEAR_MESSAGE_REQ:
      return {...state};
    case TYPES.SNACKBAR_CLEAR_MESSAGE_SUCCESS:
      return {...initState};
    default:
      return state;
  }
};
