import {useEffect} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import * as locationUtils from '../../context/location';
import * as contextActions from '../../../redux/context/action';
import * as searchActions from '../../../redux/search/action';
import {useUserState} from './useUser';

const DEFAULT_LOCATIONS = [
  "กรุงเทพมหานคร|จอมทอง",
  "กรุงเทพมหานคร|บางคอแหลม",
  "กรุงเทพมหานคร|ภาษีเจริญ",
  "กรุงเทพมหานคร|คลองสาน",
  "กรุงเทพมหานคร|ธนบุรี"
];

export const useLocation = () => {
  const locationNearestState = useSelector(
    (state) =>
      locationUtils.smartLocation({
        locations: navigator.userAgent.indexOf("prerender") !== -1 ? DEFAULT_LOCATIONS : state.search.locationNearest.locationNearest
      }),
    shallowEqual,
  );

  const {userState, isInitState} = useUserState();
  const recommendLocationState = useSelector((state) => state.search.jobCat.popularJobCat.location, shallowEqual);
  const recommendLocationState2 = useSelector((state) => state.search.jobCat.popularJobCat.locationSpecified, shallowEqual);

  const result = !isInitState
    ? null
    : recommendLocationState2 || userState?.desire_work_province || userState?.location?.province || recommendLocationState || locationNearestState;

  return {
    locationNearestState: result,
    recommendLocationState: result,
  };
};

export const useLocationContextEffect = () => {
  const dispatch = useDispatch();
  const {data: contextProvinceState, loading: isLoadingState} = useSelector((state) => state.context.area.provinces);
  useEffect(() => {
    if (!Array.isArray(contextProvinceState) && !isLoadingState && navigator.onLine) {
      dispatch(contextActions.contextGetProvinces());
    }
  }, [contextProvinceState, isLoadingState, dispatch]);
};

export const useLocationContextState = () => {
  const {data: contextProvinceState, loading: isLoadingProvinceState} = useSelector((state) => state.context.area.provinces);
  const {data: contextDistrictState, loading: isLoadingDistrictState, popular: popularState} = useSelector((state) => state.context.area.districts);
  return {contextProvinceState, contextDistrictState, isLoadingProvinceState, isLoadingDistrictState, popularState};
};

export const useLocationContextDispatch = () => {
  const dispatch = useDispatch();
  const dispatchGetDistrictByProvince = (province) => {
    if (!province) {
      return;
    }
    dispatch(
      contextActions.contextGetDistricts({
        data: {province},
      }),
    );
  };

  return {dispatchGetDistrictByProvince};
};

export const useLocationPopularDispatch = () => {
  const dispatch = useDispatch();
  const dispatchUpdateCurrentLocation = (province) => {
    if (!province) {
      return;
    }
    dispatch(searchActions.searchPopularJobUpdateLocation({data: province}));
  };
  return {dispatchUpdateCurrentLocation};
};
