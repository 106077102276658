import Numeral from 'numeral';

export const numberWithComma = ({input}) => {
  if (!input) {
    return '0';
  }
  return Numeral(input).format('0,0');
};

export const commaToNumber = ({input, string = false}) => {
  if (!input) {
    return '';
  }
  return string ? `${Numeral(input).value()}` : Numeral(input).value();
};

export const numberWithK = ({input}) => {
  if (!input) {
    return '';
  }
  try {
    return `${Math.floor(input) / 1000}K`;
  } catch (error) {
    return '';
  }
};

export const numberPhone = ({input}) => {
  if (!input) {
    return '';
  }
  try {
    if (input.length === 10) {
      const pattern = /^(\d{3})(\d{3})(\d{4})$/;
      const match = input.match(pattern);
      if (!match) {
        return '';
      }
      return [match[1], '-', match[2], '-', match[3]].join('');
    }
    if (input.length === 9) {
      const pattern = /^(\d{2})(\d{3})(\d{4})$/;
      const match = input.match(pattern);
      if (!match) {
        return '';
      }
      return [match[1], '-', match[2], '-', match[3]].join('');
    }
    return '';
  } catch (error) {
    return '';
  }
};
