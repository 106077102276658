import {useSelector, shallowEqual} from 'react-redux';
import get from 'lodash/get';

export const useGetReduxState = (statePath) => {
  const reduxState = useSelector((state) => {
    const res = get(state, statePath);
    return res;
  }, shallowEqual);
  return {reduxState};
};
