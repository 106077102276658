import * as TYPES from './type';

export const userShortProfile = (payload) => ({
  type: TYPES.USER_SHORT_PROFILE_REQ,
  payload: payload,
});

export const userDetailProfile = (payload) => ({
  type: TYPES.USER_DETAIL_PROFILE_REQ,
  payload: payload,
});

export const clearUserProfile = () => ({
  type: TYPES.CLEAR_USER_REQ,
  payload: {},
});

export const editUserProfile = (payload) => ({
  type: TYPES.EDIT_USER_PROFILE_REQ,
  payload: payload,
});

export const userChangePassword = (payload) => ({
  type: TYPES.USER_CHANGE_PASSWORD_REQ,
  payload: payload,
});

export const userManualUploadResume = (payload) => ({
  type: TYPES.USER_UPLOAD_RESUME_MANUAL_REQ,
  payload: payload,
});

export const userAutoGenerateResume = () => ({
  type: TYPES.USER_GENERATE_AUTO_RESUME_REQ,
});

export const userAcceptTerm = (payload) => ({
  type: TYPES.USER_ACCEPT_TERM_REQ,
  payload: payload,
});
