import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {PostWrapper} from './styled-component';
import NotifyHeader from './view/notifyHeader';
import NotifyContent from './view/notifyBody';
import * as dateTime from '../../utils/date-time';
import * as logic from './logic';

const NotifyPostBuilder = (props) => {
  const {notifyData, searchParams} = props;

  return (
    <PostWrapper>
      <NotifyHeader
        type={get(notifyData, 'type')}
        title={notifyData?.notification?.title}
        notifyDate={dateTime.postDateFormat({date: notifyData?.start_date})}
      />
      <NotifyContent
        type={get(notifyData, 'type')}
        notifyContent={get(notifyData?.notification, 'body')}
        searchParams={logic.getSearchParams(get(notifyData, 'type'), searchParams)}
      />
    </PostWrapper>
  );
};

NotifyPostBuilder.propTypes = {
  notifyData: PropTypes.object.isRequired,
  searchParams: PropTypes.string,
};

NotifyPostBuilder.defaultProps = {
  searchParams: null,
};

export default NotifyPostBuilder;
