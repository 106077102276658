import styled from 'styled-components';

export const SpacerWidth = styled.div`
  display: flex;
  width: ${(props) => props.width}px;
  height: 100%;
`;

export const SpacerHeight = styled.div`
  display: flex;
  height: ${(props) => props.height}px;
  width: 100%;
`;
