import React from 'react';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {StarWrapper, OverrideStyles as MainOverrideStyles} from '../styled-component';
import TextBold from '../../../../components/text-bold';
import Icon from '../../../../components/icon';
import * as ICONS from '../../../../components/icon/constants';

const StarView = (props) => {
  const {score, total} = props;
  const {formatMessage} = useIntl();

  return (
    <StarWrapper>
      <TextBold style={MainOverrideStyles.textStar}>{`(${score}/${total}`}</TextBold>
      <Icon name={ICONS.STAR} color={MainOverrideStyles.iconStarColor} size={14} />
      <TextBold style={MainOverrideStyles.textStarEnd}>{formatMessage({defaultMessage: ')'})}</TextBold>
    </StarWrapper>
  );
};

StarView.propTypes = {
  score: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

StarView.defaultProps = {};

export default StarView;
