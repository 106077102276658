import React from 'react';
import PropTypes from 'prop-types';
import RenderPostHeader from './render';
import * as ROUTES from '../../../../routes/routes-name';

const PostHeader = (props) => {
  const {jobId, authorId, authorSrc, isMeta, isCompany, avatarSrc, authorName, postDate, location, isCompanyVerified, isActivated, srcRemoved, src} =
    props;

  if (authorId && !isCompany) {
    return (
      <RenderPostHeader
        isMeta={isMeta}
        isCompanyVerified={isCompanyVerified}
        avatarSrc={avatarSrc}
        authorName={authorName}
        postDate={postDate}
        location={location}
        srcRemoved={srcRemoved}
        isActivated={isActivated}
        src={src}
        linkOptions={{
          url: authorSrc !== 'company' ? ROUTES.ROUTE_SEARCH_AUTHOR : `${ROUTES.ROUTE_COMPANY_PROFILE_RAW}${authorId}`,
          search: authorSrc !== 'company' ? `?author_id=${authorId}` : '',
        }}
        jobId={jobId}
      />
    );
  }

  if (authorId && isCompany && !isMeta) {
    return (
      <RenderPostHeader
        isCompanyPage
        isMeta={isMeta}
        isCompanyVerified={isCompanyVerified}
        avatarSrc={avatarSrc}
        authorName={authorName}
        postDate={postDate}
        location={location}
        srcRemoved={srcRemoved}
        isActivated={isActivated}
        src={authorSrc}
        jobId={jobId}
      />
    );
  }

  return (
    <RenderPostHeader
      isMeta={isMeta}
      isCompanyVerified={isCompanyVerified}
      isCompany={isCompany}
      avatarSrc={avatarSrc}
      authorName={authorName}
      postDate={postDate}
      location={location}
      srcRemoved={srcRemoved}
      isActivated={isActivated}
      src={authorSrc}
    />
  );
};

PostHeader.propTypes = {
  isMeta: PropTypes.bool,
  authorSrc: PropTypes.string,
  avatarSrc: PropTypes.string,
  authorName: PropTypes.string,
  postDate: PropTypes.string,
  location: PropTypes.string,
  isCompany: PropTypes.bool,
  isCompanyVerified: PropTypes.bool,
  isActivated: PropTypes.bool,
  srcRemoved: PropTypes.bool,
  src: PropTypes.string.isRequired,
  authorId: PropTypes.string,
  jobId: PropTypes.string,
};

PostHeader.defaultProps = {
  isMeta: false,
  authorSrc: null,
  avatarSrc: null,
  authorName: null,
  postDate: null,
  location: null,
  isCompany: false,
  isCompanyVerified: false,
  isActivated: true,
  srcRemoved: false,
  authorId: null,
  jobId: null,
};

export default PostHeader;
