import get from 'lodash/get';
import thaiResource from './content/thai';
import apiThaiResource from './api/thai';

export const translate = (key) => {
  // const receiveCurrentLocal = isOSWeb ? 'en-US' : 'th-TH';
  const receiveCurrentLocal = 'th-TH';
  if (receiveCurrentLocal === 'th-TH') {
    return get(thaiResource, key);
  }
  return get(thaiResource, key);
};

export const translateApi = (key) => {
  const receiveCurrentLocal = 'th-TH';
  if (receiveCurrentLocal === 'th-TH') {
    return get(apiThaiResource, key);
  }
  return get(apiThaiResource, key);
};
