import store from '../store';
import * as authActions from '../auth/action';
import * as alertActions from '../alert/action';
import * as snackbarActions from '../snackbar/action';

export const cancelInvokeOTPProvider = (tel) => {
  if (!tel) {
    return;
  }
  store.dispatch(authActions.userOtpCancelInvoke({data: {tel}}));
};

export const alertProvider = (message) => {
  if (!message) {
    return;
  }
  store.dispatch(alertActions.alertSetAlert({data: {message}}));
};

export const alertManualProvider = (data = {}) => {
  if (!data) {
    return;
  }
  store.dispatch(alertActions.alertSetAlert({data: {...data}}));
};

export const alertRemoveProvider = () => {
  store.dispatch(alertActions.alertRemoveAlert());
};

export const snackbarProvider = (message, type) => {
  if (!message) {
    return;
  }
  store.dispatch(snackbarActions.snackbarSetMessage({data: {message, type}}));
};

export const snackbarManualProvider = ({message, type, danger}) => {
  if (!message) {
    return;
  }
  store.dispatch(
    snackbarActions.snackbarSetMessage({
      data: {message, type, danger: !!danger},
    }),
  );
};
