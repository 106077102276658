import React from 'react';
import PropTypes from 'prop-types';
import {StyledGesture} from './styled-component';

const GestureDetector = (props) => {
  const {onClick, disabled, style, children, withEdgeInsets, circle, hovered} = props;

  return (
    <StyledGesture onClick={onClick} disabled={disabled} style={style} withEdgeInsets={withEdgeInsets} circle={circle} hovered={hovered}>
      {children}
    </StyledGesture>
  );
};

GestureDetector.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.any,
  children: PropTypes.any,
  withEdgeInsets: PropTypes.bool,
  circle: PropTypes.bool,
  hovered: PropTypes.bool,
};

GestureDetector.defaultProps = {
  disabled: false,
  style: {},
  children: null,
  withEdgeInsets: true,
  circle: false,
  hovered: false,
};

export default GestureDetector;
