import {takeEvery} from 'redux-saga/effects';
import * as TYPES from './type';
import * as saga from './saga';

export function* watchFetchJobDraft() {
  yield takeEvery(TYPES.GET_APPLY_JOB_DRAFT_REQ, saga.getDraft);
}

export function* watchUploadResume() {
  yield takeEvery(TYPES.GET_APPLY_JOB_RESUME_REQ, saga.uploadResume);
}

export function* watchSaveDraft() {
  yield takeEvery(TYPES.GET_APPLY_JOB_SAVE_DRAFT_REQ, saga.saveDraft);
}

export function* watchSaveData() {
  yield takeEvery(TYPES.GET_APPLY_JOB_SAVE_DATA_REQ, saga.saveData);
}

export function* watchSendMail() {
  yield takeEvery(TYPES.APPLY_JOB_SEND_MAIL_REQ, saga.sendMailAction);
}
