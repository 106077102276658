import React from 'react';
import PropTypes from 'prop-types';
import {SpacerHeight, SpacerWidth} from './styled-component';

const Spacer = (props) => {
  const {width, height} = props;
  if (height) {
    return <SpacerHeight height={height} />;
  }
  return <SpacerWidth width={width} />;
};

Spacer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Spacer.defaultProps = {
  width: 0,
  height: 10,
};

export default Spacer;
