import {takeEvery} from 'redux-saga/effects';
import * as TYPES from './type';
import * as likeSaga from './saga';

export function* watchLikeNewLike() {
  yield takeEvery(TYPES.LIKE_NEW_LIKE_REQ, likeSaga.likeNewLikeAction);
}

export function* watchLikeUnLike() {
  yield takeEvery(TYPES.LIKE_UNLIKE_REQ, likeSaga.likeUnlikeAction);
}

export function* watchLikeUpdateStream() {
  yield takeEvery(TYPES.LIKE_UPDATE_STREAM_REQ, likeSaga.likeUpdateStreamAction);
}
