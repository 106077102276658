import * as typeDef from '../../../utils/strong-types';
import {setStackHeader} from '../../job-post-builder/utils/header-regex';

/* eslint-disable */
const SHUFFLE_TYPE = new Set(['head1', 'head2', 'head4']);

const checkSkipSection = (head) => {
  if (!typeDef.isString(head)) {
    return false;
  }
  const pattern = /<h>|<\/h>/g;
  const headText = head.replace(pattern, '');
  return setStackHeader({header: headText});
};

export const renderWithBullet = (text, source) => {
  if (source === 'findgovjob' && text === '-') {
    return text;
  }

  if (!typeDef.isString(text)) {
    return text;
  }
  if (/^[\s\u2000-\u200F\u2800.]+$/.test(text)) return ' ';

  // remark text like **โซน พื้นที่เปิดรับ** **หากมีประสบการณ์ด้านงานขายซิม หรือ โทรศัพท์มือถือมาก่อนพิจารณาเป็นพิเศษ**
  if (/^\s*[*#(].+[*#)]\s*$/.test(text)) return text.trim();

  // hashtag
  if (/^\s*#/.test(text)) return text.trim();

  const bullet = '• ';
  const patternNumber =
    /^-|^[\s\u2000-\u200F\u2800]*[0-9]*[\s\u2000-\u200F\u2800]*(\.|\))\s*|^[\s\u2000-\u200F\u2800]*[•·\-✓*—]([\s\u2000-\u200F\u2800]+|([a-zก-๛]))/gim;
  const starStarPattern = /^[\s\u2000-\u200F\u2800]*\*\S+\*/;
  if (patternNumber.test(text) && !starStarPattern.test(text)) {
    return text.trim().replace(patternNumber, bullet);
  }
  return bullet.concat(text.trim());
};

const findShuffleRange = (listText) => {
  let start2 = -1;
  let end2 = -1;

  const listText2 = listText.map(renderWithBullet);
  for (let i = 0, ii = listText2.length; i < ii; i++) {
    const current = listText2[i];

    //level 2
    if (/^  ✓/.test(current)) {
      if (start2 === -1) start2 = i;
      end2 = i;
    } else if (start2 !== -1) {
      if (end2 - start2 < 1) start2 = -1;
      else return {start: start2, end: end2, mode: 2};
    }
  }

  for (let i = listText2.length - 1; i >= 0; i--) {
    const next = listText2[i + 1];
    const current = listText2[i];
    const previous = listText2[i - 1];

    if (/^• [ก-๛a-z]/i.test(current) && /^• [ก-๛a-z]/i.test(previous) && (next ? /^• [ก-๛a-z]/i.test(next) : true)) return {start: 0, end: i};
  }

  return {start: 0, end: listText.length - 1, mode: 1};
};

export const shuffleBody = (listText, head, count) => {
  const headerType = checkSkipSection(head);
  if (!SHUFFLE_TYPE.has(headerType) && count !== 1 && (headerType === null ? listText.length <= 4 : true)) return listText;

  const {start, end, mode} = findShuffleRange(listText);
  if (end - start < 2 && mode !== 2) return listText;

  const ans = [...listText];
  ans.splice(end - 1, 2, listText[end], listText[end - 1]);
  return ans;
};
