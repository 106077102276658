import React from 'react';
import PropTypes from 'prop-types';
import {Container, ContainerDivider, Title, Description, IconWrapper, OverrideStyles} from './styled-component';
import Icon from '../icon';
import Column from '../column';
import Divider from '../divider';

const ListTile = (props) => {
  const {icon, divider, title, description, isActive, rightArrow, topEdgeInsets, rightComponent, onClick, titleStyle, descriptionStyle} = props;
  const bodyStyle = icon ? OverrideStyles.body : null;
  const isAction = typeof onClick === 'function';

  if (divider) {
    return (
      <>
        <ContainerDivider topEdgeInsets={topEdgeInsets} isAction={isAction} onClick={() => (onClick ? onClick() : {})}>
          {icon && (
            <IconWrapper>
              <Icon name={icon} color={OverrideStyles.icon} />
            </IconWrapper>
          )}
          <Column style={bodyStyle}>
            <Title active={isActive} style={titleStyle}>
              {title}
            </Title>
            {description && (
              <Description active={isActive} style={descriptionStyle}>
                {description}
              </Description>
            )}
          </Column>
          {rightComponent && <>{rightComponent}</>}
          {rightArrow && !rightComponent && <Icon name="arrow-right" color={OverrideStyles.icon} />}
        </ContainerDivider>
        <Divider style={OverrideStyles.divider} />
      </>
    );
  }
  return (
    <Container isAction={isAction} isActive={isActive} onClick={() => (onClick ? onClick() : {})}>
      {icon && <Icon name={icon} color={OverrideStyles.icon} />}
      <Column style={bodyStyle}>
        <Title active={isActive} style={titleStyle}>
          {title}
        </Title>
        {description && (
          <Description active={isActive} style={descriptionStyle}>
            {description}
          </Description>
        )}
      </Column>
      {rightComponent && <>{rightComponent}</>}
      {rightArrow && !rightComponent && <Icon name="arrow-right" color={OverrideStyles.icon} />}
    </Container>
  );
};

ListTile.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  divider: PropTypes.bool,
  rightArrow: PropTypes.bool,
  rightComponent: PropTypes.any,
  topEdgeInsets: PropTypes.bool,
  isActive: PropTypes.bool,
  titleStyle: PropTypes.any,
  descriptionStyle: PropTypes.any,
  onClick: PropTypes.func,
};

ListTile.defaultProps = {
  icon: null,
  title: null,
  description: null,
  divider: false,
  rightArrow: false,
  isActive: false,
  rightComponent: null,
  topEdgeInsets: false,
  titleStyle: null,
  descriptionStyle: null,
  onClick: null,
};

export default ListTile;
