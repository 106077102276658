import React from 'react';
import {translate} from '../../res/language/translate';
import {useLandingGroupSliderEffect} from '../../lib/hooks/logic/useLandingGroup';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import {useSearchTimelineEffect, useSearchTimelineState, useSearchTimelineDispatch} from '../../lib/hooks/logic/useSearchTimeline';
import {useSearchHistoryEffect} from '../../lib/hooks/logic/useSearchHistory';
import {useRouteCallBackPathEffect} from './hooks/useRouteCallBackPath';
import JobPostBuilder from '../../views/job-post-builder';
import Screen from '../../views/screen';
import Loading from '../../components/loading';
import MetaTags from '../../components/meta-tag';
import SearchHistory from './views/search-history';
import RecommendJob from './views/recommend-job';
import DivWrapper from '../../components/div-wrapper';
import JobGroup from './views/job-group';
import Responsive from '../../components/responsive';
// import HotCompanyBuilder from '../../views/hot-company-builder';
import LAYOUT from '../../styles/layout';

const HomeScreen = () => {
  useSetGrayBackground();
  useSearchTimelineEffect();
  useSearchHistoryEffect();
  useRouteCallBackPathEffect();

  const {groupListState} = useLandingGroupSliderEffect();
  const {dispatchFetchSearchTimeline} = useSearchTimelineDispatch();

  const {isEmptyDataState, stackFeedState, isLoadingState, isLoadingRefreshState, pageIndexState, isCanFetchMoreState} = useSearchTimelineState();

  const onEventEndReached = () => {
    if (!isLoadingState && !isEmptyDataState && isCanFetchMoreState) {
      dispatchFetchSearchTimeline(pageIndexState);
    }
  };
  return (
    <>
      <MetaTags title={translate('meta.home')} description={translate('meta.home')} />
      <Screen
        noEdgeInsets
        isChildScreen
        type="timeline-layout"
        leftComponent={
          <DivWrapper>
            <SearchHistory />
            <RecommendJob />
            <Responsive maxWidth={LAYOUT.content.twoLayoutMinFluid - 1}>
              <JobGroup data={groupListState} />
            </Responsive>
          </DivWrapper>
        }
        rightComponent={
          <>
            {/* <Responsive maxWidth={LAYOUT.content.twoLayoutMinFluid - 1}>
              <HotCompanyBuilder as="content" />
            </Responsive>
            <Responsive minWidth={LAYOUT.content.twoLayoutMinFluid}>
              <HotCompanyBuilder as="rightColumn" />
            </Responsive> */}
          </>
        }
        onEndReached={onEventEndReached}>
        <Responsive minWidth={LAYOUT.content.twoLayoutMinFluid}>
          <JobGroup fluid data={groupListState} />
        </Responsive>
        <Loading loading={isLoadingRefreshState} type="refresh" />
        <JobPostBuilder noTopEdgeInsets noBottomEdgeInsets data={stackFeedState} isEmptyData={isEmptyDataState} isLoadingFeed={isLoadingState} />
      </Screen>
    </>
  );
};

HomeScreen.propTypes = {};

export default HomeScreen;
