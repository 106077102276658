import React from 'react';
import {useFilterModal} from '../../lib/hooks/logic/useFilter';
import {ModalFilterWrapper} from './styled-component';
import BottomSheet from '../bottom-sheets';
import FilterOptions from './index';

const ModalFilter = () => {
  const {isVisibleFilterState, dispatchSetVisibleFilter} = useFilterModal();

  return (
    <BottomSheet isOpen={isVisibleFilterState} noEdgeInsets onRequestClose={() => dispatchSetVisibleFilter(false)}>
      <ModalFilterWrapper>
        <FilterOptions />
      </ModalFilterWrapper>
    </BottomSheet>
  );
};

ModalFilter.propTypes = {};

ModalFilter.defaultProps = {};

export default ModalFilter;
