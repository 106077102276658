import * as TYPES from './type';

const initHotCompanyState = {
  loading: false,
  error: null,
  pageIndex: 0,
  data: null,
};

export const hotCompanyReducer = (state = initHotCompanyState, {type, payload = {}}) => {
  switch (type) {
    case TYPES.HOT_COMPANY_GET_LIST_REQ:
      return {...state, loading: true, pageIndex: state.pageIndex + 1};
    case TYPES.HOT_COMPANY_GET_LIST_SUCCESS:
      return {...state, loading: false, data: payload};
    case TYPES.HOT_COMPANY_GET_LIST_FAIL:
      return {...state, error: payload, loading: false, pageIndex: state.pageIndex - 1};
    default:
      return state;
  }
};
