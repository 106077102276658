import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Calendar} from '@hassanmojab/react-modern-calendar-datepicker';
import {customLocal, dateRange} from './config';
import {Container, InputWrapper, Input, InputLabel, InputHint, OverrideStyles} from './styled-component';
import ReactModal from '../react-modal';
import Icon from '../icon';
import * as logic from './logic';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import './calendar.css';

const DatePicker = (props) => {
  const {onChange, label, warningText, initDate} = props;
  const [dateState, setDateState] = useState();
  const [isFocusedState, setIsFocusedState] = useState(false);

  useEffect(() => {
    const setInitDate = (date) => {
      if (!date) {
        return;
      }
      try {
        const convertDate = {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        };
        setDateState(convertDate);
      } catch (error) {
        setDateState(null);
      }
    };
    if (initDate) {
      const convertDate = new Date(initDate);
      setInitDate(convertDate);
    }
  }, [initDate]);

  const onChangeDate = (date) => {
    onChange(logic.convertDate(date));
    setIsFocusedState(false);
    if (!initDate) {
      setDateState(date);
    }
  };

  return (
    <Container>
      <InputLabel>{label}</InputLabel>
      <InputWrapper onClick={() => setIsFocusedState(true)} warningText={warningText}>
        <Icon name="calendar" color={OverrideStyles.icon.colorGray} />
        <Input readOnly disabled placeholder={label} value={logic.convertDate(dateState)} />
      </InputWrapper>
      {warningText && <InputHint warning={!!warningText}>{warningText}</InputHint>}
      <ReactModal isVisible={isFocusedState} onRequestClose={() => setIsFocusedState(false)}>
        <Calendar
          value={dateState}
          onChange={onChangeDate}
          shouldHighlightWeekends
          locale={customLocal}
          maximumDate={dateRange.maximum}
          minimumDate={dateRange.minimum}
          calendarClassName="responsive-calendar"
        />
      </ReactModal>
    </Container>
  );
};

DatePicker.propTypes = {
  initDate: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  warningText: PropTypes.string,
};

DatePicker.defaultProps = {
  initDate: null,
  warningText: '',
};

export default DatePicker;
