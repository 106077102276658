import styled from 'styled-components';

export const Container = styled.div`
  z-index: 999;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.imageBackdrop};
`;

export const CloseWrapper = styled.div`
  z-index: 10;
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0.5rem;
  top: 0.5rem;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.chipBackground};
`;

export const SnapButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 1rem;
  top: 1rem;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.chipBackground};
`;

export const SnapLeftWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3.5rem;
  background-color: ${(props) => props.theme.imageBackdropAlpha};
`;

export const SnapRightWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  width: 3.5rem;
  background-color: ${(props) => props.theme.imageBackdropAlpha};
`;

export const ImageWrapper = styled.img`
  width: calc(1.2 * ${(props) => props.width});
  max-width: 100vw;
  max-height: 95vh;
  object-fit: contain;
`;
