import React, {useState, useEffect} from 'react';
import {ThemeProvider} from 'styled-components';
import {ToastProvider} from 'react-toast-notifications';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {useHotJar} from './utils/hot-jar/useHotJar';
import {useAuthEffect} from './lib/hooks-logic/useAuth';
import {GlobalStyle} from './styles/global-style';
import {lightTheme, darkTheme} from './styles/themes';
import AppBar from './views/app-bar';
import BottomBar from './views/bottom-bar';
import ComposeSidebar from './views/side-bar';
import RootView from './screens/root';
import ToastNotification from './utils/components/toast-notification';
import ToastBody from './utils/components/toast-notification/custom';
import ImageViewer from './views/image-viewer';
import Alert from './utils/components/alert';
import Routes from './routes/routes';
import {navigationRef} from './utils/navigation/navigation-services';
import {init} from './utils/mixpanel';
import {ErrorBoundary} from './views/error-boundary';
import {usePrerender} from './utils/prerender/usePrerender';
import MaintenanceScreen from './screens/maintenance';

const App = () => {
  usePrerender();
  useAuthEffect();
  useHotJar();

  const [themeMode] = useState('light');
  const serverMaintenance = false;

  useEffect(() => {
    init();
  }, []);

  return (
    <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
      <GlobalStyle />
      {serverMaintenance && <MaintenanceScreen />}
      {!serverMaintenance && (
        <>
          <Alert />
          <ErrorBoundary>
            <ImageViewer />
            <ToastProvider components={{Toast: ToastBody}}>
              <ToastNotification />
              <Router ref={navigationRef}>
                <RootView />
                <AppBar />
                <ComposeSidebar />
                <BottomBar />
                <Switch>
                  <Route exact component={Routes} />
                </Switch>
              </Router>
            </ToastProvider>
          </ErrorBoundary>
        </>
      )}
    </ThemeProvider>
  );
};

export default App;
