import {regexPassword} from '../../../../utils/regex';
import {translate} from '../../../../res/language/translate';

const validateOldPassword = (password) => {
  if (!password) {
    return translate('changePassword.errorOldPassword.undefined');
  }
  return '';
};

const validatePassword = (password) => {
  if (!password) {
    return translate('changePassword.errorNewPassword.undefined');
  }
  if (!regexPassword({input: password})) {
    return translate('changePassword.errorNewPassword.regex');
  }
  return '';
};

const validateConfirmPassword = (password, confPassword) => {
  if (!confPassword) {
    return translate('changePassword.errorNewPassword.confUndefined');
  }
  if (password !== confPassword) {
    return translate('changePassword.errorNewPassword.newNotMatch');
  }
  return '';
};

export const validateForm = (form) => {
  if (!form) {
    const errorUndefinedOld = translate('changePassword.errorNewPassword.undefined');
    const errorUndefined = translate('changePassword.errorNewPassword.undefined');
    const errorUnConfirm = translate('changePassword.errorNewPassword.confUndefined');
    return [errorUndefinedOld, errorUndefined, errorUnConfirm];
  }
  const errorOldPassword = validateOldPassword(form.oldPassword);
  const errorPassword = validatePassword(form.newPassword);
  const errorConfPassword = validateConfirmPassword(form.newPassword, form.confPassword);

  return [errorOldPassword, errorPassword, errorConfPassword];
};
