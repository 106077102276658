import React, {useState} from 'react';
import {translate} from '../../../res/language/translate';
import {useFormSearchState, useFormSearchDispatch} from '../../../lib/hooks/logic/useForm';
import Select from '../../../components/select';
import * as ICONS from '../../../components/icon/constants';
import * as coreJobCat from '../../../lib/context/job-cat';

const CategoryForm = () => {
  const [jobCatsData] = useState(coreJobCat.createJobCatData());
  const {formSearchState} = useFormSearchState();
  const {dispatchUpdateForm} = useFormSearchDispatch();

  const shapeTextJobCatFunc = (text, item) => {
    if (item.parentId) {
      return text;
    }
    const shapeText = coreJobCat.shapeTextParentCategory({text});
    return shapeText;
  };

  const onClickedChip = (id) => {
    const newItems = coreJobCat.filterOutById({id, selectedOptions: formSearchState.jobcat || []});
    const copyForm = {...formSearchState};
    copyForm.jobcat = newItems;
    dispatchUpdateForm(copyForm);
  };

  const onChangeSelectJobCategory = (parentItem, childItem) => {
    const mapSelected = coreJobCat.mapSelectedByTypes({
      selectedOptions: formSearchState.jobcat || [],
      newItems: {
        parentItem,
        childItem,
      },
    });
    if (mapSelected.length > coreJobCat.LIMIT_JOB_CAT) {
      return;
    }
    const copyForm = {...formSearchState};
    copyForm.jobcat = mapSelected;
    dispatchUpdateForm(copyForm);
  };

  const onEventClearJobCat = () => {
    const copyForm = {...formSearchState};
    copyForm.jobcat = [];
    dispatchUpdateForm(copyForm);
  };

  return (
    <Select
      name="jobCat"
      outline
      iconLabel={ICONS.BAG_BUSINESS}
      label={translate('landing.search.jobCategory')}
      placeholder={translate('landing.search.select')}
      limitSelect={10}
      options={jobCatsData}
      selectedOptions={formSearchState.jobcat || []}
      shapeTextFunc={shapeTextJobCatFunc}
      onClickChip={onClickedChip}
      onChange={onChangeSelectJobCategory}
      onClear={() => onEventClearJobCat()}
    />
  );
};

CategoryForm.propTypes = {};

export default CategoryForm;
