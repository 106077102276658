import {regexEmail} from '../../../utils/regex';
import {translate} from '../../../res/language/translate';

const validateEmail = ({email}) => {
  if (!email) {
    return translate('register.errorEmail.undefined');
  }
  if (!regexEmail({input: email})) {
    return translate('register.errorEmail.regex');
  }
  return '';
};

const validatePassword = ({password}) => {
  if (!password) {
    return translate('register.errorPassword.undefined');
  }
  return '';
};

export const validateLoginForm = ({email, password}) => {
  const validateResult = {};
  validateResult.passwordResult = validatePassword({password});
  validateResult.emailResult = validateEmail({email});
  return validateResult;
};
