import React from 'react';
import {useIntl} from 'react-intl';
import {translate} from '../../res/language/translate';
import {useSetGrayBackground} from '../../lib/hooks/useSetGrayBackground';
import {useSearchId} from '../../lib/hooks/logic/useSearch';
import JobPostBuilder from '../../views/job-post-builder';
import MetaTags from '../../components/meta-tag';
import Screen from '../../views/screen';
import {parseJsonLd} from '../../lib/context/parse-jsonld';
import {useDescription} from './hooks/useDescription'
import {useTitle} from './hooks/useTitle'
import ENV from '../../env.json';

const SearchIdScreen = () => {
  useSetGrayBackground();
  const {formatMessage} = useIntl();
  const {jobIdState, isLoadingState, isEmptyResult} = useSearchId();
  const description = useDescription(jobIdState ? [jobIdState] : jobIdState)
  const title = useTitle(jobIdState ? [jobIdState] : jobIdState)

  return (
    <>
      {jobIdState && (
        <MetaTags
          title={title}
          description={description}
          image={jobIdState.post_image[0]?.url}
          quote=""
          scriptLdJson={parseJsonLd(jobIdState ? [jobIdState] : jobIdState, 'search.search-id')}
          hashtag={jobIdState.hashtags}
          contentUrl={`${ENV.PUBLIC.API_URL}/search_id/id?id=${jobIdState._id}`}
        />
      )}
      <Screen noEdgeInsets isChildScreen title={formatMessage({defaultMessage: 'ผลลัพธ์การค้นหา'})}>
        <JobPostBuilder
          noTopEdgeInsets
          noBottomEdgeInsets
          loadingCount={1}
          isLoadingFeed={isLoadingState}
          isEmptyData={isEmptyResult}
          emptyLabel={translate('emptyData.default')}
          data={jobIdState ? [jobIdState] : jobIdState}
        />
      </Screen>
    </>
  );
};

SearchIdScreen.propTypes = {};

export default SearchIdScreen;
