import React from 'react';
import 'draft-js/dist/Draft.css';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {Editor, EditorState, convertFromRaw} from 'draft-js';
import {JobText, OverrideStyles, RowHeader, JobTextHeader} from './styled-component';
import Icon from '../../../components/icon';
import * as ICONS from '../../../components/icon/constants';

const BodyFancyText = (props) => {
  const {fancyText, normalText} = props;
  const {formatMessage} = useIntl();

  const editorText = EditorState.createWithContent(convertFromRaw(JSON.parse(fancyText)));

  return (
    <>
      <Editor editorState={editorText} readOnly />
      <RowHeader>
        <Icon name={ICONS.STAR} color={OverrideStyles.iconBody.color} size={16} />
        <JobTextHeader>{formatMessage({defaultMessage: 'สวัสดิการ'})}</JobTextHeader>
      </RowHeader>
      <JobText>{normalText}</JobText>
    </>
  );
};

BodyFancyText.propTypes = {
  fancyText: PropTypes.string,
  normalText: PropTypes.string,
};

BodyFancyText.defaultProps = {
  fancyText: '',
  normalText: '',
};

export default BodyFancyText;
