const thGlobal = require('./global.json');
const thFacebook = require('./facebook-login.json');
const thRegister = require('./register.json');
const thLogin = require('./login.json');
const thForgotPass = require('./forgot-password.json');
const thMonth = require('./month.json');
const thOTP = require('./otp.json');
const thPicker = require('./picker.json');
const thLanding = require('./main.json');
const thMenus = require('./menus.json');
const thProfile = require('./profile.json');
const thTimeline = require('./timeline.json');
const thHome = require('./home.json');
const thSearch = require('./search.json');
const thNotify = require('./notify.json');
const thSetting = require('./setting.json');
const thMeta = require('./meta.json');
const thCompanyDetail = require('./company-detail.json');

const composeResource = {
  ...thGlobal,
  ...thFacebook,
  ...thRegister,
  ...thLogin,
  ...thForgotPass,
  ...thMonth,
  ...thOTP,
  ...thPicker,
  ...thLanding,
  ...thMenus,
  ...thProfile,
  ...thTimeline,
  ...thHome,
  ...thSearch,
  ...thNotify,
  ...thSetting,
  ...thMeta,
  ...thCompanyDetail,
};

export default composeResource;
