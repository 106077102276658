import styled from 'styled-components';

export const SectionItem = styled.div``;

export const AutoCompleteWrapper = styled.div`
  position: relative;
`;

export const OptionAbsolute = styled.div`
  z-index: 99;
  position: absolute;
  right: 0;
  left: 0;
  overflow: auto;
  max-height: 300px;
  background-color: white;
  border-radius: 6px;
  padding: 0.25rem 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 1rem;
  margin-top: -12px;
  ${(props) => props.space && `padding: 1rem`};
`;

export const OptionAbsoluteItem = styled.div`
  padding: 0.5rem 1rem;
  font-family: 'Prompt Light';
  &:hover {
    cursor: default;
    background-color: ${(props) => props.theme.lightHover100};
  }
`;

export const ChipItemText = styled.span`
  margin-right: 0.5rem;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    cursor: default;
  }
`;

export const OverrideStyles = {
  emptyHeader: {
    marginBottom: '1.5rem',
  },
};
