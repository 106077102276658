import * as TYPES from './type';

export const trackInteractJobUpdateStack = (payload) => ({
  type: TYPES.TRACK_EVENT_INTERACT_JOB_UPDATE_STACK_REQ,
  payload: payload,
});

export const trackInteractJobStreamStack = (payload) => ({
  type: TYPES.TRACK_EVENT_INTERACT_JOB_STREAM_STACK_REQ,
  payload: payload,
});
