import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {translate} from '../../../../res/language/translate';
import {useUserState, useUserDispatch} from '../../../../lib/hooks-logic/useUser';
import {useLocationContextDispatch} from '../../../../lib/hooks/logic/useLocation';
import {FormWrapper, FormCenter, OverrideStyles, RowBirthDay, SelectBirthDayWrapper} from '../styled-component';
import Form from '../../../../components/form';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Loading from '../../../../components/loading';
import OptionBuilder from '../../../../views/option-builder/new-version';
import SelectProvince from '../../../../views/select-location-sibling/select-province';
import SelectDistrict from '../../../../views/select-location-sibling/select-district';
import * as validate from './validate';
import * as initData from './init-data';
import * as typeDef from '../../../../utils/strong-types';
import * as logic from './logic';
import * as arrayState from '../../../../lib/context/array-state';
import * as coreRegister from '../../../../lib/context/register';
import * as snackbar from '../../../../utils/components/toast-notification/provider';

const EditGeneralInfoForm = () => {
  const {formatMessage} = useIntl();
  const {userState, isLoadingState} = useUserState();
  const {dispatchUpdateProfile} = useUserDispatch();
  const {dispatchGetDistrictByProvince} = useLocationContextDispatch();

  const [formData] = useState({
    date: coreRegister.generateDateData(),
    month: coreRegister.generateMonthData(),
    year: coreRegister.generateYearData(),
  });
  const [genderState, setGenderState] = useState();
  const [provinceState, setProvinceState] = useState();
  const [districtState, setDistrictState] = useState();
  const [maritalState, setMaritalState] = useState();
  const [disabilityState, setDisabilityState] = useState();
  const [vehicleState, setVehicleState] = useState();
  const [drivingAbilityState, setDrivingAbilityState] = useState();
  const [drivingLicenseState, setDrivingLicenseState] = useState();
  const [birthDayState, setBirthDayState] = useState({birthDate: null, birthMonth: '', birthYear: ''});

  const [formState, setFormState] = useState();
  const [errorTextState, setErrorTextState] = useState(initData.initForm);

  const onChangeError = (key) => {
    const newErrorState = {...errorTextState};
    newErrorState[key] = '';
    setErrorTextState(newErrorState);
  };

  const onChangeText = (key, value) => {
    onChangeError(key);
    const newFormState = {...formState};
    newFormState[key] = value;
    setFormState(newFormState);
  };

  const onChangeGender = (item) => {
    onChangeError('gender');
    setGenderState(item);
  };

  const onChangeMarital = (value) => {
    setMaritalState(value);
  };

  const onChangeDisability = (value) => {
    setDisabilityState(value);
  };

  const onChangeProvince = (province) => {
    onChangeError('province');
    if (!province) {
      return;
    }
    if (province.value !== 'ทั้งหมด') {
      dispatchGetDistrictByProvince(province.value);
    }
    setProvinceState([province]);
    setDistrictState([]);
  };

  const onChangeDistrict = (district) => {
    onChangeError('district');
    if (!district) {
      return;
    }
    setDistrictState([district]);
  };

  const onChangeVehicle = (value) => {
    const newJobType = arrayState.setNewAndPopExistValue(vehicleState, value);
    setVehicleState([...newJobType]);
  };

  const onChangeDriving = (value) => {
    const newJobType = arrayState.setNewAndPopExistValue(drivingAbilityState, value);
    setDrivingAbilityState([...newJobType]);
  };

  const onChangeDrivingLicense = (value) => {
    const newLicense = arrayState.setNewAndPopExistValue(drivingLicenseState, value);
    setDrivingLicenseState([...newLicense]);
  };

  const onChangeBirthDate = (key, value) => {
    const copyBirthDate = {...birthDayState};
    copyBirthDate[key] = value;
    setBirthDayState(copyBirthDate);
  };

  const onSubmit = () => {
    if (!formState || !userState) {
      return;
    }
    const [isValidate, errorList] = validate.validateForm(formState, birthDayState, genderState);
    if (!isValidate) {
      setErrorTextState({...errorTextState, ...errorList});
      snackbar.setSnackbarMessage('กรุณากรอกข้อมูลให้ถูกต้อง', 'warning');
      return;
    }
    setErrorTextState({});
    const birthday = coreRegister.transformBirthDay(
      birthDayState?.birthDate?.value,
      birthDayState?.birthMonth?.value,
      birthDayState?.birthYear?.value,
    );
    const formSubmit = {
      ...userState,
      contactable_tel: [formState.contactableTel],
      contactable_email: [formState.contactableEmail],
      general: {
        ...userState.general,
        firstname: formState.firstName,
        lastname: formState.lastName,
        birthday,
        sex: genderState?.value,
        marital_status: logic.getValueOrDefault(maritalState),
        nationality: 'ไทย',
      },
      location: {
        ...userState.location,
        address: formState.address,
        province: logic.getFirstOrDefault(provinceState),
        district: logic.getFirstOrDefault(districtState),
      },
      disability: logic.getValueOrDefault(disabilityState),
      driving_information: {
        driving_ability: logic.mapArrayValue(drivingAbilityState),
        have_vehicle: logic.mapArrayValue(vehicleState),
        driver_licenses: logic.mapArrayValue(drivingLicenseState),
      },
    };
    dispatchUpdateProfile(formSubmit);
  };

  useEffect(() => {
    if (initData.genderData && userState?.general && typeDef.isNullOrUndefined(genderState)) {
      const defaultGender = logic.mapStateToGender(initData.genderData, userState?.general?.sex);
      setGenderState(defaultGender);
    }
  }, [userState, genderState, initData.genderData]);

  useEffect(() => {
    if (userState?.general && typeDef.isNullOrUndefined(formState)) {
      const defaultForm = logic.mapStateToGeneral(userState.general, userState?.location, userState?.contactable_tel, userState?.contactable_email);
      setFormState(defaultForm);
    }
  }, [userState, formState]);

  useEffect(() => {
    if (userState?.general && typeDef.isNullOrUndefined(provinceState)) {
      const defaultProvince = logic.mapStateToProvince(userState?.location?.province);
      if (defaultProvince[0]) {
        dispatchGetDistrictByProvince(defaultProvince[0].value);
      }
      setProvinceState(defaultProvince);
    }
  }, [userState, provinceState]);

  useEffect(() => {
    if (userState?.general && typeDef.isNullOrUndefined(districtState)) {
      const defaultDistrict = logic.mapStateToDistrict(userState?.location?.district);
      setDistrictState(defaultDistrict);
    }
  }, [userState, districtState]);

  useEffect(() => {
    const isCanDefault = initData.maritalStatusData && userState?.general && typeDef.isNullOrUndefined(maritalState);
    if (isCanDefault) {
      const defaultState = logic.mapDefaultSingleValue(initData.maritalStatusData, userState?.general?.marital_status);
      setMaritalState(defaultState);
    }
  }, [userState, maritalState, initData.maritalStatusData]);

  useEffect(() => {
    const isCanDefault = initData.disabilityData && userState?.general && typeDef.isNullOrUndefined(disabilityState);
    if (isCanDefault) {
      const defaultState = logic.mapDefaultSingleValue(initData.disabilityData, userState?.disability);
      setDisabilityState(defaultState);
    }
  }, [userState, disabilityState, initData.disabilityData]);

  useEffect(() => {
    const isCanDefault = typeDef.isArray(initData.vehicleData) && userState && typeDef.isNullOrUndefined(vehicleState);
    if (isCanDefault) {
      const defaultData = logic.mapDefaultMultiValue(initData.vehicleData, userState?.driving_information?.have_vehicle);
      setVehicleState(defaultData);
    }
  }, [initData.vehicleData, userState, vehicleState]);

  useEffect(() => {
    const isCanDefault = typeDef.isArray(initData.drivingAbilityData) && userState && typeDef.isNullOrUndefined(drivingAbilityState);
    if (isCanDefault) {
      const defaultData = logic.mapDefaultMultiValue(initData.drivingAbilityData, userState?.driving_information?.driving_ability);
      setDrivingAbilityState(defaultData);
    }
  }, [initData.drivingAbilityData, userState, drivingAbilityState]);

  useEffect(() => {
    const isCanDefault = typeDef.isArray(initData.drivingLicenseData) && userState && typeDef.isNullOrUndefined(drivingLicenseState);
    if (isCanDefault) {
      const defaultData = logic.mapDefaultMultiValue(initData.drivingLicenseData, userState?.driving_information?.driver_licenses);
      setDrivingLicenseState(defaultData);
    }
  }, [initData.drivingLicenseData, userState, drivingLicenseState]);

  useEffect(() => {
    const isCanDefault = userState && typeDef.isNullOrUndefined(birthDayState.birthDate) && formData.date && formData.month && formData.year;
    if (isCanDefault) {
      const defaultData = logic.mapDefaultBirthDate(userState?.general?.birthday, formData);
      setBirthDayState(defaultData);
    }
  }, [formData, userState, birthDayState]);

  return (
    <FormCenter>
      <FormWrapper>
        {isLoadingState && !userState && <Loading type="data" loading={isLoadingState} />}
        {isLoadingState && userState && <Loading type="action" loading={isLoadingState} />}
        {userState?.general && (
          <Form formId="change-password-form" onSubmit={onSubmit}>
            <Input
              name="firstName"
              fillBackground
              type="text"
              label={translate('editProfile.general.firstName')}
              placeholder={translate('editProfile.general.firstName')}
              value={formState?.firstName || ''}
              onChange={(value) => onChangeText('firstName', value)}
              warningText={errorTextState.firstName}
            />
            <Input
              name="lastName"
              fillBackground
              type="text"
              placeholder={translate('editProfile.general.lastName')}
              value={formState?.lastName || ''}
              onChange={(value) => onChangeText('lastName', value)}
              warningText={errorTextState.lastName}
            />
            <OptionBuilder
              name="sex"
              style={OverrideStyles.option}
              label={formatMessage({defaultMessage: 'เพศ'})}
              placeholder={formatMessage({defaultMessage: 'เลือกเพศ'})}
              data={initData.genderData}
              value={genderState}
              onChange={(value) => onChangeGender(value)}
              warningText={errorTextState?.sex}
            />
            <RowBirthDay>
              <SelectBirthDayWrapper noMargin>
                <OptionBuilder
                  name="bd-date"
                  noArrow
                  style={OverrideStyles.option}
                  label={formatMessage({defaultMessage: 'วันเกิด'})}
                  placeholder={formatMessage({defaultMessage: 'วัน'})}
                  data={formData.date}
                  value={birthDayState.birthDate || ''}
                  onChange={(value) => onChangeBirthDate('birthDate', value)}
                  warningText={errorTextState?.birthDate}
                />
              </SelectBirthDayWrapper>
              <SelectBirthDayWrapper>
                <OptionBuilder
                  name="bd-month"
                  noArrow
                  style={OverrideStyles.option}
                  label={formatMessage({defaultMessage: 'เดือนเกิด'})}
                  placeholder={formatMessage({defaultMessage: 'เดือน'})}
                  data={formData.month}
                  value={birthDayState.birthMonth}
                  onChange={(value) => onChangeBirthDate('birthMonth', value)}
                  warningText={errorTextState?.birthMonth}
                />
              </SelectBirthDayWrapper>
              <SelectBirthDayWrapper>
                <OptionBuilder
                  name="bd-year"
                  noArrow
                  style={OverrideStyles.option}
                  label={formatMessage({defaultMessage: 'ปีเกิด'})}
                  placeholder={formatMessage({defaultMessage: 'ปี'})}
                  data={formData.year}
                  value={birthDayState.birthYear}
                  onChange={(value) => onChangeBirthDate('birthYear', value)}
                  warningText={errorTextState?.birthYear}
                />
              </SelectBirthDayWrapper>
            </RowBirthDay>
            <Input
              name="contactableEmail"
              fillBackground
              type="email"
              label={formatMessage({defaultMessage: 'อีเมลที่สามารถติดต่อได้'})}
              placeholder={formatMessage({defaultMessage: 'กรอกอีเมลโทรที่สามารถติดต่อได้'})}
              value={formState?.contactableEmail || ''}
              onChange={(value) => onChangeText('contactableEmail', value)}
              warningText={errorTextState.contactableEmail}
            />
            <Input
              name="contactableTel"
              fillBackground
              type="text"
              label={formatMessage({defaultMessage: 'เบอร์โทรที่สามารถติดต่อได้'})}
              placeholder={formatMessage({defaultMessage: 'กรอกเบอร์โทรที่สามารถติดต่อได้'})}
              value={formState?.contactableTel || ''}
              onChange={(value) => onChangeText('contactableTel', value)}
              warningText={errorTextState.contactableTel}
            />
            <OptionBuilder
              name="op-marital-status"
              label={formatMessage({defaultMessage: 'สถานะภาพสมรส'})}
              placeholder={formatMessage({defaultMessage: 'เลือกสถานะภาพสมรส'})}
              data={initData.maritalStatusData || []}
              value={maritalState || []}
              onChange={onChangeMarital}
            />
            <OptionBuilder
              name="op-disability"
              label={formatMessage({defaultMessage: 'ความผิดปกติทางร่างกาย'})}
              placeholder={formatMessage({defaultMessage: 'ความผิดปกติทางร่างกาย'})}
              data={initData.disabilityData || []}
              value={disabilityState || []}
              onChange={onChangeDisability}
            />
            <Input
              name="address"
              fillBackground
              type="text"
              label={translate('editProfile.general.address')}
              placeholder={translate('editProfile.general.address')}
              value={formState?.address || ''}
              onChange={(value) => onChangeText('address', value)}
              warningText={errorTextState.address}
            />
            <SelectProvince
              checkBy="value"
              label={translate('editProfile.general.province')}
              selectedProvince={provinceState || []}
              onChange={onChangeProvince}
            />
            <SelectDistrict
              disabled={!provinceState}
              label={translate('editProfile.general.district')}
              selectedDistrict={districtState || []}
              onChange={onChangeDistrict}
            />
            <OptionBuilder
              name="op-vehicle"
              multiple
              label={formatMessage({defaultMessage: 'รถส่วนตัว'})}
              placeholder={formatMessage({defaultMessage: 'เลือกรถส่วนตัว (ถ้าไม่มีไม่ต้องเลือก)'})}
              data={initData.vehicleData || []}
              value={vehicleState || []}
              onChange={onChangeVehicle}
              onChangeDel={onChangeVehicle}
            />
            <OptionBuilder
              name="op-license"
              multiple
              label={formatMessage({defaultMessage: 'ใบขับขี่'})}
              placeholder={formatMessage({defaultMessage: 'เลือกใบขับขี่ (ถ้าไม่มีไม่ต้องเลือก)'})}
              data={[...initData.drivingLicenseData]}
              value={drivingLicenseState || []}
              onChange={onChangeDrivingLicense}
              onChangeDel={onChangeDrivingLicense}
            />
            <OptionBuilder
              name="op-driving-ability"
              multiple
              label={formatMessage({defaultMessage: 'ความสามารถในการขับรถ'})}
              placeholder={formatMessage({defaultMessage: 'เลือกความสามารถในการขับรถ (ถ้าไม่มีไม่ต้องเลือก)'})}
              data={initData.drivingAbilityData || []}
              value={drivingAbilityState || []}
              onChange={onChangeDriving}
              onChangeDel={onChangeDriving}
            />
            <Button block type="submit" style={OverrideStyles.buttonSubmit}>
              {translate('editProfile.buttonSave')}
            </Button>
          </Form>
        )}
      </FormWrapper>
    </FormCenter>
  );
};

EditGeneralInfoForm.propTypes = {};

export default EditGeneralInfoForm;
