/* eslint-disable no-unused-vars */
import {createRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {TextDanger} from './styled-component';
import Modal from '../../components/modal';
import Text from '../../components/text';
import Button from '../../components/button-icon';
import Input from '../../components/input';
import Icon from '../../components/icon';
import Spinner from '../../components/spinner';
import Row from '../../components/row';
// import Link from '../../components/link';
import Header from '../../components/header';
import {useMediaQuery} from '../../utils/use-media-query';
import * as S from './styled-component';
import {renderTitle, renderBody, diffTemplate, getDefaultMailVendor} from './logic';
import Switch from '../../components/switch';
import {ROUTE_PROFILE} from '../../routes/routes-name';
// import JFFSupport from '../../res/json/JFF-contact.json';
// import ENV from '../../env.json';

const FILE_SUPPORT = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'image/jpeg',
  'image/png',
  'image/jpg',
];

const View = (props) => {
  const {
    resumeMode,
    onSetResumeMode,
    onUploadResume,
    email,
    title,
    onRequestClose,
    user,
    draftTitle,
    draftBody,
    onSubmit,
    loading,
    resumeLoading,
    saveDataLoading,
    resumeDownloadLink,
    companyName,
    // postUrl,
  } = props;
  const {formatMessage} = useIntl();
  const [showSettings, setShowSettings] = useState(false);
  const [vendor, setVendor] = useState(getDefaultMailVendor(user));
  const isMobile = useMediaQuery('(max-width: 1024px)', false);

  const history = useHistory();
  const gotoProfile = (event) => {
    event.preventDefault();
    history.push(ROUTE_PROFILE);
  };

  // template handling
  const [subject, setSubject] = useState({text: '', template: ''});
  const [body, setBody] = useState({text: '', template: ''});

  useEffect(() => {
    if (!user) return;
    setSubject(renderTitle(draftTitle, title));
    setBody(renderBody(draftBody, user, title, companyName));
  }, [draftTitle, draftBody, companyName, title, user]);

  const onSubjectChange = (text) => {
    setSubject({text, template: diffTemplate(subject.template, subject.text, text)});
  };

  const onBodyChange = (event) => {
    event.preventDefault();
    const text = event.target.value;
    setBody({text, template: diffTemplate(body.template, body.text, text)});
  };

  // upload
  const uploadElem = createRef();

  const onClickUpload = (event) => {
    event.preventDefault();
    uploadElem.current.click();
  };

  const previewResume = () => {
    const url = resumeMode === 'auto' ? user.auto_resume : user.resume;
    if (url) window.open(url, '_blank').focus();
  };

  // const getPostUrl = () => {
  //   if (!postUrl || typeof postUrl !== 'string') {
  //     return JFFSupport.FACEBOOK_OFFICIAL;
  //   }
  //   if (postUrl.includes(ENV.PUBLIC.API_URL)) {
  //     return JFFSupport.FACEBOOK_OFFICIAL;
  //   }
  //   return postUrl;
  // };

  let attachmentName;
  if (resumeMode === 'auto') attachmentName = formatMessage({defaultMessage: 'auto_resume.pdf'});
  else if (user.resume) attachmentName = formatMessage({defaultMessage: 'uploaded_resume.pdf'});
  else attachmentName = formatMessage({defaultMessage: 'เรซูเม่ยังไม่ได้อัพโหลด'});

  if (loading || !user)
    return (
      <Modal visible onRequestClose={onRequestClose} id="apply-job">
        <S.ModalContainer>
          <center>
            <Spinner />
          </center>
        </S.ModalContainer>
      </Modal>
    );

  return (
    <Modal visible onRequestClose={onRequestClose} id="apply-job">
      <S.ModalContainer>
        <S.IconContainer onClick={onRequestClose} aria-hidden="true">
          <Icon name="close-outline" size={20} color="#ccc" />
        </S.IconContainer>
        <center>
          <S.HeaderWrapper>
            <Header variant="h2">{formatMessage({defaultMessage: 'อีเมลสมัครงาน'})}</Header>
          </S.HeaderWrapper>
        </center>
        <Input
          value={email.join(', ')}
          iconName="email-fill"
          disabled
          borderOutline
          fillBackground
          readOnly
          containerStyle={isMobile ? {paddingBottom: '.5rem'} : null}
        />
        <Input
          placeholder="Subject"
          iconName="title"
          value={subject.text}
          onChange={onSubjectChange}
          borderOutline
          fillBackground
          containerStyle={isMobile ? {paddingBottom: '.5rem'} : null}
        />
        <S.TextArea value={body.text} onChange={onBodyChange} showSettings={showSettings} isMobile={isMobile} />
        <S.AttachmentContainer onClick={previewResume} aria-hidden="true" resumeMode={resumeMode} resume={user.resume}>
          <Input iconName="attachment" readOnly disabled value={attachmentName} borderOutline fillBackground inputStyle={{cursor: 'pointer'}} />
        </S.AttachmentContainer>
        {!resumeLoading && resumeMode !== 'auto' && (
          <a href="dummy" onClick={onClickUpload} aria-hidden="true" style={{marginLeft: '1rem', position: 'relative', top: -5}}>
            {formatMessage({defaultMessage: 'อัพโหลดไฟล์'})}
          </a>
        )}
        {user.auto_resume && (
          <S.SwitchContainer>
            <Text style={S.switchLabelStyle}>{formatMessage({defaultMessage: 'ใช้เรซูเม่อัตโนมัติ'})}</Text>
            <Switch isToggled={resumeMode === 'auto'} onChangeToggled={onSetResumeMode} disabled={resumeLoading} />
          </S.SwitchContainer>
        )}
        {!user.auto_resume && (
          <S.SwitchContainer>
            <Text style={S.switchLabelStyle}>{formatMessage({defaultMessage: 'หากคุณต้องการสร้างเรซูเม่อัตโนมัติ สามารถไปสร้างได้'})}</Text>
            <a href="dummy" onClick={gotoProfile} aria-hidden="true" style={S.switchLabelStyle2}>
              {formatMessage({defaultMessage: 'ที่นี่'})}
            </a>
          </S.SwitchContainer>
        )}
        {resumeLoading && (
          <>
            <S.InlineBlock>
              <Spinner />
            </S.InlineBlock>
            <S.InlineBlock>{formatMessage({defaultMessage: 'กำลังอัพโหลด'})}</S.InlineBlock>
          </>
        )}
        <input style={{display: 'none'}} accept={FILE_SUPPORT.join(',')} type="file" ref={uploadElem} onChange={onUploadResume} />
        {/* {showSettings && (
          <S.SettingsContainer>
            <center>
              <label htmlFor="123">
                {formatMessage({defaultMessage: 'ช่องทางการส่งอีเมล'})}&nbsp;
                <select id="123" style={S.selectStyle} value={vendor} onChange={(event) => setVendor(event.target.value)}>
                  <option value="gmail">Gmail</option>
                  <option value="mailto">{formatMessage({defaultMessage: 'โปรแกรมส่งเมลในเครื่อง (เช่น Outlook)'})}</option>
                </select>
              </label>
            </center>
          </S.SettingsContainer>
        )} */}
        {/* <Row content="center" style={S.cannotApplyStyle}>
          <Text>{formatMessage({defaultMessage: 'สมัครงานไม่ได้'})}</Text>
          <Link newTab url={getPostUrl()}>
            <S.TextLink>{formatMessage({defaultMessage: 'กดที่นี่'})}</S.TextLink>
          </Link>
        </Row> */}
        <Row content="center" style={{width: '100%'}}>
          <Button iconName="send-email" iconSize={20} onClick={() => !saveDataLoading && resumeDownloadLink && onSubmit(subject, body, vendor)}>
            {formatMessage({defaultMessage: 'สมัครงาน'})}
          </Button>

          {/* {!isMobile && (
            <Button variant="lighter" iconName="menu" onClick={() => setShowSettings(!showSettings)}>
              {showSettings ? formatMessage({defaultMessage: 'ซ่อนเมนู'}) : formatMessage({defaultMessage: 'เมนูตั้งค่า'})}
            </Button>
          )} */}
        </Row>
        <center>
          {attachmentName === 'เรซูเม่ยังไม่ได้อัพโหลด' && (
            <TextDanger>{formatMessage({defaultMessage: 'กรุณาอัพโหลดไฟล์ ก่อนสมัครงาน'})}</TextDanger>
          )}
        </center>
      </S.ModalContainer>
    </Modal>
  );
};

View.propTypes = {
  email: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  draftTitle: PropTypes.string.isRequired,
  draftBody: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  resumeLoading: PropTypes.bool.isRequired,
  saveDataLoading: PropTypes.bool.isRequired,
  onUploadResume: PropTypes.func.isRequired,
  resumeMode: PropTypes.string.isRequired,
  onSetResumeMode: PropTypes.func.isRequired,
  resumeDownloadLink: PropTypes.string,
  companyName: PropTypes.string,
  // postUrl: PropTypes.string.isRequired,
};

View.defaultProps = {
  resumeDownloadLink: null,
  companyName: null,
};

export default View;
