import React from 'react';
import {FULL_FILTER_BREAKPOINT} from './styled-component';
import Responsive from '../../components/responsive';
import ModalFilter from '../filter/modal-filter';

const Filter = () => {
  return (
    <Responsive maxWidth={FULL_FILTER_BREAKPOINT - 1}>
      <ModalFilter />
    </Responsive>
  );
};

Filter.propTypes = {};

export default Filter;
