const tabFloat = '- ';
const newLineTabFloat = '\n- ';

const present = [
  {
    title: 'เรามีประกาศรับสมัครงานมากที่สุด กว่า 100,000 ใบ',
    description:
      `${tabFloat}เรารวบรวมงานจากทุกกลุ่มเฟซ งานราชการ งานจากหลากหลายบริษัทดัง ดูเว็บเดียวจบ` +
      `${newLineTabFloat}ครบทุกจังหวัด ปรับแต่งแสดงเฉพาะที่คุณน่าจะสนใจ ไม่ลายตา` +
      `${newLineTabFloat}ครบทุกกลุ่มงาน ทุกสายอาชีพ เช่น กลุ่มงานใช้ภาษาจีน กลุ่มงานในห้าง กลุ่มงานนิคม จัดหมวดหมู่ไว้ตอบโจทย์ทุกความต้องการ`,
  },
  {
    title: 'สารพัดความสะดวกในการสมัครงาน',
    description:
      `${tabFloat}สมัครงานได้ในคลิกเดียว เพื่อให้คุณสามารถหว่านใบสมัครได้มากขึ้น` +
      `${newLineTabFloat}ติดต่อผู้จ้างงานจากในเว็บได้เลย เมื่อไหร่อ่าน resume เมื่อไหร่ตอบรู้ได้ทันที` +
      `${newLineTabFloat}สามารถ like งานเก็บไว้ก่อนได้ เลือกๆไว้แล้วค่อยมาตัดสินใจทีหลัง` +
      `${newLineTabFloat}สร้าง resume ให้ใช้งานได้อัตโนมัติ หากคุณไม่ได้เตรียมมา`,
  },
  {
    title: 'ไม่พลาดโอกาสสำคัญ ด้วยการแจ้งเตือนอัตโนมัติ',
    description:
      `${tabFloat}บางครั้งงานที่คุณต้องการอาจยังไม่เปิดรับสมัคร หรือคุณยังไม่ออกจากงานเก่าแต่ต้องการแสวงหาโอกาสใหม่ๆให้ตนเอง` +
      `${newLineTabFloat}คุณสามารถบันทึกลักษณะงานที่ต้องการเอาไว้ได้ เราจะแจ้งเตือนคุณผ่านทางช่องทาง Email / Website` +
      `${newLineTabFloat}ทุกขั้นตอนฟรี หากงานไหนถูกใจก็สมัครได้เลย ไม่ยุ่งยาก ไม่เสียค่าใช้จ่าย ไม่มีข้อผูกมัด`,
  },
];

export default present;
