import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  z-index: 9;
  padding: 1.25rem 0.75rem;
`;

export const Wrapper = styled.div``;

export const OverrideStyles = {
  baseColor: {
    height: '6px',
    width: '100%',
    backgroundColor: lightTheme.tinGray,
    alignSelf: 'center',
  },
  colorStyles: {
    active: [lightTheme.tinGray, lightTheme.primary, lightTheme.tinGray],
    deActive: [lightTheme.tinGray, lightTheme.gray, lightTheme.tinGray],
  },
  thumbStyles: {
    active: {
      height: '24px',
      width: '24px',
      borderRadius: '12px',
      backgroundColor: lightTheme.primary,
    },
    deActive: {
      height: '24px',
      width: '24px',
      borderRadius: '12px',
      backgroundColor: lightTheme.gray,
    },
  },
  outputStyle: {
    zIndex: 0,
    marginTop: 10,
  },
};
