import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1040;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.modalBackdrop};
  visibility: hidden;
  ${(props) => props.isVisible && `visibility: visible`};
`;

export const ContentWrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 400px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.white};
  transition: opacity 250ms;
  visibility: hidden;
  opacity: 0;
  @media (max-width: 420px) {
    max-width: 80%;
    margin: 2rem;
  }
  ${(props) => props.isVisible && `opacity: 1;   visibility: visible;`};
`;

export const Title = styled.p`
  font-weight: bold;
  text-align: center;
  padding: 1.5rem;
  @media (max-width: 420px) {
    padding: 1rem;
  }
  white-space: pre-line;
  ${(props) => props.left && `text-align: left;`};
`;

export const RowAction = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.tinGray};
`;

export const Button = styled.button`
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Prompt Light';
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => (props.danger ? props.theme.danger : props.theme.primary)};
  border-bottom-right-radius: ${(props) => (!props.danger ? '6px' : '0px')};
  border-bottom-left-radius: ${(props) => (props.danger ? '6px' : '0px')};
  ${(props) => props.singleButton && `border-radius: 0 0 6px 6px`};
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
