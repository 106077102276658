import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  @media (min-width: 600px) {
    height: 100%;
    max-height: calc(90vh);
    width: 580px;
    border-radius: 8px;
  }
  @media (max-width: 599px) {
    width: calc(100vw);
    height: calc(100vh);
  }
`;

export const Title = styled.div`
  padding: 1rem 1rem;
`;

export const Body = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: ${(props) => `${props.height}px`};
  padding: 0 1.5rem;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  padding: 0 1rem;
  border-top: 1px solid ${(props) => props.theme.grayBackground};
`;

export const TopicWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  padding-top: 1rem;
  ${(props) => props.bottom && `padding-bottom: ${props.bottom}`};
  ${(props) => props.mini && `padding-top: 10px;`};
`;

export const ReasonTextArea = styled.textarea`
  margin-top: 0.75rem;
  width: 100%;
  height: 120px;
  resize: none;
  padding: 0.5rem;
`;

export const ErrorTextWrapper = styled.div`
  ${(props) => props.top && `margin-top: ${props.top}`};
`;

export const ErrorText = styled.span`
  line-height: 12px;
  font-size: 12px;
  color: ${(props) => props.theme.danger};
  ${(props) => props.noTop && `margin-top: -2rem`};
`;

export const BottomHiddenDiv = styled.div``;
