import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useCurrentScrollYEffect} from '../../../../lib/hooks/useScrollingEffect';
import {Wrapper, Container, ContainerHidden} from './styled-component';
import Responsive from '../../../../components/responsive';
import FilterOptions from '../../../../views/filter';
import ModalFilter from '../../../../views/filter/modal-filter';
import * as LAYOUT from '../layout';

const NAV_GAB = 60;

const Filter = (props) => {
  const {isNotEmpty} = props;
  const nodeFilterRef = useRef(null);

  const {isBreakpointYState} = useCurrentScrollYEffect(nodeFilterRef.current?.offsetTop ? nodeFilterRef.current.offsetTop - NAV_GAB : 0);
  const isFixedMounted = nodeFilterRef.current?.offsetTop && isBreakpointYState;

  return (
    <>
      <Responsive maxWidth={LAYOUT.FULL_FILTER_BREAKPOINT - 1}>
        <ModalFilter />
      </Responsive>
      <Wrapper ref={nodeFilterRef}>
        <Container fixed={isFixedMounted && isNotEmpty}>
          <FilterOptions isLanding />
        </Container>
        {isFixedMounted && <ContainerHidden />}
      </Wrapper>
    </>
  );
};

Filter.propTypes = {
  isNotEmpty: PropTypes.bool.isRequired,
};

export default Filter;
