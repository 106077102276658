import React from 'react';
import {translate} from '../../../res/language/translate';
import {NavWrapper, NavItemRightWrapper, NavText, OverrideStyles} from './styled-component';
import Text from '../../../components/text';
import Link from '../../../components/link';
import AppLogo from '../../../views/logo/logo';
import * as ROUTES from '../../../routes/routes-name';
import * as mixpanel from '../../../utils/mixpanel';

const NavBar = () => {
  return (
    <NavWrapper>
      <Link url={ROUTES.ROUTE_MAIN}>
        <AppLogo />
      </Link>
      <NavItemRightWrapper>
        <NavText>{translate('login.textNoAccount')}</NavText>
        <Link url={ROUTES.ROUTE_REGISTER} onClick={() => mixpanel.registerClickNavbarLogin()}>
          <Text style={OverrideStyles.registerLink}>{translate('login.buttonRegister')}</Text>
        </Link>
      </NavItemRightWrapper>
    </NavWrapper>
  );
};

NavBar.propTypes = {};

export default NavBar;
