import styled from 'styled-components';
import {lightTheme} from '../../../../styles/themes';

export const ListItemWrapper = styled.div`
  width: 100%;
`;

export const OverrideStyles = {
  title: {
    color: lightTheme.textBlack,
    fontWeight: 'bold',
  },
  rowRightComponent: {
    width: 100,
    justifyContent: 'flex-end',
  },
  textRight: {
    paddingLeft: '0.5rem',
    color: lightTheme.primary,
  },
  textRightHover: {
    textDecoration: 'underline',
  },
  iconColor: lightTheme.blueFade,
  rowHeader: {
    paddingTop: '0',
  },
  header: {
    paddingLeft: '0.5rem',
  },
};
