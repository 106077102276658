import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: relative;
  background: white;
  overflow: auto;
  border-radius: 6px;
  max-height: calc(100vh);
  @media (max-width: 680px) {
    margin: 0;
    padding: 1rem;
  }
  @media (min-width: 681px) {
    width: 600px;
    padding: 1.5rem;
  }
  @media (min-width: 1025px) {
    width: 1000px;
    padding: 1.5rem 3rem;
  }
`;

export const H2 = styled.h2``;

export const HeaderWrapper = styled.div`
  margin-bottom: 1rem;
  @media (max-width: 680px) {
    margin-bottom: 0.5rem;
  }
`;

export const InlineBlock = styled.span`
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 0.5rem;
  color: #8d949e;
`;

export const IconContainer = styled.div.attrs((props) => props)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  @media (max-width: 1024px) {
    right: 25px;
    top: 10px;
  }
`;

export const AttachmentContainer = styled.div.attrs((props) => props)`
  display: inline-block;
  cursor: pointer;
  ${(props) => {
    if (props.resumeMode === 'auto') return 'width: 190px;';
    if (props.resume) return 'width: 230px;';
    return 'width: 210px;';
  }}
`;

export const TextArea = styled.textarea.attrs((props) => props)`
  width: 100%;
  ${(props) => (props.showSettings ? 'height: 240px;' : 'height: 300px;')};
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  @media (max-width: 1024px) {
    margin-bottom: 0.5rem;
  }
`;

export const SettingsContainer = styled.div`
  height: 50px;
  margin-bottom: 10px;
  background: #f5f5f5;
  padding: 0.7rem;
  border: 1px solid #aaa;
  border-radius: 5px;
`;

export const SwitchContainer = styled.div`
  @media (min-width: 1025px) {
    float: right;
    padding-top: 12px;
  }
  @media (max-width: 1024px) {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    > div {
      float: right;
    }
  }
`;

export const TextLink = styled.span`
  padding-left: 0.25rem;
  color: ${(props) => props.theme.primary};
  text-decoration: underline;
`;

export const TextDanger = styled.span`
  color: ${(props) => props.theme.danger};
`;

export const switchLabelStyle = {
  display: 'inline',
  marginRight: 20,
  verticalAlign: 'top',
  top: 3,
  position: 'relative',
};

export const switchLabelStyle2 = {
  ...switchLabelStyle,
  marginLeft: -20,
};

export const selectStyle = {
  width: 200,
};

export const cannotApplyStyle = {
  paddingBottom: '0.2rem',
};
