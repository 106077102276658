import {createServiceIPStack} from '../../env/utils/env-ip-stack';
import {createServiceURL} from '../../env/utils/env-services';
import {adapterGet} from '../../utils/adapter/get';
import * as netInfo from '../../utils/network-info';

const getCoords = async () => {
  const options = {
    enableHighAccuracy: true,
    timeout: 8000,
    maximumAge: 10000,
  };
  const pos = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });

  return {
    longitude: pos.coords.longitude,
    latitude: pos.coords.latitude,
  };
};

export const contextLocation = async () => {
  const ipStack = createServiceIPStack();
  let response = {};
  try {
    if (navigator.geolocation) {
      try {
        response.data = await getCoords();
      } catch (error) {
        const ipAddress = await netInfo.getIpAddress();
        const API_URL = `${ipStack.HOST_URL}${ipStack.SECRET_KEY}/${ipAddress}`;
        response = await adapterGet({url: API_URL, privateMethod: false});
      }
    } else {
      const ipAddress = await netInfo.getIpAddress();
      const API_URL = `${ipStack.HOST_URL}${ipStack.SECRET_KEY}/${ipAddress}`;
      response = await adapterGet({url: API_URL, privateMethod: false});
    }
  } catch (error) {
    response.data = {
      latitude: '13.7563',
      longitude: '100.5018',
    };
  }
  return response;
};

export const contextProvinces = async () => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/location/provinces`;
  const response = await adapterGet({url: API_URL, privateMethod: false});
  return response;
};

export const contextDistricts = async (reqBody) => {
  const HOST_URL = createServiceURL();
  const API_URL = `${HOST_URL}/app/location/districts`;
  const response = await adapterGet({url: API_URL, body: reqBody, privateMethod: false});
  return response;
};
