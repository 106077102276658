import React, {useState, useEffect} from 'react';
import {translate} from '../../../../res/language/translate';
import {useNotifyDispatch, useNotifyState} from '../../../../lib/hooks/logic/useNotify';
import Select from '../../../../components/select';
import * as ICONS from '../../../../components/icon/constants';
import * as coreJobCat from '../../../../lib/context/job-cat';
import * as logic from './logic';
import * as typeDef from '../../../../utils/strong-types';

const CategoryForm = () => {
  const [jobCatsData] = useState(coreJobCat.createJobCatData());
  const {interestedJobState} = useNotifyState();
  const {dispatchUpdateInterestJobForm} = useNotifyDispatch();
  const [selectedJobCats, setSelectedJobCats] = useState();

  useEffect(() => {
    const isCanDefault = interestedJobState && typeDef.isArrayLength(jobCatsData) && typeDef.isNullOrUndefined(selectedJobCats);
    if (isCanDefault) {
      const {jobcat, subjobcat} = interestedJobState;
      const defaultData = logic.mapDefaultJobCat(jobCatsData, jobcat, subjobcat);
      setSelectedJobCats([...defaultData]);
    }
  }, [interestedJobState, jobCatsData, selectedJobCats]);

  const shapeTextJobCatFunc = (text, item) => {
    if (item.parentId) {
      return text;
    }
    const shapeText = coreJobCat.shapeTextParentCategory({text});
    return shapeText;
  };

  const onClickedChip = (id) => {
    const newItems = coreJobCat.filterOutById({id, selectedOptions: selectedJobCats || []});
    dispatchUpdateInterestJobForm(logic.updateJobCatAndSubJobCat(newItems, interestedJobState));
    setSelectedJobCats([...newItems]);
  };

  const onChangeSelectJobCategory = (parentItem, childItem) => {
    const mapSelected = coreJobCat.mapSelectedByTypes({
      selectedOptions: selectedJobCats || [],
      newItems: {
        parentItem,
        childItem,
      },
    });
    if (mapSelected.length > coreJobCat.LIMIT_JOB_CAT) {
      return;
    }

    dispatchUpdateInterestJobForm(logic.updateJobCatAndSubJobCat(mapSelected, interestedJobState));
    setSelectedJobCats(mapSelected);
  };

  const onEventClearJobCat = () => {
    const copyForm = {...interestedJobState};
    copyForm.jobcat = [];
    setSelectedJobCats([]);
    dispatchUpdateInterestJobForm(copyForm);
  };

  return (
    <Select
      name="jobCat"
      outline
      iconLabel={ICONS.BAG_BUSINESS}
      label={translate('landing.search.jobCategory')}
      placeholder={translate('landing.search.select')}
      limitSelect={10}
      options={jobCatsData}
      selectedOptions={selectedJobCats || []}
      shapeTextFunc={shapeTextJobCatFunc}
      onClickChip={onClickedChip}
      onChange={onChangeSelectJobCategory}
      onClear={() => onEventClearJobCat()}
    />
  );
};

CategoryForm.propTypes = {};

export default CategoryForm;
