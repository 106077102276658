import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useFilterModal} from '../../../../lib/hooks/logic/useFilter';
import {NavbarFilterWrapper, NavFilterLabel, GroupMenu, OverrideStyles} from './styled-component';
import Icon from '../../../../components/icon';
import Responsive from '../../../../components/responsive';
import Link from '../../../../components/link';
import * as ICONS from '../../../../components/icon/constants';
import * as ROUTES from '../../../../routes/routes-name';

const MIN_FULL_MENU = 460;

const NavbarFilter = (props) => {
  const {isShow} = props;

  const {formatMessage} = useIntl();
  const {dispatchSetVisibleFilter} = useFilterModal();

  return (
    <NavbarFilterWrapper isShow={isShow}>
      <GroupMenu onClick={() => dispatchSetVisibleFilter(true)}>
        <Responsive maxWidth={MIN_FULL_MENU - 1}>
          <NavFilterLabel>{formatMessage({defaultMessage: 'กรองงาน'})}</NavFilterLabel>
        </Responsive>
        <Responsive minWidth={MIN_FULL_MENU}>
          <Icon name={ICONS.FILTER} color={OverrideStyles.iconFilter} size={18} />
          <NavFilterLabel>{formatMessage({defaultMessage: 'กรองงาน'})}</NavFilterLabel>
        </Responsive>
      </GroupMenu>
      <Responsive maxWidth={549}>
        <Link url={ROUTES.ROUTE_GROUP_LIST}>
          <GroupMenu onClick={() => dispatchSetVisibleFilter(true)}>
            <Responsive minWidth={MIN_FULL_MENU}>
              <Icon name={ICONS.GROUP} color={OverrideStyles.iconFilter} size={18} />
            </Responsive>
            <NavFilterLabel>{formatMessage({defaultMessage: 'รวมกลุ่ม'})}</NavFilterLabel>
          </GroupMenu>
        </Link>
        <Link url={ROUTES.ROUTE_SEARCH}>
          <GroupMenu search>
            <Responsive maxWidth={MIN_FULL_MENU - 1}>
              <Icon name={ICONS.SEARCH} color={OverrideStyles.iconFilter} size={23.5} />
            </Responsive>
            <Responsive minWidth={MIN_FULL_MENU}>
              <Icon name={ICONS.SEARCH} color={OverrideStyles.iconFilter} size={18} />
              <NavFilterLabel>{formatMessage({defaultMessage: 'หางานอื่น'})}</NavFilterLabel>
            </Responsive>
          </GroupMenu>
        </Link>
      </Responsive>
    </NavbarFilterWrapper>
  );
};

NavbarFilter.propTypes = {
  isShow: PropTypes.bool,
};

NavbarFilter.defaultProps = {
  isShow: true,
};

export default NavbarFilter;
