import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import SectionHeader from '../header';
import {calWorkInfo} from '../resume-info/logic';
import * as ICONS from '../../../../components/icon/constants';
import * as ROUTES from '../../../../routes/routes-name';

const ExperienceInfo = (props) => {
  const {userState} = props;
  const {formatMessage} = useIntl();

  if (!userState) {
    return null;
  }

  return (
    <>
      <>
        <SectionHeader
          route={ROUTES.ROUTE_EDIT_PROFILE_WORKS}
          iconName={ICONS.BAG_BUSINESS}
          title={formatMessage({defaultMessage: 'ประวัติการทำงาน'})}
          score={calWorkInfo(userState)}
          total={20}
        />
      </>
    </>
  );
};

ExperienceInfo.propTypes = {
  userState: PropTypes.object,
};

ExperienceInfo.defaultProps = {
  userState: null,
};

export default ExperienceInfo;
