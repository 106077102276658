import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {translate} from '../../../res/language/translate';
import {useCallbackPathDispatch} from '../../../lib/hooks-logic/useCallbackPath';
import {getAuthToken} from '../../../utils/storage/storage';
import {JobTitle, JobSalary, JobHashTag, JobTextHeader, ButtonSeeMore, RowHeader, OverrideStyles} from './styled-component';
import {getIconName, setStackHeader} from '../../job-post-builder/utils/header-regex';
import Row from '../../../components/row';
import Link from '../../../components/link';
import Button from '../../../components/button';
import Icon from '../../../components/icon';
import BodyRewrite from './body-rewrite';
import CreditView from './credit';
import * as bodyParser from '../utils/body-parser';
import * as ROUTES from '../../../routes/routes-name';
import * as stringFn from '../../../utils/string-func';
import * as bodyCleanup from '../utils/body-cleanup';
import * as mixpanel from '../../../utils/mixpanel';
import * as navigation from '../../../utils/navigation/navigation-services';

const PostBody = (props) => {
  const {isMeta, jobTitle, bodyText, postSections, hashtags, salary, isSalaryHidden, jobId, source, postUrl, location} = props;
  const headerRef = useRef({});
  const nodeTitleRef = useRef();
  const {formatMessage} = useIntl();
  const [isShow, setIsShow] = useState(false);
  const [prettyChildren, setPrettyChildren] = useState(null);
  const [lessChildren, setLessChildren] = useState(null);
  const isLoggedIn = getAuthToken();
  const {dispatchAddCallbackPath} = useCallbackPathDispatch();

  useEffect(() => {
    if (bodyText && !prettyChildren && !lessChildren) {
      const prettyText = bodyParser.createPrettyText({rawText: bodyText, source, location, postSections});
      const lessBody = bodyCleanup.cleanupLessBody(prettyText);
      setLessChildren([...lessBody]);
      setPrettyChildren([...prettyText]);
    }
  }, [source, bodyText, prettyChildren, postSections, lessChildren]);

  const onClickSeeLess = () => {
    if (isShow && nodeTitleRef.current) {
      nodeTitleRef.current.scrollIntoView({behavior: 'smooth'});
    }
    setIsShow(!isShow);
    headerRef.current = {};
  };

  const handleAnnouncement = (text) => {
    if (typeof text !== 'string') return null;
    const textUrl = text.split('\n').filter((val) => /ดูรายละเอียดเพิ่มเติม คลิก/.test(val));
    return textUrl[0].split('\n').pop();
  };

  const onClickRegister = () => {
    mixpanel.registerClickSeeMore(window.location.pathname);
    const path = {
      pathname: ROUTES.ROUTE_SEARCH_BY_ID,
      search: {id: jobId},
    };
    dispatchAddCallbackPath(path);
    navigation.navigateTo({pathname: ROUTES.ROUTE_REGISTER, search: {rd_path: true}});
  };

  if (!prettyChildren || !lessChildren) return null;

  if (!isShow && !isMeta) {
    return (
      <>
        <JobTitle ref={nodeTitleRef}>{`เปิดรับสมัคร : ${bodyCleanup.cleanUpHeader(jobTitle)}`}</JobTitle>
        {salary && !isSalaryHidden && <JobSalary>{`เงินเดือน : ${salary}`}</JobSalary>}
        <Row style={OverrideStyles.rowHashtag}>
          {Array.isArray(hashtags) &&
            hashtags.map((item, index) => (
              <Link key={index.toString()} url={ROUTES.ROUTE_SEARCH_HASHTAG} search={`${stringFn.searchStringify({tag: item})}`}>
                <JobHashTag>{item}</JobHashTag>
              </Link>
            ))}
        </Row>
        {Array.isArray(lessChildren) &&
          lessChildren.map((text, index) => {
            const isHeader = bodyParser.isHeaderTag({text});
            const renderText = isHeader ? bodyParser.replaceHeaderTag({text}) : text;
            const headerStack = isHeader ? setStackHeader({header: renderText}) : null;
            if (bodyParser.checkBackListBodyLine({text: renderText})) {
              return null;
            }

            if (headerStack) {
              if (!headerRef.current[headerStack]) {
                headerRef.current[headerStack] = headerStack;
              }
            }

            return (
              <RowHeader key={index.toString()} isHeader={isHeader && index > 0}>
                {isHeader && (
                  <Icon name={getIconName({header: bodyParser.replaceHeaderTag({text})})} color={OverrideStyles.iconBody.color} size={16} />
                )}
                {isHeader && <JobTextHeader>{renderText}</JobTextHeader>}
                {!isHeader && (
                  <BodyRewrite source={source} head={lessChildren[index - 1]} count={lessChildren.length}>
                    {renderText}
                  </BodyRewrite>
                )}
              </RowHeader>
            );
          })}
        <ButtonSeeMore onClick={onClickSeeLess}>{translate('timeline.seeMore')}</ButtonSeeMore>
      </>
    );
  }

  return (
    <>
      <JobTitle>{`เปิดรับสมัคร : ${bodyCleanup.cleanUpHeader(jobTitle)}`}</JobTitle>
      {salary && !isSalaryHidden && <JobSalary>{`เงินเดือน : ${salary}`}</JobSalary>}
      <Row style={OverrideStyles.rowHashtag}>
        {Array.isArray(hashtags) &&
          hashtags.map((item, index) => (
            <Link key={index.toString()} url={ROUTES.ROUTE_SEARCH_HASHTAG} search={`${stringFn.searchStringify({tag: item})}`}>
              <JobHashTag>{item}</JobHashTag>
            </Link>
          ))}
      </Row>
      {Array.isArray(prettyChildren) &&
        prettyChildren.map((text, index) => {
          const isHeader = bodyParser.isHeaderTag({text});
          const renderText = isHeader ? bodyParser.replaceHeaderTag({text}) : text;
          const headerStack = isHeader ? setStackHeader({header: renderText}) : null;
          if (bodyParser.checkBackListBodyLine({text: renderText})) {
            return null;
          }

          if (headerStack) {
            if (!headerRef.current[headerStack]) {
              headerRef.current[headerStack] = headerStack;
            }
          }

          return (
            <RowHeader key={index.toString()} isHeader={isHeader && index > 0}>
              {isHeader && <Icon name={getIconName({header: bodyParser.replaceHeaderTag({text})})} color={OverrideStyles.iconBody.color} size={16} />}
              {isHeader && <JobTextHeader>{renderText}</JobTextHeader>}
              {!isHeader && (
                <BodyRewrite source={source} head={prettyChildren[index - 1]} count={prettyChildren.length}>
                  {renderText}
                </BodyRewrite>
              )}
            </RowHeader>
          );
        })}
      {source !== 'company' && source !== 'findgovjob' && <CreditView postUrl={postUrl} />}
      {source === 'findgovjob' && <CreditView textAnnouncement={handleAnnouncement(bodyText)} />}
      {!isLoggedIn && !isMeta && (
        <Row content="center" style={OverrideStyles.rowRegister}>
          <Button onClick={onClickRegister}>{formatMessage({defaultMessage: 'สมัครงานนี้'})}</Button>
        </Row>
      )}
      {!isMeta && <ButtonSeeMore onClick={onClickSeeLess}>{translate('timeline.seeLess')}</ButtonSeeMore>}
    </>
  );
};

PostBody.propTypes = {
  jobId: PropTypes.string.isRequired,
  isMeta: PropTypes.bool,
  jobTitle: PropTypes.string,
  bodyText: PropTypes.string,
  hashtags: PropTypes.array,
  salary: PropTypes.string,
  isSalaryHidden: PropTypes.bool,
  source: PropTypes.string.isRequired,
  postUrl: PropTypes.string,
  location: PropTypes.array,
  postSections: PropTypes.array,
};

PostBody.defaultProps = {
  isMeta: false,
  jobTitle: null,
  bodyText: null,
  hashtags: null,
  salary: null,
  isSalaryHidden: false,
  postUrl: null,
  location: null,
  postSections: null,
};

export default PostBody;
