import * as TYPES from './type';
import * as logic from './logic';

const initialState = {
  isCheckedAuth: false,
  loading: false,
  user: null,
  error: null,
  errorStatus: null,
};

const initialForgotPasswordState = {
  visibleStep: 1,
  loading: false,
};

const initialFacebookLoginState = {
  loading: false,
  user: null,
  error: null,
};

export const authReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case TYPES.INVALID_PAYLOAD:
      return {
        ...state,
        loading: true,
        error: payload,
      };
    case TYPES.AUTH_CURRENT_REQ:
      return {
        ...state,
        isCheckedAuth: false,
        loading: true,
        error: null,
        errorStatus: null,
        user: null,
      };
    case TYPES.AUTH_CURRENT_SUCCESS:
      return {...state, loading: false, isCheckedAuth: true, user: payload};
    case TYPES.AUTH_CURRENT_FAIL:
      return {
        ...state,
        isCheckedAuth: true,
        loading: false,
        error: payload?.message,
        errorStatus: payload?.status,
      };
    case TYPES.AUTH_IS_CHECKED_SUCCESS:
      return {...state, isCheckedAuth: true, loading: false};
    case TYPES.AUTH_REGISTER_FACEBOOK_REQ:
      return state;
    case TYPES.AUTH_REGISTER_MANUAL_REQ:
      return state;
    case TYPES.AUTH_LOGIN_MANUAL_REQ:
      return {
        ...initialState,
        loading: true,
        isCheckedAuth: false,
      };
    case TYPES.AUTH_REGISTER_SUCCESS:
      return state;
    case TYPES.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload.data,
        isCheckedAuth: true,
      };
    case TYPES.AUTH_REGISTER_FAIL:
      return state;
    case TYPES.AUTH_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: payload?.message,
        errorStatus: payload?.status,
      };
    case TYPES.AUTH_LOGIN_FROM_REGISTER_REQ:
      return {
        ...state,
        loading: true,
        isCheckedAuth: false,
      };
    case TYPES.AUTH_LOGIN_FROM_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
        isCheckedAuth: true,
      };
    case TYPES.AUTH_LOGIN_FROM_REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case TYPES.AUTH_LOGOUT_REQ:
      return {...state, loading: true, errorStatus: null};
    case TYPES.AUTH_LOGOUT_SUCCESS:
      return {...state, user: null, loading: false};
    case TYPES.AUTH_LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload?.message,
        errorStatus: payload?.status,
      };
    case TYPES.AUTH_AUTHORIZE_REQ:
      return state;
    case TYPES.AUTH_AUTHORIZE_DENIED:
      return {
        ...state,
        error: payload?.message,
        errorStatus: payload?.status,
      };
    case TYPES.AUTH_OTP_CANCEL_INVOKE_REQ:
      return state;
    case TYPES.AUTH_OTP_CANCEL_INVOKE_SUCCESS:
      return state;
    case TYPES.AUTH_OTP_CANCEL_INVOKE_FAIL:
      return state;
    case TYPES.AUTH_UPDATE_CURRENT_REQ:
      return state;
    case TYPES.AUTH_UPDATE_CURRENT_SUCCESS:
      return {
        ...state,
        user: {
          ...(state.user && state.user),
          terms_and_conditions: logic.updateTermsStatus(state.user?.terms_and_conditions, payload),
        },
      };
    case TYPES.FIREBASE_ANONYMOUS_LOGIN_REQ:
    case TYPES.FIREBASE_ANONYMOUS_LOGIN_SUCCESS:
    case TYPES.FIREBASE_ANONYMOUS_LOGIN_FAIL:
    case TYPES.FIREBASE_ANONYMOUS_LOGOUT_REQ:
    case TYPES.FIREBASE_ANONYMOUS_LOGOUT_SUCCESS:
    case TYPES.FIREBASE_ANONYMOUS_LOGOUT_FAIL:
    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = initialForgotPasswordState, {type, payload}) => {
  switch (type) {
    case TYPES.AUTH_FORGOT_PASSWORD_REQUEST_CODE_REQ:
      return {
        ...state,
        loading: true,
        requestCode: undefined,
        phone: undefined,
        error: undefined,
      };
    case TYPES.AUTH_FORGOT_PASSWORD_REQUEST_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        requestCode: true,
        phone: payload,
        visibleStep: 2,
      };
    case TYPES.AUTH_FORGOT_PASSWORD_REQUEST_CODE_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.AUTH_FORGOT_PASSWORD_CHECK_CODE_REQ:
      return {...state, loading: true, error: undefined};
    case TYPES.AUTH_FORGOT_PASSWORD_CHECK_CODE_SUCCESS:
      return {...state, user: payload};
    case TYPES.AUTH_FORGOT_PASSWORD_CHECK_CODE_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.AUTH_FORGOT_PASSWORD_RESET_PASSWORD_REQ:
      return {...state, loading: true};
    case TYPES.AUTH_FORGOT_PASSWORD_RESET_PASSWORD_SUCCESS:
      return {...state, loading: false, user: payload};
    case TYPES.AUTH_FORGOT_PASSWORD_RESET_PASSWORD_FAIL:
      return {...state, loading: false, error: payload};
    case TYPES.AUTH_FORGOT_PASSWORD_CHANGE_PHONE_REQ:
      return {...state, loading: true};
    case TYPES.AUTH_FORGOT_PASSWORD_CHANGE_PHONE_SUCCESS: {
      return {...initialForgotPasswordState};
    }
    case TYPES.AUTH_FORGOT_PASSWORD_CLEAR_REQ:
      return {...state};
    case TYPES.AUTH_FORGOT_PASSWORD_CLEAR_SUCCESS:
      return {...initialForgotPasswordState};
    default:
      return state;
  }
};

export const facebookLoginReducer = (state = initialFacebookLoginState, {type, payload}) => {
  switch (type) {
    case TYPES.AUTH_FACEBOOK_LOGIN_REQ:
      return {loading: true, user: null, error: null};
    case TYPES.AUTH_FACEBOOK_LOGIN_SUCCESS:
      return {loading: false, user: payload};
    case TYPES.AUTH_FACEBOOK_LOGIN_FAIL:
      return {
        loading: false,
        user: payload?.data,
        error: payload?.error,
      };
    case TYPES.AUTH_FACEBOOK_LOGIN_CLEAR:
      return {...initialFacebookLoginState};
    default:
      return state;
  }
};
