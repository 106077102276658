import React, {useRef, useState} from 'react';
import {useUserDispatch, useUserState} from '../../../../lib/hooks/logic/useUser';
import {Container, AvatarBackdrop, AvatarImg, IconBackdrop, InputAvatar, OverrideStyles} from './styled-component';
import Icon from '../../../../components/icon';
import Loading from '../../../../components/loading';
import Header from '../../../../components/header';
import {webImagePickerManager} from '../../../../utils/file-picker';
import {webHandlerErrorImageAndFilePicker} from '../../../../lib/context/image';
import * as snackbar from '../../../../utils/components/toast-notification/provider';
import * as ICONS from '../../../../components/icon/constants';
import * as userUtils from '../../../../lib/context/user';

const FILE_IMAGES_SUPPORT = ['image/jpeg', 'image/png', 'image/jpg'];

const ProfileAvatar = () => {
  const nodeAvatarRef = useRef(null);
  const {userState} = useUserState();

  const {isLoadingUploadState, dispatchUpdateUser, dispatchUploadImage} = useUserDispatch();
  const [isErrorImg, setIsErrorImg] = useState(false);
  const fullName = userUtils.getFullName(userState?.general?.firstname, userState?.general?.lastname);

  const onEventChangeProfile = () => {
    if (!nodeAvatarRef.current) {
      return;
    }
    nodeAvatarRef.current.click();
  };

  const onEventSubmitChangeProfile = async (event) => {
    if (!event) {
      return;
    }
    try {
      const response = await webImagePickerManager({event});
      const reqBody = {
        _id: userState._id,
        name: 'profile',
        image: response,
      };
      const [imageUrl, errorUpload] = await dispatchUploadImage(reqBody);
      if (imageUrl && userState) {
        const reqBodyUser = {
          ...userState,
          general: {
            ...userState.general,
            picture_url: imageUrl,
          },
        };
        dispatchUpdateUser(reqBodyUser, true);
      }
      if (errorUpload) {
        const message = webHandlerErrorImageAndFilePicker(errorUpload);
        snackbar.setSnackbarMessage(message, 'error');
      }
    } catch (error) {
      const message = webHandlerErrorImageAndFilePicker(error);
      snackbar.setSnackbarMessage(message, 'error');
    }
  };

  return (
    <>
      <Loading loading={isLoadingUploadState || false} />
      <Container>
        <AvatarBackdrop onClick={onEventChangeProfile}>
          {userState?.general?.picture_url && !isErrorImg && (
            <AvatarImg alt="" src={userState.general.picture_url.url} onError={() => setIsErrorImg(true)} />
          )}
          <InputAvatar alt="" type="file" ref={nodeAvatarRef} accept={FILE_IMAGES_SUPPORT.join(',')} onChange={onEventSubmitChangeProfile} />
          <IconBackdrop>
            <Icon name={ICONS.CAMERA} size={16} />
          </IconBackdrop>
        </AvatarBackdrop>
      </Container>
      <Header center variant="h3" style={OverrideStyles.name}>
        {fullName}
      </Header>
    </>
  );
};

ProfileAvatar.propTypes = {};

export default ProfileAvatar;
