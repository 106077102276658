import {useSelector, useDispatch} from 'react-redux';
import {setSnackbarMessage} from '../../../utils/components/toast-notification/provider';
import * as contextActions from '../../../redux/context/action';
import * as searchActions from '../../../redux/search/action';
import * as itrTypes from '../../../redux/interest/type';

export const useJobPostContact = () => {
  const dispatch = useDispatch();
  const contactState = useSelector((state) => state.context.contacts);

  const dispatchInterestedSeeContact = (id) => {
    if (!id) {
      return;
    }
    dispatch(
      searchActions.searchInterestDebounce({
        data: {
          _id: id,
          type: itrTypes.INTEREST_SHARE,
        },
      }),
    );
  };

  const dispatchAddContactShow = (props) => {
    const {contactList, contactText, type} = props;
    if (!type) {
      return;
    }
    dispatch(contextActions.contextContactShow({data: {contactList, contactText, type}}));
  };

  const dispatchClearContactShow = () => {
    dispatch(contextActions.contextContactShow({clear: true}));
  };

  const dispatchNativeLink = (props) => {
    const {urlSchema, data, type} = props;
    try {
      let dataParser = data;
      if (type === 'line') {
        if (data[0] !== '@') {
          dataParser = `~${data}`;
        }
      }
      window.open(`${urlSchema}${dataParser}`);
    } catch (error) {
      setSnackbarMessage('อุปกรณ์ไม่รองรับ', 'error');
    }
  };

  return {
    contactState,
    dispatchAddContactShow,
    dispatchClearContactShow,
    dispatchNativeLink,
    dispatchInterestedSeeContact,
  };
};
