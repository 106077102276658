import React from 'react';
import {translate} from '../../res/language/translate';
import {useNotifyState, useNotifyEffect} from '../../lib/hooks/logic/useNotify';
import Screen from '../../views/screen';
import NotifyPostBuild from '../../views/notify-post-builder';
import MetaTag from '../../components/meta-tag';
import Sidebar from './views/side-bar';
import Modal from './views/modal-interested-job';
import ContentMenu from './views/content-menu';
import Loading from '../../components/loading';
import * as logic from './logic';

const NotifyScreen = () => {
  const {isLoadingState, notify, isModal, interestedJobState} = useNotifyState();

  useNotifyEffect();

  return (
    <>
      <Loading loading={isLoadingState || false} />
      <Modal visible={isModal.visible} />
      <MetaTag title={translate('meta.notify')} description={translate('meta.notify')} />
      <Sidebar />
      <Screen leftSidebar isChildScreen noHorizontalEdgeInsets>
        <ContentMenu />
        <NotifyPostBuild allNotify={notify} searchParams={logic.getParamsSearch(interestedJobState) || null} />
      </Screen>
    </>
  );
};

NotifyScreen.propTypes = {};

export default NotifyScreen;
