import {translate} from '../../../res/language/translate';

export const getPlaceholderText = (fieldName) => {
  switch (fieldName) {
    case 'email':
      return `${translate('register.placeholderPrefix')}${translate('register.textFieldEmail')}`;
    case 'password':
      return `${translate('register.placeholderPrefix')}${translate('register.textFieldPassword')}`;
    case 'conf-password':
      return `${translate('register.placeholderPrefix')}${translate('register.textFieldConfPassword')}`;
    case 'firstName':
      return `${translate('register.placeholderPrefix')}${translate('register.textFieldFirstName')}`;
    case 'lastName':
      return `${translate('register.placeholderPrefix')}${translate('register.textFieldLastName')}`;
    default:
      return '';
  }
};

export const createAgeData = () => {
  const maxAge = 120;
  const data = [];
  for (let i = 12; i <= maxAge; i++) {
    data.push({id: i, label: `${i} ปี`, value: i});
  }
  return data;
};
