export const INVALID_PAYLOAD = 'INVALID_PAYLOAD';

export const AUTH_REGISTER_MANUAL_REQ = '@AUTH/REGISTER_MANUAL_REQ';
export const AUTH_REGISTER_FACEBOOK_REQ = '@AUTH/REGISTER_FACEBOOK_REQ';
export const AUTH_REGISTER_SUCCESS = '@AUTH/REGISTER_SUCCESS';
export const AUTH_REGISTER_FAIL = '@AUTH/REGISTER_FAIL';

export const AUTH_LOGIN_MANUAL_REQ = '@AUTH/LOGIN_MANUAL_REQ';
export const AUTH_LOGIN_FACEBOOK_REQ = '@AUTH/LOGIN_FACEBOOK_REQ';
export const AUTH_LOGIN_SUCCESS = '@AUTH/LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = '@AUTH/LOGIN_FAIL';

export const AUTH_FORGOT_PASSWORD_REQUEST_CODE_REQ = '@AUTH/FORGOT_PASSWORD_REQUEST_CODE_REQ';
export const AUTH_FORGOT_PASSWORD_REQUEST_CODE_SUCCESS = '@AUTH/FORGOT_PASSWORD_REQUEST_CODE_SUCCESS';
export const AUTH_FORGOT_PASSWORD_REQUEST_CODE_FAIL = '@AUTH/FORGOT_PASSWORD_REQUEST_CODE_FAIL';
export const AUTH_FORGOT_PASSWORD_CHECK_CODE_REQ = '@AUTH/FORGOT_PASSWORD_CHECK_CODE_REQ';
export const AUTH_FORGOT_PASSWORD_CHECK_CODE_SUCCESS = '@AUTH/FORGOT_PASSWORD_CHECK_CODE_SUCCESS';
export const AUTH_FORGOT_PASSWORD_CHECK_CODE_FAIL = '@AUTH/FORGOT_PASSWORD_CHECK_CODE_FAIL';
export const AUTH_FORGOT_PASSWORD_RESET_PASSWORD_REQ = '@AUTH/FORGOT_PASSWORD_RESET_PASSWORD_REQ';
export const AUTH_FORGOT_PASSWORD_RESET_PASSWORD_SUCCESS = '@AUTH/FORGOT_PASSWORD_RESET_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_RESET_PASSWORD_FAIL = '@AUTH/FORGOT_PASSWORD_RESET_PASSWORD_FAIL';
export const AUTH_FORGOT_PASSWORD_CHANGE_PHONE_REQ = '@AUTH/FORGOT_PASSWORD_CHANGE_PHONE_REQ';
export const AUTH_FORGOT_PASSWORD_CHANGE_PHONE_SUCCESS = '@AUTH/FORGOT_PASSWORD_CHANGE_PHONE_SUCCESS';
export const AUTH_FORGOT_PASSWORD_CLEAR_REQ = '@AUTH/FORGOT_PASSWORD_CLEAR_REQ';
export const AUTH_FORGOT_PASSWORD_CLEAR_SUCCESS = '@AUTH/FORGOT_PASSWORD_CLEAR_SUCCESS';

export const AUTH_LOGIN_FROM_REGISTER_REQ = '@AUTH/LOGIN_FROM_REGISTER_REQ';
export const AUTH_LOGIN_FROM_REGISTER_SUCCESS = '@AUTH/LOGIN_FROM_REGISTER_SUCCESS';
export const AUTH_LOGIN_FROM_REGISTER_FAIL = '@AUTH/LOGIN_FROM_REGISTER_FAIL';

export const AUTH_CURRENT_REQ = '@AUTH/CURRENT_REQ';
export const AUTH_CURRENT_SUCCESS = '@AUTH/CURRENT_SUCCESS';
export const AUTH_CURRENT_FAIL = '@AUTH/CURRENT_FAIL';

export const AUTH_LOGOUT_REQ = '@AUTH/LOGOUT_REQ';
export const AUTH_LOGOUT_SUCCESS = '@AUTH/LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAIL = '@AUTH/LOGOUT_FAIL';

export const AUTH_FACEBOOK_LOGIN_REQ = '@AUTH/FACEBOOK_LOGIN_REQ';
export const AUTH_FACEBOOK_LOGIN_SUCCESS = '@AUTH/FACEBOOK_LOGIN_SUCCESS';
export const AUTH_FACEBOOK_LOGIN_FAIL = '@AUTH/FACEBOOK_LOGIN_FAIL';
export const AUTH_FACEBOOK_LOGIN_CLEAR = '@AUTH/FACEBOOK_LOGIN_CLEAR';

export const AUTH_AUTHORIZE_REQ = '@AUTH/AUTHORIZE_REQ';
export const AUTH_AUTHORIZE_DENIED = '@AUTH/AUTHORIZE_DENIED';

export const AUTH_IS_CHECKED_SUCCESS = '@AUTH/IS_CHECKED_SUCCESS';

export const AUTH_OTP_CANCEL_INVOKE_REQ = '@AUTH/OTP_CANCEL_INVOKE_REQ';
export const AUTH_OTP_CANCEL_INVOKE_SUCCESS = '@AUTH/OTP_CANCEL_INVOKE_SUCCESS';
export const AUTH_OTP_CANCEL_INVOKE_FAIL = '@AUTH/OTP_CANCEL_INVOKE_FAIL';

export const AUTH_UPDATE_CURRENT_REQ = '@AUTH/UPDATE_CURRENT_REQ';
export const AUTH_UPDATE_CURRENT_SUCCESS = '@AUTH/UPDATE_CURRENT_SUCCESS';

export const FIREBASE_ANONYMOUS_LOGIN_REQ = '@AUTH/FIREBASE_ANONYMOUS_LOGIN_REQ';
export const FIREBASE_ANONYMOUS_LOGIN_SUCCESS = '@AUTH/FIREBASE_ANONYMOUS_LOGIN_SUCCESS';
export const FIREBASE_ANONYMOUS_LOGIN_FAIL = '@AUTH/FIREBASE_ANONYMOUS_LOGIN_FAIL';
export const FIREBASE_ANONYMOUS_LOGOUT_REQ = '@AUTH/FIREBASE_ANONYMOUS_LOGOUT_REQ';
export const FIREBASE_ANONYMOUS_LOGOUT_SUCCESS = '@AUTH/FIREBASE_ANONYMOUS_LOGOUT_SUCCESS';
export const FIREBASE_ANONYMOUS_LOGOUT_FAIL = '@AUTH/FIREBASE_ANONYMOUS_LOGOUT_FAIL';
