import {put, call, delay} from 'redux-saga/effects';
import {authorizeProvider} from '../utils/authorize';
import * as api from './api';
import * as TYPES from './type';

const TIME_DELAY_STREAM = 3000;

export function* trackInteractJobUpdateStack({payload}) {
  yield put({
    type: TYPES.TRACK_EVENT_INTERACT_JOB_UPDATE_STACK_SUCCESS,
    payload: payload,
  });
}

export function* trackInteractJobStreamStack({payload}) {
  yield delay(TIME_DELAY_STREAM);
  try {
    yield call(api.trackJobInteract, payload?.data);
    yield put({
      type: TYPES.TRACK_EVENT_INTERACT_JOB_STREAM_STACK_SUCCESS,
      payload: payload?.data?.jobs,
    });
  } catch (error) {
    const isAuthorize = authorizeProvider(error);
    if (isAuthorize) {
      const responseError = error?.response?.data?.error;
      yield put({
        type: TYPES.TRACK_EVENT_INTERACT_JOB_STREAM_STACK_FAIL,
        payload: responseError,
      });
    }
  }
}
