import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {
  PostHeaderLeft,
  PrimaryText,
  SecondaryText,
  OverrideStyles,
  VerifyWrapper,
  Text,
  HoverWrapper,
  HeaderWrapper,
  AvatarWrapper,
} from '../styled-component';
import ContactItem from '../interact/contact/contact-sub';
import BottomSheets from '../../../bottom-sheets';
import {MoreWrapper, PostHeaderRight, MoreListWrapper, MoreItem, ItemText} from './styled-component';
import Avatar from '../../../../components/avatar';
import Column from '../../../../components/column';
import Row from '../../../../components/row';
import Icon from '../../../../components/icon';
import AppLogo from '../../../../res/assets/images/app-logo.png';
import ENV from '../../../../env.json';
import Link from '../../../../components/link';
import Responsive from '../../../../components/responsive';
import * as ICONS from '../../../../components/icon/constants';
import * as CONTEXT_ACTIONS from '../../../../redux/context/action';

const BREAK_POINT = 1024;

const RenderPostHeader = (props) => {
  const {
    jobId,
    isMeta,
    isCompany,
    avatarSrc,
    authorName,
    postDate,
    location,
    isCompanyVerified,
    isActivated,
    srcRemoved,
    src,
    linkOptions,
    isCompanyPage,
  } = props;

  const {formatMessage} = useIntl();
  const dispatch = useDispatch();
  const [isHoverState, setIsHoverState] = useState(false);
  const [isHoverMoreState, setIsHoverMoreState] = useState(false);
  const [isShowSheet, setIsShowSheet] = useState(false);
  const jobClosedText = srcRemoved || !isActivated ? formatMessage({defaultMessage: '(ประกาศรับสมัครปิดแล้ว)'}) : '';

  const onMouseEnter = () => {
    if (!isHoverState) {
      setIsHoverState(true);
    }
  };

  const onMouseLeave = () => {
    if (isHoverState) {
      setIsHoverState(false);
    }
  };

  const onMouseEnterMoreMenu = () => {
    if (!isHoverMoreState) {
      setIsHoverMoreState(true);
    }
  };

  const onMouseLeaveMoreMenu = () => {
    if (isHoverMoreState) {
      setIsHoverMoreState(false);
    }
  };

  const onEventHideSheet = () => {
    setIsShowSheet(false);
  };

  const onEventShowSheet = () => {
    setIsShowSheet(true);
  };

  const onEventRequestReportPost = () => {
    if (jobId) {
      dispatch(CONTEXT_ACTIONS.contextShowReportPost(jobId));
    }
    onEventHideSheet();
    onMouseLeaveMoreMenu();
  };

  if (linkOptions) {
    const {url, search} = linkOptions;
    return (
      <>
        <Responsive maxWidth={BREAK_POINT}>
          <BottomSheets noEdgeInsets isOpen={isShowSheet} onRequestClose={() => setIsShowSheet(false)}>
            <MoreItem onClick={onEventRequestReportPost}>
              <Icon name={ICONS.TRASH} size={20} color={OverrideStyles.iconInteract.unActive} />
              <ItemText>{formatMessage({defaultMessage: 'แจ้งลบโพสต์นี้'})}</ItemText>
            </MoreItem>
            <ContactItem type="close" onEventCallback={onEventHideSheet} />
          </BottomSheets>
        </Responsive>
        <HeaderWrapper>
          {!ENV.PUBLIC.IS_PRODUCTION && isHoverState && (
            <HoverWrapper>
              <Text>{`source = "${src}"`}</Text>
            </HoverWrapper>
          )}
          <Row alignStart wrap="nowrap" content="space-between" style={OverrideStyles.rowHeader}>
            <Link url={url} search={search}>
              <PostHeaderLeft>
                <AvatarWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                  <Avatar src={isMeta && !isCompany ? AppLogo : avatarSrc} />
                </AvatarWrapper>
                <Column>
                  {isCompanyVerified && (
                    <VerifyWrapper>
                      <Icon name={ICONS.USER_VERIFY} size={16} color={OverrideStyles.iconVerifiedColor} />
                      <PrimaryText noEdgeInsets>{authorName}</PrimaryText>
                    </VerifyWrapper>
                  )}
                  {!isCompanyVerified && <PrimaryText>{authorName}</PrimaryText>}
                  <SecondaryText>
                    {postDate} {jobClosedText}
                  </SecondaryText>
                </Column>
              </PostHeaderLeft>
            </Link>
            {!isMeta && (
              <PostHeaderRight>
                <Row wrap="nowrap">
                  <Icon name={ICONS.LOCATION} color={OverrideStyles.iconLocation.color} size={20} />
                  <SecondaryText noPadding>{location}</SecondaryText>
                </Row>
                <Responsive minWidth={BREAK_POINT + 1}>
                  <MoreWrapper onMouseEnter={onMouseEnterMoreMenu} onMouseLeave={onMouseLeaveMoreMenu}>
                    <Icon name={ICONS.MORE_HORIZONTAL} />
                    {isHoverMoreState && (
                      <MoreListWrapper>
                        <MoreItem noBorder onClick={onEventRequestReportPost}>
                          <Icon name={ICONS.TRASH} size={20} color={OverrideStyles.iconInteract.unActive} />
                          <ItemText>{formatMessage({defaultMessage: 'แจ้งลบโพสต์นี้'})}</ItemText>
                        </MoreItem>
                      </MoreListWrapper>
                    )}
                  </MoreWrapper>
                </Responsive>
                <Responsive maxWidth={BREAK_POINT}>
                  <MoreWrapper onClick={onEventShowSheet}>
                    <Icon name={ICONS.MORE_HORIZONTAL} />
                    {isHoverMoreState && (
                      <MoreListWrapper>
                        <MoreItem noBorder onClick={onEventRequestReportPost}>
                          <Icon name={ICONS.TRASH} size={20} color={OverrideStyles.iconInteract.unActive} />
                          <ItemText>{formatMessage({defaultMessage: 'แจ้งลบโพสต์นี้'})}</ItemText>
                        </MoreItem>
                      </MoreListWrapper>
                    )}
                  </MoreWrapper>
                </Responsive>
              </PostHeaderRight>
            )}
          </Row>
        </HeaderWrapper>
      </>
    );
  }

  if (isCompanyPage) {
    return (
      <>
        <Responsive maxWidth={BREAK_POINT}>
          <BottomSheets noEdgeInsets isOpen={isShowSheet} onRequestClose={() => setIsShowSheet(false)}>
            <MoreItem onClick={onEventRequestReportPost}>
              <Icon name={ICONS.TRASH} size={20} color={OverrideStyles.iconInteract.unActive} />
              <ItemText>{formatMessage({defaultMessage: 'แจ้งลบโพสต์นี้'})}</ItemText>
            </MoreItem>
            <ContactItem type="close" onEventCallback={onEventHideSheet} />
          </BottomSheets>
        </Responsive>
        <HeaderWrapper>
          {!ENV.PUBLIC.IS_PRODUCTION && isHoverState && (
            <HoverWrapper>
              <Text>{`source = "${src}"`}</Text>
            </HoverWrapper>
          )}
          <Row alignStart wrap="nowrap" content="space-between" style={OverrideStyles.rowHeader}>
            <PostHeaderLeft>
              <AvatarWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <Avatar src={isMeta && !isCompany ? AppLogo : avatarSrc} />
              </AvatarWrapper>
              <Column>
                {isCompanyVerified && (
                  <VerifyWrapper>
                    <Icon name={ICONS.USER_VERIFY} size={16} color={OverrideStyles.iconVerifiedColor} />
                    <PrimaryText noEdgeInsets>{authorName}</PrimaryText>
                  </VerifyWrapper>
                )}
                {!isCompanyVerified && <PrimaryText>{authorName}</PrimaryText>}
                <SecondaryText>
                  {postDate} {jobClosedText}
                </SecondaryText>
              </Column>
            </PostHeaderLeft>
            {!isMeta && (
              <PostHeaderRight>
                <Row wrap="nowrap">
                  <Icon name={ICONS.LOCATION} color={OverrideStyles.iconLocation.color} size={20} />
                  <SecondaryText noPadding>{location}</SecondaryText>
                </Row>
                <Responsive minWidth={BREAK_POINT + 1}>
                  <MoreWrapper onMouseEnter={onMouseEnterMoreMenu} onMouseLeave={onMouseLeaveMoreMenu}>
                    <Icon name={ICONS.MORE_HORIZONTAL} />
                    {isHoverMoreState && (
                      <MoreListWrapper>
                        <MoreItem noBorder onClick={onEventRequestReportPost}>
                          <Icon name={ICONS.TRASH} size={20} color={OverrideStyles.iconInteract.unActive} />
                          <ItemText>{formatMessage({defaultMessage: 'แจ้งลบโพสต์นี้'})}</ItemText>
                        </MoreItem>
                      </MoreListWrapper>
                    )}
                  </MoreWrapper>
                </Responsive>
                <Responsive maxWidth={BREAK_POINT}>
                  <MoreWrapper onClick={onEventShowSheet}>
                    <Icon name={ICONS.MORE_HORIZONTAL} />
                    {isHoverMoreState && (
                      <MoreListWrapper>
                        <MoreItem noBorder onClick={onEventRequestReportPost}>
                          <Icon name={ICONS.TRASH} size={20} color={OverrideStyles.iconInteract.unActive} />
                          <ItemText>{formatMessage({defaultMessage: 'แจ้งลบโพสต์นี้'})}</ItemText>
                        </MoreItem>
                      </MoreListWrapper>
                    )}
                  </MoreWrapper>
                </Responsive>
              </PostHeaderRight>
            )}
          </Row>
        </HeaderWrapper>
      </>
    );
  }

  return (
    <HeaderWrapper>
      {!ENV.PUBLIC.IS_PRODUCTION && isHoverState && (
        <HoverWrapper>
          <Text>{`source = "${src}"`}</Text>
        </HoverWrapper>
      )}
      <Row alignStart wrap="nowrap" content="space-between" style={OverrideStyles.rowHeader}>
        <PostHeaderLeft>
          <AvatarWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Avatar src={isMeta && !isCompany ? AppLogo : avatarSrc} />
          </AvatarWrapper>
          <Column>
            {isCompanyVerified && (
              <VerifyWrapper>
                <Icon name={ICONS.USER_VERIFY} size={16} color={OverrideStyles.iconVerifiedColor} />
                <PrimaryText noEdgeInsets>{authorName}</PrimaryText>
              </VerifyWrapper>
            )}
            {!isCompanyVerified && <PrimaryText>{authorName}</PrimaryText>}
            <SecondaryText>
              {postDate} {jobClosedText}
            </SecondaryText>
          </Column>
        </PostHeaderLeft>
        {!isMeta && (
          <Row wrap="nowrap">
            <Icon name={ICONS.LOCATION} color={OverrideStyles.iconLocation.color} size={20} />
            <SecondaryText noPadding>{location}</SecondaryText>
          </Row>
        )}
      </Row>
    </HeaderWrapper>
  );
};

RenderPostHeader.propTypes = {
  isMeta: PropTypes.bool,
  avatarSrc: PropTypes.string,
  authorName: PropTypes.string,
  postDate: PropTypes.string,
  location: PropTypes.string,
  isCompany: PropTypes.bool,
  isCompanyVerified: PropTypes.bool,
  isActivated: PropTypes.bool,
  srcRemoved: PropTypes.bool,
  src: PropTypes.string.isRequired,
  linkOptions: PropTypes.object,
  jobId: PropTypes.string,
  isCompanyPage: PropTypes.bool,
};

RenderPostHeader.defaultProps = {
  isMeta: false,
  avatarSrc: null,
  authorName: null,
  postDate: null,
  location: null,
  isCompany: false,
  isCompanyVerified: false,
  isActivated: true,
  srcRemoved: false,
  linkOptions: null,
  jobId: null,
  isCompanyPage: false,
};

export default RenderPostHeader;
