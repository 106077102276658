import React from 'react';
import {useIntl} from 'react-intl';
import {useAuth} from '../../../lib/hooks/logic/useAuth';
import MetaTag from '../../../components/meta-tag';
import Screen from '../../../views/screen';
import PrivacyPolicyView from './views';

const PrivacyAndPolicyScreen = () => {
  const {formatMessage} = useIntl();
  const {loggedInState, isCheckedAuth} = useAuth();

  if (!isCheckedAuth) {
    return (
      <>
        <MetaTag
          title={formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์ - JobFinFin'})}
          description={formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์ - JobFinFin'})}
        />
      </>
    );
  }

  if (loggedInState) {
    return (
      <>
        <MetaTag
          title={formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์ - JobFinFin'})}
          description={formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์ - JobFinFin'})}
        />
        <Screen isChildScreen type="single-fluid" title={formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์'})}>
          <PrivacyPolicyView isLoggedIn />
        </Screen>
      </>
    );
  }

  return (
    <>
      <MetaTag
        title={formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์ - JobFinFin'})}
        description={formatMessage({defaultMessage: 'ข้อกำหนดและเงื่อนไขในการใช้เว็บไซต์ - JobFinFin'})}
      />
      <PrivacyPolicyView isLoggedIn={false} />
    </>
  );
};

PrivacyAndPolicyScreen.propTypes = {};

export default PrivacyAndPolicyScreen;
