import styled from 'styled-components';
// import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  padding: 0 0 1rem 0;
`;

export const Label = styled.label`
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.25rem;
`;

export const TextArea = styled.textarea`
  font-size: 16px;
  height: 44px;
  background-color: transparent;
  padding: 1rem;
  border-radius: 6px;
  width: 100%;
  height: ${(props) => props.height}px;
  resize: none;
  border: 1px solid ${(props) => props.theme.tinGray};
  outline: none;
  font-family: 'Prompt Light';
  ${(props) => props.warningText && `border: 1px solid ${props.theme.danger}`};
`;

export const InputHint = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.gray70};
  ${(props) => props.warning && `color: ${props.theme.danger}`}
`;
