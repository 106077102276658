import React from 'react';
import PropTypes from 'prop-types';
import Header from '../header';
import Text from '../text';
import {Container, WalkThroughHead, OverrideStyles} from './styled-component';
import SVGImage from '../svg-img';

const WalkThrough = (props) => {
  const {icon, title, description} = props;
  return (
    <Container>
      <WalkThroughHead>
        <SVGImage inline src={icon} height={45} width={45} inlineStyle={OverrideStyles.icon} />
        <Header variant="h3">{title}</Header>
      </WalkThroughHead>
      <Text>{description}</Text>
    </Container>
  );
};

WalkThrough.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default WalkThrough;
