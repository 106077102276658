import React from 'react';
import PropTypes from 'prop-types';
import {Container, ChipLabel, IconWrapper, OverrideStyles} from './styled-component';
import Icon from '../icon';

const Chip = (props) => {
  const {text, square, rightIcon, onClick, wrapText, active, style} = props;

  const handlerOnClick = (e) => {
    e.preventDefault();
    if (!onClick) {
      return;
    }
    onClick();
  };

  return (
    <Container onClick={handlerOnClick} square={square} wrapText={wrapText} active={active} style={style}>
      <ChipLabel active={active}>{text}</ChipLabel>
      {rightIcon && (
        <IconWrapper>
          <Icon name={rightIcon} size={OverrideStyles.icon.size} color={OverrideStyles.icon.color} />
        </IconWrapper>
      )}
    </Container>
  );
};

Chip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.any]).isRequired,
  wrapText: PropTypes.bool,
  square: PropTypes.bool,
  active: PropTypes.bool,
  rightIcon: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.any,
};

Chip.defaultProps = {
  square: false,
  active: false,
  wrapText: false,
  rightIcon: null,
  onClick: null,
  style: null,
};

export default Chip;
