import styled from 'styled-components';
import {lightTheme} from '../../styles/themes';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 1rem 0.5rem;
  border-radius: 6px;
  ${(props) => props.isAction && `cursor: pointer`};
  &:hover {
    ${(props) => props.isAction && `background-color: ${props.theme.lightHover100}`};
  }
  ${(props) => props.isActive && `background-color: ${props.theme.lightHover100}`};
`;

export const ContainerDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0.75rem;
  border-radius: 6px;
  ${(props) => props.isAction && `cursor: pointer`};
  ${(props) => props.topEdgeInsets && `margin-top: 0.5rem`};
  &:hover {
    ${(props) => props.isAction && `background-color: ${props.theme.lightHover100}`};
  }
`;

export const Title = styled.span`
  ${(props) => props.isAction && `color ${props.theme.blueFade}; font-weight: bold`};
`;

export const Description = styled.span`
  color: ${(props) => props.theme.gray};
`;

export const IconWrapper = styled.div``;

export const OverrideStyles = {
  body: {
    paddingLeft: '0.75rem',
  },
  icon: lightTheme.blueFade,
  divider: {
    margin: '0.5rem 0',
  },
};
